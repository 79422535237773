import slices from './slices';

export default {
  ...slices.channelsSlice.actions,
  ...slices.usersSlice.actions,
  ...slices.locationSlice.actions,
  ...slices.skillsSlice.actions,
  ...slices.departmentsSlice.actions,
  ...slices.actionJobsSlice.actions,
  ...slices.allUsersSlice.actions,
  ...slices.associatesSlice.actions,
  ...slices.managersSlice.actions,
  ...slices.pendingUsersSlice.actions,
  ...slices.removedUsersSlice.actions,
  ...slices.onlineUsersSlice.actions,
  ...slices.plansSlice.actions,
};
