
import React from 'react';
import { connect, } from 'react-redux';
import EditUserEmployeeIDComponent from './EditUserEmployeeIDComponent';
import { default as operations } from '../../../duck/operations';
import { unregisteredUserOperations, } from '../../../../UnregisteredUser/duck';

const mapDispatchToProps = (dispatch) => {

  const updateUnregisteredUserBadgeIDDispatch = (unregisteredUserId, badgeId) => {
    dispatch(unregisteredUserOperations.updateUnregisteredUserBadgeIDOperation(unregisteredUserId, badgeId));
  }

  const updateUserBadgeIDDispatch = (id, ownerId, badge) => {
    dispatch(operations.updateCurrentEmployeeBadgeIdOnly(id, ownerId, {
      badge_id: badge,
    }));
  }

  return {
    updateUserBadgeIDDispatch,
    updateUnregisteredUserBadgeIDDispatch,
  };
};




const EditUserEmployeeIDContainer = connect(
  null,
  mapDispatchToProps
)((props) => {
  const { locationId, handleCloseEditEmployeeIDModal, updateUserBadgeIDDispatch, updateUnregisteredUserBadgeIDDispatch, user, locationData } = props;
  return (
    <EditUserEmployeeIDComponent
      handleCloseEditEmployeeIDModal={handleCloseEditEmployeeIDModal}
      locationId={locationId}
      user={user}
      updateUserBadgeIDDispatch={updateUserBadgeIDDispatch}
      updateUnregisteredUserBadgeIDDispatch={updateUnregisteredUserBadgeIDDispatch}
      locationData={locationData}
    />
  );
});

export default EditUserEmployeeIDContainer;
