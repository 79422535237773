import React, { useEffect, useState } from 'react';
import './ShiftDetailComponent.scss';
import { Mixpanel } from '../../../../../Mixpanel';
import formatter from '../../../duck/formatter';
import displayLogic from 'pages/ScheduleCalendar/duck/displayLogic';
import { injectIntl } from 'react-intl';
import { isValidObject } from 'helpers/validation';
import ShiftApplicantsComponentContainer from '../Applicants/ShiftApplicantsComponentContainer';
import ShiftApplicantCommentsContainer from '../Applicants/ShiftApplicantCommentsContainer';
import DetailChildCardHeaderComponent from './DetailChildCardHeaderComponent';
import service from 'pages/ScheduleCalendar/duck/service';
import presenter from 'pages/ScheduleCalendar/duck/presenter';
import ShiftFieldsComponentContainer from './ShiftFieldsComponentContainer';

const ShiftDetailComponent = ({
  allUsers,
  date,
  presentedShift,
  prepareToDeleteShift,
  prepareToCopyShift,
  cancelModal,
  loadApplicantsForShiftDispatch,  
  userToAddShiftTo,
  shiftMatrix,
  weekRangeStart,
  handleTooltipShow,
  handleTooltipHide,
  applicants,
  showOpenShiftOption,
}) => {
  const [selectedTab, setSelectedTab] = useState('fields');

  useEffect(() => {
    if( isValidObject(presentedShift) === true ){ loadApplicantsForShiftDispatch(presentedShift.id); }
  }, [loadApplicantsForShiftDispatch] );

  const handleCancel = () => {
    cancelModal();
    Mixpanel.track('Cancel shift detail');
  };

  const isViewingShift = isValidObject(presentedShift) === true;
  const dateToAddShiftTo = formatter.shiftTitleDate(date);

  const showChildCardHeader = displayLogic.shouldShowChildCardHeader(presentedShift);

  const subtype = service.getShiftSubType(presentedShift); 

  const applicantId = service.getApplicantIdFromChildCard(presentedShift, subtype)
  const applicantUserId = service.getApplicantUserIdFromChildCard(presentedShift, subtype)

  const applicantNumText = presenter.applicantNumberText(applicants.length)

  return (
    <div className="shift-detail-container">
      <div className="title-row">
        <label className="title">
          {isViewingShift === true && formatter.shiftDetailTitle(presentedShift)}
          {isViewingShift === false && presenter.createShiftOnText(dateToAddShiftTo)}
        </label>
        <button className="close-button" onClick={handleCancel}>
          <img src="icons/close-simple.svg" alt="Close" />
        </button>
      </div>

      {showChildCardHeader == true && <DetailChildCardHeaderComponent shift={presentedShift} allUsers={allUsers}/> }

      { displayLogic.shouldShowSectionTab(presentedShift) == true && 
        <div className="section-tab">

          <button className={`tab-button ${selectedTab === 'fields' ? 'active' : ''}`} onClick={() => setSelectedTab('fields')} >
            Shift Details
          </button>

          { displayLogic.shouldShowApplicantsTabOnDetail(subtype)  == true &&
          <button className={`tab-button ${selectedTab === 'applicants' ? 'active' : ''}`} onClick={() => setSelectedTab('applicants')} >
           {applicantNumText} 
          </button>}

          {displayLogic.shouldShowCommentsTabOnDetail(subtype) == true &&
          <button className={`tab-button ${selectedTab === 'comments' ? 'active' : ''}`} onClick={() => setSelectedTab('comments')} >
          Comments 
          </button>}

        </div> }

      {selectedTab === 'fields' && (
        <ShiftFieldsComponentContainer
          presentedShift={presentedShift}
          cancelModal={cancelModal}
          prepareToDeleteShift={prepareToDeleteShift}
          prepareToCopyShift={prepareToCopyShift}
          date={date}
          userToAddShiftTo = {userToAddShiftTo}
          applicants = {applicants}
          showOpenShiftOption = {showOpenShiftOption}
        />)}

      {selectedTab === 'applicants' && 
        <ShiftApplicantsComponentContainer 
          presentedShift = {presentedShift}
          shiftMatrix = {shiftMatrix}
          weekRangeStart={weekRangeStart}
          handleTooltipHide={handleTooltipHide}
          handleTooltipShow={handleTooltipShow}
        />}

      {selectedTab === 'comments' && 
        <ShiftApplicantCommentsContainer 
            applicantId = {applicantId}
            applicantUserId = {applicantUserId} />}

    </div>
  );
};

export default injectIntl(ShiftDetailComponent);
