import { createSlice } from '@reduxjs/toolkit';

const applicantCommentsSlice = createSlice({
  name: 'applicantComments',
  initialState: {
    // {applicant_id: [{FreePost Object}, {FreePost Object}] } 
    dict: {},
  },
  reducers: {

    hydrateApplicantComments: (state, action) => {
      state.dict[action.payload.applicant_id] = action.payload.posts;
    },

    appendApplicantComment: (state, action) => {
      const { applicant_id, comment } = action.payload;
      if( state.dict[applicant_id] == null ){
        state.dict[applicant_id] = [];
      }

      state.dict[applicant_id].push(comment);
    },

    upsertApplicantComment: (state, action) => {
      const { applicant_id, post } = action.payload;
      if( state.dict[applicant_id] == null ){
        state.dict[applicant_id] = [];
      }

      const existingComments = state.dict[applicant_id].filter( (comment) => comment.id == action.payload.post.id ); 
      if( existingComments.length > 0 ){
        state.dict[applicant_id]= state.dict[applicant_id].map(item => item.id === action.payload.post.id ? action.payload.post: item);
      }else{
        state.dict[applicant_id].push(action.payload.post);
      }
    },

    resetApplicantComments: (state) => {
      state.dict= {};
    },
  },
});

export default applicantCommentsSlice;