import moment from 'moment';
import { LinkUnregisteredUserSchedule, AddShiftToUserCall, DeleteShiftCall, BroadcastNotificationCall, AddShiftToUnregisteredUserCall } from 'helpers/api-calls/schedule-calls';
import { RefreshSingleShiftApplicant, getShiftApplicants, PickApplicant, DenyApplicant, GetShift, UpdatePublishedOpenShiftCall } from 'helpers/api-calls/shift-calls';
import { UpdateDraftShiftCall, AddDraftShiftToUserCall, AddDraftShiftToUnregisteredUserCall, DeleteDraftShiftCall, RevertAllDraftChangesCall, PublishDraftShiftCall, AddDraftOpenShiftToUserCall, UpdateDraftOpenShiftCall } from 'helpers/api-calls/draft-calls';
import { loadCompactShiftsInDateRange } from 'helpers/api-calls/shift-calls';
import { CreateApplicantFreePost, LoadApplicantPosts, getOnePost } from 'helpers/api-calls/feed-calls';

import actions from './actions';
import teamCalls from 'helpers/api-calls/team-calls';
import { SHIFTS_PER_PAGE } from '../constants';
import { Mixpanel } from '../../../Mixpanel';

const changeDateRangeOperation = (from, to) => (dispatch) => {
  const payload = {
    from: from,
    to: to,
    startStr: from.format('YYYY-MM-DD'),
  };
  dispatch(actions.updateDateRangeInSlice(payload));
  dispatch(actions.resetShifts(true));
}

const flagForResetOperation = () => (dispatch) => {
  dispatch(actions.flagForReset());
}

const clearResetFlagOperation = () => (dispatch) => {
  dispatch(actions.clearResetFlag());
}

const notifyTeamOperation = (locationId, message, event, users) => (dispatch) => {
  return BroadcastNotificationCall(locationId, message, event, users).then(({ data }) => {

    console.log(JSON.stringify(data));
  }).catch((error) => {
    Mixpanel.error('Notify team', { 'error': error });
  });
};

const deleteShiftOperation = (scheduleId, locationId) => (dispatch) => {
  return DeleteShiftCall(scheduleId, locationId).then(({ data }) => {
    dispatch(actions.deleteSingleShift({scheduleId: scheduleId, locationId: locationId}));

  }).catch((error) => {
    Mixpanel.error('Delete Shift', { 'error': error });
  });
};

const addShiftToUserOnDateOperation = (userId, startDateTime, endDateTime, jobTitle, locationId) => (dispatch) => {
  dispatch(actions.changeStatusToAdding(true));
  return AddShiftToUserCall(userId, startDateTime, endDateTime, jobTitle, locationId).then(({ data }) => {
    const payload = {
      shifts: data.schedule_element,
    };

    dispatch(actions.appendSingleShift(payload));

  }).catch((error) => {
    Mixpanel.error('Add Shift to User', { 'error': error });
  });
};

const addShiftToUnregisteredUserOnDateOperation = (unregUserId, startDateTime, endDateTime, jobTitle, locationId) => (dispatch) => {
  dispatch(actions.changeStatusToAdding(true));
  return AddShiftToUnregisteredUserCall(unregUserId, startDateTime, endDateTime, jobTitle, locationId).then(({ data }) => {
    const payload = {
      shifts: data.schedule_element,
    };

    console.log(payload);
    dispatch(actions.appendSingleShift(payload));

  }).catch((error) => {
    Mixpanel.error('Add Shift to Unregistered User', { 'error': error });
  });
}

const fetchCompactShiftsInDateRange = (from, to, pageNum, perPageNum, includeNonPublicInventory) => (dispatch) => {
  dispatch(actions.requestShifts(true));
  return loadCompactShiftsInDateRange(from, to, pageNum, perPageNum, includeNonPublicInventory).then(({ data }) => {
    const payload = {
      shifts: data.schedule_elements,
      page: data.meta.pagination.current_page,
      startDate: from,
      endDate: to,
    };

    if (pageNum === 1) {
      dispatch(actions.receiveFirstPageShifts(payload));
    } else {
      dispatch(actions.appendShifts(payload));
    }
  }).catch((error) => {
    console.log(`loadCompactShiftsInDateRange failed. Error = ${error}`);
  });
};

const fetchScheduleUsers = (locationId, pageNo, numPerPage) => (dispatch) => {
  const allUserConfig = {
    location_id: locationId,
    page: pageNo,
    per_page: numPerPage,
    with_deleted: true,
  };

  if( typeof(locationId) == 'undefined' || locationId == null){
    console.log('fetchScheduleUsers Error - locId:' + locationId);
    return;
  }

  const start = moment();
  dispatch(actions.requestAllUsers(true));
  return teamCalls.getScheduleUsers(allUserConfig).then((response) => {

    const payload = {
      users: response.data.user_privileges,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if (allUserConfig.page === 1) {
      dispatch(actions.receiveAllUsers(payload));
    } else {
      dispatch(actions.appendAllUsers(payload));
    }

  }).catch((error) => {
    console.log(`fetAllUsers error: ${error}`);
    dispatch(actions.resetAllUsers());
  });

};

const linkUnregisteredUser = (unregistered_user_id, target_user_id, rangeStart, rangeEnd) => (dispatch) => {
  return LinkUnregisteredUserSchedule(unregistered_user_id, target_user_id).then((response) => {
    dispatch(fetchCompactShiftsInDateRange(rangeStart, rangeEnd, 1, SHIFTS_PER_PAGE, true));
    Mixpanel.track('Unregistered user linked', { 'unregistered_user_id': unregistered_user_id, 'target_user_id': target_user_id });
  }).catch((error) => {
    Mixpanel.error('link unregistered user', { 'unregistered_user_id': unregistered_user_id, 'target_user_id': target_user_id });
  });
};

// ------ Draft Mode Operations -------
const removePublishErrorOperation = () => (dispatch)  => {
  dispatch(actions.removePublishError());
};

const deleteDraftShiftOperation = (scheduleId, locationId) => (dispatch) => {
  console.log(scheduleId + ': ' + locationId);
  dispatch(actions.changeStatusToDeleting(true));
  return DeleteDraftShiftCall(scheduleId, locationId).then(({ data }) => {
    dispatch(actions.deleteSingleDraftShift({scheduleId: scheduleId, locationId: locationId}));

  }).catch((error) => {
    Mixpanel.error('Delete Shift', { 'error': error });
  });
};

const addDraftOpenShiftToUserOnDateOperation = (userId, startDateTime, endDateTime, jobTitle, locationId, channelId, shiftDescription, quantity) => (dispatch) => {
  dispatch(actions.changeStatusToAdding(true));
  return AddDraftOpenShiftToUserCall(userId, startDateTime, endDateTime, jobTitle, locationId, channelId, shiftDescription, quantity).then(({ data }) => {
    const payload = {
      shifts: data.schedule_element,
    };

    dispatch(actions.appendSingleDraftShift(payload));
    dispatch(actions.finishAdding());
  }).catch((error) => {
    dispatch(actions.finishAdding());
    Mixpanel.error('Add Draft Shift to User', { 'error': error });
  });
}

// const UpdateDraftOpenShiftOperation = (shiftId, targetStartTime, targetEndTime, jobTitle, channelId, shiftDescription, quantity) => (dispatch) => {
//   dispatch(actions.changeStatusToAdding(true));
//   return UpdateDraftOpenShiftCall(shiftId, targetStartTime, targetEndTime, jobTitle, channelId, quantity, shiftDescription).then(({ data }) => {
//     const payload = {
//       shifts: data.schedule_element,
//     };
//     dispatch(actions.upsertSingleShift(payload));
//     dispatch(actions.finishAdding());
//   }).catch((error) => {
//     dispatch(actions.finishAdding());
//     Mixpanel.error('Update Draft Open Shift', { 'error': error });
//   });
// }

const addDraftShiftToUserOnDateOperation = (userId, startDateTime, endDateTime, jobTitle, locationId) => (dispatch) => {
  dispatch(actions.changeStatusToAdding(true));
  return AddDraftShiftToUserCall(userId, startDateTime, endDateTime, jobTitle, locationId).then(({ data }) => {
    const payload = {
      shifts: data.schedule_element,
    };

    dispatch(actions.appendSingleDraftShift(payload));
    dispatch(actions.finishAdding());
  }).catch((error) => {
    dispatch(actions.finishAdding());
    Mixpanel.error('Add Draft Shift to User', { 'error': error });
  });
};

const addDraftShiftToUnregisteredUserOnDateOperation = (unregUserId, startDateTime, endDateTime, jobTitle, locationId) => (dispatch) => {
  dispatch(actions.changeStatusToAdding(true));
  return AddDraftShiftToUnregisteredUserCall(unregUserId, startDateTime, endDateTime, jobTitle, locationId).then(({ data }) => {
    const payload = {
      shifts: data.schedule_element,
    };

    console.log(payload);
    dispatch(actions.appendSingleDraftShift(payload));

  }).catch((error) => {
    Mixpanel.error('Add Draft Shift to Unregistered User', { 'error': error });
  });
};

const publishDraftShiftOperation = (shiftId, total) => (dispatch) => {
  return PublishDraftShiftCall(shiftId).then(({ data }) => {
    const payload = {
      shift: data.schedule_element,
    };

    dispatch(actions.updateSingleShift(payload));
    dispatch(actions.incrementPublishCount());
  }).catch((error) => {
    Mixpanel.error('Publish Draft Shift', { 'error': error });
    dispatch(actions.setPublishError());
    dispatch(actions.incrementPublishCount());
  }); 
}

const revertAllDraftChangesOperation = () => (dispatch) => {
  dispatch(actions.changeStatusToDeleting);
  return RevertAllDraftChangesCall().then(({data}) => {

  });
}

const moveDraftShiftOperation = (shiftId, targetRegisteredUser, targetUnregisteredUser, targetStartTime, targetEndTime) => (dispatch) => {
  return UpdateDraftShiftCall(shiftId, targetRegisteredUser, targetUnregisteredUser, targetStartTime, targetEndTime, null).then(({ data }) => {
    const payload = {
      shift: data.schedule_element,
    };

    console.log(payload);
    dispatch(actions.updateSingleShift(payload));

  }).catch((error) => {
    Mixpanel.error('Move Draft Shift', { 'error': error });
    dispatch(actions.setPublishError());
  }); 
}

const updateDraftShiftOperation = (shiftId, targetStartTime, targetEndTime, jobTitle) => (dispatch) => {
  return UpdateDraftShiftCall(shiftId, null, null, targetStartTime, targetEndTime, jobTitle).then(({ data }) => {
    const payload = {
      shift: data.schedule_element,
    };

    console.log(payload);
    dispatch(actions.updateSingleShift(payload));

  }).catch((error) => {
    Mixpanel.error('Update Draft Shift', { 'error': error });
  }); 
}

/** Published OS Operation */
const updatePublishedOpenShiftOperation = (shiftId, totalAvailable, content, position) => (dispatch) => {
  return UpdatePublishedOpenShiftCall(shiftId, totalAvailable, content, position).then(({data}) => {
    const payload = {
      shift: data.schedule_element,
    };
    dispatch(actions.updateSingleShift(payload));
  }).catch((error) => {
    Mixpanel.error('Update Open Shift', {'error': error});
  });
}

/** Applicants Operations */
const fetchApplicantsForShiftOperation = (shiftId) => (dispatch) => {
  return getShiftApplicants(shiftId).then((response) => {
    const payload = {
      applicants: response.data.applicants,
      shiftId: shiftId
    };
    dispatch(actions.hydrateApplicants(payload));
  }).catch((error) => {
    console.log(`fetchApplicantsForShift error: ${error}`);
    dispatch(actions.resetApplicants());
  });
}

const approveApplicantOperation = (shiftId, applicantId) => (dispatch) => 
  PickApplicant(shiftId, applicantId).then(({ data }) => {
      const payload = { shift: data.schedule_element, };
      dispatch(actions.updateSingleShift(payload));
      dispatch(fetchSingleApplicantOperation(applicantId));
  }).catch ((error) =>{
    console.log(error);
  });

const denyApplicantOperation = (shiftId, applicantId) => (dispatch) =>
  DenyApplicant(shiftId, applicantId).then(({ data }) => {
      
  }).catch((error) =>{
    //console.log(error);
  });

const fetchSingleShiftOperation = (shiftId) => (dispatch) => {
  return GetShift(shiftId).then( ({data}) => {
    const payload = { shift: data.schedule_element, };
    dispatch(actions.upsertSingleShift(payload));
  }).catch( (error) => {
    console.error(error);
  });
}

const fetchSingleApplicantOperation = (applicantId) => (dispatch) => {
  return RefreshSingleShiftApplicant(applicantId).then( ({ data }) => {
    const payload = { applicant: data.applicant, };
    dispatch(actions.upsertSingleApplicant(payload));
  }).catch((error) => {
    console.log(error);
  });
}

/** Applicant Comments / Free Posts */
const fetchApplicantPostsOperation = (applicantId) => (dispatch) => 
  LoadApplicantPosts(applicantId).then(({ data }) => {
    const payload = {
      applicant_id: applicantId,
      posts: data.posts,
    };
    dispatch(actions.hydrateApplicantComments(payload));
  }).catch((error) => {
    console.log(error);
  });

const addApplicantCommentOperation = (applicantId, content) => (dispatch) => {
  return CreateApplicantFreePost(applicantId, content).then(({ data }) => {
    const payload = {
      applicant_id: applicantId,
      comment: data.post,
    }; 
    dispatch(actions.appendApplicantComment(payload));
  }).catch((error) => {
    console.log(error);
  });
}

const fetchSingleFreePostOperation = (postId) => (dispatch) => {
  return getOnePost(postId).then(({ data }) => {
    const payload = {
      applicant_id: data.post.postable_id,
      post: data.post,
    };
    dispatch(actions.upsertApplicantComment(payload));
  }).catch((error) => {
    console.log(error);
  });

}

export default {
  changeDateRangeOperation,
  fetchCompactShiftsInDateRange,
  fetchScheduleUsers,
  linkUnregisteredUser,
  addShiftToUserOnDateOperation,
  deleteShiftOperation,
  addShiftToUnregisteredUserOnDateOperation,
  notifyTeamOperation,
  addDraftShiftToUserOnDateOperation,
  addDraftShiftToUnregisteredUserOnDateOperation,
  deleteDraftShiftOperation,
  revertAllDraftChangesOperation,
  publishDraftShiftOperation,
  moveDraftShiftOperation,
  updateDraftShiftOperation,
  // UpdateDraftOpenShiftOperation,
  flagForResetOperation,
  clearResetFlagOperation,
  removePublishErrorOperation,
  addDraftOpenShiftToUserOnDateOperation,
  fetchApplicantsForShiftOperation,
  approveApplicantOperation,
  denyApplicantOperation,
  fetchApplicantPostsOperation,
  addApplicantCommentOperation,
  fetchSingleShiftOperation,
  fetchSingleApplicantOperation,
  updatePublishedOpenShiftOperation,
  fetchSingleFreePostOperation,
};
