import { connect } from 'react-redux';

import AnnouncementsComponent from './AnnouncementsComponent';

import {
  teamFeedActions,
  teamFeedOperations,
  teamFeedSelectors,
} from '../../duck';

const mapStateToProps = (state) => {
  const {
    appReducer,
    teamFeedReducer,
    locationReducer,
    setupReducer,
  } = state;

  return {
    //comments: teamFeedSelectors.getOrderedComments(teamFeedReducer),
    posts: teamFeedSelectors.getOrderedPosts(teamFeedReducer),
    //activePost: teamFeedSelectors.getActivePost(teamFeedReducer),
    userId: setupReducer.setup_actions.meta.current_user.id,
    active: teamFeedReducer.active,
    locationSettings: appReducer.location.settings,
    locationId: locationReducer.location_code,
    locations: locationReducer.locations,
    activeChannel: teamFeedSelectors.getActiveChannel(state),
    allUsers: appReducer.allUsers.users,
    onlineUsers: appReducer.onlineUsers.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  const fetchPostLikeList = (post_id) => {
    dispatch(teamFeedOperations.getPostLikeList(post_id));
  };

  const fetchPostReaderList = (post_id) => {
    dispatch(teamFeedOperations.getPostReaderList(post_id));
  };

  const resetActiveSidePane = () => {
    dispatch(teamFeedActions.updateActiveSidePane(''));
  };

  return {
    fetchPostLikeList,
    fetchPostReaderList,
    resetActiveSidePane,
  };
};

const AnnouncementsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementsComponent);


export default AnnouncementsContainer;
