import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { createApiDateFormat, getDefaultTimezone, } from 'helpers/date-time';

const channelsSlice = createSlice({
  name: 'channels',
  initialState: {
    data: [],
    loading: true,
    receiveNewPost: false,
    lastUpdatedChannelId: 0,
  },
  reducers: {
    hydrateChannels: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    requestChannels: (state) => {
      state.loading = true;
    },
    updateLatestContent: (state, action) => {
      const indexToUpdate = state.data.findIndex(
        (channel) => channel.id === action.payload.id
      );

      state.data[indexToUpdate].channel_latest_content = action.payload.content;
    },
    appendChannel: (state, action) => {
      //This could be the cause of WS-2005
      // state.data = state.data.concat(action.payload);
      // state.loading = false;
      const newData = [...state.data, action.payload];
      state.data = newData;
      state.loading = false;
    },
    removeChannel: (state, action) => {
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        newData.splice(idx, 1);
        state.data = newData;
      }
    },
    addUsersToChannel: (state, action) => {
      const idx = state.data.findIndex((dept) => dept.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        //const newIds = newData[idx].user_ids.concat(action.payload.user_ids);
        newData[idx].member_count += 1;
        state.data = newData;
      }
    },
    updateChannelLastContent: (state, action) => {
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload.channel_id);
      if(idx >= 0) {
        const newData = [...state.data];
        newData[idx].channel_latest_content = action.payload.content;
        if(action.payload.updated_at !== undefined) {
          newData[idx].updated_at = action.payload.updated_at;
        }
        state.data = newData;
        state.lastUpdatedChannelId = action.payload.channel_id;
      }
    },
    NotifyNewPost: (state, action) => {
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload);
      if(idx >= 0) {
        const newData = [...state.data];
        newData[idx].receiveNewPost = true;
        state.data = newData;
      }
    },
    ResetNotifyNewPost: (state, action) => {
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload);
      if(idx >= 0) {
        const newData = [...state.data];
        newData[idx].receiveNewPost = false;
        state.data = newData;
      }
    },
    updateChannelInfo: (state, action) => {
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload.channel_id);
      if(idx >= 0) {
        const newData = [...state.data];
        newData[idx].channel_name = action.payload.channel_name;
        newData[idx].description = action.payload.description;
        state.data = newData;
      }
    },
    resetChannels: (state, action) => {
      state.data = [];
    },
    updateActiveChannelAdmin: (state, action) => {
      //state.userIsAdmin = action.payload.is_admin;
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload.channel.id);
      if(idx >= 0) {
        const newData = [...state.data];
        newData[idx].userIsAdmin = action.payload.is_admin;
        state.data = newData;
      }
    },
  },
});

export default channelsSlice;
