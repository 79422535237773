import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import Modal from 'components/GeneralComponents/Modal';
import constants from '../../../constants';
import styled from './styled';
import { getSingleShiftApplicant } from 'helpers/api-calls/shift-calls';
import { shiftDuration, shiftDurationInMin } from '../../../../../helpers/date-time';
class UserCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: false,
      mouseOverBottomLine: false,
      bottomLinePos: null,
      coverer: null,
      displayParentApplicantCount: 0,
    };

    this.bottomLineRef = React.createRef();
    this.oneItemRef = React.createRef();
  }

  componentDidMount() {
    const { user, shift, locationId } = this.props;
    const isShift = shift.time_off_reason === undefined;
    if (isShift) {
      this.fetchUserInfo();
      if (shift && shift.parent_card && shift.cover_mode === 'multi_applicants_picked_by_manager') {
        let tempCount = (shift.current_applicants_approved_user_ids.length +
          shift.current_applicants_denied_user_ids.length +
          shift.current_applicants_pending_user_ids.length);
        if (tempCount > 0) {
          this.setState({ displayParentApplicantCount: tempCount });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { user, shift } = this.props;
    const isShift = shift.time_off_reason === undefined;
    if (isShift && prevProps.user !== user) {
      this.fetchUserInfo();
    }
  }

  componentWillUnmount() {
    this.cancelFetch = true;
  }

  fetchUserInfo = () => {
    const { user, shift, locationId } = this.props;
    if (user && user.applicantId && user.applicantId > 0 && user.first_name === '') {
      getSingleShiftApplicant(shift.id, user.applicantId)
        .then((response) => {
          if (!this.cancelFetch) {
            this.handleSucceeded(response.data);
          }
        })
        .catch((error) => {
          if (user.last_name.length === 2 && user.last_name.includes('#')) {
            const coverer = {
              id: 0,
              applicantId: user.applicantId,
              first_name: '-',
              last_name: '-',
              profile_image: '/icons/default-profile-thumb.svg',
            };
            this.setState({ coverer });
          }
          console.error(`UserCell fetchUserInfo failed: ${error}`);
        });
    }
  };

  handleSucceeded = (data) => {
    console.log(data.applicant);
    const coverer = {
      applicantId: data.applicant.id,
      first_name: data.applicant.user.first_name,
      last_name: data.applicant.user.last_name,
      thumb_url: data.applicant.user.thumb_url || '/icons/default-profile-thumb.svg',
      badge_id: data.applicant.badge_id,
      available: data.applicant.available,
      position: data.applicant.user.current_position,
    };
    this.setState({ coverer });
  };

  render() {
    const { user, shift } = this.props;
    const { debug, coverer, displayParentApplicantCount } = this.state;
    const approvedBadge = '/icons/verified-badge-icon.svg';
    const isShift = shift.time_off_reason === undefined;

    if (isShift) {
      if (!user) {
        if (displayParentApplicantCount > 0) {
          return (
            <styled.TopWrap>
              <styled.OwnerInfoWrap>
                <styled.OwnerInfoTopLine>
                  {<styled.UserName>{("+" + displayParentApplicantCount + " applicants")}</styled.UserName>}
                </styled.OwnerInfoTopLine>
              </styled.OwnerInfoWrap>
            </styled.TopWrap>
          );
        } else {
          return <styled.TopWrap />
        }
      };

      const tempUser = coverer || user;
      const userImageUrl = tempUser.thumb_url ? tempUser.thumb_url : (tempUser.profile_image?.thumb_url || '/icons/default-profile-thumb.svg');
      const userName = `${tempUser.first_name} ${tempUser.last_name}`;
      // Check if the userName contains '#a', '#p', or '#d'
      const shouldDisplayUserName = !userName.includes('#a') && !userName.includes('#p') && !userName.includes('#d');

      const bottomLineFirst = tempUser.position || '';
      const bottomLineSecond = tempUser.badge_id || '';
      const bottomLineThird = '';
      // const bottomLineThird = shift ? `${shiftDurationInMin(shift.start_at, shift.end_at).toFixed(2)} hrs` : '';
      const bottomLineFourth = tempUser.available || '';
      console.log("available - ", bottomLineFourth);
      return (
        <styled.TopWrap>
          {shouldDisplayUserName && (
            <>
              <styled.OwnerAvatar src={userImageUrl} rounded />
              <styled.OwnerInfoWrap>
                <styled.OwnerInfoTopLine>
                  {<styled.UserName>{userName}</styled.UserName>}
                  {tempUser.is_admin && <styled.ApprovedBadge src={approvedBadge} />}
                </styled.OwnerInfoTopLine>
                {debug ? tempUser.owner_id : (
                  <styled.BottomLineWrap>
                    {bottomLineFirst && <styled.BottomLineText>{bottomLineFirst}</styled.BottomLineText>}
                    {bottomLineFirst && bottomLineSecond && <styled.Dot />}
                    {bottomLineSecond && <styled.BottomLineText>{bottomLineSecond}</styled.BottomLineText>}
                    {(bottomLineFirst || bottomLineSecond) && bottomLineThird && <styled.Dot />}
                    {bottomLineThird && <styled.BottomLineText>{bottomLineThird}</styled.BottomLineText>}
                    {(bottomLineFirst || bottomLineSecond || bottomLineThird) && bottomLineFourth && <styled.Dot />}
                    {bottomLineFourth && <styled.BottomLineText>{bottomLineFourth ? "Available" : ''}</styled.BottomLineText>}
                  </styled.BottomLineWrap>
                )}
              </styled.OwnerInfoWrap>
            </>)}
        </styled.TopWrap>
      );
    }
    else {
      const userImageUrl = shift && shift.profile_image_thumb_url ? shift.profile_image_thumb_url : '/icons/default-profile-thumb.svg';
      const userName = shift && `${shift.first_name} ${shift.last_name}`;
      const bottomLineFirst = shift && shift.user_privilege_position || '';
      const bottomLineSecond = shift && shift.user_privilege_badge_id || '';
      const bottomLineThird = '';//shift ? `${(shift.day_hours * shift.days).toFixed(2)} hrs` : ''; //Should be time off balance

      return (
        <styled.TopWrap>
          <styled.OwnerAvatar src={userImageUrl} rounded />
          <styled.OwnerInfoWrap>
            <styled.OwnerInfoTopLine>
              {<styled.UserName>{userName}</styled.UserName>}
              {shift.is_admin && <styled.ApprovedBadge src={approvedBadge} />}
            </styled.OwnerInfoTopLine>
            {debug ? shift.user_id : (
              <styled.BottomLineWrap>
                {bottomLineFirst && <styled.BottomLineText>{bottomLineFirst}</styled.BottomLineText>}
                {bottomLineFirst && bottomLineSecond && <styled.Dot />}
                {bottomLineSecond && <styled.BottomLineText>{bottomLineSecond}</styled.BottomLineText>}
                {(bottomLineFirst || bottomLineSecond) && bottomLineThird && <styled.Dot />}
                {bottomLineThird && <styled.BottomLineText>{bottomLineThird}</styled.BottomLineText>}
              </styled.BottomLineWrap>
            )}
          </styled.OwnerInfoWrap>
        </styled.TopWrap>
      );
    }
  }
}

UserCell.propTypes = {
  shift: PropTypes.object,
};

export default injectIntl(UserCell);