
import React, { useState, useCallback } from 'react'
import DraggableShiftCell from './DraggableShiftCell';
import moment from 'moment';
import { useDrop } from 'react-dnd';
import utils from 'pages/ScheduleCalendar/duck/utils';
import { Mixpanel } from '../../../../../Mixpanel';

export function DroppableTableCell ({ handleTooltipShow, handleTooltipHide, user, date, shiftsForDay, selectedDateToAddShift, handleOpenPopup, copyShiftToUserAndDateDispatch, isCopyingShift, shiftToCopy, moveDraftShiftDispatch, allUsers }) {

  const [isHoverButtonVisible, setIsHoverButtonVisible] = useState(false);

  const dropItem = (item) => {
    moveDraftShiftDispatch(item, user, date);
    Mixpanel.track('Moved shift');
  }

  const mouseEnterCell = (e) => {
    setIsHoverButtonVisible(true); 
  }

  const mouseExitCell = (e) => {
    setIsHoverButtonVisible(false); 
  }

  const [{canDrop, isOver}, drop] = useDrop(() => ({
    accept: 'ShiftCell',

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),

    canDrop: (item, monitor) => {
      if( item != null){
        if( false == date.isSame(moment(item.start_at)) || user.owner_id != utils.currentOwnerIdForShift(item) ) {
          return true;
        }
      }
      return false;
    },
  
    drop: (item,monitor) => {
      dropItem(item);
    },

  }), [date]
  
)

  const shiftsCellForDay = useCallback((user, date, shifts, selectedDateToAddShift, handleOpenPopup, allUsers, handleTooltipHide, handleTooltipShow) => {

    if (shifts.length > 0) {
      // Sort the shifts by start_at time in descending order
      const sortedShifts = shifts.sort((a, b) => moment(b.start_at).isBefore(moment(a.start_at)));

      return sortedShifts.map(shift => (
        <DraggableShiftCell
          key={shift.id}
          user={user}
          date={date}
          shifts={[shift]} // Passing single shift in an array
          handleOpenShiftDetail={handleOpenPopup}
          selectedDateToAddShift={selectedDateToAddShift}
          allUsers = {allUsers}
          allUserShiftsOnDay = {sortedShifts}
          handleTooltipHide = {handleTooltipHide}
          handleTooltipShow = {handleTooltipShow}
        />
      ));
    } else {
      return (
        <DraggableShiftCell
          user={user}
          date={date}
          selectedDateToAddShift={selectedDateToAddShift}
          isCopyingShift={isCopyingShift}
          shiftToCopy={shiftToCopy}
          copyShiftToUserAndDateDispatch={copyShiftToUserAndDateDispatch}
        />
      );
    }
  }, [handleOpenPopup, copyShiftToUserAndDateDispatch, isCopyingShift, shiftToCopy, handleTooltipHide, handleTooltipShow ]);

  const addButtonClicked = (item) => {
      selectedDateToAddShift(user, date);
  } 

  const showDottedFrame = (isOver == true && canDrop == true);

  return (
    <td className={shiftsForDay.length> 0 ? "shift-row__day-cell top-align" : "shift-row__day-cell middle-align"} ref={drop}  onMouseOverCapture={mouseEnterCell} onMouseLeave={mouseExitCell}>
      {shiftsCellForDay(user, date, shiftsForDay, selectedDateToAddShift, handleOpenPopup, allUsers, handleTooltipHide, handleTooltipShow)}
      {isHoverButtonVisible && (shiftsForDay.length > 0) && <button className="hover-add" onMouseDownCapture={addButtonClicked}>Add</button>}
      { showDottedFrame && <div className='dotted-drop-frame'></div>}
    </td>
  );
};

export default DroppableTableCell;