import React from 'react';
import { connect } from 'react-redux';
import SettingsPage from './SettingsPage';
import { settingsOperations } from './duck';

const mapStateToProps = ({
  settingsReducer
}) => {
  return {
    showToast: settingsReducer.settings.showToast,
  };
};

const mapDispatchToProps = (dispatch) => {

  const stopToastDispatch = () => {
    dispatch(settingsOperations.stopShowingToastOperation());
  };

  return { 
    stopToastDispatch
  };
};


const SettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {
    showToast,
    stopToastDispatch,
    match} = props;

  return <SettingsPage 
    showToast = {showToast}
    stopToastDispatch = {stopToastDispatch}
    match= {match}
   /> 
});

export default SettingsContainer;