import moment from 'moment';
import 'helpers/findIndex-shim.js';
import { connect } from 'react-redux';
import CalendarPage from './CalendarPage';
import { calendarOperations } from './duck';
import { PubNubConsumer } from 'pubnub-react';
import React from 'react';
import {
  changeDateRange,
} from '../../../actions/calendar-actions';

const findInFilters = (status, filters) => {
  return filters.findIndex((element) => {
    return status == element;
  });
};

const findInFilters1 = (shift, filters) => {
  if (shift.cover_mode === 'default') {
    if (filters.includes(shift.trade_status)) {
      return shift;
    } else {
      return null;
    }
  } else {
    if (shift.cover_mode === 'multi_applicants_picked_by_manager') {
      if (filters.includes(shift.trade_status) ||
        shift.trade_status === 'posted' ||
        shift.trade_status === 'approved') {
        return shift;
      } else {
        return null;
      }
    }
  }
};

const sortShifts = (shifts) => {
  return shifts.sort((a, b) => {
    return moment(b.start_at).isBefore(moment(a.start_at));
  });
};

const filterAndSortShifts = (shifts, typeFilters, dayFilters, stateFilters) => {
  if (
    typeFilters.length === 0 &&
    dayFilters.length === 0 &&
    stateFilters.length === 0
  )
    return sortShifts(shifts);

  const newTypeFilters = [];

  for (let i = 0; i < typeFilters.length; i++) {
    newTypeFilters.push(typeFilters[i]);
    if (typeFilters[i] === 'shift') {
      newTypeFilters.push('shift_inventory');
    }
  }

  const filteredShifts = shifts.filter((element) => {
    //if(element.cover_mode === 'default') {
    if (
      newTypeFilters.length > 0 &&
      findInFilters(element.name, newTypeFilters) === -1
    ) {
      return false;
    }

    if (
      dayFilters.length > 0 &&
      findInFilters(moment(element.start_at).day(), dayFilters) === -1
    ) {
      return false;
    }
    /*
    if (
      stateFilters.length > 0 &&
      findInFilters(element.trade_status, stateFilters) === -1
    ) {
      return false;
    }
    */

    if (stateFilters.length > 0) {
      const value = findInFilters1(element, stateFilters);
      if (value) {
        return value;
      } else {
        return false;
      }
    }

    return element;
    //}
  });

  return sortShifts(filteredShifts);
};

const mapStateToProps = ({ calendarReducer, locationReducer, appReducer, }) => {
  const { locations, location_data, location_code, } = locationReducer;
  const {
    loading,
    shifts,
    typeFilters,
    dayFilters,
    stateFilters,
    currentDateRangeStart,
    currentDateRangeEnd,
    lastCount,
  } = calendarReducer;
  const { skills, } = appReducer;

  return {
    location: locations.length > 1 ? location_data : locations[0],
    loading,
    locationSettings: appReducer.location.settings,
    locationId: location_code,
    shifts: filterAndSortShifts(shifts, typeFilters, dayFilters, stateFilters)
      .map((element) => {
        if (element.trade_status === 'rejected') {
          return { ...element, trade_status: 'denied' };
        }
        if (element.trade_status === 'posted') {
          return { ...element, trade_status: 'open' };
        }
        return element;
      })
      .sort((a, b) => {
        return new Date(a.start_at) - new Date(b.start_at);
      }),
    skills: appReducer.skills.data,
    stateFilters: stateFilters,
    dateStart: currentDateRangeStart,
    dateEnd: currentDateRangeEnd,
    lastCount,
    dayFilters,
    typeFilters,
    plans: appReducer.plans.data,
    locationData: locationReducer.location_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  refreshWeek: (from, to) => {
    dispatch(changeDateRange(from, to));
  },
  getLocationUsableSkills: (locationId) =>
    dispatch(calendarOperations.getLocationUsableSkills(locationId)),
  getMoreCalendarShifts: (start, end, pageNum, perPageNum) =>
    dispatch(calendarOperations.getMoreCalendarShifts(start, end, pageNum, perPageNum)),

});

//const CalendarPageContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarPage);

const CalendarPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  return (
    <PubNubConsumer>
      {(client) => {
        const { location,
          loading,
          locationSettings,
          locationId,
          shifts,
          skills,
          stateFilters,
          getLocationUsableSkills,
          getMoreCalendarShifts,
          refreshWeek,
          dateStart,
          dateEnd,
          lastCount,
          dayFilters,
          typeFilters,
          plans,
          locationData,
        } = props;

        return (
          <CalendarPage
            pubnub={client}
            location={location}
            loading={loading}
            locationSettings={locationSettings}
            locationId={locationId}
            shifts={shifts}
            skills={skills}
            stateFilters={stateFilters}
            getLocationUsableSkills={getLocationUsableSkills}
            refreshWeek={refreshWeek}
            getMoreCalendarShifts={getMoreCalendarShifts}
            dateStart={dateStart}
            dateEnd={dateEnd}
            lastCount={lastCount}
            dayFilters={dayFilters}
            typeFilters={typeFilters}
            plans={plans}
            locationData={locationData}
          />
        );
      }}
    </PubNubConsumer>
  );
});

export default CalendarPageContainer;
