import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import { dashboardShapes } from '../duck';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import RecentRequest from './RecentRequest';
import RequestTimeOff from './RequestTimeOff';
import { getShiftApplicants, } from 'helpers/api-calls/shift-calls';
import { PENDING_SHIFTS_PER_PAGE, MAX_LOADING_TIMES } from 'constants/index';

import styled from './styled';
import constants from 'pages/RequestsV3/constants';
import Summary from 'pages/RequestsV3/components/Summary/Summary';
import Header from 'pages/RequestsV3/components/Header/Header';
import UserTable from 'pages/RequestsV3/components/UserTable/UserTable';
import utils from 'pages/RequestsV3/duck/utils';
import filterUtil from 'pages/RequestsV3/duck/filter';
import { Mixpanel } from '../../../Mixpanel';
import { cloneDeep } from 'lodash';


class RecentRequestsV3Component extends React.Component {
  constructor(props) {
    super(props);
    const { categoryTypes } = constants;
    this.state = {
      validShifts: [],
      shiftsApplicants: [],
      currentLocationId: '',
      loadingData: false,
      timer: null,
      sortedRTOs: [],

      selectedType: categoryTypes.REQUESTS,
      selectedIndex: 0,
      selectedItem: null,
      selectedSort: null,
      filteredResults: [],
      selectedShifts: [],
      searchTerm: '',
      ascending: true,
      tableSize: null,
      actionInProgress: false,
      userActionDone: false,
      filterByDepartment: null,
      filterByStatus: null,
      filterByWeekday: null,
      filterByEmail: null,
      filterByEmployeeId: null,
      filterByGroup: null,
      filterByJobTitle: null,
      filterBySkill: null,
    }

    this._isMounted = false;
    this.removeList = [];
    this.calledList = [];
    this.calledFetchPendingShifts = false;
    this.timeOutCount = 0;
    this.startTime = moment();
    this.endTime = moment();
    this.pageIndex = 1;

  }

  componentDidMount() {
    this._isMounted = true;
  }

  populateTimeOffRequests() {
    const { locationSettings,
      requestTimeOffs,
      locationId
    } = this.props;

    if (locationSettings.cfg__departments === 1) {
      let rtos = [];
      for (let j = 0; j < requestTimeOffs.length; j++) {
        if (requestTimeOffs[j].location_id === locationId) {
          if (this.isTheRTOInMyDepartments(requestTimeOffs[j])) {
            rtos.push(requestTimeOffs[j]);
          }
        }
      }
      this.sortRTOWithNullValues(rtos, true);
      this.setState({ sortedRTOs: rtos });
    } else {
      let rtos = [];
      for (let j = 0; j < requestTimeOffs.length; j++) {
        if (requestTimeOffs[j].location_id === locationId) {
          rtos.push(requestTimeOffs[j]);
        }
      }
      this.sortRTOWithNullValues(rtos, true);
      this.setState({ sortedRTOs: rtos });
    }
  }


  componentDidUpdate(prevProps) {
    const {
      pendingShifts,
      fetchPendingShifts,
      fetchMorePendingShifts,
      locationId,
      requestTimeOffs,
      userPrivileges,
      deletedGroupId,
    } = this.props;
    const { currentLocationId, } = this.state;

    if (currentLocationId !== locationId) {
      this.calledFetchPendingShifts = false;
      this.setState({ currentLocationId: locationId });
    }

    if (!this.calledFetchPendingShifts) {
      fetchPendingShifts();
      this.calledFetchPendingShifts = true;
      this.calledList = [];
      this.pageIndex = 1;
    }

    if (prevProps.requestTimeOffs !== requestTimeOffs) {
      this.populateTimeOffRequests();
    }

    if (prevProps.userPrivileges !== userPrivileges) {
      if (userPrivileges.length > 0) {
        this.populateTimeOffRequests();
      }
    }

    if (prevProps.pendingShifts !== pendingShifts) {
      const noDuplicateShifts = uniqBy(pendingShifts, 'id'); // Remove duplicates\
      if (noDuplicateShifts) {
        this.setState({ validShifts: [], filteredResults: [] });
        let shifts = [];

        for (let i = 0; i < noDuplicateShifts.length; i++) {
          if (this.isValidShiftToShow(noDuplicateShifts[i])) {
            shifts.push(noDuplicateShifts[i]);
          }
        }

        const output = this.getTotalRows(shifts);
        this.setState({ validShifts: output, filteredResults: output });
        // load more pending shifts if any, per page PENDING_SHIFTS_PER_PAGE, if new coming shifts length = PENDING_SHIFTS_PER_PAGE, means there are more to load
        let msg = '';
        if (pendingShifts.length > prevProps.pendingShifts.length) {
          if (pendingShifts.length - prevProps.pendingShifts.length === PENDING_SHIFTS_PER_PAGE && this.pageIndex < MAX_LOADING_TIMES) {
            msg = `Dash: last page: ${this.pageIndex}, loaded ${pendingShifts.length - prevProps.pendingShifts.length} shifts this time. Total ${pendingShifts.length}. There might be more to load.`;
            this.pageIndex += 1;
            fetchMorePendingShifts(this.pageIndex, PENDING_SHIFTS_PER_PAGE);
          } else {
            if (this.pageIndex === 1) {
              msg = `Dash: last page: ${this.pageIndex}, Total ${pendingShifts.length}, loaded ${pendingShifts.length - prevProps.pendingShifts.length} shifts this time. Less than ${PENDING_SHIFTS_PER_PAGE}. Try page 2.`;
              this.pageIndex += 1;
              fetchMorePendingShifts(this.pageIndex, PENDING_SHIFTS_PER_PAGE);
            } else {
              msg = `Dash: last page: ${this.pageIndex}, Total ${pendingShifts.length}, loaded ${pendingShifts.length - prevProps.pendingShifts.length} shifts this time. Less than ${PENDING_SHIFTS_PER_PAGE}. No more loading.`;
            }
          }
        } else if (pendingShifts.length === prevProps.pendingShifts.length) {
          if (this.pageIndex === 1) {
            msg = `Dash: loaded shifts ${pendingShifts.length} are the same as previous shifts. Try to load page 2.`;
            this.pageIndex += 1;
            fetchMorePendingShifts(this.pageIndex, PENDING_SHIFTS_PER_PAGE);
          } else {
            msg = `Dash: loaded page ${this.pageIndex}, shifts: ${pendingShifts.length}, no increasing. No more loading.`;
          }
        } else {
          if (this.pageIndex === 1) {
            this.pageIndex += 1;
            fetchMorePendingShifts(this.pageIndex, PENDING_SHIFTS_PER_PAGE);
            msg = `Dash: loaded shifts ${pendingShifts.length} less than previous shifts ${prevProps.pendingShifts.length}. Could be location changed or page changed. Try to load page 2.`;
          } else {
            msg = ``;
          }
        }

        if (this.pageIndex === MAX_LOADING_TIMES) {
          msg = `Dash: max loading time ${MAX_LOADING_TIMES} reached. Stop loading.`;
          this.stopLoading = true;
        }

        console.log(msg);
      }
    }

    if (prevProps.deletedGroupId !== deletedGroupId) {
      const { validShifts } = this.state;

      let localCopy = [...validShifts];
      if (localCopy && localCopy.length > 0) {
        for (let i = 0; i < localCopy.length; i++) {
          if (localCopy[i].channel_id === deletedGroupId) {
            localCopy.splice(i, 1);
            i -= 1;
          }
        }
        console.log(localCopy.length);
        this.setState({ validShifts: localCopy });
      }
    }
  }

  componentWillUnmount() {
    const { timer } = this.state;
    this._isMounted = false;
    if (timer) {
      clearInterval(this.state.timer);
    }
  }

  getTotalRows = (rows) => {
    const { stateFilters, userPrivilegesMap } = this.props;
    let totalRows = [];
    let c0 = 0;
    let c1 = 0;
    let c2 = 0;
    let c3 = 0;
    if (rows && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].cover_mode === 'default') {
          totalRows.push(rows[i]);
          c0 += 1;
        } else {
          if (rows[i].cover_mode === 'multi_applicants_picked_by_manager') {
            //No applicant yet, show parent shift as 'Posted'
            if (rows[i].current_applicants_pending_ids.length === 0 &&
              rows[i].current_applicants_denied_ids.length === 0 &&
              rows[i].current_applicants_approved_ids.length === 0 &&
              rows[i].current_applicants_nominated_ids.length === 0) {
              if (stateFilters && stateFilters.length > 0) {
                if (stateFilters.includes('posted')) {
                  totalRows.push(rows[i]);
                  c2 += 1;
                }
              } else {
                totalRows.push(rows[i]);
                c2 += 1;
              }
            }
            else if (rows[i].current_applicants_approved_ids.length === 1 && rows[i].name === 'shift') {
              if (stateFilters && stateFilters.length > 0) {
                if (stateFilters.includes('approved')) {
                  totalRows.push(rows[i]);
                  c2 += 1;
                }
              } else {
                totalRows.push(rows[i]);
                c2 += 1;
              }
            }
            else {
              // most of the other VTO and OPEN parents cards
              let children = [];

              if (rows[i].current_applicants_approved_ids.length > 0) {
                //for team shift need to do this, not for open shift because a child card created for each approved applicant
                for (let j = 0; j < rows[i].current_applicants_approved_ids.length; j++) {
                  let copyRow = { ...rows[i] }
                  if (copyRow.coverer === null) {
                    const userId = copyRow.current_applicants_approved_user_ids[j];
                    const applicantId = copyRow.current_applicants_approved_ids[j];
                    let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                    coverer.applicantId = applicantId;
                    coverer.last_name = '#a'; //use this variable to pass applicant status
                    copyRow.coverer = coverer;
                  } else {
                    if (rows[i].name === 'shift') {
                      const userId = copyRow.current_applicants_approved_user_ids[j];
                      const applicantId = copyRow.current_applicants_approved_ids[j];
                      let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                      coverer.applicantId = applicantId;
                      coverer.last_name = '#a'; //use this variable to pass applicant status
                      copyRow.coverer = coverer;
                    }
                  }
                  if (stateFilters && stateFilters.length > 0) {
                    if (stateFilters.includes('approved')) {
                      children.push(copyRow);
                      c2 += 1;
                    }
                  } else {
                    children.push(copyRow);
                    c2 += 1;
                  }
                }
              }

              //removed condition (stateFilters.includes('pending') || stateFilters.includes('posted')
              if (rows[i].current_applicants_pending_ids.length > 0) {
                for (let j = 0; j < rows[i].current_applicants_pending_ids.length; j++) {
                  let copyRow = { ...rows[i] }
                  if (copyRow.coverer === null) {
                    const userId = copyRow.current_applicants_pending_user_ids[j];
                    const applicantId = copyRow.current_applicants_pending_ids[j];
                    let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                    coverer.applicantId = applicantId;
                    coverer.last_name = '#p'; //use this variable to pass applicant status
                    copyRow.coverer = coverer;
                  } else {
                    if (rows[i].name === 'shift') {
                      const userId = copyRow.current_applicants_pending_user_ids[j];
                      const applicantId = copyRow.current_applicants_pending_ids[j];
                      let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                      coverer.applicantId = applicantId;
                      coverer.last_name = '#p'; //use this variable to pass applicant status
                      copyRow.coverer = coverer;
                    }
                  }
                  if (stateFilters && stateFilters.length > 0) {
                    if (stateFilters.includes('pending')) {
                      children.push(copyRow);
                      c2 += 1;
                    }
                  } else {
                    children.push(copyRow);
                    c2 += 1;
                  }
                }
              }
              //removed condition  stateFilters.includes('rejected')
              if (rows[i].current_applicants_denied_ids.length > 0) {
                for (let j = 0; j < rows[i].current_applicants_denied_ids.length; j++) {
                  let copyRow = { ...rows[i] }
                  if (copyRow.coverer === null) {
                    const userId = copyRow.current_applicants_denied_user_ids[j];
                    const applicantId = copyRow.current_applicants_denied_ids[j];
                    let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                    coverer.applicantId = applicantId;
                    coverer.last_name = '#d'; //use this variable to pass applicant status
                    copyRow.coverer = coverer;
                  } else {
                    if (rows[i].name === 'shift') {
                      const userId = copyRow.current_applicants_denied_user_ids[j];
                      const applicantId = copyRow.current_applicants_denied_ids[j];
                      let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                      coverer.applicantId = applicantId;
                      coverer.last_name = '#d'; //use this variable to pass applicant status
                      copyRow.coverer = coverer;
                    }
                  }
                  if (stateFilters && stateFilters.length > 0) {
                    if (stateFilters.includes('rejected')) {
                      children.push(copyRow);
                      c2 += 1;
                    }
                  } else {
                    children.push(copyRow);
                    c2 += 1;
                  }
                }
              }
              totalRows.push({
                ...rows[i],
                children,
              });
            }
          }
        }
      }
    }
    console.log('totalRows: ', totalRows);
    return totalRows;
  }

  tick = () => {
    const { loadingData } = this.state;
    if (loadingData) {
      this.timeOutCount += 1;
      //if unexpected happens, reset the flag in 30 seconds.
      if (this.timeOutCount > 30) {
        this.timeOutCount = 0;
        this.setState({ loadingData: false });
        const msg = `loadingData set to false by timer.`;
        console.log(msg);
        clearInterval(this.state.timer);
      }
    }
  }

  hasSegments = (id) => {
    const { pendingShifts, } = this.props;
    const { validShifts, } = this.state;

    //const shift = pendingShifts.find((item) => item.id === id);
    const shift = validShifts.find((item) => item.id === id);

    return Object.prototype.hasOwnProperty.call(shift, 'segments');
  };

  isTheShiftInMyDepartments = (shift) => {
    const { departments, myDepartments, } = this.props;

    if (myDepartments && myDepartments.length > 0) {
      for (let i = 0; i < myDepartments.length; i++) {
        if (myDepartments[i].user_ids && myDepartments[i].user_ids.length > 0) {
          for (let j = 0; j < myDepartments[i].user_ids.length; j++) {
            if (shift.taker_id === myDepartments[i].user_ids[j]) {
              //const msg = `Shift id: ${shift.id}, taker Id: ${shift.taker_id} in departments`;
              //console.log(msg);
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  isTheRTOInMyDepartments = (rto) => {
    const { departments, myDepartments, userPrivileges, } = this.props;
    if (myDepartments && myDepartments.length > 0) {
      for (let i = 0; i < myDepartments.length; i++) {
        if (myDepartments[i].user_ids && myDepartments[i].user_ids.length > 0) {
          for (let j = 0; j < myDepartments[i].user_ids.length; j++) {
            if (rto.user_id === myDepartments[i].user_ids[j]) {
              //admin and RTO requester in the same dept
              if (userPrivileges.length > 0) {
                for (let k = 0; k < userPrivileges.length; k++) {
                  if (userPrivileges[k].owner_id === rto.user_id) {
                    if (userPrivileges[k].department_ids && userPrivileges[k].department_ids.length === 0) {
                      // case 4: admin dept yes, RTO requester dept none
                      return false;
                    } else if (userPrivileges[k].department_ids.length > 0) {
                      //case 1:  admin and RTO requester belong to same department and request dept yes
                      return true;
                    }
                  }
                }
              }
            }
          }
          //case 2, 4: admin dept yes, RTO requester is not in admin dept
          //return false;
          continue;
        }
        //case 2, 4: admin dept yes, RTO requester is not in admin dept
        return false;
      }
    } else {
      if (userPrivileges.length > 0) {
        for (let k = 0; k < userPrivileges.length; k++) {
          if (userPrivileges[k].owner_id === rto.user_id) {
            if (userPrivileges[k].department_ids && userPrivileges[k].department_ids.length === 0) {
              //case 3: both admin and requester do not belong to any department
              return true;
            } else if (userPrivileges[k].department_ids.length > 0) {
              //case 5: admin dept none, requester dept yes
              //const msg = `Current user no department but RTO requester ${rto.first_name} ${rto.last_name} belongs to department`;
              //console.log(msg);
              return true;
            }
          }
        }
        return false;
      } else {
        return false;
      }
    }
  }

  isValidShiftToShow = (shift) => {
    const { locationSettings, } = this.props;

    //if cover mode = 2 (multi_applicants_picked_by_manager), rendering shifts regardless trade status
    if (shift.cover_mode === 'multi_applicants_picked_by_manager' ||
      (shift.cover_mode !== 'multi_applicants_picked_by_manager' && shift.trade_status === 'pending')) {
      const startDateTime = moment(shift.start_at);
      let current = moment();
      let duration = moment.duration(startDateTime.diff(current));
      let minutes = parseInt(duration.asMinutes());
      //if starting time is less than 5 min from now, consider it is expired
      if (minutes < 5) {
        //const msg = `It is ${current}, shift ${shift.id} start time is ${startDateTime}, ${minutes} mins left.`;
        //console.log(msg);
        return false;
      }
      if (!shift.parent_card) {
        //Fixing bug WS-1499
        /* Waiting for decision 2022.01.06
         that function as design, once a shift is approved, which is kind of in locked status, 
         no more applicant actions will be accepted.  
         @Etty Burstein can you verify with design team, is it necessary for manger to deny other applicant,
         when a team shift(single slot) was approved?
         */
        //if(shift.trade_status === 'approved' || shift.trade_status === 'rejected') {
        if (shift.trade_status === 'rejected') {
          //const msg = `B * id: ${shift.id}, parent: ${shift.parent_card}, status: ${shift.trade_status}`;
          //console.log(msg);
          return false;
        }
        if (!shift.coverer) {
          if (shift.cover_mode !== 'multi_applicants_picked_by_manager') {
            //const msg = `No coverer. id: ${shift.id}, parent: ${shift.parent_card}, status: ${shift.trade_status}`;
            //console.log(msg);
            return false;
          }
        }
      }
      //const msg = `Pass * id: ${shift.id}, mode: ${shift.cover_mode} parent: ${shift.parent_card}, status: ${shift.trade_status}`;
      //console.log(msg);
      return true;
    } else {
      //const msg = `A * id: ${shift.id}, mode: ${shift.cover_mode}, status: ${shift.trade_status}`;
      //console.log(msg);
      return false;
    }
  };

  getApplicantsForShift = (shiftId) => {
    const { shiftsApplicants } = this.state;
    if (shiftsApplicants && shiftsApplicants.length) {
      const idx = shiftsApplicants.findIndex((element) => element.id === shiftId);
      if (idx < 0) {
        return null;
      }
      return shiftsApplicants[idx];
    }
    return null;
  }

  sortRTOWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof (ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function (a, b) {
      if (a.start_date === b.start_date) // identical? return 0
        return 0;
      else if (a.start_date === null)  // a is null? last 
        return 1;
      else if (b.start_date === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        //return a.user.first_name.localeCompare(b.user.first_name) * multiplier;
        return new Date(a.start_date) - new Date(b.start_date)
      }
    }
    return arr.sort(sorter);
  }

  createCategoryItems = () => {
    const { validShifts, sortedRTOs } = this.state;
    const noDuplicatedRTOs = uniqBy(sortedRTOs, 'id'); // Remove duplicates
    const combinedShiftsAndRTOs = [...validShifts, ...noDuplicatedRTOs]; // Combine shifts and RTOs

    // Initialize counts for different categories
    let totalCount = 0;
    let openShiftsCount = 0;
    let teamShiftsCount = 0;
    let vtoShiftsCount = 0;
    let rtosCount = 0;

    // Go through each item to count categories
    combinedShiftsAndRTOs.forEach(item => {
      if (item) {
        totalCount++; // Count total items

        if (item.time_off_reason === undefined) { // It's a shift
          if (item.name === 'open_shift') {
            openShiftsCount++; // Count open shifts
          } else if (item.name === 'shift') {
            teamShiftsCount++; // Count team shifts
          } else if (item.name === 'shift_vto') {
            vtoShiftsCount++; // Count voluntary time off
          }
        } else { // It's an RTO
          rtosCount++; // Count time off requests (RTOs)
        }
      }
    });

    // Prepare the output items
    const requestItems = [
      { name: <FormattedMessage id="Requests.all" defaultMessage="All" />, value: totalCount },
      { name: <FormattedMessage id="Requests.openShifts" defaultMessage="Open Shifts" />, value: openShiftsCount },
      { name: <FormattedMessage id="Requests.teamShifts" defaultMessage="Team Shifts" />, value: teamShiftsCount },
      { name: <FormattedMessage id="Requests.vtos" defaultMessage="Voluntary Time Off" />, value: vtoShiftsCount },
      { name: <FormattedMessage id="Requests.rtos" defaultMessage="Time Off Requests" />, value: rtosCount },
    ];

    return [{ id: 'requests', items: requestItems }];
  }

  createSummaryElements = (categoryItems) => {
    const { selectedType, } = this.state;
    const { categoryTypes } = constants;
    if (selectedType === categoryTypes.REQUESTS) {
      const idxL = categoryItems.findIndex((el) => el.id === 'requests');
      if (idxL >= 0) {
        return categoryItems[idxL].items;
      }
    }
  };

  summaryItemSelected = (index, item) => {
    const { selectedType, } = this.state;
    const { categoryTypes } = constants;

    this.setState({ selectedItem: item, selectedIndex: index, selectedShifts: [] });
    const output = this.filterShifts(selectedType, item, index);
    this.setState({ filteredResults: output });
  };

  filterShifts = (type, item, index) => {
    const { categoryTypes } = constants;
    const { validShifts, sortedRTOs } = this.state;

    // Determine the total shifts based on the type
    let total = (type === categoryTypes.REQUESTS) ? cloneDeep(validShifts) : [];

    // Remove duplicates from sortedRTOs
    const noDuplicatedRTOs = uniqBy(cloneDeep(sortedRTOs), 'id');
    // Combine valid shifts and unique RTOs
    const combinedShiftsAndRTOs = [...total, ...noDuplicatedRTOs];

    if (!type) {
      return combinedShiftsAndRTOs;
    }

    // Initialize filtered shifts
    let filtered = [];

    // Use a single loop to filter based on type and index
    combinedShiftsAndRTOs.forEach(idxObject => {
      if (idxObject) {
        const isShift = idxObject.time_off_reason === undefined;
        if (type === categoryTypes.REQUESTS) {
          if (index === 0) {
            filtered.push(idxObject); // All shifts
          } else if (index === 1 && isShift && idxObject.name === 'open_shift') {
            filtered.push(idxObject); // Open shifts
          } else if (index === 2 && isShift && (idxObject.name === 'shift' || idxObject.name === 'shift_inventory')) {
            filtered.push(idxObject); // Team shifts
          } else if (index === 3 && isShift && idxObject.name === 'shift_vto') {
            filtered.push(idxObject); // Voluntary time off
          } else if (index === 4 && !isShift) {
            filtered.push(idxObject); // Time off requests (RTOs)
          }
        }
      }
    });

    console.log('Filtered: filterShifts', filtered);
    return filtered;
  };

  notifyShiftSelected = (shifts, selected) => {
    const { selectedShifts } = this.state;
    console.log("selectedShifts before- ", selectedShifts);
    console.log("Received shifts - ", shifts);
    // Ensure shifts is always an array
    const shiftsArray = Array.isArray(shifts) ? shifts : [shifts];

    if (selected) {
      // If selected is true, add shifts to selectedShifts
      const newSelectedShifts = shiftsArray.filter(shift => {
        const idx = selectedShifts.findIndex(item => item.id === shift.id);
        return idx < 0; // Only keep shifts that are not already selected
      });

      console.log("notifyShiftSelected newSelectedShifts - ", newSelectedShifts);
      // Update the selectedShifts state
      this.setState(prevState => ({
        selectedShifts: [...prevState.selectedShifts, ...newSelectedShifts]
      }));
    } else {
      // If selected is false, remove shifts from selectedShifts
      const updatedSelectedShifts = selectedShifts.filter(existingShift => {
        return !shiftsArray.some(shift => shift.id === existingShift.id);
      });

      // Update the selectedShifts state
      this.setState({ selectedShifts: updatedSelectedShifts });
    }
  };

  notifyAllShiftsSelected = (selected) => {
    if (selected) {
      const allShiftsToSelect = this.visibleShifts();
      const newSelectedShifts = allShiftsToSelect.map(shift => {

        const isShift = shift.time_off_reason === undefined;

        if (!isShift) {
          return shift;
        } else if ((shift.name === 'shift' && shift.cover_mode === 'multi_applicants_picked_by_manager')) {
          //ToDo handle 2.0 team shift
          //notifyShiftSelected(shift, isChecked);
          return null;
        } else if (!(shift.parent_card || (!shift.parent_card && shift.cover_mode === 'multi_applicants_picked_by_manager'))) {
          return shift;
        } else if (shift.children && shift.children.length > 0) {
          return shift.children.map(child => child);
        }
        return null;
      }).flat().filter(Boolean);


      this.setState({ selectedShifts: newSelectedShifts })
    } else {
      this.setState({ selectedShifts: [] });
    }
  }

  visibleShifts = () => {
    const { selectedSort, ascending, filterBySkill, filterByDepartment, filterByStatus, filterByWeekday, filterByGroup, filterByJobTitle, filterByEmail, filterByEmployeeId, searchTerm, selectedType, selectedItem, selectedIndex } = this.state;
    const shiftsOfCurrentCategory = this.filterShifts(selectedType, selectedItem, selectedIndex);
    const shiftsWithFiltersApplied = filterUtil.applyFiltersToShifts(shiftsOfCurrentCategory, filterByDepartment, filterByStatus, filterByGroup, filterByJobTitle, filterByEmail, filterByEmployeeId, filterBySkill, filterByWeekday, true);
    // const foundUsers = filterUtil.searchUsers(searchTerm, usersWithFiltersApplied);
    const sortedShifts = utils.sortShifts(shiftsWithFiltersApplied, selectedSort?.name, ascending);
    return sortedShifts;
  }


  notifyActionDone = (tableSize) => {
    this.setState({ tableSize: tableSize, actionInProgress: false });
  }

  processShiftAction = (shift, actionType, configId) => {
    const {
      approvePendingShift,
      denyPendingShift,
      approveShiftApplicant,
      denyShiftApplicant,
    } = this.props;
    const { userActionTypes } = constants;

    if (shift.parent_card || (!shift.parent_card && shift.cover_mode === 'multi_applicants_picked_by_manager')) {
      console.log("Processing applicant action - ", shift);
      if (shift.coverer && shift.coverer.last_name === '#p' && shift.coverer.applicantId) {
        if (actionType === userActionTypes.APPROVE || actionType === userActionTypes.APPROVE_RTO) {
          return approveShiftApplicant(shift.id, shift.coverer.applicantId);
        } else if (actionType === userActionTypes.DENY || actionType === userActionTypes.DENY_RTO) {
          return denyShiftApplicant(shift.id, shift.coverer.applicantId);
        }
      }
    } else {
      console.log("Processing shift action - ", shift);
      if (actionType === userActionTypes.APPROVE || actionType === userActionTypes.APPROVE_RTO) {
        approvePendingShift(shift, configId);
        Mixpanel.track('APPROVE shift');
      } else if (actionType === userActionTypes.DENY || actionType === userActionTypes.DENY_RTO) {
        denyPendingShift(shift, configId);
        Mixpanel.track('DENY shift');
      }
    }
    return Promise.resolve(); // Ensure consistency by returning a resolved promise
  };

  processRtoAction = (shift, actionType) => {
    const { approveRequestTimeOff, denyRequestTimeOff } = this.props;
    const { userActionTypes } = constants;

    if (shift.status === 'requested') {
      Mixpanel.track(actionType === userActionTypes.APPROVE ? 'Approve RTO' : 'Deny RTO');
      if (actionType === userActionTypes.APPROVE || actionType === userActionTypes.APPROVE_RTO) {
        return approveRequestTimeOff(shift.id);
      } else if (actionType === userActionTypes.DENY || actionType === userActionTypes.DENY_RTO) {
        return denyRequestTimeOff(shift.id);
      }
    }
    return Promise.resolve(); // Ensure consistency by returning a resolved promise
  };

  handleRequest = (shifts, actionType) => {
    const { locationConfig } = this.props;
    const configId = locationConfig?.custom_text ? locationConfig.id : null;

    const promises = shifts.map((shift) => {
      const isShift = shift.time_off_reason === undefined;
      return isShift
        ? this.processShiftAction(shift, actionType, configId)
        : this.processRtoAction(shift, actionType);
    });

    return Promise.all(promises);
  };

  notifyActionRequested = (request, actionType) => {
    const { selectedShifts } = this.state;

    this.setState({ userActionDone: false, processedShifts: [], actionInProgress: false });

    if (!request) {
      this.setState({ selectedShifts: [] });
      return;
    }

    this.setState({ actionInProgress: true });

    this.handleRequest(selectedShifts, actionType)
      .then(() => {
        this.setState({ actionInProgress: false, selectedShifts: [] });
      })
      .catch((error) => {
        console.error('Error processing shifts:', error);
      })
      .finally(() => {
        this.setState({ actionInProgress: false, selectedShifts: [] });
      });
  };

  notifyInlineActionRequested = (request, actionType, shift) => {
    this.setState({ userActionDone: false, processedShifts: [], actionInProgress: false });

    if (!request) {
      this.setState({ selectedShifts: [] });
      return;
    }

    this.setState({ actionInProgress: true });

    const shifts = [shift]; // Wrap single shift into an array for reuse
    this.handleRequest(shifts, actionType)
      .then(() => {
        this.setState({ actionInProgress: false, selectedShifts: [] });
      })
      .catch((error) => {
        console.error('Error processing shift:', error);
      })
      .finally(() => {
        this.setState({ actionInProgress: false, selectedShifts: [] });
      });
  };


  notifyRefreshRequested = () => {

    const { loadingData, fetchPendingShifts, notifyRefreshRequested } = this.props;

    if (loadingData) {
      return;
    }
    notifyRefreshRequested();
    fetchPendingShifts();
  }

  notifyDropDownOpened = (opened) => {
    this.setState({ popupOpened: opened });
  }

  notifyFiltersSelected = (dept, status, email, employeeId, group, jobTitle, skill, weekday) => {
    this.setState({ filterByDepartment: dept, filterByStatus: status, filterByGroup: group, filterByJobTitle: jobTitle, filterByEmail: email, filterByEmployeeId: employeeId, filterBySkill: skill, filterByWeekday: weekday });
  }

  notifySortOrderChanged = (value) => {
    this.setState({ ascending: value });
  }

  notifySortTypeChanged = (type) => {
    this.setState({ selectedSort: type })
  }

  notifySearchTermChanged = (searchTerm) => {
    this.setState({ searchTerm: searchTerm });
  }

  searchUsers = (term, shifts) => {
    const { selectedType, selectedItem, selectedIndex, } = this.state;
    const { sortTypes, } = constants;
    let results = [];
    let isName = false;
    let isPosition = false;
    let isUserRole = false;
    let isStatus = false;
    if (term && term.length > 0) {
      const term1 = term.toLowerCase();
      if (term1.includes(' ')) {
        const strings = term1.split(' ');
        if (strings && strings.length === 2) {
          const fullName = shifts.filter((user) => user.first_name && user.first_name.toLowerCase().includes(strings[0]) &&
            user.last_name && user.last_name.toLowerCase().includes(strings[1]));
          if (fullName && fullName.length > 0) {
            results = fullName;
            isName = true;
          } else {
            const fullName1 = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(strings[1]) &&
              user.last_name && user.last_name.toLowerCase().includes(strings[0]));
            if (fullName1 && fullName1.length > 0) {
              results = fullName1;
              isName = true;
            } else {
              const foundInPosition = users.filter((user) => user.position &&
                (user.position.toLowerCase().includes(strings[0]) || user.position.toLowerCase().includes(strings[1])));
              if (foundInPosition && foundInPosition.length > 0) {
                results = foundInPosition;
                isPosition = true;
              }
              const foundInPosition1 = users.filter((user) => user.position &&
                (user.position.toLowerCase().includes(strings[1]) || user.position.toLowerCase().includes(strings[0])));
              if (foundInPosition && foundInPosition.length > 0) {
                results = results.concat(foundInPosition1);
                isPosition = true;
              }
              if (foundInPosition && foundInPosition.length === 0 && foundInPosition1 && foundInPosition1.length === 0) {
                if (strings[0] === 'team' && strings[1] === 'member') {
                  results = users.filter((user) => user.status && !user.is_admin);
                  isUserRole = true;
                } else if (strings[1] === 'team' && strings[0] === 'member') {
                  results = users.filter((user) => user.status && user.is_admin);
                  isUserRole = true;
                }
              }
            }
          }
        } else if (strings && strings.length > 2) {
          //Possiblly this is position
          let contains1Ward = [];
          let contains2Wards = [];
          let contains3Wards = [];
          for (let i = 0; i < strings.length; i++) {
            const found = users.filter((user) => user.position && user.position.toLowerCase().includes(strings[i]));
            contains1Ward = contains1Ward.concat(found);
          }
          if (contains1Ward.length > 0) {
            if (strings.length <= 3) {
              contains2Wards = contains1Ward.filter((user) => user.position && user.position.toLowerCase().includes(strings[1]));
              contains3Wards = contains2Wards.filter((user) => user.position && user.position.toLowerCase().includes(strings[2]));
            }
            if (contains3Wards && contains3Wards.length > 0) {
              results = contains3Wards;
            } else if (contains2Wards && contains2Wards.length > 0) {
              results = contains2Wards;
            } else {
              results = contains1Ward;
            }
            isPosition = true;
          }
        }
      } else {
        const firstNameContains = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(term1));
        if (firstNameContains && firstNameContains.length > 0) {
          results = firstNameContains;
          isName = true;
          const alsoInLastName = users.filter((user) => user.last_name && user.last_name.toLowerCase().includes(term1));
          if (alsoInLastName && alsoInLastName.length > 0) {
            results = results.concat(alsoInLastName);
          }
          const alsoInPosition = users.filter((user) => user.position && user.position.toLowerCase().includes(term1));
          if (alsoInPosition && alsoInPosition.length > 0) {
            results = results.concat(alsoInPosition);
            isPosition = true;
          }
          if (term1.toLowerCase() === 'admin') {
            results = users.filter((user) => user.status && user.is_admin);
            isName = false;
            isUserRole = true;
            isPosition = false;
          }
        } else {
          const lastNameContains = users.filter((user) => user.last_name && user.last_name.toLowerCase().includes(term1));
          if (lastNameContains && lastNameContains.length > 0) {
            results = lastNameContains;
            isName = true;
            const alsoInFirstName = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(term1));
            if (alsoInFirstName && alsoInFirstName.length > 0) {
              results = results.concat(alsoInFirstName);
            }
            if (term1.toLowerCase() === 'admin') {
              results = users.filter((user) => user.status && user.is_admin);
              isName = false;
              isUserRole = true;
            }
          } else {
            const positionContains = users.filter((user) => user.position && user.position.toLowerCase().includes(term1));
            if (positionContains && positionContains.length > 0) {
              results = positionContains
              isPosition = true;
            } else {
              const statusContains = users.filter((user) => user.status && user.status.toLowerCase().includes(term1));
              if (statusContains && statusContains.length > 0) {
                results = statusContains;
                isStatus = true;
              } else {
                if (term1.toLowerCase() === 'admin') {
                  results = users.filter((user) => user.status && user.is_admin);
                  isUserRole = true;
                } else if (term1.toLowerCase().includes('team') || term1.toLowerCase().includes('member')) {
                  results = users.filter((user) => user.status && !user.is_admin);
                  isUserRole = true;
                } else {
                  if (term1.toLowerCase() === 'terminated') {
                    const terminated = users.filter((user) => user.status && user.status !== 'active' && user.status !== 'pending');
                    if (terminated && terminated.length > 0) {
                      results = terminated;
                      isUserRole = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
      const found = uniqBy(results, 'id'); // Remove duplicated
      let type = '';
      if (isName) {
        type = sortTypes.FIRST_NAME;
      }
      if (isPosition) {
        type = sortTypes.POSITION;
      }
      if (isStatus) {
        type = sortTypes.STATUS;
      }
      if (isUserRole) {
        type = sortTypes.USER_ROLE;
      }
      if (isName && isPosition) {
        type = sortTypes.FIRST_NAME;
      }
      const sorted = utils.sortUsers(found, type, true);
      return sorted;
    } else {
      const output = this.filterShifts(selectedType, selectedItem, selectedIndex);
      return output;
    }
  }

  render() {
    const { approvePendingShift, loading, denyPendingShift, fetchPendingShiftSegments, fetchShiftApplicants, toggleConfirmationSheet, locationConfig, totalRTOs, approveRequestTimeOff, denyRequestTimeOff, userPrivileges, userIsAdmin, locationSettings, skills, channels, costCenterData, } = this.props;
    const { currentLocationId, validShifts, filteredResults, selectedType, selectedIndex, selectedItem, selectedShifts, loadingData, sortedRTOs, } = this.state;


    const categoryItems = this.createCategoryItems();
    const summaryItems = this.createSummaryElements(categoryItems);

    // if (loadingData) {
    //   return (
    //     <styled.ShiftRequestsLoadingLayer>
    //       <img src="/loader.gif" height="75" width="75" alt="Loading" />
    //     </styled.ShiftRequestsLoadingLayer>
    //   );
    // }

    const foundShifts = this.visibleShifts();

    return (
      <div className="schedule-calendar__wrap">
        {/* <LoadingLayer loading={loadingData} /> */}
        {/* <Category
          categorySelected={this.categorySelected}
          notifyDropDownOpened={this.notifyDropDownOpened}
          selectedType={selectedType}
        /> */}

        <Summary
          loading={loading}
          summaryItems={summaryItems}
          selectedType={selectedType}
          parentSelectedIndex={selectedIndex}
          selectedShifts={selectedShifts}
          summaryItemSelected={this.summaryItemSelected}
        />

        <Header
          locationSettings={locationSettings}
          selectedType={selectedType}
          locationId={currentLocationId}
          shifts={validShifts}
          filteredResults={foundShifts}
          selectedShifts={selectedShifts}
          selectedItem={selectedItem}
          isRequests={true}
          userPrivileges={userPrivileges}
          notifyActionRequested={this.notifyActionRequested}
          notifyRefreshRequested={this.notifyRefreshRequested}
          notifySearchTermChanged={this.notifySearchTermChanged}
          notifySortTypeChanged={this.notifySortTypeChanged}
          notifyDropDownOpened={this.notifyDropDownOpened}
          notifyFiltersSelected={this.notifyFiltersSelected}
          notifySortOrderChanged={this.notifySortOrderChanged}
        />

        <UserTable
          loading={loading}
          locationId={currentLocationId}
          shifts={validShifts}
          isRequests={true}
          filteredResults={foundShifts}
          selectedShifts={selectedShifts}
          notifyInlineActionRequested={this.notifyInlineActionRequested}
          notifyActionRequested={this.notifyActionRequested}
          notifyAllShiftsSelected={this.notifyAllShiftsSelected}
          notifyActionDone={this.notifyActionDone}
          notifyShiftSelected={this.notifyShiftSelected}
          selectedType={selectedType}
          selectedIndex={selectedIndex}
          selectedItem={selectedItem}
          skills={skills}
          costCenterData={costCenterData}
        />
      </div>
    );
  }
}

RecentRequestsV3Component.propTypes = {
  approvePendingShift: PropTypes.func.isRequired,
  denyPendingShift: PropTypes.func.isRequired,
  fetchPendingShifts: PropTypes.func.isRequired,
  fetchPendingShiftSegments: PropTypes.func.isRequired,
  toggleConfirmationSheet: PropTypes.func.isRequired,
  pendingShifts: PropTypes.arrayOf(dashboardShapes.shiftShape).isRequired,
  locationConfig: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired,
  fetchShiftApplicants: PropTypes.func.isRequired,
};

RecentRequestsV3Component.defaultProps = {
  locationConfig: {},
};

export default injectIntl(RecentRequestsV3Component);
