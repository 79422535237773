import React  from "react";
import './TableHeaderComponent.scss';
import { FormattedMessage, } from 'react-intl';
import {Mixpanel} from '../../Mixpanel';

class SortableTableHeaderComponent extends React.Component {
    constructor(props) {
      super(props);
    }

    trackHeaderClick = (e) => {
        if( e === 0 ){
            Mixpanel.track('Header Click Team Members');
        }else{
            Mixpanel.track('Header Click Day ' + e);
        }
    }

    headerClicked = (e) => {
        const {handleSortableHeaderClick} = this.props;

        handleSortableHeaderClick(e);
        this.trackHeaderClick(e);
    }

    headerElement = (sortByIndex, item) => {
        const {sortByDayOfWeek} = this.props;

        return (
            <div className="calendar-table__header-title" onClick= {() => { this.headerClicked(sortByIndex) } }>
                <span className={ (sortByIndex==sortByDayOfWeek && sortByIndex>0) ? "weekday-header-selected" :"weekday-header"} >
                    {/* {item} {sortByIndex==sortByDayOfWeek && <img src='icons/sort_down.svg'/>} */}
                    {item} 
                </span>
            </div>
        );
    }

    render() {
        const { rangeStart, rangeEnd , shouldShowOpenShiftUI} = this.props;

        const employee = (<FormattedMessage
            id="ReadReceipts.headerLabel"
            defaultMessage="Team Members" />);
    
            if (rangeStart === null || rangeEnd === null) {
            return null;
            }
    
            const teamMembers = this.headerElement(0, shouldShowOpenShiftUI == true ? ' ' : employee);
    
            const clone = rangeStart.clone();
            const headerFormat = 'ddd, DD';
    
            const firstDate = clone.format(headerFormat);
            const firstItem = this.headerElement(1, firstDate);
              
            const secondDate = clone.clone().add(1, 'days').format(headerFormat);
            const secondItem = this.headerElement(2, secondDate);
               
            const thirdDate = clone.clone().add(2, 'days').format(headerFormat);
            const thirdItem = this.headerElement(3, thirdDate);
              
            const fourthDate = clone.clone().add(3, 'days').format(headerFormat);
            const fourthItem = this.headerElement(4, fourthDate);
               
            const fifthDate = clone.clone().add(4, 'days').format(headerFormat);
            const fifthItem = this.headerElement(5, fifthDate);
              
            const sixthDate = clone.clone().add(5, 'days').format(headerFormat);
            const sixthItem = this.headerElement(6, sixthDate);
               
            const lastDate = rangeEnd.format(headerFormat);
            const lastItem = this.headerElement(7, lastDate);
             
            let headerItems = [teamMembers, firstItem, secondItem, thirdItem, fourthItem, fifthItem, sixthItem, lastItem];
    
            const Headers = () => {
            const headers = headerItems.map((item, index) => {
                return (
                    <th key={index} className="calendar-table__header-title">
                        {item}
                    </th>
                    );
                });
                return headers;
            };
    
            return (
                <thead>
                    <tr className="shift-table__headers">
                    <Headers />
                    </tr>
                </thead>
            );
    }

}

export default SortableTableHeaderComponent;