import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';

import localeData from '../../public/locales/data.json';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/fr');
  require('@formatjs/intl-pluralrules/dist/locale-data/es');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/es');
}

const IntlContainer = ({ children, locale }) => {
  // Add supported i18n locales
  // addLocaleData([...en, ...es, ...fr]);

  // Split locales with a region code
  const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0];

  // Try full locale, try locale without region code, fallback to 'en'
  const messages =
    localeData[languageWithoutRegionCode] ||
    localeData[locale] ||
    localeData.en;
    /*
     "WeekdayDate.mon": "Lu. {date}",
  "WeekdayDate.tue": "Ma. {date}",
  "WeekdayDate.wed": "Mi. {date}",
  "WeekdayDate.thu": "Ju. {date}",
  "WeekdayDate.fri": "Vi. {date}",
  "WeekdayDate.sat": "Sa. {date}",
  "WeekdayDate.sun": "Do. {date}",

  "WeekdayDate.mon": "Lun. {date}",
  "WeekdayDate.tue": "Mar. {date}",
  "WeekdayDate.wed": "Mer. {date}",
  "WeekdayDate.thu": "Jeu. {date}",
  "WeekdayDate.fri": "Ven. {date}",
  "WeekdayDate.sat": "Sam. {date}",
  "WeekdayDate.sun": "Dim. {date}",

    */

  moment.updateLocale('en', {
      weekdaysShortarr: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      weekdaysShort: function (momentToFormat, format) {
         return this._weekdaysShortarr[momentToFormat.weekday()];
      }
   });

  // Modify Spanish locale with 12h time
  moment.updateLocale('es', {
    longDateFormat: {
      LT: 'h:mm A',
      LTS: 'h:mm:ss A',
      L: 'MM/DD/YYYY',
      LL: 'MMMM [de] D [de] YYYY',
      LLL: 'MMMM [de] D [de] YYYY h:mm A',
      LLLL: 'dddd, MMMM [de] D [de] YYYY h:mm A',
    },
    weekdaysShortarr: [ "Do.", "Lu.", "Ma.", "Mi.", "Ju.", "Vi.", "Sa."],
      weekdaysShort: function (momentToFormat, format) {
         return this._weekdaysShortarr[momentToFormat.weekday()];
      }

  });

  moment.updateLocale('fr', {
    longDateFormat : {
      LT : 'HH:mm',
      LTS : 'HH:mm:ss',
      L : 'DD/MM/YYYY',
      LL : 'D MMMM YYYY',
      LLL : 'D MMMM YYYY HH:mm',
      LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    weekdaysShortarr: [ "Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
    weekdaysShort: function (momentToFormat, format) {
       return this._weekdaysShortarr[momentToFormat.weekday()];
    }
  })

  // Globally set locale in moment.js
  moment.locale(languageWithoutRegionCode);

  const render = () => (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );

  // Check if browser supports native intl api
  if (!global.Intl) {
    // Add intl polyfill for browsers that don't support
    require('intl');
    require('intl/locale-data/jsonp/en.js');
    require('intl/locale-data/jsonp/es.js');
    require('intl/locale-data/jsonp/fr.js');
    return render();
  }

  return render();
};

IntlContainer.propTypes = {
  children: PropTypes.element.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ localeReducer }) => ({
  locale: localeReducer.locale,
});

export default connect(mapStateToProps, null)(IntlContainer);
