import React from 'react';
import '../ScheduleCalendar/components/Header/NotifyTeamComponent.scss';
import '../../App/fonts.scss';
import '../../App/colors.scss';
import '../../App/layout.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

import {Mixpanel} from '../../Mixpanel';

class AvailabilityReminderComponent extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      confirmButtonEnabled: true,
    };
  }

  handleCancel = (event) => {
    const { cancelModal } = this.props;
    cancelModal();
    Mixpanel.track('Cancel availability update reminder');
  }

  handleConfirm = (event) => {
    const { cancelModal, locationId, notifyTeamDispatch, message } = this.props;
    const {confirmButtonEnabled} = this.state;

    if( confirmButtonEnabled == false ){
      return;
    }

    cancelModal();

    if(message !== null){
      notifyTeamDispatch(locationId, message);
      Mixpanel.track('Confirm availability update reminder', {'location_id':locationId});
    }
  }

  render() {
    const {confirmButtonEnabled, notifyTeamDispatch } = this.state;

    const cancel = (<FormattedMessage id="Confirm.cancel" defaultMessage="Cancel" />);
    const confirm = (<FormattedMessage id="Confirm.confirm" defaultMessage="Confirm" />);
    const notifyTitleText = (<FormattedMessage id="Availability.reminderTitle" defaultMessage="Send Availability Update Reminder 📢" />);
    const notifyBodyText1 = (<FormattedMessage id="Availability.reminderBody1" defaultMessage="Send out a push notification to remind your team to update their availability. " />);
    const notifyBodyText2 = (<FormattedMessage id="Availability.reminderBody2" defaultMessage="Please use this responsibly and avoid overwhelming your team with repeated notifications in a short period. " />);
    const notifyBodyText3 = (<FormattedMessage id="Scheduling.notifyTeamBody3" defaultMessage="Thank you for being awesome! 🙌" />);

    return (
      <div className="notify-team-container">
        <label className="title averta-regular fs20px">{notifyTitleText}</label>
        <div className='body-text1'>{notifyBodyText1}</div>
        <div className='body-text'>{notifyBodyText2}</div>
        <div className='body-text'>{notifyBodyText3}</div>

        <div className="confirm-button-wrap">
          <button className="confirm-button-cancel averta-semibold fs14px" onClick={this.handleCancel}>{cancel}</button>
          <button className={confirmButtonEnabled ? "confirm-button averta-semibold fs14px button-enabled" : "confirm-button averta-semibold fs14px button-disabled"} style={{ outline: 0 }} onClick={this.handleConfirm}>{confirm}</button>
        </div>

      </div>
    );
  }
}


export default injectIntl(AvailabilityReminderComponent);