import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import * as XLSX from "xlsx";
import excelFile from './sample_template27.xlsx'
//import {useCallback} from 'react';
//import {useDropzone} from 'react-dropzone';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Modal from 'components/GeneralComponents/Modal';
//import ProgressBar from './ProgressBar';
import { UploadSchedulerFile, UploadSchedulerFile1, } from '../../../../helpers/api-calls/schedule-calls';
import { UploadImage, UploadPDF, } from '../../../../helpers/api-calls/feed-calls';
import Confirmation from './Confirmation';
import './UploadComponent.scss';
import Constant from '../../constants';

import {Mixpanel} from '../../../../Mixpanel';

const LoadingLayer = ({ loading }) => {
  // return (
  //   <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
  //     <img src="/loader.gif" alt="Loading gif" />
  //   </div>
  // );
  return (
    <div className={`upload--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class UploadComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: true,
      filesToUpload: [],
      goNext: false,
      statusInfo: '',
      wrongFileType: false,
      actionInProgress: false,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleReaderLoaded = this.handleReaderLoaded.bind(this);

    this.fileInput = React.createRef(null);

    this._isMounted = false;
  }

  componentDidMount() {
    const {
      locationId,
      getLocationEmployeesNew,
    } = this.props;

    getLocationEmployeesNew(locationId);

  }

  componentDidUpdate(prevProps, prevState) {
    const {
      locationId,
    } = this.props;

  }

  handleFileChange(event) {
    event.preventDefault();
    const MAX_NUM_FILES = 3;

    const maxFilesExceeded = (<FormattedMessage
      id="UploadComponent.maxFilesExceeded"
      defaultMessage="Only {maxFiles} are allowed to upload at one time. Please select less files."
      values={{ maxFiles: MAX_NUM_FILES }}
    />);

    if (event.target.files.length === 0) {
      //to ensure file input onChange is trigged if the same file is selected
      this.fileInput.current.value = '';
      return;
    }
    //allowed file types: csv, xlsx, xlsm
    // if (event.target.files[0].type !== 'text/csv' && 
    //   event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  &&
    //   event.target.files[0].type !== 'application/vnd.ms-excel.sheet.macroenabled.12'
    //   ) {
    if (!event.target.files[0].name.includes('.xlsx') && !event.target.files[0].name.includes('.csv')) {
      const fileType = event.target.files[0].type ? event.target.files[0].type : '';
      const wrongType = (<FormattedMessage
        id="UploadComponent.wrongType"
        defaultMessage="File type {type} is not accepted. Only 'csv/xlsx' can be uploaded. "
        values={{ type: fileType }}
      />);
      this.setState({ statusInfo: wrongType, wrongFileType: true });
      this.setState({ filesToUpload: [] });
      //to ensure file input onChange is trigged if the same file is selected
      this.fileInput.current.value = '';
      return;
    }
    if (event.target.files.length > MAX_NUM_FILES) {
      alert(maxFilesExceeded);
      //to ensure file input onChange is trigged if the same file is selected
      this.fileInput.current.value = '';
      return;
    }

    let localCopy = [...this.state.filesToUpload];
    localCopy.push(event.target.files[0]);
    this.setState({ filesToUpload: localCopy });
    //to ensure file input onChange is trigged if the same file is selected
    this.fileInput.current.value = '';

  }

  handleButtonClick(event) {
    this.fileInput.current.click();
    this.setState({ filesToUpload: [] });
    this.setState({ statusInfo: '' });
  }

  handleSubmit(event) {
    const { filesToUpload, } = this.state;

    this.setState({ goNext: true });
    event.preventDefault();
    Mixpanel.track('Show confirm modal');
  }

  closeModalWindow = () => {
    this.setState({ goNext: false });
  }

  cancelUpload = () => {
    const { filesToUpload, } = this.state;
    const { handleFileUploaded } = this.props;

    this.setState({ filesToUpload: [] });
    this.setState({ statusInfo: '' });
    handleFileUploaded(false);
    this.closeModalWindow();

    Mixpanel.track('Cancel import');
  }

  confirmUpload = () => {
    const { filesToUpload, } = this.state;
    const { locationId, handleFileUploaded, nofityScheduleUploaded, } = this.props;

    const uploadOK = (
      <FormattedMessage
        id="UploadComponent.uploadOK"
        defaultMessage="Uploading file {name} completed."
        values={{ name: filesToUpload[0].name }}
      />
    );
    const uploadfailed = (
      <FormattedMessage
        id="UploadComponent.uploadFailed"
        defaultMessage="Uploading file {name} failed."
        values={{ name: filesToUpload[0].name }}
      />
    );

    //const valid = this.validateFile(filesToUpload[0]);

    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      //console.log(rABS, wb);
      const json = XLSX.utils.sheet_to_json(ws);
      for(let i = 0; i < json.length; i++) {
          let obj = json[i];
          obj["end_at"] = ExcelDateToJSDate(obj["end_at"]);
          obj["start_at"] = ExcelDateToJSDate(obj["start_at"]);
      }

      let shifts = JSON.stringify({"shifts":json});
      //console.log(shifts);
      //console.log(filesToUpload[0].name);
      let fileName = filesToUpload[0].name;
      
      this.setState({actionInProgress: true});
      UploadSchedulerFile1(shifts, locationId, fileName).then((data) =>{
        this.setState({actionInProgress: false});
        if(data.ok && data.status === 201) {
          this.setState({statusInfo: uploadOK});
          handleFileUploaded(true, uploadOK);
          nofityScheduleUploaded(filesToUpload[0].name);
        }else {
          this.setState({statusInfo: uploadfailed});
          handleFileUploaded(false, uploadfailed);
        }
      }).catch((error) =>{
        this.setState({statusInfo: uploadfailed});
        handleFileUploaded(false, uploadfailed);
      });
      this.setState({filesToUpload: []});
      this.closeModalWindow();
    };
    if (rABS) reader.readAsBinaryString(filesToUpload[0]);
    else reader.readAsArrayBuffer(filesToUpload[0]);


  }

  handleDrop = (event) => {
    console.log('dropping');
  }
  handleDropEnd = (event) => {
    console.log('drop end');
  }

  validateFile = (file) => {
    const reader = new FileReader()
    reader.onload = this.handleReaderLoaded;
    reader.readAsText(file)

    return file;
  }

  handleReaderLoaded(event) {
    const { locationId, handleFileUploaded, nofityScheduleUploaded, } = this.props;
    const { uploadOK, uploadfailed, filesToUpload, } = this.state;

    const text = (event.target.result);
    const shifts = this.createShiftsFromString(text);
   

    const shift1 = {
      first_name: "Daniel",
      last_name: "Chen",
      start_at: "2023-11-29 11:12",
      end_at: "2023-11-29 17:23",
      cached_primary_job: "Picker"
    };

    const shift2 = {
      first_name: "Yinnan",
      last_name: "Chen",
      start_at: "2023-11-29 11:12",
      end_at: "2023-11-29 17:23",
      cached_primary_job: "Picker"
    };

    //const shifts = [shift1, shift2];
    console.log(shifts);


    
    UploadSchedulerFile1(shifts, locationId).then((data) =>{
      if(data.ok && data.status === 201) {
        this.setState({statusInfo: uploadOK});
        handleFileUploaded(true, uploadOK);
        nofityScheduleUploaded(filesToUpload[0].name);
      }else {
        this.setState({statusInfo: uploadfailed});
        handleFileUploaded(false, uploadfailed);
      }
    }).catch((error) =>{
      this.setState({statusInfo: uploadfailed});
      handleFileUploaded(false, uploadfailed);
    });
    this.setState({filesToUpload: []});
    this.closeModalWindow();
  }

  render() {
    const { filesToUpload, goNext, statusInfo, wrongFileType, actionInProgress, } = this.state;

    const submit = moment();
    const submittedTime = `${submit.format('MMM DD, YYYY')}`;
    const uploadScheduler = (<FormattedMessage
      id="UploadComponent.uploadScheduler"
      defaultMessage="Step 1: Upload Scheduler"
    />);
    const buttonUpload = (<FormattedMessage
      id="UploadComponent.addFile"
      defaultMessage="Upload"
    />);

    const buttonPublish = (<FormattedMessage
      id="UploadComponent.submit"
      defaultMessage="Publish"
    />);

    const dragDrop = (<FormattedMessage
      id="UploadComponent.dragDrop"
      defaultMessage="Drap & Drop File"
    />);

    const template = (<FormattedMessage
      id="UploadComponent.template"
      defaultMessage="Download, complete, and upload the Shyft Scheduler Import template"
    />);

    const supportedFileType = (<FormattedMessage
      id="UploadComponent.supportedFileType"
      defaultMessage="Supported file types: csv, xlsx"
    />);

    const importTemplate = (<FormattedMessage
      id="UploadComponent.importTemplate"
      defaultMessage="Shyft Scheduler Import Template.xlsx"
    />);

    const publishSchedule = (<FormattedMessage
      id="UploadComponent.publishSchedule"
      defaultMessage="Step 2: Publish Schedule"
    />);

    const enabledButtonClassName = "upload__button-submit";
    const disabledButtonClassName = "upload__button-submit-disabled";

    return (
      <div className="upload__wrap">
        <div className="upload__header-wrap" onDrop={this.handleDrop} onDropEnd={this.handleDropEnd}>
          <p className="upload__step-1">{uploadScheduler}</p>
        </div>
        <div className="upload__main-body">
          <img className="upload__drag-drop-image" src="/icons/add-file-icon.svg" />
          <label className="upload__drag-drop">{dragDrop}</label>
          <label className="upload__download">{template}</label>
          <label className="upload__supported">{supportedFileType}</label>
          {wrongFileType && <label className="upload__wrong-file-type">{statusInfo}</label>}
          <div>
            <input className="upload__file-input"
              type="file"
              id="file-choose"
              multiple={false}
              ref={this.fileInput}
              onChange={this.handleFileChange}
            />
            <button className="upload__button-input" onClick={this.handleButtonClick}>
              {buttonUpload}
            </button>
          </div>
        </div>
        <div className="upload__file-link-wrap">
          <img className="upload__file-link-image" src="/icons/excel-icon.png" />
          {/* <a className="upload__file-link-text" href="https://miniobackingstore.blob.core.windows.net/public-files/import_schedule_template_min.csv" >{importTemplate}</a> */}
          <a className="upload__file-link-text" href="https://miniobackingstore.blob.core.windows.net/public-files/import_template.xltx" >{importTemplate}</a>
        </div>
        <p className="upload__step-2">{publishSchedule}</p>
        {filesToUpload.length === 1 ? <button className={enabledButtonClassName} onClick={this.handleSubmit}>{buttonPublish}</button> :
          <button className={disabledButtonClassName} onClick={() => void 0}>{buttonPublish}</button>}
        {goNext && <Modal>
          <Confirmation
            closeWin={this.closeModalWindow}
            cancelUpload={this.cancelUpload}
            confirmUpload={this.confirmUpload}
          />
        </Modal>}
        <LoadingLayer loading={actionInProgress}/>
      </div>
    );
  }
}

UploadComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
  determineFetch: PropTypes.func.isRequired,
  fetchTeamMetadata: PropTypes.func.isRequired,
  selectedAll: PropTypes.bool.isRequired,
};

const ExcelDateToJSDate = (date) => {
  if( date != null ){
    try{
      let converted_date = new Date(Math.round((date - 25569) * 864e5));
      let converted_string = converted_date.toISOString().replace('T', ' ').split(".")[0];
      return converted_string;
    } catch (e) {
      return '';
    }
  }else{
    return '';
  }
}

const createShiftsFromString = (str) => {

  if(!str || str.length === 0) {
    return null;
  }
  const idx = str.indexOf('first_name');
  if(idx < 0) {
    return null;
  }
  const validContent = str.substring(idx);
  let shifts = [];
  const strArray = validContent.split(/\r\n|\r|\n/);
  if(strArray && strArray.length > 0) {
    const paramNames = strArray[0].split(',');
    if(paramNames && paramNames.length > 0) {
      for(let i=0; i<strArray.length; i++) {
        let lines = '';
        for(let j=0; j<paramNames.length; j++) {
            const values = strArray[i].split(',');
            const line = `${paramNames[j]}: ${values[j]}`;
            lines += `${line},`;
            console.log(line);
        }
        const shift = lines;
        shifts.push(shift);
      }
    }
  }

  return shifts;
}

    
/* generate an array of column objects */
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

export default UploadComponent;
