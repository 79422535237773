import React  from "react";
import { FormattedMessage } from 'react-intl';

import '../../../App/layout.scss';
import '../../../App/fonts.scss';
// import { Mixpanel } from '../../../Mixpanel';
import AvailabilityDetailTableRowWeekdayCell from "./AvailabilityDetailTableRowWeekdayCell";
import { effectiveOnTimeStamp, submittedTimeStamp } from "helpers/calendarUtils";

class AvailabilityDetailTableRowComponent extends React.Component {
    state = {
    };
  
    constructor(props) {
      super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    render() {
        const {rangeStart, rangeEnd, availability } = this.props;

        const cloned = rangeStart.clone();
        const col1 = cloned;
        const col2 = cloned.clone().add(1, 'days');
        const col3 = cloned.clone().add(2, 'days');
        const col4 = cloned.clone().add(3, 'days');
        const col5 = cloned.clone().add(4, 'days');
        const col6 = cloned.clone().add(5, 'days');
        const col7 = rangeEnd.clone();

        return (
            <tr className="avail-row">
                  <td className="user-cell" > <span className="effective-time"> { effectiveOnTimeStamp(availability.range_start_date) } </span> </td>
                <AvailabilityDetailTableRowWeekdayCell date = {col1} availability = {availability} />
                <AvailabilityDetailTableRowWeekdayCell date = {col2} availability = {availability} />
                <AvailabilityDetailTableRowWeekdayCell  date = {col3} availability = {availability} />
                <AvailabilityDetailTableRowWeekdayCell  date = {col4} availability = {availability} />
                <AvailabilityDetailTableRowWeekdayCell  date = {col5} availability = {availability} />
                <AvailabilityDetailTableRowWeekdayCell  date = {col6} availability = {availability} />
                <AvailabilityDetailTableRowWeekdayCell  date = {col7} availability = {availability} />
                  <td className="timestamp"> <span className="submitted-time"> {submittedTimeStamp(availability.created_at)} </span> </td>
            </tr>
        );
    }

}

export default AvailabilityDetailTableRowComponent;