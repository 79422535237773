import React from 'react';
import { connect } from 'react-redux';
import AvailabilityComponent from './AvailabilityComponent';
import { scheduleCalendarOperations, } from '../ScheduleCalendar/duck';
import { availabilityOperations } from './duck';


const mapStateToProps = ({
  locationReducer,
  appReducer,
  scheduleCalendarReducer,
  availabilityReducer,
}) => {
  return {
    locationId: locationReducer.location_code,
    rangeStart: scheduleCalendarReducer.dateRange.start,
    rangeEnd: scheduleCalendarReducer.dateRange.end,
    startStr: scheduleCalendarReducer.dateRange.startStr,
    usersLoading: scheduleCalendarReducer.allUsers.loading,
    allUsers: scheduleCalendarReducer.allUsers.users,
    plans: appReducer.plans.data,
    locationData: locationReducer.location_data,
    availabilities: availabilityReducer.availabilities,
  };
};

const mapDispatchToProps = (dispatch) => {

  const notifyTeamDispatch = (locationId, message, users) => {
    dispatch(scheduleCalendarOperations.notifyTeamOperation(locationId, message, 'my_availability', users));
  };

  const loadAvailabilityDispatch = (locationId) => {
    dispatch(availabilityOperations.fetchAvailabilityOperation(locationId, 1, 10000));
  };

  const changeDateRangeSlice = (from, to) => {
    dispatch(scheduleCalendarOperations.changeDateRangeOperation(from, to));
  };

  return { 
    loadAvailabilityDispatch,
    changeDateRangeSlice,
    notifyTeamDispatch,
  };
};

const AvailabilityContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {
    locationId,
    rangeStart,
    rangeEnd,
    startStr,
    usersLoading,
    allUsers,
    plans,
    locationData,
    loadAvailabilityDispatch,
    changeDateRangeSlice,
    notifyTeamDispatch,
    availabilities,
    } = props;

  return <AvailabilityComponent
    loadAvailabilityDispatch = {loadAvailabilityDispatch}
    changeDateRangeSlice = {changeDateRangeSlice}
    startStr = {startStr}
    rangeStart = {rangeStart}
    rangeEnd = {rangeEnd}
    loading = {usersLoading}
    allUsers = {allUsers}
    locationId = {locationId}
    availabilities = {availabilities}
    notifyTeamDispatch = {notifyTeamDispatch}
    locationData = {locationData}
   /> 
});

export default AvailabilityContainer;