import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '../../duck/utils';
import UserCell from './Cell/UserCell';
import './TableComponent.scss';
import './RowComponent.scss';
import { Mixpanel } from '../../../../Mixpanel';

// import DroppableTableCell from './Cell/DroppableTableCell';
import DroppableTableCellContainer from './Cell/DroppableTableCellContainer';

const RowComponent = (props) => {

  const rowOfShifts = props.userShiftsForTheWeek

  if (
    props.user.hasOwnProperty('fullName') &&
    rowOfShifts.slice(1).every(shifts => shifts.length === 0)
  ) {
    return null;
  }

  const getCellElement = (date, shiftsForDay) => {

    return  <DroppableTableCellContainer
                 user={props.user} date={date.clone()} shiftsForDay={shiftsForDay} 
                 weekRangeStart={props.weekRangeStart}
                 selectedDateToAddShift={props.selectedDateToAddShift} 
                 handleOpenPopup={props.selectedShiftToViewDetail} 
                 isCopyingShift={props.isCopyingShift} 
                 shiftToCopy={props.shiftToCopy}
                 allUsers = {props.allUsers}
                 handleTooltipHide = {props.handleTooltipHide}
                 handleTooltipShow = {props.handleTooltipShow}
                 />
  }

  const col1 = props.weekRangeStart.clone();
  const col2 = col1.clone().add(1, 'days');
  const col3 = col1.clone().add(2, 'days');
  const col4 = col1.clone().add(3, 'days');
  const col5 = col1.clone().add(4, 'days');
  const col6 = col1.clone().add(5, 'days');

  return (
    <tr className="calendar-row">
      <td className="shift-row__owner-cell">
        <UserCell
          user={props.user}
          locationId={props.locationId}
          totalHoursForWeekForUser={rowOfShifts[0]}
          selectedUnregisteredUserToLink={props.selectedUnregisteredUserToLink}
          handleShowSMSInviteModal={props.handleShowSMSInviteModal}
        />
      </td>

      {getCellElement(col1, rowOfShifts[1])}
      {getCellElement(col2, rowOfShifts[2])}
      {getCellElement(col3, rowOfShifts[3])}
      {getCellElement(col4, rowOfShifts[4])}
      {getCellElement(col5, rowOfShifts[5])}
      {getCellElement(col6, rowOfShifts[6])}
      {getCellElement(props.weekRangeEnd.clone(), rowOfShifts[7])}

      </tr>
  );
};

export default injectIntl(RowComponent);
