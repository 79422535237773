export const SHIFTS_PER_PAGE = 2000;

const ShiftSubType = {
  DraftOpenShift: 'DraftOpenShift',
  DraftVTO: 'DraftVTO',
  DraftInventory: 'DraftInventory',
  DraftUnregisteredInventory: 'DraftUnregisteredInventory',
  ParentOpenShift: 'ParentOpenShift',
  ChildOpenShift: 'ChildOpenShift',
  ParentVTO: 'ParentVTO',
  ChildVTO: 'ChildVTO',
  Inventory: 'Inventory',
  TeamShift: 'TeamShift',
  CalledOff: 'CalledOff',
  Unknown: 'Unknown',
};

export default {
 ShiftSubType 
};
