import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';

import Icon from '@mdi/react';
import { mdiPin } from '@mdi/js';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import { appShapes } from 'App/duck';
import { teamFeedShapes } from '../../duck';
import NewPostInput from './new-post-input';
import { default as PostStyled } from './styled';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import blocks from '../../blocks';
import elements from '../../elements';
import {UploadImage, UnfurlURL, UploadPDF, UploadPDF2, UploadPDF3, UploadPDF4} from '../../../../helpers/api-calls/feed-calls';
import { getUserId } from '../../../../helpers/cookies';
import PDFComponent from './PDFComponent';
import Confirm from './Confirm';
import Modal from 'components/GeneralComponents/Modal';
import './PostsComponent.scss';

import {Mixpanel} from '../../../../Mixpanel';
import { isValidObject } from 'helpers/validation';

const sha1 = require('js-sha1');


class PostsComponent extends React.Component {
  constructor(props){
    super(props);

    this.state={
                debug: false,
                userInput: '', 
                enterKeyPressed: false, 
                currentChannel: -1, 
                textAreaHeight: 30,
                checkedAnnouncement: false,
                imageDataUrl: '',
                imageDataUrlList: [],
                imageInfoList: [],
                detectedUrl: '',
                previews: [],
                userInput2: '',
                contentWithUrl: '',
                localPosts: [],
                previewPDFs: [],
                pdfIdList: [],
                finalAllowPost: 0,
                location: null,
                deletedPostId: -1,
                channelMembers: [],
                showSnackbar: false,
                timer: null,
                openMoreActionsMenu: false,
                actionMenuPos: null,
                iconPos: null,
                currentUser: null,
                showConfirmDelete: false,
                showConfirmLeave: false,
                lastAction: '',
                leftChannel: null,
              };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this); 
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleReaderLoaded = this.handleReaderLoaded.bind(this);
    this.handlePreviewChange = this.handlePreviewChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.fileInput = React.createRef();
    this.textInput = React.createRef();
    this.imagePreView = React.createRef();
    this.previewFrame = React.createRef();
    this.previewInput = React.createRef();
    this.pdfPreview = React.createRef();
    this.buttonMoreRef = React.createRef();
    this.snackbarRef = React.createRef();
    this.warningRef = React.createRef();

    this.imgUrlListIndex = 0;
    this.isChrome = false; 
    this.urlDetected = false;
    this.detectedUrl = '';
    this.startTime = 0;
    this.endTime = 0;
    this.activePost = null;
    this._isMounted = false;
    this.pdfData = '';
    this.pdfUrlListIndex = 0;
    this.allImagesUploaded = false;
    this.allPDFsUploaded = false;
    this.postCreated = false;
    this.likeClicked = false;
    this.unlikeClicked = false;
    this.activeChannelId = 0;
    this.fetchingPosts = false;
  
   
    this.pubnub = props.pubnub;
    this.pubnub.setUUID(`user.${sha1(`user.${getUserId()}`)}`);
    this.sha1 = `user_event.${sha1(`user.${getUserId()}`)}`;
    this.sha1Presence = '';
    this.apiCalledForPosts = [];
    
  }

  messages = defineMessages({
    pressKeyPrompt: {
      id: 'PostComponent.pressKeysSubmit',
      defaultMessage: "Press CTRL + Enter to submit",
    },
    chooseFile: {
      id: 'PostComponent.chooseFile',
      defaultMessage: "Choose File",
    },
    noFileChosen: {
      id: 'PostComponent.noFileChosen',
      defaultMessage: "No file chosen",
    },
    maxFilesExceeded:{
      id: 'PostComponent.maxFilesExceeded',
      defaultMessage: "Cannot upload more than {maxFiles} files at one time.",
    }
  });

  acceptedTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ];

  isValidFileType(fileType){
    return this.acceptedTypes.includes(fileType);
  };


  isAnnouncement(post_type) {
    return [1, 2, 3, 4].includes(post_type);
  }

  containUrl(text){
    var s;
    var index1, index2, index3, index4;
    
    if(!text){
      return false;
    }
    s = text.toLowerCase();
    if(s.length < 5){
      return false;
    }
    index1 = s.indexOf("http");
    if(index1 < 0){
      return false;
    }
    index2 = s.indexOf(":");
    if(index2 <= 0){
      return false;
    }else{
      if(index2 < index1){
        let charToCheck; // "Please visit : https://www......,looking for ":" after "http"
        charToCheck = s.charAt(index1 + 3 + 1); // check char after 'http'
        if(charToCheck === 's'){
          charToCheck = s.charAt(index1+ 3 + 2) // check char after 'https'
          if(charToCheck === ':'){
            index2 = index1 + 3 + 2;
          }
        }else if(charToCheck === ':'){
          index2 = index1 + 3 + 1;
        }else{
          return false;
        }
      }
    }
    index3 = s.indexOf("//");
    if(index3 <= 0){
      return false;
    }
    index4 = s.indexOf(".");
    if(index4 <= 0){
      return false;
    }
    if(index4 <= index3 || index3 <= index2 || index2 <= index1){
      return false;
    }

    return true;
  }

  extractUrl(text) {
    const urlRegex = /(https?:\/\/[^ ]*)/;
    let start, end;
    let s;

    if(!text){
      return null;
    }
    s = text.toLowerCase();
    if(!this.containUrl(s)){
      return null;
    }
    var url = s.match(urlRegex)[1];
    if(url){
      const lastChar = url.charAt(url.length-1);
      if(lastChar === ',' || lastChar === '.'){
        url = url.slice(0, -1); // remove last char
      }
      var numberOfLineBreaks = (url.match(/\n/g)||[]).length;
      if(numberOfLineBreaks > 0) {
        var splitted = url.split("\n");
        if(splitted.length > 0) {
          url = splitted[0];
        }
      }
      return url;
    }else{
      return null;
    }
  }

  dropRef = React.createRef();

  componentDidMount() {
    const {
      fetchPosts,
      active: { channel, subscriptions },
      setActivePost,
      activeChannel,
      posts,
      fetchPostLikeList,
      fetchPostReaderList,
      fetchSubscriptions,
      allowCreatePost,
      locations,
      locationId,
      allUsers,
      onlineUsers,
    } = this.props;

    this._isMounted = true;
    if(activeChannel) {
      const id = parseInt(activeChannel.id);
      const idHash = sha1(`channel.${id}`);
      this.sha1Presence = `channel_presence.${idHash}`;
      this.pubnubSubscribe();
    }

    if(posts.loading && channel) {
      setActivePost(0, '');
      this.apiCalledForPosts = [];
      fetchPosts(channel, 1);
      fetchSubscriptions(channel);
    }

    if(!posts.loading){
      if(posts.data.length > 0 && this.state.localPosts.length === 0){
        this.setState({localPosts: posts.data});   
      }
    }
    this.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1 ? true : false;

    if(subscriptions) {
      this.setState({finalAllowPost: subscriptions.final_allow_post});
    }

    if(locations && locations.length > 0) {
      const currentLocation = locations.filter((location) => location.id === parseInt(locationId));
      if(currentLocation && currentLocation.length > 0) {
        this.setState({location: currentLocation[0]});
      }
    }

    if(activeChannel && allUsers) {
      const channelUsers = allUsers.filter((user) =>  
        user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
      if(channelUsers) {
        this.setState({channelMembers: channelUsers});
      }

      const theUser = allUsers.filter((user) => user.owner_id === parseInt(getUserId()));
      this.setState({currentUser: theUser[0]});
    }

    this.apiCalledForPosts = [];
    if(posts && posts.data.length > 0) {
      for(let i=0; i<posts.data.length; i++) {
        if(!this.apiCalledForPosts.includes(posts.data[i].id)) {
          this.apiCalledForPosts.push(posts.data[i].id)
          fetchPostLikeList(posts.data[i].id);
          fetchPostReaderList(posts.data[i].id);
          if(i=posts.data.length -1) {
            console.log(`called api for like/read list for post ${posts.data[i].id}`);
          }
        }
      }
    }

    window.addEventListener("mousemove", this.logMousePosition);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.pubnub.removeListener();
    this.pubnub.unsubscribe({
      channels: [this.sha1],
    });
    //console.log('called unsubscribe');
    window.removeEventListener("mousemove", this.logMousePosition);
    clearInterval(this.state.timer);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      active: { channel, subscriptions },
      posts, 
      locations, 
      locationId,
      onlineUsers,
      activeChannel,
      setLastPost,
      updateChannelLastContent,
      fetchPostLikeList,
      fetchPostReaderList,
      fetchReadReceipts,
      receipts,
      allUsers,
      managers,
      associates,
      channels,
      setActiveChannel,
    } = this.props;
    const {
      active: { channel: prevChannel, subscriptions: prevSubscriptions },
    } = prevProps;
    const { localPosts, deletedPostId, currentUser, lastAction, showSnackbar, } = this.state;

    if (prevChannel !== channel) {
      const { fetchPosts, fetchSubscriptions } = this.props;
      this.apiCalledForPosts = [];
      fetchPosts(channel);
      fetchSubscriptions(channel);
      //this.fetchingPosts = true;
      

      // reset unsend post/announcement
      this.setState({userInput: '',});
      this.setState({userInput2: '',});
      if(this.fileInput.current){
        this.fileInput.current.value = '';
      }
      this.setState({previews: []});
      this.setState({imageDataUrl: ''});
      this.setState({imageDataUrlList: []});
      this.setState({imageInfoList: []});
      this.setState({checkedAnnouncement: false});
      this.setState({contentWithUrl: ''});
      this.setState({pdfIdList: []});
      this.imgUrlListIndex = 0;

    }

    const height = document.getElementById('columnWrap').clientHeight;

    // the state 'localPosts' is used to prevent re-render all comments when new comment added
    if(prevProps.posts !== posts) {
      this.setState({localPosts: posts.data});
      if(posts.data.length > 0) {
        const lastContent = `${posts.data[posts.data.length-1].owner.first_name} ${posts.data[posts.data.length-1].owner.last_name}: ${posts.data[posts.data.length-1].content ? posts.data[posts.data.length-1].content: ''}`;
        const channelId = activeChannel ? activeChannel.id : 0;
        const payload = {
          channel_id: channelId,
          content: lastContent,
        }
        updateChannelLastContent(payload);
       
        for(let i=0; i<posts.data.length; i++) {
          if(!this.apiCalledForPosts.includes(posts.data[i].id)) {
            this.apiCalledForPosts.push(posts.data[i].id)
            fetchPostLikeList(posts.data[i].id);
            fetchPostReaderList(posts.data[i].id);
          }
        }
      }else {
        if(prevProps.posts && prevProps.posts.data.length === 1) {
          const channelId = activeChannel ? activeChannel.id : 0;
          const payload = {
            channel_id: channelId,
            content: '',
          }
          updateChannelLastContent(payload);
        }
      }
      this.unlikeClicked = false;
      this.likeClicked = false;

      //show snackbar for deleting a post
      if(prevProps.posts && prevProps.posts.data.length > 0) {
        if(prevProps.posts.data.length - 1 === posts.data.length) {
          if(lastAction === 'delete') {
            this.setState({showSnackbar: true});
            this.startTimer(10000);
          }
        }
      }

      if(prevProps.posts.loading === true && posts.loading === false) {
        if(this.fetchingPosts) {
          this.fetchingPosts = false;
          if(lastAction === 'leave' && showSnackbar) {
            this.startTimer(10000);
            console.log('Start timer for leaving group');
          }
        }
      }
    }

    if(prevSubscriptions !== subscriptions) {
      if(subscriptions) {
        this.setState({finalAllowPost: subscriptions.final_allow_post});
      }
    }

    if(prevProps.locations !== locations) {
      if(locations && locations.length > 0) {
        const currentLocation = locations.filter((location) => location.id === parseInt(locationId));
        if(currentLocation && currentLocation.length > 0) {
          this.setState({location: currentLocation[0]});
        }
      }
    }

    if(prevProps.locationId !== locationId) {
      if(this.sha1Presence.length === 0) {
        if(locationId) {
          const id = parseInt(activeChannel.id);
          const idHash = sha1(`channel.${id}`);
          this.sha1Presence = `channel_presence.${idHash}`;
        }
      }
    }


    if(prevState.deletedPostId !== deletedPostId) {
      let lastPost = null;
      if(localPosts && localPosts.length > 0) {
        const idx = localPosts.findIndex((post) => post.id === deletedPostId);
        if(idx >= 0) {
          if(idx === 0) {
            if(localPosts.length > 1) {
              lastPost = localPosts[localPosts.length-1];
            }
          }else if(idx === localPosts.length -1) {
            if(localPosts.length > 1) {
              lastPost = localPosts[localPosts.length -2];
            }else {
              lastPost = localPosts[0];
            }
          }else {
            lastPost = localPosts[localPosts.length-1];
          }
        }
        setLastPost(lastPost);
      }
    }

    if(prevProps.activeChannel !== activeChannel) {
      if(prevProps.activeChannel && activeChannel) {
        if(prevProps.activeChannel.id !== activeChannel.id) {
          //console.log(`channel id: ${activeChannel.id}`);
          this.apiCalledForPosts = [];
        }
      }
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
        }
      }
    }

    if(prevProps.allUsers !== allUsers) {
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
        }
      }

      const theUser = allUsers.filter((user) => user.owner_id === parseInt(getUserId()));
      this.setState({currentUser: theUser[0]});
    }

    if(prevState.currentUser !== currentUser) {
      if(prevState.currentUser && prevState.currentUser.channel_ids) {
        if(prevState.currentUser.channel_ids.length !== currentUser.channel_ids.length) {
          console.log('got');
        }
      }
    }


    if(prevProps.channels !== channels) {
      if(prevProps.channels) {
        if(prevProps.channels.length - 1 === channels.length) {
          this.setState({showSnackbar: true, lastAction: 'leave'});
          this.fetchingPosts = true;
          const main = channels.filter((channel) => channel.channel_type === 'location_feed');
          if(main && main.length > 0) {
            setActiveChannel(main[0].id);
          }
        }
      }
    }
  }

  pubnubSubscribe = () => {
    const { userID,
            fetchPosts,
            handleUpdateLikePost, 
            handleUpdateUnlikePost,
            handleUpdatePinPost,
            handleUpdateCommentCount,
            handleUpdateDeletePost,
            handleUpdateDeleteActivePost,
            handleDeleteChannel,
            getLocationChannels,
            locationId,
            getLocationOnlineUsers,
            handleAddUserToOnlineList,
            handleRemoveUserFromOnlineList,
            activeChannel,
            NotifyNewPost,
            updateLikeList,
            fetchOnePost,
          } = this.props;

    var usedNewCommentId = -1;
    var usedDeleteCommentId = -1;
    
    this.pubnub.addListener({
      message: (event) => {
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'like' &&
          event.message.crud === 'NEW'
        ) { 
            //Only do this when like from other device
            if(event.message.receiver_id !== event.message.meta.owner_id) {
              handleUpdateLikePost(event.message.node_id);
            }
            updateLikeList(event.message.node_id, event.message.meta.like_owner_ids);
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'like' &&
          event.message.crud === 'DELETE'
        ) {
          //Only do this when unlike from other device
          if(event.message.receiver_id !== event.message.meta.owner_id) {
            handleUpdateUnlikePost(event.message.node_id);
          }
          updateLikeList(event.message.node_id, event.message.meta.like_owner_ids);
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'post' &&
          event.message.crud === 'UPDATE'
        ) {
            handleUpdatePinPost(event.message.node_id, event.message.meta.pinned);
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'post' &&
          event.message.crud === 'NEW'
        ) { 
            if(!this.postCreated) {
              if(event.message.tree_id === this.activeChannelId) {
                this.apiCalledForPosts = [];
                fetchPosts(event.message.tree_id);
              }else {
                fetchOnePost(event.message.node_id);
              }
              NotifyNewPost(event.message.tree_id);

            }
            this.postCreated = false;
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'post' &&
          event.message.crud === 'DELETE'
        ) {
            if(this.activePost && this.activePost.id === event.message.node_id){
              handleUpdateDeleteActivePost(event.message.node_id);
            }else{
              handleUpdateDeletePost(event.message.node_id);
            }
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'comment' && 
          event.message.crud === 'NEW'
        ) {
            if(event.message.leaf_id !== usedNewCommentId){
              //console.log('+1. current Id = ' + event.message.leaf_id + '  previous Id = ' + usedNewCommentId);
              usedNewCommentId = event.message.leaf_id;
              handleUpdateCommentCount(event.message.node_id, event.message.leaf_id, true);
            }
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'comment' && 
          event.message.crud === 'DELETE'
        ) {
            if(event.message.leaf_id !== usedDeleteCommentId){
              usedDeleteCommentId = event.message.leaf_id;
              handleUpdateCommentCount(event.message.node_id, event.message.leaf_id, false);
            }
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'channel' && 
          event.message.crud === 'DELETE'
        ) {
            if(event.message.tree_id){
              handleDeleteChannel(event.message.tree_id);
            }
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'channel' && 
          event.message.crud === 'NEW'
        ) {
            if(event.message.tree_id){
              //This is the root cause of WS-2005. At this moment, user could have selected another location!!!
              //getLocationChannels(locationId);
            }
        }
      },

      presence: (event) => {
        if(event) {
          if(event.action === 'join') {
            handleAddUserToOnlineList(event.uuid);
          }else if(event.action === 'leave') {
            handleRemoveUserFromOnlineList(event.uuid);
          }
        }
      },   

      objects: (event) => {
        //alert('recevied objects');
      },
      messageAction: (event) => {
        //alert('recevied message action');
      },
      status: (event) => {
        //alert('received status');
      },
      signal: (event) => {
        //alert('received signal');
      },
    });

    this.pubnub.subscribe({
      channels: [this.sha1, this.sha1Presence],
      withPresence: true
    });
    if(this.sha1Presence.length > 0) {
      try {
        const start = moment();
        console.log('calling hereNow');
        this.pubnub.hereNow({
          channels: [this.sha1Presence],
          includeUUIDs: true,
          includeState: true,
        }, (status, response) => {
          const end = moment();
          const diff = moment.duration(end.diff(start))
          const ms = parseInt(diff.asMilliseconds());
          console.log(`response comes in ${ms} ms.`);
          if(response) {
            let uuids = [];
            if(response.channels[this.sha1Presence].occupants.length > 0) {
              for(let i=0; i<response.channels[this.sha1Presence].occupants.length; i++) {
                uuids.push(response.channels[this.sha1Presence].occupants[i].uuid);
              }
              getLocationOnlineUsers(uuids);
            }
          }
        });
      } catch(error) {
        console.log(error);
      };
    }
  };

  logMousePosition = (e) => {
    const { iconPos,} = this.state;
    if(iconPos) {
      if(e.clientX > iconPos.x + iconPos.width) {
        this.setState({openMoreActionsMenu: false});
      }
      if(e.clientX > iconPos.x && e.clientY > iconPos.y + iconPos.height) {
        //this.setState({openMoreActionsMenu: false});
      }
    }
  }

  autoExpand = function (field) {
    var height = field.scrollHeight;
    field.style.height = height + 'px';
  };

  resetInputHeight (field) {
    if( isValidObject(field) == true ){
      field.style.height = '40px';
    }
  }

  startTimer = (timeOut) => {
    const myTimer = setInterval(this.tick, timeOut); //unit: ms
    if(this._isMounted) {
      this.setState({timer: myTimer});
    }
  }

  tick = () => {
    if(this._isMounted) {
      this.setState({showSnackbar: false, lastAction: ''});
      this.setState({timer: null});
    }
  }

  handleScrollLoad = () => {
    const {
      active: { channel },
      fetchMorePosts,
      posts: { page, totalPages },
    } = this.props;

    if (page !== totalPages) {
      this.apiCalledForPosts = [];
      fetchMorePosts(channel, page + 1);
    }
  };

  handleChange(event) {
    this.setState({userInput: event.target.value});
    let text = event.target.value;

    if( text !== null && text.length > 0 ){
      this.autoExpand(event.target);
    }else{
      this.resetInputHeight(event.target);
    }
    
    event.preventDefault();
  }

  handlePreviewChange(event) {
    event.preventDefault();
    this.setState({userInput2: event.target.value});
    this.previewInput.current.focus();
  }

  handleImageChange(event){
    const { intl} = this.props;
    const MAX_NUM_FILES = 10;
    
    if(event.target.files.length === 0){
      return;
    }
    if(event.target.files.length + this.state.previews.length > MAX_NUM_FILES){
      const msg=intl.formatMessage(this.messages.maxFilesExceeded,{maxFiles: MAX_NUM_FILES});
      alert(msg);
      this.fileInput.current.value = '';
      return;
    }
    for(let i=0; i<event.target.files.length; i++){
      if (!this.isValidFileType(event.target.files[i].type)) {
          alert('Only images are allowed (png or jpg)');
          this.fileInput.current.value = '';
          return;
      }
      if(event.target.files[i].type === 'application/pdf') {
        let pdfObj = {name: '', size: 0, type: '', data: null, file: null, blob: null, dataUrl: '',};
        pdfObj.name = event.target.files[i].name;
        pdfObj.size = event.target.files[i].size/1000;
        pdfObj.type = event.target.files[i].type;
        pdfObj.file = event.target.files[i];
        pdfObj.blob = URL.createObjectURL(event.target.files[i]);
        
        let {previewPDFs} = this.state;
        previewPDFs.push(pdfObj);
        this.setState({previewPDFs: previewPDFs});
      }else {
        let preview = URL.createObjectURL(event.target.files[i]);
        let { previews } = this.state;
        previews.push(preview); 
        this.setState({previews: previews})
      }
    }
    //this.setState({images: event.target.files});

    for(let i=0; i < event.target.files.length; i++){
      let reader = new FileReader();
      reader.onload = this.handleReaderLoaded;
      reader.readAsDataURL(event.target.files[i]); 
      //readAsDataURL is base64 encoding.Base64 encoded images are ~33% larger than the original file size.
    }
    /*
    if(this.state.previews.length === 0){
      this.textInput.current.focus(); // prevent file selection window from opening again when ctrl+enter pressed
    }else if(this.state.previewPDFs.length === 0) {
      this.textInput.current.focus(); // prevent file selection window from opening again when ctrl+enter pressed
    }else {
      if(this.previewInput.current) {
        this.previewInput.current.focus();
      }
    }
    */
    if(this.state.previews.length > 0 || this.state.previewPDFs.length > 0) {
      if(this.previewInput.current) {
        this.previewInput.current.focus();
      }
    }else {
      this.textInput.current.focus(); // prevent file selection window from opening again when ctrl+enter pressed
    }
    event.preventDefault();
  }

  handleReaderLoaded(event){
    if(event.target.result.includes('application/pdf')){
      let localCopy = [...this.state.previewPDFs];
      if(localCopy.length > 0){
        for(let i=0; i<localCopy.length; i++){
          if(!localCopy[i].data){
            //localCopy[i].data = event.target.result;
            const idx = event.target.result.indexOf(',') + 1;
            localCopy[i].data = event.target.result.substring(idx);
            localCopy[i].dataUrl = event.target.result;
            break;
          }
        }
        this.setState({previewPDFs: localCopy});
      }
    }
    else{
      this.setState({imageDataUrl: event.target.result});
      this.setState({ imageDataUrlList: this.state.imageDataUrlList.concat(this.state.imageDataUrl)});
    }
    event.preventDefault();
  }

  

  handleSubmit(event) {
    const { activeChannel, createPost} = this.props;
    const content = this.state.userInput2 ? this.state.userInput2 : this.state.userInput;
    
    if(content){
      const url = this.extractUrl(content)
      if(url){
        this.urlDetected = true;
        this.setState({detectedUrl: url});
        this.detectedUrl = url;
        if(this.state.previews.length === 0){
          UnfurlURL(url).then((data) =>{
            console.log(data);
            this.succeedUnfurled(data);
          }).catch((error) =>{
            console.log(error);
            this.urlDetected = false;
            this.detectedUrl = '';
            this.setState({detectedUrl: ''});
            if(this.state.checkedAnnouncement){
              createPost({
                channel_id: activeChannel.id,
                title: 'Announcement',
                content,
                post_type: 2,
              });
              this.postCreated = true;
            }else{
              createPost({
                channel_id: activeChannel.id,
                title: 'Title',
                content,
              });
              this.postCreated = true;
            }
          });
          this.setState({contentWithUrl: content});
        }
      }
    }

    if(this.state.previews.length > 0 || this.state.previewPDFs.length > 0){
      if(this.state.previews.length > 0){
        this.imgUrlListIndex = 0;
        this.allImagesUploaded = false;
        this.startTime = new Date().getTime();
        UploadImage(this.state.imageDataUrlList[this.imgUrlListIndex],
          this.succeededCallback, 
          this.failedCallback).then((data) => {
            if(data && data.image){
              this.succeededCallback(data);
            }
        }).catch((error)=>{
            console.log(error);
            this.failedCallback(error);
        });
        //this.setState({previews: []});
      }
      if(this.state.previewPDFs.length > 0) {
        this.pdfUrlListIndex = 0;
        this.allPDFsUploaded = false;
        UploadPDF(this.state.previewPDFs[this.pdfUrlListIndex]).then((data) =>{
          this.succeededUploadPDF(data);
        }).catch((error) =>{
          console.log(error);
        });
      }
    }else{
      if(content){
        if(!this.urlDetected){
          if(this.state.checkedAnnouncement){
            createPost({
              channel_id: activeChannel.id,
              title: 'Announcement',
              content,
              post_type: 2,
            });
            this.postCreated = true;
          }else{
            createPost({
              channel_id: activeChannel.id,
              title: 'Title',
              content,
            });
            this.postCreated = true;
          }
        }
        this.setState({userInput: '',});
        this.setState({userInput2: '',});
        this.fileInput.current.value = '';
        this.setState({previews: []});
        this.setState({imageDataUrl: ''});
        this.setState({imageDataUrlList: []});
        this.setState({imageInfoList: []});
        this.setState({pdfIdList: []});
        this.resetInputHeight();
        //this.setState({checkedAnnouncement: false});
        this.imgUrlListIndex = 0;
        if(!this.urlDetected || this.state.previews.length > 0){
          this.setState({checkedAnnouncement: false});
        }
      }
    }
    event.preventDefault();
  }

  handleKeyPress(event){
    const { activeChannel, createPost } = this.props;
    const content = this.state.userInput2 ? this.state.userInput2 : this.state.userInput;
    
    if (event.keyCode === 13 && event.ctrlKey) {
      if(content){
        const url = this.extractUrl(content)
        if(url){
          this.urlDetected = true;
          this.setState({detectedUrl: url});
          this.detectedUrl = url;
          if(this.state.previews.length === 0){
            UnfurlURL(url).then((data) =>{
              console.log(data);
              this.succeedUnfurled(data);
            }).catch((error) =>{
              console.log(error);
              this.urlDetected = false;
              this.detectedUrl = '';
              this.setState({detectedUrl: ''});
              if(this.state.checkedAnnouncement){
                createPost({
                  channel_id: activeChannel.id,
                  title: 'Announcement',
                  content,
                  post_type: 2,
                });
                this.postCreated = true;
              }else{
                createPost({
                  channel_id: activeChannel.id,
                  title: 'Title',
                  content,
                });
                this.postCreated = true;
              }
            });
            this.setState({contentWithUrl: content});
          }
        }
      }

      if(this.state.previews.length > 0 || this.state.previewPDFs.length > 0){
        if(this.state.previews.length > 0) {
          this.imgUrlListIndex = 0;
          this.allImagesUploaded = false;
          UploadImage(this.state.imageDataUrlList[this.imgUrlListIndex],
            this.succeededCallback, 
            this.failedCallback).then((data) => {
              if(data && data.image){
                this.succeededCallback(data);
              }
          }).catch((error)=>{
            console.log(error);
            this.failedCallback(errors);
          });
        }
        if(this.state.previewPDFs.length > 0) {
          this.pdfUrlListIndex = 0;
          this.allPDFsUploaded = false;
          UploadPDF(this.state.previewPDFs[this.pdfUrlListIndex]).then((data) =>{
          this.succeededUploadPDF(data);
          }).catch((error) =>{
            console.log(error);
          });
        }
      }else{
        if(content){
          if(!this.urlDetected){
            if(this.state.checkedAnnouncement){
              createPost({
                channel_id: activeChannel.id,
                title: 'Announcement',
                content,
                post_type: 2,
              });
              this.postCreated = true;
            }else{
              createPost({
                channel_id: activeChannel.id,
                title: 'Title',
                content,
              });
              this.postCreated = true;
            }
          }
          this.setState({userInput: '',});
          this.setState({userInput2: '',});
          this.fileInput.current.value = '';
          this.setState({previews: []});
          this.setState({imageDataUrl: ''});
          this.setState({imageDataUrlList: []});
          this.setState({imageInfoList: []});
          this.setState({pdfIdList: []});
          this.imgUrlListIndex = 0;
        }
      }
      //this.setState({checkedAnnouncement: false});
      if(!this.urlDetected || this.state.previews.length > 0){
        this.setState({checkedAnnouncement: false});
      }
      event.preventDefault();
      Mixpanel.track('Posted message');
    } 
  }

   handleCheckboxChange(event){
    this.setState({ checkedAnnouncement: event.target.checked });
    // The line below will cause the problem that clicking twice to change state!!!
    //event.preventDefault();
   }

  handleDelete(event){
    //alert(event.target.nextSibling.currentSrc);
    event.preventDefault();
    
    if(this.state.previews.length > 0){
      var previewArray = [...this.state.previews]; // make a separate copy of the array
      var urlArray = [...this.state.imageDataUrlList]; // make a separate copy of the array
      var index = previewArray.indexOf(event.target.nextSibling.currentSrc);

      if(this.state.previews.length !== this.state.imageDataUrlList.length){
        console.log('previews.length !== imageDataUrlList.length');
      }

      if (index !== -1) {
        previewArray.splice(index, 1);
        this.setState({previews: previewArray});
        urlArray.splice(index, 1);
        this.setState({imageDataUrlList: urlArray});
      }
      this.previewInput.current.focus(); // prevent next preview icon shown selected
      return;
    }

    if(this.state.previewPDFs.length > 0) {
      let pdfArray = [...this.state.previewPDFs];
      const idx = pdfArray.findIndex((pdf) => pdf.name === event.target.nextSibling.children[1].firstChild.innerText);
      if (idx >= 0) {
        pdfArray.splice(idx, 1);
        this.setState({previewPDFs: pdfArray});
      }
      return;
    }
  }

   succeededCallback(data){
    const { activeChannel, createPost } = this.props;
    const content = this.state.userInput2 ? this.state.userInput2 : this.state.userInput;

    if(!data){
      return;
    }
    
    let temp = data.image.avatar_meta.slice(0, -1); // remove last char
    temp = temp.substring(1, temp.length);
    let pieces = temp.split(",");
    let w, h;
    if(pieces.length === 2){
      w = parseInt(pieces[0], 10);
      h = parseInt(pieces[1], 10);
    }
    

    let imageInfo = {
        object_instances: 
          {
            class_name: data.image.class_name,
            id: data.image.id,
            image_name: data.image.image_name,
            image_width: w,
            image_height: h,
          }
        };

    let {imageInfoList} = this.state;
    imageInfoList.push(imageInfo);
    this.setState({imageInfoList: imageInfoList});

    this.imgUrlListIndex += 1;
    if(this.imgUrlListIndex < this.state.imageDataUrlList.length){
      UploadImage(this.state.imageDataUrlList[this.imgUrlListIndex],
         this.succeededCallback, 
         this.failedCallback).then((data) => {
          if(data && data.image){
            this.succeededCallback(data);
          }
      }).catch((errors)=>{
            this.failedCallback(errors);
      });
    }else{
      this.endTime = new Date().getTime();
      const msg = `Uploaded ${this.state.imageDataUrlList.length} images took ${(this.endTime - this.startTime)/1000} seconds.`;
      console.log(msg);
      this.allImagesUploaded = true;

      if(this.state.previewPDFs.length > 0) {
        if(this.allPDFsUploaded) {
          if(this.state.checkedAnnouncement){
            var object_instances = this.state.imageInfoList.map((item) =>{
              return item.object_instances;
            });
            
            createPost({
              channel_id: activeChannel.id,
              title: 'Announcement',
              content,
              post_type: 2,
              attachment_attributes:{
                object_instances,
              },
              attached_files_blob_ids: this.state.pdfIdList
            });
            this.postCreated = true;
          }else{
            var object_instances = this.state.imageInfoList.map((item) =>{
              return item.object_instances;
            });
            
            createPost({
              channel_id: activeChannel.id,
              title: 'Title',
              content,
              attachment_attributes:{
                object_instances,
              },
              attached_files_blob_ids: this.state.pdfIdList
            });
            this.postCreated = true;
          }  
          this.setState({userInput: '',});
          this.setState({userInput2: '',});
          this.fileInput.current.value = '';
          this.setState({previews: []});
          this.setState({imageDataUrl: ''});
          this.setState({imageDataUrlList: []});
          this.setState({imageInfoList: []});
          this.setState({pdfIdList: []});
          this.setState({checkedAnnouncement: false});
          this.imgUrlListIndex = 0;

          this.setState({previewPDFs: []});
          this.setState({pdfIdList: []});
          this.pdfUrlListIndex = 0;
        }else {
          console.log('images Uploaded, waiting for PDF uploading...');
        }
        return;
      }
      
      if(this.state.checkedAnnouncement){
        var object_instances = this.state.imageInfoList.map((item) =>{
          //console.log(item);
          return item.object_instances;
        });
        
        createPost({
          channel_id: activeChannel.id,
          title: 'Announcement',
          content,
          post_type: 2,
          attachment_attributes:{
            object_instances,
          }
        });
        this.postCreated = true;
      }else{
        var object_instances = this.state.imageInfoList.map((item) =>{
          //console.log(item);
          return item.object_instances;
        });
        
        createPost({
          channel_id: activeChannel.id,
          title: 'Title',
          content,
          attachment_attributes:{
            object_instances,
          }
        });
        this.postCreated = true;
      }  


      this.setState({userInput: '',});
      this.setState({userInput2: '',});
      this.fileInput.current.value = '';
      this.setState({previews: []});
      this.setState({imageDataUrl: ''});
      this.setState({imageDataUrlList: []});
      this.setState({imageInfoList: []});
      this.setState({pdfIdList: []});
      this.setState({checkedAnnouncement: false});
      this.imgUrlListIndex = 0;
    }
   }

   failedCallback(error){
    alert('failed');
    if(this.state.imageDataUrlList.length >0){
      UploadImage(this.state.imageDataUrlList[this.imgUrlListIndex],
         this.succeededCallback, 
         this.failedCallback).then((data) => {
            this.succeededCallback(data);
      }).catch((errors)=>{
            this.failedCallback(errors);
      });
    }
   }

   succeedUnfurled(data){
     const { activeChannel, createPost } = this.props;
     const content=this.state.contentWithUrl;
     this.urlDetected = false;

     if(this.state.checkedAnnouncement){
      createPost({
        channel_id: activeChannel.id,
        title: 'title',
        content,
        post_type: 2,    
        url_meta: {
          author: data.data.author,
          date: data.data.date,
          description: data.data.description,
          image: data.data.image,
          logo: data.data.logo,
          publisher: data.data.publisher,
          title: data.data.title,
          url: data.data.url,
        }
      });
      this.postCreated = true;
    }else{
      createPost({
        channel_id: activeChannel.id,
        title: 'title',
        content,    
        url_meta: {
          author: data.data.author,
          date: data.data.date,
          description: data.data.description,
          image: data.data.image,
          logo: data.data.logo,
          publisher: data.data.publisher,
          title: data.data.title,
          url: data.data.url,
        }
      });
      this.postCreated = true;
    }
    this.setState({checkedAnnouncement: false});
   }

  succeededUploadPDF(data) {
    const { activeChannel, createPost } = this.props;
    const content = this.state.userInput2 ? this.state.userInput2 : this.state.userInput;

    if(!data) {
      return;
    }

    let {pdfIdList} = this.state;
    pdfIdList.push(data.blob.id);
    this.setState({pdfIdList: pdfIdList});
    this.pdfUrlListIndex += 1;
    if(this.pdfUrlListIndex < this.state.previewPDFs.length) {
      UploadPDF(this.state.previewPDFs[this.pdfUrlListIndex]).then((data) =>{
        this.succeededUploadPDF(data);
      }).catch((error) =>{
        console.log(error);
      });
    }else {
      this.allPDFsUploaded = true;
      if(this.state.previews.length > 0) {
        if(this.allImagesUploaded) {
          var object_instances = this.state.imageInfoList.map((item) =>{
            return item.object_instances;
          });
          if(this.state.checkedAnnouncement){
              createPost({
                channel_id: activeChannel.id,
                title: 'Announcement',
                content,
                post_type: 2,
                attachment_attributes:{
                    object_instances,
                },
                attached_files_blob_ids: this.state.pdfIdList
              });
              this.postCreated = true;
          }else{
            createPost({
              channel_id: activeChannel.id,
              title: 'Title',
              content,
              attachment_attributes:{
                object_instances,
              },
              attached_files_blob_ids: this.state.pdfIdList
            });
            this.postCreated = true;
          }
          this.setState({userInput: '',});
          this.setState({userInput2: '',});
          this.fileInput.current.value = '';
          this.setState({previewPDFs: []});
          this.setState({pdfIdList: []});
          this.setState({checkedAnnouncement: false});
          this.pdfUrlListIndex = 0;

          this.setState({previews: []});
          this.setState({imageDataUrl: ''});
          this.setState({imageDataUrlList: []});
          this.setState({imageInfoList: []});
          this.imgUrlListIndex = 0;
        }else {
          console.log('PDF loaded, waiting for image uploading...');
        }
        return;
      }

      if(this.state.checkedAnnouncement){
        createPost({
          channel_id: activeChannel.id,
          title: 'Announcement',
          content,
          post_type: 2,
          attached_files_blob_ids: this.state.pdfIdList,
        });
        this.postCreated = true;
      }else{
        createPost({
          channel_id: activeChannel.id,
          title: 'Title',
          content,
          attached_files_blob_ids: this.state.pdfIdList,
        });
        this.postCreated = true;
      }
      this.setState({userInput: '',});
      this.setState({userInput2: '',});
      this.fileInput.current.value = '';
      this.setState({previewPDFs: []});
      this.setState({pdfIdList: []});
      this.setState({checkedAnnouncement: false});
      this.pdfUrlListIndex = 0;
    }
  }

  getUserUUID = (userId) => {
    return `user.${sha1(`user.${userId}`)}`;
  };

  notifyPostHeightChanged = (value) => {
    window.scrollTo({bottom: value, left: 0, behavior: "smooth" });
  }
  
  handleMoreActionsClicked = (event) => {
    const { setActiveSidePane, } = this.props;
    const { openMoreActionsMenu, } = this.state;

    event.preventDefault();
    let winTop = '';
    let winLeft = '';
    let output = null;
    const winW = '260px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.buttonMoreRef && this.buttonMoreRef.current) {
      const domNode = ReactDOM.findDOMNode(this.buttonMoreRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + output.height - 25}px`;
        winLeft = `${output.x - 205}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({actionMenuPos: winData, iconPos: output, openMoreActionsMenu: !openMoreActionsMenu});
    //this.setState({openMoreActionsMenu: !openMoreActionsMenu});
  }

  notifyPostLikedChanged = (postId, liked) => {
    const { fetchPostLikeList, } = this.props;
    this.likeClicked = false;
    this.unlikeClicked = false;
    fetchPostLikeList(postId);
  }

  renderPosts = () => {
    const {
      active,
      posts: { data, page },
      setActivePost,
      allowComments,
      allowLikes,
      setActiveDelete,
      setActiveSidePane,
      fetchReadReceipts,
      fetchSubscriptions,
      fetchComments,
      likePost,
      unlikePost,
      pinPost,
      unpinPost,
      userIsAdmin,
      activeChannel,
      userId,
      allUsers,
      onlineUsers,
      fetchPostLikeList,
      fetchPostReaderList,
      receipts,
      handleReadPost,
    } = this.props;

    const { localPosts, deletedPostId, currentUser, showConfirmDelete, } = this.state;

    //const visiblePosts = data.map((post) => (
    const visiblePosts = localPosts.map((post, index) => (
      <blocks.Post
        key={index}
        clickComment={() => {
          if(!activeChannel) {
            return;
          }
          setActivePost(post.id, activeChannel.channel_name);
          fetchComments(post.id);
          setActiveSidePane('comments');
          this.activePost = post;
          Mixpanel.track('View comments');
        }}
        clickDelete={() => {
          setActiveDelete({
            id: post.id,
            type: this.isAnnouncement(post.post_type) ? 'announcement' : 'post',
          });
          this.setState({deletedPostId: post.id});
          this.setState({showConfirmDelete: true});
        }}
        clickLike={() => {
          if (post.liked) {
            if(!this.unlikeClicked) {
              unlikePost(post.id);
              this.unlikeClicked = true;
              Mixpanel.track('Unlike post');
            }
          } else {
            if(!this.likeClicked) {
              likePost(post.id);
              this.likeClicked = true;
              Mixpanel.track('Like post');
            }
          }
        }}
        clickReadReceipts={() => {
          //fetchReadReceipts(post.id, active.channel);
          handleReadPost(post.id);
          Mixpanel.track('Read post', {'post_id': post.id});
        }}
        clickPin={() => {
          if (post.pinned) {
            unpinPost(post.id);
            Mixpanel.track('Unpin post', {'post_id': post.id});
          } else {
            pinPost(post.id);
            Mixpanel.track('Pin post', {'post_id': post.id});
          }
        }}
        clickCopy={() => {
          if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(post.content);
            this.setState({showSnackbar: true, lastAction: 'copy'});
            Mixpanel.track('Copy post', {'post_id': post.id});
            this.startTimer(10000);
          }else {
            console.log('The Clipboard API is not available');
          }
        }}
        clickMoreActions={() => {
          //alert('clicked');
        }}
        active={active.post.id === post.id || active.delete.id === post.id}
        data={{
          ...post,
          final_allow_comment: allowComments,
          final_allow_like: allowLikes,
          final_allow_delete: active.userIsAdmin || post.owner_id === userId, // Fixed bug WS-1279, WS-1262
        }}
        allUsers={allUsers}
        onlineUsers={onlineUsers}
        index={index}
        notifyPostLikedChanged={this.notifyPostLikedChanged}
        setActiveSidePane={setActiveSidePane}
        setActivePost={setActivePost}
        activeChannel={activeChannel}
        receipts={receipts}
      />
    ));
    const scrollTrackingIndex = data.length / page;

    visiblePosts.splice(
      scrollTrackingIndex,
      0,
      <div key={`bookmark_${new Date().getTime()}`} id="scroll__bookmark" />
    );

    return visiblePosts;
  };

  getFormDataContent = (formData) => {
    const { file } = formData;
    let keys = Object.keys(formData);
    keys = keys.filter(key => key !== "file");
    const data = {
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      size: file.size,
      type: file.type
    };
    const fileData = { file: data };
    keys.forEach(key => {
      fileData[key] = formData[key];
    });
    return fileData;
  }; 

  renderSnackbar = (info) => {
    let winTop = '';
    let winLeft = '';
    const winW = '160px';
    const winH = `60px`; 

    let domNode = null;
    let output = null;
    if(this.textInput && this.textInput.current) {
      domNode = ReactDOM.findDOMNode(this.textInput.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + output.height - 36}px`;
        winLeft = `${output.x + 120}px`;
      }
    }else {
      if(this.warningRef && this.warningRef.current) {
        domNode = ReactDOM.findDOMNode(this.warningRef.current);
        output =domNode.getBoundingClientRect();
        if(output) {
          winTop = `${output.y + output.height - 100}px`;
          winLeft = `${output.x + 150}px`;
        }
      }
    }

    if(winTop !== '' && winLeft !== '') {
      return (
        <PostStyled.WinContainer ref={this.snackbarRef} top={winTop} left={winLeft}>
          <div className="status__wrap">
           <label className="status__text">{info}</label>
          </div>
        </PostStyled.WinContainer>
      );
    }
  }

  mouseEnterButtonMore = (event) => {
    let winTop = '';
    let winLeft = '';
    let output = null;
    const winW = '260px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.buttonMoreRef && this.buttonMoreRef.current) {
      const domNode = ReactDOM.findDOMNode(this.buttonMoreRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + output.height - 25}px`;
        winLeft = `${output.x - 205}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({actionMenuPos: winData, iconPos: output});
  }

  mouseLeaveActionMenu = (event) => {
    this.setState({openMoreActionsMenu: false});
  }

  renderMoreActionsMenu = () => {
    const { data, activeChannel, active, } = this.props;
    const { actionMenuPos, } = this.state;

    const groupDetails = (<FormattedMessage
            id="PostComponent.groupDetails"
            defaultMessage="View Group Details"
          />);
    const groupMembers = (<FormattedMessage
            id="PostComponent.groupMembers"
            defaultMessage="View Group Members"
          />);

    const viewAnnouncement = (<FormattedMessage
          id="PostComponent.viewAnnouncement"
          defaultMessage="View Announcements"
        />);

    const pinnedPosts = (<FormattedMessage
            id="PostComponent.viewPinnedPosts"
            defaultMessage="View Pinned Posts"
          />);
    const leaveGroup = (<FormattedMessage
            id="PostComponent.leaveGroup"
            defaultMessage="Leave Group"
          />);

    const itemDetails = {name: 'details', body: groupDetails};
    const itemMembers = {name: 'members', body: groupMembers};
    const itemAnnouncements = {name: 'announcements', body: viewAnnouncement};
    const itemPinnedPosts = {name: 'posts', body: pinnedPosts};
    const itemLeave = {name: 'leave', body: leaveGroup};
    let items = [itemDetails, itemMembers, itemAnnouncements, itemPinnedPosts, itemLeave];

    if(activeChannel && (activeChannel.channel_type === 'location_feed' || activeChannel.shyft_channel)) {
      items = [itemDetails, itemMembers, itemAnnouncements, itemPinnedPosts];
    }

    const menuItems = items.map((item, index) => (
      <PostStyled.MenuItem
        key={index} 
        value={item}
        onClick={(event) => {this.handleActionMenuClicked(item)}}
      >
        {item.name !== 'leave' ? <label className="menu__action-dropdown-text">
          {item.body}
        </label> : 
        <label className="menu__action-dropdown-text-red">{item.body}</label>}
      </PostStyled.MenuItem>
    ));

    
    return (
      <PostStyled.WinContainerMenu top={actionMenuPos.top} left={actionMenuPos.left} onMouseLeave={this.mouseLeaveActionMenu}>
        <div className="menu__wrap">
        {menuItems}
        </div>
      </PostStyled.WinContainerMenu>
    );
  }

  handleActionMenuClicked = (item) => {
    const { setActiveSidePane, } = this.props;

    if(item && item.name) {
      switch(item.name) {
      case 'details':
        setActiveSidePane('group-details');
        break;
      case 'members':
        setActiveSidePane('group-members');
        break;
      case 'announcements':
        setActiveSidePane('announcements');
        break;
      case 'posts':
         setActiveSidePane('pins');
        break;
      case 'leave':
         this.setState({showConfirmLeave: true});
        break;
      }
    }
    this.setState({openMoreActionsMenu: false});
  }

  notifyCancel = () => {
   this.setState({showConfirmDelete: false, showConfirmLeave: false});
   Mixpanel.track('Cancel delete post');
  }

  notifyDelete = () => {
    const { deletePost, posts, } = this.props;
    const { deletedPostId, } = this.state;
    this.setState({showConfirmDelete: false, lastAction: 'delete'});
    
    if(deletedPostId >= 0) {
      deletePost(deletedPostId, posts.lastPost);
      Mixpanel.track('Deleted post', {'post_id': deletedPostId});
    }
  }

  notifyLeave = () => {
    const { handleRemoveUserFromGroup, activeChannel,  allUsers, } = this.props;

    //const members = this.getChannelMembers(activeChannel.id);
    const currentUserId = parseInt(getUserId());
    const currentUser = allUsers.filter((user) => user.owner_id === currentUserId);
    if(currentUser && currentUser.length > 0) {
      handleRemoveUserFromGroup(activeChannel.id, currentUser);
    }
    this.setState({showConfirmLeave: false, leftChannel: activeChannel});
  }

  getChannelMembers = (channelId) => {
    const { allUsers, } = this.props;
    const total = uniqBy(allUsers, 'id'); //Remove duplicates
  
    const usersHaveId = total.filter((user) =>  user.user && user.user.channel_ids && user.user.channel_ids.includes(channelId));
    if(usersHaveId && usersHaveId.length > 0) {
      const valid = usersHaveId.filter((item) => item.status === 'active');
      if(valid && valid.length > 0) {
        return valid;
      }
    }
    return [];
  }

  render() {
    const { 
      active, 
      posts, 
      activeChannel, 
      createPost, 
      setActiveSidePane, 
      active: {channel}, 
      allowCreatePost,
      allUsers,
      intl} = this.props;
    const { location, debug, channelMembers, showSnackbar, openMoreActionsMenu, finalAllowPost, 
    showConfirmDelete, showConfirmLeave, lastAction, leftChannel, } = this.state;

    this.activeChannelId = activeChannel ? activeChannel.id : 0;

    const topLevelPinIcon = (
      <button onClick={() => setActiveSidePane('pins')}>
        <Icon path={mdiPin} size={1} color="#FE7B30" />
      </button>
    );

    const msg = intl.formatMessage(this.messages.pressKeyPrompt);
    const placeholder=`Channel: ${activeChannel && activeChannel.channel_name}       ${msg}`;

    let msg1 = '';
    if(this.state.previews.length > 0){
      if(this.fileInput.current && this.fileInput.current.files.length > 0){
        msg1 = this.fileInput.current.files[0].name;
      }
    }else{
      msg1 = intl.formatMessage(this.messages.noFileChosen);
    }
    const fileButtonText=`${intl.formatMessage(this.messages.chooseFile)}:          ${msg1}`;

    let postIsAllowed = false;
    if(allowCreatePost) {
      if(finalAllowPost) {
        postIsAllowed = true;
      }else {
        postIsAllowed = false;
      }
    }else {
      postIsAllowed = false;
    }
    
    let numUsers = 0;
    if(channelMembers.length === 0) {
      if(activeChannel && activeChannel.member_count) {
        numUsers = activeChannel.member_count;
      }
    }else {
      numUsers = channelMembers.length;
    }

    const approvedBadge = '/icons/verified-badge-icon.svg';
    const condition = true; 
    const channel_type = activeChannel ? activeChannel.channel_type : '';
    const channelName = activeChannel ? activeChannel.channel_name : '';
    const leftChannelName = leftChannel ? leftChannel.channel_name : '';
    const members = (<FormattedMessage
              id="PostComponent.members"
              defaultMessage="+{value} members"
              values={{ value: numUsers}}
            />);
    const textCopied = (<FormattedMessage
              id="PostComponent.textCopied"
              defaultMessage="Text Copied to Clipboard"
            />);
    let avatarUrl = '';
    if(channel_type === 'location_feed') {
      if(location && location.avatar_blob) {
        avatarUrl = location.avatar_blob.blob_url;
      }else {
        avatarUrl = '/icons/channel avatar.svg';
      }
    }else if(channel_type === 'custom_feed') {
      avatarUrl = '/icons/channel avatar.svg';
    }else if(channel_type === 'region_feed') {
      avatarUrl = '/icons/channel avatar.svg';
    }

    const confirmDeleteTitle = (<FormattedMessage
            id="PostComponent.confirmDeleteTitle"
            defaultMessage="Delete Message"
          />);
    const confirmDeleteContent = (<FormattedMessage
            id="PostComponent.confirmDeleteContent"
            defaultMessage="Are you sure you want to delete this message? This cannot be undone."
          />);
    const leaveGroup = (<FormattedMessage
            id="PostComponent.leaveGroup"
            defaultMessage="Leave Group"
          />);
    const confirmLeaveContent = (<FormattedMessage
            id="PostComponent.confirmLeaveContent"
            defaultMessage="Are you sure you want to leave {group}"
            values={{ group: channelName}}
          />);
    const messageDeleted = (<FormattedMessage
            id="PostComponent.messageDeleted"
            defaultMessage="Message deleted successfully"
          />);
    const leftGroup = (<FormattedMessage
            id="PostComponent.leftGroup"
            defaultMessage="You have left {group} Group"
            values={{ group: leftChannelName}}
          />);

    let snackbarText = null;
    switch(lastAction) {
      case 'copy':
        snackbarText = textCopied;
        break;
      case 'delete':
        snackbarText = messageDeleted;
        break;
      case 'leave':
        snackbarText = leftGroup;
        break;
    }

    if(!postIsAllowed){
      return(
        <elements.ColumnWrap id="columnWrap">
        {/*<blocks.Header icon={topLevelPinIcon}>
          <span>{!activeChannel || activeChannel.channel_name}</span>
        </blocks.Header>*/}
        <div className="posts__wrap">
          {activeChannel ? <PostStyled.ChannelAvatar src={avatarUrl} /> : null} 
          <div className="posts__info-wrap">
            <div className="posts__top-line">
            <label className="posts__title">{activeChannel && activeChannel.channel_name}</label>
            {condition && <PostStyled.ApprovedBadge src={approvedBadge}/>}
            </div>
            <label className="posts__text">{members}</label>
          </div>
          <button className="posts__button-more" 
            ref={this.buttonMoreRef}
            onMouseEnter={this.mouseEnterButtonMore} 
            onMouseLeave={this.mouseLeaveButtonMore}
            onClick={this.handleMoreActionsClicked}
          />
        </div>
        <elements.ScrollWrap reverse isLoading={posts.loading}>
          <InfiniteScroll
            isLoading={posts.loading}
            onEnter={this.handleScrollLoad}
          />
          {!posts.loading || <elements.Loading />}
          {this.renderPosts()}
        </elements.ScrollWrap>
        {posts.loading || <PostStyled.Warning ref={this.warningRef}><FormattedMessage id="PostComponent.notAllowPostNew"
                defaultMessage="Read Only"/></PostStyled.Warning>}
          {showSnackbar && <Modal>
            {this.renderSnackbar(snackbarText)}
          </Modal>} 
          {openMoreActionsMenu && <Modal>
            {this.renderMoreActionsMenu()}
          </Modal>} 
          {showConfirmDelete && <Modal>
            <Confirm
              title={confirmDeleteTitle}
              content={confirmDeleteContent}
              notifyCancel={this.notifyCancel}
              notifyDelete={this.notifyDelete}
              notifyLeave={this.notifyLeave}
            />
          </Modal>}
          {showConfirmLeave && <Modal>
            <Confirm
              title={leaveGroup}
              content={confirmLeaveContent}
              notifyCancel={this.notifyCancel}
              notifyDelete={this.notifyDelete}
              notifyLeave={this.notifyLeave}
            />
          </Modal>}  
          <PostStyled.Overlay display={showConfirmDelete || showConfirmLeave ? 'block' : 'none'} />       
         </elements.ColumnWrap>
      );
    }
    

    if(!this.state.previews.length && !this.state.previewPDFs.length){
    return (
      <elements.ColumnWrap id="columnWrap">
        {/*<blocks.Header icon={topLevelPinIcon}>
          <span>{!activeChannel || activeChannel.channel_name}</span>
        </blocks.Header>*/}
        <div className="posts__wrap">
          {debug && <p>{activeChannel.id}</p>}
          {activeChannel && <PostStyled.ChannelAvatar src={avatarUrl} />} 
          <div className="posts__info-wrap">
            <div className="posts__top-line">
            <label className="posts__title">{activeChannel && activeChannel.channel_name}</label>
            {condition && <PostStyled.ApprovedBadge src={approvedBadge}/>}
            </div>
            <label className="posts__text">{members}</label>
          </div>
          <button className="posts__button-more" 
            ref={this.buttonMoreRef}
            onMouseEnter={this.mouseEnterButtonMore} 
            onMouseLeave={this.mouseLeaveButtonMore}
            onClick={this.handleMoreActionsClicked}
          />
        </div>
        <elements.ScrollWrap reverse isLoading={posts.loading}>
          <InfiniteScroll
            isLoading={posts.loading}
            onEnter={this.handleScrollLoad}
          />
          {!posts.loading || <elements.Loading />}
          {this.renderPosts()}
        </elements.ScrollWrap>
 
        {(<PostStyled.PostInputForm onSubmit={this.handleSubmit} noAttached={!this.state.previews.length} notChrome={!this.isChrome}>
        
          <PostStyled.PostInput 
                      id="drop-area"
                      type="textarea"
                      value={this.state.userInput}
                      placeholder={placeholder}
                      multiline={true}
                      onChange={this.handleChange}
                      onKeyUp={this.handleKeyPress}
                      ref={this.textInput}
                      />

          {<PostStyled.ButtonsDiv>
          {active.userIsAdmin ? <PostStyled.CheckBoxDiv>
            <PostStyled.CheckBox
                checked={this.state.checkedAnnouncement}
                onChange={this.handleCheckboxChange}
              />
            <PostStyled.Label>
                <FormattedMessage id="PostComponent.announcement"
                defaultMessage="Announcement"/>
            </PostStyled.Label>
          </PostStyled.CheckBoxDiv> : null}
          
          <PostStyled.ChooseFileLabel>
            <PostStyled.ChooseFile
              id="file-choose"
              multiple="multiple"
              ref={this.fileInput}
              onChange={this.handleImageChange}
            />
          </PostStyled.ChooseFileLabel>
          <PostStyled.SubmitButton onClick={this.handleSubmit} />
          </PostStyled.ButtonsDiv>}
        </PostStyled.PostInputForm>)} 
        {showSnackbar && <Modal>
          {this.renderSnackbar(snackbarText)}
        </Modal>} 
        {openMoreActionsMenu && <Modal>
          {this.renderMoreActionsMenu()}
        </Modal>} 
        {showConfirmDelete && <Modal>
          <Confirm
            title={confirmDeleteTitle}
            content={confirmDeleteContent}
            notifyCancel={this.notifyCancel}
            notifyDelete={this.notifyDelete}
            notifyLeave={this.notifyLeave}
          />
        </Modal>}
        {showConfirmLeave && <Modal>
          <Confirm
            title={leaveGroup}
            content={confirmLeaveContent}
            notifyCancel={this.notifyCancel}
            notifyDelete={this.notifyDelete}
            notifyLeave={this.notifyLeave}
          />
        </Modal>}  
        <PostStyled.Overlay display={showConfirmDelete || showConfirmLeave ? 'block' : 'none'} />                          
      </elements.ColumnWrap>
    );
    }else{
      return(
        <elements.ColumnWrap id="columnWrap">
        {/*<blocks.Header icon={topLevelPinIcon}>
          <span>{!activeChannel || activeChannel.channel_name}</span>
        </blocks.Header>*/}
        <div className="posts__wrap">
          {activeChannel && <PostStyled.ChannelAvatar src={avatarUrl} />} 
          <div className="posts__info-wrap">
            <div className="posts__top-line">
            <label className="posts__title">{activeChannel && activeChannel.channel_name}</label>
            {condition && <PostStyled.ApprovedBadge src={approvedBadge}/>}
            </div>
            <label className="posts__text">{members}</label>
          </div>
          <button className="posts__button-more" 
            ref={this.buttonMoreRef}
            onMouseEnter={this.mouseEnterButtonMore} 
            onMouseLeave={this.mouseLeaveButtonMore}
            onClick={this.handleMoreActionsClicked}
          />
        </div>
        <elements.ScrollWrap reverse isLoading={posts.loading}>
          <InfiniteScroll
            isLoading={posts.loading}
            onEnter={this.handleScrollLoad}
          />
          {!posts.loading || <elements.Loading />}
          {this.renderPosts()}
        </elements.ScrollWrap>

          {<PostStyled.PreviewFrame
                                ref={this.previewFrame}
                                >
                                <PostStyled.PreviewInput
                                    multiline={true}
                                    onChange={this.handlePreviewChange}
                                    onKeyUp={this.handleKeyPress}
                                    ref={this.previewInput}
                                    >
                                    {this.state.userInput}</PostStyled.PreviewInput>
                                    <PostStyled.ImagesDiv>
                                  {this.state.previews.map((preview,index) =>(
                                    <div>
                                      <PostStyled.RemoveButton 
                                      onClick={this.handleDelete}/>
                                      <PostStyled.PreviewImage
                                      key={index}
                                      src={preview}
                                      ref={this.imagePreview}/>
                                    </div>
                                  ))}
                                </PostStyled.ImagesDiv>
                                <PostStyled.PDFContainer>
                                {this.state.previewPDFs.map((pdf, index) =>(
                                  <div>
                                    <PostStyled.RemoveButton 
                                    onClick={this.handleDelete}/>
                                    <PDFComponent
                                      key={index}
                                      pdf={pdf}
                                      ref={this.pdfPreview}>
                                    </PDFComponent>
                                  </div>
                                  ))}
                                  </PostStyled.PDFContainer>
                                { <PostStyled.ButtonsDiv>
          {active.userIsAdmin ? <PostStyled.CheckBoxDiv>
            <PostStyled.CheckBox
                checked={this.state.checkedAnnouncement}
                onChange={this.handleCheckboxChange}
              />
            <PostStyled.Label>
                <FormattedMessage id="PostComponent.announcement"
                defaultMessage="Announcement"/>
            </PostStyled.Label>
          </PostStyled.CheckBoxDiv> : null}
          
          <PostStyled.ChooseFileLabel>
            <PostStyled.ChooseFile
              id="file-choose"
              multiple="multiple"
              ref={this.fileInput}
              onChange={this.handleImageChange}
            />
          </PostStyled.ChooseFileLabel>
          <PostStyled.SubmitButton onClick={this.handleSubmit} />
        </PostStyled.ButtonsDiv>}
        </PostStyled.PreviewFrame>}
        {showSnackbar && <Modal>
          {this.renderSnackbar(snackbarText)}
        </Modal>}
        {openMoreActionsMenu && <Modal>
          {this.renderMoreActionsMenu()}
        </Modal>}  
        {showConfirmDelete && <Modal>
          <Confirm
            title={confirmDeleteTitle}
            content={confirmDeleteContent}
            notifyCancel={this.notifyCancel}
            notifyDelete={this.notifyDelete}
            notifyLeave={this.notifyLeave}
          />
        </Modal>} 
        {showConfirmLeave && <Modal>
          <Confirm
            title={leaveGroup}
            content={confirmLeaveContent}
            notifyCancel={this.notifyCancel}
            notifyDelete={this.notifyDelete}
            notifyLeave={this.notifyLeave}
          />
        </Modal>} 
        <PostStyled.Overlay display={showConfirmDelete || showConfirmLeave ? 'block' : 'none'} />       
        </elements.ColumnWrap>
     );
    }
  }
}

PostsComponent.propTypes = {
  fetchPosts: PropTypes.func.isRequired,
  fetchMorePosts: PropTypes.func.isRequired,
  setActivePost: PropTypes.func.isRequired,
  setActiveDelete: PropTypes.func.isRequired,
  setActiveSidePane: PropTypes.func.isRequired,
  likePost: PropTypes.func.isRequired,
  unlikePost: PropTypes.func.isRequired,
  pinPost: PropTypes.func.isRequired,
  unpinPost: PropTypes.func.isRequired,
  //active: teamFeedShapes.active.isRequired,
  posts: teamFeedShapes.posts.isRequired,
  activeChannel: appShapes.channelsDataShape.isRequired,
  allowComments: PropTypes.number.isRequired,
  fetchReadReceipts: PropTypes.func.isRequired,
  fetchComments: PropTypes.func.isRequired,
  createPost: PropTypes.func.isRequired,
  fetchSubscriptions: PropTypes.func.isRequred,
  pubnub: PropTypes.object.isRequired,
};

export default injectIntl(PostsComponent);
