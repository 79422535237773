import { getInviteLink, setInviteLink, getUserName, getUserId } from './cookies';
import branch from 'branch-sdk';

let env_check = (process.env.NODE_ENV === 'production' && window.location.hostname.includes("web.myshyft.com"));

if( env_check === true ){
    branch.init('key_live_obpHBSsA6onNPffRYhkUQjaetAdRkE2O', function(err, data) {
        // console.log(err, data);
    });
}else{
    branch.init('key_test_feaRyIAw9ooTHpeT9kmUIggmBEpNpE22', function(err, data) {
        console.log(err, JSON.stringify(data));
    });
}

let actions = {
    initializeBranchLink: (locationData) => {
        branch.setIdentity(getUserId());
        const link = getInviteLink(locationData.id);
        if(link == null){
            var linkData = {
                campaign: 'SMB Dash',
                data: {
                  'referral_channel': 'Email',
                  'location_id': locationData.id,
                  'location_address':locationData.formatted_address,
                  'team_name': locationData.location_name,
                  'user_name': getUserName(),
                  'swift_code':locationData.swift_code,
                  '$og_title': 'A better way to swap shifts',
                  '$og_description': 'Shyft - Making shift work easier',
                  '$og_image_url':'https://files.myshyft.com/shyft-app-images/sso/shyft_logo.png',
                  'link_version': '7'
                }
              };
              
              branch.link(linkData, function(err, link) {
                console.log(link);
                setInviteLink(locationData.id, link);
              });
        }
    },

    getSMSInviteLink: (location_id) => {
        const link = getInviteLink(location_id);
        if( link !== null && link.length > 4){
            return link;
        }else{
            return 'https://files.myshyft.com/public-files/download.html';
        }
    }
}

export let Branchlink = actions;