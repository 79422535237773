import React from 'react';
import './FinishPastingComponent.scss';
import '../../../../App/fonts.scss';
import '../../../../App/colors.scss';
import '../../../../App/layout.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

import {Mixpanel} from '../../../../Mixpanel';
import formatter from 'pages/ScheduleCalendar/duck/formatter';
import { position } from 'polished';
import { format } from 'libphonenumber-js';

class FinishPastingComponent extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
    };
  }

  handleFinishPastingClick = (event) => {
    const {handleFinishPasting}= this.props;
    Mixpanel.track('Finish Pasting shift');
    handleFinishPasting();
  }

  render() {
    const {shiftBeingPasted} = this.props;

    const shiftCopiedText = (<FormattedMessage id="Scheduling.shiftCopied" defaultMessage="Shift Copied:" />);
    const finishPastingText = (<FormattedMessage id="Scheduling.finishPasting" defaultMessage="Finish Pasting" />);

    const position = shiftBeingPasted.primary_job;
    const duration = formatter.durationString(shiftBeingPasted);

    return (
      <div className="finish-pasting-container">
        <div className='copied-shift-wrap'>
            <label className='copy-title'> {shiftCopiedText}</label>
            <label className='shift-info'>{formatter.formattedShiftTimeRange(shiftBeingPasted)}</label>

            <div className='position-duration-wrap'>
                <label className='position-label'> {position} </label>
                {position.length > 0 && <span className='span-dot'></span>} 
                <label className='duration-label'> {duration} h </label>
            </div>

        </div>

        <div className="confirm-button-wrap">
          <button className="finish-pasting-button averta-semibold fs14px" onClick={this.handleFinishPastingClick}>{finishPastingText}</button>
        </div>

      </div>
    );
  }
}


export default injectIntl(FinishPastingComponent);