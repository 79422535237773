import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./Pinput.scss";

class Pinput extends React.Component {
  state = {
    value: "",
  };

  componentDidMount() {
    this.inputRef.focus();
  }

  _handleInput = (e) => {
    let newValue = e.target.value.replace(/\D/g, "").slice(0, 4); // Allow only digits, max length 4
    this.setState({ value: newValue });
    this.props.inputChange(newValue); // Pass value to parent
  };

  handleKeyDown = (e) => {
    const { submitForm, } = this.props;
    if (e.key === 'Enter') {
      submitForm(e);
    }
  };

  render() {
    return (
      <>
        <div className="name-prompt">Verification Code</div>

        <div className="icon-input-container">
          <input
            type="tel"
            value={this.state.value}
            onChange={this._handleInput}
            maxLength="4"
            placeholder="Enter 4-Digit Code"
            className="icon-name-input"
            pattern="\d*"
            ref={(input) => (this.inputRef = input)}
            onKeyDown={this.handleKeyDown}
          />

          <img
            className="input-icon-left"
            src='/icons/pin-entry-icon.svg'
          />
        </div>
      </>
    );
  }
}

export default Pinput;

Pinput.propTypes = {
  inputChange: PropTypes.func.isRequired,
};
