import React, { useState, useEffect } from 'react';
import './Flicker.scss'; // Import your CSS file containing the animation styles

const Flicker = ({isFlickering, children}) => {
  return (
    <div className={`flicker ${isFlickering ? 'flicker-once' : ''}`}>
      {children}
    </div>
  );
};

export default Flicker;