
import { getUserId } from "helpers/cookies";
const sha1 = require('js-sha1');

export const config = {
  shift: true,
  post: false,
  like: false,
  comment: false,
  notification: false,
  chat_message: false,
};

export const eventChannelName = () => { return  `user_event.${sha1(`user.${getUserId()}`)}`; }
export const PNUUID = () => { return `user.${sha1(`user.${getUserId()}`)}`; } 