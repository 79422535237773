import React from 'react';
import PropTypes from 'prop-types';
import '../../../../components/GeneralComponents/RegistrationDesign.scss'
class EmailConfirmation extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      showPassword: false,
    };
  }

  password = null;

  setPasswordRef = (element) => {
    this.password = element;
  };

  componentDidMount() {
    this.password.focus();
  }

  handlePasswordToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleKeyDown = (e) => {
    const { submitForm, } = this.props;
    if (e.key === 'Enter') {
      submitForm(e);
    }
  };

  render() {
    const { showPassword, } = this.state;
    return (

      <>
        <div className="name-prompt">Email</div>
        <div className="icon-input-container">
          <input
            type="text"
            placeholder="Email"
            className="icon-name-input"
            name="email"
            defaultValue={this.props.value}
            onChange={this.props.emailChange}
            disabled
          />
          <img
            className="input-icon-left"
            src='/icons/email-icon.svg'
          />
        </div>
        <div className="name-prompt">Password</div>

        <div className="icon-input-container">
          <input
            ref={this.setPasswordRef}
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            className="icon-name-input"
            name="password"
            onChange={this.props.passwordChange}
            onKeyDown={this.handleKeyDown}
          />
          <img
            className="input-icon-right"
            src={showPassword ? '/icons/password-eye-close-icon.svg' : '/icons/password-eye-icon.svg'}
            alt="Toggle Password Visibility"
            onClick={this.handlePasswordToggle}
          />

          <img
            className="input-icon-left"
            src='/icons/password-lock-icon.svg'
          />
        </div>
      </>
    );
  }
}

export default EmailConfirmation;

EmailConfirmation.propTypes = {
  value: PropTypes.string.isRequired,
  validity: PropTypes.bool.isRequired,
  inputChange: PropTypes.func.isRequired,
};
