import { connect } from 'react-redux';

import { teamFeedActions, teamFeedSelectors } from '../../duck';
import { appActions } from 'App/duck';

import ChannelsComponent from './ChannelsComponent';

const mapStateToProps = ({ appReducer, teamFeedReducer: { active }, locationReducer, }) => ({
  channels: teamFeedSelectors.getDateOrderedChannels(appReducer),
  active: active.channel,
  locations: locationReducer.locations,
  locationId: locationReducer.location_code,
});

const mapDispatchToProps = (dispatch) => {
  const setActiveChannel = (id) => {
    dispatch(teamFeedActions.updateActiveChannel(id));
    dispatch(teamFeedActions.updateActivePost({id: 0, channel_name: ''}));
    dispatch(teamFeedActions.updateActiveComment(0));
    dispatch(teamFeedActions.updateActiveDelete({ type: '', id: 0 }));
    dispatch(teamFeedActions.updateActiveSidePane(''));
    dispatch(teamFeedActions.clearReadReceipts());
  };

  const ResetNotifyNewPost = (channelId) => {
    dispatch(appActions.ResetNotifyNewPost(channelId));
  };

  return {
    setActiveChannel,
    ResetNotifyNewPost,
  };
};

const ChannelsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelsComponent);

export default ChannelsContainer;
