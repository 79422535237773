import styled from 'styled-components/macro';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import { Button } from '@shyft/acorns';

import InvalidBatchStatusIcon from './assets/invalid-batch.svg';
import PencilIcon from './assets/pencil-icon.svg';

const textColor = '#90959A';
const borderColor = '#DEDEDE';

const green = '#26CE93';
const darkGrey = '#64676A';
export const breakpoints = {
  medSmall: '556px',
  med: '680px',
};

const BackLink = styled(Link)`
  font-size: 14px;

  color: ${textColor};

  :hover {
    color: ${textColor};
  }
`;

const BackArrow = styled.span`
  display: inline-block;
  width: 8px;
  height: 12px;
  background-image: url(${process.env.PUBLIC_URL}/icons/back-arrow.svg);
  background-size: cover;
  position: relative;
  margin-top: 6px;
  margin-right: 0.5rem;
`;

const Header = styled.h2`
  width: 100%;
  text-align: center;
`;

const RadioButtonLabel = styled.label`
  max-width: 200px;
`;

const FooterDescription = styled.p`
  color: ${darkGrey};
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0;

  @media (max-width: ${breakpoints.med}) {
    font-size: 0.75rem;
  }
`;

const BatchStatusIcon = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 25px;
  width: 25px;
  border-radius: 50%;
`;

const InvalidBatchStatusBadge = styled.img.attrs({
  src: InvalidBatchStatusIcon,
})`
  height: 25px;
  width: 25px;
  margin-right: 1rem;
`;

const ValidBadgePencil = styled.img.attrs({
  src: PencilIcon,
})`
  height: 12px;
  width: 12px;
`;

const ValidBatchStatusBadge = styled.span`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${green};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`;

const ActionBtn = styled(Button).attrs(({ disabled, modifiers }) => ({
  disabled,
  modifiers: ['small', 'primary'].concat(modifiers),
}))`
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  @media (max-width: ${breakpoints.med}) {
    font-size: ${rem('12px')};
    padding: 6px 12px;
    font-weight: 600;
  }

  @media (max-width: 609px) {
    display: block;
  }
`;

const EditBtn = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['small'].concat(modifiers),
}))`
  margin-right: 1rem;

  @media (max-width: ${breakpoints.med}) {
    font-size: ${rem('12px')};
    padding: 6px 12px;
    font-weight: 600;
  }

  @media (max-width: 609px) {
    display: block;
  }
`;

const RowWrap = styled.div`
  background-color: white;
  flex: 1;
  padding: 1rem;
`;

const HeadWrap = styled.div`
  background-color: white;
  padding: 1rem;
`;

const WinContainerPrompt = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  //width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  width: fit-content;
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2; 
  //overflow: scroll;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  //border: 1px solid red;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const Overlay = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  //rgba(198,198,201,255)
`;

export {
  ActionBtn,
  EditBtn,
  BackLink,
  BackArrow,
  Header,
  RadioButtonLabel,
  BatchStatusIcon,
  FooterDescription,
  ValidBadgePencil,
  ValidBatchStatusBadge,
  InvalidBatchStatusBadge,
  RowWrap,
  HeadWrap,
  WinContainerPrompt,
  Overlay,
};
