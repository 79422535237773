import { createSlice } from '@reduxjs/toolkit';

const onlineUsersSlice = createSlice({
  name: 'onlineUsers',
  initialState: {
    users: [],
  },
  reducers: {
    receiveOnlineUsers: (state, action) => {
      state.users = action.payload;
    },
    appendOnlineUser: (state, action) => {
      if(!state.users.includes(action.payload)) {
        state.users = state.users.concat(action.payload);
      }
    },
    resetOnlineUsers: (state, action) => {
      state.users = [];
    },
    removeOnlineUser: (state, action) => {
      const idx = state.users.findIndex((user) => user === action.payload);
      if(idx)
      state.users.splice(idx, 1);
    },
  },
});

export default onlineUsersSlice;
