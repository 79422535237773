import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { messageDateFormat, } from 'helpers/date-time';

import styled from './styled';

const sha1 = require('js-sha1');


class SimplePost extends React.Component{
	constructor(props){
	  super(props);
	  this.state={
	  	debug: false,
	    channelMembers: [],
	    location: null,
	    enlarged: false,
	  };

	  this._isMounted = false;
	}

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserUUID = (userId) => {
    return `user.${sha1(`user.${userId}`)}`;
  };

  isAnnouncement(post_type) {
    return [1, 2, 3, 4].includes(post_type);
  }

  extractUrl(text) {
    const urlRegex = /(https?:\/\/[^ ]*)/;
    let start, end;
    let s;

    if(!text){
      return null;
    }
    s = text.toLowerCase();
    if(!this.containUrl(s)){
      return null;
    }
    var url = s.match(urlRegex)[1];
    if(url){
      const lastChar = url.charAt(url.length-1);
      if(lastChar === ',' || lastChar === '.'){
        url = url.slice(0, -1); // remove last char
      }
      var numberOfLineBreaks = (url.match(/\n/g)||[]).length;
      if(numberOfLineBreaks > 0) {
        var splitted = url.split("\n");
        if(splitted.length > 0) {
          url = splitted[0];
        }
      }
      return url;
    }else{
      return null;
    }
  }

  containUrl(text){
    var s;
    var index1, index2, index3, index4;
    
    if(!text){
      return false;
    }
    s = text.toLowerCase();
    if(s.length < 5){
      return false;
    }
    index1 = s.indexOf("http");
    if(index1 < 0){
      return false;
    }
    index2 = s.indexOf(":");
    if(index2 <= 0){
      return false;
    }else{
      if(index2 < index1){
        let charToCheck; // "Please visit : https://www......,looking for ":" after "http"
        charToCheck = s.charAt(index1 + 3 + 1); // check char after 'http'
        if(charToCheck === 's'){
          charToCheck = s.charAt(index1+ 3 + 2) // check char after 'https'
          if(charToCheck === ':'){
            index2 = index1 + 3 + 2;
          }
        }else if(charToCheck === ':'){
          index2 = index1 + 3 + 1;
        }else{
          return false;
        }
      }
    }
    index3 = s.indexOf("//");
    if(index3 <= 0){
      return false;
    }
    index4 = s.indexOf(".");
    if(index4 <= 0){
      return false;
    }
    if(index4 <= index3 || index3 <= index2 || index2 <= index1){
      return false;
    }

    return true;
  }

   renderPDF = (pdfObjects) => {

    return pdfObjects.map((pdf, index) => {
      const sizeText = `${Math.round(pdf.byte_size/1000)} KB`;
      return (
        <styled.PDFWrap
          key={pdf.id}
          onClick={() => {
            console.log(pdf.blob_url);
            window.open(pdf.service_url);
          }}>
          <styled.PDFIcon 
            src='/icons/pdf-icon.svg'>
          </styled.PDFIcon>
          <styled.PDFProps>
          <styled.PDFName>{pdf.filename}</styled.PDFName>
          <styled.PDFSizeWrap>
            <styled.PDFSize>PDF</styled.PDFSize>
            <styled.Circle/>
            <styled.PDFSize>{sizeText}</styled.PDFSize>
          </styled.PDFSizeWrap>
        </styled.PDFProps>
        </styled.PDFWrap>
      );
    });
  };

  render () {
  	const { data, onlineUsers, } = this.props;
  	const {
      attachment,
      comments_count,
      content,
      created_at,
      final_allow_comment,
      final_allow_delete,
      final_allow_like,
      post_type,
      pinned,
      liked,
      likes_count,
      owner,
      owner_id,
      url_meta,
      attached_files_blobs,
    } = data;

  	const { debug, enlarged, } = this.state;

  	const announcementIcon = '/icons/announcement-icon.svg';
  	const approvedBadge = '/icons/verified-badge-icon.svg';
    const pinnedIcon = '/icons/pinned-post-icon.svg';

  	const announceText = (<FormattedMessage
      id="Announcement.announcement"
      defaultMessage="Announcement"
    />);

    const pinnedText = (<FormattedMessage
      id="Announcement.pinned"
      defaultMessage="Pinned"
    />);

    let profileUrl = '/icons/default-profile-thumb.svg';
    if(owner && owner.thumb_url) {
      profileUrl = owner.thumb_url;
    }

    const userUUID = this.getUserUUID(owner_id);
    let showOnline = false;
    if(onlineUsers && onlineUsers.includes(userUUID)) {
      showOnline = true;
    }
    const showAdminBadge = owner && owner.is_admin ? true : false;
    let urlInContent = this.extractUrl(content);
    if(!urlInContent){
      urlInContent = content;
    }

    let showReadCount = false;
    let readCount = 0;
    if(data.readList && data.readList.length > 0) {
      showReadCount = true;
      readCount = data.readList.length;
    }

  	return (
  		<styled.MessageWrap announcement={this.isAnnouncement(data.post_type)}> 
  			{this.isAnnouncement(data.post_type) ? <styled.AnnouncementWrap>
  				<styled.AnnouncementIcon src={announcementIcon}/>
          <styled.AnnouncementTitle>{announceText}</styled.AnnouncementTitle>
  			 </styled.AnnouncementWrap> :
          <styled.AnnouncementWrap>
            <styled.AnnouncementIcon src={pinnedIcon}/>
            <styled.PinnedTitle>{pinnedText}</styled.PinnedTitle>
          </styled.AnnouncementWrap>
        }
  			<styled.AvatarAndInfoWrap>
	        {debug && <p>{data.id}</p>}
	        <styled.AvatarWrap>
	          <styled.ProfileAvatar src={profileUrl}/>
	          {showOnline && <styled.OnlineMark/>}
	        </styled.AvatarWrap>
	        <styled.InfoWrap>
		        <styled.Name>
	            {owner !== undefined ? `${owner.first_name} ${owner.last_name}` : null}
	            {showAdminBadge && <styled.ApprovedBadge src={approvedBadge}/>}
	          </styled.Name>
	          <styled.Timestamp>{messageDateFormat(created_at)}</styled.Timestamp>
	          {!url_meta ? <styled.Content>{urlInContent}</styled.Content> :
            <div>
            <styled.Content>{urlInContent}</styled.Content>
            <styled.UrlMeta>
              {url_meta.logo && <styled.MetaLogo src={url_meta.logo} />}
              {url_meta.publisher && <styled.Publisher>{url_meta.publisher}</styled.Publisher>}
              {url_meta.title && <styled.Title onClick={this.handleTitleClick}>{url_meta.title}</styled.Title>}
              <styled.Content>{url_meta.description}</styled.Content>
              {url_meta.image && <styled.MetaImage src={url_meta.image} />}
              {url_meta.author && <styled.Content>{url_meta.author}</styled.Content>}
              {url_meta.date && <styled.Content>{url_meta.date}</styled.Content>}
              <styled.Content>
                <a href={url_meta.url} onClick={this.handleClickLink}>{url_meta.url}</a>
              </styled.Content>
            </styled.UrlMeta>
            </div>}
            <styled.ImageGroupWrap>
            	{!attachment || (
                attachment.object_instances.map((instance, index) =>(
                 <div ref={this.imageContainerRef}>
                  <styled.Image 
                    key={index} 
                    src={instance.thumb_url} 
                    onClick={this.handleImageClick}
                    enlarged={enlarged}
                    ref={this.attachedImageRef}
                    />
                  </div>
                ))
              )}
            </styled.ImageGroupWrap>
            <styled.PDFGroupWrap>
              {attached_files_blobs && this.renderPDF(attached_files_blobs)}
            </styled.PDFGroupWrap>
            {(!this.isAnnouncement(data.post_type) && likes_count > 0) ? (liked ? 
                              <styled.LikeCountLiked>
                                <label>{likes_count}</label>
                              </styled.LikeCountLiked> :
                              <styled.LikeCount>
                                <label>{likes_count}</label>
                              </styled.LikeCount>) : 
                              <styled.LikeCountPlaceHolder/>}
            {(this.isAnnouncement(data.post_type) && showReadCount) && <styled.ReadCount>
                                <label>{readCount}</label>
                              </styled.ReadCount>}
	        </styled.InfoWrap>
  			</styled.AvatarAndInfoWrap>
      </styled.MessageWrap>

  	);
  }


}

SimplePost.propTypes = {
  //pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(SimplePost);