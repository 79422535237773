import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import '../../../App/fonts.scss';

class AvailabilityTableRowUserCell extends React.Component {
    state = {
    };
  
    constructor(props) {
      super(props);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    render() {
        const {user} = this.props;
        const userImageUrl = user && user.profile_image_thumb_url ?  user.profile_image_thumb_url : '/icons/default-profile-thumb.svg';

        return (
                <td className="avail-user-cell" >
                    <td>
                        <img className='avatar' src={userImageUrl}></img>
                    </td>
                    <td>
                        <span className='title-position'>
                            <span className='fs14 averta-semibold'>{user.first_name} {user.last_name}</span>
                            <span className='user-position'>{user.position}</span>
                        </span>
                    </td>
                </td> 
        );
    }

}

export default AvailabilityTableRowUserCell;