import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';


const FileNameWrap = styled.div `
  display: flex;
  flex-direction: column;
  //border: 1px solid blue;
`;

const FileName = styled.p `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0;
  //border: 1px solid red;
`;

const ScheduleId = styled.p `
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 11px;
  font-weight: 400;
  color: rgba(9,9,17,255);
  margin-bottom: 5px;
  //border: 1px solid red;
`;

const WinContainerRight = styled.section `
  top: ${(props) => props.top || '0px'};
  //left: ${(props) => props.left || '120px'};
  right: ${(props) => props.right || '20px'};
  minWidth: ${(props) => props.width || '1200px'};
  minHeight: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  border-radius: 8px;
  z-index: 2;
  padding-left: 10px;
  padding-right: 10px;
  //overflow: scroll;
  //border: 1px solid green;
  /*
  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }
  */

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const TableWrap = styled.section `
  //top: ${(props) => props.top || '0px'};
  //left: ${(props) => props.left || '120px'};
  //width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '430px'};
  position: relative;
  width: 100%;
  display: block;
  //height: 430px;
  overflow-y: scroll;
  //border: 1px solid blue;
`;


export default {
  FileNameWrap,
  FileName,
  ScheduleId,
  WinContainerRight,
  TableWrap,
};
