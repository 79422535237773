import { protectedAxiosInstance, axiosInstance, zeroParamProtectedAxiosInstance } from 'helpers/api-calls';
import { BASE_URL, BASE_SUBDOMAIN_URL } from '../../constants';
import { createHeadersForApiCall, getUserId, getSessionUukey, createHeadersForPDFUploading, createHeadersForUploadImage } from '../cookies';
import { StatusCheck } from '../authentication';

const getPosts = (params) =>
  protectedAxiosInstance.request({
    url: '/api/customer/user_role/posts',
    method: 'GET',
    params,
  });
export const getOnePost = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/posts/${postId}`,
    method: 'GET',
  });
const getPostLikeOwnerIds = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/compact/posts/${postId}/like_owner_ids`,
    method: 'GET',
    params: {
      per_page: 10000,
    },
  });

const deletePost = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/posts/${postId}`,
    method: 'DELETE',
  });

const getComments = (params) =>
  protectedAxiosInstance.request({
    url: '/api/customer/user_role/comments',
    method: 'GET',
    params,
  });

const deleteComment = (commentId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/comments/${commentId}`,
    method: 'DELETE',
  });

const likePost = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/posts/${postId}/like`,
    method: 'PUT',
  });

const unlikePost = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/posts/${postId}/unlike`,
    method: 'PUT',
  });

const readPost = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/posts/${postId}/read`,
    method: 'PUT',
  });

const pinPost = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/posts/${postId}/pin`,
    method: 'PUT',
  });

const unpinPost = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/posts/${postId}/unpin`,
    method: 'PUT',
  });

const getReadReceipts = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/posts/${postId}/receipts`,
    method: 'GET',
    params: {
      per_page: 10000,
    },
  });
const getReadReceiptIds = (postId) =>
  protectedAxiosInstance.request({
    url: `/api/compact/posts/${postId}/receipt_user_ids`,
    method: 'GET',
    params: {
      per_page: 10000,
    },
  });

const getChannelMembers = (channelId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/channels/${channelId}/member_users`,
    method: 'GET',
    params: {
      per_page: 10000,
    },
  });

const getSubscriptions = (channelId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/channels/${channelId}/subscriptions`,
    method: 'GET',
    params: {
      per_page: 10000,
    },
  });

const putChannelInfo = (channelId, name, description) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/channels/${channelId}`,
    method: 'PUT',
    data: {
      channel: {
        channel_name: name,
        description: description,
      },
    },
  });

const createPost = (post) =>
  protectedAxiosInstance.request({
    url: '/api/customer/user_role/posts',
    method: 'POST',
    data: {
      post: post,
    },
  });


export function createComment(content, source_id){
  const data = {
    content,
    source_id,
    owner_id: getUserId(),
  };

  return fetch(`https://${BASE_SUBDOMAIN_URL}/api/organization/comments`, {
    headers: createHeadersForApiCall(),
    method: 'POST',
    body: JSON.stringify(data),
  }).then((response) => {
    StatusCheck(response.status);
    return response.json();
  });

};


/**
 * UploadImage
 *
 * @param  {String} base64 image data url
 * @return {type}        description
 */
export function UploadImage(imageDataUrl, succeededCallback, failedCallback) {
  
  const payload = {
    "image":{
      "image_name": "post_image",
      "avatar_base": imageDataUrl,
    }
  }

  return fetch(`https://${BASE_URL}/api/customer/user_role/images?response[put_return_resource]=true`, {
    method: 'POST',
    /*
    headers: {
    'Content-Type': 'application/json',
    'X-Method': 'pass_verification',
    'Session-Token': 1333,
    Accept: 'application/vnd.Expresso.v6',
    Authorization:
    'Token token="b0b8ea419a31b9340b4c8bc646282f51", nonce="def"',
    'Access-Control-Allow-Origin': '*',
    },
    */
    headers: createHeadersForUploadImage(),
    body: JSON.stringify(payload)

  }).then((response) => {
    return response.json();
  });
}

/**
 * UnfurlURL
 *
 * @param  {String} user input url
 * @return {type}   description
 */
export function UnfurlURL(url){

  return  axiosInstance.request({
    url: `https://test-unfurl.myshyft.com/?url=${url}`,
    method: 'GET',
  });
}

export function UploadPDF(pdf) {
  var formData = new FormData();
  formData.append('file', pdf.file);

  /*
  //It is fine for uploading PDF, but the uploaded PDF cannot be displayed when clicking
  const blob = new Blob([JSON.stringify(pdf.dataUrl)], {type : 'application/pdf'});
  formData.append("file", blob, pdf.name);
  */
  return fetch(`https://${BASE_URL}/api/customer/uploader/file`, {
    headers: createHeadersForPDFUploading(),
    method: 'POST',
    body: formData,
  }).then((response) => {
    return response.json();
  }).catch((error) => {
    console.log(error);
  });

};

export function UploadPDF2(pdf) {
  var data = new FormData();
  //data.append("file", pdf.file, pdf.name);

  const blob = new Blob([JSON.stringify(pdf.dataUrl)], {type : 'application/pdf'});
  data.append("file", blob);
   
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;

  xhr.addEventListener("readystatechange", function() {
    if(this.readyState === 4) {
      console.log(this.responseText);
    }
  });

  xhr.open("POST", `https://${BASE_SUBDOMAIN_URL}/api/customer/uploader/file?response[put_return_resource]=true`);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader('Session-Uukey', getSessionUukey());
  
  xhr.send(data);

};

export const LoadApplicantPosts = (applicantId) =>  
  protectedAxiosInstance.request({
    url: `/api/compact/applicants/${applicantId}/free_posts`,
    method: 'GET',
  });

export const CreateApplicantFreePost= (applicantId, content) => {
   const freePostJson = {
    postable_id : applicantId,
    postable_type: "Applicant",
    content: content,
    title: "Free Post",
    post_type: "22"
  };

  return zeroParamProtectedAxiosInstance.request({
    url: `/api/customer/user_role/posts`,
    method: 'POST',
    data: {
      post: freePostJson,
    },
  });
}

export default {
  getPosts,
  deletePost,
  getComments,
  deleteComment,
  likePost,
  unlikePost,
  readPost,
  getReadReceipts,
  getChannelMembers,
  getSubscriptions,
  createPost,
  createComment,
  pinPost,
  unpinPost,
  getPostLikeOwnerIds,
  getReadReceiptIds,
  putChannelInfo,
  getOnePost,
  CreateApplicantFreePost,
};

