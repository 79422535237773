
const sortTypes = {
  DATE: 'DATE',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  POSITION: 'POSITION',
  STATUS: 'STATUS',
  USER_ROLE: 'USER_ROLE',
}

const statusTypes = {
  APPROVED: 'approved',
  PENDING: 'pending',
  ACTIVE: 'active',
  REJECTED: 'rejected',
  REMOVED: 'removed',
  WITHDRAWN: 'withdrawn',
  BLOCKED: 'blocked',
  POSTED: 'posted',
};

const userActionTypes = {
  APPROVE: 'APPROVE',
  DENY: 'DENY',
  APPROVE_APPLICANT: 'APPROVE_APPLICANT',
  DENY_APPLICANT: 'DENY_APPLICANT',
  APPROVE_RTO: 'APPROVE_RTO',
  DENY_RTO: 'DENY_RTO',
  APPROVE_MIX: 'APPROVE_MIX',
  DENY_MIX: 'DENY_MIX',
};

const bulkActionTypes = {
  ACTIVE_BULK: 'ACTIVE_BULK',
  PENDING_BULK: 'PENDING_BULK',
  SELECT_ACTIVE_BULK: 'SELECT_ACTIVE_BULK',
};

const categoryTypes = {
  REQUESTS: 'REQUESTS',
  RECORDS: 'RECORDS',
  LOCATION: 'LOCATION',
  DEPARTMENTS: 'DEPARTMENTS',
  GROUPS: 'GROUPS',
  SKILLS: 'SKILLS',
  LOGOUT: 'LOGOUT',
  INVITE_USER: 'INVITE_USER',
  DELETESCHEDULE: 'DELETESCHEDULE',
};

const operationTypes = {
  CREATE_GROUP: 'CREATE_GROUP',
  ASSIGN_USERS_TO_GROUP: 'ASSIGN_USERS_TO_GROUP',
  REMOVE_USERS_FROM_GROUP: 'REMOVE_USERS_FROM_GROUP',
  CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',
  ASSIGN_USERS_TO_DEPT: 'ASSIGN_USERS_TO_DEPT',
  REMOVE_USERS_FROM_DEPT: 'REMOVE_USERS_FROM_DEPT',
  CREATE_SKILL: 'CREATE_SKILL',
  ASSIGN_USERS_TO_SKILL: 'ASSIGN_USERS_TO_SKILL',
  REMOVE_USERS_FROM_SKILL: 'REMOVE_USERS_FROM_SKILL',
}

const filterTypes = {
  STATUS: 'STATUS',
  WEEKDAY: 'WEEKDAY',
  GROUP: 'GROUP',
  JOB_TITLE: 'JOB_TITLE',
  SKILL: 'SKILL',
}

export const MAX_BULK_USERS = 5;
export const MAX_USERS_REMOVE_FROM_GROUP = 1; //if removing n users, backend removed n-1 users. remove 1 user is ok 
export const USERS_PER_PAGE = 5000;
export const RTOS_PER_PAGE = 2000;

export default {
  statusTypes,
  userActionTypes,
  bulkActionTypes,
  categoryTypes,
  operationTypes,
  sortTypes,
  filterTypes,
};
