
import React from 'react';
import '../../../../App/fonts.scss';
import '../../../../App/colors.scss';
import '../../../../App/layout.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

import {Mixpanel} from '../../../../Mixpanel';

import './ScheduleSearchFilterComponent.scss';

class SelectDepartmentPopover extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isShowingDepartmentList: false,
      selectedDepartment: null,
    };
  }

  handleToggleDepartmentList = () => {
    const {isShowingDepartmentList} = this.state;
    if( isShowingDepartmentList == true ){
      this.setState({isShowingDepartmentList: false});
    }else{
      this.setState({isShowingDepartmentList: true});
    }
  }

  departmentSelected = (e) => {
    this.setState({selectedDepartment: e, isShowingDepartmentList: false});
  }

  handleDoneClick = (e) => {
    const {handleDepartmentSelect} = this.props;
    const {selectedDepartment} = this.state;
    handleDepartmentSelect(selectedDepartment);
    Mixpanel.track('Confirm Dept To Filter');
  }

  handleClearAllClick = (e) => {
    this.setState({selectedDepartment: null, isShowingDepartmentList: false});
    Mixpanel.track('Tap Dept Clear All');
  }

  render() {
    const {departments} = this.props;
    const {isShowingDepartmentList, selectedDepartment} = this.state;
   
    const isText = (<FormattedMessage id="SearchFilter.is" defaultMessage="is" />);
    const clearAllText = (<FormattedMessage id="SearchFilter.clearAll" defaultMessage="Clear all" />);
    const doneText = (<FormattedMessage id="SearchFilter.done" defaultMessage="Done" />);

    // console.log(JSON.stringify(departments));

    const departmentList = departments.map( (dep) => {
      return ( <li key={dep.id} onClick={ (e) => {this.departmentSelected(dep);} }>
                  <span> {dep.name} ({dep.user_ids.length})</span>  
              </li> )
    });

    return (
        <div className='select-department-popover'>
            <div className='flex-row top-row'>
                <label>{isText}</label>
                <button className='clear-all-btn' onClick={() => {this.handleClearAllClick();}}> {clearAllText}</button>
            </div>

            <button className={ isShowingDepartmentList == true ? 'department-button arrow-up' : 'department-button arrow-down' }
              onClick={ () => {this.handleToggleDepartmentList();} }> {selectedDepartment && selectedDepartment.name} </button>

            <div className={ isShowingDepartmentList == true ? 'department-dropdown shown' : 'department-dropdown not-shown' }>
                <ul>
                    {departmentList}
                </ul>
            </div>

            <button className='done-button' onClick={ () => {this.handleDoneClick();} }>{doneText}</button>
        </div>
    );
  }
}


export default injectIntl(SelectDepartmentPopover);
