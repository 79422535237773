import { createSlice } from '@reduxjs/toolkit';
import { cleanUsersArr } from 'helpers/formatting';

const userPrivileges = createSlice({
  name: 'userPrivileges',
  initialState: {
    data: [],      // Array for ordered access
    dataMap: {},   // Object for O(1) lookup by owner_id
    loading: true,
    page: 1,
    totalPages: 1,
  },
  reducers: {
    requestMembers: (state, action) => {
      state.loading = true;
      state.page = action.payload;
    },
    hydrateEmployees: (state, action) => {
      const usersArray = action.payload;
      state.data = usersArray; // Set data array as usual
      state.dataMap = {};      // Initialize dataMap

      usersArray.forEach(user => {
        state.dataMap[user.owner_id] = user;  // Populate dataMap with O(1) lookup structure
      });

      state.loading = false;
    },
  },
});

export default userPrivileges;