import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthenticationContainer from 'containers/AuthenticationContainer';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components/macro';
import { createBrowserHistory } from 'history';

import { SignupConfirmationPage } from 'pages/SignupConfirmationPage';
import { SignupPage } from 'pages/SignUp';
import { LoginPage } from 'pages/Login';
import { DemoLoginPage } from 'pages/Login/DemoLoginPage';
import { ForgotPasswordPage } from 'pages/ForgotPassword';
import { ChangePasswordPage } from 'pages/ChangePassword';

import TeamProfileContainer from 'pages/TeamProfile/TeamProfileContainer';
import TeamFeed from 'pages/TeamFeed';
import FooterContainer from 'containers/FooterContainer';
import PostShiftContainer from 'pages/PostShift/PostShiftContainer';
import SchedulerContainer from 'pages/Scheduler/SchedulerContainer';
import ShiftsContainer from 'pages/Shift/ShiftContainer';
import UserManagementContainer from 'pages/UserManagement/UserManagementContainer';
import SettingsContainer from 'pages/Settings/SettingsContainer';
import ScheduleTabContainer from 'pages/ScheduleTab/ScheduleTabContainer';

import { MainTheme, GlobalStyle } from '@shyft/acorns';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import 'stylesheets/all.scss';
import './App.scss';
import IntlContainer from './IntlContainer';
import { PUBNUB_SUBSCRIBE_KEY } from '../constants';

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import mixpanel from 'mixpanel-browser';
import RequestsV3TabContainer from 'pages/RequestsV3/RequestsV3TabContainer';
import NavPanel from 'components/NavPanel/NavPanelContainer';
import HorizontalNavPanel from 'components/NavPanel/HorizontalNavPanelContainer';
import StartSignUpPage from 'pages/SignUp/StartSignUpPage';
import RegistrationProfilePage from 'pages/SignUp/RegistrationProfilePage';
import RegistrationLocationPage from 'pages/SignUp/RegistrationLocationPage';
import RegistrationAccountPage from 'pages/SignUp/RegistrationAccountPage';
import RegistrationLocationConfirmPage from 'pages/SignUp/RegistrationLocationConfirmPage';
import GoogleMapsLoader from './GoogleMapsLoader';
import RegistrationAccountVerificationPage from 'pages/SignUp/RegistrationAccountVerificationPage';

// Initialize PubNub
const pubNubClient = new PubNub({
  subscribeKey: PUBNUB_SUBSCRIBE_KEY,
  restore: true,
  keepAlive: true,
});

const history = createBrowserHistory();
const showNavBar = false;
const mainBodyClassName = showNavBar ? 'main-body' : 'main-body-row';

// Route Configurations with routeProps
const publicRoutes = [
  { path: '/sign-up', component: StartSignUpPage, exact: true },
  { path: '/sign-up/profile', component: RegistrationProfilePage },
  { path: '/sign-up/location', component: RegistrationLocationPage },
  { path: '/sign-up/account', component: RegistrationAccountPage },
  { path: '/sign-up/verification', component: RegistrationAccountVerificationPage },
  { path: '/sign-up/confirm-location', component: RegistrationLocationConfirmPage },
  { path: '/users/confirmation', component: SignupConfirmationPage },
  { path: '/login', component: LoginPage },
  { path: '/password-reset', component: ForgotPasswordPage },
  { path: '/users/password/edit', component: ChangePasswordPage },
];

const protectedRoutes = [
  { path: '/requests/:module', component: RequestsV3TabContainer },
  { path: '/post_shift', component: PostShiftContainer },
  { path: '/usermanagement', component: UserManagementContainer },
  { path: '/team-feed', component: TeamFeed },
  { path: '/scheduler', component: SchedulerContainer },
  { path: '/shift', component: ShiftsContainer },
  { path: '/schedulecalendar', component: ScheduleTabContainer },
  { path: '/', component: ScheduleTabContainer, exact: true },
  { path: '/settings/:module', component: SettingsContainer },
];

const protectedRoutesWithAuth = [
  ...protectedRoutes.filter(route => route.path !== "/team/:id"), // Exclude /team/:id
  { path: "/team/:id", component: TeamProfileContainer }, // This one does NOT need authentication
];

const App = ({ store }) => (
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <PubNubProvider client={pubNubClient}>
        <ThemeProvider theme={MainTheme}>
          <IntlContainer>
            <GoogleMapsLoader>
              <Router>
                <Switch>
                  {/* Public Routes */}
                  {publicRoutes.map(({ path, component, exact = false }) => (
                    <Route key={path} path={path} component={component} exact={exact} />
                  ))}

                  {/* Protected Routes with Authentication & routeProps */}
                  <>
                    <main className={mainBodyClassName}>
                      <GlobalStyle />
                      <HorizontalNavPanel history={history} />
                      <div className="content-wrapper">
                        <NavPanel history={history} />
                        <div className="main-content">
                          <Switch>
                            <Route path="/team/:id" exact component={TeamProfileContainer} />

                            {protectedRoutesWithAuth.map(({ path, component, exact = true }) => (
                              <AuthenticationContainer
                                key={path}
                                path={path}
                                exact={exact}
                                component={component}
                                routeProps={{
                                  exact,
                                  path,
                                  component
                                }}
                              />
                            ))}

                          </Switch>
                        </div>
                      </div>
                    </main>
                    <FooterContainer />
                  </>
                </Switch>
              </Router>
            </GoogleMapsLoader>
          </IntlContainer>
        </ThemeProvider>
      </PubNubProvider>
    </Provider>
  </DndProvider>
);

export default App;
