import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import Modal from 'components/GeneralComponents/Modal';
import constants from '../../../constants';
import styled from './styled';
import messages from '../../../../../components/GeneralComponents/TableComponent/TableRow/messages';
import { shiftDuration, shiftDurationInMin } from '../../../../../helpers/date-time';

class RequestCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: false,
      mouseOverBottomLine: false,
      bottomLinePos: null,
    };

    this.bottomLineRef = React.createRef();
    this.oneItemRef = React.createRef();

    this._isMounted = false;
  }

  componentDidMount() {
    const { user } = this.props;

    this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {
    const { bottomLinePos, } = this.state;
  }

  componentWillUnmount() {

    this._isMounted = false;
  }

  mouseEnterBL = (event) => {
    this.setState({ mouseOverBottomLine: true });
  }

  mouseLeaveBL = (event) => {
    this.setState({ mouseOverBottomLine: false });
  }

  mouseEnterOneItem = (event) => {
    this.setState({ mouseOverBottomLine: true });
  }

  mouseLeaveOneItem = (event) => {
    this.setState({ mouseOverBottomLine: false });
  }

  renderBottomLineMouseTip = (content) => {
    const { bottomLinePos, } = this.state;

    if (bottomLinePos) {
      const bottomLineContainerStyle = {
        top: bottomLinePos.top,
        left: bottomLinePos.left,
        width: bottomLinePos.width,
        height: bottomLinePos.height,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        zIndex: 2,
        borderRadius: '5px',
        paddingTop: '5px',
        //border: '1px solid red',
      };
      const test = `top: ${bottomLinePos.top}, left: ${bottomLinePos.left}, w: ${bottomLinePos.width}, h: ${bottomLinePos.height}`;
      return (
        <div style={bottomLineContainerStyle}>
          <div className="mouse-tip__top-wrap">{test}</div>
        </div>
      );
    }
  }

  render() {
    const { shift, isParent } = this.props;
    const { debug, mouseOverBottomLine, } = this.state;
    const { statusTypes } = constants;

    // let userImageUrl = '/icons/default-profile-thumb.svg';
    let userImageUrl = "/icons/schedule-icon.svg";
    // const userName = user ? `${user.first_name} ${user.last_name}` : '';
    // const approvedBadge = '/icons/verified-badge-icon.svg';
    // const placeholder = '';

    // const allowProfile =
    //   user.status !== statusTypes.REMOVED &&
    //   user.status !== statusTypes.REJECTED &&
    //   user.status !== statusTypes.WITHDRAWN &&
    //   user.status !== statusTypes.BLOCKED;

    let shiftType = '';
    let bottomLine = '';
    let bottomLineFirst = '';
    let bottomLineSecond = '';
    let bottomLineThird = '';
    let avatarImageDisplay = null;
    let topLineSecondDisplay = null;

    const isShift = shift.time_off_reason === undefined;

    // RTOs
    if (!isShift) {
      shiftType = messages.rto;
      avatarImageDisplay = (<styled.OwnerAvatar src="/icons/rto-icon.svg" iconOnly />);
      // if (shift.total_shifts_available) {
      //   bottomLineFirst = shift.total_shifts_available + " open shifts";
      // }
      bottomLineSecond = shift ? `${(shift.day_hours * shift.days).toFixed(2)} hours requested` : '';
    }
    // shift types
    else if (isShift && shift.name === 'open_shift') {
      shiftType = messages.openShift;

      avatarImageDisplay = (isParent ? <styled.OwnerAvatar src="/icons/schedule-icon.svg" iconOnly /> : <styled.OwnerAvatar src="/icons/open-child-icon.svg" iconOnly />);
      topLineSecondDisplay = shift.primary_job;
      if (shift.total_shifts_available) {
        bottomLineFirst = shift.total_shifts_available + " open shifts";
      }

      if (shift.current_applicants_approved_user_ids && shift.current_applicants_denied_user_ids
        && shift.current_applicants_pending_user_ids
      ) {
        bottomLineSecond =
          shift.current_applicants_approved_user_ids.length +
          shift.current_applicants_denied_user_ids.length +
          shift.current_applicants_pending_user_ids.length + " applied";
      }
      if (shift.current_applicants_approved_user_ids) {
        bottomLineThird = shift.current_applicants_approved_user_ids.length + " approved";
      }
    } else if (isShift && (shift.name === 'shift' || shift.name === 'shift_inventory')) {
      shiftType = messages.teamShift;
      avatarImageDisplay = (<styled.OwnerAvatar src={shift.owner.profile_image
        ? shift.owner.profile_image.thumb_url
        : '/icons/default-profile-thumb.svg'} rounded />);
      topLineSecondDisplay = shift.owner.first_name + " " + shift.owner.last_name;
      if (shift.primary_job) {
        bottomLineFirst = shift.primary_job;
      }
      if (shift.badge_id) {
        bottomLineSecond = shift.badge_id;
      }
      // bottomLineThird = shiftDurationInMin(shift.start_at, shift.end_at).toFixed(2) + " hrs";
    }
    else if (isShift && shift.name === 'shift_vto') {
      shiftType = messages.vto;
      avatarImageDisplay = (isParent ? <styled.OwnerAvatar src="/icons/vto-icon.svg" iconOnly /> : <styled.OwnerAvatar src="/icons/vto-child-icon.svg" iconOnly />);
      topLineSecondDisplay = shift.primary_job;

      if (shift.total_shifts_available) {
        bottomLineFirst = shift.total_shifts_available + " VTOs";
      }

      if (shift.current_applicants_approved_user_ids && shift.current_applicants_denied_user_ids
        && shift.current_applicants_pending_user_ids
      ) {
        bottomLineSecond =
          shift.current_applicants_approved_user_ids.length +
          shift.current_applicants_denied_user_ids.length +
          shift.current_applicants_pending_user_ids.length + " applied";
      }
      if (shift.current_applicants_approved_user_ids) {
        bottomLineThird = shift.current_applicants_approved_user_ids.length + " approved";
      }
    } else {
      avatarImageDisplay = (<styled.OwnerAvatar src="/icons/default-profile-thumb.svg" rounded />);
    }

    bottomLine = (
      <styled.BottomLineWrap>
        {bottomLineFirst && <styled.BottomLineText>{bottomLineFirst}</styled.BottomLineText>}
        {bottomLineFirst && bottomLineSecond && <styled.Dot />}
        {bottomLineSecond && <styled.BottomLineText isFullText={!isShift}>{bottomLineSecond}</styled.BottomLineText>}
        {(bottomLineFirst || bottomLineSecond) && bottomLineThird && <styled.Dot />}
        {bottomLineThird && <styled.BottomLineText>{bottomLineThird}</styled.BottomLineText>}

      </styled.BottomLineWrap>);


    return (
      <styled.TopWrap>
        {avatarImageDisplay}
        <styled.OwnerInfoWrap>
          <styled.TopLineWrap>
            <styled.TopLineRequestsText>{shiftType.defaultMessage}</styled.TopLineRequestsText>
            {topLineSecondDisplay && (
              <>
                <styled.Dot />
                <styled.TopLineRequestsText>{topLineSecondDisplay}</styled.TopLineRequestsText>
              </>
            )}
          </styled.TopLineWrap>
          {debug ? user.owner_id : bottomLine}
        </styled.OwnerInfoWrap>
        {/*mouseOverBottomLine && <Modal>
          {this.renderBottomLineMouseTip(bottomLine)}
        </Modal>*/}
      </styled.TopWrap>
    );
  }
}


RequestCell.propTypes = {
  shift: PropTypes.object.isRequired,
};

export default injectIntl(RequestCell);