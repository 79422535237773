import PlacesAutocomplete from 'react-places-autocomplete';
import React from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../components/GeneralComponents/RegistrationDesign.scss'
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import messages from '../AddLocation/messages'
import { SearchLocation, LocationJoin } from 'helpers/api-calls';
import { zip } from 'lodash';
class RegistrationLocationPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            address: '', // Location for Google Places Autocomplete
            isGoogleApiLoaded: false, // Track whether Google Maps API is loaded
            placeDetails: null,
            isFormValid: false,
            isCreateLocationSelected: true,
            showZipCodeInput: false,
            zipCode: '',
            locationCode: '',
            isCodeValid: false,
            location: {
                isLoading: false,
                hasBeenCalled: false,
                data: null,
            },
            showError: false,
            joinError: '',
            step: 1,
        };
    }

    componentDidMount() {
        // Check if Google Maps API is already loaded when the component mounts
        const { location } = this.props;

        if (window.google && window.google.maps) {
            if (location && location.state) {
                this.setState({
                    userName: location.state.userName,
                    isCreateLocationSelected: location.state.isCreateLocationSelected,
                    isGoogleApiLoaded: true
                });
            }
        } else {
            // If API is not loaded, redirect to Registration1
            this.props.history.push('/');
            return null;
        }
    }

    handleAddressChange = (address) => {
        this.setState({
            address,
            showZipCodeInput: address.length > 0
        }, this.validateForm);
    };

    handleZipCodeChange = (e) => {
        this.setState({
            zipCode: e.target.value
        }, this.validateForm);
    };

    handleAddressSelect = (address, placeId) => {
        const { userName } = this.state;

        // Use Google Maps API to fetch the place details
        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({ placeId }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                let zipCodeTemp = "";
                if (place.address_components) {
                    place.address_components.forEach(component => {
                        if (component.types.includes("postal_code")) {
                            zipCodeTemp = component.long_name; // Extract ZIP code
                        }
                    });
                }

                this.setState({
                    placeDetails: place, // Store the full place object
                    address: address, // Update address in state
                    zipCode: zipCodeTemp
                },
                    this.validateForm);
            }
        });
    };

    validateForm = () => {
        const { placeDetails, zipCode, showZipCodeInput, address } = this.state;

        // Check if all fields are valid
        this.setState({
            isFormValid: ((placeDetails && placeDetails.place_id) || (address && showZipCodeInput && zipCode && zipCode.length > 3)),
        });
    };

    handleNext = () => {
        const { userName, address, placeDetails, isCreateLocationSelected, zipCode, showZipCodeInput } = this.state;

        if (userName && ((placeDetails && placeDetails.place_id) || (address && showZipCodeInput && zipCode && zipCode.length > 3))) {
            const state = {
                userName,
                address,
                zipCode,
                isCreateLocationSelected,
                ...(placeDetails
                    ? {
                        placeDetails: {
                            googlePlaceID: placeDetails.place_id,
                            googleFormattedAddress: placeDetails.formatted_address,
                            googleBusinessName: placeDetails.name,
                            latitude: placeDetails.geometry.location.lat(),
                            longitude: placeDetails.geometry.location.lng(),
                        },
                    }
                    : null),
            };

            this.props.history.push({
                pathname: '/sign-up/account',
                state: state,
            });
        }
    };

    switchLocationSelection = () => {
        this.setState({
            isCreateLocationSelected: !this.state.isCreateLocationSelected,
        });
    }

    _handleLocationCodeChange = (e) => {
        const { value } = e.target;

        this.setState({
            locationCode: value,
            isCodeValid: value.length >= 6 && value.length <= 10,
            showError: false,
        });
    };

    _handleSubmitLocationCode = (e) => {
        const { isCodeValid } = this.state;
        if (isCodeValid) {
            this._submitLocationCode();
        } else {
            this.setState({
                showError: true,
            });
        }
    };

    _submitLocationCode = () => {
        const { intl } = this.props;
        const { location, locationCode } = this.state;
        this.setState({
            location: Object.assign(location, {
                isLoading: true,
            }),
            joinError: '',
            step: 1,
        });
        // call api
        SearchLocation(locationCode).then(
            (response) => {
                if (response.status === 200) {
                    this.setState({
                        location: Object.assign(location, {
                            hasBeenCalled: true,
                            isLoading: false,
                            data: response.data.location,
                        }),
                        step: 2,
                    });

                    const { userName, isCreateLocationSelected } = this.state;

                    if (userName) {
                        const state = {
                            userName,
                            location: response.data.location,
                            isCreateLocationSelected,
                        };

                        this.props.history.push({
                            pathname: '/sign-up/confirm-location',
                            state: state,
                        });
                    }
                }

                if (response.status === 404) {
                    this.setState({
                        location: Object.assign(location, {
                            hasBeenCalled: true,
                            isLoading: false,
                            data: null,
                        }),
                        joinError: intl.formatMessage(messages.locationNotFoundError),
                        step: 1,
                    });
                }
            },
            ({ response }) => {
                if (response && response.status === 404) {
                    this.setState({
                        location: Object.assign(location, {
                            hasBeenCalled: true,
                            isLoading: false,
                            data: null,
                        }),
                        joinError: intl.formatMessage(messages.locationNotFoundError),
                        step: 1,
                    });
                } else {
                    this.setState({
                        location: Object.assign(location, {
                            hasBeenCalled: true,
                            isLoading: false,
                            data: null,
                        }),
                        joinError: intl.formatMessage(messages.ourSideError),
                        step: 1,
                    });
                }
            }
        );
    };

    _renderInputFormErrorDescription = () => {
        const { intl } = this.props;
        const { showError, joinError } = this.state;

        if (showError) {
            return intl.formatMessage(messages.codeLengthError);
        } else {
            if (joinError) {
                return joinError;
            }
        }
        return '';
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const { isCreateLocationSelected } = this.state;
            if (isCreateLocationSelected) {
                this.handleNext();
            } else {
                this._handleSubmitLocationCode();
            }
        }
    };

    render() {
        const { userName, address, placeDetails, isGoogleApiLoaded,
            isFormValid, isCreateLocationSelected,
            showError, location, joinError, step, showZipCodeInput, zipCode, isCodeValid } = this.state;
        const { intl } = this.props;

        return (
            <RegistrationLayout>
                <div className="form-content">
                    <div className="welcome-text-wrapper">
                        <div className="dot"></div>
                        <div className="welcome-text">Finding location</div>
                    </div>
                    <div className="main-heading">Now, let's find where you work.</div>

                    <div className="name-prompt">{isCreateLocationSelected ? "What's your company name?" : "Enter your unique 6-digit location code"}</div>

                    {!isCreateLocationSelected && (
                        <>
                            <div className="icon-input-container">
                                <input
                                    type="text"
                                    placeholder="SHY1234"
                                    className="icon-name-input"
                                    name="locationcode"
                                    onKeyDown={this.handleKeyDown}
                                    onChange={this._handleLocationCodeChange}
                                />
                                <img
                                    className="input-icon-left"
                                    src='/icons/pin-entry-icon.svg'
                                />
                            </div>

                            <p className="error-message">
                                {this._renderInputFormErrorDescription()}
                            </p>
                        </>
                    )}

                    {isCreateLocationSelected && isGoogleApiLoaded && (
                        <PlacesAutocomplete
                            value={address}
                            onChange={this.handleAddressChange}
                            onSelect={this.handleAddressSelect} // Optionally handle the selection of an address
                            searchOptions={{
                                types: ['establishment'], // Restrict results to US, or update as needed
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className='name-input-container'>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search for company',
                                            className: 'name-input',
                                        })}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    <div className="country-dropdown">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion, index) => {
                                            const regex = new RegExp(`(${address})`, 'gi');
                                            const highlightedText = suggestion.description.replace(
                                                regex,
                                                `<strong>$1</strong>`
                                            );

                                            return (
                                                <div
                                                    key={index}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className: 'suggestion-item',
                                                    })}
                                                    dangerouslySetInnerHTML={{ __html: highlightedText }}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    )}

                    {showZipCodeInput && (
                        <>
                            <div className="name-prompt">What's your location zip code?</div>
                            <div className="name-input-container">
                                <input
                                    type="text"
                                    placeholder="Enter ZIP Code"
                                    className="name-input"
                                    value={zipCode}
                                    onChange={this.handleZipCodeChange}
                                    onKeyDown={this.handleKeyDown}
                                />
                            </div>
                        </>
                    )}

                    <button
                        className="next-btn"
                        onClick={isCreateLocationSelected ? this.handleNext : this._handleSubmitLocationCode}
                        disabled={isCreateLocationSelected ? !isFormValid : !isCodeValid}>Next</button>

                    <div className="login-message">
                        {isCreateLocationSelected ? "Know your location code?" : "Don't know your location code?"}{' '}
                        <a className="login-message-link" onClick={this.switchLocationSelection}>
                            {isCreateLocationSelected ? "Search by code" : "Search for location"}
                        </a>
                    </div>


                    <div className="login-message">
                        Already have an account?{' '}
                        <Link className="login-message-link" to="/login">
                            Login
                        </Link>

                    </div>
                </div>
            </RegistrationLayout>
        );
    }
}

const RegistrationLocationPagei18n = injectIntl(RegistrationLocationPage);

export default withRouter(RegistrationLocationPagei18n);