import React from 'react';
import { connect } from 'react-redux';
import DroppableTableCell from './DroppableTableCell';
import { scheduleCalendarOperations } from 'pages/ScheduleCalendar/duck';
import timeUtil from 'pages/ScheduleCalendar/duck/timeUtil';
import { isValidObject } from 'helpers/validation';

const mapStateToProps = ({
  scheduleCalendarReducer,
}) => {
  return {
    allUsers: scheduleCalendarReducer.allUsers.users,
  };
};

const mapDispatchToProps = (dispatch) => {

  const copyShiftToUserAndDateDispatch = (shiftToCopy, user, date, locationId) => {
    const targetStartTime = timeUtil.pastedShiftStartTime(shiftToCopy, date);
    const targetEndTime = timeUtil.pastedShiftEndtime(shiftToCopy, date);
    const targetJobTitle = shiftToCopy.primary_job;

    if( user.status !== 'unregistered') {
      dispatch(scheduleCalendarOperations.addDraftShiftToUserOnDateOperation(user.owner_id, targetStartTime, targetEndTime, targetJobTitle, locationId));
    }else{
      dispatch(scheduleCalendarOperations.addDraftShiftToUnregisteredUserOnDateOperation(user.id, targetStartTime, targetEndTime, targetJobTitle, locationId));
    }
  }

  const moveDraftShiftDispatch = (shift, targetUser, targetDate) => {
    if( shift !== null ) {
      console.log("Move Dispatch: " + JSON.stringify(shift) + "\n" + JSON.stringify(targetUser), + "\n" + JSON.stringify(targetDate));
      
      var isMoveToNewDate = false;
      var targetStartTime = null;
      var targetEndTime = null;

      if( timeUtil.haveSameDate(shift.start_at, targetDate) == false) {
        console.log("Different Day: " + shift.start_at + ' - ' + targetDate);
        isMoveToNewDate = true;
        //Calculate targetStartTime & targetEndTime
        targetStartTime = timeUtil.calculateTargetTime(shift.start_at, targetDate);
        const diff = timeUtil.shiftDuration(shift.start_at, shift.end_at);
        targetEndTime = timeUtil.addTimeDiffTo(targetStartTime, diff);

        console.log("From: " + shift.start_at + " - " + shift.end_at + ' to ' + targetStartTime + ' -'  + targetEndTime);
      }else{
        console.log("Same Day: " + shift.start_at + ' - ' + targetDate);
      }

      var isMovingToUnregisteredUser = false;
      var targetRegisteredUserId = null;
      var targetUnregisteredUserId = null;
      if( isValidObject(targetUser) == true ){
        if( targetUser.status === 'unregistered' ){
          isMovingToUnregisteredUser = true;
          targetUnregisteredUserId = targetUser.id;
        }else{
          targetRegisteredUserId = targetUser.owner_id;
        }
      }

      dispatch(scheduleCalendarOperations.moveDraftShiftOperation(shift.id, targetRegisteredUserId, targetUnregisteredUserId, targetStartTime, targetEndTime));
    }
  }

  return {
    moveDraftShiftDispatch,
    copyShiftToUserAndDateDispatch,
  };
};

const DroppableTableCellContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {
    user,
    date,
    shiftsForDay,
    weekRangeStart,
    selectedDateToAddShift,
    handleOpenPopup,
    isCopyingShift,
    shiftToCopy,
    
    allUsers,
    handleTooltipHide,
    handleTooltipShow,

    copyShiftToUserAndDateDispatch,
    moveDraftShiftDispatch,
    } = props;

   return <DroppableTableCell 
    user={user} 
    date={date.clone()} 
    shiftsForDay={shiftsForDay} 
    weekRangeStart={weekRangeStart}
    selectedDateToAddShift={selectedDateToAddShift} 
    handleOpenPopup={handleOpenPopup} 
    isCopyingShift={isCopyingShift} 
    shiftToCopy={shiftToCopy}
    
    allUsers = {allUsers}
    handleTooltipHide = {handleTooltipHide}
    handleTooltipShow = {handleTooltipShow}

    copyShiftToUserAndDateDispatch={copyShiftToUserAndDateDispatch} 
    moveDraftShiftDispatch = {moveDraftShiftDispatch}
   />
});

export default DroppableTableCellContainer;