import axios from 'axios';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';

import feedCalls from 'helpers/api-calls/feed-calls';
import teamCalls from 'helpers/api-calls/team-calls';
import actions from './actions';
import constants from './constants';
import { GetLocationChannels, } from 'helpers/api-calls/user-calls';
import { appActions } from 'App/duck';
import { getUserId } from 'helpers/cookies';


const { params } = constants;

const fetchPosts = (channel_id) => (dispatch) => {
  dispatch(actions.requestPosts(1));

  return feedCalls.getPosts(params.getPosts(channel_id, 1)).then((response) => {
    const {
      data: { posts, meta },
    } = response;

    dispatch(
      actions.hydratePosts({
        data: posts,
        total_pages: meta.pagination.total_pages,
      })
    );
    dispatch(appActions.ResetNotifyNewPost(channel_id));
  });
};

const fetchMorePosts = (channel_id, page) => (dispatch) => {
  dispatch(actions.requestPosts(page));

  return feedCalls
    .getPosts(params.getPosts(channel_id, page))
    .then((response) => {
      dispatch(actions.updatePosts(response.data.posts));
    });
};

const fetchReadReceipts = (post_id, channel_id) => (dispatch) => {
  dispatch(actions.requestReadReceipts());

  axios
    .all([
      feedCalls.getReadReceipts(post_id),
      feedCalls.getChannelMembers(channel_id),
    ])
    .then(
      axios.spread((receipts, members) => {
        dispatch(
          actions.updateReadReceipts({
            read_receipts: receipts.data.receipts,
            channel_members: members.data.users,
          })
        );
      })
    );
};

const fetchSubscriptions = (channel_id) => (dispatch) => {
  feedCalls.getSubscriptions(channel_id).then((response) =>{
    dispatch(actions.updateActiveChannelAdmin(response.data.subscriptions[0]));
    dispatch(actions.updateActiveChannelSubscriptions(response.data.subscriptions[0]));
  }).catch((error) =>{
    console.log(error);
  })
};

const createPost = (post) => (dispatch) =>
  feedCalls.createPost(post).then((response) => {
    dispatch(actions.createPost(response.data.post));
    const lastContent = `${response.data.post.owner.first_name} ${response.data.post.owner.last_name}: ${response.data.post.content ? response.data.post.content : ''}`;
    const payload = {
      channel_id: response.data.post.channel_id,
      content: lastContent,
      updated_at: response.data.post.created_at,
    }
    dispatch(appActions.updateChannelLastContent(payload));
  });

const fetchOnePost = (postId) => (dispatch) => 
  feedCalls.getOnePost(postId).then((response) => {
    const lastContent = `${response.data.post.owner.first_name} ${response.data.post.owner.last_name}: ${response.data.post.content ? response.data.post.content : ''}`;
    const payload = {
      channel_id: response.data.post.channel_id,
      content: lastContent,
      updated_at: response.data.post.created_at,
    }
    dispatch(appActions.updateChannelLastContent(payload));
  }).catch((error) => {
    console.log(`fetchOnePost failed. postId: ${postId}`);
  });

const createComment = (content, source_id) => (dispatch) =>
  feedCalls.createComment(content, source_id).then((response) => {
    dispatch(actions.createComment(response.comment));
    dispatch(actions.incrementPostCommentCount({postId: source_id, commentId: 0}));
  })
  .catch((error) =>{
      console.log(error);
  });

const deletePost = (postId, lastPost) => (dispatch) =>
  feedCalls
    .deletePost(postId)
    .then((response) => {
      dispatch(actions.updateActivePost({id: 0, channel_name: ''}));
      dispatch(actions.deletePost(postId));
      dispatch(actions.clearActiveDelete());
      dispatch(actions.updateActiveSidePane(''));
    })
    .catch((error) => {
      const {
        response: { status },
      } = error;

      if (status === 404) {
        // Silently succeed deleting already deleted post
        dispatch(actions.updateActivePost({id: 0, channel_name: ''}));
        dispatch(actions.deletePost(postId));
        dispatch(actions.clearActiveDelete());
        dispatch(actions.updateActiveSidePane(''));
      } else {
        dispatch(
          actions.updateActiveError({
            code: error.response.status,
            action: actions.deletePost.toString(),
          })
        );
      }
    });

const updateDeletePost = (postId) => (dispatch) => {
  //dispatch(actions.updateActivePost({id: 0, channel_name: ''}));
  dispatch(actions.deletePost(postId));
  dispatch(actions.clearActiveDelete());
  //dispatch(actions.updateActiveSidePane(''));
};

const updateDeleteActivePost = (postId) => (dispatch) => {
  dispatch(actions.updateActivePost({id: 0, channel_name: ''}));
  dispatch(actions.deletePost(postId));
  dispatch(actions.clearActiveDelete());
  dispatch(actions.updateActiveSidePane(''));
};


const pinPost = (post_id) => (dispatch) => {
  return feedCalls.pinPost(post_id).then(() => {
    dispatch(actions.updatePost({ id: post_id, pinned: true }));
  });
};

const updatePinPost = (post_id) => (dispatch) => {
  dispatch(actions.updatePost({ id: post_id, pinned: true }));
};

const unpinPost = (post_id) => (dispatch) => {
  return feedCalls.unpinPost(post_id).then(() => {
    dispatch(actions.updatePost({ id: post_id, pinned: false }));
  });
};

const updateUnpinPost = (post_id) => (dispatch) => {
  dispatch(actions.updatePost({ id: post_id, pinned: false })); 
};

const likePost = (post_id) => (dispatch) => {
  return feedCalls.likePost(post_id).then(() => {
    const payload = {
      id: post_id,
      changeState: true,
    };
    dispatch(actions.incrementLikeCount(payload));
  }).catch((error) =>{
    console.log(`likePost: error: ${error}`);
  });
};

const getPostLikeList = (post_id) => (dispatch) => {
  return feedCalls.getPostLikeOwnerIds(post_id).then((response) => {
    const payload = {
      postId: post_id,
      likeList: response.data.ids,
    };
    dispatch(actions.receiveLikeList(payload));
  }).catch((error) =>{
    console.log(`getPostLikeList: error: ${error}`);
  });
};

const getPostReaderList = (post_id) => (dispatch) => {
  return feedCalls.getReadReceiptIds(post_id).then((response) => {
    const payload = {
      postId: post_id,
      readList: response.data.ids,
    };
    dispatch(actions.receiveReadList(payload));
  }).catch((error) =>{
    console.log(`getPostReaderList: error: ${error}`);
  });
};

const updateLikePost = (post_id) => (dispatch) =>{
  const payload = {
    id: post_id,
    changeState: false,
  };
  dispatch(actions.incrementLikeCount(payload));
}

const unlikePost = (post_id) => (dispatch) => {
  return feedCalls.unlikePost(post_id).then(() => {
    const payload = {
      id: post_id,
      changeState: true,
    };
    dispatch(actions.decrementLikeCount(payload));
  }).catch((error) =>{
     console.log(`unlikePost: error: ${error}`);
  });
};
const updateUnlikePost = (post_id) => (dispatch) => {
  const payload = {
    id: post_id,
    changeState: false,
  };
  dispatch(actions.decrementLikeCount(payload));
};
const fetchComments = (post_id) => (dispatch) => {
  dispatch(actions.requestComments(1));

  return feedCalls
    .getComments(params.getComments(post_id, 1))
    .then((response) => {
      const {
        data: { comments, meta },
      } = response;

      dispatch(
        actions.hydrateComments({
          data: comments,
          total_pages: meta.pagination.total_pages,
        })
      );
    });
};

const updateAddComment = (post_id, comment_id) => (dispatch) => {
  dispatch(actions.incrementPostCommentCount({postId: post_id, commentId: comment_id}));
  dispatch(actions.updateAddedComments(comment_id));
};

const updateDeleteComment = (post_id, comment_id) => (dispatch) => {
  dispatch(actions.updateActiveComment(0));
  dispatch(actions.deleteComment(comment_id));
  dispatch(actions.decrementPostCommentCount({postId: post_id, commentId: comment_id}));
  dispatch(actions.updateDeletedComments(comment_id));
  dispatch(actions.clearActiveDelete());
};
const fetchMoreComments = (post_id, page) => (dispatch) => {
  dispatch(actions.requestComments(page));

  return feedCalls
    .getComments(params.getComments(post_id, page))
    .then((response) => {
      dispatch(actions.updateComments(response.data.comments));
    });
};

const deleteComment = (comment_id, post) => (dispatch) =>
  feedCalls
    .deleteComment(comment_id).then(() => {
      dispatch(actions.updateActiveComment(0));
      dispatch(actions.deleteComment(comment_id));
      dispatch(actions.decrementPostCommentCount({postId: post.id, commentId: comment_id}));
      dispatch(actions.updateDeletedComments(comment_id));
      dispatch(actions.clearActiveDelete());
    })
    .catch((error) => {
      const {response: { status },} = error;

      if (status === 404) {
        // Silently succeed deleting already deleted comment
        console.log('deleteComment error 404');
        dispatch(actions.updateActiveComment(0));
        dispatch(actions.deleteComment(comment_id));
        dispatch(actions.clearActiveDelete());
        //dispatch(actions.updateActiveSidePane(''));
      } else {
        dispatch(
          actions.updateActiveError({
            code: error.response.status,
            action: actions.deleteComment.toString(),
          })
        );
      }
    });

const getLocationChannels = (locationId) => (dispatch) => {
  GetLocationChannels(locationId).then(({data}) =>{
    dispatch(appActions.hydrateChannels(data.channels));
  }).catch((error) =>{
    const msg = `${locationId}: GetLocationChannels returns error = ${error}`;
    console.log(msg);
  });
};

const readPost = (post_id) => (dispatch) => {
  return feedCalls.readPost(post_id).then((response) => {
    const payload = {
      postId: post_id,
      readerId: parseInt(getUserId()),
    };
    dispatch(actions.setPostReadState(payload));
  }).catch((error) =>{
     console.log(`readPost: error: ${error}`);
  });
};

const leaveGroup = (channelId, users) => (dispatch) => {
  const userIds = users.map((user) => {
    return user.owner_id
  });
  const start = moment();
  dispatch(appActions.resetError(userIds[0]));
  return teamCalls.leaveChannel({channel_id: channelId}).then((response) => {
    let payload = {id: channelId, userId: userIds[0]};
    if(users[0].is_admin) {
      dispatch(appActions.removeChannelIdFromManager(payload));
    }else {
      dispatch(appActions.removeChannelIdFromAssociate(payload));
    }
    dispatch(appActions.removeChannel({id: channelId}));
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Leave channel took ${ms} ms.`;
    console.log(s);
  }).catch((error) => {
    const msg = `Team operation leaveGroup for user ${userIds[0]} error: ${error}`;
    console.log(msg);
    //dispatch(appActions.setError(userIds[0]));
  });
};

const updateChannelInfo = (channelId, name, description) => (dispatch) => {
  return feedCalls.putChannelInfo(channelId, name, description).then((response) => {
    const payload = {
      channel_id: channelId,
      channel_name: name,
      description: description,
    };
    dispatch(appActions.updateChannelInfo(payload));
    dispatch(actions.updateSubscriptionChannelInfo(payload));
    
  }).catch((error) =>{
     console.log(`updateChannelInfo: error: ${error}`);
  });
};

export default {
  fetchPosts,
  fetchMorePosts,
  deletePost,
  updateDeletePost,
  updateDeleteActivePost,
  fetchComments,
  fetchMoreComments,
  deleteComment,
  likePost,
  updateLikePost,
  unlikePost,
  updateUnlikePost,
  pinPost,
  updatePinPost,
  unpinPost,
  updateUnpinPost,
  readPost,
  fetchReadReceipts,
  createPost,
  createComment,
  updateAddComment,
  updateDeleteComment,
  fetchSubscriptions,
  getLocationChannels,
  getPostLikeList,
  getPostReaderList,
  leaveGroup,
  updateChannelInfo,
  fetchOnePost,
};
