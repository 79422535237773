import React from 'react';
import { connect } from 'react-redux';
import { PubNubConsumer } from 'pubnub-react';

import {
  teamFeedActions,
  teamFeedOperations,
  teamFeedSelectors,
} from '../../duck';

import PostsComponent from './PostsComponent';
import { appActions } from 'App/duck';
//import { userManagementOperations } from '../../../UserManagement/duck';

const mapStateToProps = (state) => {
  const {
    appReducer: {
      location: {
        settings: { cfg__group_chat__comments },
        settings: { cfg__group_chat__likes },
        settings: { cfg__group_chat__post__create},
      },
    },
    teamFeedReducer,
    teamFeedReducer: { active },
    setupReducer,
    appReducer,
    locationReducer,
  } = state;

  return {
    posts: teamFeedSelectors.getOrderedPosts(teamFeedReducer),
    active,
    activeChannel: teamFeedSelectors.getActiveChannel(state),
    allowComments: cfg__group_chat__comments,
    allowLikes: cfg__group_chat__likes,
    allowCreatePost: cfg__group_chat__post__create,
    userIsAdmin: setupReducer.setup_actions.meta.current_user.current_is_admin,
    userId: setupReducer.setup_actions.meta.current_user.id,
    channels: appReducer.channels.data,
    locationId: locationReducer.location_code,
    locations: locationReducer.locations,
    allUsers: appReducer.allUsers.users,
    onlineUsers: appReducer.onlineUsers.users,
    receipts: teamFeedReducer.readReceipts,
    managers: appReducer.managers.users,
    associates: appReducer.associates.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  const fetchPosts = (channel_id) => {
    dispatch(teamFeedOperations.fetchPosts(channel_id));
  };

  const fetchComments = (post_id) => {
    dispatch(teamFeedOperations.fetchComments(post_id));
  };

  const fetchMorePosts = (channel_id, page) => {
    dispatch(teamFeedOperations.fetchMorePosts(channel_id, page));
  };

  const setActivePost = (id, channel_name) => {
    dispatch(teamFeedActions.updateActivePost({id, channel_name}));
    dispatch(teamFeedActions.clearReadReceipts());
  };

  const setActiveDelete = ({ id, type }) => {
    dispatch(teamFeedActions.updateActiveDelete({ id, type }));
  };

  const setActiveSidePane = (pane) => {
    dispatch(teamFeedActions.updateActiveSidePane(pane));
  };

  const fetchReadReceipts = (id, channel_id) => {
    dispatch(teamFeedActions.updateActiveSidePane('read-receipts'));
    dispatch(teamFeedOperations.fetchReadReceipts(id, channel_id));
    dispatch(teamFeedActions.updateActivePost({id, channel_name: ''}));
  };

  const likePost = (id) => {
    dispatch(teamFeedOperations.likePost(id));
  };

  const unlikePost = (id) => {
    dispatch(teamFeedOperations.unlikePost(id));
  };

  const pinPost = (id) => {
    dispatch(teamFeedOperations.pinPost(id));
  };

  const unpinPost = (id) => {
    dispatch(teamFeedOperations.unpinPost(id));
  };

  const createPost = (post) => {
    dispatch(teamFeedOperations.createPost(post));
  };

  const fetchSubscriptions = (channel_id) => {
    dispatch(teamFeedOperations.fetchSubscriptions(channel_id));
  };

  const handleUpdateLikePost = (id) =>{
    dispatch(teamFeedOperations.updateLikePost(id));
  };
  const handleUpdateUnlikePost = (id) =>{
    dispatch(teamFeedOperations.updateUnlikePost(id));
  };
  const handleUpdatePinPost = (id, pin) => {
    if(pin){
      dispatch(teamFeedOperations.updatePinPost(id));
    }else{
      dispatch(teamFeedOperations.updateUnpinPost(id));
    }
  };
  const handleUpdateCommentCount = (post_id, comment_id, add) => {
    if(add){
        dispatch(teamFeedOperations.updateAddComment(post_id, comment_id));
    }else{
        dispatch(teamFeedOperations.updateDeleteComment(post_id, comment_id));
    }
  };
  const handleUpdateDeletePost = (post_id) => {
    dispatch(teamFeedOperations.updateDeletePost(post_id));
  };
  const handleUpdateDeleteActivePost = (post_id) => {
    dispatch(teamFeedOperations.updateDeleteActivePost(post_id));
  };
  const handleDeleteChannel = (channel_id) => {
    dispatch(appActions.removeChannel({id: channel_id}));
  };
  const getLocationChannels = (locationId) => {
    dispatch(teamFeedOperations.getLocationChannels(locationId));
  };

  const getLocationOnlineUsers = (users) => {
    dispatch(appActions.receiveOnlineUsers(users));
  };
  const handleAddUserToOnlineList = (user) => {
    dispatch(appActions.appendOnlineUser(user));
  };
  const handleRemoveUserFromOnlineList = (user) => {
    dispatch(appActions.removeOnlineUser(user));
  };

  const setLastPost = (lastPost) => {
    dispatch(teamFeedActions.receiveLastPost(lastPost));
  };

  const updateChannelLastContent = (payload) => {
    dispatch(appActions.updateChannelLastContent(payload));
  };

  const NotifyNewPost = (channelId) => {
    dispatch(appActions.NotifyNewPost(channelId));
  };

  const updateLikeList = (post_id, list) => {
    dispatch(teamFeedActions.receiveLikeList({postId: post_id, likeList: list}));
  }

  const fetchPostLikeList = (post_id) => {
    dispatch(teamFeedOperations.getPostLikeList(post_id));
  };

  const fetchPostReaderList = (post_id) => {
    dispatch(teamFeedOperations.getPostReaderList(post_id));
  };

  const handleReadPost = (post_id) => {
    dispatch(teamFeedOperations.readPost(post_id));
  };

  const deletePost = (postId, lastPost) => {
    dispatch(teamFeedOperations.deletePost(postId, lastPost));
  };

  const handleRemoveUserFromGroup = (channelId, users) => {
    dispatch(teamFeedOperations.leaveGroup(channelId, users));
  };

  const setActiveChannel = (id) => {
    dispatch(teamFeedActions.updateActiveChannel(id));
    dispatch(teamFeedActions.updateActivePost({id: 0, channel_name: ''}));
    dispatch(teamFeedActions.updateActiveComment(0));
    dispatch(teamFeedActions.updateActiveDelete({ type: '', id: 0 }));
    dispatch(teamFeedActions.updateActiveSidePane(''));
    dispatch(teamFeedActions.clearReadReceipts());
  };

  const fetchOnePost = (post_id) => {
    dispatch(teamFeedOperations.fetchOnePost(post_id));
  };

  return {
    fetchPosts,
    fetchMorePosts,
    setActivePost,
    setActiveDelete,
    setActiveSidePane,
    fetchReadReceipts,
    fetchComments,
    likePost,
    unlikePost,
    pinPost,
    unpinPost,
    createPost,
    fetchSubscriptions,
    handleUpdateLikePost,
    handleUpdateUnlikePost,
    handleUpdatePinPost,
    handleUpdateCommentCount,
    handleUpdateDeletePost,
    handleUpdateDeleteActivePost,
    handleDeleteChannel,
    getLocationChannels,
    getLocationOnlineUsers,
    handleAddUserToOnlineList,
    handleRemoveUserFromOnlineList,
    setLastPost,
    updateChannelLastContent,
    NotifyNewPost,
    updateLikeList,
    fetchPostLikeList,
    fetchPostReaderList,
    handleReadPost,
    deletePost,
    handleRemoveUserFromGroup,
    setActiveChannel,
    fetchOnePost,
  };
};

/*
const PostsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsComponent);
*/


const PostsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  return (
    <PubNubConsumer>
      {(client) => {
        const { fetchPosts,
                fetchMorePosts,
                setActivePost,
                setActiveDelete,
                setActiveSidePane,
                likePost,
                unlikePost,
                pinPost,
                unpinPost,
                active,
                posts,
                activeChannel,
                channels,
                allowLikes,
                allowComments,
                allowCreatePost,
                fetchReadReceipts,
                fetchComments,
                createPost,
                fetchSubscriptions,
                handleUpdateLikePost,
                handleUpdateUnlikePost,
                handleUpdatePinPost,
                handleUpdateCommentCount,
                handleUpdateDeletePost,
                handleUpdateDeleteActivePost,
                handleDeleteChannel,
                getLocationChannels,
                locationId,
                locations,
                allUsers,
                onlineUsers,
                getLocationOnlineUsers,
                handleAddUserToOnlineList,
                handleRemoveUserFromOnlineList,
                setLastPost,
                updateChannelLastContent,
                NotifyNewPost,
                updateLikeList,
                fetchPostLikeList,
                fetchPostReaderList,
                receipts,
                handleReadPost,
                deletePost,
                handleRemoveUserFromGroup,
                setActiveChannel,
                managers,
                associates,
                fetchOnePost,
              } = props;

        return (
          <PostsComponent
            pubnub={client}
            fetchPosts={fetchPosts}
            fetchMorePosts={fetchMorePosts}
            setActivePost={setActivePost}
            setActiveDelete={setActiveDelete}
            setActiveSidePane={setActiveSidePane}
            likePost={likePost}
            unlikePost={unlikePost}
            pinPost={pinPost}
            unpinPost={unpinPost}
            active={active}
            posts={posts}
            activeChannel={activeChannel}
            channels={channels}
            allowLikes={allowLikes}
            allowComments={allowComments}
            allowCreatePost={allowCreatePost}
            fetchReadReceipts={fetchReadReceipts}
            fetchComments={fetchComments}
            createPost={createPost}
            fetchSubscriptions={fetchSubscriptions}
            handleUpdateLikePost={handleUpdateLikePost}
            handleUpdateUnlikePost={handleUpdateUnlikePost}
            handleUpdatePinPost={handleUpdatePinPost}
            handleUpdateCommentCount={handleUpdateCommentCount}
            handleUpdateDeletePost={handleUpdateDeletePost}
            handleUpdateDeleteActivePost={handleUpdateDeleteActivePost}
            handleDeleteChannel={handleDeleteChannel}
            getLocationChannels={getLocationChannels}
            locationId={locationId}
            locations={locations}
            allUsers={allUsers}
            onlineUsers={onlineUsers}
            getLocationOnlineUsers={getLocationOnlineUsers}
            handleAddUserToOnlineList={handleAddUserToOnlineList}
            handleRemoveUserFromOnlineList={handleRemoveUserFromOnlineList}
            setLastPost={setLastPost}
            updateChannelLastContent={updateChannelLastContent}
            NotifyNewPost={NotifyNewPost}
            updateLikeList={updateLikeList}
            fetchPostLikeList={fetchPostLikeList}
            fetchPostReaderList={fetchPostReaderList}
            receipts={receipts}
            handleReadPost={handleReadPost}
            deletePost={deletePost}
            handleRemoveUserFromGroup={handleRemoveUserFromGroup}
            setActiveChannel={setActiveChannel}
            managers={managers}
            associates={associates}
            fetchOnePost={fetchOnePost}
          />
        );
      }}
    </PubNubConsumer>
  );
});

export default PostsContainer;
