import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './LocationContainer.scss';

const LocationContainer = (props) => {
  const LocationJoinError = () => {
    const { joinError } = props;

    if (joinError.length > 0) {
      return <p className="location-summary__join-error">{joinError}</p>;
    }
    return null;
  };

  const CurrentComponent = () => {
    const { isLoading, location, confirm, hasBeenCalled, onClose, onBack } = props;

    const cancel = (<FormattedMessage
      id="Confirm.cancel"
      defaultMessage="Cancel"
    />);

    const confirmBtn = (<FormattedMessage
      id="RecentRequest.confirm"
      defaultMessage="Confirm"
    />)

    if (isLoading) {
      return (
        <article className="location-page">
          <div className="location-loading">
            <img src="/loader.gif" height="50" width="50" alt="Loading spinner" />
          </div>
        </article>
      );
    }
    if (location) {
      let locationAvatarUrl = '';
      if (location.avatar_blob) {
        locationAvatarUrl = location.avatar_blob.blob_url;
      }
      return (
        <article className="location-page">
          <div className="location-page__header-container">
            <button
              type="button"
              onClick={onBack}
            >
              <img src="icons/week-picker-left.svg" />
            </button>
            <p className="location-page__header-main">
              <FormattedMessage
                id="AddLocationPageCode.joinNewLocation"
                defaultMessage="Join this Location?"
              />
            </p>
          </div>
          <p className="location-page__confirm-description">
            <FormattedMessage
              id="AddLocationPageCode.enteredCode"
              defaultMessage="You entered location code: #{locationCode}"
              values={{
                locationCode: `${location.swift_code}`,
              }}
            />
            <br />
            <FormattedMessage
              id="AddLocationPageCode.confirmCode"
              defaultMessage=" Please confirm you want to join."
            />
          </p>

          <img
            className="location-page__location-logo"
            src={locationAvatarUrl ? locationAvatarUrl : '/icons/channel-placeholder-avatar.svg'}
            alt="Location Icon"
          />

          <p className="location-page__location-name">
            {location.location_name ? location.location_name : ''}
          </p>

          <p className="location-page__confirm-description2">
            {location.formatted_address ? location.formatted_address : ''}
          </p>

          <div className="location-page__button-wrap">
            <button
              className="confirm__button-cancel"
              onClick={onClose}
            >
              {cancel}
            </button>
            <button
              className="confirm__button"
              onClick={confirm}
            >
              {confirmBtn}
            </button>
          </div>
          <LocationJoinError />
        </article>
      );
    }
    if (hasBeenCalled) {
      return (
        <div className="location-not-found">
          <FormattedMessage
            id="LocationContainer.locationNotFound"
            defaultMessage="Location not found! Try again."
          />
        </div>
      );
    }
    return null;
  };

  return (
    <section className="location-container">
      <CurrentComponent />
    </section>
  );
};

LocationContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasBeenCalled: PropTypes.bool.isRequired,
  location: PropTypes.object,
  confirm: PropTypes.func.isRequired,
  joinError: PropTypes.string,
};

LocationContainer.defaultProps = {
  location: null,
  joinError: '',
};

export default LocationContainer;
