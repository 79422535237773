import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { ProfileThumb } from '@shyft/acorns';

import { createFullName } from 'helpers/formatting';

import blocks from '../../blocks';
import elements from '../../elements';

import readCheckbox from './assets/read-checkbox.svg';
import unreadCheckbox from './assets/unread-checkbox.svg';
import './GroupMembersComponent.scss';
import styled from './styled';

const sha1 = require('js-sha1');

class GroupMembersComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={
      closePinPane: false,
      channelMembers: [],
      admins: [],
      members: [],
    };
    this.handleClose = this.handleClose.bind(this);

    this.closeReadPane = false;
    this._isMounted = false;
  }

  componentDidMount() {
    const {activePost, allUsers, onlineUsers, activeChannel, } = this.props;
    this._isMounted = true;

    if(activeChannel && allUsers) {
      const channelUsers = allUsers.filter((user) =>  
        user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
      if(channelUsers && channelUsers.length > 0) {
        this.setState({channelMembers: channelUsers});
        const managers = channelUsers.filter((user) => user.is_admin === true);
        const associates = channelUsers.filter((user) => user.is_admin === false);
        this.setState({admins: managers, members: associates});
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {activePost, allUsers, onlineUsers, activeChannel, } = this.props;

    if(prevProps.allUsers !== allUsers) {
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
          let filtered = [];
          for(let i=0; i< channelUsers.length; i++) {
            if(activePost && activePost.readList && !activePost.readList.includes(channelUsers[i].owner_id)) {
              filtered.push(channelUsers[i]);
            }
          }
          this.setState({unReaders: filtered});
        }
      }
    }

    if(prevProps.activeChannel !== activeChannel) {
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
          let filtered = [];
          for(let i=0; i< channelUsers.length; i++) {
            if(activePost && activePost.readList && !activePost.readList.includes(channelUsers[i].owner_id)) {
              filtered.push(channelUsers[i]);
            }
          }
          this.setState({unReaders: filtered});
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserUUID = (userId) => {
    return `user.${sha1(`user.${userId}`)}`;
  };

  handleClose(event) {
    const {resetActiveSidePane} = this.props;

    //this.setState({closePinPane: false});
    this.closeReadPane = true;
    resetActiveSidePane();
    event.preventDefault();
  }

  render () {
    //const {receipts, activeSidePane,} = this.props;
    const {receipts, activeSidePane, activePost, allUsers, onlineUsers, } = this.props;
    const { channelMembers, admins, members, } = this.state;

    const title = (<FormattedMessage id="GroupMembersComponent.header" defaultMessage="Group Members" />);
    const approvedBadge = '/icons/verified-badge-icon.svg';
    const adminsText = (<FormattedMessage
            id="GroupMembersComponent.admins"
            defaultMessage="Admins"
          />);
    const membersText = (<FormattedMessage
            id="GroupMembersComponent.members"
            defaultMessage="Members"
          />);

    const numAdmins = `(${admins ? admins.length : 0})`;
    const numMembers = `(${members ? members.length : 0})`;

    const others = members.map((user, index) => {
      let profileUrl = '/icons/default-profile-thumb.svg';
      if(user && user.profile_image_thumb_url) {
        profileUrl = user.profile_image_thumb_url;
      }
      const showAdminBadge = user && user.is_admin ? true : false;
      const position = user && user.position ? user.position : '';
      const userUUID = user ? this.getUserUUID(user.owner_id) : '';
      let showOnline = false;
      if(onlineUsers && onlineUsers.includes(userUUID)) {
        showOnline = true;
      }

      const placeholder = '';
      let  bottomLine = null;    
      if(user) {
        if(user.position && !user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.position}</styled.BottomLineTextOneItem>);
        }else if(!user.position && user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.badge_id}</styled.BottomLineTextOneItem>);
        }else if(!user.position && !user.badge_id){
          bottomLine = (<styled.BottomLineTextOneItem>{placeholder}</styled.BottomLineTextOneItem>)  
        }else {
           bottomLine = (    
            <styled.BottomLineWrap>
              <styled.BottomLineText>{user.position}</styled.BottomLineText>
              <styled.Dot/>
              <styled.BottomLineText>{user.badge_id}</styled.BottomLineText>
            </styled.BottomLineWrap>);
        }
      }

      return (
        <styled.MessageWrap key={user.id}>
        <styled.AvatarWrap>
          <styled.ProfileAvatar src={profileUrl}/>
          {showOnline && <styled.OnlineMark/>}
        </styled.AvatarWrap>
        <styled.InfoWrap>
          <styled.Name>
            {user !== undefined ? `${user.first_name} ${user.last_name}` : null}
            {showAdminBadge && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.Name>
          {bottomLine}
        </styled.InfoWrap>
        </styled.MessageWrap>
      );

    });

    const managers = admins.map((user, index) => {
      let profileUrl = '/icons/default-profile-thumb.svg';
      if(user && user.profile_image_thumb_url) {
        profileUrl = user.profile_image_thumb_url;
      }
      const showAdminBadge = user && user.is_admin ? true : false;
      const position = user && user.position ? user.position : '';
      const userUUID = user ? this.getUserUUID(user.owner_id) : '';
      let showOnline = false;
      if(onlineUsers && onlineUsers.includes(userUUID)) {
        showOnline = true;
      }

      const placeholder = '';
      let  bottomLine = null;    
      if(user) {
        if(user.position && !user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.position}</styled.BottomLineTextOneItem>);
        }else if(!user.position && user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.badge_id}</styled.BottomLineTextOneItem>);
        }else if(!user.position && !user.badge_id){
          bottomLine = (<styled.BottomLineTextOneItem>{placeholder}</styled.BottomLineTextOneItem>)  
        }else {
           bottomLine = (    
            <styled.BottomLineWrap>
              <styled.BottomLineText>{user.position}</styled.BottomLineText>
              <styled.Dot/>
              <styled.BottomLineText>{user.badge_id}</styled.BottomLineText>
            </styled.BottomLineWrap>);
        }
      }

      return (
        <styled.MessageWrap key={user.id}>
        <styled.AvatarWrap>
          <styled.ProfileAvatar src={profileUrl}/>
          {showOnline && <styled.OnlineMark/>}
        </styled.AvatarWrap>
        <styled.InfoWrap>
          <styled.Name>
            {user !== undefined ? `${user.first_name} ${user.last_name}` : null}
            {showAdminBadge && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.Name>
          {bottomLine}
        </styled.InfoWrap>
        </styled.MessageWrap>
        );
    });


    return (
      <elements.ColumnWrap>
        <div className="reader__wrap">
          <p className="reader__title">{title}</p>
          <button className="reader__close-button" onClick={this.handleClose}/>
        </div>
        <elements.ScrollWrap isLoading={false}>
          {false && <elements.Loading />}
          <div className="listSeparator">
            <label className="listSeparator__text">{adminsText}</label>
            <label className="listSeparator__number">{numAdmins}</label>
          </div>
          {managers}
          <div className="listSeparator">
            <label className="listSeparator__text">{membersText}</label>
            <label className="listSeparator__number">{numMembers}</label>
          </div>
          {others}
        </elements.ScrollWrap>
      </elements.ColumnWrap>
    );
  }
}

GroupMembersComponent.propTypes = {
  //pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(GroupMembersComponent);

