import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';


const WinContainer = styled.div `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2;
  overflow: auto;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const Title = styled.label `
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 600;
  //border: 1px solid red;
`;

const Content = styled.label `
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
  //border: 1px solid red;
`;

const ViewPlansButton = styled.button `
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
  width: 100%;
  height: 30px;
  background-color: rgba(12,132,255,255);
  color: white;
  border-radius: 5px;
  //border: 1px solid red;

  &:focus {
    outline: none;
  }
`;


export default {
  WinContainer,
  Title,
  Content,
  ViewPlansButton,
};
