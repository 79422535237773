import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import teamCalls from 'helpers/api-calls/team-calls';

import styled from './ButtonStyles';
import './ApplicantRow.scss';
import {Mixpanel} from '../../../Mixpanel';

class ApplicantRow extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
        currentAction: '',
        confirming: false,
        jobTitle: '',
    };

		this.handleDenyClick = this.handleDenyClick.bind(this);
		this.handleApproveClick = this.handleApproveClick.bind(this);

		this.approveInProgress = false;
		this.deniedApplicants = 0;

	}

	componentDidMount() {
		const {shift, applicant, user, userPrivileges, handleJobTitleReceived, getMissingJobTitle } = this.props;

		if(shift.applicants && shift.applicants.length > 0) {
			this.deniedApplicants = 0;
			for(let i=0; i<shift.applicants.length; i++) {
				if(shift.applicants[i].status === 'denied') {
					this.deniedApplicants += 1;
				}
			}
		}

		const managerConfig = {
			location_id: shift.location_id,
	    filter: 'active',
	    per_page: 1000,
	    admin_status: true,
	    page: 1,
	  };
		const associateConfig = {
			location_id: shift.location_id,
	    filter: 'active',
	    per_page: 1000,
	    admin_status: false,
	  };
	  
	}

	componentDidUpdate(prevProps) {
		const {applicant, shift, user, userPrivileges, handleJobTitleReceived, } = this.props;

		if(prevProps.applicant !== applicant) {
			const managerConfig = {
				location_id: shift.location_id,
		    filter: 'active',
		    per_page: 1000,
		    admin_status: true,
		    page: 1,
		  };
			const associateConfig = {
				location_id: shift.location_id,
		    filter: 'active',
		    per_page: 1000,
		    admin_status: false,
		  };
		}

		if(prevProps.shift !== shift) {
			if(prevProps.shift.available_shifts_covered !== shift.available_shifts_covered) {
				this.approveInProgress = false;
			}

			if(shift.applicants && shift.applicants.length > 0) {
				this.deniedApplicants = 0;
				for(let i=0; i<shift.applicants.length; i++) {
					if(shift.applicants[i].status === 'denied') {
						this.deniedApplicants += 1;
					}
				}
			}
		}
	}

	componentWillUnmount() {

	}

	handleDenyClick = (event) => {
		const {confirming, currentAction} = this.state;
		const {shift} = this.props;
		event.preventDefault();
		if(confirming) {
			this.handleCancel();
		}else {
			let cannotDeny = false;
    	if(!shift.parent_card) {
	    	let approvedApplicants = 0;
	    	if(shift.applicants && shift.applicants.length > 0){
	    		for(let i=0; i<shift.applicants.length; i++) {
		        if(shift.applicants[i].status === 'picked'){
		          approvedApplicants += 1;
		        }
		      }
	    	}
	    	cannotDeny = approvedApplicants > 0;
    	}
    	if(cannotDeny) {
    		const msg = `The shift has been filled. No more deny/approve action is allowed.`;
    		console.log(msg)
    		return;
    	}
			this.handleConfirm('deny');
		}
	}

	handleApproveClick = (event) => {
		const { confirming, currentAction} = this.state;
		const {shift, applicant, lastApplicant} = this.props;
		event.preventDefault();

		if(this.approveInProgress) {
			console.log('approve in progress, exit');
			return;
		}
	
    if(confirming) {
    	this.handleAction();
		Mixpanel.track( currentAction + ' shift');
    }else {
    	let cannotApprove = true;
    	let lastSlotToFill = false;
    	let approvedApplicants = 0;
    	if(shift.applicants && shift.applicants.length > 0){
    		for(let i=0; i<shift.applicants.length; i++) {
	        if(shift.applicants[i].status === 'picked'){
	          approvedApplicants += 1;
	        }
	      }
    	}

    	if(shift.parent_card) {
				cannotApprove = shift.total_shifts_available - shift.available_shifts_covered <= 0 ||
    											this.deniedApplicants + shift.available_shifts_covered >= shift.applicants.length;
    		lastSlotToFill = shift.total_shifts_available - shift.available_shifts_covered === 1;
    	}else {
	    	cannotApprove = approvedApplicants > 0;
	    	if(shift.name === 'shift') {
	    		lastSlotToFill = true;
	    	}
    	}

    	if(cannotApprove) {
    		let msg = '';
    		if(shift.parent_card) {
    			msg = `shift ${shift.id} total slots = ${shift.total_shifts_available},approved = ${shift.available_shifts_covered}, cannot approve.`;
					console.log(msg);
    		}else {
    			msg = `The shift has been filled. Cannot be approved any more.`;
    			console.log(msg);
    		}	
    		return;		
			}

			if(lastSlotToFill) {
				lastApplicant(applicant);
				return;
			}
    	this.handleConfirm('approve');
    }
	}

	handleConfirm = (action) => {
    const { locationConfig, util, shift, toggleConfirmationSheet } = this.props;
    
    this.setState({confirming: true, currentAction: action});
  };

  handleCancel = () => {
    this.setState({
      confirming: false,
      currentAction: '',
    });
  };

  handleAction = () => {
    const { currentAction } = this.state;
    const { locationConfig, 
            shift, 
            applicant,
            handleUpdateApplicantStatus, 
            handleUserDeny,
            } = this.props;

    const configId =
      locationConfig && locationConfig.custom_text ? locationConfig.id : null;

    switch (currentAction) {
      case 'approve':
        handleUpdateApplicantStatus(shift.id, applicant.id, true);
        this.setState({confirming: false});
        this.approveInProgress = true;
        break;
      case 'deny':
        handleUpdateApplicantStatus(shift.id, applicant.id, false);
        this.setState({confirming: false});
        handleUserDeny(applicant);
        break;
      default:
        break;
    }
  };

  renderButtons = () => {
  	const {applicant, shift, } = this.props;
  	const {confirming, } = this.state;

  	const captionApprove = (
      <FormattedMessage
        id="ApplicantRow.approve"
        defaultMessage="Approve"
      />
    );

    const captionDeny = (
      <FormattedMessage
        id="ApplicantRow.deny"
        defaultMessage="Deny"
      />
    );

    const captionConfirm = (
      <FormattedMessage
        id="ApplicantRow.confirm"
        defaultMessage="Confirm"
      />
    );

    const captionCancel = (
      <FormattedMessage
        id="ApplicantRow.cancel"
        defaultMessage="Cancel"
      />
    );

    let showApproveButton = false;

    if(shift.parent_card){
    	showApproveButton = shift.total_shifts_available - shift.available_shifts_covered > 0 &&
    												this.deniedApplicants + shift.available_shifts_covered < shift.applicants.length;
    }
    else {
    	let pendingApplicants = 0;
    	let approvedApplicants = 0;
    	if(shift.applicants && shift.applicants.length > 0){
    		for(let i=0; i<shift.applicants.length; i++) {
	        if(shift.applicants[i].status === 'applied'){
	          pendingApplicants += 1;
	        }
	        if(shift.applicants[i].status === 'picked'){
	          approvedApplicants += 1;
	        }
	      }
    	}
    	showApproveButton = pendingApplicants > 0 && approvedApplicants === 0;
    }

  	if(showApproveButton) {
	  	return (
					<div className="applicant__button-wrap">
						<label className={confirming ? "applicant__cancel-button" : "applicant__deny-button"} 
						onClick={this.handleDenyClick}>
						{confirming ? captionCancel : captionDeny}</label>
						<label className={confirming ? "applicant__confirm-button": "applicant__approve-button"} 
						onClick={this.handleApproveClick}>
						{confirming ? captionConfirm : captionApprove}</label>
					</div>
			);
  	}else {
  		return (
					<div className="applicant__button-wrap">
						<label className={confirming ? "applicant__cancel-button" : "applicant__deny-button"} 
						onClick={this.handleDenyClick}>
						{confirming ? captionCancel : captionDeny}</label>
						<label className={confirming ? "applicant__confirm-button" : "applicant__approve-button-disabled"}
						onClick={this.handleApproveClick}>
						{confirming ? captionConfirm : captionApprove}</label>
					</div>
			);
  	}
  }

	render() {
		const {applicant, shift, userDeniedApplicants, } = this.props;
		const {confirming, currentAction, jobTitle, } = this.state;

		//const name =`${applicant.user.first_name} ${applicant.user.last_name}`;
		let name = '--';
		if(applicant && applicant.user !== null) {
			name = `${applicant.user.first_name} ${applicant.user.last_name}`;
		}

  	let approvedApplicants = 0;
  	if(shift.applicants && shift.applicants.length > 0){
  		for(let i=0; i<shift.applicants.length; i++) {
        if(shift.applicants[i].status === 'picked'){
          approvedApplicants += 1;
        }
      }
  	}
  
		let deniedBySystem = false; 
		let changeStatusNoRefresh = false;

		if(applicant && applicant.status === 'denied') {
			if(applicant.decider_id === null) {
				const idx = userDeniedApplicants.findIndex((app) => app.id === applicant.id);
				if(idx >= 0) {
					deniedBySystem = false;
				}else {
					deniedBySystem = true;
				}
			}else {
				deniedBySystem = false;
			}
		}else if(applicant.status === 'applied') {
			const idx = userDeniedApplicants.findIndex((app) => app.id === applicant.id);
			if(idx >= 0) {
				deniedBySystem = false;
			}else {
				deniedBySystem = true;
			}
		}

		if(applicant && applicant.status === 'applied') {
			if(shift.available_shifts_covered === shift.total_shifts_available) {
				changeStatusNoRefresh = true;
			}
		}

		let deniedLabel = '';
		const statusApproved = (
      <FormattedMessage
        id="ApplicantRow.approved"
        defaultMessage="Approved"
      />
    );

    const statusDenied = (
      <FormattedMessage
        id="ApplicantRow.denied"
        defaultMessage="Denied"
      />
    );

     const statusDeniedBySystem = (
      <FormattedMessage
        id="ApplicantRow.deniedBySystem"
        defaultMessage="Denied By System"
      />
    );

    if(deniedBySystem) {
    	deniedLabel = statusDeniedBySystem;
    }else {
    	deniedLabel = statusDenied;
    }

    const applicantAvatar = applicant.user && applicant.user.thumb_url ? applicant.user.thumb_url : '/icons/default-profile-thumb.svg';

    if (applicant) {
    	if(shift.available_shifts_covered < shift.total_shifts_available) {
    		return (
					<tr className="applicant">
						<td className="applicant__user-cell">
							<div className="applicant__name-wrap">
								<img className="applicant__user-avatar" src={applicantAvatar}/>
								<p className="applicant__user-name">{name}</p>
							</div>
						</td>
						<td className="applicant__user-position">
							{applicant.user && applicant.user.current_position ? applicant.user.current_position : jobTitle}
						</td>
						<td className="applicant__user-status">
							{applicant.status === 'picked' && 
							<label className="applicant__status-picked-label">{statusApproved}</label>}
							{applicant.status === 'denied' && 
							<label className="applicant__status-denied-label">{statusDenied}</label>}
							{(applicant.status === 'applied' && applicant.user !== null) && this.renderButtons()}
						</td>
					</tr>
				);
    	}else if(shift.name === 'shift') {
    		return (
					<tr className="applicant">
						<td className="applicant__user-cell">
							<div className="applicant__name-wrap">
								<img className="applicant__user-avatar" src={applicantAvatar}/>
								<p className="applicant__user-name">{name}</p>
							</div>
						</td>
						<td className="applicant__user-position">
							{applicant.user && applicant.user.current_position}
						</td>
						<td className="applicant__user-status">
							{applicant.status === 'picked' && 
							<label className="applicant__status-picked-label">{statusApproved}</label>}
							{applicant.status === 'denied' && 
							<label className="applicant__status-denied-label">{deniedLabel}</label>}
							{(applicant.status === 'applied' && approvedApplicants === 0) && this.renderButtons()}
							{(applicant.status === 'applied' && approvedApplicants === 1) && 
							<label className="applicant__status-denied-label">{statusDeniedBySystem}</label>}
						</td>
					</tr>
				);
    	}
			return (
				<tr className="applicant">
					<td className="applicant__user-cell">
						<div className="applicant__name-wrap">
							<img className="applicant__user-avatar" src={applicantAvatar}/>
							<p className="applicant__user-name">{name}</p>
						</div>
					</td>
					<td className="applicant__user-position">
						{applicant.user && applicant.user.current_position}
					</td>
					<td className="applicant__user-status">
						{applicant.status === 'picked' && 
						<label className="applicant__status-picked-label">{statusApproved}</label>}
						{applicant.status === 'denied' && 
						<label className="applicant__status-denied-label">{deniedLabel}</label>}
						{/*applicant.status === 'applied' && this.renderButtons()*/}
						{changeStatusNoRefresh && <label className="applicant__status-denied-label">{deniedLabel}</label> }
					</td>
				</tr>
			);
    }
	}

}

ApplicantRow.propTypes = {
  //locationConfig: PropTypes.shape({}).isRequired,
  //shift: dashboardShapes.shiftShape.isRequired,
  
};

export default injectIntl(ApplicantRow);