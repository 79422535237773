import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import styled from './styled';

class Confirm extends React.PureComponent {
	constructor(props) {
    super(props);

    this.state = {
      debug: false,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { title, content, notifyCancel, notifyConfirm, notifyLeave, } = this.props;

    this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {
  	const { title, content, notifyCancel, notifyConfirm, notifyLeave, } = this.props;

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
  	const { title, content, notifyCancel, notifyDelete, notifyLeave, } = this.props;

  	let w = 400;
    let h = 180;
    let winTop = `${(window.innerHeight - h)/2}px`;
    let winLeft = `${(window.innerWidth - w)/2}px`;
    let winH = `${h}px`;
    let winW = `${w}px`;

    const cancel = (<FormattedMessage
            id="Confirm.buttonCancel"
            defaultMessage="Cancel"
          />);
    const deleting = (<FormattedMessage
            id="Confirm.buttonDelete"
            defaultMessage="Delete"
          />);
    const leaveGroup = (<FormattedMessage
            id="Confirm.buttonLeave"
            defaultMessage="Leave Group"
          />);

    let callback = null;
    let buttonText = null;
    if(title && title.props) {
    	if(title.props.id === 'PostComponent.leaveGroup') {
    		callback = notifyLeave;
    		buttonText = leaveGroup;
    	}else {
    		callback = notifyDelete;
    		buttonText = deleting;
    	}
    }

  	return (
  		<styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
  			<label className="confirm__title">{title}</label>
  			<label className="confirm__text">{content}</label>
  			<div className="confirm__button-wrap">
          <button className="confirm__button-cancel" onClick={notifyCancel}>{cancel}</button>
          <button className="confirm__button-leave" onClick={callback}>{buttonText}</button>
        </div>
  		</styled.WinContainerConfirm>

  	);
  }
}

Confirm.propTypes = {
  //selectedItem: PropTypes.object.isRequired,
  //selectedType: PropTypes.string.isRequired
};

export default injectIntl(Confirm);