import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import UserRow from './UserRow';
import UserRowShimmer from './UserRowShimmer';
import './UserTable.scss';
import styled from './styled';

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

class UserTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: true,
      filesToUpload: [],
      upload: false,
      selectedAllShifts: false,
      actionDoneOrCancelled: true,
      scrollDetected: false,
      rowMenuOpened: false,
    };


    this._isMounted = false;
    this.srolled = false;
    this.bodyRef = React.createRef();
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('scroll', this.handleScrolled, true);

  }

  componentDidUpdate(prevProps, prevState) {
    const { filteredResults, selectedShifts,
      notifyActionDone, } = this.props;

    if (prevProps.selectedShifts !== selectedShifts) {
      if (selectedShifts && selectedShifts.length === 0) {
        if (this.tableRef && this.tableRef.current) {
          const domNode = ReactDOM.findDOMNode(this.tableRef.current);
          const output = domNode.getBoundingClientRect();
          if (output) {
            notifyActionDone(output);
          }
        }
        this.setState({ selectedAllShifts: false });
        this.setState({ actionDoneOrCancelled: true });
      } else if (selectedShifts && selectedShifts.length > 0) {
        this.setState({ actionDoneOrCancelled: false });
      }
    }

    if (prevProps.filteredResults !== filteredResults) {
      if (filteredResults.length === 0) {
        this.setState({ selectedAllShifts: false });
      }
      this.setState({ rowMenuOpened: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.handleScrolled);
  }

  handleSelectAll = (event) => {
    const { notifyAllShiftsSelected, } = this.props;
    const { selectedAllShifts, } = this.state;

    this.setState({ selectedAllShifts: !selectedAllShifts });
    notifyAllShiftsSelected(!selectedAllShifts);
  }


  handleScrolled = (event) => {
    if (!this.scrolled) {
      this.scrolled = true;
    }
  }

  notifyMenuOpened = (opened) => {
    this.setState({ rowMenuOpened: opened });
  }

  createHeaders = () => {
    const { selectedAllShifts, } = this.state;
    const { selectedIndex, selectedType, filteredUsers, isRequests } = this.props;

    const selectBox = <input type="checkbox" checked={selectedAllShifts} onChange={this.handleSelectAll} />
    const requestDetails = (<FormattedMessage id="RequestV3Table.requestDetails" defaultMessage="Request Details" />);
    const applicant = (<FormattedMessage id="RequestV3Table.applicant" defaultMessage="Applicant" />);
    const status = (<FormattedMessage id="RequestV3Table.status" defaultMessage="Status" />);
    const actions = (<FormattedMessage id="Buttons.actions" defaultMessage="Actions" />);
    const date = (<FormattedMessage id="RequestV3Table.date" defaultMessage="Date" />);
    const time = (<FormattedMessage id="RequestV3Table.time" defaultMessage="Time" />);
    const audience = (<FormattedMessage id="RequestV3Table.audience" defaultMessage="Audience" />);
    const placeholder = '';

    let headerItems = isRequests ?
      [placeholder, selectBox, requestDetails, applicant, status, date, time, audience, actions]
      : [placeholder, requestDetails, applicant, status, date, time, audience];

    const Headers = () => {
      const headers = headerItems.map((item, index) => {
        return (
          <th key={index} className="table__header-title">
            {item}
          </th>
        );
      });
      return headers;
    };

    return (
      <thead>
        <tr className="table__headers">
          <Headers />
        </tr>
      </thead>
    );
  };



  render() {
    const { loading, shifts, locationId, filteredResults, departments, skills, channels, channelData,
      filteredUsers, selectedDeptId, selectedType, selectedIndex, selectedItem, selectedSkillId,
      notifyShiftSelected, selectedShifts, lastAction, processedUsers, handleGetUserInformation,
      notifyActionRequested, notifyInlineActionRequested, locationSettings, popupOpened, processingUserId, handleShowSMSInviteModal, handleEmployeeIDClick, processingShiftId,
      approveRequestTimeOff, denyRequestTimeOff, costCenterData, isRequests } = this.props;
    const { selectedAllShifts, actionDoneOrCancelled, rowMenuOpened, } = this.state;

    const noResults = (<FormattedMessage
      id="UserTable.noResults"
      defaultMessage="No Results" />);

    const winH = `${(window.innerHeight - 400)}px`;
    const bodyClassName = 'table__body';

    if (loading) {
      return (
        <styled.TableWrap height={winH} ref={this.tableRef}>
          <table className="table">
            {this.createHeaders()}
            <tbody className={bodyClassName} ref={this.bodyRef} onScroll={this.handleScrolled}>
              <UserRowShimmer />
              <UserRowShimmer />
              <UserRowShimmer />
              <UserRowShimmer />
            </tbody>
          </table>
        </styled.TableWrap>
      );
    }

    if (filteredResults === null || filteredResults.length === 0) {
      return (
        <styled.TableWrap height={winH} ref={this.tableRef}>
          <table className={`table ${loading ? 'loading' : ''}`}>
            {this.createHeaders()}
          </table>
          <div className="no-results__wrap">
            <img className="no-results__icon" src="/icons/search-icon.svg" />
            <label className="no-results__text">{noResults}</label>
          </div>
        </styled.TableWrap>
      );
    }
    const userRows = filteredResults.map((shift, index) => (
      <UserRow
        //key={user.owner_id} 
        key={index}
        shift={shift}
        locationId={locationId}
        selectedAllShifts={selectedAllShifts}
        selectedShifts={selectedShifts}
        skills={skills}
        isRequests={isRequests}
        notifyActionRequested={notifyActionRequested}
        notifyInlineActionRequested={notifyInlineActionRequested}
        actionDoneOrCancelled={actionDoneOrCancelled}
        processingShiftId={processingShiftId}
        notifyShiftSelected={notifyShiftSelected}
      />
    ));

    return (
      <styled.TableWrap height={winH} ref={this.tableRef}>
        {/*<LoadingLayer loading={loading} />*/}
        <table className={`table ${loading ? 'loading' : ''}`}>
          {this.createHeaders()}
          <tbody className={bodyClassName} ref={this.bodyRef} onScroll={this.handleScrolled}>
            {userRows}
          </tbody>
        </table>
      </styled.TableWrap>
    );
  }
}

UserTable.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(UserTable);
