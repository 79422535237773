import React from 'react';
import './DetailFieldSelectWeekday.scss';
import timeUtil from 'pages/ScheduleCalendar/duck/timeUtil';

class DetailFieldSelectWeekdayComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = (day) => {
    const { selectedDays, onChange, disableManualSelect } = this.props;

    if( disableManualSelect == true ){
      return;
    }

    const newSelectedDays = [...selectedDays];
    if (newSelectedDays.includes(day)) {
      const index = newSelectedDays.indexOf(day);
      newSelectedDays.splice(index, 1);
    } else {
      newSelectedDays.push(day);
    }

    // Ensure that selectedDay passed as prop remains selected
    if (!newSelectedDays.includes(this.props.selectedDay)) {
      newSelectedDays.push(this.props.selectedDay);
    }

    onChange(newSelectedDays);
  };

  render() {
    const { selectedDays, locationData } = this.props;
    const weekdays = timeUtil.fiscalWeekDays(locationData.fiscal_day);

    return (
      <div>
        <div className="apply-to">Apply to</div>
        <div className="select-weekday">
          {weekdays.map((day) => (
            <button
              key={day}
              className={`weekday-toggle-button ${selectedDays.includes(day) ? 'day-selected' : 'day-unselected'}`}
              onClick={() => this.handleClick(day)}
            >
              {day}
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default DetailFieldSelectWeekdayComponent;
