import React from 'react';
import { Mixpanel } from '../../../Mixpanel';
import './ConfigurationPage.scss';
import { FormattedMessage } from 'react-intl';
import '../../../App/fonts.scss';

class ConfigurationPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showWeekdayDropdown: false,
        };
    }

    componentDidMount() {
        const {loadLocationDataDispatch} = this.props;

        loadLocationDataDispatch();
    }

    componentDidUpdate() {
        const {locationData} = this.props;
    }

    handleToggleDropdown = () => {
        const {showWeekdayDropdown} = this.state;
        const opposite = !showWeekdayDropdown;
        this.setState({showWeekdayDropdown: opposite});
        Mixpanel.track( (opposite ? 'Open ' : 'Close ') + 'weekday dropdown');
    }

    handleWeekdaySelected = (d) => {
        const {updateWorkWeekStartDay, locationData} = this.props;
        // console.log(JSON.stringify(locationData) + ': ' + d);
        updateWorkWeekStartDay(locationData.id,d);
        this.setState({showWeekdayDropdown: false});
        Mixpanel.track('Set week start', {'fiscal_day': d});
    }

    render() {
        const {locationData} = this.props;
        const {showWeekdayDropdown} = this.state;

        const weekdays = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'];
        const fiscalDay = locationData.fiscal_day;

        const configurationTitle = <FormattedMessage id="Settings.configuration" defaultMessage="Configurations"/>;
        const startOfWeekText = <FormattedMessage id="Settings.startOfWeek" defaultMessage="Start of work week"/>;

        return (
           <div className='configuration-container'>
                <span className='configuration-title'>{configurationTitle}</span> 
                <div className='week-inline'>
                    <div className='week-start-text'>{startOfWeekText}</div>
                    <button className='week-dropdown' onClick={this.handleToggleDropdown}>{fiscalDay}</button>
                </div>
                <div className='weekday-list' style={{ display: showWeekdayDropdown ? 'flex' : 'none'}}>
                    <ul>
                        {weekdays.map((weekday) => {
                            return <li onClick={ (e) => {this.handleWeekdaySelected(weekday)}} className={weekday === fiscalDay ? 'weekday-active averta-semibold' : 'weekday' }>
                                        <span>{weekday}</span>
                                        <img style={{display : weekday === fiscalDay ? 'inline-block' : 'none'}} src='/icons/blue-check-mark.svg'></img>
                                    </li>;
                        })}
                    </ul>
                </div>
            </div> 
        );
    }

}

export default ConfigurationPage;