import { sizeMap } from './assets/flagSizeMap';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const getFlagData = (isoCode) => sizeMap.filter((flag) => flag.isoCode === isoCode)[0];

const Flag = styled.div`
  width: ${(props) => getFlagData(props.isoCode).width}px;
  height: ${(props) => getFlagData(props.isoCode).height}px;
  background-image: url(/assets/flags.png);
  background-repeat: no-repeat;
  background-position: ${(props) => getFlagData(props.isoCode).xPos}px 0px;
  display: inline-block;
  margin-right: 0.75rem;
  border: 1px solid #d1d1d1;

  @media only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 2dppx),
    only screen and (min-resolution: 192dpi) {
    background-image: url(/assets/flags@2x.png);
    background-size: 5630px 15px;
  }
`;

Flag.propTypes = {
    isoCode: PropTypes.string,
};

Flag.defaultProps = {
    isoCode: 'us',
};

export default Flag;