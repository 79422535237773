import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import constants from '../../constants';
import styled from './styled';
import './Header.scss';

class Confirm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: false,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCancel = (event) => {
    const { notifyConfirm, } = this.props;
    notifyConfirm(false);
  }

  handleConfirm = (event) => {
    const { notifyConfirm, } = this.props;
    notifyConfirm(true);
  }

  render() {
    const { selectedAction, selectedShifts, selectedPendingShifts, selectedPendingRTOs } = this.props;
    const { userActionTypes } = constants;

    let w = 400;
    let h = 205;
    let winTop = `${(window.innerHeight - h) / 2}px`;
    let winLeft = `${(window.innerWidth - w) / 2}px`;
    let winH = `${h}px`;
    let winW = `${w}px`;


    const approveRTOText = (<FormattedMessage
      id="ConfirmRequestsV3.approveRTO"
      defaultMessage="{number, plural, one {{number} RTO selected will be approved} other {{number} RTOs selected will be approved}}"
      values={{ number: selectedPendingRTOs.length }} />);

    const approveShiftText = (<FormattedMessage
      id="ConfirmRequestsV3.approveShift"
      defaultMessage="{number, plural, one {{number} Shift request selected will be approved} other {{number} Shift requests selected will be approved}}"
      values={{ number: selectedPendingShifts.length }} />);

    const denyRTOText = (<FormattedMessage
      id="ConfirmRequestsV3.denyRTO"
      defaultMessage="{number, plural, one {{number} RTO selected will be denied} other {{number} RTOs selected will be denied}}"
      values={{ number: selectedPendingRTOs.length }} />);

    const denyShiftText = (<FormattedMessage
      id="ConfirmRequestsV3.denyShift"
      defaultMessage="{number, plural, one {{number} Shift request selected will be denied} other {{number} Shift requests selected will be denied}}"
      values={{ number: selectedPendingShifts.length }} />);

    const cancel = (<FormattedMessage
      id="Confirm.cancel"
      defaultMessage="Cancel" />);

    const confirm = (<FormattedMessage
      id="Confirm.confirm"
      defaultMessage="Confirm" />);

    let topLine = '';
    let bottomLine = '';

    switch (selectedAction.name) {
      case userActionTypes.APPROVE:
      case userActionTypes.APPROVE_RTO:
        if (selectedPendingShifts.length > 0) {
          topLine = approveShiftText;
        }
        if (selectedPendingRTOs.length > 0) {
          bottomLine = approveRTOText;
        }
        break;
      case userActionTypes.DENY:
      case userActionTypes.DENY_RTO:
        if (selectedPendingShifts.length > 0) {
          topLine = denyShiftText;
        }
        if (selectedPendingRTOs.length > 0) {
          bottomLine = denyRTOText;
        }
        break;
    }

    let icon;
    if (selectedAction.name === userActionTypes.APPROVE || selectedAction.name === userActionTypes.APPROVE_RTO) {
      icon = "/icons/checkmark-icon.svg"
    } else {
      icon = "/icons/exclaimation-mark-icon.svg"
    }

    if (selectedAction.name === userActionTypes.APPROVE || selectedAction.name === userActionTypes.APPROVE_RTO || selectedAction.name === userActionTypes.DENY_RTO || selectedAction.name === userActionTypes.DENY) {
      return (
        <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
          <label className="confirm-operation__title">{selectedAction.body}</label>

          <div className="confirm__label-wrap">
            {topLine && (<div className="confirm__text-wrap">
              <img className="confirm__img" src={icon} />
              <label className="confirm__text">{topLine}</label>
            </div>)}
            {bottomLine && (<div className="confirm__text-wrap">
              <img className="confirm__img" src={icon} />
              <label className="confirm__text">{bottomLine}</label>
            </div>)}
          </div>

          <div className="confirm__button-wrap">
            <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
            <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>
          </div>

        </styled.WinContainerConfirm>
      );
    }
  }
}

Confirm.propTypes = {
  selectedItem: PropTypes.object.isRequired,
  selectedType: PropTypes.string.isRequired
};

export default injectIntl(Confirm);