import { connect } from 'react-redux';

//import PinsComponent from './pins-component';
import PinsComponent from './PinsComponent';

import {
  teamFeedActions,
  teamFeedOperations,
  teamFeedSelectors,
} from '../../duck';

const mapStateToProps = (state) => {
  const { teamFeedReducer, appReducer, setupReducer, } = state;

  return {
    pins: teamFeedSelectors.getPinnedPosts(teamFeedReducer),
    activeSidePane: teamFeedSelectors.getActiveSidePane(teamFeedReducer),
    locationSettings: appReducer.location.settings,
    active: teamFeedReducer.active,
    activeChannel: teamFeedSelectors.getActiveChannel(state),
    allUsers: appReducer.allUsers.users,
    onlineUsers: appReducer.onlineUsers.users,
    userId: setupReducer.setup_actions.meta.current_user.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  //return {};
  const resetActiveSidePane = () => {
  	dispatch(teamFeedActions.updateActiveSidePane(''));
  };

  return {
  	resetActiveSidePane,
  }
  
};

export default connect(mapStateToProps, mapDispatchToProps)(PinsComponent);
