import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';


const WinContainer = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  minHeight: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2;
  //overflow-y: scroll;
  /*
  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }
  */

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const WinContainerGroupList = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2;
  overflow-y: scroll;
  /*
  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }
  */

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const WinContainerConfirm = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  //width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  width: fit-content;
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2; 
  //overflow: scroll;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  //border: 1px solid red;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const WinContainerSearch = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  //width: fit-content;
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2; 
  //overflow: scroll;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
  //border: 1px solid red;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const Overlay = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: ${(props) => props.zindex || 2};
  cursor: pointer; /* Add a pointer on hover */
  //rgba(198,198,201,255)
`;

const WinContainerFilter = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2;
  overflow-y: scroll;
  /*
  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }
  */

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;


export default {
  WinContainer,
  WinContainerConfirm,
  WinContainerGroupList,
  Overlay,
  WinContainerSearch,
  WinContainerFilter,
};
