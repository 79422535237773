import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { ProfileThumb } from '@shyft/acorns';
import ProfileConfirmation from '../ProfileConfirmation/ProfileConfirmation';
import ConfirmRemove from './ConfirmRemove';
import Modal from './Modal';
import { default as Styled } from './styled';


import { placeholderStrings } from './strings';

class ProfileComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: null,
      lastName: null,
      position: null,
      badge: null,
      skills: [],
      departments: [],
      privileges: [],
      showButton: false,
      removePicture: false,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { userSkills, userDepartments, locationSettings, skills, locationDepartments, userPrivileges, user, edit, } = this.props;
    const { attributes } = this.props.user;

    this._isMounted = true;
    if (this._isMounted) {
      this.setState({ skills: userSkills });
      this.setState({ departments: userDepartments });
      this.setState({ privileges: userPrivileges });
      const showButton = edit && (attributes.profilePicture && !attributes.profilePicture.includes('avatar1.png'));
      this.setState({ showButton: showButton });
    }

  }

  componentDidUpdate(prevProps) {
    const { userSkills, userDepartments, userPrivileges, user, edit, } = this.props;
    const { attributes } = this.props.user;
    const { privileges } = this.state;

    if (prevProps.userSkills !== userSkills) {
      if (this._isMounted) {
        this.setState({ skills: userSkills });
      }
    }
    if (prevProps.userDepartments !== userDepartments) {
      if (this._isMounted) {
        this.setState({ departments: userDepartments });
      }
    }
    if (prevProps.user !== user) {
      this.setState({ badge: user.attributes.badge_id, position: user.attributes.position });
    }

    if (prevProps.edit !== edit) {
      const showButton = edit && (attributes.profilePicture && !attributes.profilePicture.includes('avatar1.png'));
      this.setState({ showButton: showButton });
    }

  }

  componentWillUnmount() {
    const { resetCurrentUser, } = this.props;

    resetCurrentUser();
    this._isMounted = false;
  }

  _changeDetails = (e) => {
    const { value, name } = e.target;

    this.setState({
      [name]: value,
    });
  };

  _changeBadge = (e) => {
    const { value, name } = e.target;

    //const result = event.target.value.replace(/[^a-zA-Z0-9]+/ig, ''); // Only allow letters and 0-9
    const result = event.target.value.replace(/[^0-9]+/ig, ''); // Only allow 0-9

    this.setState({
      badge: result,
    });
  };

  _confirm = () => {
    const { user, submitUserDetails, _cancelEditMode, submitUserAvatar, } = this.props;
    const { firstName, lastName, position, badge, removePicture, } = this.state;

    submitUserDetails(
      user.id,
      user.attributes.ownerId,
      firstName,
      lastName,
      position,
      badge
    );

    _cancelEditMode();
  };

  _cancel = () => {
    const { firstName, lastName, position, badge, _cancelEditMode } = this.props;

    this.setState({
      firstName,
      lastName,
      position,
      badge,
    });
    this.setState({ removePicture: false });

    _cancelEditMode();
  };

  sortSkillContentWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof (ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function (a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }
    }
    return arr.sort(sorter);
  }

  sortDepartmentNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof (ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function (a, b) {
      if (a.name === b.name) // identical? return 0
        return 0;
      else if (a.name === null)  // a is null? last 
        return 1;
      else if (b.name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.name.localeCompare(b.name) * multiplier;
      }
    }
    return arr.sort(sorter);
  }

  renderUserSkills = () => {
    const { userSkills, } = this.props;

    const copySkills = [...userSkills];
    this.sortSkillContentWithNullValues(copySkills, true);

    if (copySkills.length > 0) {
      const skills = copySkills.map((skill) => (
        <Styled.SkillName>
          {skill.skill_content}
        </Styled.SkillName>
      ));
      if (skills && skills[0]) {
        return skills;
      } else {
        return null;
      }
    }
  }

  renderUserDepartments = () => {
    const { departments, } = this.state;

    const copyDepartments = [...departments];
    this.sortDepartmentNameWithNullValues(copyDepartments, true);
    if (copyDepartments.length > 0) {
      const depts = copyDepartments.map((dept) => (
        <Styled.SkillName>
          {dept.name}
        </Styled.SkillName>
      ));
      if (depts && depts[0]) {
        return depts;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  _userInfo = () => {
    const { edit, intl, userSkills, locationSettings, userPrivileges, } = this.props;
    const { attributes } = this.props.user;
    const { badge } = this.state;
    const firstName = attributes.firstName ? attributes.firstName : '';
    const lastName = attributes.lastName ? attributes.lastName : '';
    const badgeId = attributes.badge_id ? attributes.badge_id : '';

    const txtSkills = (<FormattedMessage
      id="ProfileComponent.skills"
      defaultMessage="Skills"
    />);
    const txtDepartment = (<FormattedMessage
      id="ProfileComponent.department1"
      defaultMessage="Departments"
    />);

    if (!edit) {
      return (
        <Styled.InfoWrap>
          <Styled.UserName>{`${firstName} ${lastName}`}</Styled.UserName>
          {!attributes.position || (
            <Styled.UserPosition>{attributes.position}</Styled.UserPosition>
          )}
          <Styled.UserPosition>{badgeId}</Styled.UserPosition>
          {locationSettings.cfg__skill === 1 && <Styled.SkillInfoWrap>
            <label>{txtSkills}</label>
            <Styled.SkillNameWrap>
              {this.renderUserSkills()}
            </Styled.SkillNameWrap>
          </Styled.SkillInfoWrap>}
          {locationSettings.cfg__departments === 1 && <Styled.SkillInfoWrap>
            <label>{txtDepartment}</label>
            <Styled.SkillNameWrap>
              {this.renderUserDepartments()}
            </Styled.SkillNameWrap>
          </Styled.SkillInfoWrap>}
        </Styled.InfoWrap>
      );
    } else {
      return (
        <Styled.EditWrap>

          <Styled.LabelWrap>
            <Styled.LineLabel> {intl.formatMessage(placeholderStrings.firstName)} : </Styled.LineLabel>
            <Styled.LineLabel> {intl.formatMessage(placeholderStrings.lastName)} : </Styled.LineLabel>
            <Styled.LineLabel> {intl.formatMessage(placeholderStrings.position)} : </Styled.LineLabel>
            <Styled.LineLabel> {intl.formatMessage(placeholderStrings.badge)} : </Styled.LineLabel>
          </Styled.LabelWrap>

          <Styled.LabelWrap>

            <Styled.Input name="firstName" defaultValue={attributes.firstName} onChange={this._changeDetails} />
            <Styled.Input name="lastName" defaultValue={attributes.lastName} onChange={this._changeDetails} />
            <Styled.Input name="position" defaultValue={attributes.position} onChange={this._changeDetails} />
            <Styled.Input name="badge" defaultValue={badgeId} value={badge} onChange={this._changeBadge} />

          </Styled.LabelWrap>

        </Styled.EditWrap>
      );
    }
  };

  removePhoto = () => {
    const { user, submitUserDetails, submitUserAvatar, } = this.props;
    const { firstName, lastName, position, badge, } = this.state;
    /*
    submitUserAvatar(
      user.id,
      user.attributes.ownerId,
    );
    */
    this.setState({ removePicture: true });
  }

  closeModalWindow = () => {
    this.setState({ removePicture: false });
  }

  cancelRemove = () => {
    const { _cancelEditMode } = this.props;
    this.closeModalWindow();
    _cancelEditMode();
  }

  confirmRemove = () => {
    const { user, submitUserDetails, submitUserAvatar, _cancelEditMode } = this.props;
    submitUserAvatar(
      user.id,
      user.attributes.ownerId,
    );
    this.closeModalWindow();
    _cancelEditMode();
  }

  render() {
    const { edit } = this.props;
    const { attributes } = this.props.user;
    const { showButton, } = this.state;
    let msg = '';

    //const showButton = edit && (attributes.profilePicture && !attributes.profilePicture.includes('avatar1.png'));
    const txtRemovePhoto = (<FormattedMessage
      id="ProfileComponent.removePhoto"
      defaultMessage="Remove Photo"
    />);
    return (
      <Styled.ProfileWrap>
        <Styled.ThumbWrap>
          <ProfileThumb
            thumbUrl={attributes.profilePicture}
            width="75px"
            height="75px"
          />
          {showButton && <Styled.RemovePhotoButton onClick={this.removePhoto}>{txtRemovePhoto}</Styled.RemovePhotoButton>}
        </Styled.ThumbWrap>
        {this._userInfo()}
        <ProfileConfirmation
          visible={edit}
          cancel={this._cancel}
          confirm={this._confirm}
        />
        {this.state.removePicture && <Modal>
          <ConfirmRemove
            closeWin={this.closeModalWindow}
            cancelRemove={this.cancelRemove}
            confirmRemove={this.confirmRemove}
          >
          </ConfirmRemove>
        </Modal>}
      </Styled.ProfileWrap>
    );
  }
}

ProfileComponent.propTypes = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  position: PropTypes.string,
  profile_image_url: PropTypes.string,
};

ProfileComponent.defaultProps = {
  position: '',
  profile_image_url: '',
};

export default injectIntl(ProfileComponent);
