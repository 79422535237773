import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../../../components/GeneralComponents/TableComponent/TableRow/messages';
import styled from './styled';
import constants from 'pages/RequestsV3/constants';
import Confirm from '../../Header/Confirm';
import Modal from 'components/GeneralComponents/Modal';
import StyledOverlay from 'pages/RequestsV3/components/Header/styled';

// Static mappings
const STATUS_MAP = {
  '#a': { type: 'approved', messageKey: 'messages.approved' },
  '#p': { type: 'pending', messageKey: 'messages.pending' },
  '#d': { type: 'failed', messageKey: 'messages.denied' },
};

const TRADE_STATUS_MAP = {
  open: 'active',
  posted: 'active',
  pending: 'pending',
  requested: 'pending',
  denied: 'failed',
  rejected: 'failed',
  covered: 'approved',
  approved: 'approved',
};

class ActionCell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      appStatus: '',
      showConfirm: false,
      selectedAction: null,
    };
  }

  componentDidMount() {
    this.updateStatus(this.props.shift);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shift !== this.props.shift) {
      this.updateStatus(this.props.shift);
    }
  }

  updateStatus(shift) {
    if (!shift || shift.time_off_reason !== undefined || !shift.coverer) {
      this.setState({ appStatus: '' });
      return;
    }

    const { trade_status: tradeStatus, coverer } = shift;

    if (!coverer.first_name && coverer.last_name) {
      this.setState({ appStatus: coverer.last_name });
      return;
    }

    const statusSymbol = STATUS_MAP[tradeStatus]?.type || '';
    this.setState({ appStatus: statusSymbol });
  }

  handleApproveClick = () => {
    const { notifyActionRequested, } = this.props;
    const { userActionTypes } = constants;
    const approveText = (<FormattedMessage
      id="RequestsV3.approve"
      defaultMessage="Approve Requests?"
    />);
    const action = { name: userActionTypes.APPROVE, body: approveText };
    this.setState({ showConfirm: true, selectedAction: action });
  }

  handleDenyClick = () => {
    const { notifyActionRequested, } = this.props;
    const { userActionTypes } = constants;
    const denyText = (<FormattedMessage
      id="RequestsV3.deny"
      defaultMessage="Deny Requests?"
    />);

    const action = { name: userActionTypes.DENY, body: denyText };
    this.setState({ showConfirm: true, selectedAction: action });
  }

  notifyConfirm = (request) => {
    const { selectedAction, } = this.state;
    const { notifyActionRequested, notifyInlineActionRequested, shift } = this.props;
    const { userActionTypes } = constants;

    this.setState({ showConfirm: false });
    notifyInlineActionRequested(request, selectedAction.name, shift);
  }

  renderButtons() {
    const { shift } = this.props;
    const isShift = shift?.time_off_reason === undefined;

    return (
      <div className="employee-page-header__button-group-1-wrap">
        <button className="employee-page-header__approve-users" onClick={() => this.handleApproveClick()}>
          <FormattedMessage id="Header.approveUsers" defaultMessage="Approve" />
        </button>
        <button className="employee-page-header__deny-users" onClick={() => this.handleDenyClick()}>
          <FormattedMessage id="Header.denyUsers" defaultMessage="Deny" />
        </button>
      </div>
    );
  }

  render() {
    const { shift, notifyInlineActionRequested } = this.props;
    const { appStatus, showConfirm, selectedAction } = this.state;

    const isShift = shift.time_off_reason === undefined;
    const statusType = isShift
      ? TRADE_STATUS_MAP[shift?.trade_status]
      : TRADE_STATUS_MAP[shift?.status];

    const renderConfirmModal = () => (
      <>
        <Modal>
          <Confirm
            selectedAction={selectedAction}
            notifyConfirm={this.notifyConfirm}
            selectedPendingRTOs={isShift ? [] : [shift]}
            selectedPendingShifts={isShift ? [shift] : []}
          />
        </Modal>
        <StyledOverlay.Overlay display={showConfirm ? 'block' : 'none'} />
      </>
    );

    if (appStatus === '#p' || statusType === 'pending') {
      if (showConfirm) {
        return renderConfirmModal();
      }
      return this.renderButtons();
    }

    return null;
  }

}

ActionCell.propTypes = {
  shift: PropTypes.object.isRequired,
};

export default injectIntl(ActionCell);
