import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';


const ViewDetailsBtnWrap = styled.div`
  flex: 0;
  margin-left: 1rem;
  position: absolute;
  top: 0px;
  left: 295px;
  height: 30px;
  border: 1px solid blue;

  @media (max-width: 800px) {
    left: 225px;
    max-width: 80px;
  }
`;

const ViewDetailsButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary[0]};
  background-color: white;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border-radius: 15px;
  padding: 5px 8px;
  cursor: pointer;
  max-width: 120px;
  max-height: 30px;
  //width: 130px;
  width: 105px;
  height: 30px;
  top: 0px;
  left: 300px;
  position: absolute;
  //margin-top: 2.5rem;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 5px;
  //border: 1px solid red;


  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary[0]};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }
`;

const UserAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

const Icon = styled.img`
  /* flex: 0; */
  width: 13px;
  height: auto;
  margin-right: 0.25rem;
  margin-top: 2px;

  //border: 1px solid red;
`;

const Text = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.label};
  margin-bottom: 0;
  flex: 1;

  ${applyStyleModifiers({
    large: () => `
      font-size: 12px;
      color: inherit;
    `,
  })}
  //border: 1px solid green;
`;

const DateTimeWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //margin-bottom: ${({ marginBottom }) => marginBottom};
  width: 100%;
  //border: 1px solid red;
  font-size: 14px;

`;

const RequestText = styled.p`
  font-size: ${rem('14px')};
  //color: ${({ theme }) => theme.palette.label};
  color: black;
  margin-left: 10px;
  flex: 1;
  display: block;
  //white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 250px;
 
  //border: 1px solid green;
`;

const TopWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //margin-bottom: ${({ marginBottom }) => marginBottom};
  width: 70%;
  //border: 1px solid black;

`;

const WinContainer = styled.section`
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white;
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  //border-radius: 10px;
  z-index: 3;
  overflow-y: scroll;
  //border: 1px solid red;
`;

const CloseButton = styled.button`
  top: 0px;
  left: 10px;
  width: 75px;
  height: 35px;
  position: relative;
  color: black;
  background-color: none;
  text-align: center;
  justify-content: center;
  font-size: 30px;
  margin-left: 250px;
  //border-radius: 6px;
  //box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
`;

const Header = styled.div `
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  //border: 1px solid green;
`;
const HeaderTitleWrap = styled.div `
  display: flex;
  flex-direction: column;
  alignItems: center;
  justifyContent: center;
  //position: absolute
  //border: 1px solid blue;
`;
const HeaderText = styled.p `
  //font-size: 30px;
  width: 380px;
  margin-left: 370px;
  margin-bottom: 0px;
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  //border: 1px solid red;
`;

const SubmitText = styled.p `
  //width: 380px;
  margin-left: 370px;
  margin-top: 0px;
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: rgba(147,147,147,255);
  //border: 1px solid red;
`;

const TopRow = styled.div `
  display: flex;
  flex-direction: row;
  height: 80px;
  margin-top: 30px;
  //border: 1px solid red;
`;

const MiddleRow = styled.div `
  display: flex;
  flex-direction: row;
  height: 80px;
  margin-top: 20px;

  //border: 1px solid green;
`;
const BottomRow = styled.div `
  display: flex;
  flex-direction: row;
  height: 70px;
  margin-top: 20px;

  //border: 1px solid red;
`;

const Element = styled.div `
  width: ${(props) => props.width || '120px'};
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 15px;
  //border: 1px solid blue;

  &: last-child {
    margin-right: 50px;
  }
`;
const ElementName = styled.label `
  //font-size: 16px;
  color: rgba(135,135,135,255);
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;

`;
const ElementText = styled.label `
  //font-size: 16px;
  color: black;
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
  //border: 1px solid red;
`;

const ActionBtn = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['x-small'].concat(modifiers),
}))`
  width: 200px;
  margin-bottom: 0.5rem;
  //font-size: 24px;
  align-items: center;
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;

  &: first-child {
    margin-left: 300px;
  }

  &: last-child {
    width: 300px;
    margin-left: 30px;
    margin-right: 300px;
  }

  &: focus {
    outline: none;
  }
`;

const ShiftRequestsLoadingLayer = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  top: 45%;
  margin: auto;

  > img {
    display: block;
    margin: auto;
    margin-top: 45px;
  }
`;

const Overlay = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  margin-left: 60px;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(19,20,21,0.25);
  box-shadow: inset 0 1px 3px 0;
  rgba(255,255,255,0.50);
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: default; /* Add a pointer on hover */
`;



export default {
  ViewDetailsBtnWrap,
  ViewDetailsButton,
  UserAvatar,
  Icon,
  Text,
  DateTimeWrap,
  RequestText,
  TopWrap,
  WinContainer,
  CloseButton,
  Header,
  HeaderTitleWrap,
  HeaderText,
  SubmitText,
  TopRow,
  MiddleRow,
  BottomRow,
  Element,
  ElementName,
  ElementText,
  ActionBtn,
  Overlay,
  ShiftRequestsLoadingLayer,
};
