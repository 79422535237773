import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './InviteWithEmailComponent.scss';
import '../../App/layout.scss';
import '../../App/fonts.scss';
import { CountrySelector, } from '@shyft/acorns';

import { inviteCall } from '../../helpers/api-calls/user-calls';
import { validateEmail} from 'helpers/validation';

import {Mixpanel} from '../../Mixpanel';
import { Branchlink } from 'helpers/branchlink';

class InviteWithEmailComponent extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        confirmButtonEnabled:false, 
        email: '',
        shouldShowEmailEntryField: false,
      };
      this.emailInputRef = React.createRef();
    }
  
    componentDidMount() {
      const {userToInvite,locationData } = this.props;

      Branchlink.initializeBranchLink(locationData);

      if( userToInvite.email){
        this.setState({shouldShowEmailEntryField: false});
        this.setState({confirmButtonEnabled: true});
      }else{
        this.setState({shouldShowEmailEntryField: true});
      }
    }

    handleEmailChange = (e) => {
      const val = e.target.value;
      this.setState({email: val});
      if( val && validateEmail(val)){
        this.setState({confirmButtonEnabled: true});
      }else{
        this.setState({confirmButtonEnabled: false});
      }
    }

    handleSendEmailInviteAction = (e) => {
      const {userToInvite, locationId, updateUnregisteredUserDispatch} = this.props;
      const {confirmButtonEnabled, email } = this.state;

      const link = Branchlink.getSMSInviteLink(locationId);

      const fullName = userToInvite?.first_name + ' ' + userToInvite?.last_name;

      if( userToInvite.email){
        Mixpanel.track('Send invite to saved email', {'email':userToInvite.email, 'link':link}); 
        this.sendEmailInvite(userToInvite.email, fullName, locationId, link);
      }else if ( confirmButtonEnabled ) {
        updateUnregisteredUserDispatch(userToInvite.id, email);
        Mixpanel.track('Send invite to new email', {'email':email, 'link': link}); 
        this.sendEmailInvite(email, fullName, locationId, link);
      }else{
        Mixpanel.error('Send Email Invite');
      }
    }

    sendEmailInvite(email, fullName, locationId, link){
      const {handleCloseInviteModal } = this.props;
      
      inviteCall(email, fullName, locationId, link).then(
        (response) => {
          if (response.status === 200) {
            handleCloseInviteModal();
          }
        },
        (error) => { }
      );
    }

    _handleCountryChange = (code) => {
      this.setState({ dialCode: code });
    };

    trackAndClose() {
     const {handleCloseInviteModal}  = this.props;
      Mixpanel.track('Close email invite modal');
      handleCloseInviteModal();
    }

    render() {
        const {userToInvite,} = this.props;
        const {confirmButtonEnabled, email, shouldShowEmailEntryField} = this.state;

        const userName = userToInvite.first_name;
        const userEmail = userToInvite.email;

        const addMemberText = (<FormattedMessage id="AddUnregisteredUserComponent.inviteTeamTitle" defaultMessage="Invite Team Member" />);
        const emailText = (<FormattedMessage id="AddUnregisteredUserComponent.emailDisplay" 
            defaultMessage="{name }'s email: {num}" 
            values={{ num:  userEmail, name: userName}} />);
        const explainText = (<FormattedMessage id="AddUnregisteredUserComponent.inviteExplain" 
            defaultMessage="Invite {name} to join your team by sending a download link to the Shyft Mobile app" 
            values={{ name:  userName  }} />);
        const sendLinkText = (<FormattedMessage id="AddUnregisteredUserComponent.sendEmail" defaultMessage="Send Email Invitation" />);

        return (
            <div className="invite-team-member-container modal-container">
              <div className='flex-row title-row'>
                <label className="title averta-regular fs20px">{addMemberText}</label>
                <button onClick={ (e) => {this.trackAndClose();} }> <img src="icons/corner-close.svg" /> </button>
              </div>

              <div className='flex-row'>
                <div className="averta-regular fs14px primaryblack explain-row">{explainText}</div>

                {(shouldShowEmailEntryField === false) && <div className="averta-regular fs14px primaryblack phone-row">{emailText}</div>}

                { (shouldShowEmailEntryField === true) &&
                  <div className='flex-row country-wrap'> 
                    <input onChange={this.handleEmailChange} value={email} ref={this.emailInputRef}></input> 
                  </div> }

              </div>

              <div className="confirm-button-wrap">
                <button className={confirmButtonEnabled ? "confirm-button-cancel averta-semibold fs14px action-btn" : "confirm-button-cancel averta-semibold fs14px action-btn button-disabled"} 
                onClick={this.handleSendEmailInviteAction}>{sendLinkText}</button>
              </div>
      
            </div>
          );
    }
}

export default injectIntl(InviteWithEmailComponent);