import { createSlice } from '@reduxjs/toolkit';

const activeSlice = createSlice({
  name: 'active',
  initialState: {
    channel: 0,
    comment: 0,
    //post: 0,
    post: {
      id: 0,
      channel_name: '',
    },
    sidepane: null,
    delete: {
      type: '',
      id: 0,
    },
    error: {
      code: 0,
      action: '',
    },
    userIsAdmin: false,
    subscriptions: null,
  },
  reducers: {
    updateActiveChannel: (state, action) => {
      state.channel = action.payload;
    },
    updateActiveChannelAdmin: (state, action) => {
      state.userIsAdmin = action.payload.is_admin;
    },
    updateActiveChannelSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    updateActiveComment: (state, action) => {
      state.comment = action.payload;
    },
    updateActivePost: (state, action) => {
      //state.post = action.payload;
      state.post.id = action.payload.id;
      state.post.channel_name = action.payload.channel_name;
    },
    updateActiveDelete: (state, action) => {
      state.delete.type = action.payload.type;
      state.delete.id = action.payload.id;
    },
    updateActiveSidePane: (state, action) => {
      state.sidepane = action.payload;
    },
    clearActiveDelete: (state) => {
      state.delete = {
        type: '',
        id: 0,
      };
    },
    updateActiveError: (state, action) => {
      state.error = action.payload;
    },
    updateSubscriptionChannelInfo: (state, action) => {
      if(state.subscriptions) {
        state.subscriptions.channel.channel_name = action.payload.channel_name;
        state.subscriptions.channel.description = action.payload.description;
      }
    },
  },
});

export default activeSlice;
