import styled from 'styled-components/macro';
import blocks from '../../blocks';
import { rgba } from 'polished';
import { rem } from 'polished';

const MessageWrap = styled.div`
  position: relative;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  background-color: white;
  //border-bottom: 1px solid rgba(227,227,230,255);

  &:hover {
    //background-color: ${({ theme }) => lighten(0.1, theme.palette.border)};
    background-color: rgba(242,242,247,255);
  }
  //border: 1px solid red;
`;

const AvatarWrap = styled.div`
  width: 36px;
  margin-right: 1rem;
  flex: 0 0 36px;
  position: relative;
  //border: 1px solid red;
`;

const ProfileAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  //margin-top: 3px;
  //margin-left: 3px;
  //border: 1px solid red;
`;

const OnlineMark = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  top: 28px;
  right: 0px;
  background-color: rgb(0,222, 1);
`;

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3px;
  padding-bottom: 3px;
  //border: 1px solid blue;
`;

const Name = styled.label`
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
  margin-right: 1rem;
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 12px;
  font-weight: 600;
`;

const Position = styled.label`
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
  margin-right: 1rem;
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 300;
`;

const ApprovedBadge = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: 0px;
  //border: 1px solid red;
`;

const BottomLineWrap = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  height: 15px;
  //border: 1px solid red;
`;

const Dot = styled.span `
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
  //border: 1px solid green;
`;

const BottomLineText = styled.label `
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  //margin: auto;
  margin-top: 8px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //border: 1px solid blue;
`;

const BottomLineTextOneItem = styled.label `
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  margin: auto;
  min-width: 140px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 15px;
  //border: 1px solid green;
`;

export default {
  MessageWrap,
  AvatarWrap,
  ProfileAvatar,
  OnlineMark,
  InfoWrap,
  Name,
  Position,
  ApprovedBadge,
  BottomLineWrap,
  Dot,
  BottomLineText,
  BottomLineTextOneItem,
};

