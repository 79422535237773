import { defineMessages } from 'react-intl';

const tableHeadMessages = defineMessages({
  date: {
    id: 'CalendarTableHeaders.date',
    defaultMessage: 'Date',
  },
  owner: {
    id: 'CalendarTableHeaders.owner',
    defaultMessage: 'Requested By',
  },
  coverer: {
    id: 'CalendarTableHeaders.coverer',
    defaultMessage: 'Covered By',
  },
  startAt: {
    id: 'CalendarTableHeaders.startAt',
    defaultMessage: 'Start Time',
  },
  endAt: {
    id: 'CalendarTableHeaders.endAt',
    defaultMessage: 'End Time',
  },
  tradeStatus: {
    id: 'CalendarTableHeaders.tradeStatus2',
    defaultMessage: 'Status',
  },
  shiftNotes: {
    id: 'CalendarTableHeaders.shiftNotes',
    defaultMessage: 'Notes',
  },
  type: {
    id: 'CalendarTableHeaders.type',
    defaultMessage: 'Type',
  },
  channel: {
    id: 'CalendarTableHeaders.channel',
    defaultMessage: 'Channel',
  },
  timeRange: {
    id: 'CalendarTableHeaders.timeRange',
    defaultMessage: 'Time Range',
  },
  skills: {
    id: 'CalendarTableHeaders.skills',
    defaultMessage: 'Skills',
  },
});

export const CalendarTableHeaders = [
  {
    title: tableHeadMessages.type,
    key: 'name',
    type: 'enum',
  },
  {
    title: tableHeadMessages.date,
    key: 'start_at',
    type: 'date',
  },
  {
    title: tableHeadMessages.channel,
    key: 'channel_name',
    type: 'text',
  },
  {
    title: tableHeadMessages.owner,
    key: 'owner',
    type: 'user',
  },
  {
    title: tableHeadMessages.coverer,
    key: 'coverer',
    type: 'user',
  },
  {
    title: tableHeadMessages.startAt,
    key: 'start_at',
    type: 'time',
  },
  {
    title: tableHeadMessages.endAt,
    key: ['start_at', 'end_at'],
    type: 'time',
  },
  {
    title: tableHeadMessages.tradeStatus,
    key: 'trade_status',
    type: 'enum',
  },
  {
    title: tableHeadMessages.shiftNotes,
    key: 'content',
    type: 'text',
  },
];

export const CalendarTableHeaders1 = [
  {
    title: tableHeadMessages.type,
    key: 'name',
    type: 'enum',
  },
  {
    title: tableHeadMessages.date,
    key: 'start_at',
    type: 'date',
  },
  {
    title: tableHeadMessages.channel,
    key: 'channel_name',
    type: 'text',
  },
  {
    title: tableHeadMessages.owner,
    key: 'owner',
    type: 'user',
  },
  {
    title: tableHeadMessages.coverer,
    key: 'coverer',
    type: 'user',
  },
  {
    title: tableHeadMessages.timeRange,
    key: ['start_at', 'end_at'],
    type: 'time_range',
  },
  {
    title: tableHeadMessages.tradeStatus,
    key: 'trade_status',
    type: 'enum',
  },
  {
    title: tableHeadMessages.shiftNotes,
    key: 'content',
    type: 'text',
  },
  {
    title: tableHeadMessages.skills,
    key: 'skill_ids',
    type: 'skills',
  },
];

export const CalendarTableHeaders2 = [
  {
    title: tableHeadMessages.type,
    key: 'name',
    type: 'enum',
  },
  {
    title: tableHeadMessages.date,
    key: 'start_at',
    type: 'date',
  },
  {
    title: tableHeadMessages.channel,
    key: 'channel_name',
    type: 'text',
  },
  {
    title: tableHeadMessages.owner,
    key: 'owner',
    type: 'user',
  },
  {
    title: tableHeadMessages.coverer,
    key: 'coverer',
    type: 'user',
  },
  {
    title: tableHeadMessages.timeRange,
    key: ['start_at', 'end_at'],
    type: 'time_range',
  },
  {
    title: tableHeadMessages.tradeStatus,
    key: 'trade_status',
    type: 'enum',
  },
  {
    title: tableHeadMessages.shiftNotes,
    key: 'content',
    type: 'text',
  },
];

const csvHeadMessages = defineMessages({
  shiftNotes: {
    id: 'csvHeadMessages.shiftNotes',
    defaultMessage: 'Notes',
  },
  type: {
    id: 'csvHeadMessages.type',
    defaultMessage: 'Type',
  },
  timeZone: {
    id: 'csvHeadMessages.timeZone',
    defaultMessage: 'Time Zone',
  },
  timePosted: {
    id: 'csvHeadMessages.timePosted',
    defaultMessage: 'Time Posted',
  },
  timeCovered: {
    id: 'csvHeadMessages.timeCovered',
    defaultMessage: 'Time Covered',
  },
  managerName: {
    id: 'csvHeadMessages.managerName',
    defaultMessage: 'Manager Name',
  },
  timeManaged: {
    id: 'csvHeadMessages.timeManaged',
    defaultMessage: 'Time Managed',
  },
  locationName: {
    id: 'csvHeadMessages.locationName',
    defaultMessage: 'Location Name',
  },
  address: {
    id: 'csvHeadMessages.address',
    defaultMessage: 'Address',
  },
  shiftDuration: {
    id: 'csvHeadMessages.shiftDuration',
    defaultMessage: 'Shift Duration',
  },
});

export const CalendarCsvHeaders = [
  {
    title: tableHeadMessages.owner,
    key: 'owner',
    type: 'user',
  },
  {
    title: tableHeadMessages.type,
    key: 'name',
    type: 'enum',
  },
  {
    title: tableHeadMessages.channel,
    key: 'channel_name',
    type: 'text',
  },
  {
    title: tableHeadMessages.coverer,
    key: 'coverer',
    type: 'user',
  },
  {
    title: tableHeadMessages.startAt,
    key: 'start_at',
    type: 'time',
  },
  {
    title: tableHeadMessages.endAt,
    key: 'end_at',
    type: 'time',
  },
  {
    title: csvHeadMessages.shiftNotes,
    key: 'content',
    type: 'string',
  },
  {
    title: csvHeadMessages.shiftDuration,
    key: ['start_at', 'end_at'],
    type: 'duration',
  },
  {
    title: tableHeadMessages.tradeStatus,
    key: 'trade_status',
    type: 'enum',
  },
  {
    title: csvHeadMessages.timeZone,
    key: 'time_zone',
    type: 'time_zone',
  },
  {
    title: csvHeadMessages.timePosted,
    key: 'created_at',
    type: 'time',
  },
  {
    title: csvHeadMessages.timeCovered,
    key: 'coverer_time',
    type: 'time',
  },
  {
    title: csvHeadMessages.managerName,
    key: 'approver',
    type: 'user',
  },
  {
    title: csvHeadMessages.timeManaged,
    key: 'manager_time',
    type: 'time',
  },
  {
    title: csvHeadMessages.locationName,
    key: 'location_name',
    type: 'location_name',
  },
  {
    title: csvHeadMessages.address,
    key: 'formatted_address',
    type: 'location_address',
  },
];

export const CalendarCsvHeaders1 = [
  {
    title: tableHeadMessages.owner,
    key: 'owner',
    type: 'user',
  },
  {
    title: tableHeadMessages.type,
    key: 'name',
    type: 'enum',
  },
  {
    title: tableHeadMessages.channel,
    key: 'channel_name',
    type: 'text',
  },
  {
    title: tableHeadMessages.coverer,
    key: 'coverer',
    type: 'user',
  },
  {
    title: tableHeadMessages.startAt,
    key: 'start_at',
    type: 'time',
  },
  {
    title: tableHeadMessages.endAt,
    key: 'end_at',
    type: 'time',
  },
  {
    title: csvHeadMessages.shiftNotes,
    key: 'content',
    type: 'string',
  },
  {
    title: csvHeadMessages.shiftDuration,
    key: ['start_at', 'end_at'],
    type: 'duration',
  },
  {
    title: tableHeadMessages.tradeStatus,
    key: 'trade_status',
    type: 'enum',
  },
  {
    title: csvHeadMessages.timeZone,
    key: 'time_zone',
    type: 'time_zone',
  },
  {
    title: csvHeadMessages.timePosted,
    key: 'created_at',
    type: 'time',
  },
  {
    title: csvHeadMessages.timeCovered,
    key: 'coverer_time',
    type: 'time',
  },
  {
    title: csvHeadMessages.managerName,
    key: 'approver',
    type: 'user',
  },
  {
    title: csvHeadMessages.timeManaged,
    key: 'manager_time',
    type: 'time',
  },
  {
    title: csvHeadMessages.locationName,
    key: 'location_name',
    type: 'location_name',
  },
  {
    title: csvHeadMessages.address,
    key: 'formatted_address',
    type: 'location_address',
  },
  {
    title: tableHeadMessages.skills,
    key: 'skill_ids',
    type: 'skills',
  },
];

export const CalendarShiftTypes = defineMessages({
  vto: {
    id: 'Calendar.vto',
    defaultMessage: 'VTO',
  },
  teamShift: {
    id: 'Calendar.teamShift',
    defaultMessage: 'Team Shift',
  },
  openShift: {
    id: 'Calendar.openShift',
    defaultMessage: 'Open Shift',
  },
});

const calendarFilterMessages = {
  approved: {
    id: 'FilterContainer.approved',
    defaultMessage: 'Approved',
  },
  denied: {
    id: 'FilterContainer.denied',
    defaultMessage: 'Denied',
  },
  posted: {
    id: 'FilterContainer.posted',
    defaultMessage: 'Posted',
  },
  pending: {
    id: 'FilterContainer.pending',
    defaultMessage: 'Pending',
  },
  covered: {
    id: 'FilterContainer.covered',
    defaultMessage: 'Covered',
  },
};

export const closedLocationCalendarFilters = [
  {
    label: calendarFilterMessages.approved,
    value: 'approved',
    checked: false,
  },
  {
    label: calendarFilterMessages.denied,
    value: 'rejected',
    checked: false,
  },
  {
    label: calendarFilterMessages.posted,
    value: 'posted',
    checked: false,
  },
  {
    label: calendarFilterMessages.pending,
    value: 'pending',
    checked: false,
  },
];

export const openLocationCalendarFilters = [
  {
    label: calendarFilterMessages.covered,
    value: 'covered',
    checked: false,
  },
  {
    label: calendarFilterMessages.posted,
    value: 'posted',
    checked: false,
  },
];
