import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import UserRow from './UserRow';
import UserRowShimmer from './UserRowShimmer';
import './UserTable.scss';
import styled from './styled';

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

class UserTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: true,
      filesToUpload: [],
      upload: false,
      selectedAllUsers: false,
      actionDoneOrCancelled: true,
      scrollDetected: false,
      rowMenuOpened: false,
    };


    this._isMounted = false;
    this.srolled = false;
    this.bodyRef = React.createRef();
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('scroll', this.handleScrolled, true);

  }

  componentDidUpdate(prevProps, prevState) {
    const { filteredUsers, selectedUsers,
      notifyActionDone, } = this.props;

    if (prevProps.selectedUsers !== selectedUsers) {
      if (selectedUsers && selectedUsers.length === 0) {
        if (this.tableRef && this.tableRef.current) {
          const domNode = ReactDOM.findDOMNode(this.tableRef.current);
          const output = domNode.getBoundingClientRect();
          if (output) {
            notifyActionDone(output);
          }
        }
        this.setState({ selectedAllUsers: false });
        this.setState({ actionDoneOrCancelled: true });
      } else if (selectedUsers && selectedUsers.length > 0) {
        this.setState({ actionDoneOrCancelled: false });
      }
    }

    if (prevProps.filteredUsers !== filteredUsers) {
      if (filteredUsers.length === 0) {
        this.setState({ selectedAllUsers: false });
      }
      this.setState({ rowMenuOpened: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.handleScrolled);
  }

  handleSelectAll = (event) => {
    const { notifyAllUserSelected, } = this.props;
    const { selectedAllUsers, } = this.state;

    this.setState({ selectedAllUsers: !selectedAllUsers });
    notifyAllUserSelected(!selectedAllUsers);
  }


  handleScrolled = (event) => {
    if (!this.scrolled) {
      this.scrolled = true;
    }
  }

  notifyMenuOpened = (opened) => {
    this.setState({ rowMenuOpened: opened });
  }

  createHeaders = () => {
    const { selectedAllUsers, } = this.state;
    const { selectedIndex, selectedType, filteredUsers, } = this.props;

    const selectBox = <input type="checkbox" checked={selectedAllUsers} onChange={this.handleSelectAll} />
    const name = (<FormattedMessage id="UserTable.name" defaultMessage="Name" />);
    const employeeId = (<FormattedMessage id="UserTable.employeeId" defaultMessage="Employee ID" />);
    const userRole = (<FormattedMessage id="UserTable.userRole" defaultMessage="User Role" />);
    const status = (<FormattedMessage id="UserTable.status" defaultMessage="Status" />);
    const departments = (<FormattedMessage id="UserTable.departments" defaultMessage="Departments" />);
    const group = (<FormattedMessage id="UserTable.group" defaultMessage="Group" />);
    const skills = (<FormattedMessage id="UserTable.skills" defaultMessage="Skills" />);
    const contact = (<FormattedMessage id="UserTable.contact" defaultMessage="Contact" />);
    const contact1 = (<FormattedMessage id="UserTable.contact1" defaultMessage="Contact" />);
    const placeholder = '';

    let headerItems = [selectBox, name, employeeId, userRole, status, departments, group, skills, contact1, placeholder];
    if (selectedType === 'LOCATION' && selectedIndex === 2) {
      headerItems = ['', name, employeeId, userRole, status, departments, group, skills, contact1, placeholder];
    }

    const Headers = () => {
      const headers = headerItems.map((item, index) => {
        return (
          <th key={index} className="table__header-title">
            {item}
          </th>
        );
      });
      return headers;
    };

    return (
      <thead>
        <tr className="table__headers">
          <Headers />
        </tr>
      </thead>
    );
  };



  render() {
    const { loading, locationId, departments, skills, channels, channelData,
      filteredUsers, selectedDeptId, selectedType, selectedIndex, selectedItem, selectedSkillId,
      notifyUserSelected, selectedUsers, lastAction, processedUsers, handleGetUserInformation,
      notifyActionRequested, locationSettings, popupOpened, processingUserId, handleShowSMSInviteModal, handleEmployeeIDClick } = this.props;
    const { selectedAllUsers, actionDoneOrCancelled, rowMenuOpened, } = this.state;

    const noResults = (<FormattedMessage
      id="UserTable.noResults"
      defaultMessage="No Results" />);

    const winH = `${(window.innerHeight - 380)}px`;
    const bodyClassName = 'table__body';

    // console.log('Filtered: ', filteredUsers.length);

    if (loading) {
      return (
        <styled.TableWrap height={winH} ref={this.tableRef}>
          <table className="table">
            {this.createHeaders()}
            <tbody className={bodyClassName} ref={this.bodyRef} onScroll={this.handleScrolled}>
              <UserRowShimmer />
              <UserRowShimmer />
              <UserRowShimmer />
              <UserRowShimmer />
            </tbody>
          </table>
        </styled.TableWrap>
      );
    }

    if (filteredUsers === null || filteredUsers.length === 0) {
      return (
        <styled.TableWrap height={winH} ref={this.tableRef}>
          <table className={`table ${loading ? 'loading' : ''}`}>
            {this.createHeaders()}
          </table>
          <div className="no-results__wrap">
            <img className="no-results__icon" src="/icons/search-icon.svg" />
            <label className="no-results__text">{noResults}</label>
          </div>
        </styled.TableWrap>
      );
    }
    const userRows = filteredUsers.map((user, index) => (
      <UserRow
        //key={user.owner_id} 
        key={index}
        user={user}
        departments={departments}
        skills={skills}
        channels={channels}
        locationId={locationId}
        channelData={channelData}
        selectedDeptId={selectedDeptId}
        selectedType={selectedType}
        selectedIndex={selectedIndex}
        selectedItem={selectedItem}
        selectedSkillId={selectedSkillId}
        selectedUsers={selectedUsers}
        selectedAllUsers={selectedAllUsers}
        notifyUserSelected={notifyUserSelected}
        lastAction={lastAction}
        processedUsers={processedUsers}
        actionDoneOrCancelled={actionDoneOrCancelled}
        handleGetUserInformation={handleGetUserInformation}
        filteredUsers={filteredUsers}
        notifyActionRequested={notifyActionRequested}
        notifyMenuOpened={this.notifyMenuOpened}
        rowMenuOpened={rowMenuOpened}
        locationSettings={locationSettings}
        popupOpened={popupOpened}
        processingUserId={processingUserId}
        handleShowSMSInviteModal={handleShowSMSInviteModal}
        handleEmployeeIDClick={handleEmployeeIDClick}
      />
    ));

    return (
      <styled.TableWrap height={winH} ref={this.tableRef}>
        {/*<LoadingLayer loading={loading} />*/}
        <table className={`table ${loading ? 'loading' : ''}`}>
          {this.createHeaders()}
          <tbody className={bodyClassName} ref={this.bodyRef} onScroll={this.handleScrolled}>
            {userRows}
          </tbody>
        </table>
      </styled.TableWrap>
    );
  }
}

UserTable.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(UserTable);
