import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import constants from '../../constants';
import styled from './styled';
import './Header.scss';

class Confirm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: false,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { locationData, locations, } = this.props;

    this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCancel = (event) => {
    const { notifyConfirm, } = this.props;
    notifyConfirm(false);
  }

  handleConfirm = (event) => {
    const { notifyConfirm, } = this.props;
    notifyConfirm(true);
  }

  render() {
    const { selectedAction, selectedUsers, } = this.props;
    const { userActionTypes } = constants;

    let w = 400;
    let h = 205;
    let winTop = `${(window.innerHeight - h) / 2}px`;
    let winLeft = `${(window.innerWidth - w) / 2}px`;
    let winH = `${h}px`;
    let winW = `${w}px`;

    const nonAdmins = selectedUsers ? selectedUsers.filter((user) => user.is_admin === false) : [];
    const admins = selectedUsers ? selectedUsers.filter((user) => user.is_admin === true) : [];

    const grantText = (<FormattedMessage
      id="Confirm.grant"
      defaultMessage="{number, plural, one {{number} user selected will be granted admin privileges} other {{number} users selected will be granted admin privileges}}"
      values={{ number: nonAdmins.length }} />);
    const grantAlert = (<FormattedMessage
      id="Confirm.grantAlert"
      defaultMessage="{number, plural, one {{number} user selected is already admin} other {{number} users selected are already admins}}"
      values={{ number: admins.length }} />);


    const withdrawText = (<FormattedMessage
      id="Confirm.withdraw"
      defaultMessage="{number, plural, one {{number} user selected will have admin privileges withdrawn} other {{number} users will have admin privileges withdrawn}}"
      values={{ number: admins.length }} />);

    const withdrawAlert = (<FormattedMessage
      id="Confirm.withdrawAlert"
      defaultMessage="{number, plural, one {{number} user selected does not have admin privileges} other {{number} users selected do not have admin privileges}}"
      values={{ number: nonAdmins.length }} />);

    const removeText = (<FormattedMessage
      id="Confirm.remove"
      defaultMessage="{number, plural, one {{number} user selected will be removed from this location} other {{number} users selected will be removed from this location}}"
      values={{ number: selectedUsers.length }} />);

    const removeAlert = (<FormattedMessage
      id="Confirm.removeAlert"
      defaultMessage="This action cannot be undone"
    />);

    const approveText = (<FormattedMessage
      id="Confirm.approve"
      defaultMessage="{number, plural, one {{number} user selected will be approved to join this location} other {{number} users selected will be approved to join this location}}"
      values={{ number: selectedUsers.length }} />);

    const denyText = (<FormattedMessage
      id="Confirm.deny"
      defaultMessage="{number, plural, one {{number} user selected will be denied to join this location} other {{number} users selected will be denied to join this location}}"
      values={{ number: selectedUsers.length }} />);

    const logoutText = (<FormattedMessage
      id="Confirm.logout"
      defaultMessage="You are about to log out. You will have to log back in to access your account" />);

    const deleteScheduleText = (<FormattedMessage
      id="Confirm.deleteSchedule"
      defaultMessage="The schedule and all shifts will be deleted. This action cannot be undone."
    />);

    const cancel = (<FormattedMessage
      id="Confirm.cancel"
      defaultMessage="Cancel"
    />);
    const confirm = (<FormattedMessage
      id="Confirm.confirm"
      defaultMessage="Confirm"
    />);
    const confirmLogout = (<FormattedMessage
      id="NavPanelComponent.profile"
      defaultMessage="Log Out"
    />);


    let topLine = '';
    let bottomLine = '';
    switch (selectedAction.name) {
      case userActionTypes.PROMOTE:
        topLine = grantText;
        bottomLine = grantAlert;
        break;
      case userActionTypes.WITHDRAW:
        topLine = withdrawText;
        bottomLine = withdrawAlert;
        break;
      case userActionTypes.REMOVE:
        topLine = removeText;
        bottomLine = removeAlert;
        break;
      case userActionTypes.APPROVE:
        topLine = approveText;
        break;
      case userActionTypes.DENY:
        topLine = denyText;
        break;
      case userActionTypes.LOGOUT:
        topLine = logoutText;
        break;
      case userActionTypes.DELETESCHEDULE:
        topLine = deleteScheduleText;
        break;
    }

    if (selectedAction.name !== userActionTypes.REMOVE) {
      if (selectedAction.name === userActionTypes.PROMOTE || selectedAction.name === userActionTypes.WITHDRAW) {
        return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm__title">{selectedAction.body}</label>
            <div className="confirm__label-wrap">
              <div className="confirm__text-wrap">
                <img className="confirm__img" src="/icons/checkmark-icon.svg" />
                <label className="confirm__text">{topLine}</label>
              </div>
              <div className="confirm__text-wrap">
                {/*<img className="confirm__img" src="/icons/exclaimation-mark-icon.svg"/>*/}
                <img className="confirm__img" src="/icons/warning.svg" />
                <label className="confirm__text">{bottomLine}</label>
              </div>
            </div>
            <div className="confirm__button-wrap">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>
            </div>
          </styled.WinContainerConfirm>
        );
      } else if (selectedAction.name === userActionTypes.APPROVE || selectedAction.name === userActionTypes.DENY) {
        return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm__title">{selectedAction.body}</label>
            <div className="confirm__label-wrap">
              <div className="confirm__text-wrap">
                {/*<img className="confirm__img" src="/icons/checkmark-icon.svg"/>*/}
                <label className="confirm__text">{topLine}</label>
              </div>
            </div>
            <div className="confirm__button-wrap">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>
            </div>
          </styled.WinContainerConfirm>
        );
      } else if (selectedAction.name === userActionTypes.LOGOUT) {
        return (
          <div className="add-location-dialog">
            <article className="location-page">
              <p className="location-page__header">
                {selectedAction.body}
              </p>

              <div>
                <p className="location-page__description">
                  {topLine}
                </p>
                <section className="location-page__form-group">

                </section>
                <div className="location-page__button-wrap">

                  <button
                    className="confirm__button-cancel"
                    onClick={this.handleCancel}
                  >
                    {cancel}
                  </button>

                  <button
                    className="confirm__button"
                    onClick={this.handleConfirm}
                  >
                    {confirmLogout}
                  </button>
                </div>
              </div>
            </article>
          </div>
        );
      } else if (selectedAction.name === userActionTypes.DELETESCHEDULE) {
        return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm__title">{selectedAction.body}</label>
            <div className="confirm__label-wrap">
              <div className="confirm__text-wrap">
                {/*<img className="confirm__img" src="/icons/checkmark-icon.svg"/>*/}
                <label className="confirm__text">{topLine}</label>
              </div>
            </div>
            <div className="confirm__button-wrap">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>
            </div>
          </styled.WinContainerConfirm>
        );
      }
    } else {
      return (
        <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
          <label className="confirm__title">{selectedAction.body}</label>
          <div className="confirm__label-wrap">
            <div className="confirm__text-wrap">
              <img className="confirm__img" src="/icons/alert-icon.svg" />
              <label className="confirm__text">{topLine}</label>
            </div>
            <div className="confirm__text-wrap">
              <img className="confirm__img" src="/icons/warning.svg" />
              <label className="confirm__text">{bottomLine}</label>
            </div>
          </div>
          <div className="confirm__button-wrap">
            <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
            <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>
          </div>
        </styled.WinContainerConfirm>
      );
    }
  }
}

Confirm.propTypes = {
  selectedItem: PropTypes.object.isRequired,
  selectedType: PropTypes.string.isRequired
};

export default injectIntl(Confirm);