import moment from 'moment';

import { DetermineSetup } from 'helpers/user-setup';
import { RequestGeofence } from 'helpers/geoLocation';
import {
  GetCurrentLocation,
  GetAccessibleLocations,
  GetDashboardAnalytics,
  LoadFirstPagePendingShifts,
  LoadManagedShifts,
  GetLocationSetupActions,
  GetPlans,
} from '../helpers/api-calls';
import { setLocationCookie, getLocationCode } from '../helpers/cookies';
import {
  lastSevenDaysDateRange,
  createApiDateFormat,
  setDefaultTimezone,
} from '../helpers/date-time';
import { getBrowserLanguage } from '../helpers/localization';

/**
 * action types
 */
export const CHANGE_LOCATION = 'CHANGE_LOCATION';
export const LOCATION_LOAD_SUCCESS = 'LOCATION_LOAD_SUCCESS';
export const LOCATION_DATA_LOADED = 'LOCATION_DATA_LOADED';
export const LOCATION_KPI_DATA_LOADED = 'LOCATION_KPI_DATA_LOADED';
export const LOCATION_KPI_DATA_LOADING = 'LOCATION_KPI_DATA_LOADING';
export const SHIFT_REQUESTS_DATA_LOADED = 'SHIFT_REQUESTS_DATA_LOADED';
export const SHIFT_REQUESTS_DATA_LOADING = 'SHIFT_REQUESTS_DATA_LOADING';
export const CHANGE_PENDING_SHIFT_STATUS = 'CHANGE_PENDING_SHIFT_STATUS';
export const MANAGED_SHIFTS_LOADING = 'MANAGED_SHIFTS_LOADING';
export const MANAGED_SHIFTS_LOADED = 'MANAGED_SHIFTS_LOADED';
export const SETUP_ACTIONS_LOADING = 'SETUP_ACTIONS_LOADING';
export const SETUP_ACTIONS_LOADED = 'SETUP_ACTIONS_LOADED';
export const SETUP_ACTIONS_NEEDED = 'SETUP_ACTIONS_NEEDED';
export const GEOFENCE_CHECK = 'GEOFENCE_CHECK';
export const GEOFENCE_SUCCESS = 'GEOFENCE_SUCCESS';
export const GEOFENCE_FAILURE = 'GEOFENCE_FAILURE';
export const LOCALE_CHANGE = 'LOCALE_CHANGE';
export const ACTIVE_PLAN = 'ACTIVE_PLAN'

/**
 * action creators
 */
export function changeLocationCode(code) {
  return { type: CHANGE_LOCATION, location_code: code };
}

export function loadLocationsSuccess(locations) {
  return { type: LOCATION_LOAD_SUCCESS, locations };
}

function loadLocationKpiData(data) {
  return { type: LOCATION_KPI_DATA_LOADED, kpis: data, loading: false };
}

function loadingDashboardData() {
  return { type: LOCATION_KPI_DATA_LOADING, kpis: {}, loading: true };
}

function shiftRequestsDataLoading() {
  return { type: SHIFT_REQUESTS_DATA_LOADING, shifts: [], loading: true };
}

function shiftRequestsDataLoaded(shifts) {
  return { type: SHIFT_REQUESTS_DATA_LOADED, shifts, loading: false };
}

function managedShiftsLoading() {
  return { type: MANAGED_SHIFTS_LOADING, shifts: [], loading: true };
}

function managedShiftsLoaded(shifts) {
  return { type: MANAGED_SHIFTS_LOADED, shifts, loading: false };
}

export function locationDataLoaded(data) {
  return { type: LOCATION_DATA_LOADED, data };
}

function changePendingShiftStatus(id) {
  return { type: CHANGE_PENDING_SHIFT_STATUS, id };
}

function updateSetupActions(setupActions) {
  return { type: SETUP_ACTIONS_LOADED, setupActions };
}

function setupActionsLoading() {
  return { type: SETUP_ACTIONS_LOADING };
}

function updateSetupNeeded(setupNeeded) {
  return { type: SETUP_ACTIONS_NEEDED, setupNeeded };
}

export function geofenceCheck(timestamp) {
  return { type: GEOFENCE_CHECK, timestamp };
}

export function geofenceSuccess() {
  return { type: GEOFENCE_SUCCESS };
}

export function geofencefailure(error) {
  return { type: GEOFENCE_FAILURE, error };
}

function changeLocale(locale) {
  return { type: LOCALE_CHANGE, locale };
}

function setPlan(plan_name) {
  return { type: ACTIVE_PLAN, plan_name };
}

export function localeChanged(locale) {
  return function(dispatch) {
    dispatch(
      changeLocale(locale === 'undefined' ? getBrowserLanguage() : locale)
    );
  };
}

export function geofenceCalculate(locationCoords, maxDistance) {
  return function(dispatch) {
    dispatch(geofenceCheck(moment.utc().format()));

    return RequestGeofence(locationCoords, maxDistance)
      .then(() => dispatch(geofenceSuccess()))
      .catch((error) => dispatch(geofencefailure(error)));
  };
}

export function loadLocations() {
  return function(dispatch) {
    return GetAccessibleLocations()
      .then((data) => {
        const location_id = getLocationCode();
        dispatch(loadLocationsSuccess(data.locations));
        if (data.locations.length > 0) {
          dispatch(
            changeLocation(
              location_id !== 'false' ? location_id : data.locations[0].id
            )
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadNewLocations(code) {
  return function(dispatch) {
    return GetAccessibleLocations().then((data) => {
      dispatch(loadLocationsSuccess(data.locations));
      if (data.locations.length > 0) {
        dispatch(changeLocation(code));
      }
    });
  };
}

export function addLocation(cb) {
  return function(dispatch) {
    return dispatch(loadNewLocations(cb));
  };
}

export function changeLocation(code) {
  setLocationCookie(code);
  return function(dispatch) {
    dispatch(changeLocationCode(code));
    return dispatch(dashboardLocationUpdate());
  };
}

export function loadDashboardData() {
  const firstSeven = lastSevenDaysDateRange(
    moment()
      .endOf('day')
      .subtract(1, 'days')
  );
  const prevSeven = lastSevenDaysDateRange(
    moment()
      .endOf('day')
      .subtract(8, 'days')
  );
  const locationId = getLocationCode();
  const start = moment();
  return function(dispatch) {
    return GetDashboardAnalytics(locationId, firstSeven, prevSeven).then(
      (data) => {
        const end = moment();
        const diff = moment.duration(end.diff(start))
        const ms = parseInt(diff.asMilliseconds());
        const msg = `loadDashboardData took ${ms} ms.`;
        //console.log(msg);
        dispatch(loadLocationKpiData(data));
      }
    );
  };
}

export function loadLocationData() {
  const start = moment();
  return function(dispatch) {
    GetCurrentLocation().then((response) => {
      const end = moment();
      const diff = moment.duration(end.diff(start))
      const ms = parseInt(diff.asMilliseconds());
      // const msg = `loadLocationData took ${ms} ms.`;
      // console.log(msg);
      setDefaultTimezone(response.data.location.time_zone);
      dispatch(locationDataLoaded(response.data.location));
    });
  };
}

export function loadShiftRequests() {
  const start = moment();
  return function(dispatch) {
    dispatch(shiftRequestsDataLoading());
    LoadAllPendingShifts().then((response) => {
      const end = moment();
      const diff = moment.duration(end.diff(start))
      const ms = parseInt(diff.asMilliseconds());
      const msg = `loadShiftRequests took ${ms} ms.`;
      //console.log(msg);
      if (response.data && response.data.schedule_elements) {
        dispatch(shiftRequestsDataLoaded(response.data.schedule_elements));
      } else {
        dispatch(shiftRequestsDataLoaded({ data: { schedule_elements: [] } }));
      }
    });
  };
}

export function loadManagedShifts() {
  const start = moment();
  const today = createApiDateFormat(moment());
  const sevenDaysAgo = createApiDateFormat(moment().subtract(7, 'days'));

  return function(dispatch) {
    LoadManagedShifts(sevenDaysAgo, today).then((data) => {
      const end = moment();
      const diff = moment.duration(end.diff(start))
      const ms = parseInt(diff.asMilliseconds());
      const msg = `loadManagedShifts took ${ms} ms.`;
      //console.log(msg);
      dispatch(managedShiftsLoaded(data.data.schedule_elements));
    });
  };
}

export function dashboardLocationUpdate() {
  return function(dispatch) {
    dispatch(loadLocationData());
  };
}

export function loadSetupActions(location_id) {
  return function(dispatch) {
    dispatch(setupActionsLoading());

    GetLocationSetupActions(location_id).then((response) => {
      // connect to redux
      return dispatch(updateSetupActions(response.data));
    });
  };
}

export function setSetupNeeded(setupActions) {
  const setupNeeded = DetermineSetup(setupActions);

  return (dispatch) => dispatch(updateSetupNeeded(setupNeeded));
}

export function getPlans() {
  const start = moment();
  return function(dispatch) {
    GetPlan().then((response) => {
      console.log(response);
    });
  };
}
