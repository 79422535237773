import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { SETUP_ACTIONS } from 'helpers/user-setup';

import RouteComponent from 'components/GeneralComponents/RouteComponent';
import { SingleSignOnBoxComponent } from 'components/SingleSignOnComponents';
import { AdminClaimContainer } from 'components/GeneralComponents/AdminClaimComponent';
import { PendingLocation } from 'components/GeneralComponents/PendingLocation';
import { includes } from 'helpers/validation';
import IpWhitelistComponent from './IpWhitelistComponent/IpWhitelistComponent';

const SetupComponent = ({
  setupNeeded,
  setupActions,
  routeProps,
  locationSettings,
}) => {
  // Checks setupNeeded and returns necessary setup component
  /**
   * The order here is very important -
   * 1.) No locations - a user can't do anything on the platform if
   *     they don't belong to a location
   * 2.) SSO - This is needed to determine if a user is actually logged
   *     into a restrictive location
   * 3.) Geofence - this can only be determined if the user actually belongs
   *     to a location that requires SSO
   */

  if (includes(setupNeeded, SETUP_ACTIONS.NO_LOCATIONS.key)) {
    return <Redirect to="/add-location" />;
  }

  if (includes(setupNeeded, SETUP_ACTIONS.PENDING_LOCATION.key)) {
    // return pending location component
    return <PendingLocation />;
  }

  if (includes(setupNeeded, SETUP_ACTIONS.ADMIN_CLAIM.key)) {
    // return admin claim component
    return <AdminClaimContainer config={setupActions.meta} />;
  }

  if (includes(setupNeeded, SETUP_ACTIONS.SSO.key)) {
    return (
      <SingleSignOnBoxComponent setupActions={setupActions.setup_actions} />
    );
  }

  if (includes(setupNeeded, SETUP_ACTIONS.IP_BLOCKED.key)) {
    return <IpWhitelistComponent routeProps={routeProps} />;
  }
  /*
  if (!locationSettings.cfg__group_chat && routeProps.path === '/team-feed') {
    return <Redirect to="/" />;
  }
  */
  if(locationSettings.cfg__group_chat !== undefined) {
    if (!locationSettings.cfg__group_chat && routeProps.path === '/team-feed') {
      return <Redirect to="/" />;
    }
  }

  if(locationSettings.cfg__schedule_upload !== undefined) {
    if (!locationSettings.cfg__schedule_upload && routeProps.path === '/scheduler') {
      return <Redirect to="/" />;
    }
  }

  if(locationSettings.cfg__schedule_upload !== undefined) {
    if (!locationSettings.cfg__schedule_upload && routeProps.path === '/schedulecalendar') {
      return <Redirect to="/" />;
    }
  }

  // Default return Route component
  return <RouteComponent routeProps={routeProps} />;
};

export default SetupComponent;

SetupComponent.propTypes = {
  setSetupNeeded: PropTypes.func.isRequired,
  setupActions: PropTypes.shape({}).isRequired,
  setupNeeded: PropTypes.array.isRequired,
  locationSettings: PropTypes.shape({}).isRequired,
  routeProps: PropTypes.shape({
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.elementType,
  }).isRequired,
};
