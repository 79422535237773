import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@shyft/acorns';
import PubNub from 'pubnub';
import moment from 'moment';

import ManagementFeedContainer from '../../components/DashboardComponents/RequestsSection/ManagementActionFeed/ManagementFeedContainer';
import PendingUsersContainer from '../../components/DashboardComponents/RequestsSection/PendingUsersSection/PendingUsersContainer';
import RecentRequests from './components/RecentRequestsContainer';
import UpgradePrompt from '../../components/GeneralComponents/UpgradePrompt/UpgradePrompt';
import Modal from 'components/GeneralComponents/Modal';

import { getUserId } from '../../helpers/cookies';
import { CustomText } from '../../helpers/formatting';

import styled from './styled';
import BottomSheet from 'pages/Team/components/BottomSheet';
import styledMacro from 'styled-components/macro';
import './DashboardPage.scss';
import { GetCostCenterData } from '../../helpers/api-calls/location-calls';
import { PUBNUB_SUBSCRIBE_KEY } from '../../constants';
import styledConfirm from './components/styled';

import { RTOS_PER_PAGE, } from './constants';

import { Mixpanel } from '../../Mixpanel';

const sha1 = require('js-sha1');

const ActionBtn = styledMacro(Button).attrs(({ modifiers }) => ({
  modifiers: ['small'].concat(modifiers),
}))`
  margin-left: 1.25rem;
`;

var checkCookie = function () {

  var lastCookie = document.cookie; // 'static' memory between function calls

  return function () {

    var currentCookie = document.cookie;

    if (currentCookie != lastCookie) {
      // something useful like parse cookie, run a callback fn, etc.
      if (currentCookie === "") {
        const backToPath = "/login";
        window.location.pathname = backToPath;
      }
      lastCookie = currentCookie; // store latest cookie
    }
  };
}();


class DashboardComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pubnub = props.pubnub;
    this.pubnub.setUUID(`user.${sha1(`user.${getUserId()}`)}`);
    this.sha1 = `user_event.${sha1(`user.${getUserId()}`)}`;

    this.state = {
      confirmationSheet: false,
      confirmationShift: undefined,
      confirmationConfigId: undefined,
      deletedGroupId: null,
      trigger: false,
      planName: '',
    };

    this.rtoPageIndex = 1;
    this.prevPageIndex = 1;
    this.apiCalled = false;
  }

  componentDidMount() {
    const { locationId, getCostCenterData,
      getRequestTimeOffs, getLocationEmployees,
      getLocationUsableSkills, getPendingUsers,
      getLocationInnerDepartments, departments,
      getLocationMyDepartments, myDepartments,
      getLocationEmployeesNew, resetTotalRTOs,
      locationData, plans } = this.props;

    //Ensure componentDidUpdate called at lease once
    this.setState({ trigger: true });

    this.rtoPageIndex = 1;
    resetTotalRTOs();

    window.setInterval(checkCookie, 100); // run every 100 ms

    if (plans && plans.length > 0) {
      if (locationData && locationData.plan_id) {
        const cplan = plans.find(plan => plan.id === locationData.plan_id);
        this.setState({ planName: cplan.name });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { locationId, getCostCenterData, locationConfig,
      getRequestTimeOffs, getLocationEmployees,
      getLocationUsableSkills, getPendingUsers,
      getLocationInnerDepartments, departments,
      getLocationMyDepartments, myDepartments,
      getLocationEmployeesNew, totalRTOs, getLocationOnlineUsers, locationData, plans, } = this.props;

    if (!this.apiCalled) {
      if (locationId) {
        getCostCenterData(locationId);
        getLocationEmployeesNew(locationId);
        getLocationUsableSkills(locationId);
        // getPendingUsers(locationId);
        getLocationInnerDepartments(locationId);
        getLocationMyDepartments(locationId);
        getRequestTimeOffs(locationId, this.rtoPageIndex, RTOS_PER_PAGE);
        this.prevPageIndex = this.rtoPageIndex;
        this.rtoPageIndex += 1;

        this.pubnubSubscribe();
      }
      this.apiCalled = true;
    }

    if (prevProps.totalRTOs !== totalRTOs) {
      // console.log(`page: ${this.rtoPageIndex - 1}, rto: ${totalRTOs.length}`);
      if (prevProps.totalRTOs) {
        if (totalRTOs.length - prevProps.totalRTOs.length === RTOS_PER_PAGE) {
          getRequestTimeOffs(locationId, this.rtoPageIndex, RTOS_PER_PAGE);
          this.prevPageIndex = this.rtoPageIndex;
          this.rtoPageIndex += 1;
        }
      }
    }

    if (prevProps.locationData !== locationData) {
      if (plans && plans.length > 0) {
        if (locationData && locationData.plan_id) {
          const cplan = plans.find(plan => plan.id === locationData.plan_id);
          this.setState({ planName: cplan.name });
        }
      }
    }
  }

  componentWillUnmount() {
    this.pubnub.unsubscribe({
      channels: [this.sha1, this.sha1Presence],
    });
    // console.log('Dash: pubnub unsubscribed');
  }

  pubnubSubscribe = () => {
    const { handleShiftUpdate, handleAddUserToOnlineList, handleRemoveUserFromOnlineList, } = this.props;

    this.pubnub.addListener({
      status: (event) => {
        if (event.category === "PNConnectedCategory") {
          console.log("Connected");
        }
      },
      message: (event) => {
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'shift' &&
          event.message.crud === 'UPDATE'
        ) {
          handleShiftUpdate(event.message.node_id);
        }
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'channel' &&
          event.message.crud === 'DELETE'
        ) {
          const msg = `Dash page: Group (channel) ${event.message.tree_id} has been deleted.`;
          console.log(msg);
          this.setState({ deletedGroupId: event.message.tree_id });
        }
      },

    });

    this.pubnub.subscribe({
      channels: [this.sha1]
    });
  };

  approveShiftClick = () => {
    const { approvePendingShift } = this.props;
    const { confirmationShift, confirmationConfigId } = this.state;
    approvePendingShift(confirmationShift, confirmationConfigId)
    this.toggleConfirmationSheet(undefined, undefined);
  }

  toggleConfirmationSheet = (shift, configId) => {
    this.setState((prevState) => ({
      confirmationSheet: !prevState.confirmationSheet,
      confirmationShift: shift,
      confirmationConfigId: configId
    }));
  };

  notifyRefreshRequested = () => {

    const { locationId, getRequestTimeOffs } = this.props;
    this.rtoPageIndex = 1;
    getRequestTimeOffs(locationId, this.rtoPageIndex, RTOS_PER_PAGE);
  }

  render() {
    const { confirmationSheet, confirmationShift, confirmationConfigId, deletedGroupId, planName, } = this.state;
    const { locationConfig, allowScheduleUpload } = this.props;

    const customText = new CustomText(locationConfig);
    return (
      <article>
        <RecentRequests
          notifyRefreshRequested={this.notifyRefreshRequested}
          toggleConfirmationSheet={this.toggleConfirmationSheet}
          deletedGroupId={deletedGroupId}
        />

        {planName === 'Basic' && <Modal>
          <UpgradePrompt />
        </Modal>}
        <styledConfirm.Overlay display={planName === 'Basic' ? 'block' : 'none'} />
      </article>
    );
  }
}

DashboardComponent.propTypes = {
  handleShiftUpdate: PropTypes.func.isRequired,
  approvePendingShift: PropTypes.func.isRequired,
  locationConfig: PropTypes.shape({}),
  pubnub: PropTypes.object.isRequired,
};

DashboardComponent.defaultProps = {
  locationConfig: {},
};

export default DashboardComponent;
