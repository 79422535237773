import React from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../components/GeneralComponents/RegistrationDesign.scss'
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

class RegistrationProfilePage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            isCreateLocationSelected: true,
            isSubmitDisabled: true,
        };
    }


    componentDidMount() {
        const { location } = this.props;

        if (location && location.state) {
            this.setState({
                isCreateLocationSelected: location.state.isCreateLocationSelected,
            });
        } else {
            // If API is not loaded, redirect to Registration1
            this.props.history.push('/');
            return null;
        }

    }

    handleInputChange = (e) => {
        const name = e.target.value;
        const isValid = /^[a-zA-Z]+(?:[-'][a-zA-Z]+)*(?:\s+[a-zA-Z]+(?:[-'][a-zA-Z]+)*)*[-']?\s*$/.test(name.trimStart());

        if (isValid) {
            this.setState({ userName: name, isSubmitDisabled: false });
        } else {
            this.setState({ userName: name, isSubmitDisabled: true });
        }
    };

    handleNext = () => {
        const { userName, isSubmitDisabled, isCreateLocationSelected } = this.state;
        if (userName && !isSubmitDisabled) {
            this.props.history.push({
                pathname: '/sign-up/location',
                state: {
                    userName,
                    isCreateLocationSelected
                },
            });
        }
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleNext();
        }
    };

    render() {
        const { isSubmitDisabled } = this.state;

        return (
            <RegistrationLayout>
                <div className="form-content">
                    <div className="welcome-text-wrapper">
                        <div className="dot"></div>
                        <div className="welcome-text">Welcome to Shyft</div>
                    </div>
                    <div className="main-heading">Get Started Creating<br /> Schedules.</div>
                    <div className="name-prompt">What's your name?</div>
                    <input
                        type="text"
                        placeholder="Name"
                        className="name-input"
                        onChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                    />
                    <button className="next-btn" onClick={this.handleNext} disabled={isSubmitDisabled}>Next</button>
                    <div className="login-message">
                        Already have an account?{' '}
                        <Link className="login-message-link" to="/login">
                            Login
                        </Link>

                    </div>
                </div>
            </RegistrationLayout>
        );
    }
}

const RegistrationProfilePagei18n = injectIntl(RegistrationProfilePage);

export default withRouter(RegistrationProfilePagei18n);