import React from 'react';
import PropTypes from 'prop-types';

import TableRow from './TableRow/TableRow';
import AbsentDataComponent from '../AbsentData/AbsentDataComponent';
import { FormattedMessage } from 'react-intl';
import { getUserInfoNonAdmin } from 'helpers/api-calls/user-calls';
import './TableComponent.scss';
import UserTable from 'pages/RequestsV3/components/UserTable/UserTable';
import constants from 'pages/RequestsV3/constants';
import Summary from 'pages/RequestsV3/components/Summary/Summary';
import Header from 'pages/RequestsV3/components/Header/Header';
import filterUtil from 'pages/RequestsV3/duck/filter';
import utils from 'pages/RequestsV3/duck/utils';
import { cloneDeep } from 'lodash';

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

class TableComponentV3 extends React.Component {
  constructor(props) {
    super(props);
    const { categoryTypes } = constants;
    this.state = {
      headers: props.headers ? props.headers : [],
      rows: [],
      totalRows: [],
      locationId: 0,

      selectedType: categoryTypes.REQUESTS,
      selectedIndex: 0,
      selectedItem: null,
      selectedSort: null,
      selectedShifts: [],
      searchTerm: '',
      filteredResults: [],
    };
  }

  componentDidMount() {
    const { locationSettings, skills, rows, headers, stateFilters, updateRowData, deletedGroupId, } = this.props;
    const output = this.getTotalRows(rows);
    this.setState({ totalRows: output, filteredResults: output });
    this.setState({ rows: this.createRowData(output, headers) });
    updateRowData(output);
    if (rows && rows.length > 0) {
      this.setState({ locationId: rows[0].location_id });
      const msg = `TableComponent.componentDidMount: shiftId = ${rows[0].id}, location_id = ${rows[0].location_id}`;
      console.log(msg);
    }
  }

  componentDidUpdate(prevProps) {
    const { locationSettings, headers, rows, skills, stateFilters, updateRowData, deletedGroupId, } = this.props;
    const { totalRows, } = this.state;

    if (prevProps.headers !== headers) {
      this.setState({ headers: headers });
    }
    if (prevProps.rows !== rows) {
      if (rows && rows.length > 0) {
        this.setState({ locationId: rows[0].location_id });
      }
      if (headers) {
        //this.setState({rows: this.createRowData(rows, headers),})
        const output = this.getTotalRows(rows);
        this.setState({ totalRows: output, filteredResults: output });
        this.setState({ rows: this.createRowData(output, headers) });
        updateRowData(output);
      }
    }
    if (prevProps.stateFilters !== stateFilters) {
      const output = this.getTotalRows(rows);
      this.setState({ totalRows: output, filteredResults: output });
      this.setState({ rows: this.createRowData(output, headers) });
      updateRowData(output);
    }
    if (prevProps.deletedGroupId !== deletedGroupId) {
      let localCopy = [...rows];
      if (localCopy && localCopy.length > 0) {
        for (let i = 0; i < localCopy.length; i++) {
          if (localCopy[i].channel_id === deletedGroupId) {
            localCopy.splice(i, 1);
            i -= 1;
          }
        }
        const output1 = this.getTotalRows(localCopy);
        this.setState({ totalRows: output1, filteredResults: output1 });
        this.setState({ rows: this.createRowData(output1, headers) });
        updateRowData(output1);
      }
    }
  }

  getTotalRows = (rows) => {
    const { stateFilters, } = this.props;
    let totalRows = [];
    let c0 = 0;
    let c1 = 0;
    let c2 = 0;
    let c3 = 0;
    if (rows && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].cover_mode === 'default') {
          totalRows.push(rows[i]);
          c0 += 1;
        } else {
          if (rows[i].cover_mode === 'multi_applicants_picked_by_manager') {
            //No applicant yet, show parent shift as 'Posted'
            if (rows[i].current_applicants_pending_user_ids.length === 0 &&
              rows[i].current_applicants_denied_user_ids.length === 0 &&
              rows[i].current_applicants_approved_user_ids.length === 0 &&
              rows[i].current_applicants_nominated_user_ids.length === 0) {
              if (stateFilters && stateFilters.length > 0) {
                if (stateFilters.includes('posted')) {
                  totalRows.push(rows[i]);
                  c2 += 1;
                }
              } else {
                totalRows.push(rows[i]);
                c2 += 1;
              }
              //totalRows.push(rows[i]);
            }
            //for team shift need to do this, not for open shift because a child card created for each approved applicant
            if (rows[i].current_applicants_approved_user_ids.length === 1) {
              if (rows[i].name === 'shift') {
                if (stateFilters && stateFilters.length > 0) {
                  if (stateFilters.includes('approved')) {
                    totalRows.push(rows[i]);
                    c2 += 1;
                  }
                } else {
                  totalRows.push(rows[i]);
                  c2 += 1;
                }
              }
            }
            //removed condition (stateFilters.includes('pending') || stateFilters.includes('posted')
            if (rows[i].current_applicants_pending_user_ids.length > 0) {
              for (let j = 0; j < rows[i].current_applicants_pending_user_ids.length; j++) {
                let copyRow = { ...rows[i] }
                if (copyRow.coverer === null) {
                  const userId = copyRow.current_applicants_pending_user_ids[j];
                  const applicantId = copyRow.current_applicants_pending_ids[j];
                  let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                  coverer.applicantId = applicantId;
                  coverer.last_name = '#p'; //use this variable to pass applicant status
                  copyRow.coverer = coverer;
                } else {
                  if (rows[i].name === 'shift') {
                    const userId = copyRow.current_applicants_pending_user_ids[j];
                    const applicantId = copyRow.current_applicants_pending_ids[j];
                    let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                    coverer.applicantId = applicantId;
                    coverer.last_name = '#p'; //use this variable to pass applicant status
                    copyRow.coverer = coverer;
                  }
                }
                if (stateFilters && stateFilters.length > 0) {
                  if (stateFilters.includes('pending')) {
                    totalRows.push(copyRow);
                    c2 += 1;
                  }
                } else {
                  totalRows.push(copyRow);
                  c2 += 1;
                }
              }
            }
            //removed condition  stateFilters.includes('rejected')
            if (rows[i].current_applicants_denied_user_ids.length > 0) {
              for (let j = 0; j < rows[i].current_applicants_denied_user_ids.length; j++) {
                let copyRow = { ...rows[i] }
                if (copyRow.coverer === null) {
                  const userId = copyRow.current_applicants_denied_user_ids[j];
                  const applicantId = copyRow.current_applicants_denied_ids[j];
                  let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                  coverer.applicantId = applicantId;
                  coverer.last_name = '#d'; //use this variable to pass applicant status
                  copyRow.coverer = coverer;
                } else {
                  if (rows[i].name === 'shift') {
                    const userId = copyRow.current_applicants_denied_user_ids[j];
                    const applicantId = copyRow.current_applicants_denied_ids[j];
                    let coverer = { id: 0, first_name: '', last_name: '', profile_image: {}, applicantId: 0 };
                    coverer.applicantId = applicantId;
                    coverer.last_name = '#d'; //use this variable to pass applicant status
                    copyRow.coverer = coverer;
                  }
                }
                if (stateFilters && stateFilters.length > 0) {
                  if (stateFilters.includes('rejected')) {
                    totalRows.push(copyRow);
                    c2 += 1;
                  }
                } else {
                  totalRows.push(copyRow);
                  c2 += 1;
                }
              }
            }
          }
        }
      }
    }
    return totalRows;
  }

  createRowData = (shifts, headers) => {
    const { stateFilters, } = this.props;

    if (shifts && shifts.length > 0 && headers && headers.length > 0) {
      return shifts.map((shift, index) => {
        return headers.map((header) => {
          if (Array.isArray(header.key)) {
            return header.key.map((key) => {
              return shift[key];
            });
          } else {
            return shift[header.key];
          }
        });
      });
    } else {
      return [];
    }
  };

  createRows = (rowDatas, headers) => {
    const { skills, stateFilters, } = this.props;
    const { locationId, } = this.state;


    if (rowDatas.length > 0) {
      return rowDatas.map((element, index) => {
        return <TableRow
          key={index}
          rowData={element}
          headers={headers}
          skills={skills}
          locationId={locationId}
          stateFilters={stateFilters}
        />;
      });
    } else {
      return [];
    }
  };

  checkForAbsentData = () => {
    if (!this.props.loading && this.state.rows.length === 0) {
      return (
        //<AbsentDataComponent message="There is no data available for the conditions set." />
        <AbsentDataComponent message=<FormattedMessage
          id="CalendarTable.noData"
          defaultMessage="There is no data available for the condition set." />
        />
      );
    }
  };

  createHeaders = () => {
    if (!this.props.headers) {
      return;
    }
    const Headers = () => {
      const headers = this.props.headers.map((element, index) => {
        return (
          <th key={index} className="shyft-table--header-title">
            {element.title}
          </th>
        );
      });
      return headers;
    };

    return (
      <thead>
        <tr className="shyft-table--headers">
          <Headers />
        </tr>
      </thead>
    );
  };

  findInFilters = (status, filters) => {
    return filters.findIndex((element) => {
      return status == element;
    });
  };

  createCategoryItems = () => {
    const { totalRows } = this.state;

    // Initialize counts for different categories
    let totalCount = 0;
    let openShiftsCount = 0;
    let teamShiftsCount = 0;
    let vtoShiftsCount = 0;

    // Go through each item to count categories
    totalRows.forEach(item => {
      if (item) {
        totalCount++; // Count total items

        if (item.time_off_reason === undefined) { // It's a shift
          if (item.name === 'open_shift') {
            openShiftsCount++; // Count open shifts
          } else if (item.name === 'shift') {
            teamShiftsCount++; // Count team shifts
          } else if (item.name === 'shift_vto') {
            vtoShiftsCount++; // Count voluntary time off
          }
        }
      }
    });

    // Prepare the output items
    const requestItems = [
      { name: <FormattedMessage id="Requests.all" defaultMessage="All" />, value: totalCount },
      { name: <FormattedMessage id="Requests.openShifts" defaultMessage="Open Shifts" />, value: openShiftsCount },
      { name: <FormattedMessage id="Requests.teamShifts" defaultMessage="Team Shifts" />, value: teamShiftsCount },
      { name: <FormattedMessage id="Requests.vtos" defaultMessage="Voluntary Time Off" />, value: vtoShiftsCount },
    ];

    return [{ id: 'requests', items: requestItems }];
  }

  categorySelected = (selectedType) => {
    const { selectedItem, categoryItems, selectedIndex, } = this.state;
    const { categoryTypes } = constants;
    console.log('Category Selected');
    this.setState({ selectedType: selectedType, selectedShifts: [] });
    switch (selectedType) {
      case categoryTypes.REQUESTS:
        if (categoryItems && categoryItems.length > 0) {
          this.setState({ selectedItem: categoryItems[0] });
          const output = this.filterShifts(selectedType, categoryItems[0], selectedIndex);
          this.setState({ filteredResults: output });
        }
        break;
    }
  };

  createSummaryElements = (categoryItems) => {
    const { managers, associates, removedUsers, pendingUsers, departments, channels, skills, } = this.props;
    const { selectedType, } = this.state;
    const { categoryTypes } = constants;
    if (selectedType === categoryTypes.REQUESTS) {
      const idxL = categoryItems.findIndex((el) => el.id === 'requests');
      if (idxL >= 0) {
        return categoryItems[idxL].items;
      }
    }
  };

  summaryItemSelected = (index, item) => {
    const { locationId, managers, associates, removedUsers, pendingUsers, departments, skills, } = this.props;
    const { selectedType, } = this.state;
    const { categoryTypes } = constants;

    this.setState({ selectedItem: item, selectedIndex: index });
    this.setState({ selectedShifts: [] });
    const output = this.filterShifts(selectedType, item, index);

    this.setState({ filteredResults: output });
  };

  notifySortTypeChanged = (type) => {
    this.setState({ selectedSort: type })
  }

  notifySearchTermChanged = (searchTerm) => {
    this.setState({ searchTerm: searchTerm });
  }

  notifyDropDownOpened = (opened) => {
    this.setState({ popupOpened: opened });
  }

  notifyFiltersSelected = (dept, status, email, employeeId, group, jobTitle, skill, weekday) => {
    this.setState({ filterByDepartment: dept, filterByStatus: status, filterByGroup: group, filterByJobTitle: jobTitle, filterByEmail: email, filterByEmployeeId: employeeId, filterBySkill: skill, filterByWeekday: weekday });
  }

  filterShifts = (type, item, index) => {
    const { categoryTypes } = constants;
    const { totalRows } = this.state;

    // Determine the total shifts based on the type
    let total = (type === categoryTypes.REQUESTS) ? cloneDeep(totalRows) : [];

    if (!type) {
      return combinedShiftsAndRTOs;
    }

    // Initialize filtered shifts
    let filtered = [];

    // Use a single loop to filter based on type and index
    total.forEach(idxObject => {
      if (idxObject) {
        const isShift = idxObject.time_off_reason === undefined;
        if (type === categoryTypes.REQUESTS) {
          if (index === 0) {
            filtered.push(idxObject); // All shifts
          } else if (index === 1 && isShift && idxObject.name === 'open_shift') {
            filtered.push(idxObject); // Open shifts
          } else if (index === 2 && isShift && (idxObject.name === 'shift' || idxObject.name === 'shift_inventory')) {
            filtered.push(idxObject); // Team shifts
          } else if (index === 3 && isShift && idxObject.name === 'shift_vto') {
            filtered.push(idxObject); // Voluntary time off
          }
        }
      }
    });

    console.log('Filtered: filterShifts', filtered);
    return filtered;
  };

  visibleShifts = () => {
    const { selectedSort, ascending, filterBySkill, filterByDepartment, filterByStatus, filterByWeekday, filterByGroup, filterByJobTitle, filterByEmail, filterByEmployeeId, searchTerm, selectedType, selectedItem, selectedIndex } = this.state;
    const shiftsOfCurrentCategory = this.filterShifts(selectedType, selectedItem, selectedIndex);
    const shiftsWithFiltersApplied = filterUtil.applyFiltersToShifts(shiftsOfCurrentCategory, filterByDepartment, filterByStatus, filterByGroup, filterByJobTitle, filterByEmail, filterByEmployeeId, filterBySkill, filterByWeekday, false,);
    // const foundUsers = filterUtil.searchUsers(searchTerm, usersWithFiltersApplied);
    const sortedShifts = utils.sortShifts(shiftsWithFiltersApplied, selectedSort?.name, ascending);
    return sortedShifts;
  }

  render() {
    const { selectedType, selectedIndex, selectedItem, selectedShifts, locationId, totalRows, filteredResults } = this.state;
    const { loading, locationSettings, skills, notifyRefreshRequested} = this.props;

    const categoryItems = this.createCategoryItems();
    const summaryItems = this.createSummaryElements(categoryItems);

    const foundShifts = this.visibleShifts();

    return (
      <div className="schedule-calendar__wrap">
        {/* <LoadingLayer loading={this.props.loading} /> */}

        <Summary
          loading={this.props.loading}
          summaryItems={summaryItems}
          selectedType={selectedType}
          parentSelectedIndex={selectedIndex}
          selectedShifts={selectedShifts}
          summaryItemSelected={this.summaryItemSelected}
        />

        <Header
          locationSettings={locationSettings}
          selectedType={selectedType}
          locationId={locationId}
          isRequests={false}
          shifts={totalRows}
          filteredResults={foundShifts}
          selectedShifts={selectedShifts}
          selectedItem={selectedItem}
          notifyRefreshRequested={notifyRefreshRequested}
          notifyDropDownOpened={this.notifyDropDownOpened}
          notifyFiltersSelected={this.notifyFiltersSelected}
          notifySearchTermChanged={this.notifySearchTermChanged}
          notifySortTypeChanged={this.notifySortTypeChanged}
        />

        <UserTable
          loading={loading}
          isRequests={false}
          locationId={locationId}
          shifts={totalRows}
          filteredResults={foundShifts}
          skills={skills} />

        {this.checkForAbsentData()}
      </div>
    );
  }
}

export default TableComponentV3;

TableComponentV3.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  rows: PropTypes.array.isRequired,
};
