import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  unregisteredUsers: slices.unregisteredUsersSlice.reducer,
};

const reducer = combineReducers({
    unregisteredUsers: reducers.unregisteredUsers,
});

export default reducer;