import moment from 'moment';
import { startOfFiscalWeek } from "helpers/date-time";

const defaultStart = (date) => { return moment(date).hours(9).minute(0).second(0);} // Default start time: 09:00 AM 

const defaultEnd = (date) => { return moment(date).hours(15).minute(0).second(0);} // Default end time: 03:00 PM

const haveSameDate = (timeStamp, dateObject) => {
    return moment(timeStamp).isSame(moment(dateObject), 'day')
}

const pastedShiftStartTime = (shiftToCopy, dateToPaste)  => {
  const date = moment(dateToPaste).clone();
  const originalStart = moment(shiftToCopy.start_at).clone();
  const resultDateTime = date.hours(originalStart.hour()).minute(originalStart.minutes()).second(0);

  const startTimeString = resultDateTime.format('YYYY-MM-DD HH:mm:00Z');

  console.log('Pasted Start Time: '+ startTimeString);
  return startTimeString;
}

const pastedShiftEndtime = (shiftToCopy, dateToPaste) => {
  const date = moment(dateToPaste).clone();
  const originalStart = moment(shiftToCopy.start_at).clone();
  const originalEnd = moment(shiftToCopy.end_at).clone();

  const resultStartDateTime = date.clone().hours(originalStart.hour()).minute(originalStart.minutes()).second(0);
  var resultEndDateTime = date.clone().hours(originalEnd.hour()).minute(originalEnd.minutes()).second(0);

  const startString = resultStartDateTime.format('HH:mm:00Z');
  const endString = resultEndDateTime.format('HH:mm:00Z');

  if( isOvernightShift(startString, endString, date) == true ){ 
    resultEndDateTime = date.clone().add(1, 'days').hours(originalEnd.hour()).minute(originalEnd.minutes()).second(0);
  }
  const endTimeString = resultEndDateTime.format('YYYY-MM-DD HH:mm:00Z')
  console.log('Pasted End Time: '+ endTimeString);
  return endTimeString;
}

const calculateTargetTime = (sourceTime, newDate ) => {
   // Parse the input times with moment.js
   const shiftStart = moment(sourceTime);
   const targetDate = moment(newDate).startOf('day'); // Set to the start of the new day
   
   // Get the time of the original shift (hours, minutes, seconds)
   const shiftTime = shiftStart.format('HH:mm:ss');
   
   // Combine the target date with the shift time to get the new shift start
   const newTime = targetDate.set({
     hour: shiftStart.hour(),
     minute: shiftStart.minute(),
     second: 0,
     millisecond: 0,
   });
 
   return newTime;
}

const shiftDuration = (start_at, end_at) => {
    const start = moment(start_at).local().clone();
    const end = moment(end_at).local().clone();
    const diff = moment.duration(end.diff(start)); 
    return diff;
}

const addTimeDiffTo = (sourceTimeStamp, diff) => {
    return moment(sourceTimeStamp).clone().add(diff);
}

const isOvernightShift = (startStr, endStr, date) => {

  const dateString = date.format('YYYY-MM-DD');
  const startTimeString = dateString + ' ' + startStr;
  var endTimeString = dateString + ' ' + endStr;

  const momentStartTime = moment(startTimeString);
  const momentEndTime = moment(endTimeString);

  return momentStartTime.isSameOrAfter(momentEndTime);
}

const fiscalWeekDays = (fiscalStartDay) => {
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  if(fiscalStartDay === 'monday' ){
    return weekdays;
  }else if(fiscalStartDay === 'sunday'){
    return weekdays.slice(6).concat(weekdays.slice(0,6));
  }else if(fiscalStartDay === 'tuesday' ){
    return weekdays.slice(1).concat(weekdays.slice(0,1));
  }else if(fiscalStartDay === 'wednesday'){
    return weekdays.slice(2).concat(weekdays.slice(0,2));
  }else if(fiscalStartDay === 'thursday'){
    return weekdays.slice(3).concat(weekdays.slice(0,3));
  }else if(fiscalStartDay === 'friday'){
    return weekdays.slice(4).concat(weekdays.slice(0,4));
  }else if(fiscalStartDay === 'saturday'){
    return weekdays.slice(5).concat(weekdays.slice(0,5));
  }else{
    return weekdays;
  }
}

const adjustedDateOfWeek = (fiscalStartDayOfWeek, dayOfWeek, anchorDate) => {
  const startDateOfAnchorWeek = startOfFiscalWeek(fiscalStartDayOfWeek, anchorDate);
  const fiscalDays = fiscalWeekDays(fiscalStartDayOfWeek);
  const indexOfDay = fiscalDays.indexOf(dayOfWeek);
  const resultDate = startDateOfAnchorWeek.clone().add(indexOfDay, 'days');
  return resultDate;
}

const getAdjustedShiftTimesForMultipleDays = (startDateTime, endDateTime, selectedDays, date, fiscalStartDay) => {
    var shiftTimePairs = [];
    selectedDays.forEach((dayOfWeek) => {

      // Adjust the date based on the selected day
      const adjustedDate = adjustedDateOfWeek(fiscalStartDay, dayOfWeek, date);
      
      // Adjust the start and end date based on the day
      const adjustedStartDateTime = dayLightSavingsAdjustedDateTime(adjustedDate, date, startDateTime);
      var adjustedEndDateTime = dayLightSavingsAdjustedDateTime(adjustedDate, date, endDateTime);
      
      // Handle overnight shift case (where end time is after midnight)
      if (isOvernightShift(startDateTime, endDateTime, adjustedDate)) {
        const nextDay = adjustedDate.clone().add(1, 'days');
        adjustedEndDateTime = dayLightSavingsAdjustedDateTime(nextDay, date, endDateTime);
      }

      shiftTimePairs.push({start: adjustedStartDateTime, end: adjustedEndDateTime});
    });
    return shiftTimePairs;
  }


// Fix for 1hour time offset bug due to adding shifts to multiple days across Daylight Savings Boundaries.
// ie: Add a shift to date Mar 8, 2025, select multiple days Mar 9, Mar 10, Mar 11, the resulting timestamp are off by 1 hour since Mar 9, 10, and 11 are in a different DLS timezone.
// This function fixes the timestamp by making sure resulting timestamp is the same for both standard and daylight savings timezones.

const dayLightSavingsAdjustedDateTime = (targetDate, originalDate, originalHourMinuteTimeZoneOffset) => {
    const originalTimeStamp = originalDate.format('YYYY-MM-DD') + ' ' + originalHourMinuteTimeZoneOffset;
    const hour = moment(originalTimeStamp).hour();
    const minute = moment(originalTimeStamp).minutes();
    const result = moment(targetDate).hours(hour).minute(minute).second(0);
    return result;
}

// Duration from a date and hh:mm:ss timestamps for start and end 
const calculateDuration = (date, startTime, endTime) => {
  const adjustedStartDateTime = dayLightSavingsAdjustedDateTime(date, date, startTime);
  var adjustedEndDateTime = dayLightSavingsAdjustedDateTime(date, date, endTime);
  
  // Handle overnight shift case (where end time is after midnight)
  if (isOvernightShift(startTime, endTime, date)) {
    const nextDay = date.clone().add(1, 'days');
    adjustedEndDateTime = dayLightSavingsAdjustedDateTime(nextDay, date, endTime);
  }   

  const startVal = moment(adjustedStartDateTime).local().clone();
  const endVal = moment(adjustedEndDateTime).local().clone();
  const diff = moment.duration(endVal.diff(startVal));
  const hours = parseFloat(diff.asHours()).toFixed(2);
  return hours;
}

const isShiftInRange = (shift, rangeStart, rangeEnd) => {
   const start = moment(shift.start_at);

   if( start.isBefore(rangeEnd) && start.isSameOrAfter(rangeStart) ){
    return true;
   }else{
    return false;
   }
}


const daysOfTheWeek = (locationData) => {
  const fiscalDay = locationData.fiscal_day;

  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  if(fiscalDay === 'monday' ){
    return weekdays;
  }else if(fiscalDay === 'sunday'){
    return weekdays.slice(6).concat(weekdays.slice(0,6));
  }else if(fiscalDay === 'tuesday' ){
    return weekdays.slice(1).concat(weekdays.slice(0,1));
  }else if(fiscalDay === 'wednesday'){
    return weekdays.slice(2).concat(weekdays.slice(0,2));
  }else if(fiscalDay === 'thursday'){
    return weekdays.slice(3).concat(weekdays.slice(0,3));
  }else if(fiscalDay === 'friday'){
    return weekdays.slice(4).concat(weekdays.slice(0,4));
  }else if(fiscalDay === 'saturday'){
    return weekdays.slice(5).concat(weekdays.slice(0,5));
  }else{
    return weekdays;
  }
}

const dayOfCurrentWeekRangeForShift = (presentedShift, rangeStart) => {
  var momentVal = moment(presentedShift.start_at);
  momentVal.locale("en");
  const dayDiff = momentVal.diff(rangeStart, 'days');
  return (dayDiff+1)
}

export default {
    haveSameDate,
    addTimeDiffTo,
    shiftDuration,
    calculateTargetTime,
    dayLightSavingsAdjustedDateTime,
    isShiftInRange,
    fiscalWeekDays,
    isOvernightShift,
    getAdjustedShiftTimesForMultipleDays,
    pastedShiftStartTime,
    pastedShiftEndtime,
    defaultStart,
    defaultEnd,
    calculateDuration,
    daysOfTheWeek,
    dayOfCurrentWeekRangeForShift,
}