import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import './SubscriptionPage.scss';

import { getPlans } from '../../helpers/api-calls/plan-calls';
import { getUserInfoNonAdmin, } from 'helpers/api-calls/user-calls';
import { getUserId } from '../../helpers/cookies';


let basePricingPageUrl = "";
let baseShopPageUrl = "";

if (window.location.host === 'web.myshyft.com' || window.location.host === 'manager.myshyft.com') {
  basePricingPageUrl = "https://www.myshyft.com/monthly_price/"
  baseShopPageUrl = "https://billing.stripe.com/p/login/5kAeWA49Fbv6cNi6oo"
} else {
  basePricingPageUrl = "https://www.myshyft.com/pricing-test-3/"
  baseShopPageUrl = "https://billing.stripe.com/p/login/test_eVa9BB6633V8eIw7ss"
}

class SubscriptionPage extends React.Component {
  state = {
    loading: true,
    path: null,
  };

  constructor(props) {
    super(props);

    this.state ={
      planName:[], 
      planPrice:[],
      planUrl: [],
      planButtonText: [],
      planThirdLineText: [],
      planFourthLineText: [],
    };

    this._isMounted = false;
    this.planUrlSet = false;
  }

  componentDidMount() {
    const { intl, locationId, locationData, plans, } = this.props;
    this._isMounted = true;
    const userId = parseInt(getUserId());
    let ref_id = this.encrypt(userId,locationId);
    if(ref_id) {
      this.setState({planUrl: basePricingPageUrl+"?ref_id="+ref_id, 
        planButtonText: "View Plans",
        planThirdLineText: "",
      });
    }

    if(plans && plans.length > 0) {
      if(locationData && locationData.plan_id) {
        const cplan = plans.find(plan => plan.id === locationData.plan_id);
        //this.setState({planName: cplan.name, planPrice: cplan.month_price});
        let userId = parseInt(getUserId());
        let ref_id = this.encrypt(userId,locationId);
        if(ref_id) {
          if(cplan.name === "Basic") {
            this.setState({planUrl: basePricingPageUrl+"?ref_id="+ref_id, 
              planButtonText: "View Plans",
              planThirdLineText: "You do not have an active subscription, purchase a plan from the Subscription Page to unlock additional features.",
            });
          }
          else if(cplan.name === "Trial") {
            this.setState({planUrl: basePricingPageUrl+"?ref_id="+ref_id, 
              planButtonText: "View Plans",
              planThirdLineText: "Your trial ends on: " + moment(locationData.plan_refresh_date).format('MMMM D, YYYY'),
            });
          }
          else {
            this.setState({planUrl: baseShopPageUrl, 
              planButtonText: "Manage Plan",
              planThirdLineText: "Current billing cycle: " + this.modifyBillingCycle(locationData.plan_cycle),
            });
            if(locationData.plan_cycle === "monthly") {
              this.setState({planName: cplan.name, planPrice: cplan.month_price});
              if(cplan.name === "Starter") {
                this.setState({planFourthLineText: "Switch to annual billing and get 14% off the price of your subscription."});
              } else if(cplan.name === "Advanced") {
                this.setState({planFourthLineText: "Switch to annual billing and get 15.8% off the price of your subscription."});
              } else if(cplan.name === "Professional") {
                this.setState({planFourthLineText: "Switch to annual billing and get 16% off the price of your subscription."});
              } else{
              }
            } else {
              this.setState({planName: cplan.name, planPrice: cplan.year_price});
            }
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { plans, locationData, locationId } = this.props;
    if(plans && plans.length > 0) {
      if(locationData && locationData.plan_id) {
        if(!this.planUrlSet) {
          this.planUrlSet = true;
          const cplan = plans.find(plan => plan.id === locationData.plan_id); //find what plan the location is on
          this.setState({planName: cplan.name, 
            planPrice: cplan.month_price});
          let userId = parseInt(getUserId());
          let ref_id = this.encrypt(userId,locationId);
          if(ref_id) {
            if(cplan.name === "Basic") {
              this.setState({planUrl: basePricingPageUrl+"?ref_id="+ref_id, 
                planButtonText: "View Plans",
                planThirdLineText: "You do not have an active subscription, view our Subscription Page to select a plan and unlock additional features.",
              });
            }
            else if(cplan.name === "Trial") {
              this.setState({planUrl: basePricingPageUrl+"?ref_id="+ref_id, 
                planButtonText: "View Plans",
                planThirdLineText: "Your trial ends on: " + moment(locationData.plan_refresh_date).format('MMMM D, YYYY'),
              });
            }
            else {
              this.setState({planUrl: baseShopPageUrl, 
                planButtonText: "Manage Plan",
                planThirdLineText: "Current billing cycle: " + this.modifyBillingCycle(locationData.plan_cycle),
              });
              if(locationData.plan_cycle === "monthly") {
                this.setState({planName: cplan.name, planPrice: cplan.month_price});
                if(cplan.name === "Starter") {
                  this.setState({planFourthLineText: "Switch to annual billing and get 14% off the price of your subscription."});
                } else if(cplan.name === "Advanced") {
                  this.setState({planFourthLineText: "Switch to annual billing and get 15.8% off the price of your subscription."});
                } else if(cplan.name === "Professional") {
                  this.setState({planFourthLineText: "Switch to annual billing and get 16% off the price of your subscription."});
                } else {
                }
              } else {
                this.setState({planName: cplan.name, planPrice: cplan.year_price});
              }
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  encrypt(uid, lid) {
    return (uid*uid + lid*lid + uid + 2*uid*lid + 3*lid)/2;
  }

  modifyBillingCycle(bill_cycle) {
    if(bill_cycle === 'monthly') {
      return 'Monthly';
    } else if(bill_cycle === 'yearly') {
      return 'Annually';
    } else {
      return 'Monthly';
    }
  }

  render() {
    const { locationData, plans, planName, planPrice, planUrl, planButtonText, planThirdLineText, planFourthLineText} = this.state;
    return (
      <div className="view-plan-subscribed">
          <div className="overlap">
            <div className="row">Current Shyft Plan</div>
            <div className="group">
              <div className="text-wrapper">{planName}</div>
              <div className="text-wrapper-2">${planPrice} per month</div>
              <p className="your-plan-renews-on">{planThirdLineText}<br /><br />{planFourthLineText}</p>
            </div>
            <div className="group-2">
              <div className="div-wrapper">
                  <a className="text-wrapper-3" target="_blank" href={planUrl ? planUrl : ""}>{planButtonText}</a>
              </div>
            </div>
          </div>
      </div>
    );
  };
}

//export {SubscriptionPage};

export default injectIntl(SubscriptionPage);