import { connect } from 'react-redux';
import React from 'react';
import { PubNubConsumer } from 'pubnub-react';

import { DetermineSetup } from 'helpers/user-setup';

import SetupComponent from 'components/GeneralComponents/SetupComponent';
import { setSetupNeeded, localeChanged } from '../actions/dashboard-actions';

const mapStateToProps = ({ setupReducer, localeReducer, appReducer }) => {
  return {
    setupNeeded: DetermineSetup(setupReducer.setup_actions),
    setupActions: setupReducer.setup_actions,
    locale: localeReducer.locale,
    locationSettings: appReducer.location.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSetupNeeded: (setupActions) => {
      dispatch(setSetupNeeded(setupActions));
    },
    onLocaleChange: (locale) => {
      dispatch(localeChanged(locale));
    },
  };
};

const SetupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupComponent);

export default SetupContainer;
