import React from 'react';
import './NotifyTeamComponent.scss';
import '../../../../App/fonts.scss';
import '../../../../App/colors.scss';
import '../../../../App/layout.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

import {Mixpanel} from '../../../../Mixpanel';

class CopyToWeekComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    const { cancelModal, locationId, scrWeekDay, destWeekDay, destWeekLabel, ownerIds} = props;

    this.state = {
      confirmButtonEnabled: true,
    };
  }

  handleCancel = (event) => {
    const { cancelModal } = this.props;
    cancelModal();
    Mixpanel.track('Cancel copy to week');
  }

  handleConfirm = (event) => {
    const { cancelModal, locationId, scrWeekDay, destWeekDay, destWeekLabel, ownerIds, copyToWeekDispatch} = this.props;
    const {confirmButtonEnabled} = this.state;

    if( confirmButtonEnabled == false ){
      return;
    }

    cancelModal();

    if(destWeekDay !== null){
      copyToWeekDispatch(locationId, scrWeekDay, destWeekDay, ownerIds);
      Mixpanel.track('Confirm copy to week', {'location_id': locationId, src_week_day: scrWeekDay, dest_week_day: destWeekDay, owner_ids: ownerIds});
    }
  }

  render() {
    const {confirmButtonEnabled, } = this.state;
    const { cancelModal, locationId, scrWeekDay, destWeekDay, destWeekLabel, ownerIds} = this.props;

    const cancel = (<FormattedMessage id="Confirm.cancel" defaultMessage="Cancel" />);
    const confirm = (<FormattedMessage id="Confirm.confirm" defaultMessage="Confirm" />);
    const copyToWeekTitle = (<FormattedMessage id="Scheduling.copyToWeekTitle" defaultMessage='Copy Shedule to {week}' values={{week: destWeekLabel}} />);
    const copyToWeekNotes = (<FormattedMessage id="Scheduling.copyToWeekNotes" defaultMessage='Scheduled shifs and traded shifts are copied to their original owners by default.'/>);

    return (
      <div className="notify-team-container">
        <label className="title averta-regular fs20px">{copyToWeekTitle}</label>
        <div className='body-text1'>{copyToWeekNotes}</div>

        <div className="confirm-button-wrap">
          <button className="confirm-button-cancel averta-semibold fs14px" onClick={this.handleCancel}>{cancel}</button>
          <button className={confirmButtonEnabled ? "confirm-button averta-semibold fs14px button-enabled" : "confirm-button averta-semibold fs14px button-disabled"} style={{ outline: 0 }} onClick={this.handleConfirm}>{confirm}</button>
        </div>

      


      </div>
    );
  }
}


export default injectIntl(CopyToWeekComponent);