import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as styled from '../styled';
import * as blocks from '../blocks';

const InformativeMessage = ({
  posted,
  posting,
  errorCount,
  shiftLimit,
  numberOfShiftsIsValid,
  currentNumberOfShifts,
}) => {
  // posted and errors
  if (posted && errorCount > 0) {
    return (
      <FormattedMessage
        id="ActionFooter.postShiftsWithErrors"
        defaultMessage="{totalPosted} offers are now ready for your team to view and cover on the Shift Feed. Some offers failed to post."
        values={{
          totalPosted: currentNumberOfShifts - errorCount,
        }}
      />
    );
  }

  if (posted) {
    return (
      <FormattedMessage
        id="ActionFooter.postedShifts"
        defaultMessage="All set! Your batch of {currentNumberOfShifts} offers is now ready for your team to view and cover on the Shift Feed."
        values={{
          currentNumberOfShifts,
        }}
      />
    );
  }

  if (posting) {
    return (
      <FormattedMessage
        id="ActionFooter.postingShifts"
        defaultMessage="Hang tight! We're working on posting your batch of {currentNumberOfShifts} offers. {lineBreak} Please do not refresh this screen."
        values={{
          lineBreak: <br />,
          currentNumberOfShifts,
        }}
      />
    );
  }

  if (numberOfShiftsIsValid) {
    return (
      <FormattedMessage
        id="ActionFooter.shiftCountProgress"
        defaultMessage="You can post up to {maxNumberOfShifts} offers in a batch. This batch currently contains {currentNumberOfShifts} {currentNumberOfShifts, plural, one {shift} other {shifts}}. You may add {numberOfShiftsLeft} more."
        values={{
          maxNumberOfShifts: shiftLimit,
          currentNumberOfShifts,
          numberOfShiftsLeft: shiftLimit - currentNumberOfShifts,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      id="ActionFooter.invalidShiftCountProgress"
      defaultMessage="You can post up to {maxNumberOfShifts} offers in a batch. This batch currently contains {currentNumberOfShifts} {currentNumberOfShifts, plural, one {shift} other {shifts}}. Please remove {numberOfShiftsToRemove} {numberOfShiftsToRemove, plural, one {shift} other {shifts}}."
      values={{
        maxNumberOfShifts: shiftLimit,
        currentNumberOfShifts,
        numberOfShiftsToRemove: Math.abs(shiftLimit - currentNumberOfShifts),
      }}
    />
  );
};

const ActionButtons = ({
  posted,
  isValid,
  previewing,
  posting,
  backToEdit,
  submitClick,
  previewClick,
  numberOfShiftsIsValid,
  isValidSelection,
}) => {
  if (posted) {
    return (
      <styled.ActionBtn
        onClick={() => {
          window.location.pathname = '/requests/requests';
        }}
      >
        <FormattedMessage id="ActionFooter.finish" defaultMessage="Finish" />
      </styled.ActionBtn>
    );
  }

  if (posting) {
    return (
      <styled.ActionBtn disabled>
        <FormattedMessage
          id="ActionFooter.postBatchDisabled"
          defaultMessage="Post"
        />
      </styled.ActionBtn>
    );
  }

  if (previewing) {
    return (
      <React.Fragment>
        <styled.EditBtn onClick={backToEdit}>
          <FormattedMessage
            id="ActionFooter.editBatch"
            defaultMessage="Back to Editing"
          />
        </styled.EditBtn>
        <styled.ActionBtn onClick={submitClick}>
          <FormattedMessage id="ActionFooter.postBatch" defaultMessage="Post" />
        </styled.ActionBtn>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <styled.ActionBtn
        disabled={!isValid || !numberOfShiftsIsValid || !isValidSelection}
        onClick={previewClick}
      >
        <FormattedMessage
          id="ActionFooter.previewBatch"
          defaultMessage="Preview"
        />
      </styled.ActionBtn>
    </React.Fragment>
  );
};

const StatusBadge = ({
  posted,
  posting,
  numberOfShiftsIsValid,
  errorCount,
}) => {
  if (posted && errorCount > 0) {
    return <styled.InvalidBatchStatusBadge />;
  }

  if (posted) {
    return (
      <styled.ValidBatchStatusBadge>
        <styled.ValidBadgePencil />
      </styled.ValidBatchStatusBadge>
    );
  }

  if (posting) {
    return <styled.InvalidBatchStatusBadge />;
  }

  if (numberOfShiftsIsValid) {
    return (
      <styled.ValidBatchStatusBadge>
        <styled.ValidBadgePencil />
      </styled.ValidBatchStatusBadge>
    );
  }

  return <styled.InvalidBatchStatusBadge />;
};

const ActionFooter = ({
  posted,
  isValid,
  posting,
  errorCount,
  previewing,
  shiftLimit,
  backToEdit,
  submitClick,
  previewClick,
  numberOfShiftsIsValid,
  currentNumberOfShifts,
  isValidSelection,
}) => (
  <blocks.Footer>
    <blocks.FooterColumn>
      <styled.FooterDescription>
        <span>
          <StatusBadge
            posted={posted}
            posting={posting}
            errorCount={errorCount}
            numberOfShiftsIsValid={numberOfShiftsIsValid}
          />
        </span>
        <InformativeMessage
          posted={posted}
          posting={posting}
          errorCount={errorCount}
          shiftLimit={shiftLimit}
          currentNumberOfShifts={currentNumberOfShifts}
          numberOfShiftsIsValid={numberOfShiftsIsValid}
        />
      </styled.FooterDescription>
    </blocks.FooterColumn>
    <blocks.FooterColumn>
      <ActionButtons
        posted={posted}
        isValid={isValid}
        posting={posting}
        errorCount={errorCount}
        previewing={previewing}
        shiftLimit={shiftLimit}
        backToEdit={backToEdit}
        submitClick={submitClick}
        previewClick={previewClick}
        numberOfShiftsIsValid={numberOfShiftsIsValid}
        currentNumberOfShifts={currentNumberOfShifts}
        isValidSelection={isValidSelection}
      />
    </blocks.FooterColumn>
  </blocks.Footer>
);

export default ActionFooter;
