import { protectedAxiosInstance, axiosInstance } from 'helpers/api-calls';
import { createHeadersForApiCall, getUserId, getSessionUukey } from '../cookies';
import { formatOnlyProps } from 'helpers/formatting';

const planApiUrl = '/api/compact/plans';

/**
 * Get pending shifts
 * @param { Int } per_page - the amount of shifts to be returned
 * @param { Int } page - which page of shifts needs to be returned
 *
 * @return { Object } this contains the stats for the shifts (total count, current page, etc)
 *                    and then an array of the shifts
 */
export const getPlans = (location_id) =>
  protectedAxiosInstance.request({
    headers: createHeadersForApiCall(),
    method: 'GET',
    url: `${planApiUrl}`,
  });