import utils from "./utils";
import formatter from "./formatter";
import moment from "moment";

const transformSingleDayShiftsToString = (singleDayShifts) => {
    var result = [];
    singleDayShifts.forEach((shift) => {
        const timeRange = formatter.formattedShiftTimeRange(shift);
        const position = shift.primary_job;
        
        if( result.length > 0 ){
          if( position !== null && position.length > 0){
            result.push(`\n\n${timeRange}\n${position}`);
          }else{
            result.push(`\n\n${timeRange}`);
          }
        }else{
          if( position !== null && position.length > 0){
            result.push(`${timeRange}\n${position}`);
          }else{
            result.push(`${timeRange}`);
          }
        }
    });
    return result;
  }
  
  const transformShiftMatrixToCSVRow = (user, shiftMatrix) => {
    return [user.first_name, user.last_name, shiftMatrix[0],
      transformSingleDayShiftsToString(shiftMatrix[1]),
      transformSingleDayShiftsToString(shiftMatrix[2]),
      transformSingleDayShiftsToString(shiftMatrix[3]),
      transformSingleDayShiftsToString(shiftMatrix[4]),
      transformSingleDayShiftsToString(shiftMatrix[5]),
      transformSingleDayShiftsToString(shiftMatrix[6]),
      transformSingleDayShiftsToString(shiftMatrix[7])];
  }
  
  const exportableData = (shifts, allUsers, unregisteredUsers, rangeStart, rangeEnd) => {
    var header = ['Employee First Name', 'Employee Last Name', 'Weekly Scheduled Hours'];
  
    if( rangeStart != null ){
      const clone = rangeStart.clone();
      const headerFormat = 'YYYY-MM-DD';
  
      const firstDate = clone.format(headerFormat);
      const secondDate = clone.clone().add(1, 'days').format(headerFormat);
      const thirdDate = clone.clone().add(2, 'days').format(headerFormat);
      const fourthDate = clone.clone().add(3, 'days').format(headerFormat);
      const fifthDate = clone.clone().add(4, 'days').format(headerFormat);
      const sixthDate = clone.clone().add(5, 'days').format(headerFormat);
      const lastDate = rangeEnd.format(headerFormat);
  
      header.push(firstDate);
      header.push(secondDate);
      header.push(thirdDate);
      header.push(fourthDate);
      header.push(fifthDate);
      header.push(sixthDate);
      header.push(lastDate);
    }
    var result = [header];
  
    allUsers.forEach((user) => {
      const row = utils.getRowOfShiftsToDisplay(shifts, user, rangeStart, 1);
      result.push(transformShiftMatrixToCSVRow(user, row));
    });
  
    unregisteredUsers.forEach((user) => {
      const row = utils.getRowOfShiftsToDisplay(shifts, user, rangeStart, 1);
      result.push(transformShiftMatrixToCSVRow(user, row));
    });
  
    return result;
}

const getUnregisteredUserInfo = (shift, unregisteredUsers) => {
    if (shift.hasOwnProperty('unregistered_user_id')) {
      if ( shift.unregistered_user_id !== null ) {
          const usr = unregisteredUsers.find( unregUser => {return unregUser.id == shift.unregistered_user_id } );
          return usr;
      }
    } else if (shift.hasOwnProperty('unregistered_user')) {
      return shift.unregistered_user;
    }
}

const tableFormatData= (shifts, allUsers, unregisteredUsers, rangeStart, rangeEnd) => {
    var header = ['first_name', 'last_name', 'employee_id', 'start_at', 'end_at', 'cached_primary_job'];
    var result = [header];
    const shiftFormat = 'YYYY-MM-DD HH:mm:ss';
  
    shifts.forEach((shift) => {
      if( utils.shouldShiftBeCountedTowardsTotalHours(shift) ) {
          const start = moment(shift.start_at).local().clone().format(shiftFormat);
          const end = moment(shift.end_at).local().clone().format(shiftFormat);
  
        if( shift.trade_status == 'unregistered_user_shift_inventory' ){
          if( shift.unregistered_user_id !== null ){
              const unregUser = getUnregisteredUserInfo(shift, unregisteredUsers);
  
              if( typeof unregUser !== 'undefined' && unregUser.first_name !== null && unregUser.last_name !== null ){
                  result.push([unregUser.first_name, unregUser.last_name, unregUser.internal_id , start, end, shift.primary_job]);
              }
          }
  
        }else{
          const user = allUsers.find( userObj => {return userObj.owner_id === utils.currentOwnerIdForRegisteredShift(shift) });
          if( typeof user !== 'undefined' && typeof user.first_name !== 'undefined' && user.first_name !== null 
            && typeof user.last_name !== 'undefined' && user.last_name !== null  ){
              result.push([user.first_name, user.last_name,  user.badge_id, start, end, shift.primary_job]); 
          }
        }
      }
    });
  
    return result;
  }

  export default {
    exportableData,
    tableFormatData,
    transformShiftMatrixToCSVRow,
    getUnregisteredUserInfo,
  }