import React from 'react';
import { connect } from 'react-redux';
import { PubNubConsumer } from 'pubnub-react';
import moment from 'moment';
import UserManagementComponent from './UserManagementComponent';

import { changeLocation, loadLocations } from '../../actions/dashboard-actions';

import { userManagementOperations } from './duck';
import { appActions } from 'App/duck';
import { userManagementActions } from './duck';

import {unregisteredUserOperations} from '../UnregisteredUser/duck';

const mapStateToProps = ({
  teamReducer,
  locationReducer,
  appReducer,
  userManagementReducer,
  unregisteredUserReducer,
}) => {
  return {
    locationSettings: appReducer.location.settings,
    locationId: locationReducer.location_code,
    locationData: locationReducer.location_data,
    locations: locationReducer.locations,
    visibleMembers: teamReducer.visibleMembers,
    bottomSheet: teamReducer.bottomSheet,
    searchTerm: teamReducer.search.searchTerm,
    searching: teamReducer.search.searching,
    activeFilter: userManagementReducer.filters.activeFilter,
    filters: userManagementReducer.filters.filters,
    managers: appReducer.managers.users,
    managersCurrentPage: userManagementReducer.managers.page,
    managersTotalPages: userManagementReducer.managers.totalPages,
    associates: appReducer.associates.users,
    allUsers: appReducer.allUsers.users,
    associatesCurrentPage: userManagementReducer.associates.page,
    associatesTotalPages: userManagementReducer.associates.totalPages,
    removedUsers: appReducer.removedUsers.users,
    removedUsersCurrentPage: userManagementReducer.removedUsers.page,
    removedUsersTotalPages: userManagementReducer.removedUsers.totalPages,
    pendingUsers: appReducer.pendingUsers.users,
    departments: appReducer.departments.data,
    channels: appReducer.channels.data,

    unregisteredUsers: unregisteredUserReducer.unregisteredUsers.users,

    skills: appReducer.skills.data,
    channelMembers: appReducer.users.data,
    channelData: userManagementReducer.channelMembers.data,
    loadingAssociates: appReducer.associates.loading,
    loadingManagers: appReducer.managers.loading,
    loadingRemovedUsers: appReducer.removedUsers.loading,
    lastAction: appReducer.managers.lastAction,
    lastOperation: userManagementReducer.managers.lastOperation,
    error: userManagementReducer.allUsers.error,
    plans: appReducer.plans.data,
    locationData: locationReducer.location_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  const fetchRemovedUsers = (locationId, pageNo, numPerPage) => {
    dispatch(userManagementOperations.fetchRemovedUsers(locationId, pageNo, numPerPage));
  };

  const fetchAllUsers = (locationId, pageNo, numPerPage) => {
    dispatch(userManagementOperations.fetchAllUsers(locationId, pageNo, numPerPage));
  };
  const fetchUnregisteredUsers = (locationId) => {
    dispatch(unregisteredUserOperations.fetchUnregisteredUsersDispatch(locationId));
  };
  const handleRemoveUnregisteredUser = (unregisteredUserId) => {
    dispatch(unregisteredUserOperations.removeUnregisteredUserOperation(unregisteredUserId));
  }

  const getLocationInnerDepartments = (locationId) => {
    dispatch(userManagementOperations.getLocationInnerDepartments(locationId));
  };
  const getLocationUsableSkills = (locationId) => {
    dispatch(userManagementOperations.getLocationUsableSkills(locationId));
  };
  const requestChannelMembers = () => {
    dispatch(userManagementActions.requestChannelMembers(true));
  };
  const fetchChannelMembers = (channelId, channelName) => {
    dispatch(userManagementOperations.fetchChannelMembers(channelId, channelName));
  };
  const getLocationChannels = (locationId) => {
    dispatch(userManagementOperations.getLocationChannels(locationId));
  };
  const handlePromote = (userPrivileges) => {
    dispatch(userManagementOperations.updateAdminStatus(userPrivileges, true));
  };

  const handleWithdraw = (userPrivileges) => {
    dispatch(userManagementOperations.updateAdminStatus(userPrivileges, false));
  };

  const handleApprove = (userPrivilegeId, locationId) => {
    dispatch(userManagementOperations.approveUser(userPrivilegeId, locationId));
  };

  const handleDeny = (users, locationId) => {
    dispatch(userManagementOperations.denyUser(users, locationId));
  };

  const handleRemove = (admins, nonAdmins, locationId) => {
    dispatch(userManagementOperations.removeUser(admins,  nonAdmins, locationId));
  };

  const resetManagers = () => {
    dispatch(userManagementActions.resetManagers(true));
  };

  const resetAssociates = () => {
    dispatch(userManagementActions.resetAssociates(true));
  };

  const resetRemovedUsers = () => {
    dispatch(userManagementActions.resetRemovedUsers(true));
  };

  const resetPendingUsers = () => {
    dispatch(userManagementActions.resetPendingUsers(true));
  };

  const resetAllUsers = () => {
    dispatch(userManagementActions.resetAllUsers(true));
  };

  const handleCreateSkill = (skillName, locationId) => {
    dispatch(userManagementOperations.createGroup(skillName, locationId));
  }

  const handleCreateGroup = (groupName, locationId, description) => {
    dispatch(userManagementOperations.createGroup(groupName, locationId, description));
  };

  const handleCreateDepartment = (deptName, locationId, description) => {
    dispatch(userManagementOperations.createDepartment(deptName, locationId, description));
  };

  const handleAddUserToGroup = (channelId, users) => {
    dispatch(userManagementOperations.addUserToGroup(channelId, users));
  };

  const handleRemoveUserFromGroup = (channelId, users, memberCount) => {
    dispatch(userManagementOperations.removeUserFromGroup(channelId, users, memberCount));
  };

  const handleAddUserToDepartment = (users, departmentId) => {
    dispatch(userManagementOperations.addUserToDepartment(users, departmentId));
  };

  const handleRemoveUserFromDepartment = (users, departmentId) => {
    dispatch(userManagementOperations.removeUserFromDepartment(users, departmentId));
  };

  const handleAssignUsersToSkill = (users, locationId, skill) => {
    dispatch(userManagementOperations.assignUsersToSkill(users, locationId, skill));
  };

  const handleRemoveUsersFromSkill = (users, locationId, skill) => {
    dispatch(userManagementOperations.removeUsersFromSkill(users, locationId, skill));
  };

  const handleChangeLocation = (locationId) => {
    dispatch(changeLocation(locationId));
  };

  const handleGetUserInformation = (userPrivilege, locationId) => {
    dispatch(userManagementOperations.getUserInformation1(userPrivilege, locationId));
  };

  const resetChannels = () => {
    dispatch(appActions.resetChannels(true));
  };

   const fetchSubscriptions = (channel_id) => {
    dispatch(userManagementOperations.fetchSubscriptions(channel_id));
  };
  
  return { 
    fetchRemovedUsers,
    fetchAllUsers,
    fetchUnregisteredUsers,
    handleRemoveUnregisteredUser,
    getLocationInnerDepartments,
    getLocationUsableSkills,
    requestChannelMembers,
    fetchChannelMembers,
    fetchSubscriptions,
    getLocationChannels,
    handlePromote,
    handleWithdraw,
    handleApprove,
    handleDeny,
    handleRemove,
    resetManagers,
    resetAssociates,
    resetRemovedUsers,
    resetPendingUsers,
    resetAllUsers,
    handleCreateSkill,
    handleCreateGroup,
    handleCreateDepartment,
    handleAddUserToGroup,
    handleRemoveUserFromGroup,
    handleAddUserToDepartment,
    handleRemoveUserFromDepartment,
    handleAssignUsersToSkill,
    handleRemoveUsersFromSkill,
    handleChangeLocation,
    handleGetUserInformation,
    resetChannels,
  };
};

const UserManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  return (
    <PubNubConsumer>
      {(client) => {
        const { visibleMembers,
                bottomSheet,
                searchTerm,
                searching,
                locationSettings,
                locationId,
                locationData,
                locations,
                managers,
                managersCurrentPage,
                managersTotalPages,
                associates,
                associatesCurrentPage,
                associatesTotalPages,
                removedUsers,
                allUsers,
                removedUsersCurrentPage,
                removedUsersTotalPages,
                pendingUsers,
                departments,
                channels,
                skills,
                channelMembers,
                channelData,
                loadingAssociates,
                loadingManagers,
                loadingRemovedUsers,
                loadingChannelMembers,
                lastAction,
                lastOperation,
                error,
                plans,
                unregisteredUsers,

                fetchRemovedUsers,
                fetchAllUsers,
                fetchUnregisteredUsers,
                handleRemoveUnregisteredUser,
                getLocationInnerDepartments,
                getLocationUsableSkills,
                requestChannelMembers,
                fetchChannelMembers,
                fetchSubscriptions,
                getLocationChannels,
                handlePromote,
                handleWithdraw,
                handleApprove,
                handleDeny,
                handleRemove,
                resetManagers,
                resetAssociates,
                resetRemovedUsers,
                resetPendingUsers,
                resetAllUsers,
                handleCreateSkill,
                handleCreateGroup,
                handleCreateDepartment,
                handleAddUserToGroup,
                handleRemoveUserFromGroup,
                handleAddUserToDepartment,
                handleRemoveUserFromDepartment,
                handleAssignUsersToSkill,
                handleRemoveUsersFromSkill,
                handleChangeLocation,
                handleGetUserInformation,
                resetChannels,
              } = props;

        return (
          <UserManagementComponent
            pubnub={client}
            visibleMembers={visibleMembers}
            bottomSheet={bottomSheet}
            searchTerm={searchTerm}
            searching={searching}
            locationSettings={locationSettings}
            locationId={locationId}
            locationData={locationData}
            locations={locations}
            managers={managers}
            managersCurrentPage={managersCurrentPage}
            managersTotalPages={managersTotalPages}
            associates={associates}
            associatesCurrentPage={associatesCurrentPage}
            associatesTotalPages={associatesTotalPages}
            removedUsers={removedUsers}
            removedUsersCurrentPage={removedUsersCurrentPage}
            removedUsersTotalPages={removedUsersTotalPages}
            pendingUsers={pendingUsers}
            allUsers={allUsers}
            departments={departments}
            channels={channels}
            skills={skills}
            channelMembers={channelMembers}
            channelData={channelData}
            loadingAssociates={loadingAssociates}
            loadingManagers={loadingManagers}
            loadingRemovedUsers={loadingRemovedUsers}
            loadingChannelMembers={loadingChannelMembers}
            lastAction={lastAction}
            lastOperation={lastOperation}
            error={error}

            fetchRemovedUsers={fetchRemovedUsers}
            fetchAllUsers={fetchAllUsers}
            fetchUnregisteredUsers = {fetchUnregisteredUsers}
            handleRemoveUnregisteredUser = {handleRemoveUnregisteredUser}
            getLocationInnerDepartments={getLocationInnerDepartments}
            getLocationUsableSkills={getLocationUsableSkills}
            requestChannelMembers={requestChannelMembers}
            fetchChannelMembers={fetchChannelMembers}
            fetchSubscriptions={fetchSubscriptions}
            getLocationChannels={getLocationChannels}
            handlePromote={handlePromote}
            handleWithdraw={handleWithdraw}
            handleApprove={handleApprove}
            handleDeny={handleDeny}
            handleRemove={handleRemove}
            resetManagers={resetManagers}
            resetAssociates={resetAssociates}
            resetRemovedUsers={resetRemovedUsers}
            resetPendingUsers={resetPendingUsers}
            resetAllUsers={resetAllUsers}
            handleCreateSkill={handleCreateSkill}
            handleCreateGroup={handleCreateGroup}
            handleCreateDepartment={handleCreateDepartment}
            handleAddUserToGroup={handleAddUserToGroup}
            handleRemoveUserFromGroup={handleRemoveUserFromGroup}
            handleAddUserToDepartment={handleAddUserToDepartment}
            handleRemoveUserFromDepartment={handleRemoveUserFromDepartment}
            handleAssignUsersToSkill={handleAssignUsersToSkill}
            handleRemoveUsersFromSkill={handleRemoveUsersFromSkill}
            handleChangeLocation={handleChangeLocation}
            handleGetUserInformation={handleGetUserInformation}
            resetChannels={resetChannels}
            plans={plans}

            unregisteredUsers={unregisteredUsers}
          />
        );
      }}
    </PubNubConsumer>
  );
});

export default UserManagementContainer;
