import channelsSlice from './channels';
import usersSlice from './users';
import locationSlice from './location';
import skillsSlice from './skills';
import departmentsSlice from './departments';
import actionJobsSlice from './actionJobs';
import allUsersSlice from './allUsers';
import associatesSlice from './associates';
import managersSlice from './managers';
import pendingUsersSlice from './pendingUsers';
import removedUsersSlice from './removedUsers';
import onlineUsersSlice from './onlineUsers';
import plansSlice from './plans';
export default {
  channelsSlice,
  usersSlice,
  locationSlice,
  skillsSlice,
  departmentsSlice,
  actionJobsSlice,
  allUsersSlice,
  associatesSlice,
  managersSlice,
  pendingUsersSlice,
  removedUsersSlice,
  onlineUsersSlice,
  plansSlice,
};
