import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import RowComponent from './RowComponent';
import OpenShiftTableRow from './OpenShift/OpenShiftTableRow';

import styledConfirm from '../../../UserManagement/components/Header/styled';
import Modal from 'components/GeneralComponents/Modal';
import LinkUserComponent from './LinkUserComponent';
// import ShiftDetailComponent from './ShiftDetail/ShiftDetailComponent'; 
import ShiftDetailComponentContainer from './ShiftDetail/ShiftDetailComponentContainer';
import AddUnregisteredUserContainer from 'pages/UnregisteredUser/AddUnregisteredUserContainer';
import './TableComponent.scss';

import { Mixpanel } from '../../../../Mixpanel';
import DeleteShiftComponent from '../Header/DeleteShiftComponent';
import  SortableTableHeaderComponent from 'pages/ScheduleTab/SortableTableHeaderComponent';
import utils from 'pages/ScheduleCalendar/duck/utils';
import service from 'pages/ScheduleCalendar/duck/service';

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

class TableComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      debug: true,
      unRegisteredUsers: [],
      showLinkScheduleModal: false,
      showShiftDetailModal: false,
      showDeleteShiftModal: false,
      showAddUnregUserModal: false,
      sortByDayOfWeek: 0,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  selectedUnregisteredUserToLink = (user) => {
    this.setState({
      showLinkScheduleModal: true,
    })
    this.userToLink = user;
  };

  cancelLinkUser = () => {
    this.setState({ showLinkScheduleModal: false });
  }

  selectedDateToAddShift = (user, date) => {
    this.shiftToView = null;
    this.userToAddShiftTo = user;
    this.selectedDate = date;
    console.log(`User: ${user?.first_name} ${user?.last_name}`);

    this.setState({ showShiftDetailModal: true })
  }

  selectedShiftToViewDetail = (shift, date) => {
    this.shiftToView = shift;
    this.selectedDate = date;
    this.userToAddShiftTo = null;
    this.setState({ showShiftDetailModal: true })
  }

  prepareToDeleteShift = (scheduleId, locationId) => {
    this.deleteShiftId = scheduleId;
    this.deleteShiftLocationId = locationId;
    this.setState({
      showDeleteShiftModal: true,
    })
  }

  cancelAddShift = () => {
    this.setState({ showShiftDetailModal: false });
  }

  cancelDeleteShift = () => {
    this.setState({ showDeleteShiftModal: false });
  }

  handleAddTeamMemberClick = (e) => {
    this.setState({ showAddUnregUserModal: true });
    Mixpanel.track('Clicked add user on schedule');
  }

  handleCloseAddTeamModal = (e) => {
    this.setState({ showAddUnregUserModal: false });
  }

  handleSortableHeaderClick = (sortByDay) => {
    this.setState( {sortByDayOfWeek: sortByDay} );
  };

  keyForUser = (user) => {
    if( user == null) {
      return 'null';
    }else{
      if( user.id == null ){
        return user.unregistered_user_id;
      }else{
        return user.id;
      }
    }
  }

  choseShiftToCopy = (shift) => {
    const {notifyHeaderOfShiftPasting} = this.props;
    this.setState({shiftToCopy: shift, isCopyingShift: true});
    notifyHeaderOfShiftPasting(true, shift);
  }

  render() {
    const {handleTooltipShow, handleTooltipHide, isCopyingShift, shiftToCopy,  
      shouldShowAddMemberButton, handleShowSMSInviteModal, shifts, loading, allUsers, locationSettings, channels, locationId, locationData, 
      rangeStart, rangeEnd, linkUnregisteredUser, deleteShiftDispatch, unregisteredUsers, openShiftHoursResult } = this.props;
    const { sortByDayOfWeek, showLinkScheduleModal, showShiftDetailModal, showDeleteShiftModal, showSendEmailModal, showAddUnregUserModal } = this.state;

    const addTeamMemberText = (<FormattedMessage id="ScheduleCalendarComponent.addTeamMember" defaultMessage="+ Add Team Member" />);

    const sortedUsers = utils.sortUsersWithShiftOrder(rangeStart, sortByDayOfWeek, shifts, allUsers, unregisteredUsers);
    const matrix = service.shiftMatrixMappedToUsers(shifts, sortedUsers, rangeStart, locationSettings.cfg__inventory_show)

    const sortedRows = sortedUsers.map( (user) => { 
      const userShiftsForTheWeek = service.getUserWeeklyShiftsFromMatrix(user, matrix)
      return ( rangeStart && user &&
        <RowComponent
          key={this.keyForUser(user)}
          userShiftsForTheWeek = {userShiftsForTheWeek}
          user={user}
          allUsers={allUsers}
          locationId={locationId}
          weekRangeStart={rangeStart}
          weekRangeEnd={rangeEnd}
          selectedUnregisteredUserToLink={this.selectedUnregisteredUserToLink}
          selectedDateToAddShift={this.selectedDateToAddShift}
          selectedShiftToViewDetail = {this.selectedShiftToViewDetail}
          handleShowSMSInviteModal={handleShowSMSInviteModal}
          isCopyingShift = {isCopyingShift}
          shiftToCopy = {shiftToCopy}
          handleTooltipHide = {handleTooltipHide}
          handleTooltipShow = {handleTooltipShow}
          channels = {channels} />)
    });

    const shouldShowOpenShiftUI = (locationSettings.cfg__shift__post_open_shift == true)

    return (
      <section className="calendar-table__wrap">

        <LoadingLayer loading={loading} />
        <table className={`calendar-table ${loading ? 'loading' : ''}`}>
          <SortableTableHeaderComponent shouldShowOpenShiftUI={shouldShowOpenShiftUI} rangeStart = {rangeStart} rangeEnd = {rangeEnd} handleSortableHeaderClick={this.handleSortableHeaderClick} sortByDayOfWeek={sortByDayOfWeek}/>

          { rangeStart && shouldShowOpenShiftUI && <OpenShiftTableRow 
              shifts={shifts} 
              locationSettings={locationSettings} 
              weekRangeStart={rangeStart} 
              weekRangeEnd={rangeEnd}
              handleTooltipHide={handleTooltipHide} 
              handleTooltipShow={handleTooltipShow}
              selectedDateToAddShift = {this.selectedDateToAddShift}
              selectedShiftToViewDetail = {this.selectedShiftToViewDetail}
              channels = {channels}
              locationData = {locationData}
              openShiftHoursResult = {openShiftHoursResult}
              isCopyingShift = {isCopyingShift}
              shiftToCopy = {shiftToCopy}
          />}
          { shouldShowOpenShiftUI && <tbody className='filler-row'>
            <tr className="calendar-row">
              <td colSpan={8}>
                <label className='filler-label fs14'>Team Members</label>
              </td> 
            </tr>
          </tbody>}

          <tbody> {sortedRows} </tbody>
          <tbody>
            { shouldShowAddMemberButton && <button className='inv-btn averta-semibold' onClick={(e) => { this.handleAddTeamMemberClick(); }}> {addTeamMemberText} </button> }
          </tbody>
        </table>

        {showLinkScheduleModal && <Modal>
          <LinkUserComponent
            allUsers={allUsers}
            shifts={shifts}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            unregisteredUserToLink={this.userToLink}
            cancelModal={this.cancelLinkUser}
            linkUnregisteredUser={linkUnregisteredUser} > </LinkUserComponent>
        </Modal>}

        {showShiftDetailModal && <Modal>
          <ShiftDetailComponentContainer
           date={this.selectedDate}
           cancelModal={this.cancelAddShift}

           presentedShift ={this.shiftToView}
           prepareToDeleteShift={this.prepareToDeleteShift}
           prepareToCopyShift={this.choseShiftToCopy}

           userToAddShiftTo={this.userToAddShiftTo}

           shiftMatrix = {matrix}
           weekRangeStart={rangeStart}
           handleTooltipHide = {handleTooltipHide}
           handleTooltipShow = {handleTooltipShow}
           showOpenShiftOption = {shouldShowOpenShiftUI} />

        </Modal>}

        {showDeleteShiftModal && <Modal>
          <DeleteShiftComponent
            deleteShiftId={this.deleteShiftId}
            deleteShiftLocationId={this.deleteShiftLocationId}
            cancelModal={this.cancelDeleteShift}
            deleteShiftDispatch={deleteShiftDispatch}
          > </DeleteShiftComponent>
        </Modal>}

        {showAddUnregUserModal === true && <Modal> <AddUnregisteredUserContainer
          locationId={locationId}
          handleCloseAddTeamModal={this.handleCloseAddTeamModal}
          locationData={locationData}
        /> </Modal>}

        <styledConfirm.Overlay display={(showLinkScheduleModal || showShiftDetailModal || showDeleteShiftModal || showSendEmailModal || showAddUnregUserModal) ? 'block' : 'none'} />

      </section>
    );
  }
}

export default injectIntl(TableComponent);
