import { createSlice } from '@reduxjs/toolkit';

const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState: {
    start: null,
    end: null,
    startStr: '',
    needReset: false,
  },
  reducers: {
    
    updateDateRangeInSlice: (state, action) => {
      state.start = action.payload.from;
      state.end = action.payload.to;
      state.startStr = action.payload.startStr;
    },

    flagForReset: (state, action) => {
      state.needReset = true;
    },

    clearResetFlag: (state, action) => {
      state.needReset = false;
    }
  },
});

export default dateRangeSlice;
