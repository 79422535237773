import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';

import Modal from 'components/GeneralComponents/Modal';

import { getUserInfoNonAdmin, } from 'helpers/api-calls/user-calls';
import UserCell from './Cell/UserCell';
import StatusCell from './Cell/StatusCell';
import Confirm from './Confirm';
import ConfirmStep2 from './ConfirmStep2';
import constants from '../../constants';

import './UserRowShimmer.scss';
import styled from './styled';

class UserRowShimmer extends React.Component {
	constructor(props) {
    super(props);

    this.state ={
      debug: false,
    };
  }

  componentDidMount () {
    
  	this._isMounted = true;

  }

  componentDidUpdate (prevProps, prevState) {
    const { userActionTypes, } = constants;



  }

  componentWillUnmount () {

  	this._isMounted = false;
    window.removeEventListener('scroll', this.handleScrolled);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }



  render() {
  	return (
  		<tr className="user-row__row">
        <td className="user-row__checkbox">
          <span className="checkbox-cell"></span>
        </td>
        <td className="user-row__owner-cell">
          <span className="general-cell"/>
        </td>
        <td className="user-row__user-role">
          <span className="general-cell"/>
        </td>
        <td className="user-row__status-cell">
          <span className="general-cell"/>
        </td>
        <td className="user-row__departments-wrap">
          <span className="general-cell"/>
        </td>
        <td className="user-row__departments-wrap">
         <span className="general-cell"/>
        </td>
        <td className="user-row__departments-wrap">
          <span className="general-cell"/>
        </td>
        <td className="user-row__departments-wrap">
         <span className="general-cell"/>
        </td>
        <td className="user-row__departments-wrap">
          <span className="general-cell"/>
        </td>
      </tr>
  	);
  }

}

UserRowShimmer.propTypes = {
  //notifyUserSelected: PropTypes.func.isRequired,
};

export default injectIntl(UserRowShimmer);

