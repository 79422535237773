import axios from 'axios';
import { protectedAxiosInstance, zeroParamProtectedAxiosInstance, singleLevelProtectedAxiosInstance } from 'helpers/api-calls';
import { createApiDateFormat } from '../date-time';

import { perfShiftCallKeys, shiftCallKeys, PENDING_SHIFTS_PER_PAGE } from 'constants/index';
import { getLocationCode, getLocaleCookie } from '../cookies';

const shiftApiUrl = '/api/organization/schedule_elements/can_admin';
const shiftNames = [
  'shift',
  'shift_vto',
  'open_shift',
];

/**
 * Get pending shifts
 * @param { Int } per_page - the amount of shifts to be returned
 * @param { Int } page - which page of shifts needs to be returned
 *
 * @return { Object } this contains the stats for the shifts (total count, current page, etc)
 *                    and then an array of the shifts
 */
export const LoadFirstPagePendingShifts = () => {
  const location_id = getLocationCode('location_id');
  const locale = getLocaleCookie();
  const associated_location_id = location_id;
  return protectedAxiosInstance.get(
    '/api/organization/schedule_elements/can_admin',
    {
      params: {
        page: 1,
        //per_page: 1000,
        per_page: PENDING_SHIFTS_PER_PAGE,
        //pending: true,
        order: 'start_at_asc',
        not_expired: true,
        location_id,
        //associated_location_id,
        locale,
        'response[only]': perfShiftCallKeys,
        name_is: shiftNames,
        'response[keep]': 'location',
      },
    }
  );
};

/**
 * Get more pending shifts on specified page
 * @param { Int } per_page - the amount of shifts to be returned
 * @param { Int } page - which page of shifts needs to be returned
 *
 * @return { Object } this contains the stats for the shifts (total count, current page, etc)
 *                    and then an array of the shifts
 */
export const LoadMorePendingShifts = (pageNum, perPageNum) => {
  const location_id = getLocationCode('location_id');
  const locale = getLocaleCookie();
  const associated_location_id = location_id;
  return protectedAxiosInstance.get(
    '/api/organization/schedule_elements/can_admin',
    {
      params: {
        page: pageNum,
        per_page: perPageNum,
        order: 'start_at_asc',
        not_expired: true,
        location_id,
        locale,
        'response[only]': perfShiftCallKeys,
        name_is: shiftNames,
        'response[keep]': 'location',
      },
    }
  );
};

/**
 * Approve a shift
 * @param { String | Int } id - id of the schedule element
 * @param { String | Int } configId - id of the posted shift
 */
export const ApproveShift = (id, configId) =>
  protectedAxiosInstance.put(
    `/api/customer/user_role/schedule_elements/${id}/approve`,
    {
      params: {
        approve_config_id: configId,
        'response[only]': shiftCallKeys,
      },
    }
  );

/**
 * Reject a shift
 * @param { String | Int } id - id of the schedule element
 */
export const RejectShift = (id) =>
  protectedAxiosInstance.put(
    `/api/customer/user_role/schedule_elements/${id}/reject`,
    {
      repost: 'false',
      'response[only]': shiftCallKeys,
    }
  );

export const GetShift = (id) =>
  protectedAxiosInstance
    .request({
      url: `/api/organization/schedule_elements/${id}`,
      method: 'GET',
    })
    .then((response) => response);

export const GetPost = (id) =>
  protectedAxiosInstance
    .request({
      url: `/api/organization/posts/${id}`,
      method: 'GET',
      'response[only]': shiftCallKeys,
    })
    .then((response) => response);

export const LoadManagedShifts = (from, to) => {
  const location_id = getLocationCode('location_id');
  return protectedAxiosInstance.request({
    method: 'GET',
    url: shiftApiUrl,
    params: {
      page: 1,
      per_page: 100,
      status_is: ['rejected', 'approved'],
      order: 'start_at_asc',
      location_id,
      managed_after: from,
      managed_before: to,
      'response[only]': perfShiftCallKeys,
      name_is: shiftNames,
      'response[keep]': 'location',
    },
  });
};

export function postShiftGroup(data) {
  return protectedAxiosInstance
    .post(
      '/api/customer/user_role/schedule_elements/one_point_five_create',
      data
    )
    .then((response) => {
      const returnedGroup = data;

      returnedGroup.id = response.data.uuid;

      return returnedGroup;
    });
}

// https://customer-api-doc.myshyft.com/?version=latest#60a4a80f-73d4-498e-af60-edf19d052520
export const PostBulkShifts = (data) => {
  return protectedAxiosInstance.post(
    '/api/customer/user_role/schedule_elements/one_point_five_create',
    data
  );
};

/**
 * @param  { Array } ids - array of shift batch ids, corresponding to each group being posted
 */
export const CheckBulkShiftStatus = (ids) =>
  protectedAxiosInstance
    .get('/api/customer/request_jobs', {
      params: {
        uuid_is: ids.join(','),
      },
    })
    .then((response) => {
      const map = {};

      response.data.request_jobs.forEach((element) => {
        const data = {
          success_count: element.success_count,
          total_count: element.total_count,
          failed_count: element.failed_count,
        };

        map[element.uuid] = data;
      });

      return map;
    });

export const getShiftSegments = (id) =>
  protectedAxiosInstance.get(
    `/api/customer/user_role/schedule_elements/${id}/segments`
  );

export const getShiftApplicants = (id) =>
  protectedAxiosInstance.get(
    `/api/customer/user_role/schedule_elements/${id}/get_current_applicants`
  );

export const getSingleShiftApplicant = (shiftId, applicantId) =>
  protectedAxiosInstance.get(
    `/api/customer/user_role/applicants/${applicantId}?response[keep]=badge_id,internal_id,available, user_location_week_hours`
  );
/**
 * Deny an applicant
 * @paran { String | Int} shift id 
 * @param { String | Int } applicant id
 */
export const DenyApplicant = (shiftId, applicantId) =>
  zeroParamProtectedAxiosInstance.request({
    url: `/api/customer/user_role/schedule_elements/${shiftId}/deny_applicant?applicant_id=${applicantId}`,
    method: 'PUT',
  });

/**
 * Pick an applicant
 * @paran { String | Int} shift id 
 * @param { String | Int } applicant id
 */
export const PickApplicant = (shiftId, applicantId) =>
  singleLevelProtectedAxiosInstance.request({
    url: `/api/customer/user_role/schedule_elements/${shiftId}/pick_applicant?applicant_id=${applicantId}`,
    method: 'PUT',
  });

  /*
  curl https://sp3-service.myshyft.com/api/customer/user_role/applicants/19748 --header "Session-uukey: 13717b79-8171-45df-a034-725cbbb398cc"
  */

export const RefreshSingleShiftApplicant = (applicantId) =>
  protectedAxiosInstance.get(
    `/api/customer/user_role/applicants/${applicantId}`
  );

export const loadCompactShiftsInDateRange = (from, to, pageNum, perPageNum, includeNonPublicInventory) => {
  const location_id = getLocationCode('location_id');
  const locale = getLocaleCookie();
  return protectedAxiosInstance.request({
    method: 'GET',
    url: '/api/compact/schedule_elements/all',
    params: {
      page: pageNum,
      per_page: perPageNum,
      start_after: createApiDateFormat(from),
      start_before: createApiDateFormat(to),
      include_non_public_inventory: includeNonPublicInventory,
      order: 'start_at_asc',
      location_id,
    },
  });
};



export const UpdatePublishedOpenShiftCall = (shiftId, totalAvailable, content, position) => {
  const payload = {
    "schedule_element": {
      "cached_primary_job": position,
      "total_shifts_available": totalAvailable,
      "shift_content": content,
    }
  };

  return protectedAxiosInstance.request({
    url: `api/customer/user_role/schedule_elements/${shiftId}?response[put_return_resource]=true`,
    method: 'PUT',
    data: payload
  });
};