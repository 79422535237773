import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { createFullName, truncateString } from 'helpers/formatting';
import { fullDate, startEndRange } from 'helpers/date-time';
import { dashboardShapes } from '../duck';

import ShiftRequest from '../blocks/ShiftRequest';
import calendarIcon from './assets/calendar.svg';
import clockIcon from './assets/clock.svg';
import segmentsIcon from './assets/segments.svg';
import speechIcon from './assets/speech-bubble.svg';
import Modal from './Modal';
import RequestDetails from './RequestDetails'
import { GetUserRequestTimeOffBalance, GetLocationRequestTimeOffs, } from 'helpers/api-calls/rto-calls';

import styled from './styled';

import {Mixpanel} from '../../../Mixpanel';

class RequestTimeOff extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: false,

      showDetails: false,
      rtoParams: null,
      organicRTO: false,
      myPrivilege: null,
      tags: [],
      rtoTypes: [],
    };

    this.messages = defineMessages({
      floating: {
        id: 'RequestTimeOff.floating',
        defaultMessage: 'Floating Holiday',
      },
      diversity: {
        id: 'RequestTimeOff.diversity',
        defaultMessage: 'Diversity Day',
      },
      paidTimeOff: {
        id: 'RequestTimeOff.pto',
        defaultMessage: 'Paid Time Off',
      },
      
    });

    this._isMounted = false;

    this.handleViewDetailsClick = this.handleViewDetailsClick.bind(this);
  }

  componentDidMount () {
    const {rto, totalRTOs, userPrivileges, locationSettings, costCenterData, intl} = this.props;

    this._isMounted = true;

    if(locationSettings) {
      if(locationSettings.cfg__rto_balance === 0) {
        if(this._isMounted) {
          this.setState({organicRTO: true});
        }
      }
    }

    let types = [];
    if(costCenterData) {
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'rto_categories'){
          if(this._isMounted) {
            this.setState({tags: costCenterData.tag_groups[i].tags});
          }
          if(costCenterData.tag_groups[i].tags.length > 0) {
            //let types = [];
            for(let j=0; j<costCenterData.tag_groups[i].tags.length; j++) {
              let type = {index: 0, key: '', content: ''};
              type.index = j;
              type.key = costCenterData.tag_groups[i].tags[j].tag_key;
              type.content = costCenterData.tag_groups[i].tags[j].tag_content;
              types.push(type);
            }
            if(this._isMounted) {
              this.setState({rtoTypes: types});
            }
          }
        }
      }
    }
    /*
    const idx = userPrivileges.findIndex(
     (userPrivilege) => userPrivilege.owner_id === rto.user_id
    );

    if(idx >= 0) {
      if(this._isMounted) {
        this.setState({myPrivilege: userPrivileges[idx]});
      }
      GetUserRequestTimeOffBalance(userPrivileges[idx].id, rto.id).then((data) =>{
        let rtoData = {
            category: '', 
            hours: 0, 
            balance: 0, 
            inQueue: 0,
            remaining: 0,
            status: '',
          };
        let balance = 0;
        let inQueue = 0;
        let remaining = 0;
        let hours = 0;
        if(!data.data.rto_balances) {
          if(totalRTOs) {
            const output = this.handleRTOData(totalRTOs);
            if(this._isMounted) {
              this.setState({rtoParams: output});
            }
          }
        }else {
          const n = types.findIndex(
            (type) => type.key === rto.category
          );

          if(n < 0) {
            const msg = `rto ${rto.id}, category = ${rto.category}, type not found.`;
            console.log(msg);
            return;
          }
          rtoData.category = types[n].content;
          balance = data.data.rto_balances[types[n].key];
          inQueue = data.data.rto_in_queue[types[n].key] ? data.data.rto_in_queue[types[n].key] : 0;
          
          hours = rto.day_hours * rto.days;
          if(inQueue == null){
            remaining = balance - hours;
          }else {
            remaining = balance - inQueue - hours;
          }
          
          rtoData.hours = hours;
          rtoData.balance = balance;
          rtoData.inQueue = inQueue ? inQueue : 0;
          rtoData.remaining = remaining;
          if(this._isMounted) {
            this.setState({rtoParams: rtoData});
          }
        }
      }).catch((error) =>{
        const msg = `componentDidMount:   calling GetUserRequestTimeOffBalance returns error: ${error}`;
        console.log(msg);
      });
    }else { //idx < 0, user privilege not found.
      if(totalRTOs) {
        const output = this.handleRTOData(totalRTOs);
        if(this._isMounted) {
          this.setState({rtoParams: output});
        }
      }
    }
    */
  }

  componentDidUpdate (prevProps) {
    const {rto, totalRTOs, userPrivileges, intl} = this.props;
    const { rtoTypes, } = this.state;
    /*
    if(!this.state.rtoParams) {
      const idx = userPrivileges.findIndex(
      (userPrivilege) => userPrivilege.owner_id === rto.user_id);
      if(idx >= 0) {
        if(this._isMounted) {
          this.setState({myPrivilege: userPrivileges[idx]});
        }
        GetUserRequestTimeOffBalance(userPrivileges[idx].id, rto.id).then((data) =>{
          let rtoData = {
            category: '', 
            hours: 0, 
            balance: 0, 
            inQueue: 0,
            remaining: 0,
            status: '',
          };
          let balance = 0;
          let inQueue = 0;
          let remaining = 0;
          let hours = 0;
          if(!data.data.rto_balances) {
            if(totalRTOs) {
              const output = this.handleRTOData(totalRTOs);
              if(this._isMounted) {
                this.setState({rtoParams: output});
              }
            }
          }else {
            const n = rtoTypes.findIndex(
              (type) => type.key === rto.category
            );

            if(n < 0) {
              const msg = `rto ${rto.id}, category = ${rto.category}, type not found.`;
              console.log(msg);
              return;
            }
            rtoData.category = rtoTypes[n].content;
            balance = data.data.rto_balances[rtoTypes[n].key];
            inQueue = data.data.rto_in_queue[rtoTypes[n].key] ? data.data.rto_in_queue[rtoTypes[n].key] : 0;
            hours = rto.day_hours * rto.days;
            if(inQueue == null){
              remaining = balance - hours;
            }else {
              remaining = balance - inQueue - hours;
            }
            
            rtoData.hours = hours;
            rtoData.balance = balance;
            rtoData.inQueue = inQueue ? inQueue : 0;
            rtoData.remaining = remaining;
            if(this._isMounted) {
              this.setState({rtoParams: rtoData});
            }
          }// end of if(!data.data.rto_balance)
        }).catch((error)=>{
          console.log(error);
        });
      }// end of if(idx >= 0)
    }// end of if(!this.state.rtoParams)
    */
  }    

  componentWillUnmount () {
    this._isMounted = false;
  }


  handleRTOData = (totalRTOs) => {
    const { rto, } = this.props;
    const { rtoTypes, } = this.state;
    let rtoData = {
      category: '', 
      hours: 0, 
      balance: 0, 
      inQueue: 0,
      remaining: 0,
      status: '',
    };
    let balance = 0;
    let inQueue = 0;
    let remaining = 0;
    let hours = 0;
    const rtos = totalRTOs;
    const idx = rtos.findIndex((item) => item.id === rto.id);
    if(idx >= 0) {
      const foundRTO = rtos[idx];
      rtoData.id = rto.id;
      rtoData.status = rto.status;
      rtoData.category = rto.category;
      
      hours = foundRTO.day_hours * foundRTO.days;
      if(foundRTO && foundRTO.balances_at_closed) {
        const n = rtoTypes.findIndex(
          (type) => type.key === rto.category
        );

        if(n < 0) {
          console.log('RTO type not found.')
          return;
        }
        balance = foundRTO.balances_at_closed.rto_balances[rtoTypes[n].key];
        inQueue = foundRTO.balances_at_closed.rto_in_queue[rtoTypes[n].key] ? foundRTO.balances_at_closed.rto_in_queue[rtoTypes[n].key] : 0;
      }else {
        const n = rtoTypes.findIndex(
          (type) => type.key === rto.category
        );
        if(n < 0) {
          const msg = `rto ${rto.id}, category = ${rto.category}, type not found.`;
          console.log(msg);
          return;
        }
        rtoData.category = rtoTypes[n].content;
      }
      if(inQueue == null){
        remaining = balance - hours;
      }else {
        remaining = balance - inQueue - hours;
      }
      rtoData.hours = hours;
      rtoData.balance = balance;
      rtoData.inQueue = inQueue;
      rtoData.remaining = remaining;
      /*
      if(this._isMounted) {
        this.setState({rtoParams: rtoData});
      }
      */
      return rtoData;
    }
  };

  handleAction = () => {
    const { currentAction } = this.state;
    const { locationConfig, shift, util } = this.props;
    const configId =
      locationConfig && locationConfig.custom_text ? locationConfig.id : null;

    switch (currentAction) {
      case 'approve':
        util.handleApprove(shift, configId);
        break;
      case 'deny':
        util.handleDeny(shift);
        break;
      default:
        break;
    }

    // this.handleCancel();
  };

  

  handleCancel = () => {
    if(this._isMounted) {
      this.setState({
        confirming: false,
        currentAction: '',
      });
    }
  };

  handleViewDetailsClick = () => {
    const {rto, totalRTOs, userPrivileges, intl} = this.props;
    const { rtoTypes, } = this.state;

    const idx = userPrivileges.findIndex(
     (userPrivilege) => userPrivilege.owner_id === rto.user_id
    );

      Mixpanel.track('View RTO detail');

    if(idx >= 0) {
      if(this._isMounted) {
        this.setState({myPrivilege: userPrivileges[idx]});
      }
      GetUserRequestTimeOffBalance(userPrivileges[idx].id, rto.id).then((data) =>{
        let rtoData = {
            category: '', 
            hours: 0, 
            balance: 0, 
            inQueue: 0,
            remaining: 0,
            status: '',
          };
        let balance = 0;
        let inQueue = 0;
        let remaining = 0;
        let hours = 0;
        if(!data.data.rto_balances) {
          if(totalRTOs) {
            const output = this.handleRTOData(totalRTOs);
            if(this._isMounted) {
              this.setState({rtoParams: output});
            }
          }
        }else {
          const n = rtoTypes.findIndex(
            (type) => type.key === rto.category
          );

          if(n < 0) {
            const msg = `rto ${rto.id}, category = ${rto.category}, type not found.`;
            console.log(msg);
            return;
          }
          const myType = rtoTypes[n];
          /*
          rtoData.category = rtoTypes[n].content;
          balance = data.data.rto_balances[rtoTypes[n].key];
          inQueue = data.data.rto_in_queue[rtoTypes[n].key] ? data.data.rto_in_queue[rtoTypes[n].key] : 0;
          */
          rtoData.category = myType.content;
          balance = data.data.rto_balances[myType.key];
          inQueue = data.data.rto_in_queue[myType.key] ? data.data.rto_in_queue[myType.key] : 0;
          
          hours = rto.day_hours * rto.days;
          if(inQueue == null){
            remaining = balance - hours;
          }else {
            remaining = balance - inQueue - hours;
          }
          
          rtoData.hours = hours;
          rtoData.balance = balance;
          rtoData.inQueue = inQueue ? inQueue : 0;
          rtoData.remaining = remaining;
          if(this._isMounted) {
            this.setState({rtoParams: rtoData});
          }
        }
      }).catch((error) =>{
        const msg = `componentDidMount:   calling GetUserRequestTimeOffBalance returns error: ${error}`;
        console.log(msg);
      });
    }else { //idx < 0, user privilege not found.
      if(totalRTOs) {
        const output = this.handleRTOData(totalRTOs);
        if(this._isMounted) {
          this.setState({rtoParams: output});
        }
      }
    }


    if(this._isMounted) {
      this.setState({showDetails: !this.state.showDetails});
    }
  }

  closeModalWindow = () =>{
    if(this._isMounted) {
      this.setState({showDetails: false});
      Mixpanel.track('Close RTO detail');
    }
  }

  render() {
    const { rto, locationConfig, approveRequestTimeOff, 
      denyRequestTimeOff, userPrivileges, locationSettings, costCenterData, } = this.props;
    const { intl: { formatMessage } } = this.props;
    const { confirming, expanded, rtoParams, myPrivilege, rtoTypes, } = this.state;

    let name = '';
    let requestString = '';
    let dateRange = '';
    let timeRange = '';
    let startTime = '';
    let endTime = '';
    let jobTitle = '';
    let debugInfo = '';
    

    if(rto){
      name = `${rto.first_name} ${rto.last_name}`;
      //requestString = `${rto.first_name} ${rto.last_name} ${formatMessage(this.messages.request)}`;
      const startDate = moment(rto.start_date);
      const endDate = moment(rto.end_date);
      dateRange = `${startDate.format('ddd, MMM DD')} - ${endDate.format('ddd, MMM DD')}`;
      const startTime = moment(rto.start_time);
      const endTime = moment(rto.end_time);
      timeRange = `${startTime.format('h:mm A')} -${endTime.format('h:mm A')}`;
      //const hours = rto.day_hours ? (rto.days - 1) * 24 + rto.day_hours : rto.days * 24;
      debugInfo = `id: ${rto.id}`;
    }

    if(myPrivilege) {
      jobTitle = myPrivilege.position;
    }

    

    return (
      <ShiftRequest key={rto.id}>
        {this.state.debug && <ShiftRequest.Row>{debugInfo}</ShiftRequest.Row>}
        <ShiftRequest.Row>
            <styled.TopWrap>
            {/* Avatars and cover text */}
            <ShiftRequest.Row marginBottom="1rem">
              <styled.UserAvatar src={rto.profile_image_thumb_url}/>
              <styled.RequestText>
                <FormattedMessage
                  id="RequestTimeOff.request"
                  defaultMessage="{name} has requested time off"
                  values={{name,}}
                />
              </styled.RequestText>
              <styled.ViewDetailsButton
                onClick={this.handleViewDetailsClick}>
                <FormattedMessage
                  id="RequestTimeOff.viewDetails"
                  defaultMessage="View Details"
                />
              </styled.ViewDetailsButton>
              
            </ShiftRequest.Row>
          </styled.TopWrap>
        </ShiftRequest.Row>
        <ShiftRequest.Row>
           <styled.DateTimeWrap>
              <styled.Icon src={calendarIcon} />
              <styled.Text>
                {dateRange}
              </styled.Text>
              <styled.Icon src={clockIcon} />
              <styled.Text>
                {timeRange}
              </styled.Text>
            </styled.DateTimeWrap>
        </ShiftRequest.Row>
        {this.state.showDetails && <Modal>
            <RequestDetails
              closeWin={this.closeModalWindow}
              rto={rto}
              approveRequestTimeOff={approveRequestTimeOff}
              denyRequestTimeOff={denyRequestTimeOff}
              userPrivileges={userPrivileges}
              rtoParams={rtoParams}
              locationSettings={locationSettings}
              costCenterData={costCenterData}
              rtoTypes={rtoTypes}
            >
            </RequestDetails>
        </Modal>}
      </ShiftRequest>
    );
  }
}

RequestTimeOff.propTypes = {
  locationConfig: PropTypes.shape({}).isRequired,
  shift: dashboardShapes.shiftShape.isRequired,
  util: PropTypes.shape({
    fetchPendingShiftSegments: PropTypes.func,
    hasSegments: PropTypes.func,
    handleApprove: PropTypes.func,
    handleDeny: PropTypes.func,
    toggleConfirmationSheet: PropTypes.func,
  }).isRequired,
};

export default injectIntl(RequestTimeOff);
