import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { GetUserRequestTimeOffBalance, } from 'helpers/api-calls/rto-calls';
import { getUserInfo, } from 'helpers/api-calls/user-calls';

import styled from './styled';

import {Mixpanel} from '../../../Mixpanel';

class RequestDetails extends React.PureComponent {
	constructor(props) {
    super(props);

    this.state = {
      hide: true,
      confirming: false,
      currentAction: '',
      rtoHours: 0,
      rtoBalance: 0,
      rtoInQueue: 0,
      rtoRemaining: 0,
      rtoCategory: '',
      organicRTO: false,
      myPrivilege: null,
      myJobTitle: '',
      textBalance: '',
      textRemaining: '',
    };

    this.messages = defineMessages({
      floating: {
        id: 'RequestDetails.floating',
        defaultMessage: 'Floating Holiday',
      },
      diversity: {
        id: 'RequestDetails.diversity',
        defaultMessage: 'Diversity Day',
      },
      paidTimeOff: {
        id: 'RequestDetails.pto',
        defaultMessage: 'Paid Time Off',
      },
      
    });

    this._isMounted = false;
    this.handleClose = this.handleClose.bind(this);
    
  }

  componentDidMount () {
  	const {rto, userPrivileges, intl, rtoParams, locationSettings, costCenterData, rtoTypes, } = this.props;

  	this._isMounted = true;

  	if(locationSettings) {
      if(locationSettings.cfg__rto_balance === 0) {
        if(this._isMounted) {
          this.setState({organicRTO: true});
        }
      }
    }

    if(userPrivileges && userPrivileges.length > 0) {
	    const idx = userPrivileges.findIndex(
	      (userPrivilege) => userPrivilege.owner_id === rto.user_id);
	    if(idx >= 0) {
	      if(this._isMounted) {
	        this.setState({myPrivilege: userPrivileges[idx]});
	      }
	    }
	  }else {
	  	if(rto) {
        getUserInfo(rto.user_id).then((response) => {
          if(this._isMounted) {
            this.setState({myJobTitle: response.data.user.current_position});
          }
        }).catch((error) =>{
           const msg = `RequestDetails.componentDidMount: call getUserInfo failed. error: ${error}`;
           console.log(msg);
        });
      }
	  }

  	if(rtoParams) {
  		this.setState({rtoCategory: rtoParams.category});
	  	this.setState({rtoHours: rtoParams.hours ? rtoParams.hours.toFixed(2) : 0})
	  	this.setState({rtoInQueue: rtoParams.inQueue ? rtoParams.inQueue.toFixed(2) : 0});
	  	const valueBalance = rtoParams.balance ? rtoParams.balance.toFixed(2) : 0;
	  	this.setState({rtoBalance: valueBalance});
	    const valueRemaining = rtoParams.remaining ? rtoParams.remaining.toFixed(2) : 0;
	    this.setState({rtoRemaining: valueRemaining});
	    const textRTOBalance = (<FormattedMessage
		          id="RequestDetails.rtoBalance"
		          defaultMessage="{valueBalance} {valueBalance, plural, one {hour} other {hours}}"
		          values={{valueBalance,}}
		        />);
	    const textRTORemaining = (<FormattedMessage
		          id="RequestDetails.rtoRemaining"
		          defaultMessage="{valueRemaining} {valueRemaining, plural, one {hour} other {hours}}"
		          values={{valueRemaining,}}
		        />);
	    this.setState({textBalance: textRTOBalance});
	    this.setState({textRemaining: textRTORemaining});
  	}else {
  		if(rtoTypes && rtoTypes.length > 0) {
  			for(let i=0; i<rtoTypes.length; i++) {
  				if(rtoTypes[i].key === rto.category) {
  					this.setState({rtoCategory: rtoTypes[i].content});
  					break;
  				}
  			}
  		}
  		const valueBalance = 0;
	    const valueRemaining = 0;
	    const textRTOBalance = (<FormattedMessage
		          id="RequestDetails.rtoBalance"
		          defaultMessage="{valueBalance} {valueBalance, plural, one {hour} other {hours}}"
		          values={{valueBalance,}}
		        />);
	    const textRTORemaining = (<FormattedMessage
		          id="RequestDetails.rtoRemaining"
		          defaultMessage="{valueRemaining} {valueRemaining, plural, one {hour} other {hours}}"
		          values={{valueRemaining,}}
		        />);
	    this.setState({rtoBalance: textRTOBalance});
	    this.setState({rtoRemaining: textRTORemaining})

  	}
  }

  componentDidUpdate (prevProps) {
  	const {rto, userPrivileges, intl, rtoParams} = this.props;
  	const { rtoTypes, } = this.state;
  	if(prevProps.rtoParams !== rtoParams) {
	  	if(rtoParams) {
	  		this.setState({rtoCategory: rtoParams.category});
		  	this.setState({rtoHours: rtoParams.hours ? rtoParams.hours.toFixed(2) : 0})
		  	this.setState({rtoInQueue: rtoParams.inQueue ? rtoParams.inQueue.toFixed(2) : 0});
		  	const valueBalance = rtoParams.balance ? rtoParams.balance.toFixed(2) : 0;
		  	this.setState({rtoBalance: valueBalance});
		    const valueRemaining = rtoParams.remaining ? rtoParams.remaining.toFixed(2) : 0;
		    this.setState({rtoRemaining: valueRemaining});
		    const textRTOBalance = (<FormattedMessage
			          id="RequestDetails.rtoBalance"
			          defaultMessage="{valueBalance} {valueBalance, plural, one {hour} other {hours}}"
			          values={{valueBalance,}}
			        />);
		    const textRTORemaining = (<FormattedMessage
			          id="RequestDetails.rtoRemaining"
			          defaultMessage="{valueRemaining} {valueRemaining, plural, one {hour} other {hours}}"
			          values={{valueRemaining,}}
			        />);
		    this.setState({textBalance: textRTOBalance});
		    this.setState({textRemaining: textRTORemaining});
	  	}else {
	  		if(rtoTypes && rtoTypes.length > 0) {
	  			for(let i=0; i<rtoTypes.length; i++) {
	  				if(rtoTypes[i].key === rto.category) {
	  					this.setState({rtoCategory: rtoTypes[i].content});
	  					break;
	  				}
	  			}
	  		}
	  		const valueBalance = 0;
		    const valueRemaining = 0;
		    const textRTOBalance = (<FormattedMessage
			          id="RequestDetails.rtoBalance"
			          defaultMessage="{valueBalance} {valueBalance, plural, one {hour} other {hours}}"
			          values={{valueBalance,}}
			        />);
		    const textRTORemaining = (<FormattedMessage
			          id="RequestDetails.rtoRemaining"
			          defaultMessage="{valueRemaining} {valueRemaining, plural, one {hour} other {hours}}"
			          values={{valueRemaining,}}
			        />);
		    this.setState({rtoBalance: textRTOBalance});
		    this.setState({rtoRemaining: textRTORemaining})
	  	}
	  }

	  if(prevProps.userPrivileges !== userPrivileges) {
	  	if(userPrivileges && userPrivileges.length > 0) {
		  	const idx = userPrivileges.findIndex(
		      (userPrivilege) => userPrivilege.owner_id === rto.user_id);
		    if(idx >= 0) {
		      if(this._isMounted) {
		        this.setState({myPrivilege: userPrivileges[idx]});
		      }
		    }
		  }else {
		  	if(rto) {
	        getUserInfo(rto.user_id).then((response) => {
	          if(this._isMounted) {
	            this.setState({myJobTitle: response.data.user.current_position});
	          }
	        }).catch((error) =>{
	           const msg = `RequestDetails.componentDidMount: call getUserInfo failed. error: ${error}`;
	           console.log(msg);
	        });
	      }
		  }
	  }
  }

  componentWillUnmount () {
  	this._isMounted = false;
  }

  handleClose = (event) => {
    //callback function from parent
    this.props.closeWin && this.props.closeWin(event);
    event.preventDefault();
  }

  handleConfirm = (action) => {
    this.setState({confirming: true});
    this.setState({currentAction: action});
  };

  handleCancel = () => {
    this.setState({confirming: false});
    this.setState({currentAction: ''});
  };

  handleAction = () => {
    const { currentAction } = this.state;
    const {rto, approveRequestTimeOff, denyRequestTimeOff} = this.props;

    switch (currentAction) {
      case 'approve':
        approveRequestTimeOff(rto.id);
		Mixpanel.track('Approve RTO');
        break;
      case 'deny':
        denyRequestTimeOff(rto.id);
		Mixpanel.track('Deny RTO');
        break;
      default:
        break;
    }
    //callback function from parent
    this.props.closeWin && this.props.closeWin(event);
  };

  render () {
  	const {confirming, rtoBalance, rtoInQueue, rtoRemaining, rtoCategory, rtoHours, organicRTO, myPrivilege, myJobTitle, 
  					textBalance, textRemaining, } = this.state;
  	const {rto, rtoParams, } = this.props;

  	let dateRange = '';
    let timeRange = '';
    let startTime = '';
    let endTime = '';
    let hours = '';
    let jobTitle = '';
    let submittedTime = '';
    

    if(rto){
      const startDate = moment(rto.start_date);
      const endDate = moment(rto.end_date);
      const submit = moment(rto.created_at);
      dateRange = `${startDate.format('ddd, MMM DD')} - ${endDate.format('ddd, MMM DD')}`;
      const startTime = moment(rto.start_time);
      const endTime = moment(rto.end_time);
      timeRange = `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`;
      submittedTime = `${submit.format('MMM DD, YYYY @ h:mm A')}`;
    }

    if(myPrivilege) {
    	jobTitle = myPrivilege.position;
    }else {
    	jobTitle = myJobTitle;
    }

  	const winTop = `50px`; //`${(window.innerHeight - 612)/2}px`;
    const winLeft = `200px`; //`${(window.innerWidth - 1200)/2}px`;
    const winW = `1100px`;
    const winH = `400px`; //94.9% viewport height
    
    const renderActionButtons = () => {
    	if(rtoRemaining >= 0 || organicRTO) {
    		return (
    			<styled.BottomRow>
		  			<styled.ActionBtn
	              modifiers={['danger']}
	              onClick={() => {
	                confirming ? this.handleCancel() : this.handleConfirm('deny');
	              }}
	            >
	              {confirming ? (
	                <FormattedMessage
	                  id="RequestDetails.cancel"
	                  defaultMessage="Cancel"
	                />
	              ) : (
	                <FormattedMessage
	                  id="RequestDetails.deny"
	                  defaultMessage="Deny"
	                />
	              )}
	            </styled.ActionBtn>
	            <styled.ActionBtn
	              onClick={() => {
	                confirming
	                  ? this.handleAction()
	                  : this.handleConfirm('approve');
	              }}
	            >
	              {confirming ? (
	                <FormattedMessage
	                  id="RequestDetails.confirm"
	                  defaultMessage="Confirm"
	                />
	              ) : (
	                <FormattedMessage
	                  id="RequestDetails.approve"
	                  defaultMessage="Approve Request"
	                />
	              )}
	            </styled.ActionBtn>
            </styled.BottomRow>
    		);
    	}else {
	  			return (
	  				<styled.BottomRow>
	  					<styled.ActionBtn
		              modifiers={['danger']}
		              onClick={() => {
		                confirming ? this.handleCancel() : this.handleConfirm('deny');
		              }}
		            >
		              {confirming ? (
		                <FormattedMessage
		                  id="RequestDetails.cancel"
		                  defaultMessage="Cancel"
		                />
		              ) : (
		                <FormattedMessage
		                  id="RequestDetails.deny"
		                  defaultMessage="Deny"
		                />
		              )}
		            </styled.ActionBtn>
		            {confirming && <styled.ActionBtn
		              onClick={() => {
		                this.handleAction()
		              }}
		            >
	                <FormattedMessage
	                  id="RequestDetails.confirm"
	                  defaultMessage="Confirm"
	                />
		            </styled.ActionBtn>}
	          </styled.BottomRow>
  			);
    	}
    };
   
  	return (
  		 <styled.WinContainer top={winTop} left={winLeft} width={winW} height={winH}>
  		 	<styled.Header>
  		 		<styled.HeaderTitleWrap>
	  		 	<styled.HeaderText>
	  		 	<FormattedMessage
            id="RequestDetails.timeOffRequest"
            defaultMessage="Time Off Request"
          />
	  		 	</styled.HeaderText>
	  		 	<styled.SubmitText>
		  		 	<FormattedMessage
	            id="RequestDetails.submitTime1"
	            defaultMessage="Submitted: {time} "
	            values={{time: submittedTime}}
	          />
	  		 	</styled.SubmitText>
	  		 	</styled.HeaderTitleWrap>
		  		<styled.CloseButton
		          onClick={this.handleClose}>
		         X 
		      </styled.CloseButton>
	      </styled.Header>
	      <styled.TopRow>
	      	<styled.Element width="220px">
	      		<styled.ElementName>
	      			<FormattedMessage
		            id="RequestDetails.dateRange"
		            defaultMessage="Date Range"
		          />
	      		</styled.ElementName>
	      		<styled.ElementText>
	      			{dateRange}
	      		</styled.ElementText>
	      	</styled.Element>
	      	<styled.Element width="220px">
	      		<styled.ElementName>
	      			<FormattedMessage
		            id="RequestDetails.startEndTime"
		            defaultMessage="Start Time & End Time"
		          />
	      		</styled.ElementName>
	      		<styled.ElementText>
	      			{timeRange}
	      		</styled.ElementText>
	      	</styled.Element>
	      	<styled.Element width="120px">
	      		<styled.ElementName>
	      			<FormattedMessage
		            id="RequestDetails.jobTitle"
		            defaultMessage="Job Title"
		          />
	      		</styled.ElementName>
	      		<styled.ElementText>
		          {jobTitle}
	      		</styled.ElementText>
	      	</styled.Element>
	      	<styled.Element width="120px">
	      		<styled.ElementName>
	      			<FormattedMessage
		            id="RequestDetails.type"
		            defaultMessage="Type"
		          />
	      		</styled.ElementName>
	      		<styled.ElementText>
		          {rtoCategory}
	      		</styled.ElementText>
	      	</styled.Element>
	      	<styled.Element width="120px">
	      		<styled.ElementName>
	      			<FormattedMessage
		            id="RequestDetails.hours"
		            defaultMessage="Hours"
		          />
	      		</styled.ElementName>
	      		<styled.ElementText>
	      			<FormattedMessage
		          id="RequestDetails.paidTimeValue"
		          defaultMessage="{rtoHours} {rtoHours, plural, one {hour} other {hours}}"
		          values={{rtoHours,}}
		        />
	      		</styled.ElementText>
	      	</styled.Element>
	      </styled.TopRow>
	      {!organicRTO && <styled.MiddleRow>
	      	<styled.Element width="200px">
	      		<styled.ElementName>
	      			<FormattedMessage
		            id="RequestDetails.available"
		            defaultMessage="Available as of today"
		          />
	      		</styled.ElementName>
	      		<styled.ElementText>
	      			{textBalance}
	      		</styled.ElementText>
	      	</styled.Element>
	      	<styled.Element width="120px">
	      		<styled.ElementName>
	      			<FormattedMessage
		            id="RequestDetails.thisRequest"
		            defaultMessage="This Request"
		          />
	      		</styled.ElementName>
	      		<styled.ElementText>
	      			<FormattedMessage
			          id="RequestDetails.hoursValue"
			          defaultMessage="{rtoHours} {rtoHours, plural, one {hour} other {hours}}"
			          values={{rtoHours,}}
			        />
	      		</styled.ElementText>
	      	</styled.Element>
	      	<styled.Element width="250px">
	      		<styled.ElementName>
	      			<FormattedMessage
		            id="RequestDetails.pending"
		            defaultMessage="Pending/Approved Requests"
		          />
	      		</styled.ElementName>
	      		<styled.ElementText>
			        <FormattedMessage
				      id="RequestDetails.pendingValue"
				      defaultMessage="({rtoInQueue} {rtoInQueue, plural, one {hour} other {hours}})"
				      values={{rtoInQueue,}}
				    />
	      		</styled.ElementText>
	      	</styled.Element>
	      	<styled.Element width="250px">	
	      		<styled.ElementName>
	      			<FormattedMessage
		            id="RequestDetails.remaining"
		            defaultMessage="Remaining as of Today"
		          />
	      		</styled.ElementName>
	      		<styled.ElementText>
	      			{textRemaining}
	      		</styled.ElementText>
	      	</styled.Element>
	      </styled.MiddleRow>}
	      {renderActionButtons()}
  		 </styled.WinContainer>
  	);
  }

}

RequestDetails.propTypes = {
  //locationConfig: PropTypes.shape({}).isRequired,
  //shift: dashboardShapes.shiftShape.isRequired,
  
};

export default injectIntl(RequestDetails);