import Channels from './Channels/ChannelsContainer';
import Posts from './Posts/PostsContainer';
import Comments from './Comments/CommentsContainer';
import ReadReceipts from './ReadReceipts/ReadReceiptsContainer';
import Pins from './Pins/pins-container';
import TopLine from './TopLine/TopLineContainer';
import Likers from './Likers/LikersContainer';
import GroupDetails from './GroupDetails/GroupContainer';
import GroupMembers from './GroupMembers/GroupMembersContainer';
import Announcements from './Announcements/AnnouncementsContainer';

export default {
  Channels,
  Posts,
  Comments,
  ReadReceipts,
  Pins,
  TopLine,
  Likers,
  GroupDetails,
  GroupMembers,
  Announcements,
};
