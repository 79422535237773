import { protectedAxiosInstance } from 'helpers/api-calls';
import { formatOnlyProps } from 'helpers/formatting';


/**
 * Set basic user info
 * @param  {String} {user_id
 * @param  {String} email
 * @param  {String} password}
 */
export const updateUserAttributes = ({ user_id, email, password, locale }) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/users/${user_id}`,
    method: 'PUT',
    data: {
      user: {
        email,
        password,
        locale,
      },
    },
  });

/**
 * Get channels
 * @param  {Object} params - Object of query string params
 */
export const getChannels = (params) =>
  protectedAxiosInstance.request({
    url: '/api/customer/user_role/channels',
    method: 'GET',
    params,
  });

export const getUserInfo = (user_id) =>
  protectedAxiosInstance.request({
    url: `/api/organization/users/${user_id}`,
    method: 'GET',
    params: {
      user_id,
    },
  });

export const getUserPrivilege = (user_privilege_id) =>
  protectedAxiosInstance.request({
    url: `api/customer/user_role/user_privileges/${user_privilege_id}`,
    method: 'GET',
    params: {
      user_privilege_id,
    },
  });

export const getUserInfoNonAdmin = (user_id, location_id) =>
  protectedAxiosInstance.request({
    url: `api/customer/user_role/users/${user_id}`,
    method: 'GET',
    params: {
      user_id,
      location_id,
    },
  });

export const getChannelMembers = (channelId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/channels/${channelId}/member_users`,
    method: 'GET',
    params: {
      per_page: 10000,
    },
  });

export const GetLocationChannels = (location_id) =>
  protectedAxiosInstance.request({
    url: '/api/customer/user_role/channels',
    method: 'GET',
    params: {
      location_id,
      per_page: 100,
      type_is: ['custom_feed', 'location_feed', 'region_feed'],
      'response[only]': formatOnlyProps(`
        id,
        channel_name,
        updated_at,
        channel_type,
        channel_latest_content,
        member_count,
      `),
      'response[nested]': 1,
    }
  });


export const GetChannelsToPost = (location_id) =>
  protectedAxiosInstance.request({
    url: '/api/customer/user_role/channels',
    method: 'GET',
    params: {
      location_id,
      per_page: 100,
      type_is: ['custom_feed', 'location_feed', 'region_feed'],
      'response[only]': formatOnlyProps(`
        id,
        channel_name,
        channel_type,
        member_count,
      `),
      'response[nested]': 1,
    }
  });

/**
 * creata a channel
 * @param  {String} {channel_name
 * @param  {String} channel_frequency
 * @param  {String} description}
 */
export const createChannel = ({ channel_name, channel_frequency, description }) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/channels`,
    method: 'POST',
    data: {
      channel: {
        channel_name,
        channel_frequency,
        description,
      },
    },
  });

/**
 * send single sms invite
 * @param  {String} email 
 * @param  {String} fullName}
 * @param  {String} locationId}
 */
export const inviteCall = (email, fullName, locationId, link) => {
  const charactersToStrip = /[\s]/g;
  const strippedEmail = email.replace(charactersToStrip, '');
  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/locations/${locationId}/invitations/invite`,
    method: 'POST',
    data: {
      invitees: [{ "email": strippedEmail, "full_name": fullName }],
      app_hyperlink: link ? link : 'https://files.myshyft.com/public-files/download.html'
    },
  });
};

export const getUnregisteredUsers = (locationId) => {
 return protectedAxiosInstance.request({
    url: `/api/customer/user_role/locations/${locationId}/unregistered_users/unsettled?per_page=10000`,
    method: 'GET',
 });
};

export const removeUnregisteredUser = (unregisteredUserId) => {
 return protectedAxiosInstance.request({
    url: `/api/customer/user_role/unregistered_users/${unregisteredUserId}`,
    method: 'DELETE',
 });
}

export const createUnregisteredUser = (locationId, firstName, lastName, email) => {
 return protectedAxiosInstance.request({
    url: `/api/customer/user_role/unregistered_users`,
    method: 'POST',
    data: {
      unregistered_user : {
        location_id : locationId,
        first_name: firstName,
        last_name: lastName,
        email: email 
      }
    }
 });
}

export const updateUnregisteredUser = (unregisteredUserId, email) => {
  return protectedAxiosInstance.request({
     url: `/api/customer/user_role/unregistered_users/${unregisteredUserId}`,
     method: 'PUT',
     data: {
       unregistered_user : {
         email: email 
       }
     }
  });
 }

 export const updateUnregisteredUserBadgeID = (unregistered_user_id, badge_id) => {
  return protectedAxiosInstance.request({
     url: `/api/customer/user_role/unregistered_users/${unregistered_user_id}`,
     method: 'PUT',
     data: {
       unregistered_user : {
         internal_id: badge_id,
         badge_id: badge_id
       }
     }
  });
 }

export default {
  getUnregisteredUsers,
  createUnregisteredUser, 
  removeUnregisteredUser,
  updateUnregisteredUser,
  updateUnregisteredUserBadgeID
}