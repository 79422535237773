const draftShiftsToPublish = (shifts, includeOpenShifts) => {
    const publishableShifts = shifts.filter( (item) => 
      item.trade_status === 'unpublished_inventory' || 
      item.trade_status === 'unregistered_unpublished_inventory'  ||
      (item.trade_status === 'unpublished_posted' && includeOpenShifts)
  ); 
    return publishableShifts;
}

const isDraftShift = (shift) => {
  if( shift?.trade_status === 'unpublished_inventory' ||
      shift?.trade_status === 'unregistered_unpublished_inventory' ||
      shift?.trade_status === 'unpublished_posted' ) {
        return true;
    }else{
      return false;
    }
}

const isDraftOpenShift = (shift) => {
  if ( shift?.trade_status === 'unpublished_posted' )  {
    return true;
  }else{
    return false;
  }
}

export default {
    draftShiftsToPublish,
    isDraftShift,
    isDraftOpenShift,
}