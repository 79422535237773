import React from "react"
import presenter from "pages/ScheduleCalendar/duck/presenter"

export function DetailFieldPositionComponent ({defaultValue, handleJobTitleChange, disableField, shouldDisplayLengthWarning}) {

    return (
        <div>
            <div className="title-label">Position</div>
            <input disabled={disableField} className='job-box' type="text" onChange={handleJobTitleChange} defaultValue={defaultValue} />
            {shouldDisplayLengthWarning == true && <div className="warning">Job title too long (max 35 characters)</div>}
        </div>
    )
}

export default DetailFieldPositionComponent