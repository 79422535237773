import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { messageDateFormat, } from 'helpers/date-time';

import elements from '../../elements';
import blocks from '../../blocks';
import styled from './styled';
import './AnnouncementsComponent.scss';
import SimplePost from './SimplePost';

const sha1 = require('js-sha1');


class AnnouncementsComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={
      //closePinPane: false,
      channelMembers: [],
      location: null,
    };

    this._isMounted = false;

    this.handleClose = this.handleClose.bind(this);

    this.closePinPane = false;
  }

  componentDidMount() {
    const { active, activePost, allUsers, activeChannel, locations, locationId, posts, } = this.props;

    this._isMounted = true;

    if(activeChannel && allUsers) {
      const channelUsers = allUsers.filter((user) =>  
        user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
      if(channelUsers) {
        this.setState({channelMembers: channelUsers});
      }
    }

    if(locations && locations.length > 0) {
      const currentLocation = locations.filter((location) => location.id === parseInt(locationId));
      if(currentLocation && currentLocation.length > 0) {
        this.setState({location: currentLocation[0]});
      }
    }

    if(posts && posts.data) {
      const announcements = posts.data.filter((post) => this.isAnnouncement(post.post_type) === true);
      if(announcements && announcements.length > 0) {
        this.setState({announcements: announcements});
      }
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    const { active, activeChannel, allUsers, locations, locationId, } = this.props;

    if(prevProps.activeChannel !== activeChannel) {
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
        }
      }
    }

    if(prevProps.allUsers !== allUsers) {
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isAnnouncement(post_type) {
    return [1, 2, 3, 4].includes(post_type);
  }

  getUserUUID = (userId) => {
    return `user.${sha1(`user.${userId}`)}`;
  };

  handleClose(event) {
    const {resetActiveSidePane} = this.props;

    this.setState({closePinPane: false});
    this.closePinPane = true;
    resetActiveSidePane();
    event.preventDefault();
  }

  render () {
    const {pins, activeSidePane, allUsers, onlineUsers, activeChannel, locationSettings, active, userId, } = this.props;
    const { channelMembers, location, announcements, } = this.state;

    if(this.closePinPane){
      this.closePinPane = false;
      return null;
    }

    let numUsers = 0;
    if(channelMembers.length === 0) {
      if(activeChannel && activeChannel.member_count) {
        numUsers = activeChannel.member_count;
      }
    }else {
      numUsers = channelMembers.length;
    }

    const title = (<FormattedMessage id="AnnouncementsComponent.headerLabel" defaultMessage="Announcements" />);
    const channel_type = activeChannel ? activeChannel.channel_type : '';
    const members = (<FormattedMessage
              id="GroupComponent.members"
              defaultMessage="+{value} members"
              values={{ value: numUsers}}
            />);
    let avatarUrl = '';
    if(channel_type === 'location_feed') {
      if(location && location.avatar_blob) {
        avatarUrl = location.avatar_blob.blob_url;
      }else {
        avatarUrl = '/icons/channel avatar.svg';
      }
    }else if(channel_type === 'custom_feed') {
      avatarUrl = '/icons/channel avatar.svg';
    }else if(channel_type === 'region_feed') {
      avatarUrl = '/icons/channel avatar.svg';
    }
    // allowLikes: cfg__group_chat__likes,
    // allowCreatePost: cfg__group_chat__post__create,
    const announces = announcements && announcements.map((post, index) => {

      return (
        <SimplePost
          key={index}
          data={{
            ...post,
            final_allow_comment: locationSettings.cfg__group_chat__comments,
            final_allow_like: locationSettings.cfg__group_chat__likes,
            final_allow_delete: active.userIsAdmin || post.owner_id === userId, // Fixed bug WS-1279, WS-1262
          }}
          active={active.post.id === post.id || active.delete.id === post.id}
          allUsers={allUsers}
          onlineUsers={onlineUsers}
          index={index}
          activeChannel={activeChannel}
        >
        </SimplePost>
      );
    });
    

    return (
      <elements.ColumnWrap>
        <div className="group__wrap">
          <p className="group__title">{title}</p>
          <button className="group__close-button" onClick={this.handleClose}/>
        </div>
        <elements.ScrollWrap isLoading={false}>
          {announces}
        {false && <elements.Loading />}
        </elements.ScrollWrap>
      </elements.ColumnWrap>
    );

  }
}

AnnouncementsComponent.propTypes = {
  //pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(AnnouncementsComponent);

