import React from 'react';
import './PublishScheduleComponent.scss';
import '../../../../App/fonts.scss';
import '../../../../App/colors.scss';
import '../../../../App/layout.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Mixpanel } from '../../../../Mixpanel';
import { PublishDraftShiftCall } from 'helpers/api-calls/draft-calls';
import formatter from 'pages/ScheduleCalendar/duck/formatter';
import utils from 'pages/ScheduleCalendar/duck/utils';
import service from 'pages/ScheduleCalendar/duck/service';

class PublishScheduleComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmButtonEnabled: true,
      showingDropDown: false, 
      selectedNotifyOption: '1', 
    };
  }

  handleCancel = (event) => {
    const { cancelModal } = this.props;
    cancelModal();
    Mixpanel.track('Cancel publish schedule');
  };

  handleConfirm = (event) => {
    const { draftShiftsToPublish, cancelModal, locationId, publishDraftShiftsDispatch, notifyTeamOfPublishDispatch, message } = this.props;
    const { confirmButtonEnabled, selectedNotifyOption } = this.state;

    if (confirmButtonEnabled === false) {
      return;
    }

    cancelModal();

    if (message !== null) {
      publishDraftShiftsDispatch(draftShiftsToPublish);
      
      if( selectedNotifyOption == '1' ) {
        const usersToNotify = service.usersWithUnpublishedChanges(draftShiftsToPublish);

        // console.log(JSON.stringify(usersToNotify));
        if( usersToNotify.length > 0){
          notifyTeamOfPublishDispatch(locationId, message, 'calendar_schedule', usersToNotify);
          Mixpanel.track('Publish notify updates only', {'location_id': locationId});
        }

      }else if ( selectedNotifyOption == '2' ){
        notifyTeamOfPublishDispatch(locationId, message, 'calendar_schedule');
        Mixpanel.track('Publish notify everyone', {'location_id': locationId});

      }else {
        Mixpanel.track('Publish notify nobody', {'location_id': locationId});
      }
    }
  };

  // Toggle dropdown visibility
  handleDropDownClick = () => {
    this.setState((prevState) => ({
      showingDropDown: !prevState.showingDropDown,
    }));
  };

  closeDropdownList = () => {
    const {showingDropDown} = this.state;
    if( showingDropDown == true ){
      this.setState({ showingDropDown: false });
    }
  }

  // Handle dropdown item selection
  handleSelectNotifyOption = (option) => {
    this.setState({
      selectedNotifyOption: option,
      showingDropDown: false, // Close dropdown after selection
    });
  };

  render() {
    const {shouldShowOpenShiftUI, scheduledResult, openShiftResult, currentDateRangeStart, currentDateRangeEnd, shifts, allUsers, allUnregisteredUsers} = this.props;
    const { confirmButtonEnabled } = this.state;

    const numberOfHours = scheduledResult["WeekTotal"]

    const openShiftHours = openShiftResult["WeekTotalHours"]
    const openShiftSlots = openShiftResult["WeekTotalSlots"]

    const publish = <FormattedMessage id="Confirm.publish" defaultMessage="Publish" />;
    const publishTitleText = <FormattedMessage id="Scheduling.publishSchedule" defaultMessage="Publish Schedule" />;
    const dateRangeText = formatter.summaryDateRange(currentDateRangeStart, currentDateRangeEnd);

    const shiftsInSchedule = utils.shiftsInSchedule(shifts);
    const numberOfShifts = shiftsInSchedule.length;

    const summaryText = <FormattedMessage id="Scheduling.summary" defaultMessage="Summary: {dateRange}" values={{ dateRange: dateRangeText }} />;
    const totalShiftsText = <FormattedMessage id="Scheduling.totalShifts" defaultMessage="Total Scheduled Shifts: {num}" values={{ num: numberOfShifts }} />;
    const totalHoursText = <FormattedMessage id="Scheduling.totalHours" defaultMessage="Total Scheduled Hours: {hours}" values={{ hours: numberOfHours.toFixed(2) }} />;

    const totalOpenShiftHoursText = <FormattedMessage id="Scheduling.totalOSHours" defaultMessage="Total Open Shift Hours: {hours}" values={{ hours: openShiftHours.toFixed(2) }} />;
    const totalOpenShiftSlotsText= <FormattedMessage id="Scheduling.totalOSSlots" defaultMessage="Total Open Shift Shifts: {num}" values={{ num: openShiftSlots}} />;

    const notificationText = <FormattedMessage id="Scheduling.notification" defaultMessage="Notifications" />;
    const notifyBodyText1 = <FormattedMessage id="Scheduling.onlyNotifyChange" defaultMessage="Notify team members with changes only" />;
    const notifyBodyText2 = <FormattedMessage id="Scheduling.notifyAll" defaultMessage="Notify everyone on the team" />;
    const notifyBodyText3 = <FormattedMessage id="Scheduling.notifyNone" defaultMessage="Do not notify anyone" />;

    // Render the selected notification body text based on state
    let selectedNotifyText;
    switch (this.state.selectedNotifyOption) {
      case '1':
        selectedNotifyText = notifyBodyText1;
        break;
      case '2':
        selectedNotifyText = notifyBodyText2;
        break;
      case '3':
        selectedNotifyText = notifyBodyText3;
        break;
      default:
        selectedNotifyText = notifyBodyText1;
    }

    return (
      <div className="publish-schedule-container" onClick={this.closeDropdownList}> 

        <div className="title-row">
          <label className="title">{publishTitleText}</label>
          <button className="close-button" onClick={this.handleCancel}>
            <img src="icons/close-simple.svg" alt="close" />
          </button>
        </div>

        <div className='summary'>{summaryText}</div>
        <div className='total-shifts'>{totalShiftsText}</div>
        <div className='total-hours'>{totalHoursText}</div>
        { shouldShowOpenShiftUI && <div className='total-shifts'>{totalOpenShiftSlotsText}</div> }
        { shouldShowOpenShiftUI && <div className='total-hours'>{totalOpenShiftHoursText}</div> }
        
        <div className="notification-section">
          <div className='notif-label'>{notificationText}</div>

          <div className="dropdown" onClick={this.handleDropDownClick}> 
            <span>{selectedNotifyText}</span>
          </div>

          {this.state.showingDropDown && (
            <div className="dropdown-list">
              <div onClick={() => this.handleSelectNotifyOption('1')}>{notifyBodyText1}</div>
              <div onClick={() => this.handleSelectNotifyOption('2')}>{notifyBodyText2}</div>
              <div onClick={() => this.handleSelectNotifyOption('3')}>{notifyBodyText3}</div>
            </div>
          )}
        </div>

        <div className="confirm-button-wrap">
          <button
            className={confirmButtonEnabled ? 'confirm-button averta-semibold fs14px button-enabled' : 'confirm-button averta-semibold fs14px button-disabled'}
            style={{ outline: 0 }}
            onClick={this.handleConfirm}
          >
            {publish}
          </button>
        </div>
      </div>
    );
  }
}

export default injectIntl(PublishScheduleComponent);
