import { connect } from 'react-redux';
import ConfigurationPage from './ConfigurationPage';
import { loadLocationData } from '../../../actions/dashboard-actions'
import {settingsOperations} from '../duck';

const mapStateToProps = ({
  locationReducer,
}) => {
  return {
    locationId: locationReducer.location_code,
    locationData: locationReducer.location_data,
  };
};

const mapDispatchToProps = (dispatch) => {

  const  updateWorkWeekStartDay = (locationId, weekday) => {
    dispatch(settingsOperations.updateWorkweekStartDayOperation(locationId, weekday));
  };

  const loadLocationDataDispatch = () => {
    dispatch(loadLocationData());
  }

  return { 
    updateWorkWeekStartDay, 
    loadLocationDataDispatch
  };
};

const ConfigurationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationPage);

export default ConfigurationContainer;