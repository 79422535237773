import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 

const Overlay = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
`;

const ButtonWrap = styled.div`
  top: ${(props) => props.top || '0px'};
  //width: 180px;
  height: 30px;
  border-radius: 5px;
  position: absolute;
  right: 15px;
  //left: 205px;
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 3.5px;
  padding-right: 6px;
  //border: 1px solid red;

`;

const Button = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 20px;
  width: 20px;
  margin-right: 15px;

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    margin-left: 5px;
    //border: 1px solid blue;
  }

  &:last-child {
    margin-right: 0px;
    //border: 1px solid blue;
  }
  //border: 1px solid blue;
`;

const WinContainer = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  //width: ${(props) => props.width || '1200px'};
  //height: ${(props) => props.height || '612px'};
  position: absolute;
  // border-radius: 10px;
  // background-color: rgba(44,44,46,255);
  // box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2;
  // display: flex;
  // flex-direction: column;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  //border: 1px solid red;
  
`;

const Triangle = styled.span `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '0px'};
  position: absolute;
  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 10px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-top-color: rgba(44,44,46,255);

`;

const WinContainerMenu = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  position: absolute;
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  border-radius: 10px;
  z-index: 2;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  //border: 1px solid red;
  
`;

const MenuItem = styled.div`
  //height: 32px;
  background-image: ${(props) => (props.src ? `url(${props.src})` : 'none')};
  text-indent: ${(props) => (props.src ? '20px' : '0')};
  background-size: 18px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 8px;
  background-color: white;
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 14px;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 3px;
  //border: 1px solid red;

  &:hover {
    cursor: pointer;
    background-color: rgba(242,242,247,255);
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export default {
  WinContainer,
  Overlay,
  ButtonWrap,
  Button,
  Triangle,
  WinContainerMenu,
  MenuItem,
};
