import moment from 'moment';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';

import teamCalls from 'helpers/api-calls/team-calls';
import { getUserId, getUserRole, } from '../../../helpers/cookies';
import constants from '../constants';

import actions from './actions';
import { appActions } from 'App/duck';
import { GetLocationInnerDepartments, addDepartment, addDepartmentMember, removeDepartmentMember, } from 'helpers/api-calls/department-calls';
import { GetLocationUsableSkills, addUsersToSkill, removeSkillUsers, addSkill} from 'helpers/api-calls/skill-calls';
import { getChannelMembers, GetLocationChannels, createChannel, getUserInfoNonAdmin, } from 'helpers/api-calls/user-calls';
import feedCalls from 'helpers/api-calls/feed-calls';

const { statusTypes, userActionTypes, operationTypes, } = constants;

const fetchRemovedUsers = (locationId, pageNo, numPerPage) => (dispatch) => {
  const config = {
    location_id: locationId,
    page: pageNo,
    search_term: '',
    per_page: numPerPage,
  };
  dispatch(actions.requestRemovedUsers(true));
  return teamCalls.getRemovedTeamMembers(config).then((response) => {
    const payload = {
      users: response.data.user_privileges,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if (config.page === 1) {
      dispatch(actions.receiveRemovedUsers(payload));
    } else {
      dispatch(actions.appendRemovedUsers(payload));
    }
  }).catch((error) => {
    console.log(`fetchRemovedUsers error: ${error}`);
  });
};

const getLocationInnerDepartments = (locationId) => (dispatch) => {
  if( typeof(locationId) == 'undefined' || locationId == null){
    console.log('getLocationInnerDepartments Error: undefined or null locationId');
    return;
  }
  GetLocationInnerDepartments(locationId).then(({ data }) => {
    dispatch(appActions.hydrateDepartments(data.departments));
  }).catch((error) => {
    const msg = `${locationId}: GetLocationInnerDepartments returns error = ${error}`;
    console.log(msg);
  });
};

const getLocationUsableSkills = (locationId) => (dispatch) => {
  GetLocationUsableSkills(locationId).then(({ data }) => {
    dispatch(appActions.hydrateSkills(data.skills));
  }).catch((error) => {
    const msg = `${locationId}: GetLocationUsableSkills returns error = ${error}`;
    console.log(msg);
  });
};

const fetchChannelMembers = (channelId, channelName) => (dispatch) => {
  const start = moment();
  getChannelMembers(channelId).then((data) => {
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `fetchChannelMembers took ${ms} ms.`;
    console.log(s);
    const channelData = { id: channelId, name: channelName, members: data.data.users };
    dispatch(actions.recieveChannelMembers(channelData));
  }).catch((error) => {
    dispatch(actions.resetChannelMembers({ id: channelId, name: channelName }));
    dispatch(actions.removeChannel({ id: channelId, name: channelName }));
    const msg = `${channelId}: getChannelMembers returns error = ${error}`;
    console.log(msg);
  });
};

const getLocationChannels = (locationId) => (dispatch) => {
  GetLocationChannels(locationId).then(({ data }) => {
    dispatch(actions.hydrateLocationChannels(data.channels));
  }).catch((error) => {
    const msg = `${locationId}: GetLocationChannels returns error = ${error}`;
    console.log(msg);
  });
};

/**
 * Can withdraw admin priv. from a user or promote them and updates user data in state
 *
 * @param  {Number} userPrivilegeId
 * @param  {Boolean} is_admin true to promote and false to demote
 */
const updateAdminStatus = (userPrivileges, is_admin) => (dispatch) => {
  const userIds = userPrivileges.map((item) => {
    return item.id
  });
  const start = moment();
  return teamCalls.putUserPrivilege(userIds[0], { is_admin }).then((response) => {
    if (response.data.user_privilege.is_admin) {
      let payload = { name: userActionTypes.PROMOTE, ids: userIds };
      dispatch(appActions.lastAction(payload));
      //const manager = {users: response.data.user_privilege};
      const managerObj = {
        badge_id: userPrivileges[0].badge_id,
        department_ids: userPrivileges[0].department_ids,
        email: userPrivileges[0].email,
        first_name: userPrivileges[0].first_name,
        id: userPrivileges[0].id,
        is_admin: response.data.user_privilege.is_admin,
        last_name: userPrivileges[0].last_name,
        owner_id: userPrivileges[0].owner_id,
        phone_number: userPrivileges[0].phone_number,
        position: userPrivileges[0].position,
        profile_image_thumb_url: userPrivileges[0].profile_image_thumb_url,
        status: userPrivileges[0].status,
        user: userPrivileges[0].user,
      };
      const manager = { users: managerObj };
      dispatch(appActions.appendManagers(manager));
      dispatch(appActions.removeAssociate(response.data.user_privilege));
      const end = moment();
      const diff = moment.duration(end.diff(start))
      const ms = parseInt(diff.asMilliseconds());
      const s = `Grant 1 users took ${ms} ms.`;
      console.log(s);
    } else {
      let payload1 = { name: userActionTypes.WITHDRAW, ids: userIds };
      dispatch(appActions.lastAction(payload1));
      //const associate = {users: response.data.user_privilege};
      const associateObj = {
        badge_id: userPrivileges[0].badge_id,
        department_ids: userPrivileges[0].department_ids,
        email: userPrivileges[0].email,
        first_name: userPrivileges[0].first_name,
        id: userPrivileges[0].id,
        is_admin: response.data.user_privilege.is_admin,
        last_name: userPrivileges[0].last_name,
        owner_id: userPrivileges[0].owner_id,
        phone_number: userPrivileges[0].phone_number,
        position: userPrivileges[0].position,
        profile_image_thumb_url: userPrivileges[0].profile_image_thumb_url,
        status: userPrivileges[0].status,
        user: userPrivileges[0].user,
      };
      const associate = { users: associateObj };
      dispatch(appActions.appendAssociates(associate));
      dispatch(appActions.removeManager(response.data.user_privilege));
      const end = moment();
      const diff = moment.duration(end.diff(start))
      const ms = parseInt(diff.asMilliseconds());
      const s = `Withdraw 1 users took ${ms} ms.`;
      console.log(s);
    }
  }).catch((error) => {
    const msg = `User manager opetion updateAdminStatus for user ${userPrivileges[0].id} error: ${error}`;
    console.log(msg);
    dispatch(actions.setError(true));
  });

}

/**
 * Approves pending user request to join location
 * then removes user from view and updates pending count
 *
 * @param  {number or array of numbers} userPrivilegeId
 */
const approveUser = (userPrivilegeId, locationId, approve) => (dispatch) => {
  const start = moment();
  dispatch(appActions.resetError(true));
  return teamCalls.putUserPrivilege(userPrivilegeId, { is_approved: true }).then((response) => {
    // The reason why bulkApprove is not used: it does not return response.data.user_privilege, which is needed to update list
    //return teamCalls.bulkApprove(nonAdminIds, locationId).then((response) => {
    const payload = { name: userActionTypes.APPROVE, ids: userPrivilegeId };
    dispatch(appActions.lastAction(payload));
    dispatch(appActions.removePendingUser(response.data.user_privilege));
    if (response.data.user_privilege.is_admin) {
      const manager = { users: response.data.user_privilege };
      dispatch(appActions.appendManagers(manager));
    } else {
      const associate = { users: response.data.user_privilege };
      dispatch(appActions.appendAssociates(associate));
    }

    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Approving 1 users took ${ms} ms.`;
    console.log(s);
  }).catch((error) => {
    const msg = `Team operation approveUser error: ${error}`;
    console.log(msg);
    dispatch(actions.setError(true));
  });
};

/**
 * Approves pending user request to join location
 * then removes user from view and updates pending count
 *
 * @param  {number or array of numbers} userPrivilegeId
 */
const denyUser = (users, locationId) => (dispatch) => {
  let adminIds = [];
  let nonAdminIds = [];
  for (let i = 0; i < users.length; i++) {
    if (users[i].is_admin) {
      adminIds.push(users[i].id);
    } else {
      nonAdminIds.push(users[i].id);
    }
  }
  const allIds = adminIds.concat(nonAdminIds);

  const start = moment();
  dispatch(appActions.resetError(allIds[0]));
  return teamCalls.deleteUserPrivilege(allIds[0], 'decline').then((response) => {
    const payload = { name: userActionTypes.DENY, ids: allIds };
    dispatch(appActions.lastAction(payload));
    dispatch(appActions.removePendingUserWithId(allIds[0]));

    //let makeups = [];
    //for(let i=0; i<users.length; i++) {
    const makeup = {
      badge_id: users[0].badge_id,
      department_ids: users[0].department_ids,
      first_name: users[0].first_name,
      id: users[0].id,
      is_admin: users[0].is_admin,
      last_name: users[0].last_name,
      owner_id: users[0].owner_id,
      position: users[0].position,
      profile_image_thumb_url: users[0].profile_image_thumb_url,
      status: 'rejected',
    }
    //makeups.push(makeup);
    //}
    const deniedUsers = { users: makeup };
    dispatch(actions.appendRemovedUsers(deniedUsers))

    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Approving 1 users took ${ms} ms.`;
    console.log(s);
  }).catch((error) => {
    const msg = `Team operation denyUser error: ${error}`;
    console.log(msg);
    dispatch(actions.setError(allIds[0]));
  });
};

/**
 * Removes user from location and removes them from view
 *
 * @param  {Number} userPrivilegeId
 * @param  {Number} locationId
 */
const removeUser = (admins, nonAdmins, locationId) => (dispatch) => {
  let adminIds = [];
  let nonAdminIds = [];
  for (let i = 0; i < admins.length; i++) {
    adminIds.push(admins[i].id);
  }
  for (let j = 0; j < nonAdmins.length; j++) {
    nonAdminIds.push(nonAdmins[j].id);
  }
  const totalIds = adminIds.concat(nonAdminIds);
  dispatch(appActions.resetError(totalIds[0]));
  return teamCalls.bulkRemove(totalIds, locationId).then((response) => {
    const payload = { name: userActionTypes.REMOVE, ids: totalIds };
    dispatch(appActions.lastAction(payload));
    dispatch(appActions.removeManagers(adminIds));
    dispatch(appActions.removeAssociates(nonAdminIds));

    const users = admins.concat(nonAdmins);
    let makeups = [];
    for (let i = 0; i < users.length; i++) {
      const makeup = {
        badge_id: users[i].badge_id,
        department_ids: users[i].department_ids,
        first_name: users[i].first_name,
        id: users[i].id,
        is_admin: users[i].is_admin,
        last_name: users[i].last_name,
        owner_id: users[i].owner_id,
        position: users[i].position,
        profile_image_thumb_url: users[i].profile_image_thumb_url,
        status: 'removed',
      }
      makeups.push(makeup);
    }
    const removedUsers = { users: makeups };
    dispatch(appActions.appendRemovedUsers(removedUsers))
  }).catch((error) => {
    const msg = `User manager opetion removeUser error: ${error}`;
    console.log(msg);
    dispatch(actions.setError(totalIds[0]));
  });

};

const createGroup = (groupName, locationId, description) => (dispatch) => {
  const params = {
    channel_name: groupName,
    channel_frequency: locationId,
    description,
  };
  //dispatch(actions.requestChannels(true));
  return createChannel(params).then((response) => {
    //dispatch(actions.appendChannel(response.data.channel));
    dispatch(appActions.appendChannel(response.data.channel));
    let payload = { name: operationTypes.CREATE_GROUP, ids: [] };
    dispatch(appActions.lastAction(payload));
    //Add group creater's id to cache
    const temp = getUserId();
    const groupOwnerId = parseInt(temp);
    //This function is not working when location changed after login!!!
    //const groupOwnerIsAdmin = getUserRole();
    payload = { id: response.data.channel.id, userId: groupOwnerId };
  
    //If the login user is admin, manager will be updated, otherwise, associate will be updated
    dispatch(appActions.addChannelIdToManager(payload));
    dispatch(appActions.addChannelIdToAssociate(payload));
  }).catch((error) => {
    console.log(`createGroup error: ${error}`);
  });
};

const addUserToGroup = (channelId, users) => (dispatch) => {
  const userIds = users.map((user) => {
    return user.owner_id
  });
  const start = moment();
  dispatch(appActions.resetError(userIds[0]));
  return teamCalls.addUserToChannel(channelId, userIds[0]).then((response) => {
    //let payload = {name: operationTypes.ASSIGN_USERS_TO_GROUP, ids: userIds};
    //dispatch(appActions.lastAction(payload));
    let payload = { id: channelId, userId: userIds[0] };
    dispatch(appActions.addUsersToChannel(payload));
    if (users[0].is_admin) {
      dispatch(appActions.addChannelIdToManager(payload));
    } else {
      dispatch(appActions.addChannelIdToAssociate(payload));
    }
    payload = { name: operationTypes.ASSIGN_USERS_TO_GROUP, ids: userIds };
    dispatch(appActions.lastAction(payload));
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Adding 1 users took ${ms} ms.`;
    console.log(s);
  }).catch((error) => {
    const msg = `Team operation addUserToGroup error: ${error}. User: ${userIds[0]}`;
    console.log(msg);
    dispatch(appActions.setError(userIds[0]));
  });
};

const removeUserFromGroup = (channelId, users, memberCount) => (dispatch) => {
  const userIds = users.map((user) => {
    return user.owner_id
  });
  const start = moment();
  dispatch(appActions.resetError(userIds[0]));
  return teamCalls.removeUserFromChannel({ channel_id: channelId, user_id: userIds[0] }).then((response) => {
    let payload = { id: channelId, userId: userIds[0] };
    if (users[0].is_admin) {
      dispatch(appActions.removeChannelIdFromManager(payload));
    } else {
      dispatch(appActions.removeChannelIdFromAssociate(payload));
    }
    payload = { name: operationTypes.REMOVE_USERS_FROM_GROUP, ids: userIds };
    dispatch(appActions.lastAction(payload));
    if (userIds && userIds.length === 1 && memberCount === 1) {
      //dispatch(actions.removeChannel({id: channelId}));
      dispatch(appActions.removeChannel({ id: channelId }));
    }
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Removing 1 users took ${ms} ms.`;
    console.log(s);
  }).catch((error) => {
    //This error is because removing other group members after group creator was removed. 
    const msg = `Team operation removeUserFromChannel for user ${userIds[0]} error: ${error}`;
    console.log(msg);
    //dispatch(actions.removeChannel({id: channelId}));
    dispatch(appActions.removeChannel({ id: channelId }));
    dispatch(appActions.setError(userIds[0]));
  });
};

const createDepartment = (deptName, locationId, description) => (dispatch) => {
  const params = {
    name: deptName,
    location_id: locationId,
    description,
  };
  return addDepartment(params).then((response) => {
    dispatch(appActions.appendDepartment(response.data.department));
    const payload = { name: operationTypes.CREATE_DEPARTMENT, ids: [] };
    dispatch(appActions.lastAction(payload));
  }).catch((error) => {
    console.log(`addDepartment error: ${error}`);
  });
};

const addUserToDepartment = (users, departmentId) => (dispatch) => {
  const start = moment();
  const userIds = users.map((user) => {
    return user.owner_id
  });
  return addDepartmentMember(userIds, departmentId).then((response) => {
    let payload = { name: operationTypes.ASSIGN_USERS_TO_DEPT, ids: userIds };
    dispatch(appActions.lastAction(payload));
    payload = { id: response.data.department.id, user_ids: userIds };
    dispatch(appActions.addUsersToDepartment(payload));
    if (users[0].is_admin) {
      dispatch(appActions.addDeptIdToManager(payload));
    } else {
      dispatch(appActions.addDeptIdToAssociate(payload));
    }
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Adding 1 users took ${ms} ms.`;
    console.log(s);
  }).catch((error) => {
    const msg = `Team operation addDepartmentMember error: ${error}`;
    console.log(msg);
  });
};

const removeUserFromDepartment = (users, departmentId) => (dispatch) => {
  const start = moment();
  const userIds = users.map((user) => {
    return user.owner_id
  });
  return removeDepartmentMember(userIds, departmentId).then((response) => {
    let payload = { name: operationTypes.REMOVE_USERS_FROM_DEPT, ids: userIds };
    dispatch(appActions.lastAction(payload));
    payload = { id: response.data.department.id, user_ids: userIds };
    dispatch(appActions.removeUsersFromDepartment(payload));
    if (users[0].is_admin) {
      dispatch(appActions.removeDeptIdFromManager(payload));
    } else {
      dispatch(appActions.removeDeptIdFromAssociate(payload));
    }
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Adding 1 users took ${ms} ms.`;
    console.log(s);
  }).catch((error) => {
    const msg = `Team operation removeDepartmentMember error: ${error}`;
    console.log(msg);
  });
};

const updateCurrentEmployeeBadgeIdOnly = (id, ownerId, attributes) => (dispatch) => {

  const attributesForUserBadge = {
    badge_id: attributes.badge_id,
    internal_id: attributes.badge_id
  };

  return (teamCalls.putUserPrivilege.apply(this, [id, attributesForUserBadge])).then((results) => {
    let payload = {
      id: results.data.user_privilege.id,
      isAdmin: results.data.user_privilege.is_admin,
      badge_id: results.data.user_privilege.badge_id,
      internal_id: results.data.user_privilege.internal_id
    };

    if (payload.isAdmin) {
      dispatch(appActions.addBadgeIdToManager(payload));
    } else {
      dispatch(appActions.addBadgeIdToAssociate(payload));
    }
  }).catch((error) => {
    const msg = `update Current Employee BadgeId error: ${error}`;
    console.log(msg);
  });
};

const createSkill = (skillName, locationId ) => (dispatch) => {
  return  addSkill(skillName, locationId).then( (response) => {
    dispatch(appActions.appendSkill(response.data.skill));
    const payload = { name: operationTypes.CREATE_SKILL, ids: [] };
    dispatch(appActions.lastAction(payload));
  }).catch((error) => {
    console.log('createSkill error: ${error}');
  });
};

const assignUsersToSkill = (users, locationId, skill) => (dispatch) => {
  const start = moment();
  const userIds = users.map((user) => {
    return user.owner_id
  });
  return addUsersToSkill(userIds, locationId, skill).then((response) => {
    let payload = { name: operationTypes.ASSIGN_USERS_TO_SKILL, ids: userIds };
    dispatch(appActions.lastAction(payload));
    payload = { id: response.data.skill.id, user_ids: userIds };
    if (users[0].is_admin) {
      dispatch(appActions.addSkillIdToManager(payload));
    } else {
      dispatch(appActions.addSkillIdToAssociate(payload));
    }
    dispatch(appActions.addUsersToSkill(payload));
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Adding ${userIds.length} users took ${ms} ms.`;
    console.log(s);
  }).catch((error) => {
    const msg = `Team operation addUsersToSkill error: ${error}`;
    console.log(msg);
  });
};

const removeUsersFromSkill = (users, locationId, skill) => (dispatch) => {
  const start = moment();
  const userIds = users.map((user) => {
    return user.owner_id
  });
  return removeSkillUsers(userIds, locationId, skill).then((response) => {
    let payload = { name: operationTypes.REMOVE_USERS_FROM_SKILL, ids: userIds };
    dispatch(actions.lastAction(payload));
    payload = { id: response.data.skill.id, user_ids: userIds };
    if (users[0].is_admin) {
      dispatch(appActions.removeSkillIdFromManager(payload));
    } else {
      dispatch(appActions.removeSkillIdFromAssociate(payload));
    }
    dispatch(appActions.removeUsersFromSkill(payload));
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Adding 1 users took ${ms} ms.`;
    console.log(s);
  }).catch((error) => {
    const msg = `Team operation addUsersToSkill error: ${error}`;
    console.log(msg);
  });
};

const getUserInformation1 = (userPrivilege, locationId) => (dispatch) => {
  teamCalls.getTeamMember(locationId, userPrivilege.id).then(({ data }) => {
    const payload = {
      id: data.user_privilege.id,
      owner_id: data.user_privilege.owner_id,
      skill_ids: data.user_privilege.user.skill_ids,
      email: data.user_privilege.email,
      phone_number: data.user_privilege.phone_number,
      position: data.user_privilege.position,
      first_name: data.user_privilege.first_name,
      last_name: data.user_privilege.last_name,
      badge_id: data.user_privilege.badge_id,
      profile_image_thumb_url: data.user_privilege.profile_image_thumb_url,
      is_admin: data.user_privilege.is_admin,
    };

    //When grant/withdraw admin privilege, it is hard to tell the user is manager or asssociate at the moment
    //So update both, user will be found in manager or associate, not in both
    dispatch(appActions.addSkillsToManager(payload));
    if (data.user_privilege.status === 'active') {
      dispatch(appActions.addSkillsToAssociate(payload));
    }
    console.log(`Location ${locationId}, user ${userPrivilege.owner_id} getUserInformation1 called`);
  }).catch((error) => {
    const msg = `Location: ${locationId}, User: ${userPrivilege.owner_id} Status: ${userPrivilege.status}: getUserInformation1 returns error = ${error}`;
    console.log(msg);
  });
};

const fetchAllUsers = (locationId, pageNo, numPerPage) => (dispatch) => {
  console.log('Fetch All from UM');

  const start = moment();
  const allUserConfig = {
    location_id: locationId,
    page: pageNo,
    per_page: numPerPage,
    with_deleted: true,
  };
  dispatch(actions.requestAllUsers(true));
  dispatch(actions.requestManagers(true));
  dispatch(actions.requestAssociates(true));
  dispatch(actions.requestPendingUsers(true));
  return teamCalls.getTeamMembersUM(allUserConfig).then((response) => {
    const managers = response.data.user_privileges.filter((item) => item.is_admin === true && item.status === 'active');
    const associates = response.data.user_privileges.filter((item) => item.is_admin === false && item.status === 'active');
    const pendingUsers = response.data.user_privileges.filter((item) => item.status === 'pending');
    const removedUsers = response.data.user_privileges.filter((item) => item.status !== 'active' && item.status !== 'pending');
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Fetch ${response.data.user_privileges.length} users take ${ms} ms.`;
    console.log(s);
    const payload = {
      users: response.data.user_privileges,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if (allUserConfig.page === 1) {
      dispatch(actions.receiveAllUsers(payload));
    } else {
      dispatch(actions.appendAllUsers(payload));
    }

    const payloadManagers = {
      users: managers,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if (allUserConfig.page === 1) {
      dispatch(actions.receiveManagers(payloadManagers));
    } else {
      dispatch(actions.appendManagers(payloadManagers));
    }

    const payloadAssociates = {
      users: associates,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if (allUserConfig.page === 1) {
      dispatch(actions.receiveAssociates(payloadAssociates));
    } else {
      dispatch(actions.appendAssociates(payloadAssociates));
    }

    const payloadPendingUsers = {
      users: pendingUsers,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if (allUserConfig.page === 1) {
      dispatch(actions.receivePendingUsers(payloadPendingUsers));
    } else {
      dispatch(actions.appendPendingUsers(payloadPendingUsers));
    }

    const payloadRemovedUsers = {
      users: removedUsers,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if (allUserConfig.page === 1) {
      dispatch(actions.receiveRemovedUsers(payloadRemovedUsers));
    } else {
      dispatch(actions.appendRemovedUsers(payloadRemovedUsers));
    }
  }).catch((error) => {
    console.log(`fetAllUsers error: ${error}`);
    dispatch(actions.resetAllUsers());
    dispatch(actions.resetManagers());
    dispatch(actions.resetAssociates());
    dispatch(actions.resetPendingUsers());
    dispatch(actions.resetRemovedUsers());
  });

};

const fetchSubscriptions = (channel_id) => (dispatch) => {
  feedCalls.getSubscriptions(channel_id).then((response) => {
    dispatch(appActions.updateActiveChannelAdmin(response.data.subscriptions[0]));
    //dispatch(actions.updateActiveChannelSubscriptions(response.data.subscriptions[0]));
    //console.log(`update channel admin: ${channel_id}, ${response.data.subscriptions[0].is_admin}`);
  }).catch((error) => {
    console.log(`fetchSubscriptions error: ${error}`);
  })
};


export default {
  fetchRemovedUsers,
  fetchAllUsers,
  getLocationInnerDepartments,
  getLocationUsableSkills,
  fetchChannelMembers,
  getLocationChannels,
  updateAdminStatus,
  approveUser,
  denyUser,
  removeUser,
  createGroup,
  createDepartment,
  addUserToGroup,
  updateCurrentEmployeeBadgeIdOnly,
  removeUserFromGroup,
  addUserToDepartment,
  removeUserFromDepartment,
  assignUsersToSkill,
  removeUsersFromSkill,
  getUserInformation1,
  fetchSubscriptions,
  createSkill,
};
