import { createSlice } from '@reduxjs/toolkit';

const availabilitySlice = createSlice({
  name: 'availabilities',
  initialState: {
    availabilities: [],
    page: 0,
    totalPages: 0,
    loading: false,
    error: false,
  },

  reducers: {
    requestAllAvailabilities: (state, action) => {
      state.loading = true;
    },
    receiveAllAvailabilities: (state, action) => {
      state.availabilities= action.payload.availabilities;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.loading = false;
    },
    appendAllAvailabilities: (state, action) => {
        state.availabilities = state.availabilities.concat(action.payload.availabilities);
        state.loading = false;
      },
    setError: (state, action) => {
      state.error = true;
    },
    resetError: (state, action) => {
      state.error = false;
    },
  },
});

export default availabilitySlice;
