import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import Modal from 'components/GeneralComponents/Modal';
import constants from '../../constants';
import styled from './styled';
import './Header.scss';
import { closedLocationCalendarFilters } from 'config';
const weekFormat = 'ddd';
class SearchDialogue extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: false,
      inputText: '',
      openDeptMenu: false,
      selectedDept: null,
      dropdownPos: null,
      openChannelMenu: false,
      selectedChannel: null,
      openSkillMenu: false,
      openStatusMenu: false,
      openWeekdayMenu: false,
      selectedSkill: null,
      selectedStatus: [],
      selectedWeekday: [],
    };

    this._isMounted = false;
    this.listRef = React.createRef();
    this.listWinRef = React.createRef();
    this.input = React.createRef();
  }

  componentDidMount() {
    const { locationData, locations, } = this.props;

    this._isMounted = true;
    if (this.input && this.input.current) {
      this.input.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { openDeptMenu, openChannelMenu, openSkillMenu, openStatusMenu, openWeekdayMenu } = this.state;
    const { selectedFilter, } = this.props;

    let top = `400px`; //`${(window.innerHeight - 612)/2}px`;
    let left = `20px`; //`${(window.innerWidth - 1200)/2}px`;
    let width = '340px';//`50%`;
    let height = `128px`; //94.9% viewport height

    if (prevState.openDeptMenu !== openDeptMenu) {
      if (openDeptMenu) {
        if (this.listRef && this.listRef.current) {
          const domNode = ReactDOM.findDOMNode(this.listRef.current);
          const output = domNode.getBoundingClientRect();
          if (output) {
            top = `${output.y + output.height}px`;
            left = `${output.x}px`;
          }
        }
        const pos = {
          top: top,
          left: left,
          width: width,
          height: height,
        }
        this.setState({ dropdownPos: pos });
      }
    }

    if (prevState.openChannelMenu !== openChannelMenu) {
      if (openChannelMenu) {
        if (this.listRef && this.listRef.current) {
          const domNode = ReactDOM.findDOMNode(this.listRef.current);
          const output = domNode.getBoundingClientRect();
          if (output) {
            top = `${output.y + output.height}px`;
            left = `${output.x}px`;
          }
        }
        const pos = {
          top: top,
          left: left,
          width: width,
          height: height,
        }
        this.setState({ dropdownPos: pos });
      }
    }

    if (prevState.openSkillMenu !== openSkillMenu) {
      if (openSkillMenu) {
        if (this.listRef && this.listRef.current) {
          const domNode = ReactDOM.findDOMNode(this.listRef.current);
          const output = domNode.getBoundingClientRect();
          if (output) {
            top = `${output.y + output.height}px`;
            left = `${output.x}px`;
          }
        }
        const pos = {
          top: top,
          left: left,
          width: width,
          height: height,
        }
        this.setState({ dropdownPos: pos });
      }
    }

    if (prevState.openStatusMenu !== openStatusMenu) {
      if (openStatusMenu) {
        if (this.listRef && this.listRef.current) {
          const domNode = ReactDOM.findDOMNode(this.listRef.current);
          const output = domNode.getBoundingClientRect();
          if (output) {
            top = `${output.y + output.height}px`;
            left = `${output.x}px`;
          }
        }
        const pos = {
          top: top,
          left: left,
          width: width,
          height: height,
        }
        this.setState({ dropdownPos: pos });
      }
    }

    if (prevState.openWeekdayMenu !== openWeekdayMenu) {
      if (openWeekdayMenu) {
        if (this.listRef && this.listRef.current) {
          const domNode = ReactDOM.findDOMNode(this.listRef.current);
          const output = domNode.getBoundingClientRect();
          if (output) {
            top = `${output.y + output.height}px`;
            left = `${output.x}px`;
          }
        }
        const pos = {
          top: top,
          left: left,
          width: width,
          height: height,
        }
        this.setState({ dropdownPos: pos });
      }
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  handleDone = (event) => {
    const { notifySearchDone, selectedFilter, } = this.props;
    const { selectedDept, inputText, selectedChannel, selectedSkill, selectedStatus, selectedWeekday } = this.state;
    const { filterTypes, } = constants;

    switch (selectedFilter.name) {
      case filterTypes.DEPARTMENT:
        notifySearchDone(selectedDept);
        break;
      case filterTypes.EMAIL:
        notifySearchDone(inputText);
        break;
      case filterTypes.EMPLOYEE_ID:
        notifySearchDone(inputText);
        break;
      case filterTypes.GROUP:
        notifySearchDone(selectedChannel);
        break;
      case filterTypes.JOB_TITLE:
        notifySearchDone(inputText);
        break;
      case filterTypes.SKILL:
        notifySearchDone(selectedSkill);
        break;
      case filterTypes.STATUS:
        notifySearchDone(selectedStatus);
        break;
      case filterTypes.WEEKDAY:
        notifySearchDone(selectedWeekday);
        break;
    }
  }

  handleTextChanged = (event) => {
    const { selectedFilter, } = this.props;
    const { filterTypes, } = constants;
    if (selectedFilter.name === filterTypes.EMPLOYEE_ID) {
      if (!Number(event.target.value)) {
        return;
      }
    }
    this.setState({ inputText: event.target.value });
  }

  handleListClick = (event) => {
    const { openDeptMenu, openChannelMenu, openSkillMenu, openStatusMenu, openWeekdayMenu } = this.state;
    const { selectedFilter, } = this.props;
    const { filterTypes, } = constants;

    switch (selectedFilter.name) {
      case filterTypes.DEPARTMENT:
        this.setState({ openDeptMenu: !openDeptMenu });
        break;
      case filterTypes.GROUP:
        this.setState({ openChannelMenu: !openChannelMenu });
        break;
      case filterTypes.SKILL:
        this.setState({ openSkillMenu: !openSkillMenu });
        break;
      case filterTypes.STATUS:
        this.setState({ openStatusMenu: !openStatusMenu });
        break;
      case filterTypes.WEEKDAY:
        this.setState({ openWeekdayMenu: !openWeekdayMenu });
        break;
    }
  }

  handleClearAll = (event) => {
    this.setState({ selectedDept: null, selectedChannel: null, selectedSkill: null, inputText: '' });
  }

  handleDeptSelected = (dept) => {
    this.setState({ selectedDept: dept, openDeptMenu: false });
  }

  handleChannelSelected = (channel) => {
    this.setState({ selectedChannel: channel, openChannelMenu: false });
  }

  handleSkillSelected = (skill) => {
    this.setState({ selectedSkill: skill, openSkillMenu: false });
  }

  handleStatusSelected = (status) => {
    this.setState((prevState) => {
      const { selectedStatus } = prevState;
      const isSelected = selectedStatus.includes(status);
      const newSelectedStatus = isSelected
        ? selectedStatus.filter((s) => s !== status) // Remove if already selected
        : [...selectedStatus, status]; // Add if not selected

      return { selectedStatus: newSelectedStatus, };
    });
  }

  handleWeekdaySelected = (weekday) => {
    this.setState((prevState) => {
      const { selectedWeekday } = prevState;
      const isSelected = selectedWeekday.includes(weekday);
      const newSelectedWeekday = isSelected
        ? selectedWeekday.filter((w) => w !== weekday) // Remove if already selected
        : [...selectedWeekday, weekday]; // Add if not selected

      return { selectedWeekday: newSelectedWeekday, };
    });
  }

  renderDepartmentMenu = () => {
    const { departments, } = this.props;
    const { dropdownPos, } = this.state;

    let sortedDepts = [...departments];
    this.sortDeptNameWithNullValues(sortedDepts);
    const deptItems = sortedDepts.map((dept, index) => (
      <div
        key={index}
        className="search-dialogue__dropdown-item-wrap"
        value={dept}
        onClick={(event) => { this.handleDeptSelected(dept) }}
      >
        <label className="search-dialogue__item-text">
          {dept.name}
        </label>
      </div>
    ));

    if (dropdownPos) {
      return (
        <styled.WinContainerFilter ref={this.listWinRef} top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {deptItems}
        </styled.WinContainerFilter>
      );
    }
  }

  renderChannelMenu = () => {
    const { channels, } = this.props;
    const { dropdownPos, } = this.state;

    let sortedChannels = [...channels];
    this.sortChannelNameWithNullValues(sortedChannels);
    const channelItems = sortedChannels.map((channel, index) => (
      <div
        key={index}
        className="search-dialogue__dropdown-item-wrap"
        value={channel}
        onClick={(event) => { this.handleChannelSelected(channel) }}
      >
        <label className="search-dialogue__item-text">
          {channel.channel_name}
        </label>
      </div>
    ));

    if (dropdownPos) {
      return (
        <styled.WinContainerFilter ref={this.listWinRef} top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {channelItems}
        </styled.WinContainerFilter>
      );
    }
  }

  renderSkillMenu = () => {
    const { skills, } = this.props;
    const { dropdownPos, } = this.state;

    let sortedSkills = [...skills];
    this.sortSkillNameWithNullValues(sortedSkills);
    const skillItems = sortedSkills.map((skill, index) => (
      <div
        key={index}
        className="search-dialogue__dropdown-item-wrap"
        value={skill}
        onClick={(event) => { this.handleSkillSelected(skill) }}
      >
        <label className="search-dialogue__item-text">
          {skill.skill_content}
        </label>
      </div>
    ));

    if (dropdownPos) {
      return (
        <styled.WinContainerFilter ref={this.listWinRef} top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {skillItems}
        </styled.WinContainerFilter>
      );
    }
  }

  renderStatusMenu = () => {
    const { dropdownPos, } = this.state;
    const { intl } = this.props;
    const statusItems = closedLocationCalendarFilters.map((status, index) => (
      <div
        key={index}
        className="search-dialogue__dropdown-item-wrap"
        value={status}
        onClick={(event) => { this.handleStatusSelected(status) }}
      >
        <label className="search-dialogue__item-text">
          {typeof status.label === 'string'
            ? status.label
            : intl.formatMessage(status.label)}
        </label>
      </div>
    ));

    if (dropdownPos) {
      return (
        <styled.WinContainerFilter ref={this.listWinRef} top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {statusItems}
        </styled.WinContainerFilter>
      );
    }
  }

  renderWeekdayMenu = () => {
    const { dropdownPos, } = this.state;
    const { intl } = this.props;


    const weekdays = [
      { label: moment('2019-01-21').format(weekFormat), value: 1 },
      { label: moment('2019-01-22').format(weekFormat), value: 2 },
      { label: moment('2019-01-23').format(weekFormat), value: 3 },
      { label: moment('2019-01-24').format(weekFormat), value: 4 },
      { label: moment('2019-01-25').format(weekFormat), value: 5 },
      { label: moment('2019-01-26').format(weekFormat), value: 6 },
      { label: moment('2019-01-20').format(weekFormat), value: 0 },
    ];

    const weekdayItems = weekdays.map((weekday, index) => (
      <div key={index} className="search-dialogue__dropdown-item-wrap" onClick={() => this.handleWeekdaySelected(weekday)}>
        <label className="search-dialogue__item-text" style={{ cursor: 'pointer' }}>
          {typeof weekday.label === 'string'
            ? weekday.label
            : intl.formatMessage(weekday.label)}
        </label>
      </div>
    ));

    if (dropdownPos) {
      return (
        <styled.WinContainerFilter ref={this.listWinRef} top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {weekdayItems}
        </styled.WinContainerFilter>
      );
    }
  }

  sortDeptNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof (ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function (a, b) {
      if (a.name === b.name) // identical? return 0
        return 0;
      else if (a.name === null)  // a is null? last 
        return 1;
      else if (b.name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.name.localeCompare(b.name) * multiplier;
      }
    }
    return arr.sort(sorter);
  }

  sortChannelNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof (ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function (a, b) {
      if (a.channel_name === b.channel_name) // identical? return 0
        return 0;
      else if (a.channel_name === null)  // a is null? last 
        return 1;
      else if (b.channel_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.channel_name.localeCompare(b.channel_name) * multiplier;
      }
    }
    return arr.sort(sorter);
  }

  sortSkillNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof (ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function (a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }
    }
    return arr.sort(sorter);
  }

  render() {
    const { inputText, selectedDept, selectedChannel, selectedSkill, selectedStatus, selectedWeekday, openDeptMenu, openChannelMenu, openSkillMenu, openStatusMenu, openWeekdayMenu } = this.state;
    const { position, selectedFilter, intl } = this.props;
    const { filterTypes, } = constants;

    const winW = '400px';//`50%`;
    const winH = `220px`; //94.9% viewport height

    const is = (<FormattedMessage
      id="SearchDialogue.is"
      defaultMessage="is"
    />);
    const done = (<FormattedMessage
      id="SearchDialogue.done"
      defaultMessage="Done"
    />);

    const clearAll = (<FormattedMessage
      id="SearchDialogue.clearAll"
      defaultMessage="Clear all"
    />);

    //const deptText = selectedDept ? selectedDept.name : '';
    const arrowButtonClassName = openDeptMenu ? "search-dialogue__arrow-up" : "search-dialogue__arrow-down";
    let showComboBox = false;
    let selectedItemName = '';
    switch (selectedFilter.name) {
      case filterTypes.DEPARTMENT:
        showComboBox = true;
        selectedItemName = selectedDept ? selectedDept.name : '';
        break;
      case filterTypes.STATUS:
        showComboBox = true;
        selectedItemName = selectedStatus && Array.isArray(selectedStatus) && selectedStatus.length > 0
          ? selectedStatus.map((status) =>
            typeof status.label === 'string'
              ? status.label
              : intl.formatMessage(status.label)
          ).join(', ')
          : '';
        break;
      case filterTypes.WEEKDAY:
        showComboBox = true;
        selectedItemName = selectedWeekday && Array.isArray(selectedWeekday) && selectedWeekday.length > 0
          ? selectedWeekday.map((weekday) =>
            typeof weekday.label === 'string'
              ? weekday.label
              : intl.formatMessage(weekday.label)
          ).join(', ')
          : '';
        break;
      case filterTypes.EMAIL:
        showComboBox = false;
        break;
      case filterTypes.EMPLOYEE_ID:
        showComboBox = false;
        break;
      case filterTypes.GROUP:
        showComboBox = true;
        selectedItemName = selectedChannel ? selectedChannel.channel_name : '';
        break;
      case filterTypes.JOB_TITLE:
        showComboBox = false;
        break;
      case filterTypes.SKILL:
        showComboBox = true;
        selectedItemName = selectedSkill ? selectedSkill.skill_content : '';
        break;
    }


    return (
      <styled.WinContainerSearch top={position.top} left={position.left} width={winW} height={winH}>
        <div className="search-dialogue__top-wrap">
          <div className="search-dialogue__top-row">
            <label className="search-dialogue__title">{is}</label>
            <label className="search-dialogue__clear-all" onClick={this.handleClearAll}>{clearAll}</label>
          </div>
          {showComboBox && <div className="search-dialogue__drop-down" ref={this.listRef} onClick={this.handleListClick}>
            <label className="search-dialogue__item-text">{selectedItemName}</label>
            <button className={arrowButtonClassName} />
          </div>}
          {!showComboBox && <input className="search-dialogue__input-box"
            ref={this.input}
            onChange={this.handleTextChanged}
            type="text"
            value={inputText}
          />}
          <button className="search-dialogue__button" onClick={this.handleDone}>{done}</button>
        </div>
        {openDeptMenu && <Modal>
          {this.renderDepartmentMenu()}
        </Modal>}
        {openChannelMenu && <Modal>
          {this.renderChannelMenu()}
        </Modal>}
        {openSkillMenu && <Modal>
          {this.renderSkillMenu()}
        </Modal>}
        {openStatusMenu && <Modal>
          {this.renderStatusMenu()}
        </Modal>}
        {openWeekdayMenu && <Modal>
          {this.renderWeekdayMenu()}
        </Modal>}
      </styled.WinContainerSearch>
    );
  }
}

SearchDialogue.propTypes = {
  selectedItem: PropTypes.object.isRequired,
  selectedType: PropTypes.string.isRequired
};

export default injectIntl(SearchDialogue);
