
import React from 'react';
import { connect } from 'react-redux';
import { PubNubConsumer } from 'pubnub-react';
import ScheduleCalendarComponent from './ScheduleCalendarComponent';
import { loadLocationData } from '../../actions/dashboard-actions'

import actions from './duck/actions'
import { scheduleCalendarOperations, } from './duck';
import {unregisteredUserOperations} from '../UnregisteredUser/duck';
import {userManagementOperations} from '../UserManagement/duck';
import timeUtil from './duck/timeUtil';
import draftLogic from './duck/draftLogic';
import { isValidObject } from 'helpers/validation';
import ShiftContainer from 'pages/Shift/ShiftContainer';

const mapStateToProps = ({
  locationReducer,
  appReducer,
  scheduleCalendarReducer,
  unregisteredUserReducer,
}) => {
  return {
    locationId: locationReducer.location_code,
    shifts: scheduleCalendarReducer.shifts.data,
    rangeStart: scheduleCalendarReducer.dateRange.start,
    rangeEnd: scheduleCalendarReducer.dateRange.end,
    startStr: scheduleCalendarReducer.dateRange.startStr,
    needReset: scheduleCalendarReducer.dateRange.needReset,
    shiftsLoading: scheduleCalendarReducer.shifts.loading,
    usersLoading: scheduleCalendarReducer.allUsers.loading,
    allUsers: scheduleCalendarReducer.allUsers.users,
    errorWhilePublishing: scheduleCalendarReducer.shifts.publishError,
    plans: appReducer.plans.data,
    locationData: locationReducer.location_data,
    unregisteredUsers: unregisteredUserReducer.unregisteredUsers.users,
    departments: appReducer.departments.data,
    channels: appReducer.channels.data,
    isPublishing: scheduleCalendarReducer.shifts.publishing
  };
};

const mapDispatchToProps = (dispatch) => {

  const loadSingleShiftDispatch = (shiftId) => {
    dispatch(scheduleCalendarOperations.fetchSingleShiftOperation(shiftId));
  }

  const loadSingleApplicantDispatch = (applicantId) => {
    dispatch(scheduleCalendarOperations.fetchSingleApplicantOperation(applicantId));
  }
 
  const loadChannelsToPostDispatch = (locationId) => {
    dispatch(userManagementOperations.getLocationChannels(locationId));
  }

  const removePublishErrorDispatch = () => {
    dispatch(scheduleCalendarOperations.removePublishErrorOperation());
  }

  const clearDateRangeResetFlagDispatch = () => {
    dispatch(scheduleCalendarOperations.clearResetFlagOperation());
  }

  const loadDepartments = (locationId) => {
    dispatch(userManagementOperations.getLocationInnerDepartments(locationId));
  };

  const loadUnregisteredUsersDispatch = (locationId) => {
    dispatch(unregisteredUserOperations.fetchUnregisteredUsersDispatch(locationId));
    };

  const loadLocationDataDispatch = () => {
    dispatch(loadLocationData());
  };

  const deleteShiftDispatch = (shiftId, locationId) => {
      dispatch(scheduleCalendarOperations.deleteShiftOperation(shiftId, locationId));
  };

  const changeDateRangeSlice = (from, to) => {
    dispatch(scheduleCalendarOperations.changeDateRangeOperation(from, to));
  };

  const getShiftsInDateRange = (from, to, pageNum, perPageNum, includeNonPublicInventory) => {
    dispatch(scheduleCalendarOperations.fetchCompactShiftsInDateRange(from, to, pageNum, perPageNum, includeNonPublicInventory));
  };

  const getScheduleUsers = (locationId, pageNo, numPerPage) => {
    dispatch(scheduleCalendarOperations.fetchScheduleUsers(locationId, pageNo, numPerPage));
  };

  const linkUnregisteredUser = (unregistered_user_id, target_user_id, rangeStart, rangeEnd) => {
    dispatch(scheduleCalendarOperations.linkUnregisteredUser(unregistered_user_id, target_user_id, rangeStart, rangeEnd));
  };

  const notifyTeamOfPublishDispatch = (locationId, message, event, userIds ) => {
    dispatch(scheduleCalendarOperations.notifyTeamOperation(locationId, message, event, userIds ));
  };

  const loadSingleFreePostDispatch = (postId) => {
    dispatch(scheduleCalendarOperations.fetchSingleFreePostOperation(postId));
  }

  const publishDraftShiftsDispatch = (shiftsToPublish) => {
    const total = shiftsToPublish.length;

    const payload = { totalToPublish: total }
    dispatch(actions.changeStatusToPublishing(payload))

    shiftsToPublish.forEach(shift => {
      dispatch(scheduleCalendarOperations.publishDraftShiftOperation(shift.id, total));
    });
  };

  const deleteAllShiftsDispatch = (shifts, locationId, rangeStart, rangeEnd) => {
    shifts.forEach(element => {
      if( timeUtil.isShiftInRange(element, rangeStart, rangeEnd) == true) {
        dispatch(scheduleCalendarOperations.deleteShiftOperation(element.id, locationId));
      }
    });
  }

  const deleteAllDraftShiftsDispatch = (shifts, locationId, rangeStart, rangeEnd) => {
    if( typeof shifts != 'undefined' && shifts != null ){
      shifts.forEach(element => {
        if( draftLogic.isDraftShift(element) == true && timeUtil.isShiftInRange(element, rangeStart, rangeEnd) == true) {
          dispatch(scheduleCalendarOperations.deleteShiftOperation(element.id, locationId));
        }
      });
    }
  }

  return {
    changeDateRangeSlice,
    getShiftsInDateRange,
    getScheduleUsers,
    linkUnregisteredUser,
    deleteShiftDispatch,
    loadLocationDataDispatch,
    notifyTeamOfPublishDispatch,
    publishDraftShiftsDispatch,
    loadUnregisteredUsersDispatch,
    loadDepartments,
    deleteAllDraftShiftsDispatch,
    deleteAllShiftsDispatch,
    clearDateRangeResetFlagDispatch,
    removePublishErrorDispatch,
    loadChannelsToPostDispatch,
    loadSingleShiftDispatch,
    loadSingleApplicantDispatch,
    loadSingleFreePostDispatch,
  };
};

const ScheduleCalendarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  return (
    <PubNubConsumer>
      {(client) => {
        const { locationId,
          shifts,
          rangeStart,
          rangeEnd,
          startStr,
          needReset,
          allUsers,
          locationSettings,
          changeDateRangeSlice,
          getShiftsInDateRange,
          usersLoading,
          getScheduleUsers,
          linkUnregisteredUser,
          plans,
          locationData,
          deleteShiftDispatch,
          loadLocationDataDispatch,
          notifyTeamOfPublishDispatch,
          loadUnregisteredUsersDispatch,
          unregisteredUsers,
          departments,
          loadDepartments,
          publishDraftShiftsDispatch,
          deleteAllDraftShiftsDispatch,
          deleteAllShiftsDispatch,
          clearDateRangeResetFlagDispatch,
          removePublishErrorDispatch,
          errorWhilePublishing,
          loadChannelsToPostDispatch,
          channels,
          loadSingleShiftDispatch,
          loadSingleApplicantDispatch,
          loadSingleFreePostDispatch,
          isPublishing,
        } = props;

        return (
          <ScheduleCalendarComponent
            pubnub={client}
            locationId={locationId}
            shifts={shifts}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            startStr={startStr}
            needReset={needReset}
            allUsers={allUsers}
            locationSettings={locationSettings}
            loading={usersLoading}
            changeDateRangeSlice={changeDateRangeSlice}
            getShiftsInDateRange={getShiftsInDateRange}
            getScheduleUsers={getScheduleUsers}
            linkUnregisteredUser = {linkUnregisteredUser}
            plans={plans}
            locationData={locationData}
            deleteShiftDispatch ={deleteShiftDispatch}
            loadLocationDataDispatch = {loadLocationDataDispatch}
            notifyTeamOfPublishDispatch = {notifyTeamOfPublishDispatch}
            loadUnregisteredUsersDispatch = {loadUnregisteredUsersDispatch}
            unregisteredUsers = {unregisteredUsers}
            departments = {departments}
            loadDepartments = {loadDepartments}
            publishDraftShiftsDispatch = {publishDraftShiftsDispatch}
            deleteAllDraftShiftsDispatch = {deleteAllDraftShiftsDispatch}
            deleteAllShiftsDispatch = {deleteAllShiftsDispatch}
            clearDateRangeResetFlagDispatch = {clearDateRangeResetFlagDispatch}
            removePublishErrorDispatch = {removePublishErrorDispatch}
            errorWhilePublishing = {errorWhilePublishing}
            loadChannelsToPostDispatch = {loadChannelsToPostDispatch}
            channels = {channels}
            loadSingleShiftDispatch = {loadSingleShiftDispatch}
            loadSingleApplicantDispatch = {loadSingleApplicantDispatch}
            loadSingleFreePostDispatch = {loadSingleFreePostDispatch}
            isPublishing = {isPublishing}
          />
        );
      }}
    </PubNubConsumer>
  );
});

export default ScheduleCalendarContainer;
