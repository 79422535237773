import { protectedAxiosInstance } from 'helpers/api-calls';
import { formatOnlyProps } from 'helpers/formatting';
import { BASE_URL, BASE_SUBDOMAIN_URL } from '../../constants';
import { createHeadersForUploadImage } from '../cookies';

const teamParams = (search_term) => {
  const params = {
    'response[only]': formatOnlyProps(`
    owner_id,
    id,
    is_admin,
    first_name,
    last_name,
    position,
    status,
    profile_image_thumb_url,
    deleted_at,
    department_ids,
    badge_id,
    email,
    phone_number,
    user[
      skill_ids,
    ],
  `),
    total: true,
  };

  if (search_term) params.search_term = search_term;

  return params;
};


const getScheduleUsers = ({
  location_id,
  page,
  search_term,
  filter,
  per_page,
}) =>
  protectedAxiosInstance.get('api/customer/admin_role/user_privileges/', {
    params: {
      ...teamParams(search_term),
      page,
      per_page,
      location_id,
    },
  });

/**
 * @param  {Object} config
 * @param  {boolean} config.admin_status
 * @param  {number} config.location_id
 * @param  {number} config.page
 * @param  {string} [config.search_term]
 * @param  {string} [config.filter = approved]
 * @param  {number} config.per_page
 */
const getTeamMembers = ({
  admin_status,
  location_id,
  page,
  search_term,
  filter,
  per_page,
  with_deleted,
}) =>
  protectedAxiosInstance.get('api/customer/admin_role/user_privileges/', {
    params: {
      ...teamParams(search_term),
      page,
      per_page,
      admin_status,
      location_id,
      with_deleted,
      [filter === 'active' ? 'approved' : filter]: true,
    },
  });

/**
 * @param  {Object} config
 * @param  {boolean} config.admin_status
 * @param  {number} config.location_id
 * @param  {number} config.page
 * @param  {string} [config.search_term]
 * @param  {string} [config.filter = approved]
 * @param  {number} config.per_page
 */
const getTeamMembersUM = ({
  admin_status,
  location_id,
  page,
  search_term,
  filter,
  per_page,
  with_deleted,
}) =>
  protectedAxiosInstance.get('/api/compact/user_privileges/ui_dash_user_management?', {
    params: {
      total: true,
      page,
      per_page,
      location_id,
      with_deleted,
    },
  });

const getTeamMember = (locationId, employeeId) =>
  protectedAxiosInstance.get(
    `api/customer/admin_role/user_privileges/${employeeId}`
  );

const getRemovedTeamMembers = ({ location_id, page, search_term, per_page }) =>
  protectedAxiosInstance.get(
    'api/customer/admin_role/user_privileges/removed_users',
    {
      params: {
        ...teamParams(search_term),
        page,
        per_page,
        location_id,
      },
    }
  );

const getTeamMetadata = (location_id, searchTerm, approved, pending) =>
  protectedAxiosInstance.get('api/customer/admin_role/user_privileges/', {
    params: {
      'response[only]': 'id',
      location_id,
      search_term: searchTerm || null,
      approved,
      pending,
      total: true,
      per_page: 5,
    },
  });

/**
 * @param  {number} id
 * @param  {Object} user_privilege - Object with user_privilege fields to update
 */
const putUserPrivilege = (id, user_privilege) =>
  protectedAxiosInstance.put(
    `/api/customer/admin_role/user_privileges/${id}`,
    { user_privilege },
    {
      params: {
        ...teamParams(),
        'response[put_return_resource]': true,
      },
    }
  );

/**
 * @param  {number} id
 * @param  {Object} user - Object with user fields to update (first_name, last_name)
 */
const putUserAttributes = (id, attributes) => {
  const user = {};

  if (attributes.first_name) user.first_name = attributes.first_name;
  if (attributes.last_name) user.last_name = attributes.last_name;

  return protectedAxiosInstance.put(
    `/api/customer/user_role/users/${id}`,
    { user },
    {
      params: {
        ...teamParams(),
        'response[put_return_resource]': true,
      },
    }
  );
};

/**
 * @param  {number} id
 * @param  {string} operation
 */
const deleteUserPrivilege = (id, operation) => {
  const operationList = ['decline', 'remove', 'block'];
  const baseUrl = '/api/customer/user_role/user_privileges/';

  if (!operationList.includes(operation)) {
    throw new Error(`Expected one of ${operationList.toString()}`);
  }

  if (operation === 'block') {
    return protectedAxiosInstance.put(`${baseUrl + id}/block`, null, {
      params: {
        is_blocked: true,
        'response[put_return_resource]': true,
      },
    });
  }

  return protectedAxiosInstance.delete(`${baseUrl + id}/${operation}`);
};

const bulkRemove = (ids, location_id) =>
  protectedAxiosInstance.put(
    '/api/customer/admin_role/user_privileges/bulk_remove',
    { ids },
    {
      params: {
        ...teamParams(),
        location_id,
        'response[put_return_resource]': true,
      },
    }
  );

const bulkApprove = (ids, location_id) =>
  protectedAxiosInstance.put(
    '/api/customer/admin_role/user_privileges/bulk_approve',
    { ids },
    {
      params: {
        ...teamParams(),
        location_id,
        'response[put_return_resource]': true,
      },
    }
  );

const bulkDeny = (ids, location_id) =>
  protectedAxiosInstance.put(
    '/api/customer/admin_role/user_privileges/bulk_deny',
    { ids },
    {
      params: {
        ...teamParams(),
        location_id,
        'response[put_return_resource]': true,
      },
    }
  );


export function removeUserAvatar(userId) {
  
  const payload = {
    "user":{
      "profile_id": null,
    }
  }

  return fetch(`https://${BASE_URL}/api/customer/user_role/users/${userId}?response[put_return_resource]=true`, {
    method: 'PUT',
    headers: createHeadersForUploadImage(),
    body: JSON.stringify(payload)
  });
}

/**
 * @param  {number} user id
 * @param  {number} channel id
 */
const addUserToChannel = (channel_id, user_id) =>
  protectedAxiosInstance.post(
    `/api/customer/user_role/subscriptions`,
    {
      subscription: {
        user_id,
        channel_id,
      },
    }
  );

/**
 * @param  {number} user id
 * @param  {number} channel id
 */
const removeUserFromChannel = ({channel_id, user_id}) =>
  protectedAxiosInstance.put(
    `/api/customer/user_role/channels/${channel_id}/remove_user?user_id=${user_id}`,
    {
      params: {
        user_id,
        channel_id,
      },
    },
    
  );

/**
 * 
 * @param  {number} channel id
 */
const leaveChannel = ({channel_id}) =>
  protectedAxiosInstance.put(
    `/api/customer/user_role/channels/${channel_id}/leave`,
    {
      params: {
        channel_id,
      },
    },
  );

export default {
  getTeamMember,
  getTeamMembers,
  getTeamMembersUM,
  getRemovedTeamMembers,
  getTeamMetadata,
  putUserPrivilege,
  putUserAttributes,
  deleteUserPrivilege,
  bulkRemove,
  bulkApprove,
  bulkDeny,
  removeUserAvatar,
  addUserToChannel,
  removeUserFromChannel,
  getScheduleUsers,
  leaveChannel
};
