import axios from 'axios';
import Cookies from 'js-cookie';
import { countriesArr, defaultCountry } from '../../countries';
import Flag from '../../Flag';

import React from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../components/GeneralComponents/RegistrationDesign.scss'
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import messages from './messages';
import {
    setAuthenticationCookie,
    setUserCookie,
    setLocaleCookie,
    setLocationCookie,
} from 'helpers/cookies';

import {
    AddAuthHeaders,
    signUpWithEmailPhone,
    signUpWithEmail,
    LocationJoin,
} from 'helpers/api-calls';


class RegistrationAccountPage extends React.PureComponent {
    constructor(props) {
        super(props);
        // Initialize state
        this.state = {
            userName: '',
            placeDetails: null,
            address: '',
            email: '',
            phone: '',
            password: '',
            isFormValid: false,
            showPassword: false,
            loading: false, // Track loading state while the request is in progress
            error: '', // To display error messages if any,
            isChecked: false,
            selectedCountry: defaultCountry, // Default to USA
            countrySelectionOpen: false,
            isCreateLocationSelected: true,
            location: null,
            path: null,
            zipCode: '',
            accountCreatedErrors: {
                value: '',
                showValidity: false,
            },
            passwordCriteria: {
                minLength: false,
                containsNumber: false,
                containsSymbol: false,
                containsCapital: false,
            },
            passwordError: false,
            emailError: false,
            phoneError: false,
        };
    }

    componentDidMount() {
        const { location } = this.props;

        if (location && location.state) {
            this.setState({
                userName: location.state.userName,
                address: location.state.address,
                placeDetails: location.state.placeDetails,
                isCreateLocationSelected: location.state.isCreateLocationSelected,
                location: location.state.location,
                zipCode: location.state.zipCode,
            });
        } else {
            // If API is not loaded, redirect to Registration1
            this.props.history.push('/');
            return null;
        }

    }

    handleCheckboxChange = (e) => {
        this.setState({
            isChecked: e.target.checked
        });
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            {
                [name]: value,
            },
            this.validateForm
        );
    };

    handlePasswordToggle = () => {
        // Toggle the password visibility
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    validateForm = () => {
        const { email, password, phone, passwordError, emailError, phoneError } = this.state;

        // Email validation (simple regex for basic email format)
        const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

        // Update password validation
        const passwordCriteria = {
            minLength: password.length >= 8,
            containsNumber: /\d/.test(password),
            containsSymbol: /[!@*%\-$]/.test(password),
            containsCapital: /[A-Z]/.test(password),
        };

        const passwordValid = Object.values(passwordCriteria).every(Boolean);

        // Phone number validation
        // const phoneVald = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone);
        let phoneValid = false;
        if (typeof phone !== 'string') {
            phoneValid = false;
        } else {
            // strips '-', '(', ')', and spaces
            const charactersToReplace = /[-()\s+]/g;
            const stringToValidate = phone.replace(charactersToReplace, '');
            const regex = /^([0-9]+)$/;
            phoneValid = (regex.test(stringToValidate) && stringToValidate.length > 4);
        }

        // Check if all fields are valid
        this.setState({
            isFormValid: emailValid && passwordValid && phoneValid,
            passwordCriteria,
            passwordError: !passwordValid && password.length > 0,
            emailError: !emailValid && email.length > 0,
            phoneError: !phoneValid && phone.length > 0,
        });
    };


    checkUserForLocations = (privilege) => {
        return privilege?.location ? privilege.location.id : null;
    };

    handleNext = async () => {
        const { email, password, phone, address, userName, placeDetails, isChecked, selectedCountry, accountCreatedErrors, isCreateLocationSelected, location, zipCode } = this.state;

        const { intl } = this.props;
        const nameParts = userName.split(' ');
        const first_name = nameParts[0]; // First part is the first name
        const last_name = nameParts.slice(1).join(' '); // Join the remaining parts for the last name
        const finalPhone = selectedCountry[2] + phone;


        this.setState({ loading: true, error: '' });
        if (isCreateLocationSelected) {
            signUpWithEmailPhone({
                first_name: first_name,
                last_name: last_name,
                email: email,
                finalPhone: finalPhone,
                password: password,
                placeDetails,
                isChecked,
                locale: 'en',
                signup_from: 'web_manager',
                zipCode: zipCode,
                address: address,
            }).then(
                (response) => {
                    const { userName, isCreateLocationSelected } = this.state;

                    if (userName) {
                        const state = {
                            email,
                            phone,
                            userName,
                            isCreateLocationSelected,
                        };

                        this.props.history.push({
                            pathname: '/sign-up/verification',
                            state: state,
                        });
                    }
                },
                (error) => {
                    let errorText;
                    if (error.response.status === 422) {
                        if (error.response.data.errors && error.response.data.errors.email) {
                            errorText = intl.formatMessage(messages.emailTakenError);
                        }
                        if (error.response.data.errors && error.response.data.errors.phone_number) {
                            errorText = intl.formatMessage(messages.phoneTakenError);
                        }
                    }

                    this.setState({
                        accountCreatedErrors: Object.assign(accountCreatedErrors, {
                            value: errorText || error.response.statusText,
                            showValidity: true,
                        }),
                        loading: false,
                    });
                }
            );
        } else if (location) {
            signUpWithEmail({
                first_name: first_name,
                last_name: last_name,
                email: email,
                password: password,
                finalPhone: finalPhone,
                isChecked,
                locale: 'en',
                location,
                signup_from: 'web_manager',
            }).then(
                (response) => {
                    const { userName, isCreateLocationSelected } = this.state;

                    if (userName) {
                        const state = {
                            email,
                            phone,
                            userName,
                            isCreateLocationSelected,
                        };

                        this.props.history.push({
                            pathname: '/sign-up/verification',
                            state: state,
                        });
                    }
                },
                (error) => {
                    console.log(" - error - ", error);
                    let errorText;
                    if (error.response.status === 422) {
                        if (error.response.data.errors && error.response.data.errors.email) {
                            errorText = intl.formatMessage(messages.emailTakenError);
                        }
                    }

                    this.setState({
                        accountCreatedErrors: Object.assign(accountCreatedErrors, {
                            value: errorText || error.response.statusText,
                            showValidity: true,
                        }),
                        loading: false,
                    });
                }
            );
        }
    };


    toggleCountryDropdown = () => {
        this.setState((prevState) => ({
            countrySelectionOpen: !prevState.countrySelectionOpen,
        }));
    };

    selectCountry = (country) => {
        this.setState({
            selectedCountry: country,
            countrySelectionOpen: false,
        });
    };

    populateCountryList = (countries) => countries.map((country) => {
        const name = country[0];
        const isoCode = country[1];
        const phoneCode = country[2];

        return (
            <div
                key={isoCode}
                className="country-option"
                onClick={() => this.selectCountry(country)}
            >

                <Flag isoCode={isoCode} />
                <span>{name} +{phoneCode}</span>
            </div>
        );
    });

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleNext();
        }
    };

    render() {
        const { email, password, passwordCriteria, passwordError, emailError, phoneError, phone, isFormValid, showPassword, loading, path, accountCreatedErrors, error, isChecked, selectedCountry, countrySelectionOpen, userName, isCreateLocationSelected, location } = this.state;

        const accountCreationErrorText = accountCreatedErrors.showValidity
            ? accountCreatedErrors.value
            : '';


        if (path) {
            //  return <Redirect push to={path} />;
        }

        return (
            <RegistrationLayout>
                <div className="form-content">
                    <div className="welcome-text-wrapper">
                        <div className="dot"></div>
                        <div className="welcome-text">Creating account</div>
                    </div>
                    <div className="main-heading">Let's finish setting up your account.</div>
                    <div className="trial-prompt">Enjoy a 14-day free trial. No credit card required.</div>

                    <div className="name-prompt">What's your email?</div>
                    <div className="icon-input-container">
                        <input
                            type="text"
                            placeholder="Email"
                            className={`icon-name-input ${emailError ? 'input-error' : ''}`}
                            name="email"
                            value={email}
                            onChange={this.handleInputChange}
                        />
                        <img
                            className="input-icon-left"
                            src='/icons/email-icon.svg'
                        />
                    </div>
                    {emailError && <div className="error-message">Please enter a valid email.</div>}

                    <div className="name-prompt">Create a password</div>

                    <div className="icon-input-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Enter Password"
                            className={`icon-name-input ${passwordError ? 'input-error' : ''}`}
                            name="password"
                            value={password}
                            onChange={this.handleInputChange}
                        />
                        <img
                            className="input-icon-right"
                            src={showPassword ? '/icons/password-eye-close-icon.svg' : '/icons/password-eye-icon.svg'}
                            alt="Toggle Password Visibility"
                            onClick={this.handlePasswordToggle}
                        />
                        <img
                            className="input-icon-left"
                            src='/icons/password-lock-icon.svg'
                        />
                    </div>
                    {passwordError && <div className="error-message">Please enter a valid password. It must include at least one uppercase letter, one special character (!*@-$%), one number, and be a minimum of eight characters long.</div>}

                    <div className="name-prompt">What's your phone number?</div>

                    <div className="icon-input-container">
                        <input
                            type="text"
                            placeholder="Phone Number"
                            className={`icon-name-input ${phoneError ? 'input-error' : ''}`}
                            name="phone"
                            value={phone}
                            onChange={this.handleInputChange}
                            onKeyDown={this.handleKeyDown}
                        />
                        <img
                            className="input-icon-left"
                            src='/icons/phone-icon.svg'
                            alt="Phone Icon"
                        />
                        <div
                            className="country-selector"
                            onClick={this.toggleCountryDropdown}
                        >

                            <Flag isoCode={selectedCountry[1]} />

                            <img
                                className="dropdown-arrow"
                                src='/icons/dropdown-arrow-icon.svg'
                                alt="Toggle Country Dropdown"
                            />
                        </div>
                        {countrySelectionOpen && (
                            <div className="country-dropdown">
                                {this.populateCountryList(countriesArr)}
                            </div>
                        )}
                    </div>
                    {phoneError && <div className="error-message">Please enter a valid phone number.</div>}

                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            id="sendLink"
                            checked={isChecked}
                            className='checkbox-input'
                            onChange={this.handleCheckboxChange}
                        />
                        <label htmlFor="sendLink" className="checkbox-label">
                            Send a link to try the mobile app.
                        </label>
                    </div>

                    {accountCreationErrorText && <div className="error-message">{accountCreationErrorText}</div>}

                    <button className="next-btn" onClick={this.handleNext} disabled={!isFormValid}>
                        Create Account
                    </button>
                    <div className="terms-message">
                        By clicking Create Account, you agree to the{' '}
                        <a
                            href="https://www.myshyft.com/terms-service/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>
                        ,{' '}
                        <a
                            href="https://www.myshyft.com/privacy-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        , and{' '}
                        <a
                            href="https://www.myshyft.com/refund-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Refund Policy
                        </a>
                        . Message and data rates may apply. Reply STOP to cancel messages.
                    </div>
                    {loading && (
                        <div className="loading-modal">
                            <div className="loading-dialog">
                                <div className="spinner"></div>
                                <div className="loading-text">Registering...</div>
                            </div>
                        </div>
                    )}
                </div>
            </RegistrationLayout >
        );
    }
}

export default withRouter(injectIntl(RegistrationAccountPage));