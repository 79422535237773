import React from 'react';
import { connect } from 'react-redux';
import { scheduleCalendarOperations } from 'pages/ScheduleCalendar/duck';
import { ShiftApplicantsComponent } from './ShiftApplicantsComponent';

const mapStateToProps = ({
  scheduleCalendarReducer,
}) => {
  return {
    allUsers: scheduleCalendarReducer.allUsers.users,
    applicants: scheduleCalendarReducer.applicants.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  
  const approveApplicantDispatch = (shiftId, applicantId) => {
    dispatch(scheduleCalendarOperations.approveApplicantOperation(shiftId, applicantId));
  };
    
  const denyApplicantDispatch = (shiftId, applicantId) => {
    dispatch(scheduleCalendarOperations.denyApplicantOperation(shiftId, applicantId));
  };

  return {
    approveApplicantDispatch,
    denyApplicantDispatch,
  };
};

const ShiftApplicantsComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {
    applicants,
    approveApplicantDispatch,
    denyApplicantDispatch,
    presentedShift,
    shiftMatrix,
    weekRangeStart,
    handleTooltipHide,
    handleTooltipShow,
    } = props;

  return <ShiftApplicantsComponent
    applicants = {applicants}
    approveApplicantDispatch = {approveApplicantDispatch}
    denyApplicantDispatch = {denyApplicantDispatch}
    presentedShift={presentedShift}
    shiftMatrix = {shiftMatrix}
    weekRangeStart = {weekRangeStart}
    handleTooltipHide={handleTooltipHide}
    handleTooltipShow={handleTooltipShow}
   /> 
});

export default ShiftApplicantsComponentContainer;