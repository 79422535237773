import React from "react";
import { FormattedMessage } from 'react-intl';
import DashContainer from "../Dashboard/DashboardContainer";
import '../css/tab.scss';
import '../../App/layout.scss';
import '../../App/fonts.scss';
import { Mixpanel } from '../../Mixpanel';
import CalendarPageContainer from "./Calendar/CalendarContainer";

class RequestsV3TabComponent extends React.Component {
    state = {
        activeTab: 0,
    };

    componentDidMount() {
        const { match } = this.props;
        const initialTab = match.params.module;

        this.setState({ activeTab: initialTab === 'requests' ? 0 : 1 }, () => {
            Mixpanel.track(initialTab === 'requests' ? 'Requests Page' : 'Shifts Records Page');
        });
    }

    handleTabClick = (tab) => {
        this.setState({ activeTab: tab }, () => {
            Mixpanel.track(tab === 0 ? 'Dash Page' : 'Requests Page');
        });
    }

    renderCategoryItem = (item, isSelected, onClick) => {
        const className = isSelected ? 'category__text-wrap-selected' : 'category__text-wrap';
        return (
            <div className={className} onClick={onClick}>
                <p className="category__text">
                    {item}
                </p>
            </div>
        );
    };

    render() {
        const { locationSettings } = this.props;
        const { activeTab } = this.state;

        const categoryItems = [
            { text: <FormattedMessage id="ShiftHeaderComponent.schedule" defaultMessage="Requests" />, onClick: () => this.handleTabClick(0) },
            { text: <FormattedMessage id="Availability.availability" defaultMessage="Shift Records" />, onClick: () => this.handleTabClick(1) },
        ];

        return (
            <div className="top-tab_wrap">
                <div className="category__wrap">
                    {categoryItems.map((item, index) => this.renderCategoryItem(item.text, activeTab === index, item.onClick))}
                </div>

                <div className="top-tab_container">
                    {activeTab === 0 && <DashContainer locationSettings={locationSettings} />}
                    {activeTab === 1 && <CalendarPageContainer />}
                </div>
            </div>
        );
    }
}

export default RequestsV3TabComponent;
