import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  plans: slices.plansSlice.reducer,
  
};

const reducer = combineReducers({
  plans: reducers.plans,
  
});

export default reducer;
