import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import constants from '../../constants';
import './Summary.scss';
import './SummaryShimmer.scss';
import strings from '../../../../strings/text';

class Summary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      searchTerm: '',
      selectedIndex: 0,
      selectedItem: null,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { parentSelectedIndex, summaryItems, selectedType, } = this.props;
   
    this._isMounted = true;
    if(parentSelectedIndex !== undefined) {
      if(selectedType === 'LOCATION') {
        this.setState({selectedIndex: parentSelectedIndex});
      }
      if(selectedType === 'GROUPS' || selectedType === 'DEPARTMENTS' || selectedType === 'SKILLS') {
        if(parentSelectedIndex < summaryItems.length) {
          this.setState({selectedIndex: parentSelectedIndex, selectedItem: summaryItems[parentSelectedIndex]});
        }else {
          const firstItem = summaryItems && summaryItems.length > 0 ? summaryItems[0] : null;
          this.setState({selectedIndex: 0, selectedItem: firstItem});
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { summaryItemSelected, selectedType, summaryItems, departments, selectedUsers, parentSelectedIndex, } = this.props;
    const { selectedItem, selectedIndex, } = this.state;
    const { categoryTypes } = constants;

    if(prevProps.selectedType !== selectedType) {
      const firstItem = summaryItems && summaryItems.length > 0 ? summaryItems[0] : null;
      this.setState({selectedIndex: 0, selectedItem: firstItem});
    }

    if(prevState.selectedItem !== selectedItem) {
      summaryItemSelected(selectedIndex, selectedItem);
    }

    if(prevProps.parentSelectedIndex !== parentSelectedIndex) {
      this.setState({selectedIndex: parentSelectedIndex});
    }


    if(prevProps.summaryItems !== summaryItems) {
      let foundItem = [];
      if(selectedType === categoryTypes.GROUPS) {
        if(summaryItems &&  prevProps.summaryItems && summaryItems.length < prevProps.summaryItems.length) {
          if(selectedItem) {
            foundItem = summaryItems.filter((item) => item.id === selectedItem.id);
            if(foundItem.length === 0) {
              if(selectedIndex < summaryItems.length) {
                this.setState({selectedItem: summaryItems[selectedIndex]});
                summaryItemSelected(selectedIndex, summaryItems[selectedIndex]);
              }else if(selectedIndex === summaryItems.length && summaryItems.length > 0) {
                this.setState({selectedIndex: summaryItems.length - 1, selectedItem: summaryItems[summaryItems.length - 1]});
                summaryItemSelected(summaryItems.length - 1, summaryItems[summaryItems.length - 1]);
              }
            }
          }else {
            if(selectedIndex === summaryItems.length && summaryItems.length > 0) {
              this.setState({selectedIndex: summaryItems.length - 1, selectedItem: summaryItems[summaryItems.length - 1]});
              summaryItemSelected(summaryItems.length - 1, summaryItems[summaryItems.length - 1]);
            }
          }
        }else if(summaryItems && prevProps.summaryItems && summaryItems.length > prevProps.summaryItems.length) {
          if(selectedIndex < summaryItems.length) {
            this.setState({selectedItem: summaryItems[selectedIndex]});
            summaryItemSelected(selectedIndex, summaryItems[selectedIndex]);
          }
        }
      }
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleItemClicked = (e: any, index: number, item: any) => {
    this.setState({selectedIndex: index, selectedItem: item});
  }

  render() {
    const { searchTerm, selectedIndex, } = this.state;
    const { intl, summaryItems, selectedType, channels, departments, loading, handleAddMemberClick} = this.props;

    const paramWrapClassName = 'summary__param-wrap';

    const createSummaryElements = () => {
      if(summaryItems) {
        const elements = summaryItems.map((item, index) => {
          return (
             <div className={selectedIndex === index ?  "summary__param-wrap-selected" : "summary__param-wrap"} 
              key={index}
              id={index}
              onClick={(e) => this.handleItemClicked(e, index, item)}
              >
              {/*<label className={selectedType === 'LOCATION' && index === 3 ? 'summary__param-value-location' : 'summary__param-value'}>{item.value}</label>*/}
              <label className="summary__param-value">{item.value}</label>
              <label className="summary__param-name">{item.name}</label>
            </div>
          );
        });
        return elements;
      }
      return null;
    };

    const createShimmerSummaryElements = () => {
      if(summaryItems) {
        const elements = summaryItems.map((item, index) => {
          return (
             <div className={selectedIndex === index ?  "summary__param-wrap-selected" : "summary__param-wrap"} 
              key={index}
              id={index}
              onClick={(e) => this.handleItemClicked(e, index, item)}
              >
              {/*<label className={selectedType === 'LOCATION' && index === 3 ? 'summary__param-value-location' : 'summary__param-value'}>{item.value}</label>*/}
              <label className="param-value"></label>
              <label className="summary__param-name">{item.name}</label>
            </div>
          );
        });
        return elements;
      }
      return null;
    };

    if(loading) {
      return (
        <div className="summary__wrap">
          {createShimmerSummaryElements()}
        </div>
      );
    }

    return (
      <div className="summary__wrap">
        <div className="summary-left"> {createSummaryElements()} </div>
        <button className="add-member-button" onClick={ () => { handleAddMemberClick(); }} >
          {strings.addTeamMember}
        </button>
      </div>
    );
  }
}

Summary.propTypes = {
  summaryItems: PropTypes.array.isRequired,
  selectedType: PropTypes.string.isRequired,
  summaryItemSelected: PropTypes.func.isRequired,
};

export default injectIntl(Summary);

