import React from 'react';
import { connect } from 'react-redux';
import ScheduleTabComponent from './ScheduleTabComponent';

import { Mixpanel } from '../../Mixpanel';

const mapStateToProps = ({
  appReducer,
}) => {
  return {
    locationSettings: appReducer.location.settings,
  };
};

const ScheduleTabContainer = connect(
  mapStateToProps,
)((props) => {
  const {
    locationSettings,
    match,
    } = props;

  return <ScheduleTabComponent
    locationSettings = {locationSettings}
    match = {match}
   /> 
});

export default ScheduleTabContainer;