import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import Modal from 'components/GeneralComponents/Modal';

import { getUserId } from 'helpers/cookies';

import likeIcon from './assets/like-icon.svg';
import commentIcon from './assets/comment-icon-new.svg';
import pinIcon from './assets/Shape.svg';
import pinnedIcon from './assets/pinned.svg';
import copyIcon from './assets/copy-icon.svg';
import deleteIcon from './assets/delete-icon-new1.svg';
import readIcon from './assets/mark-green.png';
import unreadIcon from './assets/mark-gray.png';
import moreActionIcon from './assets/ellipses-icon.svg';


import styled from './styled';
import './Buttons.scss';

const isAnnouncement = (post_type) => {
  return [1, 2, 3, 4].includes(post_type);
};

class Buttons extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: true,
      readAnnouncement: false,
      mouseOverButton: false,
      mouseTipPos: null,
      mouseMenuPos: null,
      mouseTipText: '',
      iconPos: null,
      openMoreActionMenu: false,
    };

    this._isMounted = false;

    this.likeRef = React.createRef();
    this.readRef = React.createRef();
    this.commentRef = React.createRef();
    this.pinRef = React.createRef();
    this.copyRef = React.createRef();
    this.deleteRef = React.createRef();
    this.moreActionRef = React.createRef();
  }

  componentDidMount() {
  	const { active, data, clickComment, clickDelete, clickLike, clickPin, clickCopy, clickReadReceipts, index, clickMoreActions, activeChannel, } = this.props;

  	this._isMounted = true;
    window.addEventListener("mousemove", this.logMousePosition);
  };

  componentDidUpdate(prevProps, prevState) {
  	const { active, data, clickComment, clickDelete, clickLike, clickPin, clickCopy, clickReadReceipts, index, receipts, clickMoreActions, activeChannel, } = this.props;

    if(prevProps.receipts !== receipts) {
      if(prevProps.receipts) {
        if(prevProps.receipts.loading && !receipts.loading) {
          this.setState({readAnnouncement: true});
        }
      }
    }

    if(prevProps.data !== data) {
      if(data && data.readList) {
        const myId = parseInt(getUserId());
        if(data.readList.includes(myId)) {
          this.setState({mouseOverButton: false});
        }
      }
    }
  };

  componentWillUnmount() {
  	this._isMounted = false;
    window.removeEventListener("mousemove", this.logMousePosition);
  };

  logMousePosition = (e) => {
    const { iconPos,} = this.state;
    if(iconPos) {
      if(e.clientX > iconPos.x + iconPos.width) {
        //this.setState({mouseOverMoreActions: false});
      }
      if(e.clientX > iconPos.x && e.clientY > iconPos.y + iconPos.height) {
        //this.setState({mouseOverMoreActions: false});
      }
    }
  }

  mouseEnterButtonLike = (event) => {
    const { data,} = this.props;
    
    let winTop = '';
    let winLeft = '';
    let output = null;
    const winW = '160px';
    const winH = `60px`; 
    if(this.likeRef && this.likeRef.current) {
      const domNode = ReactDOM.findDOMNode(this.likeRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height -30}px`;
        winLeft = `${output.x - 78}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
      triangleLeft: '81px',
    }
    let text = null;
    if(!data.liked) {
      text = (<FormattedMessage
            id="Buttons.like"
            defaultMessage="Like Post"
          />);
    }else {
      text = (<FormattedMessage
            id="Buttons.unlike"
            defaultMessage="Unlike Post"
          />);
    }
    this.setState({mouseOverButton: true, mouseTipPos: winData, mouseTipText: text});
  }

  mouseLeaveButton = (event) => {
    this.setState({mouseOverButton: false});
  }

  mouseEnterButtonRead = (event) => {
    const { data, } = this.props;
    
    let winTop = '';
    let winLeft = '';
    let output = null;
    const winW = '160px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.readRef && this.readRef.current) {
      const domNode = ReactDOM.findDOMNode(this.readRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height -30}px`;
        winLeft = `${output.x - 78}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
      triangleLeft: '81px',
    }
    const text = (<FormattedMessage
          id="Buttons.read"
          defaultMessage="Mark as Read"
        />);
    this.setState({mouseOverButton: true, mouseTipPos: winData, mouseTipText: text});
  }

  mouseEnterButtonComment = (event) => {
    const { data,} = this.props;
    
    let winTop = '';
    let winLeft = '';
    let output = null;
    const winW = '160px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.commentRef && this.commentRef.current) {
      const domNode = ReactDOM.findDOMNode(this.commentRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height -30}px`;
        winLeft = `${output.x - 78}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
      triangleLeft: '81px',
    }
    const text = (<FormattedMessage
          id="Buttons.comments"
          defaultMessage="View Comments"
        />);
    this.setState({mouseOverButton: true, mouseTipPos: winData, mouseTipText: text});
  }

  mouseEnterButtonPin = (event) => {
    const { data, } = this.props;
    
    let winTop = '';
    let winLeft = '';
    let output = null;
    const winW = '160px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.pinRef && this.pinRef.current) {
      const domNode = ReactDOM.findDOMNode(this.pinRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height -30}px`;
        winLeft = `${output.x - 78}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
      triangleLeft: '81px',
    }
    let text = null;
    if(!data.pinned) {
      text = (<FormattedMessage
            id="Buttons.pin"
            defaultMessage="Pin to Channel"
          />);
    }else {
      text = (<FormattedMessage
            id="Buttons.unpin"
            defaultMessage="Unpin from Channel"
          />);
    }
    this.setState({mouseOverButton: true, mouseTipPos: winData, mouseTipText: text});
  }

  mouseEnterButtonCopy = (event) => {
    const { data, } = this.props;
    
    let winTop = '';
    let winLeft = '';
    let output = null;
    const winW = '160px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.copyRef && this.copyRef.current) {
      const domNode = ReactDOM.findDOMNode(this.copyRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height -30}px`;
        winLeft = `${output.x - 78}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
      triangleLeft: '81px',
    }
    const text = (<FormattedMessage
          id="Buttons.copy"
          defaultMessage="Copy Text"
        />);
    this.setState({mouseOverButton: true, mouseTipPos: winData, mouseTipText: text});
  }

  mouseEnterButtonDelete = (event) => {
    const { data, } = this.props;
  
    let winTop = '';
    let winLeft = '';
    let output = null;
    const winW = '160px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.deleteRef && this.deleteRef.current) {
      const domNode = ReactDOM.findDOMNode(this.deleteRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height -30}px`;
        winLeft = `${output.x - 78}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
      triangleLeft: '81px',
    }
    const text = (<FormattedMessage
          id="Buttons.delete"
          defaultMessage="Delete Message"
        />);
    this.setState({mouseOverButton: true, mouseTipPos: winData, mouseTipText: text});
  }

  mouseEnterButtonMore = (event) => {
    let winTop = '';
    let winLeft = '';
    let menuTop = '';
    let menuLeft = '';
    let output = null;
    const winW = '260px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.moreActionRef && this.moreActionRef.current) {
      const domNode = ReactDOM.findDOMNode(this.moreActionRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height -30}px`;
        winLeft = `${output.x - 78}px`;
        menuTop = `${output.y}px`;
        menuLeft = `${output.x - 205}px`;
        if((window.innerHeight - (output.y + output.height)) < 300) {
          menuTop = `${output.y - 200}px`;
        }
      }
    }
    const tipData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
      triangleLeft: '81px',
    }
    const menuData = {
      top: menuTop,
      left: menuLeft,
      width: winW,
      height: winH,
      triangleLeft: '81px',
    }


    const text = (<FormattedMessage
          id="Buttons.moreActions"
          defaultMessage="More Actions"
        />);
    this.setState({
      mouseOverButton: true, 
      mouseTipPos: tipData, 
      mouseMenuPos: menuData, 
      mouseTipText: text,
      iconPos: output,
    });
  }

  mouseLeaveButtonMore = (event) => {
    this.setState({mouseOverButton: false});
  }

  renderMouseTip = () => {
    const { mouseTipPos, mouseTipText, } = this.state;

    return (
      <styled.WinContainer top={mouseTipPos.top} left={mouseTipPos.left}>
        <div className="button-mouse-tip__wrap">
          <label className="button-mouse-tip__text">{mouseTipText}</label>
          <styled.Triangle top={'35px'} left={mouseTipPos.triangleLeft}/>
        </div>
      </styled.WinContainer>
    );
  }

  renderMoreActionsMenu = () => {
    const { data, activeChannel, } = this.props;
    const { mouseMenuPos, mouseTipText, iconPos, } = this.state;

    const like = (<FormattedMessage
            id="Buttons.like"
            defaultMessage="Like Post"
          />);
    const unlike = (<FormattedMessage
            id="Buttons.unlike"
            defaultMessage="Unlike Post"
          />);

    const comment = (<FormattedMessage
          id="Buttons.comments"
          defaultMessage="View Comments"
        />);

    const pin = (<FormattedMessage
            id="Buttons.pin"
            defaultMessage="Pin to Channel"
          />);
    const unpin = (<FormattedMessage
            id="Buttons.unpin"
            defaultMessage="Unpin from Channel"
          />);
    const copy = (<FormattedMessage
          id="Buttons.copy"
          defaultMessage="Copy Text"
        />);
    const deleting = (<FormattedMessage
          id="Buttons.delete"
          defaultMessage="Delete Message"
        />);
    const read = (<FormattedMessage
          id="Buttons.read"
          defaultMessage="Mark as Read"
        />);

    let items = [];
    const itemLike = {name: 'like', body: like, src: likeIcon};
    const itemUnlike = {name: 'unlike', body: unlike, src: likeIcon};
    const itemComment = {name: 'comment', body: comment, src: commentIcon};
    const itemPin = {name: 'pin', body: pin, src: pinIcon};
    const itemPinned = {name: 'pinned', body: unpin, src: pinnedIcon};
    const itemCopy = {name: 'copy', body: copy, src: copyIcon};
    const itemDelete = {name: 'delete', body: deleting, src: deleteIcon};
    const itemRead = {name: 'read', body: read, src: unreadIcon};

    const myId = parseInt(getUserId());
    const announcementRead = data.readList && data.readList.includes(myId) ? true : false;

    if(isAnnouncement(data.post_type)) {
      items = [itemRead, itemComment, itemCopy, itemDelete];
      if(announcementRead) {
        items = items.filter((el) => el.name !== 'read');
      }
      if(activeChannel && activeChannel.shyft_channel) {
        items = items.filter((el) => el.name !== 'comment');
      }
    }else {
      if(!data.pinned) {
        if(!data.liked) {
          items = [itemLike, itemComment, itemPin, itemCopy, itemDelete];
        }else {
          items = [itemUnlike, itemComment, itemPin, itemCopy, itemDelete];
        }
      }else {
        if(!data.liked) {
          items = [itemLike, itemComment, itemPinned, itemCopy, itemDelete];
        }else {
          items = [itemUnlike, itemComment, itemPinned, itemCopy, itemDelete];
        }
      }

      if(!data.final_allow_like) {
        if(!data.liked) {
          items = items.filter((el) => el.name !== 'like');
        }else {
          items = items.filter((el) => el.name !== 'unlike');
        }
      }
      if(activeChannel && activeChannel.shyft_channel) {
        items = items.filter((el) => el.name !== 'like' && el.name !== 'unlike' && el.name !== 'comment');
      }
    }

    if(!data.final_allow_comment) {
      items = items.filter((el) => el.name !== 'comment');
    }
    if(data.content === null || data.content.length === 0) {
      items = items.filter((el) => el.name !== 'copy');
    }
    if(!data.final_allow_delete) {
      items = items.filter((el) => el.name !== 'delete');
    }

    const menuItems = items.map((item, index) => (
      <styled.MenuItem
        key={index} 
        src={item.src} 
        value={item}
        onClick={(event) => {this.handleActionMenuClicked(item)}}
      >
        <label className="menu__action-dropdown-text">
          {item.body}
        </label>
      </styled.MenuItem>
    ));
    let changeTop = false;
    let newTop = '';
    if(items && items.length <= 2) {
      if(iconPos && (window.innerHeight - iconPos.y < 400)) {
        newTop = `${iconPos.y - 10}px`;
        changeTop = true;
      }
    }
    if(mouseMenuPos) {
      return (
        <styled.WinContainerMenu top={changeTop ? newTop : mouseMenuPos.top} left={mouseMenuPos.left} onMouseLeave={this.mouseLeaveActionMenu}>
          <div className="menu__wrap">
          {menuItems}
          </div>
        </styled.WinContainerMenu>
      );
    }
  }

  handleActionMenuClicked = (item) => {
    const { active, data, clickComment, clickDelete, clickLike, clickPin, clickCopy, clickReadReceipts, pinned, liked, index, clickMoreActions, } = this.props;
    if(item && item.name) {
      switch(item.name) {
      case 'like':
      case 'unlike':
        clickLike();
        break;
      case 'comment':
        clickComment();
        break;
      case 'delete':
        clickDelete();
        break;
      case 'pin':
      case 'pinned':
        clickPin();
        break;
      case 'copy':
        clickCopy();
        break;
      case 'read':
        clickReadReceipts();
        break;
      }
    }
    this.setState({openMoreActionMenu: false});
  }

  mouseLeaveActionMenu = (event) => {
    this.setState({openMoreActionMenu: false});
  }

  handleClickMoreActions = (event) => {
    this.setState({openMoreActionMenu: true});
  }


  render() {
  	const { active, data, clickComment, clickDelete, clickLike, clickPin, clickCopy, clickReadReceipts, pinned, liked, index, activeChannel, } = this.props;
    const { readAnnouncement, mouseOverButton, openMoreActionMenu, } = this.state;
    const myId = parseInt(getUserId());
    const announcementRead = data.readList && data.readList.includes(myId) ? true : false;
    
  	return (
  		<styled.ButtonWrap top={index === 0 ? '0px' : '-15px'} >
  			{data.final_allow_like && !isAnnouncement(data.post_type) && !activeChannel.shyft_channel ? <styled.Button 
          ref={this.likeRef}
          src={likeIcon}
  				onClick={clickLike}
          onMouseEnter={this.mouseEnterButtonLike}
          onMouseLeave={this.mouseLeaveButton}
  			/>: null}
        {(isAnnouncement(data.post_type) && !announcementRead) ? <styled.Button 
          ref={this.readRef}
          src={!announcementRead ? unreadIcon : readIcon}
          onClick={!announcementRead ? clickReadReceipts : null}
          onMouseEnter={this.mouseEnterButtonRead}
          onMouseLeave={this.mouseLeaveButton}
        />: null}
  			{data.final_allow_comment && !activeChannel.shyft_channel ? <styled.Button 
          ref={this.commentRef}
  				src={commentIcon}
  				onClick={clickComment}
          onMouseEnter={this.mouseEnterButtonComment}
          onMouseLeave={this.mouseLeaveButton}
  			/>: null}
  			{!isAnnouncement(data.post_type) ? <styled.Button 
          ref={this.pinRef}
  				src={pinned ? pinnedIcon : pinIcon}
  				onClick={clickPin}
          onMouseEnter={this.mouseEnterButtonPin}
          onMouseLeave={this.mouseLeaveButton}
  			/>: null}
  			{data.content && data.content.length > 0 ? <styled.Button 
          ref={this.copyRef}
  				src={copyIcon}
  				onClick={clickCopy}
          onMouseEnter={this.mouseEnterButtonCopy}
          onMouseLeave={this.mouseLeaveButton}
  			/>: null}
  			{data.final_allow_delete && <styled.Button 
          ref={this.deleteRef}
  				src={deleteIcon}
  				onClick={clickDelete}
          onMouseEnter={this.mouseEnterButtonDelete}
          onMouseLeave={this.mouseLeaveButton}
  			/>}
        {true ? <styled.Button 
          ref={this.moreActionRef}
          src={moreActionIcon}
          onClick={this.handleClickMoreActions}
          onMouseEnter={this.mouseEnterButtonMore}
          onMouseLeave={this.mouseLeaveButtonMore}
        />: null}
        {mouseOverButton && <Modal>
          {this.renderMouseTip()}
        </Modal>}
        {openMoreActionMenu && <Modal>
          {this.renderMoreActionsMenu()}
        </Modal>}
  		</styled.ButtonWrap>
  	);
  }

}

Buttons.propTypes = {
  data: PropTypes.object.isRequired,
};

export default injectIntl(Buttons);