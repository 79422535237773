import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { messageDateFormat, } from 'helpers/date-time';

import elements from '../../elements';
import blocks from '../../blocks';
import styled from './styled';
import './LikersComponent.scss';

const sha1 = require('js-sha1');

class LikersComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={
      //closePinPane: false,
      likeList: [],
    };

    this._isMounted = false;

    this.handleClose = this.handleClose.bind(this);

    this.closePinPane = false;
  }

  componentDidMount() {
    const { active, activePost, allUsers, fetchPostLikeList, fetchPostReaderList, } = this.props;

    this._isMounted = true;

    if(activePost && activePost.likeList) {
      if(activePost.likeList.length > 0) {
        let likers = [];
        if(allUsers) {
          for(let i=0; i<activePost.likeList.length; i++) {
            const liker = allUsers.filter((person) => person.owner_id === activePost.likeList[i]);
            if(liker && liker.length > 0) {
              likers.push(liker[0]);
            }
          }
        }
        this.setState({likeList: likers});
      }
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    const { active, activePost, allUsers, fetchPostLikeList, fetchPostReaderList, } = this.props;

    if(prevProps.activePost !== activePost) {
      if(activePost && allUsers) {
        if(activePost.likeList.length > 0) {
          let likers = [];
          if(allUsers) {
            for(let i=0; i<activePost.likeList.length; i++) {
              const liker = allUsers.filter((person) => person.owner_id === activePost.likeList[i]);
              if(liker && liker.length > 0) {
                likers.push(liker[0]);
              }
            }
          }
          this.setState({likeList: likers});
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserUUID = (userId) => {
    return `user.${sha1(`user.${userId}`)}`;
  };

  handleClose(event) {
    const {resetActiveSidePane} = this.props;

    this.setState({closePinPane: false});
    this.closePinPane = true;
    resetActiveSidePane();
    event.preventDefault();
  }

  render () {
    const {pins, activeSidePane, allUsers, onlineUsers, } = this.props;
    const { likeList, } = this.state;

    if(this.closePinPane){
      this.closePinPane = false;
      return null;
    }
    const title = (<FormattedMessage id="Likers.headerLabel" defaultMessage="Liked By" />);
    const approvedBadge = '/icons/verified-badge-icon.svg';
    
    const likers = likeList.map((user, index) => {
      let profileUrl = '/icons/default-profile-thumb.svg';
      if(user && user.profile_image_thumb_url) {
        profileUrl = user.profile_image_thumb_url;
      }
      const showAdminBadge = user && user.is_admin ? true : false;
      const position = user && user.position ? user.position : '';
      const userUUID = user ? this.getUserUUID(user.owner_id) : '';
      let showOnline = false;
      if(onlineUsers && onlineUsers.includes(userUUID)) {
        showOnline = true;
      }

      const placeholder = '';
      let  bottomLine = null;    
      if(user) {
        if(user.position && !user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.position}</styled.BottomLineTextOneItem>);
        }else if(!user.position && user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.badge_id}</styled.BottomLineTextOneItem>);
        }else if(!user.position && !user.badge_id){
          bottomLine = (<styled.BottomLineTextOneItem>{placeholder}</styled.BottomLineTextOneItem>)  
        }else {
           bottomLine = (    
            <styled.BottomLineWrap>
              <styled.BottomLineText>{user.position}</styled.BottomLineText>
              <styled.Dot/>
              <styled.BottomLineText>{user.badge_id}</styled.BottomLineText>
            </styled.BottomLineWrap>);
        }
      }

      return (
        <styled.MessageWrap key={user.id}>
        <styled.AvatarWrap>
          <styled.ProfileAvatar src={profileUrl}/>
          {showOnline && <styled.OnlineMark/>}
        </styled.AvatarWrap>
        <styled.InfoWrap>
          <styled.Name>
            {user !== undefined ? `${user.first_name} ${user.last_name}` : null}
            {showAdminBadge && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.Name>
          {bottomLine}
        </styled.InfoWrap>
        </styled.MessageWrap>
        );
    });

    return (
      <elements.ColumnWrap>
        <div className="liker__wrap">
          <p className="liker__title">{title}</p>
          <button className="liker__close-button" onClick={this.handleClose}/>
        </div>
        <elements.ScrollWrap isLoading={false}>
        {likers}
        {false && <elements.Loading />}
        </elements.ScrollWrap>
      </elements.ColumnWrap>
    );

  }
}

LikersComponent.propTypes = {
  //pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(LikersComponent);

