import React from 'react';
import { connect,} from 'react-redux';
import { unregisteredUserOperations, } from './duck';
import InviteWithEmailComponent from './InviteWithEmailComponent';

const mapDispatchToProps = (dispatch) => {
    const updateUnregisteredUserDispatch = (unregistedUserId,  email) => {
      dispatch(unregisteredUserOperations.updateUnregisteredUserOperation(unregistedUserId, email));
    }
    return {
      updateUnregisteredUserDispatch
    };
  };

const InviteWithEmailContainer = connect(
    null,
  mapDispatchToProps
)((props) => {
    const {handleCloseInviteModal, userToInvite, locationId, updateUnregisteredUserDispatch,locationData} = props;

  return (
    <InviteWithEmailComponent
        handleCloseInviteModal = {handleCloseInviteModal} 
        userToInvite = {userToInvite}
        locationId = {locationId}
        locationData = {locationData}
        updateUnregisteredUserDispatch = {updateUnregisteredUserDispatch}
    />
  );
});

export default InviteWithEmailContainer;
