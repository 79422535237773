import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
    settings: slices.settingsSlice.reducer,
};

const reducer = combineReducers({
  settings: reducers.settings,
});

export default reducer;