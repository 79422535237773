import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import { getUserInfoNonAdmin, } from 'helpers/api-calls/user-calls';
import ScheduleRowComponent from './ScheduleRowComponent';
import constants from '../../constants';

import './TableComponent.scss';
import styled from './styled';

const LoadingLayer = ({ loading }) => {
  // return (
  //   <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
  //     <img src="/loader.gif" alt="Loading gif" />
  //   </div>
  // );
  return (
    <div className={`delete--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

class ScheduleTableComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      filesToUpload: [],
      upload: false,
      sortedActionJobs: [],
      scheduleOwners: [],
      rowMenuOpened: false,
      deleteInProgress: false,
    };


    this.fileInput = React.createRef(null);

    this._isMounted = false;
  }

  componentDidMount() {
    const { locationId, getLocationSchedules,  getLocationActionJobs, loading, actionJobs, notifyActionJobChanged, allUsers, } = this.props;

    this._isMounted = true;
    /*
    if(locationId) {
      getLocationSchedules(locationId);
      getLocationActionJobs(locationId);
    }
    */
    const copyArray = [...actionJobs];
    this.sortSchedulesByDate(copyArray, true);
    this.setState({sortedActionJobs: copyArray});
    notifyActionJobChanged(copyArray);

    this.setState({rowMenuOpened: false});
  }

  componentDidUpdate(prevProps, prevState) {
    const { locationId, schedules, loading, actionJobs, notifyActionJobChanged, searchTerm, selectedSort, ascending, allUsers, } = this.props;
    
    if(prevProps.schedules !== schedules) {
      //console.log('got schedules');
    }

    if(prevProps.loading !== loading) {
      //console.log('loading changed');
    }

    if(prevProps.allUsers !== allUsers) {
      //console.log('allusers');
    }

    if(prevProps.actionJobs !== actionJobs) {
      if(actionJobs && selectedSort) {
        const output = this.sortActionJobs(actionJobs, selectedSort.name, ascending);
        this.setState({sortedActionJobs: output});
        notifyActionJobChanged(output);
      }

      if(prevProps.actionJobs && actionJobs.length < prevProps.actionJobs.length)  {
        this.setState({deleteInProgress: false});
      }
    }

    if(prevProps.searchTerm !== searchTerm) {
      this.searchJobs(searchTerm);
    }

    if(prevProps.selectedSort !== selectedSort) {
      if(actionJobs && selectedSort) {
        const output1 = this.sortActionJobs(actionJobs, selectedSort.name, ascending);
        this.setState({sortedActionJobs: output1});
        notifyActionJobChanged(output1);
      }
    }

    if(prevProps.ascending !== ascending) {
      if(actionJobs && selectedSort) {
        const sortedArr = this.sortActionJobs(actionJobs, selectedSort.name, ascending);
        this.setState({sortedActionJobs: sortedArr});
        notifyActionJobChanged(sortedArr);
      }
    }
    
  }

  createHeaders = () => {
    const fileName = (<FormattedMessage
                id="TableComponent.fileName"
                defaultMessage="File Name" />);
    const owner = (<FormattedMessage
                id="TableComponent.owner"
                defaultMessage="Owner" />);
    const status = (<FormattedMessage
                id="TableComponent.status"
                defaultMessage="Status" />);
    const total = (<FormattedMessage
                id="TableComponent.total"
                defaultMessage="Total" />);
    const succeeded = (<FormattedMessage
                id="TableComponent.succeeded"
                defaultMessage="Succeeded" />);
    const failed = (<FormattedMessage
                id="TableComponent.failed"
                defaultMessage="Failed" />);
    let headerItems = [fileName, owner, status, total, succeeded, failed];
    
    const Headers = () => {
      const headers = headerItems.map((item, index) => {
        return (
          <th key={index} className="schedule-table__header-title">
            {item}
          </th>
        );
      });
      return headers;
    };

    return (
      <thead>
        <tr className="schedule-table__headers">
          <Headers />
        </tr>
      </thead>
    );
  };

  sortSchedulesByDate = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;
    /*
    const sorter = function(a, b) {
      if (a.created_at === b.created_at) // identical? return 0
        return 0;
      else if (a.created_at === null)  // a is null? last 
        return 1;
      else if (b.created_at === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return moment(b.created_at).isBefore(moment(a.created_at)) * multiplier;
      }                  
    }
    return arr.sort(sorter);
    */

    arr.sort(function(a, b) {
      var c = new Date(a.created_at);
      var d = new Date(b.created_at);
      if(ascending) {
        return d-c;
      }else {
        return c-d;
      }
    });
  }

  /*
  sortSchedulesByDate1 = (arr, ascending) => {
    if (typeof(ascending) === "undefined") {
      ascending = true;
    }
    const multiplier = ascending ? 1 : -1;
    return arr.sort((a, b) => {
      return moment(b.created_at).isBefore(moment(a.created_at)) * multiplier;
    });
  };
  */

  sortFileNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.record_meta.input_sheet_original_filename === b.record_meta.input_sheet_original_filename) // identical? return 0
        return 0;
      else if (a.record_meta.input_sheet_original_filename === null)  // a is null? last 
        return 1;
      else if (b.record_meta.input_sheet_original_filename === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.record_meta.input_sheet_original_filename.localeCompare(b.record_meta.input_sheet_original_filename) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortShiftCountWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;


    const sorter = function(a, b) {
      if (a.total_count === b.total_count) // identical? return 0
        return 0;
      else if (a.total_count === null)  // a is null? last 
        return 1;
      else if (b.total_count === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return (a.total_count - b.total_count) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  searchJobs = (term) => {
    const { actionJobs, notifyActionJobChanged, } = this.props;
    const { scheduleOwners, sortedActionJobs, } = this.state;

    let results = [];
    if(term && term.length > 0) {
      const foundByOwner = scheduleOwners.filter((item) => (item.owner.first_name && item.owner.first_name.toLowerCase().includes(term.toLowerCase())) || 
        (item.owner.last_name && item.owner.last_name.toLowerCase().includes(term.toLowerCase())));
      if(foundByOwner && foundByOwner.length > 0) {
        for(let i=0; i<foundByOwner.length; i++) {
          for(let j=0; j< actionJobs.length; j++) {
            if(actionJobs[j].schedule_id === foundByOwner[i].schedule_id) {
              results.push(actionJobs[j]);
            }
          }
        }
      }else {
        const foundById = scheduleOwners.filter((item) => (item.schedule_id && item.schedule_id.toString() === term));
        if(foundById && foundById.length > 0) {
          for(let i1=0; i1<foundById.length; i1++) {
            for(let j1=0; j1< actionJobs.length; j1++) {
              if(actionJobs[j1].schedule_id === foundById[i1].schedule_id) {
                results.push(actionJobs[j1]);
              }
            }
          }
        }else {
          const foundByFileName = actionJobs.filter((job) => job.record_meta && job.record_meta.input_sheet_original_filename &&
            job.record_meta.input_sheet_original_filename.toLowerCase().includes(term.toLowerCase()));
          if(foundByFileName && foundByFileName.length > 0) {
            results = foundByFileName;
          }
        }
      }
      this.setState({sortedActionJobs: results});
      notifyActionJobChanged(results);
    }else {
      const copyArray = [...actionJobs];
      this.sortSchedulesByDate(copyArray, true);
      this.setState({sortedActionJobs: copyArray});
      notifyActionJobChanged(copyArray);
    }
  }

  notifyScheduleOwner = (scheduleId, userId, owner) => {
    const scheduleOwner = {
      schedule_id: scheduleId,
      user_id: userId,
      owner: owner,
    };
  
    let {scheduleOwners} = this.state;
    const idx = scheduleOwners.findIndex((item) => item.schedule_id === scheduleId);
    if(idx < 0) {
      scheduleOwners.push(scheduleOwner);
    }
    this.setState({scheduleOwners: scheduleOwners});
  }

  notifyMenuOpened = (opened) => {
    this.setState({rowMenuOpened: opened});
  }

  notifyScheduleAboutToDelete = () => {
    this.setState({deleteInProgress: true});
  }

  sortActionJobs = (jobs, sortType, ascending) => {
    //const { actionJobs, } = this.props;
    const { sortTypes, } = constants;

    const arrToSort = [...jobs];
     switch(sortType) {
      case sortTypes.DATE:
        this.sortSchedulesByDate(arrToSort, ascending);
        break;
      case sortTypes.FILE_NAME:
        this.sortFileNameWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.SHIFT_COUNT:
        this.sortShiftCountWithNullValues(arrToSort, ascending);
        break;
      }

      return arrToSort;
  }

  render() {
    const { schedules, loading, actionJobs, deleteActionJob, allUsers, handleScheduleDeleted} = this.props;
    const { sortedActionJobs, rowMenuOpened, deleteInProgress, } = this.state;

    //const winH = `${(window.innerHeight - 100)}px`;
    const winH = `${(window.innerHeight-318)}px`;
    
    const scheduleRows = sortedActionJobs.map((actionJob, index) => (
        <ScheduleRowComponent
          key={index} 
          schedule={actionJob}
          notifyScheduleOwner={this.notifyScheduleOwner}
          notifyMenuOpened={this.notifyMenuOpened}
          rowMenuOpened={rowMenuOpened}
          deleteActionJob={deleteActionJob}
          handleScheduleDeleted={handleScheduleDeleted}
          allUsers={allUsers}
          notifyScheduleAboutToDelete={this.notifyScheduleAboutToDelete}
        />
    ));
    
    return (
      <styled.TableWrap height={winH}>
        <LoadingLayer loading={deleteInProgress}/>
        <table className={`schedule-table ${loading ? 'loading' : ''}`}>
          {this.createHeaders()}
          <tbody className="schedule-table__body">
            {scheduleRows}
          </tbody>
        </table>
      </styled.TableWrap>
    );
  }
}

ScheduleTableComponent.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(ScheduleTableComponent);
