import styled from 'styled-components/macro';

import { rem } from 'polished';

const Name = styled.h4`
  //font-size: ${rem('14px')};
  //font-weight: bold;
  //font-family: ${({ theme }) => theme.fonts.secondary};
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 14px;
  font-weight: 200;
  letter-spacing: -0.26px;
  margin-bottom: 0;
  padding: 0;
`;

const LatestContent = styled.p`
  //font-size: ${rem('12px')};
  //font-family: ${({ theme }) => theme.fonts.secondary};
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  //border: 1px solid red;
`;

const TopLineWrap = styled.div`
  display: flex;
  flex-direction: row;
  //border: 1px solid red;
`;
const ApprovedBadge = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 3px;
  //border: 1px solid red;
`;
const Time = styled.p`
  position: absolute;
  right: 30px;
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 400;
  color: rgba(130,134,140,255);
  padding-right: 5px;
  //border: 1px solid blue;
`;

const ChannelAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
  //border: 1px solid red;
`;

const BottomLineWrap = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const NewPostIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  top: 4px;
  right: 18px;
  background-color: rgba(0,134,255,255);
`;

export default {
  Name,
  LatestContent,
  TopLineWrap,
  ApprovedBadge,
  Time,
  ChannelAvatar,
  BottomLineWrap,
  NewPostIndicator,
};
