import React from 'react';
import ReactDOM from 'react-dom';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
//import { ProfileThumb } from '@shyft/acorns';

import Icon from '@mdi/react';
import { mdiBullhorn } from '@mdi/js';

import devices from 'helpers/devices';

import { commentFormat, messageDateFormat, } from 'helpers/date-time';
import { teamFeedShapes } from '../../duck';
import ActionButtons from '../ActionButtons';
import Buttons from '../ActionButtons/Buttons';
import styled from './styled';
import blocksStyled from '../styled';
import './Post.scss';
import ImageWin from './ImageWin';
import Modal from './Modal';
import PDFComponent from '../../components/Posts/PDFComponent';


const sha1 = require('js-sha1');

const isAnnouncement = (post_type) => {
  return [1, 2, 3, 4].includes(post_type);
};


class Post extends React.PureComponent {
    constructor(props){
    super(props);
    this.state = {
      debug: false,
      hovered: false,
      enlarged: false,
      showPictureView: false,
      selectedPicture: '',
      allPictures: [],
      modalWindow: null,
      show: false,
      currentIdx: -1,
      currentTarget: null,
      theUser: null,
      likeList: [],
      readList: [],
      mouseOverCount: false,
      mouseTipPos: null,
      likeIconPos: null,
    };
    this.messageWrapRef=React.createRef();
    this.attachedImageRef = React.createRef();
    this.imageContainerRef = React.createRef();
    this.nwRef = React.createRef();
    this.countRef = React.createRef();
    this.readCountRef = React.createRef();
    this.handleTitleClick = this.handleTitleClick.bind(this);
    this.handleClickLink = this.handleClickLink.bind(this);

    this.selectedUrl = '';
    this.metaUrl = '';
    this.prevHeight = 0;

  }

  componentDidMount() {
    const {data, allUsers, onlineUsers, fetchPostLikeList, fetchPostReaderList, } = this.props;
    const { theUser, } = this.state;
    const {attachment} = data;
    if(attachment){
      this.setState({allPictures: attachment.object_instances});
    }
    if(data && allUsers) {
      const users = allUsers.filter((user) => user.owner_id === data.owner_id);
      if(users && users.length > 0) {
        this.setState({theUser: users[0]});
      }
    }
   
    window.addEventListener("mousemove", this.logMousePosition);
  }

  componentDidUpdate(prevProps, prevState) {
    const {data, allUsers, onlineUsers, notifyPostLikedChanged, receipts, } = this.props;
    const { theUser, readList, } = this.state;

    if(prevProps.data !== data) {
      if(data && allUsers) {
        const users = allUsers.filter((user) => user.owner_id === data.owner_id);
        if(users && users.length > 0) {
          this.setState({theUser: users[0]});
        }
      }
      if(data.liked !== prevProps.data.liked) {
        if(notifyPostLikedChanged) {
          notifyPostLikedChanged(data.id, data.liked);
        }
      }

      if(data.likeList) {
        if(data.likeList.length > 0) {
          let likers = [];
          if(allUsers) {
            for(let i=0; i<data.likeList.length; i++) {
              const liker = allUsers.filter((person) => person.owner_id === data.likeList[i]);
              if(liker && liker.length > 0) {
                likers.push(liker[0]);
              }
            }
          }
          this.setState({likeList: likers});
        }
      }
      if(data.readList) {
        if(data.readList.length > 0) {
          let readers = [];
          if(allUsers) {
            for(let j=0; j<data.readList.length; j++) {
              const reader = allUsers.filter((person) => person.owner_id === data.readList[j]);
              if(reader && reader.length > 0) {
                readers.push(reader[0]);
              }
            }
          }
          this.setState({readList: readers});
        }
      }

      if(data.attachment) {
        this.setState({allPictures: data.attachment.object_instances});
      }
    }

    if(prevProps.allUsers !== allUsers) {
      if(allUsers && allUsers.length > 0) {
        if(data) {
          const users = allUsers.filter((user) => user.owner_id === data.owner_id);
          if(users && users.length > 0) {
            this.setState({theUser: users[0]});
          }
        }
      }
    }
  }

  componentWillUnmount(){
    if(this.state.modalWindow && !this.state.modalWindow.closed){
      this.state.modalWindow.close();
    }
    window.removeEventListener("mousemove", this.logMousePosition);

 }

  getUserUUID = (userId) => {
    return `user.${sha1(`user.${userId}`)}`;
  };

  logMousePosition = (e) => {
    const { likeIconPos, } = this.state;
    if(likeIconPos) {
      if(e.clientX < likeIconPos.x && e.clientY > likeIconPos.y) {
        this.setState({mouseOverCount: false});
      }else if(e.clientX > likeIconPos.x + likeIconPos.width && e.clientY > likeIconPos.y) {
        this.setState({mouseOverCount: false});
      }else if(e.clientY > likeIconPos.y + likeIconPos.height) {
        this.setState({mouseOverCount: false});
      }
    }
  }

  handleMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  handleImageClick = (event) => {
    //this.setState({enlarged: !this.state.enlarged});
    this.setState({showPictureView: !this.state.showPictureView});
    this.setState({selectedPicture: event.currentTarget.currentSrc});
    this.selectedUrl = event.currentTarget.currentSrc;
    this.setState({currentTarget: event.currentTarget});
    this.setState({show: true});
    
    /*
    const url = "about:blank";
    const windowName = "Picture View";
    const windowFeatures = "left=100, top=100,width=1200,height=675,menubar=no,location=yes,resizable=no,scrollbars=yes,status=no";
    const windowFeatures1 = "left=100, top=100,width=600,height=450";
    this.state.modalWindow = window.open(url, windowName,windowFeatures);
    this.renderPopOut();
    */

    let currentUrl=event.currentTarget.currentSrc;
    let idx = this.findSelected(currentUrl);
    this.setState({currentIdx: idx});
    
    event.preventDefault();

  };

  handleTitleClick = (event) => {
    window.open(this.metaUrl);
    event.preventDefault();
  };

  handleClickLink = (event) => {
    window.open(this.metaUrl);
    event.preventDefault();
  };

  handleUrlClick = (event) => {
    window.open(event.target);
    event.preventDefault();
  }

  containUrl(text){
    var s;
    var index1, index2, index3, index4;
    
    if(!text){
      return false;
    }
    s = text.toLowerCase();
    if(s.length < 5){
      return false;
    }
    index1 = s.indexOf("http");
    if(index1 < 0){
      return false;
    }
    index2 = s.indexOf(":");
    if(index2 <= 0){
      return false;
    }else{
      if(index2 < index1){
        let charToCheck; // "Please visit : https://www......,looking for ":" after "http"
        charToCheck = s.charAt(index1 + 3 + 1); // check char after 'http'
        if(charToCheck === 's'){
          charToCheck = s.charAt(index1+ 3 + 2) // check char after 'https'
          if(charToCheck === ':'){
            index2 = index1 + 3 + 2;
          }
        }else if(charToCheck === ':'){
          index2 = index1 + 3 + 1;
        }else{
          return false;
        }
      }
    }
    index3 = s.indexOf("//");
    if(index3 <= 0){
      return false;
    }
    index4 = s.indexOf(".");
    if(index4 <= 0){
      return false;
    }
    if(index4 <= index3 || index3 <= index2 || index2 <= index1){
      return false;
    }

    return true;
  }

  extractUrl(text) {
    const urlRegex = /(https?:\/\/[^ ]*)/;
    let start, end;
    let s;

    if(!text){
      return null;
    }
    s = text.toLowerCase();
    if(!this.containUrl(s)){
      return null;
    }
    var url = s.match(urlRegex)[1];
    if(url){
      let beforeUrl, afterUrl;
      const lastChar = url.charAt(url.length-1);
      if(lastChar === ',' || lastChar === '.'){
        url = url.slice(0, -1); // remove last char
      }
      start = s.indexOf(url);
      if(start > 0){
        beforeUrl = text.substring(0, start);
        afterUrl = text.substring(start+url.length, text.length)
        return <a>{beforeUrl} <a href={url} onClick={this.handleUrlClick}>{url}</a>{afterUrl}</a>;
      }else{
        if(s.length > url.length){
          afterUrl = text.substring(url.length, text.length)
          return <a><a href={url} onClick={this.handleUrlClick}>{url}</a>{afterUrl}</a>;
        }
        else{
          return <a href={url} onClick={this.handleUrlClick}>{url}</a>;
        }
      }
    }else{
      return null;
    }
  }

  renderPopOut() {
    if (this.state.modalWindow) {
      let root = this.state.modalWindow.document.body;
      let idx = this.findSelected(this.selectedUrl);
      
      ReactDOM.render(
          
          <ImageWin  selected={event.currentTarget.currentSrc}
                                allPictures={this.state.allPictures}
                                replacedElement={event.currentTarget}
                                enlarged={this.state.enlarged}
                                currentIdx={idx}
                                closeWin={this.closeModalWindow}
                                /> 
           
                                ,root);
    }
  }

  handleSubmit = (event) =>{

  };

  findSelected = (picTofind) =>{
    const {allPictures} = this.state;

    for(var i=0; i<allPictures.length; i++){
        if(allPictures[i].thumb_url===picTofind || 
          allPictures[i].gallery_url===picTofind ||
          allPictures[i].original_url===picTofind ||
          allPictures[i].full_url===picTofind){
          return i;
        }
    }
    return -1
  }

  closeModalWindow = () =>{
    if(this.state.modalWindow){
      this.state.modalWindow.close();
    }

    this.setState({show: false});
  }

  renderPDF = (pdfObjects) => {

    return pdfObjects.map((pdf, index) => {
      const sizeText = `${Math.round(pdf.byte_size/1000)} KB`;
      return (
        <styled.PDFWrap
          key={pdf.id}
          onClick={() => {
            console.log(pdf.blob_url);
            window.open(pdf.service_url);
          }}>
          <styled.PDFIcon 
            src='/icons/pdf-icon.svg'>
          </styled.PDFIcon>
          <styled.PDFProps>
          <styled.PDFName>{pdf.filename}</styled.PDFName>
          <styled.PDFSizeWrap>
            <styled.PDFSize>PDF</styled.PDFSize>
            <styled.Circle/>
            <styled.PDFSize>{sizeText}</styled.PDFSize>
          </styled.PDFSizeWrap>
        </styled.PDFProps>
        </styled.PDFWrap>
      );
    });
  };

  mouseEnterCount = (event) => {
    let winTop = '';
    let winLeft = '';
    let output = null;
    const winW = '160px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.countRef && this.countRef.current) {
      const domNode = ReactDOM.findDOMNode(this.countRef.current);
      output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height -30}px`;
        winLeft = `${output.x - 78}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({mouseTipPos: winData, likeIconPos: output, mouseOverCount: true});
  }

  mouseLeaveCount = (event) => {
    //this.setState({mouseOverCount: false});
  }

  HandleMouseEnterTip = (event) => {

  }

  HandleMouseLeaveTip = (event) => {
    this.setState({mouseOverCount: false});
  }

  HandleOthersClicked = (event) => {
    const { setActiveSidePane, setActivePost, data, activeChannel, } = this.props;

    this.setState({mouseOverCount: false});
    if(!isAnnouncement(data.post_type)) {
      if(setActiveSidePane) {
        setActiveSidePane('likers');
      }
      if(setActivePost && data && activeChannel) {
        setActivePost(data.id, activeChannel.channel_name);
      }
    }else {
      if(setActiveSidePane) {
        setActiveSidePane('read-receipts');
      }
      if(setActivePost && data && activeChannel) {
        setActivePost(data.id, activeChannel.channel_name);
      }
    }
  }

  renderCountMouseTip = (isAnnouncement) => {
    const { mouseTipPos, likeList, readList, } = this.state;

    const mouseTipStyle = {
      top: mouseTipPos.top,
      left: mouseTipPos.left,
      minWidth: mouseTipPos.width,
      height: mouseTipPos.height,
      position: 'absolute',
      backgroundColor: 'black',
      color: 'white',
      boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
      zIndex: 2,
      //overflow: 'scroll',
      borderRadius: '5px',
      paddingTop: '10px',
      display: 'flex',
      flexDirection: 'row',
      //border: '1px solid red',
    };

    const checkMarkStyle = {
      width: '18px',
      height: '18px',
      marginLeft: '12px',
      marginTop: '12px',
      //marginRight: '0px',
    };

    const messageWrapStyle = {
      paddingLeft: 0,
      //border: '1px solid red',
    };

    let displayText = '';
    let user1 = '';
    let user2 = '';
    let reader1 = '';
    let reader2 = '';
    let numOthers = 0;
    
    if(!isAnnouncement) {
      if(likeList && likeList.length > 0) {
        if(likeList.length === 1) {
          user1 = `${likeList[0].first_name} ${likeList[0].last_name}`;
        }else if(likeList.length >= 2) {
          user1 = `${likeList[0].first_name} ${likeList[0].last_name}`;
          user2 = `${likeList[1].first_name} ${likeList[1].last_name}`;
        }
      }
      numOthers = likeList && likeList.length > 2 ? likeList.length -2 : 0;
    }else {
      if(readList && readList.length > 0) {
        if(readList.length === 1) {
          reader1 = `${readList[0].first_name} ${readList[0].last_name}`;
        }else if(readList.length >= 2) {
          reader1 = `${readList[0].first_name} ${readList[0].last_name}`;
          reader2 = `${readList[1].first_name} ${readList[1].last_name}`;
        }
      }
      numOthers = readList && readList.length > 2 ? readList.length -2 : 0;
    }

    const and = (<FormattedMessage
      id="Post.and"
      defaultMessage="and"
    />);
    const likedBy = (<FormattedMessage
      id="Post.likedBy"
      defaultMessage="Liked by {user1}"
      values={{user1,}}
    />);
    const likedBy2 = (<FormattedMessage
      id="Post.likedBy2"
      defaultMessage="Liked by {user1}, {user2}"
      values={{user1, user2,}}
    />);
    const likedByMore = (<FormattedMessage
      id="Post.likedByMore"
      defaultMessage="{numOthers} others"
      values={{numOthers}}
    />);
    const readBy = (<FormattedMessage
      id="Post.readBy"
      defaultMessage="Read by {reader1}"
      values={{reader1,}}
    />);
    const readBy2 = (<FormattedMessage
      id="Post.readBy2"
      defaultMessage="Read by {reader1}, {reader2}"
      values={{reader1, reader2,}}
    />);
    const readByMore = (<FormattedMessage
      id="Post.readByMore"
      defaultMessage="{numOthers} others"
      values={{numOthers}}
    />);

    if(isAnnouncement) {
      if(readList && readList.length > 0) {
        if(readList.length === 1) {
          displayText = readBy;
        }else if(readList.length >= 2) {
          displayText = readBy2;
        }
      }
    }else {
      if(likeList && likeList.length > 0) {
        if(likeList.length === 1) {
          displayText = likedBy;
        }else if(likeList.length >= 2) {
          displayText = likedBy2;
        }
      }
    }
    
    if(isAnnouncement) {
      return (
        <div style={mouseTipStyle} onMouseEnter={this.HandleMouseEnterTip} onMouseLeave={this.HandleMouseLeaveTip}>
           <img src='/icons/mark-green.png' style={checkMarkStyle} />
          <div style={messageWrapStyle}>
            <div className="count-mouse-tip__top-wrap">{displayText}</div>
            {(readList && readList.length > 2) && 
            <div className="count-mouse-tip__wrap">{and}
            <div className="count-mouse-tip__others" onClick={this.HandleOthersClicked}>{isAnnouncement ? readByMore : likedByMore}</div>
            </div>}
          </div>
        </div>
      );
    }else {
      return (
        <div style={mouseTipStyle} onMouseEnter={this.HandleMouseEnterTip} onMouseLeave={this.HandleMouseLeaveTip}>
          <img src='/icons/like-icon.svg' style={checkMarkStyle} />
          <div style={messageWrapStyle}>
            <div className="count-mouse-tip__top-wrap">{displayText}</div>
            {(likeList && likeList.length > 2) && 
            <div className="count-mouse-tip__wrap">{and}
            <div className="count-mouse-tip__others" onClick={this.HandleOthersClicked}>{isAnnouncement ? readByMore : likedByMore}</div>
            </div>}
          </div>
        </div>
      );
    }
  }

  render() {
    const {
      active,
      data,
      clickComment,
      clickDelete,
      clickLike,
      clickPin,
      className,
      clickReadReceipts,
      clickCopy,
      clickMoreActions,
      allUsers,
      onlineUsers,
      index,
      activeChannel,
    } = this.props;
    const { hovered, enlarged, theUser, debug, mouseOverCount, } = this.state;
    const {
      attachment,
      comments_count,
      content,
      created_at,
      final_allow_comment,
      final_allow_delete,
      final_allow_like,
      post_type,
      pinned,
      liked,
      likes_count,
      owner,
      url_meta,
      attached_files_blobs,
    } = data;

    if(url_meta && url_meta.url){
      this.metaUrl = url_meta.url;
    }

    let urlInContent = this.extractUrl(content);
    if(!urlInContent){
      urlInContent = content;
    }

    let sizeText = '';
    if(attached_files_blobs && attached_files_blobs.length > 0) {
      sizeText = `${Math.round(attached_files_blobs[0].byte_size/1000)} KB`;
    }

    let profileUrl = '/icons/default-profile-thumb.svg';
    if(owner && owner.thumb_url) {
      profileUrl = owner.thumb_url;
    }
    const showAdminBadge = theUser && theUser.is_admin ? true : false;
    const approvedBadge = '/icons/verified-badge-icon.svg';
    const announcementIcon = '/icons/announcement-icon.svg';
    const pinnedIcon = '/icons/pinned-post-icon.svg';
    const userUUID = theUser ? this.getUserUUID(theUser.owner_id) : this.getUserUUID(data.owner_id);
    let showOnline = false;
    if(onlineUsers && onlineUsers.includes(userUUID)) {
      showOnline = true;
    }
    let showReadCount = false;
    let readCount = 0;
    if(data.readList && data.readList.length > 0) {
      showReadCount = true;
      readCount = data.readList.length;
    }

    const announceText = (<FormattedMessage
      id="Post.announcement"
      defaultMessage="Announcement"
    />);

     const pinnedText = (<FormattedMessage
      id="Post.pinned"
      defaultMessage="Pinned"
    />);



    return (
      <styled.MessageWrap
        className={className}
        modifiers={active ? 'active' : null}
        announcement={isAnnouncement(post_type)}
        pinned={pinned}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={() => {
          void 0;
        }}
        ref = {this.messageWrapRef}
      > 
        {isAnnouncement(post_type) ? <styled.AnnouncementWrap>
          <styled.AnnouncementIcon src={announcementIcon}/>
          <styled.AnnouncementTitle>{announceText}</styled.AnnouncementTitle>
        </styled.AnnouncementWrap> :
        (pinned && <styled.AnnouncementWrap>
          <styled.AnnouncementIcon src={pinnedIcon}/>
          <styled.PinnedTitle>{pinnedText}</styled.PinnedTitle>
        </styled.AnnouncementWrap>)
        }
        <styled.AvatarAndInfoWrap>
        {debug && <p>{data.id}</p>}
        <styled.AvatarWrap>
          <styled.ProfileAvatar src={profileUrl}/>
          {showOnline && <styled.OnlineMark/>}
        </styled.AvatarWrap>
        <styled.InfoWrap>
          <styled.Name>
            {owner !== undefined ? `${owner.first_name} ${owner.last_name}` : null}
            {showAdminBadge && <styled.ApprovedBadge src={approvedBadge}/>}
            {/*isAnnouncement(post_type) && (
              <Icon path={mdiBullhorn} size={1} color="#acacac" />
            )*/}
          </styled.Name>
          <styled.Timestamp>{messageDateFormat(created_at)}</styled.Timestamp>
          {!url_meta ? <styled.Content>{urlInContent}</styled.Content> :
            <div>
            <styled.Content>{urlInContent}</styled.Content>
            <styled.UrlMeta>
              {url_meta.logo && <styled.MetaLogo src={url_meta.logo} />}
              {url_meta.publisher && <styled.Publisher>{url_meta.publisher}</styled.Publisher>}
              {url_meta.title && <styled.Title onClick={this.handleTitleClick}>{url_meta.title}</styled.Title>}
              <styled.Content>{url_meta.description}</styled.Content>
              {url_meta.image && <styled.MetaImage src={url_meta.image} />}
              {url_meta.author && <styled.Content>{url_meta.author}</styled.Content>}
              {url_meta.date && <styled.Content>{url_meta.date}</styled.Content>}
              <styled.Content>
                <a href={url_meta.url} onClick={this.handleClickLink}>{url_meta.url}</a>
              </styled.Content>
            </styled.UrlMeta>
            </div>}
            <styled.ImageGroupWrap>
              {!attachment || (
                attachment.object_instances.map((instance, index) =>(
                 <div ref={this.imageContainerRef}>
                  <styled.Image 
                    key={index} 
                    src={instance.thumb_url} 
                    onClick={this.handleImageClick}
                    enlarged={enlarged}
                    ref={this.attachedImageRef}
                    />
                  </div>
                ))
              )}
            </styled.ImageGroupWrap>
            <styled.PDFGroupWrap>
              {attached_files_blobs && this.renderPDF(attached_files_blobs)}
            </styled.PDFGroupWrap>
          {(post_type > 0 && likes_count > 0) ? (liked ? 
                              <styled.LikeCountLiked ref={this.countRef} onMouseEnter={this.mouseEnterCount} onMouseLeave={this.mouseLeaveCount}>
                                <label>{likes_count}</label>
                              </styled.LikeCountLiked> :
                              <styled.LikeCount ref={this.countRef} onMouseEnter={this.mouseEnterCount} onMouseLeave={this.mouseLeaveCount}>
                                <label>{likes_count}</label>
                              </styled.LikeCount>) : 
                              ((!isAnnouncement(post_type)) && <styled.LikeCountPlaceHolder/>)}
          {(isAnnouncement(post_type) && showReadCount) && <styled.ReadCount ref={this.countRef} onMouseEnter={this.mouseEnterCount} onMouseLeave={this.mouseLeaveCount}>
                                <label>{readCount}</label>
                              </styled.ReadCount>}
          {comments_count && final_allow_comment ? (
            <styled.CommentCount onClick={clickComment}>
              <FormattedMessage
                id="Post.commentCount"
                defaultMessage="{count, plural, one {# comment} other {# comments}}"
                values={{ count: comments_count }}
              />
            </styled.CommentCount>
          ) : null}
          {/*hovered &&
          (final_allow_like || isAnnouncement(post_type) || final_allow_delete || final_allow_comment) && (
            <ActionButtons>
              {final_allow_like && !isAnnouncement(post_type) ? (
                <ActionButtons.Button
                  onClick={clickLike}
                  modifiers={liked ? 'liked' : 'like'}
                />
              ) : null}
              <ActionButtons.Button
                onClick={clickPin}
                modifiers={pinned ? 'pinned' : 'pin'}
              />
              {isAnnouncement(post_type) ? (
                <ActionButtons.Button
                  onClick={clickReadReceipts}
                  modifiers="readReceipts"
                />
              ) : null}
              {final_allow_comment ? (
                <ActionButtons.Button
                  onClick={clickComment}
                  modifiers="comment"
                />
              ) : null}
              {!final_allow_delete || (
                <ActionButtons.Button
                  onClick={clickDelete}
                  modifiers="delete"
                />
              )}
            </ActionButtons>
          )*/}
          {(hovered && post_type > 0) && 
           <Buttons 
            active={active}
            data={data}
            clickComment={clickComment}
            clickDelete={clickDelete}
            clickLike={clickLike}
            clickPin={clickPin}
            clickCopy={clickCopy}
            clickReadReceipts={clickReadReceipts}
            //clickMoreActions={clickMoreActions}
            pinned={pinned}
            liked={liked}
            index={index}
            activeChannel={activeChannel}
           />
          }

        </styled.InfoWrap>
        </styled.AvatarAndInfoWrap>
        {this.state.show && <Modal>
           <ImageWin  selected={this.state.selectedPicture}
                                allPictures={this.state.allPictures}
                                replacedElement={this.state.currentTarget}
                                enlarged={this.state.enlarged}
                                currentIdx={this.state.currentIdx}
                                closeWin={this.closeModalWindow}
                                /> 
        </Modal>}
        {mouseOverCount && <Modal>
          {this.renderCountMouseTip(isAnnouncement(post_type))}
        </Modal>}
      </styled.MessageWrap>
    );
  }
}

Post.propTypes = {
  clickComment: PropTypes.func.isRequired,
  clickDelete: PropTypes.func.isRequired,
  clickLike: PropTypes.func.isRequired,
  clickReadReceipts: PropTypes.func.isRequired,
  clickPin: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  data: teamFeedShapes.postsDataShape.isRequired,
  className: PropTypes.string,
};

Post.defaultProps = {
  className: '',
};

export default Post;
