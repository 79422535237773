import React from 'react';
import { connect } from 'react-redux';
import ShiftApplicantCommentsComponent from './ShiftApplicantCommentsComponent';
import { scheduleCalendarOperations } from 'pages/ScheduleCalendar/duck';

const mapStateToProps = ({
  scheduleCalendarReducer,
}) => {
  return {
    applicantCommentsDictionary: scheduleCalendarReducer.applicantComments.dict,
    allUsers: scheduleCalendarReducer.allUsers.users,
  };
};

const mapDispatchToProps = (dispatch) => {

  const fecthApplicantPostsDispatch = (applicantId) => {
    dispatch(scheduleCalendarOperations.fetchApplicantPostsOperation(applicantId));
  }

  const addApplicantCommentDispatch = (applicantId, comment) => {
    dispatch(scheduleCalendarOperations.addApplicantCommentOperation(applicantId, comment));
  }
  
  return {
    fecthApplicantPostsDispatch,
    addApplicantCommentDispatch,
  };
};

const ShiftApplicantCommentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {
    applicantCommentsDictionary,
    applicant,
    fecthApplicantPostsDispatch,
    addApplicantCommentDispatch,
    allUsers,
    handleBackButtonClick,
    applicantId,
    applicantUserId,
    } = props;

  return <ShiftApplicantCommentsComponent
    applicantCommentsDictionary = {applicantCommentsDictionary}
    applicant = {applicant}
    fecthApplicantPostsDispatch = {fecthApplicantPostsDispatch}
    addApplicantCommentDispatch = {addApplicantCommentDispatch}
    allUsers = {allUsers}
    handleBackButtonClick = {handleBackButtonClick}
    applicantId = {applicantId}
    applicantUserId = {applicantUserId}
   /> 
});

export default ShiftApplicantCommentsContainer;