import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { validateEmail } from 'helpers/validation';
import { StartPasswordReset } from 'helpers/api-calls';
import { Redirect, Link } from 'react-router-dom';
import './ForgotPasswordPage.scss';
import '../../components/GeneralComponents/RegistrationDesign.scss'
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';
import {
  checkForAuthentication,
} from 'helpers/cookies';

class ForgotPasswordPagei18n extends React.Component {
  state = {
    email: {
      value: '',
      validity: false,
      showValidity: false,
      submitted: false,
    },
    error: {
      value: '',
      showError: false,
    },
  };

  messages = defineMessages({
    error1: {
      id: 'ForgotPasswordPage.error1',
      defaultMessage: 'There was an error! Please try again',
    },
    error2: {
      id: 'ForgotPasswordPage.error2',
      defaultMessage: "We couldn't find that email! Please try another one",
    },
    error3: {
      id: 'ForgotPasswordPage.error3',
      defaultMessage: 'Please enter a valid email',
    },
    email: {
      id: 'ForgotPasswordPage.email',
      defaultMessage: 'Email',
    },
  });

  _resetPassword = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    StartPasswordReset(this.state.email.value).then(
      (response) => {
        if (response.status === 201) {
          this.setState({
            email: Object.assign(this.state.email, {
              submitted: true,
            }),
          });
        } else {
          this.setState({
            error: Object.assign(this.state.error, {
              value: formatMessage(this.messages.error1),
              showError: true,
            }),
          });
        }
      },
      (error) => {
        if (
          error.response.data.errors.email &&
          error.response.data.errors.email[0] === 'not found'
        ) {

          this.setState({
            error: Object.assign(this.state.error, {
              value: formatMessage(this.messages.error2),
              showError: true,
            }),
          });
        }
      }
    );
  };

  _resendEmail = (e) => {
    e.preventDefault();
    this._resetPassword();
  };

  _errorToShow = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    if (this.state.error.showError && this.state.error.value.length > 0) {
      return this.state.error.value;
    }
    if (this.state.email.showValidity) {
      return formatMessage(this.messages.error3);
    }
    return '';
  };

  handleInputChange = (e) => {
    const { value } = e.target;

    this.setState({
      email: Object.assign(this.state.email, {
        value,
        validity: validateEmail(value),
      }),
    });
  };

  handleNext = () => {
    if (this.state.email.validity) {
      this._resetPassword();
    } else {
      this.setState({
        email: Object.assign(this.state.email, {
          showValidity: true,
        }),
        error: Object.assign(this.state.error, {
          value: '',
          showError: false,
        }),
      });
    }
  };


  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    if (checkForAuthentication()) {
      // User is logged in
      return <Redirect to="/" />;
    }

    if (this.state.email.submitted) {
      return (

        <RegistrationLayout>
          <div className="form-content">
            <div className="welcome-text-wrapper">
              <div className="dot"></div>
              <div className="welcome-text">Email sent</div>
            </div>
            <div className="main-heading">
              <FormattedMessage
                id="LoginPage.resetPassword"
                defaultMessage="Reset Password"
              />
            </div>
            <div className="sub-heading" style={{ marginBottom: '16px', }}>
              A link to reset your password has been sent to {this.state.email.value}
            </div>

            <div className="login-message">
              Didn't get the link?{' '}
              <a href="" onClick={this._resendEmail}>
                <FormattedMessage
                  id="ForgotPasswordPage.resendEmailLink"
                  defaultMessage="Resend"
                />
              </a>
            </div>
            <div className="login-message" style={{ marginTop: '96px', }}>
              Remember it after all?{' '}
              <Link to="/login">
                <FormattedMessage
                  id="ChangePasswordPage.loginUrl"
                  defaultMessage="Login"
                />
              </Link>
            </div>
          </div>
        </RegistrationLayout>
      );
    }

    let errorMessage = this._errorToShow();

    return (
      <RegistrationLayout>
        <div className="form-content">
          <div className="welcome-text-wrapper">
            <div className="dot"></div>
            <div className="welcome-text">Password reset</div>
          </div>
          <div className="main-heading">
            <FormattedMessage
              id="LoginPage.forgotPassword"
              defaultMessage="Forgot your password?"
            />
          </div>
          <div className="sub-heading">Enter the email address associated with your account, and we'll email you a link to reset your password.</div>
          <div className="icon-input-container">
            <input
              placeholder={formatMessage(this.messages.email)}
              className={`icon-name-input ${errorMessage ? 'input-error' : ''}`}
              name="email"
              autoComplete='email'
              onChange={this.handleInputChange}
            />
            <img
              className="input-icon-left"
              src='/icons/email-icon.svg'
            />
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <button className="next-btn" onClick={this.handleNext}>Send Reset Link</button>
        </div>
      </RegistrationLayout>
    );
  }
}

const ForgotPasswordPage = injectIntl(ForgotPasswordPagei18n);

export { ForgotPasswordPage };
