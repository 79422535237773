import React from 'react';
import { checkForAuthentication } from 'helpers/cookies';
import { includes } from 'helpers/validation';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import ReactDOM from 'react-dom';

import PageHeader from 'components/PageHeader/PageHeader';
import './NavPanel.scss';
import { purgeAuthenticationCookie } from 'helpers/cookies';
import Modal from './Modal';

import styled from '../../pages/UserManagement/components/Header/styled';
import constants from '../../pages/UserManagement/constants';
import Confirm from '../../pages/UserManagement/components/Header/Confirm';
import { Redirect } from 'react-router-dom';
import currentUser from 'pages/TeamProfile/duck/slices';

import { Mixpanel } from '../../Mixpanel';

class NavPanelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height,
      requestsActive: false,
      teamActive: false,
      requestsV3Active: false,
      feedActive: false,
      scheduleActive: false,
      logoutActive: false,
      settingsActive: false,
      locationActive: false,
      profileActive: false,
      scheduleCalendarActive: false,
      openLocationMenu: false,
      locationInfo: [],
      currentElement: this.props.options[0] ? this.props.options[0] : undefined,
      currentLocation: null,
      allEmployees: [],
      locationEmployees: [],
      lastLocationIndex: 0,
      selectedPage: '',
      showConfirm: false,
      selectedAction: null,
      selectedSettings: false,
      selectedLocation: false,
      selectedProfile: false,
      dashPos: null,
      calendarPos: null,
      requestPos: null,
      feedPos: null,
      teamPos: null,
      schedulePos: null,
      scheduleCalendarPos: null,
      settingsPos: null,
      locationPos: null,
      profilePos: null,
      dropdownPos: null,
      subscriptionPos: null,
    };

    this._isMounted = false;
    this.locationArrayIdx = 0;

    this.requests = "/calendar/requests";
    this.feed = "/team-feed";
    this.management = "/usermanagement";
    this.requestsv3 = "/requests/requests";
    this.scheduler = "/scheduler";
    this.addLoc = "/add-location-authenticated";
    this.scheduleCalendar = "/schedulecalendar";
    this.settingsPage = "/settings/subscription";


    this.requestsRef = React.createRef();
    this.feedRef = React.createRef();
    this.teamRef = React.createRef();
    this.requestsV3Ref = React.createRef();
    this.scheduleRef = React.createRef();
    this.settingsRef = React.createRef();
    this.locationRef = React.createRef();
    this.profileRef = React.createRef();
    this.addLocRef = React.createRef();
    this.scheduleCalendarRef = React.createRef();
    this.subscriptionRef = React.createRef();

    this.mouseTipW = 50;
    this.mouseTipH = 30;
    this.Mixpanel = require('mixpanel');

  }

  messages = defineMessages({
    employees: {
      id: 'NavPanelComponent.employees',
      defaultMessage: "employees",
      members: "Members",
    },

  });

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevState, prevProps) {
    const { locationId, locations, } = this.props;


    if (this.state.currentLocation === null || (this.state.currentLocation !== null && this.state.currentLocation.id != locationId)) {
      if (locations && locations.length > 0) {
        const current = locations.filter((item) => item.id.toString() == locationId);
        if (current && current.length > 0) {
          this.setState({ currentLocation: current[0] });
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  navigateToTeamFeedPage = (event) => {
    this.setState({ selectedPage: this.feed });
    Mixpanel.track('Community Page');
  }

  navigateToSchedulePage = (event) => {
    this.setState({ selectedPage: this.scheduler });
    Mixpanel.track('Imports Page');
  }

  navigateToUserManagementPage = (event) => {
    this.setState({ selectedPage: this.management });
    Mixpanel.track('User Management Page');
  }

  navigateToRequestsV3Page = (event) => {
    this.setState({ selectedPage: this.requestsv3 });
    Mixpanel.track('Requests Page');
  }

  navigateToAddLocationPage = (event) => {
    this.setState({ selectedPage: this.addLoc, selectedLocation: false });
    Mixpanel.track('Add Location Page');
  }

  navigateToScheduleCalendarPage = (event) => {
    this.setState({ selectedPage: this.scheduleCalendar });
    Mixpanel.track('Schedule Page');
  }

  navigateToSettingsPage = (event) => {
    this.setState({ selectedPage: this.settingsPage });
    Mixpanel.track('Settings Page');
  }

  handleLogOut = (event) => {

    const { userActionTypes } = constants;
    const logOutText = (<FormattedMessage
      id="Header.logout"
      defaultMessage="Log Out?"
    />);
    const action = { name: userActionTypes.LOGOUT, body: logOutText };
    this.setState({ showConfirm: true, selectedAction: action });
    event.preventDefault();
  }

  notifyConfirm = (request) => {
    const { selectedAction, } = this.state;
    const { userActionTypes } = constants;
    this.setState({ showConfirm: false });
    if (request) {
      if (selectedAction.name === userActionTypes.LOGOUT) {
        console.log('notifyConfirm 2 -> purgeAuthenticationCookie()')
        purgeAuthenticationCookie();
        window.location.pathname = '/';
        Mixpanel.reset();
      }
    }
  }

  requestsMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if (this.requestsRef && this.requestsRef.current) {
      const domNode = ReactDOM.findDOMNode(this.requestsRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ requestPos: winData });
    this.setState({ requestsActive: true });
  }

  requestsMouseLeave = (event) => {
    this.setState({ requestsActive: false });
  }

  teamMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if (this.teamRef && this.teamRef.current) {
      const domNode = ReactDOM.findDOMNode(this.teamRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ teamPos: winData });
    this.setState({ teamActive: true });
  }

  teamMouseLeave = (event) => {
    this.setState({ teamActive: false });
  }

  requestsV3MouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if (this.requestsV3Ref && this.requestsV3Ref.current) {
      const domNode = ReactDOM.findDOMNode(this.requestsV3Ref.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ requestsV3Pos: winData });
    this.setState({ requestsV3Active: true });
  }

  requestsV3MouseLeave = (event) => {
    this.setState({ requestsV3Active: false });
  }

  scheduleMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if (this.scheduleRef && this.scheduleRef.current) {
      const domNode = ReactDOM.findDOMNode(this.scheduleRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ schedulePos: winData });
    this.setState({ scheduleActive: true });
  }

  scheduleMouseLeave = (event) => {
    this.setState({ scheduleActive: false });
  }

  scheduleCalendarMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if (this.scheduleCalendarRef && this.scheduleCalendarRef.current) {
      const domNode = ReactDOM.findDOMNode(this.scheduleCalendarRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ scheduleCalendarPos: winData });
    this.setState({ scheduleCalendarActive: true });
  }

  scheduleCalendarMouseLeave = (event) => {
    this.setState({ scheduleCalendarActive: false });
  }

  feedMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if (this.feedRef && this.feedRef.current) {
      const domNode = ReactDOM.findDOMNode(this.feedRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ feedPos: winData });
    this.setState({ feedActive: true });
  }

  feedMouseLeave = (event) => {
    this.setState({ feedActive: false });
  }

  logoutMouseEnter = (event) => {
    this.setState({ logoutActive: true });
  }

  logoutMouseLeave = (event) => {
    this.setState({ logoutActive: false });
  }

  settingsMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if (this.settingsRef && this.settingsRef.current) {
      const domNode = ReactDOM.findDOMNode(this.settingsRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ settingsPos: winData });
    this.setState({ settingsActive: true });
  }

  settingsMouseLeave = (event) => {
    this.setState({ settingsActive: false });
  }

  subscriptionMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if (this.subscriptionRef && this.subscriptionRef.current) {
      const domNode = ReactDOM.findDOMNode(this.subscriptionRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ subscriptionPos: winData });
    this.setState({ subscriptionActive: true });
  }

  subscriptionMouseLeave = (event) => {
    this.setState({ subscriptionActive: false });
  }

  locationMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;

    let top1 = `100px`; //`${(window.innerHeight - 612)/2}px`;
    let left1 = `200px`; //`${(window.innerWidth - 1200)/2}px`;
    const width1 = '200px';//`50%`;
    const height1 = `300px`; //94.9% viewport height

    if (this.locationRef && this.locationRef.current) {
      const domNode = ReactDOM.findDOMNode(this.locationRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
        top1 = `${output.y - 258}px`;
        left1 = winLeft;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    const pos1 = {
      top: top1,
      left: left1,
      width: width1,
      height: height1,
    }
    this.setState({ locationPos: winData });
    this.setState({ locationActive: true });
    this.setState({ dropdownPos: pos1 });
  }

  locationMouseLeave = (event) => {
    this.setState({ locationActive: false });
  }

  profileMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if (this.profileRef && this.profileRef.current) {
      const domNode = ReactDOM.findDOMNode(this.profileRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ profilePos: winData });
    this.setState({ profileActive: true });
  }

  profileMouseLeave = (event) => {
    this.setState({ profileActive: false });
  }

  handleSettingsClicked = (event) => {
    this.setState({ selectedSettings: true, selectedLocation: false, selectedProfile: false });
    event.preventDefault();
  }

  handleSubscriptionClicked = (event) => {
    event.preventDefault();
    window.location.pathname = "/settings/subscription";
    Mixpanel.track('Settings Page');
  }

  handleLocationClicked = (event) => {
    this.setState({ selectedSettings: false, selectedLocation: (!this.state.selectedLocation), selectedProfile: false });
    event.preventDefault();
  }

  handleLocationChange = (location) => {
    const { handleChange, } = this.props;
    this.setState({ selectedSettings: false, selectedLocation: false, selectedProfile: false });
    //Do all things required when  changing location
    handleChange(location.id);
    Mixpanel.track('Change to location', { 'location_id': location.id });
  }



  handleLocationSelected = (location) => {
    console.log('location selected');
  }

  handleLocationEmployees = (index, code, employees) => {
    const { allEmployees, updating } = this.state;

    let tempEmployees = allEmployees;
    for (let i = 0; i < employees.length; i++) {
      const idx = allEmployees.findIndex((item) => (item.id === employees[i].id));
      if (idx < 0) {
        tempEmployees.push(employees[i]);
      }
    }
    this.setState({ allEmployees: tempEmployees });

    let obj = { index: 0, code: 0, numEmployees: 0 };
    obj.index = index;
    obj.code = code;
    obj.numEmployees = employees.length;
    let tempInfo = this.state.locationEmployees;
    tempInfo.push(obj);
    this.setState({ locationEmployees: tempInfo });
    this.setState({ lastLocationIndex: index });
  }

  renderMouseTip = (pos, content) => {

    if (pos) {
      const containerStyle = {
        top: pos.top,
        left: pos.left,
        minWidth: pos.width,
        minHeight: pos.height,
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
      };

      const spanStyle = {
        width: '2px',
        height: '10px',
        marginRight: '0px',
        backgroundColor: 'black',
        //border: '1px solid red',
      };

      const textStyle = {
        minWidth: pos.width,
        height: pos.height,
        backgroundColor: 'black',
        color: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        borderRadius: '7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginLeft: '0px',
        marginRight: '5px',
        fontFamily: 'AvertaLight',
        src: "url('/fonts/averta/Averta-Light.ttf')",
        fontSize: '10px',
        fontWeight: 600,
        //border: '1px solid red',
      }
      return (
        <div style={containerStyle}>
          <span style={spanStyle}></span>
          <div style={textStyle}>{content}</div>
        </div>
      );
    }
  }


  renderLocationItems = () => {
    const { locations, locationId } = this.props;
    const { dropdownPos, } = this.state;

    const locationItems = locations && locations.map((location, index) => (
      <div
        key={index}
        className="employee-page-header__location-item-wrap"
        value={location}
        onClick={(event) => { this.handleLocationChange(location) }}
      >
        {this.locationAvatarImage(location)}

        <div className="location-info-div">
          <label className="employee-page-header__location-name-text">
            {this.locationNameLabel(location)}
          </label>
          <label className="employee-page-header__member-count">
            {this.locationMemberCountLabel(location)}
          </label>
        </div>
        {this.locationSelectedImage(location)}

      </div>
    ));

    if (dropdownPos) {
      const containerStyle = {
        bottom: '42px',
        left: dropdownPos.left,
        width: '320px',
        minHeight: dropdownPos.height,
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        borderRadius: '8px'
      };

      const spanStyle = {
        width: '2px',
        height: '10px',
        marginTop: '250px',
        marginRight: '0px',
      };
      const locationWrapStyle = {
        borderRadius: '8px',
        backgroundColor: '#2c2c2e',
        color: 'white',
        overflow: 'auto',
        maxHeight: '520px',
      }

      return (
        <div style={containerStyle}>
          <span style={spanStyle}></span>
          <div style={locationWrapStyle} id="locations-wrap">
            {locationItems}
            <NavLink
              ref={this.addLocRef}
              to={this.addLoc}
              onClick={this.navigateToAddLocationPage}
            >
              <div className="add-location-item" onClick={this.navigateToAddLocationPage}>
                <span>
                  <FormattedMessage
                    id="UserDropdown.addLocationLink"
                    defaultMessage="+ Add Location"
                    description="Link to Add Location page"
                  />
                </span>
              </div>
            </NavLink>
          </div>
        </div>
      );
    }
  }

  locationAvatarImage = (location) => {

    const locationAvatarImageStyle = {
      width: '36px',
      height: '36px',
      borderRadius: '18px',
      margin: 'auto',
    }
    const locationAvatarContainerStyle = {
      display: 'flex',
    }

    if (location.avatar_blob !== undefined && location.avatar_blob !== null) {
      const url = `${location.avatar_blob.blob_url}`;
      return (<div className="location-avatar-container" style={locationAvatarContainerStyle}>
        <img src={url} style={locationAvatarImageStyle} />
      </div>)
    }
    else {
      return (<div className="location-avatar-container" style={locationAvatarContainerStyle}>
        <img src="/img/channel-placeholder.svg" style={locationAvatarImageStyle} />
      </div>)
    }
  }

  locationSelectedImage = (location) => {

    const locationSelectedImageStyle = {
      width: '16px',
      height: '16px',
      borderRadius: '18px',
      alignSelf: 'center',
      marginLeft: '24px',
    }
    const locationSelectedContainerStyle = {
      display: 'flex',
    }

    if (location.id == this.props.locationId) {
      return (<div className="location-selected-container" style={locationSelectedContainerStyle}>
        <img src="/icons/location-selected-icon.svg" style={locationSelectedImageStyle} />
      </div>)
    } else {
      return
    }
  }

  locationNameLabel = (location) => {
    const info = `${location.location_name}`;
    return info;
  }

  locationMemberCountLabel = (location) => {
    //NeedsLoc
    const info = `${location.swift_code}` + ` • +` + `${location.users_count}` + ` members`;
    return info;
  }

  render() {
    const { page } = this.state;
    if (page) {
      return <Redirect to={page} />;
    }
    const path = window.location.pathname;
    const { allowTeamFeed, allowScheduleUpload, userInfo, user, locationData, } = this.props;
    const {
      requestsActive,
      teamActive,
      requestsV3Active,
      feedActive,
      scheduleActive,
      scheduleCalendarActive,
      showConfirm,
      selectedAction,
      locationActive,
      subscriptionActive,
      profileActive,
      currentLocation,
      selectedSettings,
      selectedLocation,
      selectedProfile,
      requestPos,
      feedPos,
      teamPos,
      requestsV3Pos,
      schedulePos,
      locationPos,
      profilePos,
      scheduleCalendarPos,
      subscriptionPos,
    } = this.state;

    const { categoryTypes } = constants;
    const userName = `${userInfo ? userInfo.first_name : ''} ${userInfo ? userInfo.last_name : ''}`;

    let locationAvatarUrl = '';
    if (currentLocation && currentLocation.avatar_blob) {
      locationAvatarUrl = currentLocation.avatar_blob.blob_url;
    }

    let userAvatarUrl = '';
    if (user && user.profile_image) {
      userAvatarUrl = user.profile_image.thumb_url;
    }

    const settingsClassName = selectedSettings ? 'settings-button-wrap-selected' : 'settings-button-wrap';
    const locationClassName = selectedLocation ? 'button-wrap-selected' : 'button-wrap';
    const profileClassName = selectedProfile ? 'button-wrap-selected' : 'button-wrap';

    const feedText = (<FormattedMessage
      id="NavPanelComponent.feed"
      defaultMessage="Community"
    />);

    const teamText = (<FormattedMessage
      id="NavPanelComponent.team"
      defaultMessage="User Management"
    />);

    const requestsV3Text = (<FormattedMessage
      id="NavPanelComponent.requestsV3"
      defaultMessage="Requests"
    />);

    const scheduleText = (<FormattedMessage
      id="NavPanelComponent.imports"
      defaultMessage="Imports"
    />);

    const scheduleCalendarText = (<FormattedMessage
      id="ScheduleCalendarComponent.schedule"
      defaultMessage="Schedule"
    />);

    const requestsText = (<FormattedMessage
      id="NavPanelComponent.requests"
      defaultMessage="Requests"
    />);

    const subscriptionText = (<FormattedMessage
      id="NavPanelComponent.subscription"
      defaultMessage="Settings"
    />);

    const locationText = (<FormattedMessage
      id="NavPanelComponent.location"
      defaultMessage="Location"
    />);

    const profileText = (<FormattedMessage
      id="NavPanelComponent.profile"
      defaultMessage="Log out"
    />);

    if (
      checkForAuthentication() &&
      !includes(path, 'login') &&
      !includes(path, 'sign-up') &&
      !includes(path, 'password')
    ) {
      return (
        <nav className="vertical-header">
          <header className="header-panel">
            <section className="section">

              <ul className="nav-bar-list">

                {allowScheduleUpload && <li >
                  <NavLink
                    ref={this.scheduleCalendarRef}
                    activeClassName="active-page"
                    className="button btn-nav"
                    to={this.scheduleCalendar}
                    onClick={this.navigateToScheduleCalendarPage}
                    onMouseEnter={this.scheduleCalendarMouseEnter}
                    onMouseLeave={this.scheduleCalendarMouseLeave}
                  >
                    <img
                      className="btn-nav--img"
                      src={scheduleCalendarActive ? '/icons/schedule-icon-active-1.svg' : '/icons/schedule-icon-inactive-1.svg'}
                      width="15"
                      alt="Calendar Icon"
                    />
                  </NavLink>
                </li>}

                <li >
                  <NavLink
                    ref={this.requestsV3Ref}
                    activeClassName="active-page"
                    className="button btn-nav"
                    to={this.requestsv3}
                    onClick={this.navigateToRequestsV3Page}
                    onMouseEnter={this.requestsV3MouseEnter}
                    onMouseLeave={this.requestsV3MouseLeave}
                  >
                    <img
                      className="btn-nav--img"
                      src={requestsV3Active ? '/icons/requests-icon-active.svg' : '/icons/requests-icon-inactive.svg'}
                      width="15"
                      alt="Requests Icon"
                    />
                  </NavLink>
                </li>

                {allowTeamFeed && (
                  <li >
                    <NavLink
                      ref={this.feedRef}
                      activeClassName="active-page"
                      className="button btn-nav"
                      to={this.feed}
                      onClick={this.navigateToTeamFeedPage}
                      onMouseEnter={this.feedMouseEnter}
                      onMouseLeave={this.feedMouseLeave}
                    >
                      <img
                        className="btn-nav--img"
                        src={feedActive ? '/icons/community-icon-active.svg' : '/icons/community-icon-inactive.svg'}
                        width="15"
                        alt="Community Icon"
                      />
                    </NavLink>
                  </li>
                )}

                <li >
                  <NavLink
                    ref={this.teamRef}
                    activeClassName="active-page"
                    className="button btn-nav"
                    to={this.management}
                    onClick={this.navigateToUserManagementPage}
                    onMouseEnter={this.teamMouseEnter}
                    onMouseLeave={this.teamMouseLeave}
                  >
                    <img
                      className="btn-nav--img"
                      src={teamActive ? '/icons/team-icon-active.svg' : '/icons/team-icon-inactive.svg'}
                      width="15"
                      alt="User Management Icon"
                    />
                  </NavLink>
                </li>

                {allowScheduleUpload && <li >
                  <NavLink
                    ref={this.scheduleRef}
                    activeClassName="active-page"
                    className="button btn-nav"
                    to={this.scheduler}
                    onClick={this.navigateToSchedulePage}
                    onMouseEnter={this.scheduleMouseEnter}
                    onMouseLeave={this.scheduleMouseLeave}
                  ><img
                      className="btn-nav--img"
                      src={scheduleActive ? '/icons/schedule-upload-icon-active.svg' : '/icons/schedule-upload-icon-inactive.svg'}
                      width="15"
                      alt="Schedule Icon"
                    />
                  </NavLink>
                </li>}

                {locationData && locationData.plan_id !== null && <li >
                  <NavLink
                    ref={this.subscriptionRef}
                    activeClassName="active-page"
                    className="button btn-nav"
                    to={this.settingsPage}
                    onClick={this.navigateToSettingsPage}
                    onMouseEnter={this.subscriptionMouseEnter}
                    onMouseLeave={this.subscriptionMouseLeave}>
                    <img
                      className="btn-nav--img"
                      src={subscriptionActive ? '/icons/settings-active.svg' : '/icons/settings-inactive.svg'}
                      width="15"
                      alt="Settings Icon"
                    />
                  </NavLink>
                </li>}

              </ul>

              {selectedLocation && <Modal>
                {this.renderLocationItems()}
              </Modal>}

              {requestsActive && <Modal>
                {this.renderMouseTip(requestPos, requestsText)}
              </Modal>}

              {feedActive && <Modal>
                {this.renderMouseTip(feedPos, feedText)}
              </Modal>}

              {teamActive && <Modal>
                {this.renderMouseTip(teamPos, teamText)}
              </Modal>}

              {requestsV3Active && <Modal>
                {this.renderMouseTip(requestsV3Pos, requestsV3Text)}
              </Modal>}

              {scheduleActive && <Modal>
                {this.renderMouseTip(schedulePos, scheduleText)}
              </Modal>}

              {scheduleCalendarActive && <Modal>
                {this.renderMouseTip(scheduleCalendarPos, scheduleCalendarText)}
              </Modal>}

              {subscriptionActive && <Modal>
                {this.renderMouseTip(subscriptionPos, subscriptionText)}
              </Modal>}

              {locationActive && !selectedLocation && <Modal>
                {this.renderMouseTip(locationPos, locationText)}
              </Modal>}

              {profileActive && <Modal>
                {this.renderMouseTip(profilePos, profileText)}
              </Modal>}

              {showConfirm && <Modal>
                <Confirm
                  selectedAction={selectedAction}
                  selectedItem={currentUser}
                  selectedUsers={[currentUser]}
                  selectedType={categoryTypes.LOGOUT}
                  notifyConfirm={this.notifyConfirm}
                />
              </Modal>}
              <styled.Overlay display={showConfirm ? 'block' : 'none'} />
            </section>
          </header>
        </nav>
      )
    }
    if (
      !checkForAuthentication() &&
      (includes(path, 'login') ||
        includes(path, 'sign-up') ||
        includes(path, 'add-location'))
    ) {
      return <> </>;
    }

    return <> </>;
  }
}

NavPanelComponent.propTypes = {

}

export default injectIntl(NavPanelComponent);
