import { injectIntl } from "react-intl";
import React from "react";
import presenter from "pages/ScheduleCalendar/duck/presenter";
import { isValidArray } from "helpers/validation";

export function DetailFieldPostToGroupComponent ({channels, selectGroup, groupDisplayText, disableField}) {

    const postableGroupList = () => {
      // console.log(JSON.stringify(channels));
      if(  isValidArray(channels) === false ) {
        return null 
      }
      const locationFeedText = 'location_feed';
      var sortedChannels = [...channels].sort((a, b) => {
        // console.log(JSON.stringify(a));
        // console.log(JSON.stringify(b));

        if( a.channel_type == locationFeedText && b.channel_type != locationFeedText ) {
          return -1;
        }else if ( a.channel_type != locationFeedText && b.channel_type == locationFeedText ) {
          return 1;
        }else {
          if (a.channel_name > b.channel_name) return 1;
          if (a.channel_name < b.channel_name) return -1;
        }
        return 1;
      });

        return sortedChannels.map( (element) => {
          return <li className='dropdown-li' key ={element.id} onClick={() => selectGroup(element) }> 
                    <label> {element.channel_name} </label><br/>
                    <label className='count-li'> +{element.member_count} members</label>
                </li>
        });
    } 

    return (
        <div> 
            <div className= {"title-label"}> Group </div>
            { <button disabled={disableField} id="dropdownMenuButton1" className="header-button group-header group-placeholder" type="button" data-bs-toggle="dropdown" aria-expanded="false"> 
              { groupDisplayText }
              </button> 
            } 
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {postableGroupList()}
            </ul>
        </div>
    );
}

export default injectIntl(DetailFieldPostToGroupComponent);
