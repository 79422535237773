import React  from "react";
import { FormattedMessage } from 'react-intl';

import './UserHeaderComponent.scss';

class UserHeaderComponent extends React.Component {

    render() {
        const { user } = this.props;
        
        const userImageUrl = user && user.profile_image_thumb_url ?  user.profile_image_thumb_url : '/icons/default-profile-thumb.svg';
        const userName = user ? `${user.first_name} ${user.last_name}` : ''

        return (
                <div className="user-header">
                    <img src={userImageUrl} />
                    <div className="user-wrap">
                        <div className="name-wrap"> <label className="name">{user.first_name} {user.last_name}</label> </div>
                        <label className="job-title">{user.position}</label>
                    </div>
                </div>
            );
        }

    }

    export default UserHeaderComponent;