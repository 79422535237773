import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import uniqBy from 'lodash/uniqBy';

import Modal from 'components/GeneralComponents/Modal';
import { getUserId } from '../../helpers/cookies';
import Category from './components/Category/Category';
import Summary from './components/Summary/Summary';
import Header from './components/Header/HeaderContainer';
import UserTable from './components/UserTable/UserTable';
import ProgressBar from './ProgressBar';
import constants from './constants';
import { MAX_BULK_USERS, USERS_PER_PAGE, MAX_USERS_REMOVE_FROM_GROUP, } from './constants';

import './UserManagementComponent.scss';
import styled from './styled';
import styledConfirm from '../Dashboard/components/styled';
import UpgradePrompt from '../../components/GeneralComponents/UpgradePrompt/UpgradePrompt';

import '../UnregisteredUser/InviteWithEmailComponent';

import utils from './duck/utils';
import filterUtil from './duck/filter';

import { Mixpanel } from '../../Mixpanel';
import AddUnregisteredUserContainer from '../UnregisteredUser/AddUnregisteredUserContainer';
import InviteWithEmailContainer from '../UnregisteredUser/InviteWithEmailContainer';
import EditUserEmployeeIDContainer from './components/UserTable/EditEmployeeDetails/EditUserEmployeeIDContainer';

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`manager--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const sha1 = require('js-sha1');

class UserManagementComponent extends React.Component {
  constructor(props) {
    super(props);
    const { categoryTypes } = constants;

    this.pubnub = props.pubnub;
    this.pubnub.setUUID(`user.${sha1(`user.${getUserId()}`)}`);
    this.sha1 = `user_event.${sha1(`user.${getUserId()}`)}`;

    this.state = {
      debug: true,
      selectedType: categoryTypes.LOCATION,
      selectedIndex: 0,
      selectedItem: null,
      selectedDeptId: -1,
      selectedSkillId: -1,
      selectedUsers: [],
      locationInfo: '',
      userActionDone: false,
      timer: null,
      tableSize: null,
      actionUsers: [],
      processedUsers: [],
      actionInProgress: false,
      requestedOperation: null,
      lastCreatedChannel: null,
      lastCreatedDept: null,
      lastCreatedSkill: null,
      selectedChannel: null,
      selectedDepartment: null,
      selectedSkill: null,
      searchTerm: '',
      selectedSort: null,
      ascending: true,
      popupOpened: false,
      processingUserId: 0,
      planName: '',
      showAddMemberModal: false,
      showSendEmailModal: false,
      showEditEmployeeIDModal: false,
      filterByDepartment: null,
      filterByEmail: null,
      filterByEmployeeId: null,
      filterByGroup: null,
      filterByJobTitle: null,
      filterBySkill: null,
    };

    this._isMounted = false;
    this.singleUser = false;
    this.actionType = '';
    this.calledSearch = false;
    this.apiCalled = false;
    this.managerPageIndex = 1;
    this.associatePageIndex = 1;
    this.pendingUserPageIndex = 1;
    this.removedUserPageIndex = 1;
    this.allUserPageIndex = 1;
    this.lastDoneTask = null;
    this.localStorageKey = 'edited-user';
    this.prevLocationChannels = [];
    this.subscriptionCalled = [];
  }

  componentDidMount() {
    const { locationId, locationData,
      channels,
      fetchSubscriptions, fetchUnregisteredUsers,
      pendingUsers, allUsers, handleGetUserInformation,
      plans, } = this.props;

    this._isMounted = true;

    const filter = getParameterByName('filter');

    if (filter === 'pending') {
      const pending = (<FormattedMessage
        id="UserManagementComponent.pending"
        defaultMessage="Pending"
      />);
      const itemPending = { name: pending, value: pendingUsers.length };
      this.summaryItemSelected(1, itemPending);
    } else {
      const isNumber = /^\d+$/.test(filter);
      if (isNumber) {
        const id = Number(filter);
        const changedUser = allUsers.filter((user) => user.id === id);
        if (changedUser && changedUser.length > 0) {
          if (locationId) {
            handleGetUserInformation(changedUser[0], locationId);
          }
        }
      }
    }

    const readOut = localStorage.getItem(this.localStorageKey);
    if (readOut) {
      const editedUserId = Number(readOut);
      const editedUser = allUsers.filter((user) => user.id === editedUserId);
      if (editedUser && editedUser.length > 0) {
        if (locationId) {
          handleGetUserInformation(editedUser[0], locationId);
        }
      }
      localStorage.setItem(this.localStorageKey, '');
    }

    this.pubnubSubscribe();
    console.log('UM: pubnub subscribed');
    this.subscriptionCalled = [];
    if (channels && channels.length > 0) {
      for (let i = 0; i < channels.length; i++) {
        if (!this.subscriptionCalled.includes(channels[i].id)) {
          fetchSubscriptions(channels[i].id);
          this.subscriptionCalled.push(channels[i].id);
          //console.log(`88-called for channel ${channels[i].channel_name}`);
        }
      }
    }

    if (locationId !== null) {
      fetchUnregisteredUsers(locationId);
    }

    //Following functions should not be here because componentDidMout called two times.
    if (plans && plans.length > 0) {
      if (locationData && locationData.plan_id) {
        const cplan = plans.find(plan => plan.id === locationData.plan_id);
        this.setState({ planName: cplan.name });
        console.log("1");
        console.log(cplan);
      }
    }
    this.updateUI();
  }

  componentDidUpdate(prevProps, prevState) {
    const { locationId, locationData, fetchRemovedUsers, getLocationInnerDepartments, getLocationUsableSkills, removedUsers,
       departments, channels, skills, fetchSubscriptions, lastAction, handleApprove, handleDeny, handlePromote,
      handleWithdraw, handleAddUserToGroup, handleAddUserToDepartment, error, plans, } = this.props;
    const { selectedType,  selectedUsers, processedUsers, lastCreatedDept, selectedChannel, selectedDepartment, selectedDeptId, } = this.state;
    const { categoryTypes, userActionTypes, operationTypes, } = constants;

    if (!this.apiCalled) {
      if (locationId) {
        getLocationInnerDepartments(locationId);
        getLocationUsableSkills(locationId);
        this.subscriptionCalled = [];
      }
      this.apiCalled = true;
    }

    if (prevProps.removedUsers !== removedUsers) {
      this.updateUI();
      if (prevProps.removedUsers) {
        if (removedUsers.length - prevProps.removedUsers.length === USERS_PER_PAGE) {
          fetchRemovedUsers(locationId, this.removedUserPageIndex, USERS_PER_PAGE);
          this.removedUserPageIndex += 1;
        }
      }
    }

    if (prevProps.departments !== departments) {
      this.updateUI();
      if (departments && departments.length > 0) {
        if (selectedDeptId < 0) {
          this.setState({ selectedDeptId: departments[0].id });
        }
        if (lastCreatedDept) {
          const idx = departments.findIndex((dept) => dept.name === lastCreatedDept.name)
          if (idx >= 0) {
            this.setState({ userActionDone: true });
            this.startTimer(10000);
          }
        }
      }
    }

    if (prevProps.skills !== skills) {
      this.updateUI();
      if (skills && skills.length > 0) {
        this.setState({ selectedSkillId: skills[0].id });
      }
    }

    if (prevProps.channels !== channels) {
      this.updateUI();
      if (channels && channels.length > 0) {
        for (let i = 0; i < channels.length; i++) {
          if (!this.subscriptionCalled.includes(channels[i].id)) {
            fetchSubscriptions(channels[i].id);
            this.subscriptionCalled.push(channels[i].id);
            //console.log(`88-called for channel ${channels[i].channel_name}`);
          }
        }
      }
    }

    if (prevProps.lastAction !== lastAction) {
      if (lastAction && lastAction.ids && lastAction.ids.length > 0) {
        let processed = [...processedUsers];
        const copy = [...selectedUsers];
        if (lastAction.name !== userActionTypes.REMOVE && lastAction.name !== userActionTypes.DELETE_UNREGISTERED &&
          lastAction.name !== operationTypes.ASSIGN_USERS_TO_DEPT && lastAction.name !== operationTypes.REMOVE_USERS_FROM_DEPT &&
          lastAction.name !== operationTypes.ASSIGN_USERS_TO_SKILL && lastAction.name !== operationTypes.REMOVE_USERS_FROM_SKILL) {
          processed = processed.concat(lastAction.ids[0]);
          this.setState({ processedUsers: processed });
          let idx = -1;
          if (lastAction.name !== operationTypes.ASSIGN_USERS_TO_GROUP && lastAction.name !== operationTypes.REMOVE_USERS_FROM_GROUP) {
            idx = selectedUsers.findIndex((user) => user.id === lastAction.ids[0]);
          } else {
            idx = selectedUsers.findIndex((user) => user.owner_id === lastAction.ids[0]);
          }
          if (idx >= 0) {
            copy.splice(idx, 1);
            this.setState({ selectedUsers: copy });
          }
        } else {
          processed = processed.concat(lastAction.ids);
          this.setState({ processedUsers: processed });
          copy.splice(0, lastAction.ids.length);
          this.setState({ selectedUsers: copy });
        }

        if (copy.length > 0) {
          let adminIds = [];
          let nonAdminIds = [];
          let allIds = [];
          for (let i = 0; i < copy.length; i++) {
            if (copy[i].is_admin) {
              adminIds.push(copy[i].id);
            } else {
              nonAdminIds.push(copy[i].id);
            }
          }
          const nonAdmins = copy ? copy.filter((user) => user && user.is_admin === false) : [];
          const admins = copy ? copy.filter((user) => user && user.is_admin === true) : [];
          switch (lastAction.name) {
            case userActionTypes.APPROVE:
              allIds = adminIds.concat(nonAdminIds);
              if (allIds.length > 0) {
                handleApprove(allIds);
              }
              break
            case userActionTypes.DENY:
              if (copy.length > 0) {
                handleDeny(copy, locationId);
              }
              break;
            case userActionTypes.PROMOTE:
              if (nonAdminIds.length > 0) {
                this.setState({ processingUserId: nonAdminIds[0] });
                handlePromote(nonAdmins);
              } else {
                this.setState({ userActionDone: true });
                this.startTimer(10000);
              }
              break;
            case userActionTypes.WITHDRAW:
              if (adminIds.length > 0) {
                this.setState({ processingUserId: adminIds[0] });
                handleWithdraw(admins);
              } else {
                this.setState({ userActionDone: true });
                this.StartTimer(10000);
              }
              break;
            case userActionTypes.REMOVE:
              this.bulkRemoveUsers(admins, nonAdmins)
              break;
            case operationTypes.ASSIGN_USERS_TO_GROUP:
              handleAddUserToGroup(selectedChannel.id, copy);
              break;
            case operationTypes.REMOVE_USERS_FROM_GROUP:
              this.bulkRemoveUsersFromGroup(selectedChannel.id, copy);
              break;
            case operationTypes.ASSIGN_USERS_TO_DEPT:
              const userIds = copy.map((user) => {
                return user.owner_id
              })
              if (userIds && userIds.length > 0) {
                handleAddUserToDepartment(userIds, selectedDepartment.id);
              }
              break;
          }
        } else {
          this.setState({ userActionDone: true, actionInProgress: false, selectedUsers: [], });
          this.updateUI();
          this.startTimer(10000);
          this.singleUser = false;
          this.actionType = '';
        }
      }
    }

    if (prevState.selectedUsers !== selectedUsers) {
      if (selectedUsers && selectedUsers.length === 1) {
        if (this.singleUser) {
          this.notifyActionRequested(true, this.actionType);
        }
      }
    }

    if (prevState.selectedType !== selectedType) {
      this.setState({ searchTerm: '' });
    }

    if (prevProps.error !== error) {
      if (error) {
        this.setState({ userActionDone: true, actionInProgress: false, selectedUsers: [], });
        this.updateUI();
        this.singleUser = false;
        this.actionType = '';
      }
    }

    if (prevProps.locationData !== locationData) {
      if (plans && plans.length > 0) {
        if (locationData && locationData.plan_id) {
          const cplan = plans.find(plan => plan.id === locationData.plan_id);
          this.setState({ planName: cplan.name });
        }
      }
      this.updateUI();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.timer);

    this.pubnub.removeListener();
    this.pubnub.unsubscribe({
      channels: [this.sha1],
    });
  }

  tick = () => {
    if (this._isMounted) {
      this.setState({ userActionDone: false });
      this.setState({ timer: null });
    }
  }

  pubnubSubscribe = () => {
    const { locationId, allUsers, handleGetUserInformation, } = this.props;

    this.pubnub.addListener({
      message: (event) => {
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'user_privilege' &&
          event.message.crud === 'UPDATE'
        ) {
          //updateUser(event.message);
          const changedUser = allUsers.filter((user) => user.id === event.message.node_id);
          if (changedUser && changedUser.length > 0) {
            if (locationId) {
              handleGetUserInformation(changedUser[0], locationId);
            }
          }
        }
      },
    });

    this.pubnub.subscribe({
      channels: [this.sha1],
    });
  };

  startTimer = (timeOut) => {
    const myTimer = setInterval(this.tick, timeOut); //unit: ms
    if (this._isMounted) {
      this.setState({ timer: myTimer });
    }
  }

  updateUI = () => {
    const info = this.createLocationInfo();
    this.setState({ locationInfo: info });
  }

  createLocationCategoryItems = () => {
    const { managers, associates, removedUsers, pendingUsers, departments, channels, skills, unregisteredUsers } = this.props;
    const { operationTypes } = constants;
    let output = [];

    const roster = (<FormattedMessage id="UserManagementComponent.roster" defaultMessage="Roster" />);
    const active = (<FormattedMessage id="UserManagementComponent.active" defaultMessage="Active" />);
    const allUnreg = (<FormattedMessage id="UserManagementComponent.unreigstered" defaultMessage="Unregistered" />);
    const pending = (<FormattedMessage id="UserManagementComponent.pending" defaultMessage="Pending" />);
    const terminated = (<FormattedMessage id="UserManagementComponent.terminated" defaultMessage="Terminated" />);

    const temp = managers.concat(associates);

    const temp1 = temp.filter(user => user && (user.status === 'active'));
    const activeUsers = uniqBy(temp1, 'id');
    const rosterUsers = activeUsers.concat(unregisteredUsers);

    let locationItems = [];

    const objRoster = { name: roster, value: rosterUsers.length };
    locationItems.push(objRoster);

    const objActive = { name: active, value: activeUsers.length };
    locationItems.push(objActive);

    const objUnreg = { name: allUnreg, value: unregisteredUsers.length };
    locationItems.push(objUnreg);

    const objPending = { name: pending, value: pendingUsers.length };
    locationItems.push(objPending);

    const objTerminated = { name: terminated, value: removedUsers.length };
    locationItems.push(objTerminated);


    const objLocation = { id: 'location', items: locationItems };
    output.push(objLocation);

    if (departments && departments.length > 0) {
      let sortedDepts = [...departments];
      utils.sortDeptNameWithNullValues(sortedDepts);

      let departmentItems = [];
      for (let i = 0; i < sortedDepts.length; i++) {
        let deptUsers = [];
        if (activeUsers.length > 0) {
          for (let j = 0; j < sortedDepts[i].user_ids.length; j++) {
            const found = activeUsers.filter((user) => user.owner_id === sortedDepts[i].user_ids[j]);
            if (found && found.length > 0) {
              deptUsers.push(found[0]);
            }
          }
        }

        const dept = { name: sortedDepts[i].name, value: deptUsers.length, id: sortedDepts[i].id };
        departmentItems.push(dept);
      }
      const objDept = { id: 'department', items: departmentItems };
      output.push(objDept);
    }

    if (channels && channels.length > 0) {
      let sortedChannels = [...channels];
      utils.sortChannelNameWithNullValues(sortedChannels, true);

      let channelItems = [];
      for (let j = 0; j < sortedChannels.length; j++) {
        let chl = { name: '', value: 0, id: 0 };

        if( sortedChannels[j].final_allow_manage == true ){
          const members = this.getChannelMembers(sortedChannels[j].id);
          if (members.length > 0) {
            chl = { name: sortedChannels[j].channel_name, value: members.length, id: sortedChannels[j].id };
            channelItems.push(chl);
          } else {
            chl = { name: sortedChannels[j].channel_name, value: sortedChannels[j].member_count, id: sortedChannels[j].id };
            if (this.lastDoneTask && this.lastDoneTask.name === operationTypes.CREATE_GROUP) {
              channelItems.push(chl);
            }
          }
        }
      }
      const objChannel = { id: 'channel', items: channelItems };
      output.push(objChannel);
    }

    if (skills && skills.length > 0) {
      let sortedSkills = [...skills];
      utils.sortSkillNameWithNullValues(sortedSkills, true);

      let skillItems = [];
      for (let k = 0; k < sortedSkills.length; k++) {
        const skillUsers = this.getSkillUsersInLocation(sortedSkills[k]);
        const skl = { name: sortedSkills[k].skill_content, value: skillUsers.length };
        skillItems.push(skl);
      }
      const objSkill = { id: 'skill', items: skillItems };
      output.push(objSkill);
    }

    return output;
  }

  getSkillUsersInLocation = (skill) => {
    const { managers, associates, removedUsers, pendingUsers, departments, channels, skills, } = this.props;
    const temp = managers.concat(associates);
    const totalUsers = uniqBy(temp, 'id'); //Remove duplicated
    let skillUsers = [];
    if (skill) {
      for (let i = 0; i < skill.user_ids.length; i++) {
        for (let j = 0; j < totalUsers.length; j++) {
          if (skill.user_ids[i] === totalUsers[j].owner_id) {
            skillUsers.push(totalUsers[j]);
          }
        }
      }
    }

    return skillUsers;
  }

  getChannelMembers = (channelId) => {
    const { managers, associates, removedUsers, pendingUsers, channels, channelData, } = this.props;
    const activeUsers = managers.concat(associates);
    const temp = activeUsers.concat(pendingUsers, removedUsers);
    const total = uniqBy(temp, 'id'); //Remove duplicates

    const usersHaveId = total.filter((user) => user.user && user.user.channel_ids && user.user.channel_ids.includes(channelId));
    if (usersHaveId && usersHaveId.length > 0) {
      const valid = usersHaveId.filter((item) => item.status === 'active');
      if (valid && valid.length > 0) {
        return valid;
      }
    }
    return [];
  }

  createSummaryElements = (items) => {
    const {  departments, channels, skills, } = this.props;
    const { selectedType, } = this.state;
    const { categoryTypes } = constants;

    if (selectedType === categoryTypes.LOCATION) {
      const idxL = items.findIndex((el) => el.id === 'location');
      if (idxL >= 0) {
        return items[idxL].items;
      }
    } else if (selectedType === categoryTypes.DEPARTMENTS) {
      if (departments && departments.length > 0) {
        const idxD = items.findIndex((el) => el.id === 'department');
        if (idxD >= 0) {
          return items[idxD].items;
        }
      }
      return [];
    } else if (selectedType === categoryTypes.GROUPS) {
      if (channels && channels.length > 0) {
        const idxC = items.findIndex((el) => el.id === 'channel');
        // console.log(JSON.stringify(idxC));
        if (idxC >= 0) {
          return items[idxC].items;
        }
      }
      return [];
    } else if (selectedType === categoryTypes.SKILLS) {
      if (skills && skills.length > 0) {
        const idxS = items.findIndex((el) => el.id === 'skill');
        if (idxS >= 0) {
          return items[idxS].items;
        }
      }
      return [];
    }
  };

  // Location, Departments, Groups
  categorySelected = (selectedType) => {
    this.setState({ selectedType: selectedType , selectedUsers: []});
  };

  // Level 2 summary
  summaryItemSelected = (index, item) => {
    const { departments, skills } = this.props;
    const { selectedType } = this.state;
    const { categoryTypes } = constants;
  
    this.setState({ selectedItem: item, selectedIndex: index, selectedUsers: [] });
  
    let selectedId;
  
    if (selectedType === categoryTypes.DEPARTMENTS) {
      selectedId = departments.find(department => department.id === item.id)?.id;
      this.setState({ selectedDeptId: selectedId });
    } else if (selectedType === categoryTypes.SKILLS) {
      selectedId = skills.find(skill => skill.skill_content === item.name)?.id;
      this.setState({ selectedSkillId: selectedId });
    }
  };

  gatherUsersBySelectedCategory = (type, item, index) => {
    const { managers, associates, removedUsers, pendingUsers, departments, channels, skills, unregisteredUsers } = this.props;
    return filterUtil.filterUsersByCategoryType(type, item, index, managers, associates, removedUsers, pendingUsers, departments, channels, skills, unregisteredUsers );
  };

  notifyUserSelected = (user, selected) => {
    const { selectedUsers } = this.state;
    const userExists = selectedUsers.some((item) => item.owner_id === user.owner_id);
  
    if (selected && !userExists) {
      this.setState({ selectedUsers: [...selectedUsers, user] });
    } else if (!selected && userExists) {
      this.setState({ selectedUsers: selectedUsers.filter(item => item.owner_id !== user.owner_id) });
    }
  };

  notifyAllUserSelected = (selected) => {
    const allUsersToSelect = this.visibleUsers();
    if (selected) {
      this.setState({ selectedUsers: allUsersToSelect})
    } else {
      this.setState({ selectedUsers: [] });
    }
  }

  notifyActionRequested = (request, actionType, user) => {
    const { handleApprove, handleDeny, handlePromote, handleWithdraw, locationId, handleRemoveUnregisteredUser } = this.props;
    const { selectedUsers } = this.state;
    const { userActionTypes } = constants;
  
    this.setState({ userActionDone: false, processedUsers: [], actionInProgress: false });
  
    if (!request) {
      this.setState({ selectedUsers: [] });
      return;
    }
  
    const updateSelectedUsers = (newUser) => {
      this.setState({ selectedUsers: [newUser] });
      this.singleUser = true;
      this.actionType = actionType;
    };
  
    if (user) {
      if (selectedUsers.length === 0 || selectedUsers.some(person => person.id === user.id)) {
        updateSelectedUsers(user);
      } else if (actionType === userActionTypes.DELETE_UNREGISTERED) {
        Mixpanel.track('Remove unregistered user', { 'user_id': user.id });
        updateSelectedUsers(user);
      }
      return;
    }
  
    const adminIds = selectedUsers.filter(u => u.is_admin).map(u => u.id);
    const nonAdminIds = selectedUsers.filter(u => !u.is_admin).map(u => u.id);
    const nonAdmins = selectedUsers.filter(u => !u.is_admin);
    const admins = selectedUsers.filter(u => u.is_admin);
  
    this.setState({ actionUsers: selectedUsers });
  
    switch (actionType) {
      case userActionTypes.APPROVE:
        if (adminIds.length || nonAdminIds.length) {
          const allIds = [...adminIds, ...nonAdminIds];
          handleApprove(allIds);
          Mixpanel.track('Approve users', { count: allIds.length });
          if (allIds.length > 1) this.setState({ actionInProgress: true });
        }
        break;
  
      case userActionTypes.DENY:
        if (selectedUsers.length) {
          handleDeny(selectedUsers, locationId);
          Mixpanel.track('Deny users', { count: selectedUsers.length });
          if (selectedUsers.length > 1) this.setState({ actionInProgress: true });
        }
        break;
  
      case userActionTypes.PROMOTE:
        if (nonAdmins.length) {
          this.setState({ selectedUsers: nonAdmins, processingUserId: nonAdminIds[0] });
          handlePromote(nonAdmins);
          Mixpanel.track('Promote users', { count: nonAdmins.length });
          this.setState({ actionInProgress: true });
        }
        break;
  
      case userActionTypes.WITHDRAW:
        if (admins.length) {
          this.setState({ selectedUsers: admins, processingUserId: adminIds[0] });
          handleWithdraw(admins);
          Mixpanel.track('Demote users', { count: admins.length });
          this.setState({ actionInProgress: true });
        }
        break;
  
      case userActionTypes.ADD:
        console.log('action: add');
        break;
  
      case userActionTypes.REMOVE:
        if (admins.length || nonAdmins.length) {
          this.bulkRemoveUsers(admins, nonAdmins);
          Mixpanel.track('Remove users', { admins: admins.length, non_admins: nonAdmins.length });
        }
        break;
  
      case userActionTypes.DELETE_UNREGISTERED:
        if (selectedUsers.length) {
          const targetUser = selectedUsers[0];
          this.setState({ actionUsers: selectedUsers, actionInProgress: true });
          Mixpanel.track('Remove unregistered user', { id: targetUser.id });
          handleRemoveUnregisteredUser(targetUser.id);
        }
        break;
  
      case userActionTypes.OPEN_MORE:
        console.log('action: open more');
        break;
    }
  };

  bulkRemoveUsers = (admins, nonAdmins) => {
    const { handleRemove, locationId, } = this.props;

    let bulkAdmins = [];
    let bulkNonAdmins = [];
    if (admins.length > MAX_BULK_USERS) {
      bulkAdmins = admins.slice(0, MAX_BULK_USERS);
      const toProcess = admins.slice(MAX_BULK_USERS);
    } else {
      bulkAdmins = admins;
    }

    if (nonAdmins.length > MAX_BULK_USERS) {
      bulkNonAdmins = nonAdmins.slice(0, MAX_BULK_USERS);
      const toProcess = nonAdmins.slice(MAX_BULK_USERS);
    } else {
      bulkNonAdmins = nonAdmins;
    }

    this.setState({ actionInProgress: true });
    handleRemove(bulkAdmins, bulkNonAdmins, locationId);
  }

  bulkRemoveUsersFromGroup = (channelId, users) => {
    const { handleRemoveUserFromGroup, locationId, channelData, } = this.props;
    const members = this.getChannelMembers(channelId);
    handleRemoveUserFromGroup(channelId, users, members.length);
  }

  createLocationInfo = () => {
    const { locationData, locations, locationId } = this.props;

    if (locationData?.location_name) { return locationData.location_name; }
  
    if (locationId && locations?.length > 0) {
      const foundLocation = locations.find(location => location.id === parseInt(locationId));
      if (foundLocation) {
        return foundLocation.location_name;
      }
    }
    return ''; 
  };

  renderStatus = (lastAction) => {
    const { actionUsers, lastCreatedChannel, lastCreatedDept, lastCreatedSkill, } = this.state;
    const { userActionTypes, operationTypes, } = constants;

    let winTop = '500px';
    let winLeft = '300px';
    winTop = `${(window.innerHeight - 100)}px`;
    winLeft = `${(window.innerWidth) / 2}px`;

    const numUsers = actionUsers ? actionUsers.length : 0;
    const channelName = lastCreatedChannel ? lastCreatedChannel.name : '';
    const deptName = lastCreatedDept ? lastCreatedDept.name : '';
    const skillName = lastCreatedSkill ? lastCreatedSkill.name : '';

    const promoteText = (<FormattedMessage id="UserManagementComponent.promote" defaultMessage="{number} users successfully granted admin privileges" values={{ number: numUsers }} />);
    const withdrawText = (<FormattedMessage id="UserManagementComponent.withdraw" defaultMessage="Admin privileges withdrawn for {number} users" values={{ number: numUsers }} />);
    const removeText = (<FormattedMessage id="UserManagementComponent.remove" defaultMessage="{number} users successfully removed from this location" values={{ number: numUsers }} />);
    const approveText = (<FormattedMessage id="UserManagementComponent.approve" defaultMessage="{number} users approved" values={{ number: numUsers }} />);
    const denyText = (<FormattedMessage id="UserManagementComponent.deny" defaultMessage="{number} users denied" values={{ number: numUsers }} />);
    const createdGroup = (<FormattedMessage id="UserManagementComponent.createGroup" defaultMessage="Group {name} successfully created" values={{ name: channelName }} />);
    const createdDept = (<FormattedMessage id="UserManagementComponent.createDept" defaultMessage="Department {name} successfully created" values={{ name: deptName }} />);
    const createdSkill = (<FormattedMessage id="UserManagementComponent.createSkill" defaultMessage="Skill {name} successfully created" values={{ name: skillName }} />);
    const addedToGroup = (<FormattedMessage id="UserManagementComponent.addedToGroup" defaultMessage="{number, plural, one {{number} user successfully added to group} other {{number} users successfully added to group}}" values={{ number: numUsers }} />);
    const removedFromGroup = (<FormattedMessage id="UserManagementComponent.removedFromGroup" defaultMessage="{number, plural, one {{number} user successfully removed from group} other {{number} users successfully removed from group}}" values={{ number: numUsers }} />);
    const addedToDept = (<FormattedMessage id="UserManagementComponent.addedToDept" defaultMessage="{number, plural, one {{number} user successfully added to department} other {{number} users successfully added to department}}" values={{ number: numUsers }} />);
    const removeFromDept = (<FormattedMessage id="UserManagementComponent.removeFromDept" defaultMessage="{number, plural, one {{number} user successfully removed from department} other {{number} users successfully removed from department}}" values={{ number: numUsers }} />);
    const addToSkill = (<FormattedMessage id="UserManagementComponent.addToSkill" defaultMessage="{number, plural, one {{number} user successfully added to the skill} other {{number} users successfully added to the skill}}" values={{ number: numUsers }} />);
    const removeFromSkill = (<FormattedMessage id="UserManagementComponent.removeFromSkill" defaultMessage="{number, plural, one {{number} user successfully removed from the skill} other {{number} users successfully removed from the skill}}" values={{ number: numUsers }} />);

    let displayText = null;
    if (lastAction) {
      switch (lastAction.name) {
        case userActionTypes.APPROVE:
          displayText = approveText;
          break;
        case userActionTypes.DENY:
          displayText = denyText;
          break;
        case userActionTypes.PROMOTE:
          displayText = promoteText;
          break;
        case userActionTypes.WITHDRAW:
          displayText = withdrawText;
          break;
        case userActionTypes.REMOVE:
          displayText = removeText;
          break;
        case operationTypes.CREATE_GROUP:
          displayText = createdGroup;
          break;
        case operationTypes.CREATE_DEPARTMENT:
          displayText = createdDept;
          break;
        case operationTypes.CREATE_SKILL:
          displayText = createdSkill;
          break;
        case operationTypes.ASSIGN_USERS_TO_GROUP:
          displayText = addedToGroup;
          break;
        case operationTypes.REMOVE_USERS_FROM_GROUP:
          displayText = removedFromGroup;
          break;
        case operationTypes.ASSIGN_USERS_TO_DEPT:
          displayText = addedToDept;
          break;
        case operationTypes.REMOVE_USERS_FROM_DEPT:
          displayText = removeFromDept;
          break;
        case operationTypes.ASSIGN_USERS_TO_SKILL:
          displayText = addToSkill;
          break;
        case operationTypes.REMOVE_USERS_FROM_SKILL:
          displayText = removeFromSkill;
          break;
        case userActionTypes.DELETE_UNREGISTERED:
          displayText = removeText;
          break;
      }
    }

    return (
      <styled.WinContainer top={winTop} left={winLeft}>
        <div className="status__wrap">
          <label className="status__text">{displayText}</label>
        </div>
      </styled.WinContainer>
    );
  }

  notifyActionDone = (tableSize) => {
    this.setState({ tableSize: tableSize, actionInProgress: false });
  }

  notifyOperationRequested = (operation) => {
    const { operationTypes, } = constants;
    this.lastDoneTask = operation;
    switch (operation.name) {
      case operationTypes.CREATE_DEPARTMENT:
        this.setState({ requestedOperation: operation.name });
        break;
      case operationTypes.ASSIGN_USERS_TO_DEPT:
        this.setState({ requestedOperation: operation.name });
        break;
      case operationTypes.REMOVE_USERS_FROM_DEPT:
        this.setState({ requestedOperation: operation.name });
        break;
      case operationTypes.CREATE_GROUP:
        this.setState({ requestedOperation: operation.name });
        break;
      case operationTypes.ASSIGN_USERS_TO_GROUP:
        this.setState({ requestedOperation: operation.name });
        break;
      case operationTypes.REMOVE_USERS_FROM_GROUP:
        this.setState({ requestedOperation: operation.name });
        break;
      case operationTypes.CREATE_SKILL:
        this.setState({ requestedOperation: operation.name });
        break;
      case operationTypes.ASSIGN_USERS_TO_SKILL:
        this.setState({ requestedOperation: operation.name });
        break;
      case operationTypes.REMOVE_USERS_FROM_SKILL:
        this.setState({ requestedOperation: operation.name });
        break;
    }
  }

  notifyConfirmOperation = (confirmed, object) => {
    const { locationId, handleCreateGroup, handleCreateSkill, handleCreateDepartment, handleAddUserToGroup,
      handleAddUserToDepartment, handleRemoveUserFromDepartment,
      handleAssignUsersToSkill, handleRemoveUsersFromSkill } = this.props;
    const { selectedUsers } = this.state;
    const { operationTypes } = constants;
  
    this.setState({ userActionDone: false, processedUsers: [], actionInProgress: false });
  
    const admins = object?.users?.filter(user => user.is_admin) || [];
    const members = object?.users?.filter(user => !user.is_admin) || [];
  
    const handleDepartmentUsers = (action) => {
      if (object.users && object.department) {
        this.setState({ selectedDepartment: object.department, actionUsers: object.users });
        if (action === 'add') {
          if (selectedUsers.length > object.users.length) {
            this.setState({ selectedUsers: object.users });
          }
          if (admins.length > 0) handleAddUserToDepartment(admins, object.department.id);
          if (members.length > 0) handleAddUserToDepartment(members, object.department.id);
        } else if (action === 'remove') {
          if (admins.length > 0) handleRemoveUserFromDepartment(admins, object.department.id);
          if (members.length > 0) handleRemoveUserFromDepartment(members, object.department.id);
        }
      }
    };
  
    const handleGroupUsers = (action) => {
      if (object.channel && object.users) {
        const usersToProcess = action === 'remove' ? object.users.reverse() : object.users;
        this.setState({ selectedChannel: object.channel, selectedUsers: usersToProcess, actionUsers: usersToProcess });
        if (usersToProcess.length >= 10) this.setState({ actionInProgress: true });
        if (action === 'add') {
          handleAddUserToGroup(object.channel.id, object.users);
        } else if (action === 'remove') {
          this.bulkRemoveUsersFromGroup(object.channel.id, usersToProcess);
        }
      }
    };
  
    if (confirmed) {
      switch (object.operation) {
        case operationTypes.CREATE_DEPARTMENT:
          if (object.name) {
            handleCreateDepartment(object.name, locationId, object.description);
            this.setState({ lastCreatedDept: { name: object.name, description: object.description }, userActionDone: false });
          }
          break;
  
        case operationTypes.ASSIGN_USERS_TO_DEPT:
          handleDepartmentUsers('add');
          break;
  
        case operationTypes.REMOVE_USERS_FROM_DEPT:
          handleDepartmentUsers('remove');
          break;
  
        case operationTypes.CREATE_GROUP:
          if (object.name) {
            handleCreateGroup(object.name, locationId, object.description);
            this.setState({ lastCreatedChannel: { name: object.name, description: object.description }, userActionDone: false });
          }
          break;
  
        case operationTypes.ASSIGN_USERS_TO_GROUP:
          handleGroupUsers('add');
          break;
  
        case operationTypes.REMOVE_USERS_FROM_GROUP:
          handleGroupUsers('remove');
          break;
  
        case operationTypes.CREATE_SKILL:
          if (object.name) {
            handleCreateSkill(object.name, locationId);
            this.setState({ lastCreatedSkill: { name: object.name, description: object.description }, userActionDone: false });
          }
          break;
  
        case operationTypes.ASSIGN_USERS_TO_SKILL:
          if (object.users && object.skill) {
            this.setState({ selectedSkill: object.skill, selectedUsers: object.users, actionUsers: object.users });
            if (admins.length > 0) handleAssignUsersToSkill(admins, locationId, object.skill);
            if (members.length > 0) handleAssignUsersToSkill(members, locationId, object.skill);
          }
          break;
  
        case operationTypes.REMOVE_USERS_FROM_SKILL:
          if (object.users && object.skill) {
            this.setState({ selectedSkill: object.skill, selectedUsers: object.users, actionUsers: object.users });
            if (admins.length > 0) handleRemoveUsersFromSkill(admins, locationId, object.skill);
            if (members.length > 0) handleRemoveUsersFromSkill(members, locationId, object.skill);
          }
          break;
      }
    }
  
    this.setState({ requestedOperation: null });
  };

  notifySearchTermChanged = (term) => {
    this.setState({ searchTerm: term});
  }

  notifyRefreshRequested = () => {
    const { locationId, loadingAssociates, loadingManagers, loadingRemovedUsers, loadingChannelMembers, fetchAllUsers, } = this.props;

    const loading = loadingAssociates || loadingManagers || loadingRemovedUsers || loadingChannelMembers;
    if (loading) {
      return;
    }

    fetchAllUsers(locationId, this.allUserPageIndex, USERS_PER_PAGE);
    fetchUnregisteredUsers(locationId);
  }

  notifySortTypeChanged = (type) => {
    this.setState({ selectedSort: type })
  }

  notifySortOrderChanged = (value) => {
    this.setState({ ascending: value });
  }

  notifyDropDownOpened = (opened) => {
    this.setState({ popupOpened: opened });
  }

  notifyFiltersSelected = (dept, email, employeeId, group, jobTitle, skill) => {
    this.setState({filterByDepartment: dept, filterByGroup: group, filterByJobTitle: jobTitle, filterByEmail: email, filterByEmployeeId: employeeId, filterBySkill: skill});
  }

  handleAddMemberClick = (e) => {
    this.setState({ showAddMemberModal: true });
  }

  handleCloseAddTeamModal = (e) => {
    this.setState({ showAddMemberModal: false });
  }

  handleEmployeeIDClick = (user) => {
    this.setState({ showEditEmployeeIDModal: true });
    this.editEmployeeData = user;
  }

  handleCloseEditEmployeeIDModal = (e) => {
    this.setState({ showEditEmployeeIDModal: false });
  }

  handleCloseInviteModal = (e) => {
    this.setState({ showSendEmailModal: false });
  }

  handleShowSMSInviteModal = (user) => {
    this.setState({ showSendEmailModal: true });
    this.unregisteredUserToInvite = user;
  }

  visibleUsers = () => {
    const { selectedSort, ascending, filterBySkill, filterByDepartment, filterByGroup, filterByJobTitle, filterByEmail, filterByEmployeeId , searchTerm, selectedType, selectedItem, selectedIndex } = this.state;
    const usersOfCurrentCategory = this.gatherUsersBySelectedCategory(selectedType, selectedItem, selectedIndex);
    const usersWithFiltersApplied = filterUtil.applyFiltersToUsers(usersOfCurrentCategory, filterByDepartment, filterByGroup, filterByJobTitle, filterByEmail, filterByEmployeeId, filterBySkill);
    const foundUsers = filterUtil.searchUsers(searchTerm, usersWithFiltersApplied);
    const sortedUsers = utils.sortUsers(foundUsers, selectedSort?.name, ascending);
    return sortedUsers;
  }

  render() {
    const { locationId, locationData, locations, managers, associates, removedUsers, pendingUsers, loadingManagers,
      loadingAssociates, loadingRemovedUsers, loadingChannelMembers, departments, skills, channels, channelData,
      lastAction, handleChangeLocation, locationSettings, handleGetUserInformation } = this.props;

    const {selectedType, selectedItem, selectedDeptId, selectedSkillId,
      locationInfo, selectedUsers, userActionDone, processedUsers, actionInProgress, actionUsers,
      requestedOperation, selectedIndex, popupOpened, processingUserId, planName, showAddMemberModal, showEditEmployeeIDModal, showSendEmailModal } = this.state;

    const loading = loadingAssociates || loadingManagers || loadingRemovedUsers || loadingChannelMembers;

    const allUsers = managers.concat(associates, pendingUsers, removedUsers);

    const catItems = this.createLocationCategoryItems();
    const summaryItems = this.createSummaryElements(catItems);
    const percentage = actionUsers.length > 0 && actionUsers.length >= processedUsers.length ? (processedUsers.length / actionUsers.length) * 100 : 0;

    if (actionInProgress) {
      return (
        <ProgressBar percentage={percentage} />
      );
    }

    const foundUsers = this.visibleUsers();

    return (
      <div className="management__wrap">
        <LoadingLayer loading={loading} />
        <Category
          categorySelected={this.categorySelected}
          notifyOperationRequested={this.notifyOperationRequested}
          locationSettings={locationSettings}
          notifyDropDownOpened={this.notifyDropDownOpened}
          selectedType={selectedType}
        />
        <Summary
          loading={loading}
          summaryItems={summaryItems}
          selectedType={selectedType}
          parentSelectedIndex={selectedIndex}
          selectedUsers={selectedUsers}
          summaryItemSelected={this.summaryItemSelected}
          channels={channels}
          departments={departments}
          handleAddMemberClick={this.handleAddMemberClick}
        />
        <Header
          locationSettings={locationSettings}
          managers={managers}
          associates={associates}
          removedUsers={removedUsers}
          pendingUsers={pendingUsers}
          selectedType={selectedType}
          selectedItem={selectedItem}
          locationId={locationId}
          locationInfo={locationInfo}
          locationData={locationData}
          locations={locations}
          filteredUsers={foundUsers}
          selectedUsers={selectedUsers}
          requestedOperation={requestedOperation}
          channels={channels}
          channelData={channelData}
          departments={departments}
          skills={skills}
          handleChangeLocation={handleChangeLocation}
          notifyActionRequested={this.notifyActionRequested}
          notifyOperationRequested={this.notifyOperationRequested}
          notifyConfirmOperation={this.notifyConfirmOperation}
          notifySearchTermChanged={this.notifySearchTermChanged}
          notifyRefreshRequested={this.notifyRefreshRequested}
          notifySortTypeChanged={this.notifySortTypeChanged}
          notifySortOrderChanged={this.notifySortOrderChanged}
          notifyDropDownOpened={this.notifyDropDownOpened}
          notifyFiltersSelected={this.notifyFiltersSelected}
        />
        <UserTable
          locationSettings={locationSettings}
          loading={loading}
          locationId={locationId}
          totalUsers={allUsers}
          departments={departments}
          skills={skills}
          channels={channels}
          filteredUsers={foundUsers}
          selectedDeptId={selectedDeptId}
          selectedType={selectedType}
          selectedItem={selectedItem}
          selectedSkillId={selectedSkillId}
          selectedIndex={selectedIndex}
          notifyUserSelected={this.notifyUserSelected}
          notifyAllUserSelected={this.notifyAllUserSelected}
          selectedUsers={selectedUsers}
          lastAction={lastAction}
          notifyActionDone={this.notifyActionDone}
          processedUsers={processedUsers}
          handleGetUserInformation={handleGetUserInformation}
          notifyActionRequested={this.notifyActionRequested}
          popupOpened={popupOpened}
          processingUserId={processingUserId}
          handleEmployeeIDClick={this.handleEmployeeIDClick}
          handleShowSMSInviteModal={this.handleShowSMSInviteModal}
        />
        {showAddMemberModal === true &&
          <AddUnregisteredUserContainer
            locationId={locationId}
            handleCloseAddTeamModal={this.handleCloseAddTeamModal}
            locationData={locationData}
          />}

        {showSendEmailModal === true && <InviteWithEmailContainer
          handleCloseInviteModal={this.handleCloseInviteModal}
          userToInvite={this.unregisteredUserToInvite}
          locationId={locationId}
          locationData={locationData}
        />}

        {showEditEmployeeIDModal === true &&
          <EditUserEmployeeIDContainer
            locationId={locationId}
            user={this.editEmployeeData}
            handleCloseEditEmployeeIDModal={this.handleCloseEditEmployeeIDModal}
            locationData={locationData}
          />}

        <styledConfirm.Overlay display={(showAddMemberModal === true || showEditEmployeeIDModal || showSendEmailModal) ? 'block' : 'none'} />

        {userActionDone && <Modal> {this.renderStatus(lastAction)} </Modal>}

        {planName === 'Basic' && <Modal> <UpgradePrompt /> </Modal>}
        <styledConfirm.Overlay display={planName === "Basic" ? 'block' : 'none'} />
      </div>
    );
  }
}

UserManagementComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  managers: PropTypes.array.isRequired,
  associates: PropTypes.array.isRequired,
  removedUsers: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  skills: PropTypes.array.isRequired,
  channels: PropTypes.array.isRequired,
  channelData: PropTypes.array.isRequired,
};

export default UserManagementComponent;
