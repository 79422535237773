import { connect } from 'react-redux';
import { changeLocation, loadLocations } from '../../actions/dashboard-actions';
import { dashboardOperations } from '../../pages/Dashboard/duck';
import NavPanelComponent from './NavPanelComponent';

const mapStateToProps = ({ appReducer, setupReducer, locationReducer, }) => {
  return {
    locationConfig:
      setupReducer.setup_actions.meta ? setupReducer.setup_actions.meta.current_location.configuration : null,
    userImgUrl: 
      setupReducer.setup_actions.meta ? setupReducer.setup_actions.meta.current_user.gallery_url : null,
    allowTeamFeed: appReducer.location.settings ? appReducer.location.settings.cfg__group_chat : null,
    allowScheduleUpload: appReducer.location.settings ? appReducer.location.settings.cfg__schedule_upload : null,
    userInfo: 
    setupReducer.setup_actions.meta ? setupReducer.setup_actions.meta.current_user: null,
    options: locationReducer.locations.map((element) => {
      return {
        value: element.id,
        text: element.location_name,
        subtext: element.formatted_address,
        avatarUrl: element.avatar_blob ? element.avatar_blob.blob_url : '',
      };
    }),
    locationId: locationReducer.location_code,
    apiCalled: locationReducer.locations_called,
    user: setupReducer.setup_actions.meta ? setupReducer.setup_actions.meta.current_user : null,
    locations: locationReducer.locations,
    locationData: locationReducer.location_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (id) => {
      dispatch(changeLocation(id));
    },

    loadOptions: () => {
      dispatch(loadLocations());
    },
    getCostCenterData: (locationId) =>{
      dispatch(dashboardOperations.getCostCenterData(locationId));
    }
  };
};

const NavPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavPanelComponent);

export default NavPanelContainer;
