import moment from 'moment';
import { getLocationAvailabilities } from 'helpers/api-calls/availability-calls';
import { BroadcastNotificationCall } from 'helpers/api-calls/schedule-calls';

import actions from './actions';
import { Mixpanel } from '../../../Mixpanel';

const remindTeamToUpdateAvailabilityOperation = (locationId, message) => (dispatch) => {
  return BroadcastNotificationCall(locationId, message).then(({ data }) => {

    console.log(JSON.stringify(data));
  }).catch((error) => {
    Mixpanel.error('Notify Availability Reminder', { 'error': error });
  });
};

const fetchAvailabilityOperation = (locationId, pageNo, numPerPage) => (dispatch) => {
  const allUserConfig = {
    location_id: locationId,
    page: pageNo,
    per_page: numPerPage,
  };

  dispatch(actions.requestAllAvailabilities(true));
  return getLocationAvailabilities(locationId).then((response) => {

    const payload = {
      availabilities: response.data.availabilities,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };

    if (allUserConfig.page === 1) {
      dispatch(actions.receiveAllAvailabilities(payload));
    } else {
      dispatch(actions.appendAvailabilities(payload));
    }

  }).catch((error) => {
    console.log(`fetchAvailabilities error: ${error}`);
  });

};

export default {
    remindTeamToUpdateAvailabilityOperation,
    fetchAvailabilityOperation,
};
