import React from 'react';
import {Suspence} from 'react';
import PropTypes, { shape } from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { teamFeedShapes } from '../../duck';

import styled from './styled';
import blocks from '../../blocks';
import elements from '../../elements';
import { appShapes } from 'App/duck';
import { getUserId } from '../../../../helpers/cookies';
import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import Confirm from './Confirm';
import Modal from 'components/GeneralComponents/Modal';

import './CommentsComponent.scss';

import {Mixpanel} from '../../../../Mixpanel';
import { isValidObject } from 'helpers/validation';

const sha1 = require('js-sha1');


class CommentsComponent extends React.PureComponent{
  constructor(props){
    super(props);
    this.state={userInput: '',
                closeSidePane: false,
                localComments: [],
                openConfirm: false,
                };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.closeSidePane = false;
    this.pubnub = props.pubnub;
    this.pubnub.setUUID(`user.${sha1(`user.${getUserId()}`)}`);
    this.sha1 = `user_event.${sha1(`user.${getUserId()}`)}`;

    this.scrollRef = React.createRef();
    this.remotePostId = -1;
    this._isMounted = false;
    this.localPost = {id: -1, comments: []};

  }

  /*
   getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
    if (prevProps.comments.data.length < this.props.comments.data.length) {
      const { current } = this.scrollRef;
      return current.scrollHeight - current.scrollTop;
    }
    return null;
  }
  */

  componentDidMount() {
    const {comments, active} = this.props;
    this._isMounted = true;
    this.pubnubSubscribe();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {comments, activePost} = this.props;
    const { current } = this.scrollRef;
    /*
    if(prevProps.comments.data.length < comments.data.length){
      if (snapshot !== null) {
          current.scrollTop = current.scrollHeight - snapshot;
      }
    }
    */


    // the state 'localComments' is used to prevent re-render all comments when new comment added
    //Add/delete comments

    if(this.remotePostId < 0){
      
      if(this._isMounted){
        this.setState({localComments: comments.data});
        //this.localPost.id = activePost.id;
        this.localPost.id = activePost ? activePost.id : 0;
        this.localPost.comments = comments;
        //console.log('pos1: remotePostId < 0');
        return;
      }
      
    }else{
      if(this.remotePostId === activePost.id){
        if(this._isMounted){
          this.setState({localComments: comments.data});
          this.localPost.id = activePost.id;
          this.localPost.comments = comments;
          //console.log('pos2: remotePostId === activePost.id');
          if(this.localPost.comments.data.length === activePost.comments_count){
            this.remotePostId = -1;
          }
          return;
        }
      }
    }
  }

   componentWillUnmount() {
    this._isMounted = false;
    this.pubnub.removeListener();
    this.pubnub.unsubscribe({
      channels: [this.sha1],
    });
  }


  pubnubSubscribe = () => {
    const { 
            fetchComments,
            handleUpdateComment,
            activePost,
          } = this.props;

    var usedNewCommentId = -1;
    var usedDeleteCommentId = -1;
    
    this.pubnub.addListener({
      message: (event) => {
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'comment' && 
          event.message.crud === 'NEW'
        ) {
            if(event.message.node_id === this.localPost.id){
              if(event.message.leaf_id !== usedNewCommentId){
                usedNewCommentId = event.message.leaf_id;
                fetchComments(event.message.node_id);
              }
              this.remotePostId = event.message.node_id;
            }
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'comment' && 
          event.message.crud === 'DELETE' &&
          event.message.leaf_id !== usedDeleteCommentId
        ) {
            usedDeleteCommentId = event.message.leaf_id;
            //this.remotePostId = event.message.node_id;
        }
      },
    });

    this.pubnub.subscribe({
      channels: [this.sha1],
    });
  };

  messages = defineMessages({
    pressKeyPrompt: {
      id: 'CommentsComponent.pressKeysSubmit',
      defaultMessage: "Press CTRL + Enter to submit",
    }
    
  });


  handleChange(event) {
    this.setState({userInput: event.target.value});

    let text = event.target.value;
    if( text !== null && text.length > 0 ){
      this.autoExpand(event.target);
    }else{
      this.resetInputHeight(event.target);
    }
    event.preventDefault();
  }

  autoExpand = function (field) {
    var height = field.scrollHeight;
    field.style.height = height + 'px';
  };

  resetInputHeight (field) {
    if( isValidObject(field) == true ){
      field.style.height = '40px';
    }
  }

  handleKeyPress(event){
    const {activePost, createComment,} = this.props;

    if (event.keyCode === 13 && event.ctrlKey) {
      if(activePost && activePost.id) {
        if(this.state.userInput){
          createComment(this.state.userInput, activePost.id);
          this.setState({userInput: ''});
          Mixpanel.track('Posted comment'); 
        }
      }
    }
    event.preventDefault();
  }

  handleSubmit(event) {
    const {activePost, createComment,} = this.props;
    if(activePost && activePost.id) {
      if(this.state.userInput){
        createComment(this.state.userInput, activePost.id);
        this.setState({userInput: ''});
        Mixpanel.track('Posted comment');
      }
    }
    event.preventDefault();
  }

  handleClose(event) {
    const {resetActiveSidePane,} = this.props;
    
    this.setState({closeSidePane: false});
    this.closeSidePane = true;
    resetActiveSidePane();
    event.preventDefault();

    Mixpanel.track('Close comments');
  }

  autoExpand = function (field) {

    // Reset field height
    field.style.height = 'inherit';
    // Get the computed styles for the element
    var computed = window.getComputedStyle(field);
    // Calculate the height
    var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
               + parseInt(computed.getPropertyValue('padding-top'), 10)
               + field.scrollHeight
               + parseInt(computed.getPropertyValue('padding-bottom'), 10)
               + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    field.style.height = height + 'px';
  };

   notifyCancel = () => {
   this.setState({openConfirm: false});
  }

  notifyDelete = () => {
    const { active, deleteComment, activePost, } = this.props;
    
    this.setState({openConfirm: false});
    
    if(active && active.delete.id >= 0 && activePost && activePost.id >= 0) {
      deleteComment(active.delete.id, activePost);
    }
  }


  render () {
      const {comments,
      activePost,
      activeSidePane,
      fetchComments,
      setActiveDelete,
      setActiveComment,
      setActiveSidePane,
      setActivePost,
      activeChannel,
      active,
      allowCreatePost,
      allUsers,
      onlineUsers,
      fetchPostLikeList,
      fetchPostReaderList,
      intl,} = this.props;
      const { openConfirm, } = this.state;

      const renderComments = (data) =>
        data.map((comment) => (
        <blocks.Comment
          key={comment.id}
          clickDelete={() => {
            setActiveDelete({ id: comment.id, type: 'comment' });
            setActiveComment(comment.id);
            this.setState({openConfirm: true});
          }}
          data={comment}
          allUsers={allUsers}
          onlineUsers={onlineUsers}
          active={
            active.comment === comment.id || active.delete.id === comment.id
          }
        />
      ));


      const msg = intl.formatMessage(this.messages.pressKeyPrompt);
      //const placeholder=`Channel  ${active.channel}       ${msg}`;
      const placeholder=`Channel:  ${active.post.channel_name}   ${msg}`;

      if(this.closeSidePane){
        this.closeSidePane = false;
        return null;
      }

      let postIsAllowed = false;
      if(allowCreatePost) {
        if(active.subscriptions.final_allow_post) {
          postIsAllowed = true;
        }else {
          postIsAllowed = false;
        }
      }else {
        postIsAllowed = false;
      }

      const title = (<FormattedMessage id="Comments.headerLabel" defaultMessage="Comments" />);
      const numComments = this.state.localComments ? this.state.localComments.length : 0;

      const totalReplies = (<FormattedMessage
        id="Comments.replies"
        defaultMessage="{number} Replies"
        values={{number: numComments}}
      />);

      if(!postIsAllowed) {
        return (
          <elements.ColumnWrap>
          {/*<blocks.Header>
            <FormattedMessage id="Comments.headerLabel" defaultMessage="Comments" />
            <styled.CloseButton
              onClick={this.handleClose}>
              <FormattedMessage id="CommentsComponent.close" defaultMessage="X Close"/>
            </styled.CloseButton>
          </blocks.Header>*/}
          {/*<div className="channel__wrap">
            <p className="channel__title">{title}</p>
            <button className="channel__compose-button" onClick={this.handleComposeClicked}/>
          </div>*/}
          <div className="comment__wrap">
            <p className="comment__title">{title}</p>
            <button className="comment__close-button" onClick={this.handleClose}/>
          </div>
          <elements.ScrollWrap isLoading={comments.loading} ref={this.scrollRef}>
            <styled.ActivePost
              clickPost={() => {}}
              clickComment={() => {}}
              clickDelete={() => {}}
              clickLike={() => {}}
              clickPin={undefined}
              active={false}
              allUsers={allUsers}
              onlineUsers={onlineUsers}
              setActiveSidePane={setActiveSidePane}
              setActivePost={setActivePost}
              activeChannel={activeChannel}
              data={{
                ...activePost,
                post_type: -1,
                comments_count: 0,
                final_allow_delete: false,
                final_allow_like: false,
                final_allow_comment: false, // Fixed bug WS-1276
              }}
            />
            {activePost && renderComments(this.state.localComments)}
          </elements.ScrollWrap>
           {comments.loading || <styled.Warning><FormattedMessage id="CommentsComponent.notAllowPost"
                defaultMessage="Read Only"/></styled.Warning>}
        </elements.ColumnWrap>
        );
      }

      return (
        <elements.ColumnWrap>
     
          <div className="comment__wrap">
            <p className="comment__title">{title}</p>
            <button className="comment__close-button" onClick={this.handleClose}/>
          </div>
          <elements.ScrollWrap isLoading={comments.loading} ref={this.scrollRef}>
            <styled.ActivePost
              clickPost={() => {}}
              clickComment={() => {}}
              clickDelete={() => {}}
              clickLike={() => {}}
              clickPin={undefined}
              active={false}
              allUsers={allUsers}
              onlineUsers={onlineUsers}
              fetchPostLikeList={fetchPostLikeList}
              fetchPostReaderList={fetchPostReaderList}
              setActiveSidePane={setActiveSidePane}
              setActivePost={setActivePost}
              setActiveSidePane={setActiveSidePane}
              activeChannel={activeChannel}
              data={{
                ...activePost,
                post_type: -1,
                comments_count: 0,
                final_allow_delete: false,
                final_allow_like: false,
                final_allow_comment: false, // Fixed bug WS-1276
              }}
            />
            <div className="comment__top-line-wrap">
              <span className="comment__top-line-span"/>
              <span className="comment__top-line-text">{totalReplies}</span>
              <span className="comment__top-line-span"/>
            </div>
            {/*activePost && renderComments(comments)*/}
            {activePost && renderComments(this.state.localComments)}
            {openConfirm && <Modal>
              <Confirm
                notifyCancel={this.notifyCancel}
                notifyDelete={this.notifyDelete}
              />
            </Modal>}
            {/*!comments.loading || <elements.Loading />*/}
          </elements.ScrollWrap>
          <styled.CommentInputForm onSubmit={this.handleSubmit}>
            <styled.CommentInput
              type="textarea"
              value={this.state.userInput}
              placeholder={placeholder}
              multiline={true}
              onChange={this.handleChange}
              onKeyUp={this.handleKeyPress}
            />
            <styled.ButtonsDiv>
            <styled.SubmitButton/>
            </styled.ButtonsDiv>
          </styled.CommentInputForm>
        </elements.ColumnWrap>
      );
  }
}

CommentsComponent.propTypes = {
  fetchComments: PropTypes.func.isRequired,
  fetchMoreComments: PropTypes.func.isRequired,
  active: shape({
    post: PropTypes.number.isRequired,
  }).isRequired,
  comments: teamFeedShapes.posts.isRequired,
  activePost: teamFeedShapes.posts.isRequired,
  setActiveComment: PropTypes.func.isRequired,
  setActiveDelete: PropTypes.func.isRequired,
};

export default injectIntl(CommentsComponent);
