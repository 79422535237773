
import React from 'react';
import './NotifyTeamComponent.scss';
import '../../../../App/fonts.scss';
import '../../../../App/colors.scss';
import '../../../../App/layout.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Mixpanel } from '../../../../Mixpanel';

class DeleteShiftComponent extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  handleCancel = (event) => {
    const { cancelModal } = this.props;
    cancelModal();
    Mixpanel.track('Cancel Delete shift');
  }

  handleConfirm = (event) => {
    const { cancelModal, deleteShiftLocationId, deleteShiftDispatch, deleteShiftId } = this.props;
    deleteShiftDispatch(deleteShiftId, deleteShiftLocationId);
    Mixpanel.track('Confirm Delete shift', { 'location_id': deleteShiftLocationId, 'shift_id': deleteShiftId });
    cancelModal();
  }

  render() {
    const cancel = (<FormattedMessage id="Confirm.cancel" defaultMessage="Cancel" />);
    const confirm = (<FormattedMessage id="Confirm.confirm" defaultMessage="Confirm" />);
    const deleteTitleText = (<FormattedMessage id="Scheduling.notifyTeamTitle" defaultMessage="Delete Shift?" />);
    const deleteBodyText = (<FormattedMessage id="Scheduling.notifyTeamBody1" defaultMessage="Are you sure you want to delete this shift? This action cannot be undone." />);

    return (
      <div className="notify-team-container">
        <label className="title averta-regular fs20px">{deleteTitleText}</label>
        <div className='body-text1'>{deleteBodyText}</div>

        <div className="confirm-button-wrap">
          <button className="confirm-button-cancel averta-semibold fs14px" onClick={this.handleCancel}>{cancel}</button>
          <button className="confirm-button averta-semibold fs14px button-enabled" style={{ outline: 0 }} onClick={this.handleConfirm}>{confirm}</button>
        </div>

      </div>
    );
  }
}


export default injectIntl(DeleteShiftComponent);