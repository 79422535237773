
import { PNUUID, eventChannelName } from "helpers/pubnub/config";
import { getUserId } from "helpers/cookies";

/*

{
    "channel": "user_event.e9b86157265a7c20d373cbbc71e2988647dd94fe",
    "actualChannel": null,
    "subscribedChannel": "user_event.e9b86157265a7c20d373cbbc71e2988647dd94fe",
    "timetoken": "17429384787295671",
    "publisher": "c3c1cbb7-792a-4e5a-9070-979962fb13a4",
    "message": {
        "event": "applicant",
        "crud": "CREATE",
        "tree_id": 15902,
        "node_id": 169028,
        "leaf_id": 19797,
        "callback": false,
        "receiver_id": 7610,
        "meta": {
            "id": 19797,
            "schedule_element_id": 5901180,
            "schedule_element_start_at": "2025-04-13T16:00:00.000Z",
            "schedule_element_end_at": "2025-04-13T22:00:00.000Z",
            "post_id": 169028,
            "user_id": 16512,
            "changes": {
                "post_id": [
                    null,
                    169028
                ],
                "schedule_element_id": [
                    null,
                    5901180
                ],
                "user_id": [
                    null,
                    16512
                ]
            }
        }
    }
}

*/

const subscribe = (pubnub, loadSingleShiftDispatch, loadSingleApplicantDispatch, loadSingleFreePostDispatch) => {
    pubnub.setUUID(PNUUID());
    const handleMessage = e => {
        // console.log(e);
        const message = e.message;
        if ( message.receiver_id == getUserId() ) {
            if ( message.event === 'open_shift' ){
              if ( message.crud === 'NEW' || message.crud === 'UPDATE' ){
                console.log('loadSingleShiftDispatch');
                  loadSingleShiftDispatch(message.leaf_id);
              }else if ( message.crud === 'DELETE' ){

              }
            }else if( message.event === 'applicant' ) {
                if ( message.crud === 'CREATE' || message.crud === 'UPDATE' ) {
                    console.log('loadSingleApplicantDispatch: ' + JSON.stringify(message));
                    loadSingleApplicantDispatch(message.leaf_id);
                }
            }else if ( message.event === 'free_post' ) {
                if ( message.crud === 'NEW' ) {
                    console.log('loadSingleFreePostDispatch');
                    loadSingleFreePostDispatch(message.node_id);
                }
            }
        }
    } 

    const listenerParams = {
        status: (event) => { if (event.category === "PNConnectedCategory") { console.log("Pubnub Connected"); } },
        message: handleMessage 
    }

    pubnub.addListener(listenerParams);
    const name = eventChannelName();
    pubnub.subscribe({ channels: [name]});
    return listenerParams;
}

export default {
    subscribe,
};