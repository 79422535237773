import React  from "react";
import { FormattedMessage, } from 'react-intl';

class AvailabilityDetailTableHeaderComponent extends React.Component {
    constructor(props) {
      super(props);
    }
    createHeaders = (rangeStart, rangeEnd) => {

        const effectiveOnText = (<FormattedMessage id="Availability.effectiveOn" defaultMessage="Effective On" />);
        const submittedText = (<FormattedMessage id="Availability.submitted" defaultMessage="Submitted" />);

        if (rangeStart === null || rangeEnd === null) { return null; }

        const clone = rangeStart.clone();

        const headerFormat = 'ddd';

        const firstDate = clone.format(headerFormat);
        const firstItem = (
        <div className="calendar-table__header-title">
            {firstDate}
        </div>);

        const secondDate = clone.clone().add(1, 'days').format(headerFormat);
        const secondItem = (
        <div className="calendar-table__header-title">
            {secondDate}
        </div>);

        const thirdDate = clone.clone().add(2, 'days').format(headerFormat);
        const thirdItem = (
        <div className="calendar-table__header-title">
            {thirdDate}
        </div>);

        const fourthDate = clone.clone().add(3, 'days').format(headerFormat);
        const fourthItem = (
        <div className="calendar-table__header-title">
            {fourthDate}
        </div>);

        const fifthDate = clone.clone().add(4, 'days').format(headerFormat);
        const fifthItem = (
        <div className="calendar-table__header-title">
            {fifthDate}
        </div>);

        const sixthDate = clone.clone().add(5, 'days').format(headerFormat);
        const sixthItem = (
        <div className="calendar-table__header-title">
            {sixthDate}
        </div>);

        const lastDate = rangeEnd.format(headerFormat);
        const lastItem = (
        <div className="calendar-table__header-title">
            {lastDate}
        </div>);

        let headerItems = [effectiveOnText, firstItem, secondItem, thirdItem, fourthItem, fifthItem, sixthItem, lastItem, submittedText];

        const Headers = () => {
        const headers = headerItems.map((item, index) => {
            return (
            <th key={index} className="calendar-table__header-title">
                {item}
            </th>
            );
        });
        return headers;
        };

        return (
        <thead>
            <tr className="shift-table__headers">
            <Headers />
            </tr>
        </thead>
        );
    };

    render() {
        const { locationId, locationData, rangeStart, rangeEnd, startStr, notifyTeamDispatch, changeDateRangeSlice, } = this.props;
        return ( this.createHeaders(rangeStart, rangeEnd) );
    }

}

export default AvailabilityDetailTableHeaderComponent;