import React from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { defineMessages } from 'react-intl';
import messages from './messages';
import {
  UserCell,
  DateCell,
  TimeCell,
  StatusCell,
  NotesCell,
  DurationCell,
  ChannelCell,
  TimeRangeCell,
  SkillsCell,
} from './TableCell/TableCell';

class TableRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appStatus: '',
    };

    this._isMounted = false;
  }

  componentDidMount () {
    const { skills, rowData, locationId, stateFilters, } = this.props;

    this._isMounted = true;

    if(rowData && rowData.length > 4) {
      /*
      let msg = `TableRow.componentDidMount: owner: ${rowData[3].first_name} ${rowData[3].last_name}`;
      if(rowData[4]) {
        msg += `, Coverer: ${rowData[4].first_name} ${rowData[4].last_name}`;
      }else {
        msg += ', Coverer: null';
      }
      if(stateFilters && stateFilters.length > 0) {
        if(stateFilters.includes('approved')) {
          console.log(msg)
        }
      }
      */
      const coverer = rowData[4];
      if(!coverer) {
        this.setState({appStatus: ''});
      }
      if(coverer && coverer.first_name === '') {
        if(coverer.last_name) {
          if(this._isMounted) {
            this.setState({appStatus: coverer.last_name});
            //console.log('1. status = ' + coverer.last_name);
          }
        }
      }else {
        if(coverer && coverer.first_name && coverer.last_name) {
          if(rowData.length > 6) {
            const tradeStatus = rowData[6];
            if(tradeStatus) {
              switch(tradeStatus) {
                case 'approved':
                  this.setState({appStatus: '#a'});
                  break;
                case 'pending':
                  this.setState({appStatus: '#p'});
                  break;
                case 'denied' || 'rejected':
                  this.setState({appStatus: '#d'});
                  break;
              }
            }
          }
        }
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { skills, rowData, stateFilters, } = this.props;
    const { applicantStatus, } = this.state;

    if(prevProps.skills !== skills) {
      if(skills && skills.length > 0) {
        
      }
    }

    if(prevProps.rowData !== rowData) {
      if(rowData && rowData.length > 4) {
        /*
        let msg = `TableRow.componentDidUpdate: owner: ${rowData[3].first_name} ${rowData[3].last_name}`;
        if(rowData[4]) {
          msg += `, Coverer: ${rowData[4].first_name} ${rowData[4].last_name}`;
        }else {
          msg += ', Coverer: null';
        }
        if(stateFilters && stateFilters.length > 0) {
          if(stateFilters.includes('approved')) {
            console.log(msg)
          }
        }
        */
        const coverer = rowData[4];
        if(!coverer) {
          this.setState({appStatus: ''});
        }
        if(coverer && coverer.first_name === '') {
          if(coverer.last_name) {
            if(this._isMounted) {
              this.setState({appStatus: coverer.last_name});
            }
          }
        }else {
          if(coverer && coverer.first_name && coverer.last_name) {
            if(rowData.length > 6) {
              const tradeStatus = rowData[6];
              if(tradeStatus) {
                switch(tradeStatus) {
                  case 'approved':
                    this.setState({appStatus: '#a'});
                    break;
                  case 'pending':
                    this.setState({appStatus: '#p'});
                    break;
                  case 'denied' || 'rejected':
                    this.setState({appStatus: '#d'});
                    break;
                }
              }
            }
          }
        }
      }
    }

    if(prevProps.stateFilters !== stateFilters) {
      
    }
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  createCell(cell, index, type) {
    const {
      intl: { formatMessage },
      skills,
      locationId,
      stateFilters,
    } = this.props;
    const {appStatus, } = this.state;

    switch (type) {
      case 'user':
        return <UserCell key={index} user={cell} locationId={locationId}  stateFilters={stateFilters} index={index}/>;

      case 'date':
        return <DateCell key={index} date={cell} />;

      case 'time':
        return <TimeCell key={index} date={cell} />;
      
      case 'text':

        const vtoRegex = /\((VTO )(\#\d*)( of )(\d*)\)(.*)/;
        const openShiftRegex = /\((Open Shift )(\#\d*)( of )(\d*)\)(.*)/;
        const openShiftMobileRegex = /\((Open Shift )(\#\d*)( of  )(\d*)\)(.*)/;
        const voluntaryRegex = /\((Voluntary Time Off )(\#\d*)( of )(\d*)\)(.*)/;
        
        //console.log(cell);
      
        let count, total, vals, msg, results;
        
        if (String(cell).match(voluntaryRegex)){
          results = String(cell).match(voluntaryRegex);
          if(results && results.length > 0){
            vals = extractNumbersInString(results[0]); 
            if(vals && vals.length > 0){
            count = vals[0];
            total = vals[1];
            }
            if(results.length === 6){
              msg=`(${formatMessage(messages.locVoluntaryTimeOff,{count: count, total: total})}) ${results[5]}`;
            }else if(results.length === 5){
              msg=`(${formatMessage(messages.locVoluntaryTimeOff,{count: count, total: total})})`;
            }
            return (
            <td key={index} className="shyft--table-cell" title={msg}>
            <p className="shyft-table--text-block">{msg}</p>
            </td>
            );
          }
        }else if(String(cell).match(openShiftRegex)){
          results = String(cell).match(openShiftRegex);
          if(results && results.length > 0){
            vals = extractNumbersInString(results[0]); 
            if(vals && vals.length > 0){
            count = vals[0];
            total = vals[1];
            }
            if(results.length === 6){
              msg=`(${formatMessage(messages.locOpenShift,{count: count, total: total})}) ${results[5]}`;
            }else if(results.length === 5){
              msg=`(${formatMessage(messages.locOpenShift,{count: count, total: total})})`;
            }
            return (
            <td key={index} className="shyft--table-cell" title={msg}>
            <p className="shyft-table--text-block">{msg}</p>
            </td>
            );
          }
        }else if(String(cell).match(openShiftMobileRegex)){
          results = String(cell).match(openShiftMobileRegex);
          if(results && results.length > 0){
            vals = extractNumbersInString(results[0]); 
            if(vals && vals.length > 0){
            count = vals[0];
            total = vals[1];
            }
            if(results.length === 6){
              msg=`(${formatMessage(messages.locOpenShift,{count: count, total: total})}) ${results[5]}`;
            }else if(results.length === 5){
              msg=`(${formatMessage(messages.locOpenShift,{count: count, total: total})})`;
            }
            return (
            <td key={index} className="shyft--table-cell" title={msg}>
            <p className="shyft-table--text-block">{msg}</p>
            </td>
            );
          }
        }else if(String(cell).match(vtoRegex)){
          results = String(cell).match(vtoRegex);
          if(results && results.length > 0){
            vals = extractNumbersInString(results[0]); 
            if(vals && vals.length > 0){
            count = vals[0];
            total = vals[1];
            }
            if(results.length === 6){
              msg=`(${formatMessage(messages.locVTO,{count: count, total: total})}) ${results[5]}`;
            }else if(results.length === 5){
              msg=`(${formatMessage(messages.locVTO,{count: count, total: total})})`;
            }
            return (
            <td key={index} className="shyft--table-cell" title={msg}>
            <p className="shyft-table--text-block">{msg}</p>
            </td>
            );
          }
        }
        

        //The message could be "Notes" block from server, already translated
        //display as it is.
        return (
          <td key={index} className="shyft--table-cell" title={cell}>
            <p className="shyft-table--text-block">{cell}</p>
          </td>
        );
        

      case 'enum':
        let statusMessage = {};

        // shift types
        if (cell === 'shift' || cell === 'shift_inventory') {
          statusMessage = messages.teamShift;
          return <StatusCell key={index} status={formatMessage(statusMessage)} />;
        }
        if (cell === 'open_shift') {
          statusMessage = messages.openShift;
          return <StatusCell key={index} status={formatMessage(statusMessage)} />;
        }
        if (cell === 'shift_vto') {
          statusMessage = messages.vto;
          return <StatusCell key={index} status={formatMessage(statusMessage)} />;
        }

        // shift statuses (stati?)
        if (cell === 'open') {
          let str = '';
          if(appStatus) {
            switch(appStatus) {
              case '#a':
                str = formatMessage(messages.approved);
                break;
              case '#p':
                str = formatMessage(messages.pending);
                break;
              case '#d':
                str = formatMessage(messages.denied);
                break;
            }
            statusMessage = `${str}`;
            return <StatusCell key={index} status={statusMessage} />;
          }
          statusMessage = messages.posted;
          return <StatusCell key={index} status={formatMessage(statusMessage)} />;
        }

        if (cell === 'denied') {
          const shiftStatus = formatMessage(messages.denied);
          let str = '';
          if(appStatus) {
            switch(appStatus) {
              case '#a':
                str = formatMessage(messages.approved);
                break;
              case '#p':
                str = formatMessage(messages.pending);
                break;
              case '#d':
                str = formatMessage(messages.denied);
                break;
            }
            //statusMessage = `${shiftStatus}/${str}`;
            statusMessage = `${str}`;
            return <StatusCell key={index} status={statusMessage} />;
          }
          statusMessage = messages.denied;
          return <StatusCell key={index} status={formatMessage(statusMessage)} />;
        }
        if (cell === 'pending') {
          const shiftStatus = formatMessage(messages.pending);
          let str = '';
          if(appStatus) {
            switch(appStatus) {
              case '#a':
                str = formatMessage(messages.approved);
                break;
              case '#p':
                str = formatMessage(messages.pending);
                break;
              case '#d':
                str = formatMessage(messages.denied);
                break;
            }
            //statusMessage = `${shiftStatus}/${str}`;
            statusMessage = `${str}`;
            return <StatusCell key={index} status={statusMessage} />;
          }
          statusMessage = messages.pending;
          return <StatusCell key={index} status={formatMessage(statusMessage)} />;
          
        }
        if (cell === 'covered') {
          const shiftStatus = formatMessage(messages.covered);
          let str = '';
          if(appStatus) {
            switch(appStatus) {
              case '#a':
                str = formatMessage(messages.approved);
                break;
              case '#p':
                str = formatMessage(messages.pending);
                break;
              case '#d':
                str = formatMessage(messages.denied);
                break;
            }
            //statusMessage = `${shiftStatus}/${str}`;
            statusMessage = `${str}`;
            return <StatusCell key={index} status={statusMessage} />;
          }
          statusMessage = messages.covered;
          return <StatusCell key={index} status={formatMessage(statusMessage)} />;
        }
        if (cell === 'approved') {
          const shiftStatus = formatMessage(messages.approved);
          let str = '';
          if(appStatus) {
            switch(appStatus) {
              case '#a':
                str = formatMessage(messages.approved);
                break;
              case '#p':
                str = formatMessage(messages.pending);
                break;
              case '#d':
                str = formatMessage(messages.denied);
                break;
            }
            //statusMessage = `${shiftStatus}/${str}`;
            statusMessage = `${str}`;
            return <StatusCell key={index} status={statusMessage} />;
          }
          statusMessage = messages.approved;
          return <StatusCell key={index} status={formatMessage(statusMessage)} />;
        }

        //return <StatusCell key={index} status={formatMessage(statusMessage)} />;
        
      case 'duration':
        return <DurationCell key={index} dates={cell} />;
      case 'time_range':
        if(cell.length !== 2) {
          return null;
        }else {
          return <TimeRangeCell key={index} start={cell[0]} end={cell[1]}/>
        }
        break;
      case 'skills':
        let skillNames = '';
        let rowSkills = [];
        if(cell && cell.length > 0 && skills && skills.length > 0) {
          for(let i=0; i<cell.length; i++) {
            for(let j=0; j<skills.length; j++) {
              if(cell[i] === skills[j].id) {
                //skillNames += `${skills[j].skill_content}, `;
                rowSkills.push(skills[j]);
              }
            }
          }
          if(rowSkills.length > 1) {
            this.sortSkillContentWithNullValues(rowSkills, true);
            rowSkills.map((skill) => {
              skillNames += `${skill.skill_content}, `;
            });
          }else if(rowSkills.length === 1){
            skillNames = `${rowSkills[0].skill_content} `;
          }
        }
        return (
          <td key={index}className="shyft-table--cell" title={skillNames}>
            <p className="shyft-table--text-block">
              {skillNames}
            </p>
          </td>
        );
        break;
      default:
        return (
          <td key={index} className="shyft--table-cell" title={cell}>
            <p className="shyft-table--text-block">{cell}</p>
          </td>
        );
    }
  }

  sortSkillContentWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  render() {
    const { appStatus, } = this.state;
    const { rowData, headers, } = this.props;
    let cells;
    if (rowData.length > 0) {
      cells = rowData.map((cell, index) => {
        return this.createCell(cell, index, headers[index].type);
      });
    }

    return <tr className="shyft-table--row">{cells}</tr>;
  }
}

function extractNumbersInString(inString){
  var count, total, beginning, end;
  var s, str_count, str_total;
  s = inString;
          if(s && s.length > 0){
            beginning = s.indexOf("#");
            end = s.indexOf("of");
            if(beginning > 0 && end > 0 && end > beginning){
              str_count = s.substring(beginning + 1, end);
              if(str_count && str_count.length >0){
                if(!isNaN(str_count)){
                  count = parseInt(str_count);
                }
              }
            }
            s = s.substring(beginning + 1);
            beginning= s.indexOf("of");
            end = s.indexOf(")");
            if(beginning > 0 && end > 0 && end > beginning){
              // beginnig is the position "o" in "of"
              str_total = s.substring(beginning + 2, end);
              if(str_total && str_total.length >0){
                if(!isNaN(str_total)){
                  total = parseInt(str_total);
                }
              }
            }
          }
  return [count, total];
}

export default injectIntl(TableRow);
