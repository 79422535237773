import React from 'react';
import { connect } from 'react-redux';
import RequestsV3TabComponent from './RequestsV3TabComponent';

const mapStateToProps = ({ appReducer }) => ({
  locationSettings: appReducer.location.settings,
});

const RequestsV3TabContainer = connect(mapStateToProps)(({ locationSettings, match }) => (
  <RequestsV3TabComponent
    locationSettings={locationSettings}
    match={match}
  />
));

export default RequestsV3TabContainer;
