import axios from 'axios';
import { protectedAxiosInstance } from 'helpers/api-calls';
import { createApiDateFormat } from '../date-time';

export const AddDraftShiftToUserCall = (userId, startDateTime, endDateTime, jobTitle, locationId) => {
    const payload = {
      "schedule_element": {
        "start_at": startDateTime,
        "end_at": endDateTime,
        "cached_primary_job": jobTitle,
        "name": "shift_inventory",
        "owner_id": userId,
      }
    };
  
    return protectedAxiosInstance.request({
      url: `api/customer/user_role/locations/${locationId}/schedule_elements/draft_create`,
      method: 'POST',
      data: payload
    });
};

export const AddDraftOpenShiftToUserCall = (userId, startDateTime, endDateTime, jobTitle, locationId, channelId, shiftDescription, quantity) => {
  const payload = {
    "schedule_element": {
      "start_at": startDateTime,
      "end_at": endDateTime,
      "cached_primary_job": jobTitle,
      "name": "open_shift",
      "channel_id": channelId,
      "total_shifts_available": quantity,
      "shift_content": shiftDescription,
      "owner_id": userId, 
    }
  };

  return protectedAxiosInstance.request({
    url: `api/customer/user_role/locations/${locationId}/schedule_elements/draft_create`,
    method: 'POST',
    data: payload
  });
};
  
  export const DeleteDraftShiftCall = (scheduleId, locationId) => {
    return protectedAxiosInstance.request({
      url: `/api/customer/user_role/locations/${locationId}/schedule_elements/${scheduleId}`,
      method: 'DELETE'
    });
  };
  
  export const AddDraftShiftToUnregisteredUserCall = (unregUserId, startDateTime, endDateTime, jobTitle, locationId) => {
    const payload = {
      "schedule_element": {
        "start_at": startDateTime,
        "end_at": endDateTime,
        "cached_primary_job": jobTitle,
        "name": "shift_inventory",
        "unregistered_user_id": unregUserId,
      }
    };
  
    return protectedAxiosInstance.request({
      url: `api/customer/user_role/locations/${locationId}/schedule_elements/draft_create`,
      method: 'POST',
      data: payload
    });
  };

  export const PublishDraftShiftCall = (draftShiftId) => {
    return protectedAxiosInstance.request({
      url: `api/customer/user_role/schedule_elements/${draftShiftId}/publish`,
      method: 'PUT',
    });
  }

  export const UpdateDraftOpenShiftCall = (draftShiftId, targetStartTime, targetEndTime, jobTitle, channelId, quantity, shiftDescription) => {
    var json = {};
    if( targetStartTime !== null ){
      json.start_at = targetStartTime;
    }
    if( targetEndTime !== null ) {
      json.end_at = targetEndTime;
    }
    if( jobTitle !== null ){
      json.cached_primary_job = jobTitle;
    }
    if( channelId !== null ){
      json.channel_id = channelId;
    }
    if( quantity !== null ){
      json.total_shifts_available = quantity;
    }
    if( shiftDescription !== null ){
      json.shift_content = shiftDescription;
    }
    console.log(JSON.stringify(json));
    const payload = {
      "schedule_element": json
    };
    return protectedAxiosInstance.request({
      url: `api/customer/user_role/schedule_elements/${draftShiftId}/draft_update`,
      method: 'PUT',
      data: payload
    });
  }

  export const UpdateDraftShiftCall = (draftShiftId, targetRegisteredUserId, targetUnregisteredUserId, targetStartTime, targetEndTime, jobTitle) => {
    var json = {};

    if( targetRegisteredUserId !== null){
      json.owner_id = targetRegisteredUserId;
    }

    if( targetUnregisteredUserId !== null){
      json.unregistered_user_id = targetUnregisteredUserId;
    }

    if( targetStartTime !== null ){
      json.start_at = targetStartTime;
    }

    if( targetEndTime !== null ) {
      json.end_at = targetEndTime;
    }

    if( jobTitle !== null ){
      json.cached_primary_job = jobTitle;
    }

    console.log(JSON.stringify(json));

    const payload = {
      "schedule_element": json 
    };

    return protectedAxiosInstance.request({
      url: `api/customer/user_role/schedule_elements/${draftShiftId}/draft_update`,
      method: 'PUT',
      data: payload
    }); 
  }

  export const RevertAllDraftChangesCall = () => {
    return protectedAxiosInstance.request({
      url: `api/customer/`,
      method: 'POST',
      data: payload
    });
  }

export default {
};
