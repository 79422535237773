import './ShiftApplicantCommentsComponent.scss';
import React, { useEffect, useRef } from 'react';
import presenter from 'pages/ScheduleCalendar/duck/presenter';
import { messageDateFormat } from 'helpers/date-time';

export function ShiftApplicantCommentRow ({user, comment}) {

    const avatar = presenter.getUserAvatar(user);
    const timestamp = messageDateFormat(comment.created_at);
    return (
        <div className="comment-row" key={comment.id}>
            <img className='avatar' src={avatar}></img>
            <div>
                <div className='info-line'>
                    <label className='name'>{user.first_name} {user.last_name}</label>
                    <label className='timestamp'> {timestamp} </label>
                </div>
                <label className='message'> {comment.content} </label>
            </div>
        </div>
    )
}

export default ShiftApplicantCommentRow;