import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { Button } from '@shyft/acorns';

const TopWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 68px;
  width: 200px;
  align-items: center;
  padding-right: 10px;
`;

const OwnerAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`;

const InviteOwnerAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`;

const OwnerInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  // flex-grow: 1;
  width:88%;
  margin-left: 8px;
  margin-top: 5px;
  margin-bottom: 0;
`;

const OwnerInfoTopLine = styled.p`
  letter-spacing: -0.26px;
  line-height: normal;
  min-height: 17px;
  @font-face {
    font-family: 'AvertaSemiBold';
    src: url('/fonts/averta/Averta-Semibold.ttf');
  }
  font-family: 'AvertaSemiBold';
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 0;
  text-align: left;
`;

const BottomLineWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  //border: 1px solid green;
`;

const DayCellBottomLineWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
`;

const TopLineText = styled.p`
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 12px;
  font-weight: 500;
  margin: auto;
`;

const Dot = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
`;

const BottomLineText = styled.label`
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  padding-top: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
  cursor: pointer;
`;


const BottomLineTextPosition = styled.label`
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  padding-top: 0;
  text-overflow: ellipsis;
  max-width: 140px;
  white-space: nowrap;
`;


const BottomLineTextBadgeId = styled.label`
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  padding-top: 0;
  max-width: 140px;
`;

const BottomLineTextUserLinks = styled.label`
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  letter-spacing: -0.17px;
  text-decoration: underline;
  text-overflow: ellipsis;
  max-width: 140px;
  color: #0a84ff;
  white-space: nowrap;
`;

const BottomLineTextDisabledLinks = styled.label`
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  letter-spacing: -0.17px;
  text-decoration: underline;
  text-overflow: ellipsis;
  max-width: 140px;
  color: #666666;
  white-space: nowrap;
`;


const BottomLineTextOneItem = styled.label`
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  // margin: auto;
  min-width: 140px;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 15px;
  cursor: pointer;
`;

const Line = styled.span`
  width: 1px;
  height: 10px;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: black;
`;

const ShiftCountText = styled.span`
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 10px;
  margin-bottom: 5px;
`;

const DayCellTopRow = styled.label`
  letter-spacing: -0.26px;
  line-height: normal;
  min-height: 17px;
  @font-face {
    font-family: 'AvertaSemiBold';
    src: url('/fonts/averta/Averta-Semibold.ttf');
  }
  font-family: 'AvertaSemiBold';
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  cursor: pointer;
`;

const DayCellTextPosition = styled.label`
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  padding-top: 0;
  text-overflow: ellipsis;
  max-width: 100px;
  white-space: nowrap;
  overflow-x:hidden;
  overflow-y:hidden;
  cursor: pointer;
  margin-left: 1px;
`;

const DayCellAvatar1 = styled.img `
  margin-top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border-width: 1px;
  border-color: #ffffff;
`

const DayCellAvatar2 = styled.img `
  margin-top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border-width: 1px;
  border-color: #ffffff;
`

const Overlay = styled.div`
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
`;

const NotScheduledWrap = styled.label`
  font-size: 12px;
  display: ${(props) => props.display || 'none'};
  font-weight: 400;
  font-family: 'AvertaSemibold';
  font-size: 16px; 
  color: #bbbbbe;
  margin: auto;
  cursor: pointer;
`


const PasteShiftWrap = styled.label`
  display: ${(props) => props.display || 'none'};
  font-size: 12px;
  font-weight: 400;
  font-family: 'AvertaSemibold';
  color: #2c2c2e;
  margin: auto;
  cursor: pointer;
  border: 1px solid #2c2c2e;
  border-radius: 14px;
  height: 28px;
  width: 96px;
  text-align: center;
  padding-top: 5px;
`

const PhoneNumberEntryWrap = styled.div`
  text-align: left;
`;

const PhoneNumberEntryChildWrap = styled.div`
display: inline-block;
padding: 10px 10px;
vertical-align: middle;
`;

export default {
  TopWrap,
  PhoneNumberEntryWrap,
  PhoneNumberEntryChildWrap,
  OwnerAvatar,
  InviteOwnerAvatar,
  OwnerInfoWrap,
  OwnerInfoTopLine,
  BottomLineWrap,
  TopLineText,
  Dot,
  BottomLineText,
  BottomLineTextOneItem,
  BottomLineTextPosition,
  BottomLineTextUserLinks,
  BottomLineTextBadgeId,
  BottomLineTextDisabledLinks,
  Line,
  ShiftCountText,
  Overlay,
  DayCellTopRow,
  DayCellBottomLineWrap,
  NotScheduledWrap,
  DayCellTextPosition,
  PasteShiftWrap,
  DayCellAvatar1,
  DayCellAvatar2,
};
