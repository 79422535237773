import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import './ShiftApplicantsComponent.scss';
import presenter from 'pages/ScheduleCalendar/duck/presenter';

const ShiftApplicantRow = ({
    channels,
    date,
    locationData,
    presentedShift,
    applicant,
    allUsers,
    selectApplicant,
    isHighlighted,
    showCommentsForApplicant,
    hasConflict,
    handleTooltipShow,
    handleTooltipHide,
  }) => {

    const handleChatClick = () => {
      showCommentsForApplicant(applicant);
    }

    const handleConflictMouseOver = (e) => {
      const name = presenter.getApplicantName(applicant)
      handleTooltipShow(e, 'Conflict: '+ name + ' has another shift on this day.');;
    }

    const handleConflictMouseOut = () => {
      handleTooltipHide()
    }

    const handleRowClick = () => {
      selectApplicant(applicant);
    }

    const url = presenter.applicantAvatar(applicant);
    const nameAndPosition = presenter.getApplicantNameAndPosition(applicant);
    const tagline = presenter.getOpenShiftApplicantTagline(applicant);

    return (<div className={`applicant-row ${ isHighlighted ? 'highlighted' : ''}`}  > 
              <div className='left-side'>
                <img className='avatar' src={url}/>
                <div className='applicant-info' onClick={handleRowClick}>
                    <label className='name-position'> {nameAndPosition}</label>
                    <label className='tagline'> {tagline} </label>
                </div>
              </div>

              <div className='right-side'>
                 {hasConflict == true && <img onMouseOverCapture={handleConflictMouseOver} onMouseOutCapture={handleConflictMouseOut} className='conflict-icon' src='icons/conflict.svg'/>}
                <img onClick={handleChatClick} className='chat-icon' src='icons/chat-bubble.svg'/>
              </div>
    </div>);
}

export default injectIntl(ShiftApplicantRow);
