import mixpanel from 'mixpanel-browser';
import { getLocationCode } from 'helpers/cookies';

//Production mixpanel token
mixpanel.init('d803e2bec1cb5872dc2498bfb6a50ac8');

let env_check = (process.env.NODE_ENV === 'production' && window.location.hostname.includes("web.myshyft.com"));

let actions = {
  identify: (id) => {
    if (env_check){ 
        mixpanel.identify(id);
    }else{
        console.log('Mixpanel: identify('+id+')');
    }
  },
  alias: (id) => {
    if (env_check) { 
        mixpanel.alias(id);
    }else{
        console.log('Mixpanel: alias('+id+')');
    }
  },
  track: (name, props) => {
    if (env_check) {
      try {
        let loc_id = getLocationCode();

         if ( loc_id != null && loc_id.length > 0 ) {
            if( props != null ){
              props.location_id = loc_id;
              mixpanel.track(name, props);
            }else{
              mixpanel.track(name, {'location_id':loc_id});
            }
         }else{
              mixpanel.track(name, props);
         }

      } catch (e){
        // console.log('mp error:' + name + ',' + JSON.stringify(props));
      }
    }else{
      let loc_id = getLocationCode();

      if ( loc_id != null && loc_id.length > 0 ) {

         if( props != null ){
           props.location_id = loc_id;
           console.log('Mixpanel: track("'+name+'", '+ JSON.stringify(props) + ')');
         }else{
           console.log('Mixpanel: track("'+name+'", '+ JSON.stringify({'location_id':loc_id}) + ')');
         }

      }else{
          console.log('Mixpanel: track("'+name+'")');
      }
    }
  },
  error: (name, props) => {
    if (env_check) {
      try {
        mixpanel.track('Error - ' + name, props);
      } catch (e){
        // console.log('mp error:' + name + ',' + JSON.stringify(props));
      }
    }else{
      if( props != null){
        console.log('Mixpanel: track("Error - '+name+'", '+ JSON.stringify(props) + ')');
      }else{
        console.log('Mixpanel: track("Error - '+name+'")');
      }
    }
  },

  people: {
    set: (props) => {
      if (env_check) {
        mixpanel.people.set(props);
      }else{
        console.log('Mixpanel: people.set(' + JSON.stringify(props) + ')');
      }
    },
  },
  reset: () => {
    if (env_check)  {
      mixpanel.reset();
    }else{
      console.log('Mixpanel: reset()');
    }
  }
};

export let Mixpanel = actions;