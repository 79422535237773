import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import './SettingsPage.scss';
import '../Subscription/SubscriptionPage';
import SubscriptionContainer from "pages/Subscription/SubscriptionContainer";
import ConfigurationContainer from "./components/ConfigurationContainer";

import '../../App/layout.scss';
import '../../App/fonts.scss';
import { Mixpanel } from '../../Mixpanel';

class SettingsPage extends React.Component {
    state = {
        activeTab: 1,
    };
  
    constructor(props) {
      super(props);
    }

    componentDidMount() {
        const { match } = this.props;

        const initialTab = match.params.module;
        if( initialTab === 'subscription'){
            this.setState({activeTab: 1});
            Mixpanel.track('Shyft Plan Page')
        }else{
            Mixpanel.track('Configuration Page')
        }
    }

    componentDidUpdate() {
        const {showToast, stopToastDispatch} = this.props;

        if ( showToast === true) {
            setTimeout(() => {
                stopToastDispatch();
            }, 2000);
        }
    }

    handleConfigClick = () => {
        this.setState({activeTab: 2});
        Mixpanel.track('Configuration Page')
    }

    handlePlanClick = () => {
        this.setState({activeTab: 1});
        Mixpanel.track('Shyft Plan Page')
    }

    render() {
        const {showToast} = this.props;
        const {activeTab } = this.state;

        const settingsText = <FormattedMessage id="Settings.title" defaultMessage="Settings"/>;
        const configurationListItem = <FormattedMessage id="Settings.configuration" defaultMessage="Configurations"/>;
        const planListItem = <FormattedMessage id="Settings.shyftPlan" defaultMessage="Shyft Plan"/>;

        const toastMessage = <FormattedMessage id="Settings.changeSaved" defaultMessage="Your changes have been saved"/>;
        
        return (
            <div className="settings_big-wrap">
                <div className="settings_title">{settingsText}</div>
                <div className="settings_row">
                    <div className="settings_list">
                        <ul>
                            <li className={ (activeTab === 1 ? 'active-li' : '') } onClick={this.handlePlanClick}>{planListItem}</li>
                            <li className={ (activeTab === 2 ? 'active-li' : '') } onClick={this.handleConfigClick}>{configurationListItem}</li>
                        </ul>
                    </div>
                    <div className="settings_page-container"> 

                    { activeTab === 1 && <SubscriptionContainer/> }
                    { activeTab === 2 && <ConfigurationContainer/> }
                    
                    </div>
                </div>
                 { showToast && <div className="toast-message"> {toastMessage} </div>}
            </div>
        );
    }

}


SettingsPage.propTypes = {
    showToast: PropTypes.bool.isRequired
}

export default SettingsPage;