import React, { Component } from "react";
import '../../components/GeneralComponents/RegistrationDesign.scss'
class RegistrationLayout extends Component {
  render() {
    return (
      <div className="split-container">
        <a className="logo-container" href="https://www.myshyft.com/">
          <img src="/shyft-workforce-logo-black-text.svg" alt="Shyft Logo" />
        </a>
        
        {/* Left half changes dynamically */}
        <div className="split left-half">{this.props.children}</div>

        {/* Right half remains constant */}
        <div className="split right-half">
          <img className="stars-icon" src="/icons/registration-stars-icon.svg" alt="Placeholder Stars" />
          <img className="shyft-icon" src="/icons/registration-shyft-grayscale-icon.svg" alt="Placeholder Shyft" />
        </div>
      </div>
    );
  }
}

export default RegistrationLayout;
