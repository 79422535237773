import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import utils from '../duck/utils';
import { localTimeRangeFromTimeStamps } from 'helpers/calendarUtils';
import '../../../App/fonts.scss';

// import {Mixpanel} from '../../../../../Mixpanel';

class AvailabilityDetailTableRowWeekdayCell extends React.Component {
    state = {
    };
  
    constructor(props) {
      super(props);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    render() {
      const {availability, date, }  = this.props;
      const elements = utils.availabilityElementsForDateWithoutRestrictions(date, availability);

      const preferToWorkText = <FormattedMessage id="Availability.preferToWork" defaultMessage='Available'/>;
      const unavailableText = <FormattedMessage id="Availability.unavailable" defaultMessage='Unavailable'/>;
      const unsubmittedText = <FormattedMessage id="Availability.unsubmitted" defaultMessage='Unsubmitted'/>;

      var rows = elements.map( (element) => {
        const statusClassName = element.open == true ? 'prefer-to-work' : 'unavailable'; 
        return <span className={`avail-element averta-semibold fs14 ${statusClassName}`}>
                  {element.open == true ?  preferToWorkText : unavailableText}<br/>
                  <span className='avail-time'> {utils.timeRangeForAvailabilityElement(element)}</span>
              </span>;
      });

      if ( rows.length == 0 ){ rows = <span className='avail-element unsubmitted'> {unsubmittedText} </span> }

      return (
              <td className="weekday-cell">
                {rows}
              </td> 
      );
    }

}

export default AvailabilityDetailTableRowWeekdayCell;