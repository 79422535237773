export const datastore = {
  employee: {},
  location: {},
  setEmployeeData(data) {
    this.employee = data;
  },
  setLocationData(data) {
    this.location = data;
  },
  getEmployeeData() {
    return this.employee;
  },
  getLocationData() {
    return this.location;
  },
};