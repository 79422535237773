import React from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "132px",
};

const GoogleMapView = ({ lat, lng }) => {
    const center = { lat, lng };

    if (!lat || !lng || isNaN(lat) || isNaN(lng)) {
        return <p>Loading Map...</p>; // Prevent rendering invalid data
    }

    return (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
            <Marker position={center} />
        </GoogleMap>
    );
};

export default GoogleMapView;
