import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import { getUserInfoNonAdmin, } from 'helpers/api-calls/user-calls';


import styled from './styled';

class OwnerCell extends React.PureComponent {
	 constructor(props) {
    super(props);

    this.state ={
      debug: true,
      //owner: null,
      
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { schedule, notifyScheduleOwner, allUsers, owner, } = this.props;

  	this._isMounted = true;
    
  }

  componentDidUpdate (prevProps) {
    const { schedule, allUsers, notifyScheduleOwner, owner, } = this.props;

    if(prevProps.schedule !== schedule) {
     
    }

    if(prevProps.allUsers !== allUsers) {
  
    }

    if(prevProps.owner !== owner) {
      //console.log('here');
    }
  }

  componentWillUnmount () {

  	this._isMounted = false;
  }

  render () {
    const { schedule, owner, } = this.props;

    const ownerImageUrl = owner && owner.profile_image_thumb_url ? 
            owner.profile_image_thumb_url : '/icons/default-profile-thumb.svg';
    const ownerName = owner ? `${owner.first_name} ${owner.last_name}` : '';
    let submittedDate = '';
    const submit = moment(schedule.created_at);
    submittedDate = `${submit.format('MMM DD, YYYY')}`;
    const importedDate = (<FormattedMessage
                    id="OwnerCell.importedTDate"
                    defaultMessage="Imported schedule on {date}" 
                    values={{date: submittedDate}}/>);
    const approvedBadge = '/icons/verified-badge-icon.svg';
    
  	return (
  		<styled.TopWrap>
        <styled.OwnerAvatar src={ownerImageUrl}/>
        <styled.OwnerInfoWrap>
          <styled.OwnerInfoTopLine>
          {ownerName}
          {owner && owner.current_is_admin && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.OwnerInfoTopLine>
          <styled.OwnerInfoBottomLine>{importedDate}</styled.OwnerInfoBottomLine>
        </styled.OwnerInfoWrap>
      </styled.TopWrap>
  	);
  }
}


OwnerCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(OwnerCell);