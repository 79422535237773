import { protectedAxiosInstance } from 'helpers/api-calls';

export const getLocationAvailabilities = (locationId) => {
    return protectedAxiosInstance.request({
       url: `/api/customer/user_role/locations/${locationId}/availabilities/all?per_page=5000&order=created_at_desc`,
       method: 'GET',
    });
   };
   
export default {
    getLocationAvailabilities
}