import { connect } from 'react-redux';
import SubscriptionPage from './SubscriptionPage';

import { appActions } from 'App/duck';
import { subscriptionOperations } from './duck';

const mapStateToProps = ({
  locationReducer,
  appReducer,
}) => {
  return {
    locationId: locationReducer.location_code,
    locationData: locationReducer.location_data,
    plans: appReducer.plans.data,
  };
};

const mapDispatchToProps = (dispatch) => {

  const  getPlans = (locationId) => {
    dispatch(subscriptionOperations.fetchPlans(locationId));
  };

  return { 
    getPlans, 
  };
};

const SubscriptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPage);

export default SubscriptionContainer;