import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { GetUserRequestTimeOffBalance, } from 'helpers/api-calls/rto-calls';
import ApplicantRowContainer from './ApplicantRowContainer';

import calendarIcon from './assets/date.svg';
import clockIcon from './assets/clock.svg';
import jobTitle from './assets/job-title.svg';
import locationIcon from './assets/location icon.svg';
import statsIcon from './assets/stats.svg';
import reasonIcon from './assets/reason.svg';
import typeIcon from './assets/shift-type.svg';
import upArrow from './assets/blue-up-arrow.svg';
import downArrow from './assets/blue-down-arrow.svg';
import skillsIcon from './assets/skills-icon.svg';
import LastToApproveContainer from './LastToApproveContainer';

import styled from './ButtonStyles';
import {Mixpanel} from '../../../Mixpanel';


class ShiftDetails extends React.PureComponent {
	constructor(props) {
    super(props);

    this.state = {
      hide: true,
      sortNameA: true,
      sortTitleA: true,
      sortStatusA: true,
      selectedSort: '',
      applicantsSortByName: [],
      applicantsSortByJob: [],
      applicantsSortByStatus: [],
      lastToApprove: null,
      lastApplicantApproved: false,
    };

	  this.messages = defineMessages({
	    to: {
	      id: 'ShiftDetails.to',
	      defaultMessage: 'to',
	    },
	    shifts: {
	    	id: 'ShiftDetails.shifts',
	      defaultMessage: 'Shifts',
	    },
	    applied: {
	    	id: 'ShiftDetails.applied',
	      defaultMessage: 'Applied',
	    },
	    approved: {
	    	id: 'ShiftDetails.approved',
	      defaultMessage: 'Approved',
	    },
	    description: {
	    	id: 'ShiftDetails.description',
	      defaultMessage: 'Description',
	    }
	  });
	  this._isMounted = false;
	  this.isBackButtonClicked = false;

	  this.handleNameClick = this.handleNameClick.bind(this);
	  this.handleJobTitleClick = this.handleJobTitleClick.bind(this);
	  this.handleStatusClick = this.handleStatusClick.bind(this);

  }

  componentDidMount () {
  	const {shift, skills, locationSettings, fetchShiftApplicants, userPrivileges, } = this.props;

  	this._isMounted = true;
  	//handle browser back button clicked
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onBackButtonEvent);

    if(shift) {
    	fetchShiftApplicants(shift.id, userPrivileges);
    }

  }

  componentDidUpdate (prevProps) {
  	const {shift, skills, locationSettings, userPrivileges, addApplicantJobTitle, } = this.props;
  	const { selectedSort, sortNameA, sortTitleA, sortStatusA, applicantsSortByName, applicantsSortByJob, applicantsSortByStatus,} = this.state;

  	if(prevProps.shift !== shift) {
  		if(shift.applicants) {
  			const msg = `shift ${shift.id} has ${shift.applicants.length} applicants.`;
  			console.log(msg);
  			if(selectedSort !== '') {
	  				switch(selectedSort) {
	  				case 'name':
					  	const copy1 = [...shift.applicants];
					  	if(sortNameA) {
								this.sortNameWithNullValues(copy1, true);
							}else {
								this.sortNameWithNullValues(copy1, false);
							}
							this.setState({applicantsSortByName: copy1});
	  					break;
	  				case 'job-title':
	  					let noJobTitle = [];
					  	let withJobTitle = [];
					  	const copy2 = [...shift.applicants];
					  	for(let i=0; i<copy2.length; i++) {
					  		if(copy2[i].user.current_position) {
					  			withJobTitle.push(copy2[i]);
					  		}else {
					  			noJobTitle.push(copy2[i]);
					  		}
					  	}

					  	let wholeArray = [];
					  	if(withJobTitle.length >=2) {
						  	if(sortTitleA) {
									this.sortJobTitleWithNullValues(withJobTitle, true);
									wholeArray = noJobTitle.concat(withJobTitle);
								}else {
									this.sortJobTitleWithNullValues(withJobTitle, false);
									wholeArray = withJobTitle.concat(noJobTitle);
								}
						  }
						  this.setState({applicantsSortByJob: wholeArray});
	  					break;
	  				case 'status':
	  					const copy3 = [...shift.applicants];
					  	if(sortStatusA){
						  	copy3.sort(function(a, b){
							    if(a.status < b.status) { return -1; }
							    if(a.status > b.status) { return 1; }
							    return 0;
								})
					  	}else {
					  		copy3.sort(function(a, b){
							    if(a.status < b.status) { return 1; }
							    if(a.status > b.status) { return -1; }
							    return 0;
								})
					  	}
							this.setState({applicantsSortByStatus: copy3});
	  					break;
	  			}
  			}
  		}
  	}

  }

  componentWillUnmount () {
  	this._isMounted = false;
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }

  getUserJobTitle = (applicantId) => {
  	const { userPrivileges, } = this.props;
  	for(let i=0; i<userPrivileges.length; i++) {
  		if(userPrivileges[i].owner_id === applicantId) {
  			return userPrivileges[i].position;
  		}
  	}
  	return '';

  };

  onBackButtonEvent = (e) => {
    e.preventDefault();

    if (!this.isBackButtonClicked) {
      this.props.closeWin2();
    }
  }

  handleClose = (event) => {

    //callback function from parent
    this.props.closeWin && this.props.closeWin(event);
    event.preventDefault();
		Mixpanel.track('Close home shift detail');
  }

  sortNameWithNullValues = (arr, ascending) => {
  	// default to ascending
	  if (typeof(ascending) === "undefined")
	    ascending = true;

	  const multiplier = ascending ? 1 : -1;

	  const sorter = function(a, b) {
	    if (a.user.first_name === b.user.first_name) // identical? return 0
	      return 0;
	    else if (a.user.first_name === null)  // a is null? last 
	      return 1;
	    else if (b.user.first_name === null)  // b is null? last
	      return -1;
	    else {// compare, negate if descending
	    	return a.user.first_name.localeCompare(b.user.first_name) * multiplier;
	    }                  
	  }
	  return arr.sort(sorter);
  }

  sortJobTitleWithNullValues = (arr, ascending) => {
  	// default to ascending
	  if (typeof(ascending) === "undefined")
	    ascending = true;

	  const multiplier = ascending ? 1 : -1;

	  const sorter = function(a, b) {
	    if (a.user.current_position === b.user.current_position) // identical? return 0
	      return 0;
	    else if (a.user.current_position === null)  // a is null? last 
	      return 1;
	    else if (b.user.current_position === null)  // b is null? last
	      return -1;
	    else {// compare, negate if descending
	    	return a.user.current_position.localeCompare(b.user.current_position) * multiplier;
	    }                  
	  }
	  return arr.sort(sorter);
  }

   sortSkillContentWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  handleNameClick = (event) => {
  	const {shift} = this.props;
  	const {sortNameA} = this.state;
		event.preventDefault();

		if(!shift.applicants || shift.applicants.length === 0) {
  		console.log('no applicant, return');
  		return;
  	}

  	this.setState({sortNameA: !this.state.sortNameA});
  	this.setState({selectedSort: 'name'});
  	
  	const copyArray = [...shift.applicants];
  	if(sortNameA) {
			this.sortNameWithNullValues(copyArray, true);
		}else {
			this.sortNameWithNullValues(copyArray, false);
		}
		this.setState({applicantsSortByName: copyArray});
  }

  handleJobTitleClick = (event) => {
  	const {shift} = this.props;
  	const {sortTitleA} = this.state;
  	event.preventDefault();

  	if(!shift.applicants || shift.applicants.length === 0) {
  		console.log('no applicant, return');
  		return;
  	}

  	let jobTitleCount = 0;
  	for(let i=0; i<shift.applicants.length; i++){
  		if(shift.applicants[i].user.current_position) {
  			jobTitleCount += 1;
  		}
  	}

  	if(jobTitleCount < 2) {
  		console.log('there is only 1 job title, no sorting.');
  		return;
  	}
  	
  	this.setState({sortTitleA: !this.state.sortTitleA});
  	this.setState({selectedSort: 'job-title'});

  	const copyArray = [...shift.applicants];

  	let noJobTitle = [];
  	let withJobTitle = [];
  	for(let i=0; i<copyArray.length; i++) {
  		if(copyArray[i].user.current_position) {
  			withJobTitle.push(copyArray[i]);
  		}else {
  			noJobTitle.push(copyArray[i]);
  		}
  	}

  	let wholeArray = [];
  	if(withJobTitle.length >=2) {
	  	if(sortTitleA) {
				this.sortJobTitleWithNullValues(withJobTitle, true);
				wholeArray = noJobTitle.concat(withJobTitle);
			}else {
				this.sortJobTitleWithNullValues(withJobTitle, false);
				wholeArray = withJobTitle.concat(noJobTitle);
			}
	  }

	  //const wholeArray = noJobTitle.concat(withJobTitle);
	  this.setState({applicantsSortByJob: wholeArray});
  }

  handleStatusClick = (event) => {
  	const {shift} = this.props;
  	const {sortStatusA} = this.state;
  	event.preventDefault();

  	if(!shift.applicants || shift.applicants.length === 0) {
  		console.log('no applicant, return');
  		return;
  	}
  	
  	this.setState({sortStatusA: !this.state.sortStatusA});
  	this.setState({selectedSort: 'status'});

  	const copyArray = [...shift.applicants];

  	if(sortStatusA){
	  	copyArray.sort(function(a, b){
		    if(a.status < b.status) { return -1; }
		    if(a.status > b.status) { return 1; }
		    return 0;
			})
  	}else {
  		copyArray.sort(function(a, b){
		    if(a.status < b.status) { return 1; }
		    if(a.status > b.status) { return -1; }
		    return 0;
			})
  	}
		this.setState({applicantsSortByStatus: copyArray});
  	
  }

  createRows = () => {
  	const {shift, handleUserDeny, userDeniedApplicants} = this.props;
  	const {selectedSort, applicantsSortByName, applicantsSortByJob, applicantsSortByStatus,} = this.state;

  	switch(selectedSort) {
  		case 'name':
  			if(applicantsSortByName && applicantsSortByName.length > 0) {
		  		return applicantsSortByName.map((applicant, index) => {
			        return <ApplicantRowContainer 
			        key={index} 
			        applicant={applicant} 
			        shift={shift}
			        lastApplicant={this.handleLastApplicant}
			        handleUserDeny={handleUserDeny}
			        userDeniedApplicants={userDeniedApplicants}
			        handleJobTitleReceived={this.handleJobTitleReceived}/>;
			      });
		  	}else {
		  		return [];
		  	}
  			break;
  		case 'job-title':
  			if(applicantsSortByJob && applicantsSortByJob.length > 0) {
		  		return applicantsSortByJob.map((applicant, index) => {
			        return <ApplicantRowContainer 
			        key={index} 
			        applicant={applicant} 
			        shift={shift}
			        lastApplicant={this.handleLastApplicant}
			        handleUserDeny={handleUserDeny}
			        userDeniedApplicants={userDeniedApplicants}
			        handleJobTitleReceived={this.handleJobTitleReceived}/>;

			      });
		  	}else {
		  		return [];
		  	}
  			break;
  		case 'status':
  			if(applicantsSortByStatus && applicantsSortByStatus.length > 0) {
		  		return applicantsSortByStatus.map((applicant, index) => {
			        return <ApplicantRowContainer 
			        key={index} 
			        applicant={applicant} 
			        shift={shift}
			        lastApplicant={this.handleLastApplicant}
			        handleUserDeny={handleUserDeny}
			        userDeniedApplicants={userDeniedApplicants}
			        handleJobTitleReceived={this.handleJobTitleReceived}/>;
			      });
		  	}else {
		  		return [];
		  	}
  			break;

  		default:
  			if(shift.applicants && shift.applicants.length > 0) {
		  		return shift.applicants.map((applicant, index) => {
			        return <ApplicantRowContainer 
			        key={index} 
			        applicant={applicant} 
			        shift={shift}
			        lastApplicant={this.handleLastApplicant}
			        handleUserDeny={handleUserDeny}
			        userDeniedApplicants={userDeniedApplicants}
			        handleJobTitleReceived={this.handleJobTitleReceived}/>;
			      });
		  	}else {
		  		return [];
		  	}
		  	break;
  	}	
  }

  handleLastApplicant = (lastToApprove) => {
  	this.setState({lastToApprove: lastToApprove});
  }

  closePopup = () => {
  	this.setState({lastToApprove: false});
  }

  findSkills = (shift, skills ) => {
	  let skillNames = '';
	  let temp = '';
	  let matchedSkills = [];

	  if(shift && shift.skill_ids && shift.skill_ids.length > 0 && skills && skills.length > 0) {
	    for(let i=0; i<shift.skill_ids.length; i++) {
	      for(let j=0; j<skills.length; j++) {
	        if(shift.skill_ids[i] === skills[j].id) {
	          matchedSkills.push(skills[j]);
	        }
	      }
	    }
	    const copyArray = [...matchedSkills];
    	this.sortSkillContentWithNullValues(copyArray, true);
    	for(let k=0; k<copyArray.length; k++) {
    		temp += `${copyArray[k].skill_content}, `;
    	}
    	skillNames = temp.substring(0, temp.length - 2); // remove last two chars
	  }
	  if(skillNames.length === 0) {
	  	skillNames = (<FormattedMessage
			        id="ShiftDetails.noSkillForShift"
			        defaultMessage="No skills assigned to this shift"
			        />);
	  }
	  return skillNames;
	};

	handleJobTitleReceived = (applicantId,  jobTitle) => {
		const { shift, } = this.props;
		const { lastSorted, } = this.state;
		const msg = `Received applicant id : ${applicantId}, job title: ${jobTitle}`;
		console.log(msg);

		switch(lastSorted) {
			case 'job':
				break;
			case 'name':
				break;
			case 'status':
				break;
			case 'skill':
				break;
		}
	}
  
  render () {
  	const {shift, skills, intl, locationSettings, } = this.props;

   	let winTop = `100px`; //`${(window.innerHeight - 612)/2}px`;
    let winLeft = `300px`; //`${(window.innerWidth - 1200)/2}px`;
    let winW = '800px';//`50%`;
    let winH = `700px`; //94.9% viewport height

    const startDate = moment(shift.start_at).format('ddd, MMM DD');
    const startTime = moment(shift.start_at).format('h:mm A');
    const endTime = moment(shift.end_at).format('h:mm A');
    const timeRange = `${startTime} ${intl.formatMessage(this.messages.to)} ${endTime}`;
    const shiftsApplied = `${intl.formatMessage(this.messages.applied)}: ${shift.applicants ? shift.applicants.length: 0}`;
    //const shiftsApproved = `${intl.formatMessage(this.messages.approved)}: ${shift.available_shifts_covered}`;

    let totalPosted = 0;
    let totalAvailable = 0;
    let totalPending = 0;
    let totalApproved = 0;

    if(shift.name === 'shift') {
    	totalPosted = 1;
    	if(shift.coverer !== null) {
    		totalAvailable = 0;
    		totalApproved = 1;
    	}else {
    		totalAvailable = 1;
    		totalApproved = 0;
    	}
    }else {
    	totalPosted = shift.total_shifts_available;
    	totalAvailable = shift.available_shifts_left;
    	totalApproved = shift.available_shifts_covered;
    }

    if(shift.shift_1_5) {
  		totalPending = shift.current_applicants_nominated_user_ids.length;
  	}else {
  		totalPending = shift.current_applicants_pending_user_ids.length
  	}
   
  	//totalAvailable = shift.name === 'shift' ? 1 : shift.available_shifts_left;
  	//totalPending = shift.shift_1_5 ? shift.current_applicants_nominated_user_ids.length :shift.current_applicants_pending_user_ids.length;
  	//totalApproved = shift.available_shifts_covered;
  	//shift.content: "Note: Open Shift\nTotal Posted: 1\nTotal Available: 0\nTotal Pending: 0\nTotal Approved: 1
    const shiftContent = shift.content;

    let totalPostedText = (<FormattedMessage
			        id="ShiftDetails.totalPosted"
			        defaultMessage="Total Posted:  {number}"
			        values={{number: totalPosted}}/>);
    let totalAvailableText = (<FormattedMessage
			        id="ShiftDetails.totalAvailable"
			        defaultMessage="Total Available:  {number}"
			        values={{number: totalAvailable}}/>);
    let totalPendingText = (<FormattedMessage
			        id="ShiftDetails.totalPending"
			        defaultMessage="Total Pending:  {number}"
			        values={{number: totalPending}}/>);
    let totalApprovedText = (<FormattedMessage
			        id="ShiftDetails.totalApproved"
			        defaultMessage="Total Approved:  {number}"
			        values={{number: totalApproved}}/>);
    let skillNames = (<FormattedMessage
			        id="ShiftDetails.skills"
			        defaultMessage="Skills: {skills}"
			        values={{ skills: this.findSkills(shift, skills) }}
			      />);


    let parts = [];
    let params = [];
    let modifiedContent = '';
    let description = '';
    if(shiftContent && shiftContent.length > 10) {
    	parts = shiftContent.split('\n');
	    if(parts && parts.length > 0) {
	    	for(let i=1; i<parts.length; i++) {
	    		const idx = parts[i].indexOf(':');
	    		if(idx >= 0) {
	    			const str = parts[i].substring(0, idx);
	    			params.push(str);
	    		}
	    	}
	    }

	    if(parts && parts.length > 0) {
	    	//Remove redundance info "Total..."
	    	let loopEnd = 0;
	    	if(params.length > 0) {
	    		loopEnd = parts.length - params.length - 1;
	    	}else {
	    		loopEnd = parts.length;
	    	}
	  		for(let j=0; j<loopEnd; j++) {
	  			modifiedContent += `${parts[j]}  `;
	  		}
	  		if(modifiedContent.includes(':')) {
	  			const toRemove = modifiedContent.substring(0, modifiedContent.indexOf(':'));
	  			description = modifiedContent.replace(toRemove, intl.formatMessage(this.messages.description));
	  		}
	  	}
	    
	    if(params && params.length === 4) {
		    //modifiedContent = `${parts[0]}   ${params[0]}: ${totalPosted}   ${params[1]}: ${totalAvailable}   ${params[2]}: ${totalPending}   ${params[3]}: ${totalApproved}`;
		  }
		}
		
    let typeText = '';
    let shiftsTotal = '';
    let shiftsApproved = '';

    if(shift.name === 'open_shift') {
    	typeText = (
	      <FormattedMessage
	        id="ShiftDetails.openShift"
	        defaultMessage="Open Shift"
	      />
	    );
	    shiftsTotal = `${intl.formatMessage(this.messages.shifts)}: ${shift.total_shifts_available ? shift.total_shifts_available : ''}`;
	    shiftsApproved = `${intl.formatMessage(this.messages.approved)}: ${shift.available_shifts_covered}`;
	  }else if(shift.name === 'shift') {
	  	typeText = (
	      <FormattedMessage
	        id="ShiftDetails.teamShift"
	        defaultMessage="Team Shift"
	      />
	    );
	    shiftsTotal = `${intl.formatMessage(this.messages.shifts)}: ${shift.total_shifts_available ? shift.total_shifts_available : '1'}`;
	    let approvedApplicants = 0;
    	if(shift.applicants && shift.applicants.length > 0){
    		for(let i=0; i<shift.applicants.length; i++) {
	        if(shift.applicants[i].status === 'picked'){
	          approvedApplicants += 1;
	        }
	      }
    	}
    	shiftsApproved = `${intl.formatMessage(this.messages.approved)}: ${approvedApplicants > 0 ? 1 : 0}`;
    }else {
    	typeText = (
	      <FormattedMessage
	        id="ShiftDetails.vto"
	        defaultMessage="Voluntary Time Off"
	      />
	    );
	    shiftsTotal = `VTOs: ${shift.total_shifts_available}`;
	    shiftsApproved = `${intl.formatMessage(this.messages.approved)}: ${shift.available_shifts_covered}`;
    }

    
   	return (
   		<styled.DetailedWinContainer top={winTop} left={winLeft} width={winW} height={winH}>
   			<styled.HeaderWrap>
	   			<styled.Caption>
	   				<FormattedMessage id="ShiftDetails.shiftApplicants"
		        	defaultMessage="Shift Applicants"/>
		      </styled.Caption>
	   			<styled.DetailedWinCloseButton 
			        onClick={this.handleClose}/>
	      </styled.HeaderWrap>
	      <styled.InfoItemsWrap>
	   			<styled.IconTextWrap>
		   			<styled.Icon src={calendarIcon}/>
		   			<styled.Text>{startDate}</styled.Text>
	   			</styled.IconTextWrap>
	   			<styled.IconTextWrap>
		   			<styled.Icon src={clockIcon}/>
		   			<styled.Text>{timeRange}</styled.Text>
	   			</styled.IconTextWrap>
	   			<styled.IconTextWrap>
		   			<styled.Icon src={jobTitle}/>
		   			<styled.Text>{shift.primary_job ? shift.primary_job : ''}</styled.Text>
	   			</styled.IconTextWrap>
	   			<styled.IconTextWrap>
		   			<styled.Icon src={locationIcon}/>
		   			<styled.Text>{shift.location.formatted_address}</styled.Text>
	   			</styled.IconTextWrap>		
   			</styled.InfoItemsWrap>
   			<styled.InfoItemsWrap>
   				<styled.IconTextWrap>
		   			<styled.Icon src={typeIcon}/>
		   			<styled.Text>{typeText}</styled.Text>
	   			</styled.IconTextWrap>
   				<styled.IconTextWrap>
		   			<styled.Icon src={statsIcon}/>
		   			<styled.Text>{shiftsTotal}</styled.Text>
	   			</styled.IconTextWrap>
	   			<styled.IconTextWrap>
		   			{/*<styled.Icon src=''/>*/}
		   			<styled.Text>{shiftsApplied}</styled.Text>
	   			</styled.IconTextWrap>
	   			<styled.IconTextWrap>
		   			{/*<styled.Icon src=''/>*/}
		   			<styled.Text>{shiftsApproved}</styled.Text>
	   			</styled.IconTextWrap>
   			</styled.InfoItemsWrap>
   			{(locationSettings.cfg__skill === 1 && shift.name !== 'shift') && <styled.InfoItemsWrap>
   				<styled.IconTextWrap>
		   			<styled.Icon src={skillsIcon}/>
		   			<styled.Text>{skillNames}</styled.Text>
	   			</styled.IconTextWrap>
   			</styled.InfoItemsWrap>}
   			<styled.InfoItemsWrap>
   				{modifiedContent.length > 8 && <styled.IconTextWrap>
		   			<styled.Icon src={reasonIcon}/>
		   			{/*<styled.Text>{shift.content}</styled.Text>*/}
		   			<styled.TextWrap>
			   			<styled.Text1>{parts && description}</styled.Text1>
			   			{/*<styled.Text1>{totalPostedText}</styled.Text1>*/}
			   			{/*<styled.Text1>{totalAvailableText}</styled.Text1>*/}
			   			{/*<styled.Text1>{totalPendingText}</styled.Text1>*/}
			   			{/*<styled.Text1>{totalApprovedText}</styled.Text1>*/}
		   			</styled.TextWrap>
	   			</styled.IconTextWrap>}
   			</styled.InfoItemsWrap>
   			<styled.ButtonWrap>
   				<styled.ColumnButton 
   					width="37%"
   					marginLeft="30px"
   					url={this.state.sortNameA ? downArrow: upArrow} 
   					onClick={this.handleNameClick}>
   					<FormattedMessage
              id="ShiftDetails.name"
              defaultMessage="Name"
            />
   				</styled.ColumnButton>
   				<styled.ColumnButton 
   					width="20%"
   					marginLeft="0px"
   					url={this.state.sortTitleA ? downArrow: upArrow} 
   					onClick={this.handleJobTitleClick}>
   					<FormattedMessage
              id="ShiftDetails.jobTitle"
              defaultMessage="Job Title"
            />
   				</styled.ColumnButton>
   				<styled.ColumnButton 
   					width="34%"
   					marginLeft="0px"
   					url={this.state.sortStatusA ? downArrow: upArrow} 
   					onClick={this.handleStatusClick}>
   					<FormattedMessage
              id="ShiftDetails.status"
              defaultMessage="Status"
            />
   				</styled.ColumnButton>
   			</styled.ButtonWrap>
   			<styled.ApplicantTable>
   				<styled.TableBody>{this.createRows()}</styled.TableBody>
   			</styled.ApplicantTable>
				{this.state.lastToApprove && <LastToApproveContainer
																		shift={this.props.shift} 
																		applicant={this.state.lastToApprove}
																		closePopup={this.closePopup}
																		handleConfirm={this.handleConfirm}
																		/>}
   		</styled.DetailedWinContainer>
   	);
   }

}


ShiftDetails.propTypes = {
  //locationConfig: PropTypes.shape({}).isRequired,
  //shift: dashboardShapes.shiftShape.isRequired,
  
};

export default injectIntl(ShiftDetails);