import constants from '../constants';

const sortUsers = (users, sortType, ascending) => {
    const { sortTypes, } = constants;

    let arrToSort = [...users];    
    switch(sortType) {
      case sortTypes.POSITION:
        sortJobTitleWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.FIRST_NAME:
        sortFirstNameWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.LAST_NAME:
        sortLastNameWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.STATUS:
        sortStatusWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.USER_ROLE:
        //this.sortUserRoleWithNullValues(arrToSort, ascending);
        const admins = arrToSort.filter((user) => user.is_admin === true);
        const members = arrToSort.filter((user) => user.is_admin === false);
        const unregistered = arrToSort.filter((user) => user.status === 'unregistered');
        sortFirstNameWithNullValues(admins, true);
        sortFirstNameWithNullValues(members, true);
        sortFirstNameWithNullValues(unregistered, true);
        if(ascending) {
          arrToSort = admins.concat(members).concat(unregistered);
        }else {
          arrToSort = members.concat(admins);
        }
        break;
    }
    console.log(sortType, ascending);
    return arrToSort;
  }

  const sortFirstNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.first_name === b.first_name) // identical? return 0
        return 0;
      else if (a.first_name === null)  // a is null? last 
        return 1;
      else if (b.first_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.first_name.localeCompare(b.first_name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  const sortLastNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.last_name === b.last_name) // identical? return 0
        return 0;
      else if (a.last_name === null)  // a is null? last 
        return 1;
      else if (b.last_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.last_name.localeCompare(b.last_name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  const sortJobTitleWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.position === b.position) // identical? return 0
        return 0;
      else if (a.position === null)  // a is null? last 
        return 1;
      else if (b.position === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        const aIsValid = a.hasOwnProperty('position') && (typeof(a.position) !== 'undefined');
        const bIsValid = b.hasOwnProperty('position') && (typeof(b.position) !== 'undefined');
        if ( aIsValid && bIsValid ){
          return a.position.localeCompare(b.position) * multiplier;
        }else if (aIsValid){
          return multiplier;
        }else{
          return -1*multiplier; 
        }
      }                  
    }
    return arr.sort(sorter);
  }

  const sortStatusWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.status === b.status) // identical? return 0
        return 0;
      else if (a.status === null)  // a is null? last 
        return 1;
      else if (b.status === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.status.localeCompare(b.status) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  const sortUserRoleWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.is_admin === b.is_admin) // identical? return 0
        return 0;
      else if (a.is_admin === null)  // a is null? last 
        return 1;
      else if (b.is_admin === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return (b.is_admin - a.is_admin) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  const sortChannelNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.channel_name === b.channel_name) // identical? return 0
        return 0;
      else if (a.channel_name === null)  // a is null? last 
        return 1;
      else if (b.channel_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.channel_name.localeCompare(b.channel_name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  const sortDeptNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.name === b.name) // identical? return 0
        return 0;
      else if (a.name === null)  // a is null? last 
        return 1;
      else if (b.name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.name.localeCompare(b.name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  const sortSkillNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  export default {
    sortUsers,
    sortFirstNameWithNullValues,
    sortLastNameWithNullValues,
    sortJobTitleWithNullValues,
    sortStatusWithNullValues,
    sortUserRoleWithNullValues,
    sortChannelNameWithNullValues,
    sortDeptNameWithNullValues,
    sortSkillNameWithNullValues
  }