import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Modal from 'components/GeneralComponents/Modal';
import { UploadSchedulerFile, } from '../../../../helpers/api-calls/schedule-calls';
import {UploadImage, UploadPDF, } from '../../../../helpers/api-calls/feed-calls';
import constants from '../../constants';
import './HeaderComponent.scss';
import styled from './styled';

class HeaderComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      searchTerm: '',
      openSortMenu: false,
      dropdownPos: null,
      selectedSort: null,
      ascending: true,
    };

    this.messages = defineMessages({
      placeholder: {
        id: 'HeaderComponent.placeholder',
        defaultMessage: 'Search',
      },
    });

    this.sortRef = React.createRef();

    this._isMounted = false;
  }

  componentDidMount() {
    const { sortTypes, } = constants;
    const { notifySortTypeChanged, } = this.props;

    this._isMounted = true;
    const date = (<FormattedMessage
      id="HeaderComponent.date"
      defaultMessage="Date"
    />);
    const itemDate = { name: sortTypes.DATE, body: date};
    notifySortTypeChanged(itemDate);

  }

  componentDidUpdate(prevProps, prevState) {
    const { openSortMenu, } = this.state;

     if(prevState.openSortMenu !== openSortMenu) {
      if(openSortMenu) {
        let top = `400px`; //`${(window.innerHeight - 612)/2}px`;
        let left = `20px`; //`${(window.innerWidth - 1200)/2}px`;
        let width = '180px';//`50%`;
        const height = `95px`; //94.9% viewport height

        if(this.sortRef && this.sortRef.current) {
          const domNode = ReactDOM.findDOMNode(this.sortRef.current);
          const output =domNode.getBoundingClientRect();
          if(output) {
            top = `${output.y + output.height}px`;
            left = `${output.x}px`;
            width = `${output.width}px`;
          }
        }
        const pos = {
        top: top,
        left: left,
        width: width,
        height: height,
        }
        this.setState({dropdownPos: pos});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleUploadClick = (event) => {
    const { ReceiveUploadClick } = this.props;
    ReceiveUploadClick();
  }

  handleSearchChanged = (event) => {
    const { notifySearchTermChanged, } = this.props;
    event.preventDefault();
    this.setState({searchTerm: event.target.value});
    if(event.target.value === '') {
      notifySearchTermChanged('');
    }

  }

  handleKeyPress = (event) => {
    const { notifySearchTermChanged, } = this.props;
    const { searchTerm, } = this.state;

    if(event.keyCode === 13) {
      notifySearchTermChanged(searchTerm);
    }
  }

  handleSortDropdownClick = (event) => {
    const { openSortMenu, } = this.state;

    this.setState({openSortMenu: !openSortMenu})
  }

  renderSortMenu = () => {
    const {dropdownPos, } = this.state;
    const { sortTypes, } = constants;

    const date = (<FormattedMessage
      id="HeaderComponent.date"
      defaultMessage="Date"
    />);
    const fileName = (<FormattedMessage
      id="HeaderComponent.fileName"
      defaultMessage="File name"
    />);
    const shiftCount = (<FormattedMessage
      id="HeaderComponent.shiftCount"
      defaultMessage="Shift Count"
    />);
    

    const itemDate = { name: sortTypes.DATE, body: date};
    const itemFileName = { name: sortTypes.FILE_NAME, body: fileName};
    const itemShiftCount = { name: sortTypes.SHIFT_COUNT, body: shiftCount};

    const items = [itemDate, itemFileName, itemShiftCount];
    const sortItems = items.map((item, index) => (
      <div 
        key={index} 
        className="employee-page-header__dropdown-item-wrap" 
        value={item}
        onClick={(event) => {this.handleSortChange(item)}}
      >
        <label className="employee-page-header__action-dropdown-text">
          {item.body}
        </label>
      </div>
    ));
    if(dropdownPos) {
      return (
        <styled.WinContainer top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {sortItems}
        </styled.WinContainer>
      );
    }
  }

  handleSortChange = (sort) => {
    const { notifySortTypeChanged, } = this.props;

    this.setState({openSortMenu: false, selectedSort: sort});
    notifySortTypeChanged(sort);
  }

  handleSortOrderChanged = (event) => {
    const { ascending, } = this.state;
    const { notifySortOrderChanged, } = this.props;
    this.setState({ascending: !ascending});
    notifySortOrderChanged(!ascending);
  }

  handleRefresh = (event) => {
    const { notifyRefreshRequested, } = this.props;
    event.preventDefault();
    notifyRefreshRequested();
  }

  render() {
    const { openSortMenu, selectedSort, ascending, } = this.state;
    const { actionJobs, } = this.props;

    const numResults = actionJobs ? actionJobs.length : 0;
    const sortButtonClassName = openSortMenu ? 'page-header__arrow-button-up': 'page-header__arrow-button-down';
    const sortOrderButtonClassName =  ascending ? 'page-header__sort-order-descending' : 'page-header__sort-order-ascending';
    
    const buttonText = (<FormattedMessage
      id="HeaderComponent.import"
      defaultMessage="Import"
    />);
    const sortBy = (<FormattedMessage
      id="HeaderComponent.sortBy"
      defaultMessage="Sort by"
    />);

    const dateText = (<FormattedMessage
      id="HeaderComponent.date"
      defaultMessage="Date"
    />);

    const results = (<FormattedMessage
      id="HeaderComponent.results"
      defaultMessage="{number} Results" 
      values={{number: numResults}}/>);

    const sortBoxText = selectedSort ? selectedSort.body : dateText;

    return (
      <div className="page-header__wrap">
        <div className="page-header__row">
          {/*<input className="page-header__search-box"
            onChange={this.handleSearchChanged}
            type="text"
            placeholder={intl.formatMessage(this.messages.placeholder)}
            value={searchTerm}
            onKeyUp={this.handleKeyPress}
          />*/}
          <div className="page-header__sort-drop-down" ref={this.sortRef}>{sortBy}
            <label className="page-header__date-text">{sortBoxText}</label>
            <button className={sortButtonClassName} onClick={this.handleSortDropdownClick}/>
          </div>
          <button className={sortOrderButtonClassName} onClick={this.handleSortOrderChanged}/>
          <button className="page-header__refresh-button" onClick={this.handleRefresh}/>
          <p className="page-header__results">{results}</p>
          <button className="page-header__import-button" onClick={this.handleUploadClick}>{buttonText}</button>
        </div>
        {openSortMenu && <Modal>
          {this.renderSortMenu()}
        </Modal>}
      </div>
    );
  }
}

HeaderComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
  determineFetch: PropTypes.func.isRequired,
  fetchTeamMetadata: PropTypes.func.isRequired,
  selectedAll: PropTypes.bool.isRequired,
};

export default injectIntl(HeaderComponent);

