import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import styled from './styled';
import { Mixpanel } from '../../../../../Mixpanel';

class StatusCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: true,

    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { user } = this.props;

    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  trackAndShowInviteModal(user) {
    const { handleShowSMSInviteModal } = this.props;
    Mixpanel.track('Clicked invite on UM row', { 'user_id': user.id });
    handleShowSMSInviteModal(user);
  }

  render() {
    const { user, handleShowSMSInviteModal } = this.props;

    const active = (<FormattedMessage
      id="StatusCell.active"
      defaultMessage="Active"
    />);

    const active1 = (<FormattedMessage
      id="StatusCell.active1"
      defaultMessage="Active"
    />);

    const pending = (<FormattedMessage
      id="StatusCell.pending"
      defaultMessage="Pending"
    />);

    const terminated = (<FormattedMessage id="StatusCell.terminated" defaultMessage="Terminated" />);
    const unregistered = (<FormattedMessage id="StatusCell.unregistered" defaultMessage="Unregistered" />);
    const inviteText = (<FormattedMessage id="StatusCell.invite" defaultMessage="Invite" />);

    if (user && user.status === 'active') {
      return (
        <styled.StatusActive>
          <styled.ActiveSpan />
          <styled.ActiveText>{active1}</styled.ActiveText>
        </styled.StatusActive>
      );
    } else if (user && user.status === 'pending') {
      return (
        <styled.StatusPending>
          <styled.PendingSpan />
          <styled.PendingText>{pending}</styled.PendingText>
        </styled.StatusPending>
      );
    } else if ((user && user.status === 'removed') || (user && user.status === 'rejected') || (user && user.status === 'withdrawn')) {
      return (
        <styled.StatusFailed>
          <styled.FailedSpan />
          <styled.FailedText>{terminated}</styled.FailedText>
        </styled.StatusFailed>
      );
    } else if ((user && user.status === 'unregistered')) {
      return (
        <styled.UnregisteredWrap>
          <styled.StatusPending>
            <styled.PendingSpan />
            <styled.PendingText>{unregistered}</styled.PendingText>
          </styled.StatusPending>
          {/* {user.phone_number && <styled.inviteLink onClick={ () => {handleShowSMSInviteModal(user);}}>{inviteText} </styled.inviteLink>} */}
          {handleShowSMSInviteModal && true && <styled.inviteLink onClick={() => { this.trackAndShowInviteModal(user); }}>{inviteText} </styled.inviteLink>}
        </styled.UnregisteredWrap>
      );
    } else {
      return null;
    }
  }
}


StatusCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(StatusCell);