import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '../../../duck/utils';
import '../TableComponent.scss';
import OpenShiftDroppableCellBox from './OpenShiftDroppableCellBox';
import OpenShiftDroppableCellBoxContainer from './OpenShiftDroppableCellBoxContainer';
import { Mixpanel } from '../../../../../Mixpanel';

const OpenShiftTableRow = (props) => {
 
  const rowOfOpenShifts = utils.getRowOfOpenShiftsToDisplay(props.shifts, props.weekRangeStart, props.locationSettings.cfg__shift__post_open_shift);

  const getCellElement = (date, shiftsForDay) => {
    return  <OpenShiftDroppableCellBoxContainer
                 user={props.user} 
                 date={date.clone()} 
                 shiftsForDay={shiftsForDay} 
                 weekRangeStart={props.weekRangeStart}
                 selectedDateToAddShift={props.selectedDateToAddShift} 
                 selectedShiftToViewDetail = {props.selectedShiftToViewDetail}
                 isCopyingShift={props.isCopyingShift} 
                 shiftToCopy={props.shiftToCopy}
                 allUsers = {props.allUsers} />
  }

  const col1 = props.weekRangeStart.clone();
  const col2 = col1.clone().add(1, 'days');
  const col3 = col1.clone().add(2, 'days');
  const col4 = col1.clone().add(3, 'days');
  const col5 = col1.clone().add(4, 'days');
  const col6 = col1.clone().add(5, 'days');

  const totalOpenHours = props.openShiftHoursResult["WeekTotalHours"]?.toFixed(2) || 0 

  return (
    <tr className="calendar-row">
      <td className="shift-row__owner-cell">
        <div className='head-cell'>
            <img className='os-icon' src='/icons/open-plus.svg'/>
              <div className='right-column'>
                <label className='os-label'> Open Shifts </label>
                <label className='os-hours'> {totalOpenHours} h</label>
              </div>
          </div>
      </td>

      {getCellElement(col1, rowOfOpenShifts[1])}
      {getCellElement(col2, rowOfOpenShifts[2])}
      {getCellElement(col3, rowOfOpenShifts[3])}
      {getCellElement(col4, rowOfOpenShifts[4])}
      {getCellElement(col5, rowOfOpenShifts[5])}
      {getCellElement(col6, rowOfOpenShifts[6])}
      {getCellElement(props.weekRangeEnd.clone(), rowOfOpenShifts[7])}

    </tr>
  );
};

export default injectIntl(OpenShiftTableRow);
