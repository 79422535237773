import moment from 'moment';
import axios from 'axios';


import { GetLocationSchedules, GetShiftsInSchedule, } from 'helpers/api-calls/schedule-calls';
import { createApiDateFormat } from 'helpers/date-time';
import { LoadFirstPagePendingShifts, LoadMorePendingShifts, } from 'helpers/api-calls/shift-calls';
import { getPlans } from 'helpers/api-calls/plan-calls';

import actions from './actions';
import { appActions } from 'App/duck';


const fetchPlans = (location_id) => (dispatch) => {
  dispatch(actions.requestPlans(true));
  return getPlans(location_id).then(({ data }) => {
    dispatch(actions.hydratePlans(data.plans));
  }).catch((error) => {
    console.log(`fetchPlans error: ${error}`);
  });
};


export default {
  fetchPlans,
};
