import React from 'react';
import { FormattedMessage, injectIntl, } from 'react-intl';
import { listCalendarWeeks } from 'helpers/calendarUtils';

import { CopyToWeekCall } from 'helpers/api-calls/schedule-calls';

import WeekPickerComponent from 'components/CalendarComponents/WeekPickerComponent/WeekPickerComponent';
import './ScheduleHeaderComponent.scss';
import {Mixpanel} from '../../../../Mixpanel';

import PublishScheduleComponent from './PublishScheduleComponent';
import CopyToWeekComponent from "./CopyToWeekComponent";

import SearchFilterComponent from './ScheduleSearchFilterComponent';

import Modal from 'components/GeneralComponents/Modal';
import styledConfirm from '../../../Dashboard/components/styled';

import Spinner from 'react-bootstrap/Spinner';
import BooModal from 'react-bootstrap/Modal';

import moment from 'moment';
import Flicker from 'components/Flicker/FlickerComponent'
import utils from 'pages/ScheduleCalendar/duck/utils';
import draftLogic from 'pages/ScheduleCalendar/duck/draftLogic';

import { startOfLocationFiscalWeek, endOfLocationFiscalWeek } from '../../../../helpers/date-time';

import {CSVLink} from "react-csv";

class ScheduleHeaderComponent extends React.PureComponent {

  // use class properties, not trigger re-render
  scrWeekDay = null;
  destWeekDay = null;
  ownerIds = null;

  constructor(props) {
    super(props);

    this.state = {
      showingPublishScheduleModal: false,
      showCopyToWeekModal: false,
      showLoading: false, 
      flickerWeekPicker: false,
      showExportSelectionMenu: false,
      showFinishPastingSnackBar: false,
    };

    this.weekFlickerRef = React.createRef();
  }

  moveCurrentWeekBackward = () => {
    const { changeDateRangeSlice, rangeStart, rangeEnd} = this.props;

    const newDateRangeStart = utils.previousWeek(rangeStart);
    const newDateRangeEnd= utils.previousWeek(rangeEnd);

    changeDateRangeSlice(newDateRangeStart, newDateRangeEnd);
    Mixpanel.track('Previous week', {'target_week': newDateRangeStart.format("MMM Do YYYY") + ' - ' + newDateRangeEnd.format("MMM Do YYYY")});
  };

  moveCurrentWeekForward = () => {
    const { changeDateRangeSlice, rangeStart, rangeEnd } = this.props;

    const newDateRangeStart = utils.nextWeek(rangeStart);
    const newDateRangeEnd= utils.nextWeek(rangeEnd);

    changeDateRangeSlice(newDateRangeStart, newDateRangeEnd);
    Mixpanel.track('Next week', {'target_week': newDateRangeStart.format("MMM Do YYYY") + ' - ' + newDateRangeEnd.format("MMM Do YYYY")});
  };

  moveCurrentWeekTo = (sDate) => {
    const { changeDateRangeSlice } = this.props;

    const newDateRangeStart = moment(sDate);
    const newDateRangeEnd = newDateRangeStart.clone().add(7, 'day').subtract(1, 'second');

    changeDateRangeSlice(newDateRangeStart, newDateRangeEnd);
    this.handleFinishPasting();
  }

  moveToToday = () => {
    const {locationData} = this.props;

    var start = startOfLocationFiscalWeek(locationData.fiscal_day);
    this.moveCurrentWeekTo(start);

    Mixpanel.track('Go to today');
  }

  handlePublishClick= () => {
    this.setState({ showingPublishScheduleModal: true});
  }
  
  closePublishScheduleModel = () => {
    this.setState({ showingPublishScheduleModal: false});
  }

  handleCopyToWeekSelect = (event, destWeekDay) => {
    this.destWeekLabel = event.target.textContent;
    this.destWeekDay = destWeekDay; 
    this.scrWeekDay = this.props.startStr;

    this.setState({ showCopyToWeekModal: true});
  }
  
  closeCopyToWeekModal = () => {
    this.setState({ showCopyToWeekModal: false});
  }
 
  copyToWeekDispatch = () => {
    this.setState( {showLoading: true});
    return CopyToWeekCall(this.props.locationId, this.scrWeekDay, this.destWeekDay, this.ownerIds).then(({ data}) => {
      // TODO: switch week
      this.moveCurrentWeekTo(this.destWeekDay)
      this.setState( {flickerWeekPicker: true});
      
      setTimeout(() => {
        this.setState( {flickerWeekPicker: false})
      }, 5000); 

      console.log(JSON.stringify(data));
    }).catch( (error) => {
      Mixpanel.error('Copy To Week', {'error':error});
    }).finally(() => {
      // Code to run regardless of success or failure
      this.setState({ showLoading: false });    
    });
  }

  handleExportClick = () => {
    const {showExportSelectionMenu} = this.state;
    if( showExportSelectionMenu == true){
      this.setState( {showExportSelectionMenu: false} );
    }else{
      this.setState( {showExportSelectionMenu: true} );
    }
  }

  handleFinishPasting = () => {
    const {notifyHeaderOfShiftPasting} = this.props;
    notifyHeaderOfShiftPasting(false, null);
  }

  createFileName = (rangeStart, rangeEnd) => {
    const shiftFormat = 'YYYY-MM-DD';
    var fileName = `schedule.csv`;
    if( rangeStart != null ){
      fileName = `${rangeStart.format(shiftFormat)}_${rangeEnd.format(shiftFormat)}`;
    }
    return fileName;
  }

  handleDeleteAllSchedule = () => {
    const {handleDeleteAllSchedule} = this.props;
    handleDeleteAllSchedule();
  }
  
  handleDeleteUnpublishedDrafts = () => {
    const {handleDeleteUnpublishedDrafts} = this.props;
    handleDeleteUnpublishedDrafts();
  }

  render() {
      const {isPublishing, shouldShowOpenShiftUI, scheduledResult, openShiftResult, scheduledUserCount, handleDeleteDraftsModal, handleDeleteEntireScheduleModal, allUsers, allUnregisteredUsers, publishDraftShiftsDispatch, shifts, shiftBeingPasted, isShowingPastingSnackBar, 
        notifySearchTermChanged, handleDepartmentSelect, rangeStart, rangeEnd, notifyTeamOfPublishDispatch, locationSettings, locationData, locationId, startStr, exportableData, tableFormatData, searchTerm, departments} = this.props;
      let { showingPublishScheduleModal, showCopyToWeekModal, showLoading, flickerWeekPicker } = this.state;
      const {showExportSelectionMenu} = this.state;

      const format = 'MMM DD';

      const copyToWeekText = <FormattedMessage id="Scheduling.copyToWeek" defaultMessage='Copy to Week'/>;
      const toolsText = <FormattedMessage id="Scheduling.tools" defaultMessage='Tools'/>;

      const exportToCSVText = <FormattedMessage id="Scheduling.exportToCSV" defaultMessage='Export Schedule Format'/>;
      const exportToPdfText = <FormattedMessage id="Scheduling.exportToPDF" defaultMessage='Export Importer Format'/>;
      const deleteUnpublishedChangesText = <FormattedMessage id="Scheduling.deleteUnpublishedChanges" defaultMessage='Delete unpublished shifts'/>;
      const deleteEntireScheduleText = <FormattedMessage id="Scheduling.deleteEntireSchedule" defaultMessage='Delete entire schedule'/>;

      var message = null;
      if( rangeStart && rangeEnd && locationData){
        message = locationData.location_name + ' (' + locationData.swift_code + ') schedule updated: ' + rangeStart.format(format) + ' - ' + rangeEnd.format(format);
      }

      let weeks_list = listCalendarWeeks(undefined, locationData.fiscal_day);
      weeks_list = weeks_list.filter(item => item[0] !== startStr);

      const shiftFormat = 'YYYY-MM-DD';
      var fileName = `schedule.csv`;
      if( rangeStart != null ){
        fileName = `${rangeStart.format(shiftFormat)}_${rangeEnd.format(shiftFormat)}`;
      }

      const draftShiftsToPublish = draftLogic.draftShiftsToPublish(shifts, shouldShowOpenShiftUI);
      const numberOfShiftsToPublish = draftShiftsToPublish.length ;

      const publishText = (<FormattedMessage id="Scheduling.publish" defaultMessage='Publish ({num})' values={{num: numberOfShiftsToPublish}} />);
      const nothingToPublishText = (<FormattedMessage id="Scheduling.publishZero" defaultMessage='Publish'/>);

    return (
      <div className='header-row-wrap'>

            <Flicker isFlickering = {flickerWeekPicker}>
              {rangeStart && <WeekPickerComponent
                back={this.moveCurrentWeekBackward}
                forward={this.moveCurrentWeekForward} 
                currentDateRangeStart={rangeStart}
                currentDateRangeEnd={rangeEnd}
                goToday={this.moveToToday}
              /> }
                     <SearchFilterComponent 
              notifySearchTermChanged = {notifySearchTermChanged}
              handleDepartmentSelect = {handleDepartmentSelect}
              departments={departments} 
              searchTerm={searchTerm} 
              locationSettings={locationSettings}/>
            </Flicker>

          {rangeStart && 
            <div className='header-right-group'>
              <div className="dropdown">

                  { <button className="header-button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"> {copyToWeekText} </button> } 
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {weeks_list.map((week) => (
                      <li className="dropdown-item" key={week[0]} onClick={(event) => this.handleCopyToWeekSelect.bind(this)(event, week[0])} >{week[1]}</li> 
                    ))}
                  </ul>

                  { <button className="header-button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"> {toolsText} </button> } 
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                    <li className='dropdown-item' key ={1001} >
                      <CSVLink data={tableFormatData} filename={fileName + "_importer.csv"} className="" 
                          onClick={() => { Mixpanel.track('Export in Importer Format', {'target_week': rangeStart.format("MMM Do YYYY") + ' - ' + rangeEnd.format("MMM Do YYYY")}); }} > 
                          {exportToPdfText} 
                      </CSVLink>
                    </li>

                    <li className='dropdown-item' key ={1002} >
                      <CSVLink data={exportableData} filename={fileName + "_schedule.csv"} className="" 
                          onClick={() => { Mixpanel.track('Export in Schedule Format', {'target_week': rangeStart.format("MMM Do YYYY") + ' - ' + rangeEnd.format("MMM Do YYYY")}); 
                          }} > 
                          {exportToCSVText} 
                      </CSVLink>
                    </li>

                    <li className='dropdown-item danger-action' key ={1003} onClick={(event) => handleDeleteEntireScheduleModal()}> {deleteEntireScheduleText} </li>
                    <li className='dropdown-item danger-action' key ={1004} onClick={(event) => handleDeleteDraftsModal()}> {deleteUnpublishedChangesText} </li>
                  </ul>

                  { (numberOfShiftsToPublish > 0 && isPublishing == false) ? 
                  <button className='header-button publish-button' onClick={this.handlePublishClick}> {publishText} </button>
                  :
                  <button className='header-button nothing-to-publish'> {nothingToPublishText} </button>
                  }

              </div>
            </div>} 
        
            { showingPublishScheduleModal === true &&
              <Modal>
                <PublishScheduleComponent
                  locationId = {locationId}
                  cancelModal = {this.closePublishScheduleModel}
                  draftShiftsToPublish = {draftShiftsToPublish}
                  publishDraftShiftsDispatch = {publishDraftShiftsDispatch}
                  currentDateRangeStart={rangeStart}
                  currentDateRangeEnd={rangeEnd}
                  shifts={shifts}
                  allUsers={allUsers}
                  allUnregisteredUsers={allUnregisteredUsers}
                  message = {message}
                  notifyTeamOfPublishDispatch = {notifyTeamOfPublishDispatch}
                  scheduledResult = {scheduledResult}
                  openShiftResult = {openShiftResult}
                  scheduledUserCount = {scheduledUserCount} 
                  shouldShowOpenShiftUI = {shouldShowOpenShiftUI}
                />
              </Modal>
            }
            <styledConfirm.Overlay display={ ( showingPublishScheduleModal === true) ? 'block' : 'none'} />

            { showCopyToWeekModal === true && 
              <Modal>
                <CopyToWeekComponent
                  cancelModal = {this.closeCopyToWeekModal.bind(this)} 
                  copyToWeekDispatch = {this.copyToWeekDispatch.bind(this)}
                  locationId = {locationId}
                  destWeekLabel = {this.destWeekLabel}
                  scrWeekDay = { this.scrWeekDay }
                  destWeekDay = {this.destWeekDay}
                  ownerIds = { null }
                />
              </Modal>
            }
            <styledConfirm.Overlay display={ showCopyToWeekModal === true ? 'block' : 'none'} />

            <BooModal show={showLoading} backdrop="static" keyboard={false} centered contentClassName="bg-transparent border-0" >
              <BooModal.Body>
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              </BooModal.Body>
            </BooModal>

      </div>
    );
  }
}

ScheduleHeaderComponent.propTypes = {
};

export default injectIntl(ScheduleHeaderComponent);