import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';

const handleColorType = (isAnnouncement, pinned) => {
  if(isAnnouncement) {
     return rgba(219,237,255,255);
  }else {
    if(pinned) {
       return rgba(255,238,214,255);
    }else {
      return 'white';
    }
  }
};

const handleHoverColorType = (isAnnouncement, pinned) => {
  if(isAnnouncement) {
    return rgba(219,237,255,255);
  }else {
    if(pinned) {
      return '#FFDFB2';
    }else {
      return rgba(242,242,247,255);
    }
  }
};


const MessageWrap = styled.div.attrs((props) => ({
  isAnnouncement: props.announcement,
  pinned: props.pinned,
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  background-color: ${({isAnnouncement, pinned}) =>  handleColorType(isAnnouncement, pinned)};
  border-bottom: 1px solid rgba(227,227,230,255);

  &:hover {
    background-color: ${({isAnnouncement, pinned}) =>  handleHoverColorType(isAnnouncement, pinned)};
  }
`;

const AvatarWrap = styled.div`
  width: 44px;
  margin-right: 1rem;
  flex: 0 0 44px;
  position: relative;
`;

const Name = styled.h4`

  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
  margin-right: 1rem;

  svg {
    margin-left: 0.5em;
    vertical-align: sub;
  }
  //border: 1px solid red;
`;

const Timestamp = styled.span`
  //font-size: ${rem('14px')};
  //font-family: ${({ theme }) => theme.fonts.secondary};
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.label};
`;

const Content = styled.p`
  //font-size: ${rem('14px')};
  //font-family: ${({ theme }) => theme.fonts.secondary};
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 0;
  white-space: pre-line;
  //border: 1px solid blue;
`;

const CommentCount = styled.button`
  // position: absolute;
  // bottom: 0.5rem;
  // right: 1rem;
  margin-top: 5px;
  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: bold;
  //color: ${({ theme }) => theme.palette.label};
  color: rgba(0,134,255,255);

  &:focus {
    outline: none;
  }
`;

const Image= styled.img`
  width: 50px;
  height: 50px;
  display: block;
  border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  //border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 6px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0rem;
  margin-bottom: 5px;
  position: relative;
  float: left;

  &:hover {
    //width: 200px;
    //height: 200px;
    border: 2px solid ${({ theme }) => theme.palette.primary[0]};
  }

  ${({enlarged}) => enlarged && `
    width: 200px;
    height: 200px;
  `}

`;

const UrlMeta = styled.form`
  font-size: 0.88rem;
  font-weight: 600;
  //color: ${({ theme }) => theme.palette.primary[0]};
  background-color: white;
  padding: 5px 8px;
  max-width: 500px;
  max-height: 600px;
  //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border-radius: 8px;
  

  &:hover {
    //color: ${({ theme }) => theme.palette.primary[0]};
    //background-color: white;
    border: 1px solid ${({ theme }) => theme.palette.primary[0]};
    border-radius: 8px;
  }

`;

const MetaLogo = styled.img`
  width: 100px;
  height: 100px;
  max-width: 150px;
  max-height: 150px;
  display: block;
  float: left;
`;
const MetaImage = styled.img`
  width: 250px;
  height: 141px;
  display: block;
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 8px;
  margin-bottom: 1rem;
`;
/*
const Title = styled.p`
  font-size: ${rem('14px')};
  color: ${({ theme }) => theme.palette.primary[0]};
  position: relative;
  margin-top: 60px;
  margin-left: 5px;
`;
*/
const Title = styled.button`
  font-size: ${rem('14px')};
  color: ${({ theme }) => theme.palette.primary[0]};
  position: relative;
  margin-top: 60px;
  margin-left: 5px;
`;

const Publisher = styled.p`
  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.secondary};
  position: relative;
  margin-top: 40px;
  margin-left: 120px;
`;
// Flowing items are for PictureView window
const TestButton = styled.button`
  font-size: 0.88rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary[0]};
  //color: blue;
  background-color: white;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  //border: 1px solid blue;
  border-radius: 6px;
  padding: 5px 8px;
  cursor: pointer;
  max-width: 150px;
  max-height: 30px;
  position: relative;
  //margin-bottom: 0.5rem;


  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary[0]};
    //background-color: blue;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px 
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }
`;

const PreviewFrame = styled.div`
    max-width: 400px;
    max-height: 300px;
    width: 200px;
    height: 350px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    text-align: left;
    //padding: 4px 8px;
    //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
    border: 1px solid blue;
    border-radius: 6px;
    margin-top: 0rem;
    margin-left: 12rem;
    margin-right: 1rem;
    //margin-bottom: 1rem;
    //float: left;
    z-index: 2;
    position: absolute;
    &:hover {
      //border: 2px solid ${({ theme }) => theme.palette.primary[0]};
      border: 2px solid blue;
    }

    @media (max-width: 420px) {
    min-width: 200px;
    }
  `;

const CurrentImage= styled.img`
  width: 50px;
  height: 50px;
  display: block;
  border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  //border: 1px solid blue;
  //border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 6px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0rem;
  margin-bottom: 5px;
  position: relative;
  float: left;

  &:hover {
    border: 2px solid ${({ theme }) => theme.palette.primary[0]};
    //border: 2px solid blue;
  }

  ${({enlarged}) => enlarged && `
    width: 200px;
    height: 200px;
  `}

`;

const PicViewFrame = styled.div`
    //max-width: 400px;
    //max-height: 300px;
    left: 100px;
    top: 100px;
    width: 600px;
    height: 450px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    text-align: left;
    //padding: 4px 8px;
    //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
    border: 1px solid blue;
    border-radius: 6px;
    margin-top: 0rem;
    margin-left: 12rem;
    margin-right: 1rem;
    //margin-bottom: 1rem;
    //float: left;
    z-index: 2;
    position: absolute;
    opacity: 1;
    &:hover {
      //border: 2px solid ${({ theme }) => theme.palette.primary[0]};
      border: 2px solid blue;
    }

    @media (max-width: 420px) {
    min-width: 200px;
    }
  `;

  const PicViewButton = styled.button`
  font-size: 0.88rem;
  font-weight: 600;
  //color: ${({ theme }) => theme.palette.primary[0]};
  color: blue;
  background-color: white;
  text-align: center;
  //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border: 1px solid blue;
  border-radius: 6px;
  padding: 5px 8px;
  cursor: pointer;
  max-width: 150px;
  max-height: 30px;
  position: relative;
  //margin-bottom: 0.5rem;


  &:hover {
    color: white;
    //background-color: ${({ theme }) => theme.palette.primary[0]};
    background-color: blue;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px blue;
      //${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }
`;

const PicViewImage= styled.img`
  width: 200px;
  height: 200px;
  display: block;
  //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  //border: 1px solid ${({ theme }) => theme.palette.border};
  border: 1px solid blue;
  border-radius: 6px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0rem;
  margin-bottom: 5px;
  position: relative;
  float: left;

  &:hover {
    //width: 200px;
    //height: 200px;
    //border: 2px solid ${({ theme }) => theme.palette.primary[0]};
    border: 2px solid blue;
  }

  ${({enlarged}) => enlarged && `
    width: 400px;
    height: 400px;
  `}

`;

const PicViewReturnImage= styled.img`
  width: 50px;
  height: 50px;
  display: block;
  //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  //border: 1px solid ${({ theme }) => theme.palette.border};
  border: 1px solid blue
  border-radius: 6px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0rem;
  margin-bottom: 5px;
  position: relative;
  float: left;

  &:hover {
    //width: 200px;
    //height: 200px;
    //border: 2px solid ${({ theme }) => theme.palette.primary[0]};
    border: 2px solid blue;
  }

  ${({enlarged}) => enlarged && `
    width: 200px;
    height: 200px;
  `}

`;
const ImageGroupWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
const PDFGroupWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

const PDFWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  max-width: 400px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid rgba(199,199,204,255);

  &:first-child{
    margin-left: 8px;
  }
`;
 const PDFIcon = styled.img`
    padding-left: 10px;
    //width: 40px;
    //height: 40px;
  `;
  const PDFProps = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-top: 10px;
    //width: 60px;
    //height: 40px;
    //border: 1px solid green;
  `;
  const PDFName = styled.label`
    width: 200px;
    overflow: hidden;
  `;
  const PDFSizeWrap = styled.div`
    display: flex;
    flex-direction: row;
    //border: 1px solid red;
  `;
  const PDFSize = styled.label`
    color: rgba(199,199,204,255);
  `;
  const Circle = styled.span`
    display: inline-block;
    height: 5px;
    width: 5px;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
    border: 3px solid rgba(199,199,204,255);
  `;
const AvatarAndInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  //border: 1px solid red;
`;
const InfoWrap = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.secondary};
  min-width: 0;
  //border: 1px solid blue;
`;

const ProfileAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 3px;
  //border: 1px solid red;
`;

const OnlineMark = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  top: 32px;
  right: 0px;
  background-color: rgb(0,222, 1);
`;

const ApprovedBadge = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: 3px;
  //border: 1px solid red;
`;

const LikeCount = styled.div`
  //min-width: 35px;
  //max-width: 55px;
  width: fit-content;
  height: 32px;
  border-radius: 15px;
  background-image: url('/icons/like-icon.svg');
  text-indent: 17px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 8px;
  //background-color: rgba(219,237,255,255);
  background-color: rgba(242,242,247,255);
  //border: 1px solid rgba(11,132,255,255);
  //color: rgba(11,132,255,255);
  color: black;
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 14px;
  font-weight: 500;
  padding-top: 3px;
  padding-right: 10px;
  margin-top: 10px;
  //border: 1px solid red;
`;

const LikeCountLiked = styled.div`
  //min-width: 35px;
  //max-width: 55px;
  width: fit-content;
  height: 32px;
  border-radius: 15px;
  background-image: url('/icons/like-icon.svg');
  text-indent: 17px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 8px;
  // background-color: rgba(242,242,247,255);
  background-color: rgba(219,237,255,255);
  color: rgba(11,132,255,255);
  border: 1px solid rgba(11,132,255,255);
  //color: black;
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 14px;
  font-weight: 500;
  padding-top: 3px;
  padding-right: 10px;
  margin-top: 10px;
  //border: 1px solid red;
`;
//LikeCountPlaceHolder is used to keep message height unchanged when like/unlike clicked. WS-2140
const LikeCountPlaceHolder = styled.div`
  width: 58px;
  height: 32px;
  //border: 1px solid red;
`;

const AnnouncementWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 25px;
  //border: 1px solid red;
`;

const AnnouncementIcon = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 3px;
  margin-right: 7px;
  //border: 1px solid red;
`;

const AnnouncementTitle = styled.label`
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 12px;
  font-weight: 500;
  color: rgba(14,134,255,255);
  padding-left: 10px;

`;

const ReadCount = styled.div`
  min-width: 58px;
  max-width: 63px;
  height: 32px;
  border-radius: 15px;
  background-image: url('/icons/mark-green.png');
  text-indent: 17px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 8px;
  background-color: rgba(219,237,255,255);
  border: 1px solid rgba(11,132,255,255);
  color: rgba(11,132,255,255);
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 14px;
  font-weight: 500;
  padding-top: 3px;
  padding-right: 3px;
  margin-top: 0px;
  //border: 1px solid red;
`;

const PinnedTitle = styled.label`
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 12px;
  font-weight: 500;
  color: rgba(255,153,16,255);
  padding-left: 10px;
  margin-top: 1px;
  //border: 1px solid blue;
`;


export default {
  MessageWrap,
  AvatarWrap,
  Name,
  Timestamp,
  Content,
  Image,
  CommentCount,
  UrlMeta,
  MetaLogo,
  MetaImage,
  Title,
  Publisher,
  PreviewFrame,
  TestButton,
  CurrentImage,
  PicViewFrame,
  PicViewButton,
  PicViewImage,
  PicViewReturnImage,
  ImageGroupWrap,
  PDFGroupWrap,
  PDFWrap,
  PDFIcon,
  PDFProps,
  PDFName,
  PDFSizeWrap,
  PDFSize,
  Circle,
  AvatarAndInfoWrap,
  InfoWrap,
  ProfileAvatar,
  OnlineMark,
  ApprovedBadge,
  LikeCount,
  LikeCountLiked,
  LikeCountPlaceHolder,
  AnnouncementWrap,
  AnnouncementIcon,
  AnnouncementTitle,
  ReadCount,
  PinnedTitle,
};
