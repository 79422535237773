import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import { appShapes } from 'App/duck';

import blocks from '../../blocks';
import elements from '../../elements';
import './TopLineComponent.scss';


class TopLineComponent extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
  }

  componentDidMount() {
    
    this._isMounted = true;

  }

  componentDidUpdate(prevProps) {
   
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSearchClicked = (event) => {
    alert('search clicked');
  }

  handleQuestionClicked = (event) => {
    alert('question clicked');
  }

  handlePlusButtonClicked = (event) => {
    alert('plus button clicked');
  }

  render() {

    const community = (<FormattedMessage
      id="TopLineComponent.community"
      defaultMessage="Community"
    />);

    return (
      <div className="top-line__wrap">
        <label className="top-line__title">{community}</label>
        {/*<div className="top-line__button-wrap">
        <button className="top-line__search-button" onClick={this.handleSearchClicked}/>
        <button className="top-line__question-button" onClick={this.handleQuestionClicked}/>
        <button className="top-line__plus-button" onClick={this.handlePlusButtonClicked}/>
        </div>*/}
      </div>
    );
  }
}

TopLineComponent.propTypes = {
  //setActiveChannel: PropTypes.func.isRequired,
  //channels: appShapes.channelsShape.isRequired,
  //active: PropTypes.number.isRequired,
};

export default injectIntl(TopLineComponent);

