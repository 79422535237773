import { isValidObject } from 'helpers/validation';
import constants from '../constants';
import utils from './utils';
import timeUtil from './timeUtil';

  const usersWithUnpublishedChanges = (draftShiftsToPublish) => {
    // const registeredUnpublishedShifts = draftShiftsToPublish.filter( (shift) => shift.trade_status == 'unpublished_inventory' || shift.trade_status == 'unpublished_update');
    const registeredUnpublishedShifts = draftShiftsToPublish.filter( (shift) => shift.trade_status == 'unpublished_inventory' );
    var resultUserIds = new Set();

    registeredUnpublishedShifts.forEach( (shift) => {
      resultUserIds.add(shift.owner_id);
    });

    return Array.from(resultUserIds);
  }

  const userHasShiftConflict = (singleShift, allUserShiftsOnDay) => {
    if ( allUserShiftsOnDay.length > 1 ){
        return true;
    }else{
        return false;
    }
  }

/*
  DraftOpenShift: 'DraftOpenShift',
  DraftVTO: 'DraftVTO',
  DraftInventory: 'DraftInventory',
  DraftUnregisteredInventory: 'DraftUnregisteredInventory',
  ParentOpenShift: 'ParentOpenShift',
  ChildOpenShift: 'ChildOpenShift',
  ParentVTO: 'ParentVTO',
  ChildVTO: 'ChildVTO',
  Inventory: 'Inventory',
  TeamShift: 'TeamShift',
  CalledOff: 'CalledOff',
  Unknown: 'Unknown',
*/

  const getShiftSubType = (shift) => {
    const SubType = constants.ShiftSubType
    if ( isValidObject(shift) == false ){
      return SubType.Unknown
    }else if ( shift.name == 'open_shift' ){

      if( shift.trade_status == 'unpublished_posted' ){
          return SubType.DraftOpenShift

      }else if ( shift.trade_status == 'posted' ){

        if ( shift.parent_card == true ){
          return SubType.ParentOpenShift
        }
      }else if ( shift.trade_status == 'approved' ){
        if ( shift.child_card == true ){
          return SubType.ChildOpenShift
        }
      }
      return SubType.Unknown

    }else if ( shift.trade_status == 'inventory' ) {
      return SubType.Inventory
    }else if ( shift.trade_status == 'unpublished_inventory' ) {
      return SubType.DraftInventory
    }else if ( shift.trade_status == 'unregistered_unpublished_inventory' ){
      return SubType.DraftUnregisteredInventory
    }else if ( shift.trade_status == 'called_off'){
      return SubType.CalledOff
    }
  }

  const isPostingBulkShifts = (assignToTarget)  => {
    return (assignToTarget.assignToOpenShift == true)
  }

  const shouldCountTowardsOpenShiftTotalHours = (shift) => {
    const subtype = getShiftSubType(shift)

    return (subtype == constants.ShiftSubType.DraftOpenShift || subtype == constants.ShiftSubType.ParentOpenShift) 
  }

  const populateOpenShiftHoursForWeek = (shifts, statsDictionary) => {

    let totalHoursForWeek = 0;
    let totalSlotsForWeek = 0;

    shifts.forEach(shift => {
      if( shouldCountTowardsOpenShiftTotalHours(shift) === true ){
  
        const startDate = new Date(shift.start_at);
        const endDate = new Date(shift.end_at);
        const weekday = startDate.toLocaleDateString('en-US', { weekday: 'long' });
  
        // Check if unregistered_user_id is available, otherwise use owner_id
        const userId = shift.unregistered_user_id || shift.owner_id;
  
        const hoursDifference = (endDate - startDate) / (1000 * 60 * 60);
        const hoursForOpenShift =  hoursDifference * shift.total_shifts_available 
  
        if( statsDictionary != null ){
          statsDictionary[weekday].totalSlots += shift.total_shifts_available;
          statsDictionary[weekday].totalHours += hoursForOpenShift;
        }
  
        totalHoursForWeek += hoursForOpenShift;
        totalSlotsForWeek += shift.total_shifts_available;
      }
    });
  
    statsDictionary["WeekTotalHours"] = totalHoursForWeek;
    statsDictionary["WeekTotalSlots"] = totalSlotsForWeek;
  
    return statsDictionary;
  }

  const initializeZeroStatsByWeekday = (weekdays) => {
    const initialStatsByWeekday = {}
    weekdays.forEach(weekday => {
        initialStatsByWeekday[weekday] = {
          uniqueUsers: new Set(),
          totalHours: 0,
          totalSlots: 0,
        };
    });
    return initialStatsByWeekday
  }

  const getApplicantIdFromChildCard = (shift, subtype) => {
    if( subtype == constants.ShiftSubType.ChildOpenShift )  {
      const approvedApplicantIDs = shift?.current_applicants_approved_ids
      if( approvedApplicantIDs?.length == 1 ){
        return approvedApplicantIDs[0] 
      }else {
        return null
      }
    }else{
      return null 
    }
  }

  const getApplicantUserIdFromChildCard = (shift, subtype) => {
    if( subtype == constants.ShiftSubType.ChildOpenShift )  {
      const approvedUserIDs= shift?.current_applicants_approved_user_ids
      if( approvedUserIDs?.length == 1 ){
        return approvedUserIDs[0] 
      }else {
        return null
      }
    }else{
      return null 
    }
  }

  //Should optimize this method to go through all shifts once, and slot all the shifts in the map in the future
  const shiftMatrixMappedToUsers= (shifts, sortedUsers, rangeStart, cfgShowInventory)  => {
    var shiftMatrix = {registeredUsers:{}, unregisteredUsers: {}}
    sortedUsers.forEach( user => {
      // console.log(JSON.stringify(user))
      const rowOfShifts = utils.getRowOfShiftsToDisplay(shifts, user, rangeStart, cfgShowInventory);

      if( user.status !== 'unregistered' ){
        shiftMatrix.registeredUsers[user.owner_id] = rowOfShifts
      }else if ( isValidObject(user) == true ){
        shiftMatrix.unregisteredUsers[user.id] = rowOfShifts
      }
    });
    return shiftMatrix
  }

  const getUserWeeklyShiftsFromMatrix = (user, matrix) => {
      var userShiftsForTheWeek = null
      if( user.status !== 'unregistered' ){
        userShiftsForTheWeek = matrix.registeredUsers[user.owner_id]
      }else{
        userShiftsForTheWeek = matrix.unregisteredUsers[user.id]
      }
      return userShiftsForTheWeek
  }

  const applicantHasOtherShifts = (applicant, presentedShift, rangeStart, shiftMatrix) => {
    const applicantWeeklyShifts = shiftMatrix.registeredUsers[applicant.user_id]
    const dayIndex = timeUtil.dayOfCurrentWeekRangeForShift(presentedShift, rangeStart)
    // console.log(applicantWeeklyShifts)
    const shiftsOnDay = applicantWeeklyShifts[dayIndex]
    const count = shiftsOnDay.length 

    if( count > 1){
      return true
    }else if ( count == 1 ){
      // approved child card does not count as conflict 
      if( shiftsOnDay[0]?.parent_id !== presentedShift.id ){
        return true 
      }else{
        return false 
      }
    }else{
      return false
    }
  }

  export default {
    usersWithUnpublishedChanges ,
    userHasShiftConflict,
    getShiftSubType,
    isPostingBulkShifts,
    populateOpenShiftHoursForWeek,
    initializeZeroStatsByWeekday,
    getApplicantIdFromChildCard,
    getApplicantUserIdFromChildCard,
    shiftMatrixMappedToUsers,
    getUserWeeklyShiftsFromMatrix,
    applicantHasOtherShifts,
  }