import React from 'react';
import { PubNubConsumer } from 'pubnub-react';
import { connect } from 'react-redux';

import { dashboardOperations } from './duck';
import { dashboardActions } from './duck';
import { appActions } from 'App/duck';
import DashboardComponent from './DashboardComponent';
import { userOperations } from '_data/modules/Users';

const mapStateToProps = ({ setupReducer, locationReducer, appReducer, dashboardDuckReducer, }) => ({
  locationConfig:
    setupReducer.setup_actions.meta.current_location.configuration,
  location: 
    locationReducer.locations.length > 1 ? locationReducer.location_data : locationReducer.locations[0],
  locationId: locationReducer.location_code,
  departments: appReducer.departments.data,
  myDepartments: appReducer.departments.myDepartments,
  totalRTOs: dashboardDuckReducer.requestTimeOffs.total,
  onlineUsers: appReducer.onlineUsers.users,
  plans: appReducer.plans.data,
  locationData: locationReducer.location_data,
  allowScheduleUpload: appReducer.location.settings ? appReducer.location.settings.cfg__schedule_upload : null
});

const mapDispatchToProps = (dispatch) => ({
  handleShiftUpdate: (shift) =>
    dispatch(dashboardOperations.handleShiftUpdate(shift)),
  approvePendingShift: (shift, configId) =>
    dispatch(dashboardOperations.approvePendingShift(shift, configId)),
  getCostCenterData: (locationId) =>
    dispatch(dashboardOperations.getCostCenterData(locationId)),
  getRequestTimeOffs: (locationId, pageNo, numPerPage) =>
    dispatch(dashboardOperations.getRequestTimeOffs(locationId, pageNo, numPerPage)),
  getLocationEmployees: (locationId) =>
    dispatch(dashboardOperations.getLocationEmployees(locationId)),
  getLocationUsableSkills: (locationId) =>
    dispatch(dashboardOperations.getLocationUsableSkills(locationId)),
  getPendingUsers: (locationId) =>
    dispatch(userOperations.fetchPendingUsers(locationId)),
  getLocationInnerDepartments: (locationId) =>
    dispatch(dashboardOperations.getLocationInnerDepartments(locationId)),
  getLocationMyDepartments: (locationId) =>
    dispatch(dashboardOperations.getLocationMyDepartments(locationId)),
  
  getLocationEmployeesNew: (locationId) => {
    const params = {
    location_id: locationId,
    page: 1,
  };
    dispatch(dashboardOperations.fetchAllUsers(params));
  },

  resetTotalRTOs: () => {
    dispatch(dashboardActions.resetTotalRTOs(true));
  }
  
});

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  return (
    <PubNubConsumer>
      {(client) => {
        const { locationConfig,
                handleShiftUpdate,
                approvePendingShift, 
                location,
                locationId,
                getCostCenterData, 
                getRequestTimeOffs,
                getLocationEmployees,
                getLocationEmployeesNew,
                getLocationUsableSkills,
                getPendingUsers,
                getLocationInnerDepartments,
                departments,
                getLocationMyDepartments,
                myDepartments,
                totalRTOs,
                resetTotalRTOs,
                onlineUsers,
                plans,
                locationData,
                allowScheduleUpload
                } = props;

        return (
          <DashboardComponent
            pubnub={client}
            locationConfig={locationConfig}
            handleShiftUpdate={handleShiftUpdate}
            approvePendingShift={approvePendingShift}
            location={location}
            locationId={locationId}
            getCostCenterData={getCostCenterData}
            getRequestTimeOffs={getRequestTimeOffs}
            getLocationEmployees={getLocationEmployees}
            getLocationEmployeesNew={getLocationEmployeesNew}
            getLocationUsableSkills={getLocationUsableSkills}
            getPendingUsers={getPendingUsers}
            getLocationInnerDepartments={getLocationInnerDepartments}
            departments={departments}
            getLocationMyDepartments={getLocationMyDepartments}
            myDepartments={myDepartments}
            totalRTOs={totalRTOs}
            resetTotalRTOs={resetTotalRTOs}
            onlineUsers={onlineUsers}
            plans={plans}
            locationData={locationData}
            allowScheduleUpload = {allowScheduleUpload}
          />
        );
      }}
    </PubNubConsumer>
  );
});

export default DashboardContainer;
