import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { messageDateFormat, } from 'helpers/date-time';

import Modal from 'components/GeneralComponents/Modal';
import EditGroupComponent from './EditGroupComponent';

import elements from '../../elements';
import blocks from '../../blocks';
import styled from './styled';
import './GroupComponent.scss';

const sha1 = require('js-sha1');

class GroupComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={
      //closePinPane: false,
      channelMembers: [],
      location: null,
      openEdit: false,
    };

    this._isMounted = false;

    this.handleClose = this.handleClose.bind(this);

    this.closePinPane = false;
  }

  componentDidMount() {
    const { active, activePost, allUsers, activeChannel, locations, locationId, } = this.props;

    this._isMounted = true;

    if(activeChannel && allUsers) {
      const channelUsers = allUsers.filter((user) =>  
        user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
      if(channelUsers) {
        this.setState({channelMembers: channelUsers});
      }
    }

    if(locations && locations.length > 0) {
      const currentLocation = locations.filter((location) => location.id === parseInt(locationId));
      if(currentLocation && currentLocation.length > 0) {
        this.setState({location: currentLocation[0]});
      }
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    const { active, activeChannel, allUsers, locations, locationId, } = this.props;

    if(prevProps.activeChannel !== activeChannel) {
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
        }
      }
    }

    if(prevProps.allUsers !== allUsers) {
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserUUID = (userId) => {
    return `user.${sha1(`user.${userId}`)}`;
  };

  handleClose(event) {
    const {resetActiveSidePane} = this.props;

    this.setState({closePinPane: false});
    this.closePinPane = true;
    resetActiveSidePane();
    event.preventDefault();
  }

  handleEditDetailsClicked = (event) => {
    const { openEdit, } = this.state;

    this.setState({openEdit: true});
  }

  notifyCancel = () => {
    this.setState({openEdit: false});
  }

  notifySaveChanges = (channelId, name, description) => {
    const { updateChannelInfo, } = this.props;

    this.setState({openEdit: false});
    updateChannelInfo(channelId, name, description);
  }

  render () {
    const {pins, activeSidePane, allUsers, onlineUsers, activeChannel, updateChannelInfo, active, } = this.props;
    const { channelMembers, location, openEdit, } = this.state;

    if(this.closePinPane){
      this.closePinPane = false;
      return null;
    }

    let numUsers = 0;
    if(channelMembers.length === 0) {
      if(activeChannel && activeChannel.member_count) {
        numUsers = activeChannel.member_count;
      }
    }else {
      numUsers = channelMembers.length;
    }

    const title = (<FormattedMessage id="GroupComponent.headerLabel" defaultMessage="Group Details" />);
    const channel_type = activeChannel ? activeChannel.channel_type : '';
    const description = active && active.subscriptions ? active.subscriptions.channel.description : '';
    const showEditButton = active && active.userIsAdmin && active.subscriptions && 
                          (active.subscriptions.channel.channel_type !== 'location_feed' && 
                            active.subscriptions.channel.channel_type !== 'region_feed' &&
                            !active.subscriptions.channel.shyft_channel);
    const members = (<FormattedMessage
              id="GroupComponent.members"
              defaultMessage="+{value} members"
              values={{ value: numUsers}}
            />);
    const buttonEdit = (<FormattedMessage
              id="GroupComponent.buttonEdit"
              defaultMessage="Edit Group Details"
            />);

    let avatarUrl = '';
    if(channel_type === 'location_feed') {
      if(location && location.avatar_blob) {
        avatarUrl = location.avatar_blob.blob_url;
      }else {
        avatarUrl = '/icons/channel avatar.svg';
      }
    }else if(channel_type === 'custom_feed') {
      if(activeChannel && activeChannel.shyft_channel) {
        avatarUrl = '/icons/favicon.ico';
      }else {
        avatarUrl = '/icons/channel avatar.svg';
      }
    }else if(channel_type === 'region_feed') {
      avatarUrl = '/icons/channel avatar.svg';
    }
    

    return (
      <elements.ColumnWrap>
        <div className="group__wrap">
          <p className="group__title">{title}</p>
          <button className="group__close-button" onClick={this.handleClose}/>
        </div>
        <elements.ScrollWrap isLoading={false}>
        <div className="group__inner-wrap">
          {activeChannel && <styled.ChannelAvatar src={avatarUrl} />} 
          <label className="group__inner-title">{activeChannel && activeChannel.channel_name}</label>
          <label className="group__text">{members}</label>
          <label className="group__description">{description}</label>
          <label className="group__address">{location ? location.formatted_address : ''}</label>
          {showEditButton && <button className="group__edit-button" onClick={this.handleEditDetailsClicked}>{buttonEdit}</button>}
        </div>
        {false && <elements.Loading />}
        {openEdit && <Modal>
          <EditGroupComponent
            active={active}
            notifyCancel={this.notifyCancel}
            notifySaveChanges={this.notifySaveChanges}
          />
        </Modal>}
        <styled.Overlay display={openEdit ? 'block' : 'none'} />    
        </elements.ScrollWrap>
      </elements.ColumnWrap>
    );

  }
}

GroupComponent.propTypes = {
  //pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(GroupComponent);

