import React from 'react';
import { connect } from 'react-redux';
import { scheduleCalendarOperations } from 'pages/ScheduleCalendar/duck';
import timeUtil from 'pages/ScheduleCalendar/duck/timeUtil';
import OpenShiftDroppableCellBox from './OpenShiftDroppableCellBox';

const mapStateToProps = ({
  scheduleCalendarReducer,
}) => {
  return {
    allUsers: scheduleCalendarReducer.allUsers.users,
  };
};

const mapDispatchToProps = (dispatch) => {

    const copyOpenShiftToDateDispatch = (shiftToCopy, date, locationId) => {
        const targetStartTime = timeUtil.pastedShiftStartTime(shiftToCopy, date);
        const targetEndTime = timeUtil.pastedShiftEndtime(shiftToCopy, date);
        const targetJobTitle = shiftToCopy.primary_job;
        const channelId = shiftToCopy.channel_id;
        const content = shiftToCopy.content;
        const total = shiftToCopy.total_shifts_available;
        dispatch(scheduleCalendarOperations.addDraftOpenShiftToUserOnDateOperation(shiftToCopy.owner_id, targetStartTime, targetEndTime, targetJobTitle, locationId,channelId, content, total))
      }

    const moveDraftOpenShiftDispatch = (shift, targetDate) => {

        var isMoveToNewDate = false;
        var targetStartTime = null;
        var targetEndTime = null;
  
        if( timeUtil.haveSameDate(shift.start_at, targetDate) == false) {
          isMoveToNewDate = true;
          //Calculate targetStartTime & targetEndTime
          targetStartTime = timeUtil.calculateTargetTime(shift.start_at, targetDate);
          const diff = timeUtil.shiftDuration(shift.start_at, shift.end_at);
          targetEndTime = timeUtil.addTimeDiffTo(targetStartTime, diff);
  
          console.log("From: " + shift.start_at + " - " + shift.end_at + ' to ' + targetStartTime + ' -'  + targetEndTime);
        }else{
          console.log("Same Day: " + shift.start_at + ' - ' + targetDate);
        }

        if( isMoveToNewDate == true ) {
            updateDraftOpenShiftDispatch(shift.id, shift.owner_id, targetStartTime, targetEndTime, shift.primary_job, shift.location_id, targetDate, shift.channel_id, shift.total_shifts_available, shift.content);
        }
    }

    const deleteDraftOpenShiftDispatch = (shiftId, locationId) => {
        dispatch(scheduleCalendarOperations.deleteShiftOperation(shiftId, locationId));
    };

    const updateDraftOpenShiftDispatch = (shiftId, currentUserId, startDateTime, endDateTime, jobTitle, locationId, date, channelId, quantity, shiftDescription) => {
        deleteDraftOpenShiftDispatch(shiftId, locationId)
        dispatch(scheduleCalendarOperations.addDraftOpenShiftToUserOnDateOperation(currentUserId, startDateTime, endDateTime,jobTitle, locationId, channelId, shiftDescription, quantity));
    }

  return {
    moveDraftOpenShiftDispatch,
    copyOpenShiftToDateDispatch,
  };
};

const OpenShiftDroppableCellBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {
    user,
    date,
    shiftsForDay,
    weekRangeStart,
    selectedDateToAddShift,
    isCopyingShift,
    shiftToCopy,
    
    allUsers,
    selectedShiftToViewDetail,

    copyOpenShiftToDateDispatch,
    moveDraftOpenShiftDispatch,
    } = props;

   return <OpenShiftDroppableCellBox
        user={user} 
        date={date} 
        shiftsForDay={shiftsForDay} 
        weekRangeStart={weekRangeStart}
        selectedDateToAddShift={selectedDateToAddShift} 
        selectedShiftToViewDetail = {selectedShiftToViewDetail}
        copyOpenShiftToDateDispatch={copyOpenShiftToDateDispatch} 
        isCopyingShift={isCopyingShift} 
        shiftToCopy={shiftToCopy}
        moveDraftOpenShiftDispatch = {moveDraftOpenShiftDispatch}
        allUsers = {allUsers} 
   />
});

export default OpenShiftDroppableCellBoxContainer;