import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { appShapes } from 'App/duck';
import styled from './styled';
import blocksStyled from '../styled';
import Icon from './Icon';
import { messageDateFormat } from 'helpers/date-time';
import {Mixpanel} from '../../../../Mixpanel';

class ChannelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      receiveNewPost: false,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { locations, locationId, } = this.props;

    this._isMounted = true;
    if(locations && locations.length > 0) {
      const currentLocation = locations.filter((location) => location.id === parseInt(locationId));
      if(currentLocation && currentLocation.length > 0) {
        this.setState({location: currentLocation[0]});
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { active, channel, handleClick, location, locations, locationId, } = this.props;

    if(prevProps.locations !== locations) {
      if(locations && locations.length > 0) {
        const currentLocation = locations.filter((location) => location.id === parseInt(locationId));
        if(currentLocation && currentLocation.length > 0) {
          this.setState({location: currentLocation[0]});
        }
      }
    }

    if(prevProps.channel !== channel) {
      if(channel && channel.receiveNewPost && channel.receiveNewPost === true) {
        this.setState({receiveNewPost: true});
      }else {
        this.setState({receiveNewPost: false});
      }
    }

  }

  componentWillUnmount() {

    this._isMounted = false;
  }

  render() {
    const { active, channel, handleClick, } = this.props;
    const { location, receiveNewPost, } = this.state;

    let avatarUrl = '';
    if(channel.channel_type === 'location_feed') {
      if(location && location.avatar_blob) {
        avatarUrl = location.avatar_blob.blob_url;
      }else {
        avatarUrl = '/icons/channel avatar.svg';
      }
    }else if(channel.channel_type === 'custom_feed') {
      if(channel.shyft_channel) {
        avatarUrl = '/icons/favicon.ico';
      }else {
        avatarUrl = '/icons/channel avatar.svg';
      }
    }else if(channel.channel_type === 'region_feed') {
      avatarUrl = '/icons/channel avatar.svg';
    }

    const approvedBadge = '/icons/verified-badge-icon.svg';
    const condition = false;
   
    const dateTimeInfo = messageDateFormat(channel.updated_at);
    let lastContent = '';
    if(channel && channel.channel_latest_content) {
      if(channel.channel_latest_content.length > 3) {
        lastContent = channel.channel_latest_content;
      }
    }

    return (
      <blocksStyled.ChannelMessageWrap
        modifiers={active ? 'active' : null}
        onClick={() => {
          handleClick(channel.id);
          Mixpanel.track('Click channel feed', {'channel_id':channel.id});
        }}
      >
        <styled.ChannelAvatar src={avatarUrl}/>
        <blocksStyled.Info>
          <styled.TopLineWrap>
            <styled.Name>{channel ? channel.channel_name : ''}</styled.Name>
            {condition && <styled.ApprovedBadge src={approvedBadge}/>}
            <styled.Time>{dateTimeInfo}</styled.Time>
          </styled.TopLineWrap>
          <styled.BottomLineWrap>
            <styled.LatestContent>{lastContent}</styled.LatestContent>
            {receiveNewPost && <styled.NewPostIndicator/>}
          </styled.BottomLineWrap>
        </blocksStyled.Info>
      </blocksStyled.ChannelMessageWrap>
    );
  }
}

ChannelComponent.propTypes = {
  //fetchPosts: PropTypes.func.isRequired,
  
};

export default injectIntl(ChannelComponent);



