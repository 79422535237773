import { createSlice } from '@reduxjs/toolkit';

const scheduleUsersSlice = createSlice({
  name: 'scheduleUsers',
  initialState: {
    users: [],
    page: 0,
    totalPages: 0,
    loading: false,
    error: false,
  },
  reducers: {
    requestAllUsers: (state, action) => {
      state.loading = true;
    },
    receiveAllUsers: (state, action) => {
      state.users = action.payload.users;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.loading = false;
    },
    appendAllUsers: (state, action) => {
      state.users = state.users.concat(action.payload.users);
      state.loading = false;
    },
    resetAllUsers: (state, action) => {
      state.users = [];
      state.page = 0
      state.totalPages = 0;
      state.loading = false;
      state.lastAction = null;
    },
    setError: (state, action) => {
      state.error = true;
    },
    resetError: (state, action) => {
      state.error = false;
    },
  },
});

export default scheduleUsersSlice;
