import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { validateEmail, validatePhoneNumber } from 'helpers/validation';
import { EmailPhoneInput } from '@shyft/acorns';
import HoneyPotInput from 'components/GeneralComponents/HoneyPotInput/HoneyPotInput';
import { countriesArr, defaultCountry } from '../../../countries';
import './LoginFirstStage.scss';
import Flag from '../../../Flag';

const FormElement = styled.div.attrs({
  'data-cy': 'login-form-element',
})`
  margin-bottom: 4rem;
`;

class LoginFirstStagei18n extends React.Component {
  state = {
    emailPhone: {
      dialCode: '1',
      value: '',
      error: '',
    },
    selectedCountry: defaultCountry, // Default to USA
    countrySelectionOpen: false,
    honeyPotValidity: true,
  };

  messages = defineMessages({
    invalidEmailPhone: {
      id: 'LoginFirstStage.invalidEmailPhone',
      defaultMessage: 'Please enter a valid email or phone number',
    },
    emailPhonePlaceholder: {
      id: 'LoginFirstStage.emailPhonePlaceholder',
      defaultMessage: 'Email or phone number',
    },
  });

  _handleChange = (e) => {
    const { emailPhone } = this.state;

    this.setState({
      emailPhone: {
        ...emailPhone,
        value: e.target.value,
      },
    });
  };



  _handleHoneyPotInputChange = (validity) => {
    this.setState({
      honeyPotValidity: validity,
    });
  };

  _handleFormSubmit = (e) => {
    const { _formSubmit, intl } = this.props;
    const { emailPhone, honeyPotValidity } = this.state;
    e.preventDefault();

    if (!honeyPotValidity) {

      return false;
    }

    if (validatePhoneNumber(emailPhone.value)) {
      // Input is phone number

      _formSubmit({
        type: 'phone',
        value: emailPhone.dialCode + emailPhone.value,
      });

      return null;
    }

    if (validateEmail(emailPhone.value)) {
      // Input is email
      _formSubmit({
        type: 'email',
        value: emailPhone.value,
      });

      return null;
    }

    // Input is invalid

    this.setState({
      emailPhone: {
        ...emailPhone,
        error: intl.formatMessage(this.messages.invalidEmailPhone),
      },
    });
  };

  toggleCountryDropdown = () => {
    this.setState((prevState) => ({
      countrySelectionOpen: !prevState.countrySelectionOpen,
    }));
  };

  _handleCountryChange = (dialCode) => {
    const { emailPhone } = this.state;

    this.setState({
      emailPhone: {
        ...emailPhone,
        dialCode,
      },
    });
  };

  selectCountry = (country) => {
    const { emailPhone } = this.state;
    const phoneCode = country[2];
    this.setState({
      emailPhone: {
        ...emailPhone,
        dialCode: phoneCode,
      },
      selectedCountry: country,
      countrySelectionOpen: false,
    });
  };

  populateCountryList = (countries) => countries.map((country) => {
    const name = country[0];
    const isoCode = country[1];
    const phoneCode = country[2];

    return (
      <div
        key={isoCode}
        className="country-option"
        onClick={() => this.selectCountry(country)}
      >

        <Flag isoCode={isoCode} />
        <span>{name} +{phoneCode}</span>
      </div>
    );
  });

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        this._handleFormSubmit(e);
    }
};

  render() {
    const {
      intl: { formatMessage },
      error,
    } = this.props;
    const { emailPhone, selectedCountry, countrySelectionOpen } = this.state;

    return (

      <>
        <div className="name-prompt">Email or Phone Number</div>
        {/* {formatMessage(this.messages.emailPhonePlaceholder)} */}
        <div className="icon-input-container">
          <input
            style={{ paddingLeft: '80px' }}
            type="text"
            placeholder="Enter Email or Phone Number"
            className="icon-name-input"
            name="phone"
            value={emailPhone.value}
            onChange={this._handleChange}
            onKeyDown={this.handleKeyDown}
          />
          <img

            className="input-icon-left"
            src='/icons/email-icon.svg'
            alt="Phone Icon"
          />
          <img
            style={{ marginLeft: '32px' }}
            className="input-icon-left"
            src='/icons/phone-icon.svg'
            alt="Phone Icon"
          />
          <div
            className="country-selector"
            onClick={this.toggleCountryDropdown}
          >

            <Flag isoCode={selectedCountry[1]} />

            <img
              className="dropdown-arrow"
              src='/icons/dropdown-arrow-icon.svg'
              alt="Toggle Country Dropdown"
            />
          </div>
          {countrySelectionOpen && (
            <div className="country-dropdown">
              {this.populateCountryList(countriesArr)}
            </div>
          )}
        </div>
        <p className='error-message'>
          {emailPhone.error ? emailPhone.error : error}
        </p>
        <button className="next-btn" onClick={this._handleFormSubmit}>Next</button>
      </>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
LoginFirstStagei18n.propTypes = {
  _formSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const LoginFirstStage = injectIntl(LoginFirstStagei18n);

export { LoginFirstStage, LoginFirstStagei18n };
