import { createSlice } from '@reduxjs/toolkit';

const applicantsSlice = createSlice({
  name: 'applicants',
  initialState: {
    data: [],
    shiftId: null,
  },
  reducers: {

    hydrateApplicants: (state, action) => {
      // console.log(action.payload);
      state.data = action.payload.applicants;
      state.shiftId = action.payload.shiftId;
    },

    resetApplicants: (state) => {
      state.data = [];
    },

    updateApplicant: (state, action) => {
      state.data = state.data.map(item => item.id === action.payload.applicant.id ? action.payload.applicant: item)
    },

    upsertSingleApplicant: (state, action) => {
      const applicantId = action.payload.applicant.id;
      const applicantShiftId = action.payload.applicant.schedule_element_id;
      if( state.shiftId !== null && state.shiftId == applicantShiftId ){
        const existingApplicants = state.data.filter( (applicant) => applicant.id == applicantId); 
        if( existingApplicants.length > 0 ){
          state.data = state.data.map(item => item.id === action.payload.applicant.id ? action.payload.applicant: item);
        }else{
          state.data = state.data.concat(action.payload.applicant);
        }
      }
    },
  },
});

export default applicantsSlice;