import React from 'react';
import PropTypes from 'prop-types';
import { ProfileThumb } from '@shyft/acorns';

import { commentFormat, messageDateFormat, } from 'helpers/date-time';
import { teamFeedShapes } from '../../duck';
import ActionButtons from '../ActionButtons';
import styled from './styled';
import blocksStyled from '../styled';
import deleteIcon from '../ActionButtons/assets/delete-icon-new1.svg';

const sha1 = require('js-sha1');

class Comment extends React.PureComponent {
  constructor(props) {
    super(props);
  
    this.state = {
      hovered: false,
      theUser: null,
    };
  };

  componentDidMount() {
    const {data, allUsers, onlineUsers, } = this.props;
    const { theUser, } = this.state;
    const {attachment} = data;
    if(attachment){
      this.setState({allPictures: attachment.object_instances});
    }
    if(data && allUsers) {
      const users = allUsers.filter((user) => user.first_name === data.owner.first_name && user.last_name === data.owner.last_name);
      if(users && users.length > 0) {
        this.setState({theUser: users[0]});
      }
    }

  };

  componentDidUpdate(prevProps, prevState) {
    const {data, allUsers, onlineUsers, } = this.props;
    const { theUser, } = this.state;

    if(prevProps.data !== data) {
      if(data && allUsers) {
        const users = allUsers.filter((user) => user.first_name === data.owner.first_name && user.last_name === data.owner.last_name);
        if(users && users.length > 0) {
          this.setState({theUser: users[0]});
        }
      }
    }

    if(prevProps.allUsers !== allUsers) {
      if(allUsers && allUsers.length > 0) {
        if(data) {
          const users = allUsers.filter((user) => user.first_name === data.owner.first_name && user.last_name === data.owner.last_name);
          if(users && users.length > 0) {
            this.setState({theUser: users[0]});
          }
        }
      }
    }
  };

  componentWillUnmount() {

  };

  handleMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  getUserUUID = (userId) => {
    return `user.${sha1(`user.${userId}`)}`;
  };

  render() {
    const { data, clickDelete, active, allUsers, onlineUsers, } = this.props;
    const { hovered, theUser, } = this.state;
    const { attachment, content, created_at, owner, allow_delete } = data;

    let profileUrl = '/icons/default-profile-thumb.svg';
    if(owner && owner.thumb_url) {
      profileUrl = owner.thumb_url;
    }

    const showAdminBadge = theUser && theUser.is_admin ? true : false;
    const approvedBadge = '/icons/verified-badge-icon.svg';
    const userUUID = theUser ? this.getUserUUID(theUser.owner_id) : this.getUserUUID(data.owner_id);
    let showOnline = false;
    if(onlineUsers && onlineUsers.includes(userUUID)) {
      showOnline = true;
    }

    return (
      <styled.MessageWrap
        modifiers={active ? 'active' : null}
        announcement={false}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={() => {
          void 0;
        }}
      >
        <styled.AvatarWrap>
          <styled.ProfileAvatar src={profileUrl}/>
          {showOnline && <styled.OnlineMark/>}
        </styled.AvatarWrap>
        {/*<blocksStyled.Info>
          <styled.Name>{owner !== undefined ? `${owner.first_name} ${owner.last_name}` : null}
            {showAdminBadge && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.Name>
          <styled.Timestamp>{commentFormat(created_at)}</styled.Timestamp>
          <styled.Content>{content}</styled.Content>
          {!attachment || (
            attachment.object_instances.map((instance, index) =>(
                <styled.Image key={index} src={instance.gallery_url} />
            ))
          )}
          {hovered && allow_delete ? (
            <ActionButtons>
              <ActionButtons.Button onClick={clickDelete} modifiers="delete" />
            </ActionButtons>
          ) : null}
        </blocksStyled.Info>*/}
        <styled.InfoWrap>
          <styled.Name>
             {owner !== undefined ? `${owner.first_name} ${owner.last_name}` : null}
             {showAdminBadge && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.Name>
           <styled.Timestamp>{messageDateFormat(created_at)}</styled.Timestamp>
           <styled.Content>{content}</styled.Content>
           {/*hovered && allow_delete ? (
            <ActionButtons>
              <ActionButtons.Button onClick={clickDelete} modifiers="delete" />
            </ActionButtons>
            ) : null*/}
           {hovered && allow_delete ? 
           <styled.ButtonWrap>
           <styled.Button src={deleteIcon} onClick={clickDelete}/> 
           </styled.ButtonWrap>
           : null}
        </styled.InfoWrap>
      </styled.MessageWrap>
    );
  }
}

Comment.propTypes = {
  clickDelete: PropTypes.func.isRequired,
  data: teamFeedShapes.posts.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Comment;
