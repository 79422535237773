import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './AddUnregisteredUserComponent.scss';
import '../../App/layout.scss';
import '../../App/fonts.scss';
import { validateEmail} from '../../helpers/validation';
import { inviteCall } from 'helpers/api-calls';
import {Mixpanel} from '../../Mixpanel';
import { Branchlink } from 'helpers/branchlink';

import strings from 'strings/text';

class AddUnregisteredUserComponent extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        confirmButtonEnabled:false, 
        firstName: '',
        lastName: '',
        shouldSendDownloadLink: true,
        email: '',
      };

      this.firstNameInputRef = React.createRef();
      this.lastNameInputRef = React.createRef();
      this.emailInputRef= React.createRef();
    }
  
    componentDidMount() {
      const {locationData} = this.props;
      Branchlink.initializeBranchLink(locationData);
    }

    componentDidUpdate() {

    }

    handleFirstNameChange = (e) => {
      const {lastName, email} = this.state;
      const val = e.target.value;
      this.validateInputs(val, lastName, email);
      this.setState({firstName: val});
    }

    handleLastNameChange = (e) => {
      const {firstName, email} = this.state;
      const val = e.target.value;
      this.validateInputs(firstName, val, email);
      this.setState({lastName: val});
    }

    handleEmailChange = (e) => {
      const {firstName, lastName} = this.state;
      const val = e.target.value;
      this.validateInputs(firstName, lastName, val);
      this.setState({email: val});
    }

    validateInputs (fName, lName, email) {
      if(this.areInputsValid(fName, lName, email) == true){
        this.setState({ confirmButtonEnabled: true });
      }else{
        this.setState({ confirmButtonEnabled: false});
      }
    }

    areInputsValid (fName, lName, email) {
      if( fName && fName.length > 1 && fName.length < 60
        && lName && lName.length > 1 && lName.length < 60
      && email && validateEmail(email)){
        return true;
      }else{
        return false;
      }
    }

    canAddUnregisteredUser () {
      const {firstName, lastName, email} = this.state;
      return this.areInputsValid(firstName, lastName, email);
    }

    handleCheckbox = (e) => {
      this.setState({shouldSendDownloadLink: e.target.checked});
    }

    handleSaveAddAnother = (e) => {
      if( this.canAddUnregisteredUser() === true){
        this.addUnregisteredUser();
        this.firstNameInputRef.current.value = '';
        this.lastNameInputRef.current.value = '';
        this.emailInputRef.current.value = '';
        this.setState({confirmButtonEnabled: false,
          firstName: '', lastName: '', email:'' 
        });
        Mixpanel.track('Unreg save and add another');
      }
    }

    handleSaveAndFinish = (e) => {
      const {handleCloseAddTeamModal} = this.props;

      if( this.canAddUnregisteredUser() === true ){
        this.addUnregisteredUser();
        Mixpanel.track('Unreg save and finish');
        handleCloseAddTeamModal();
      }
    }

    addUnregisteredUser () {
      const {firstName, lastName, shouldSendDownloadLink, email} = this.state;
      const {locationId, createUnregisteredUserDispatch} = this.props;
      createUnregisteredUserDispatch(locationId, firstName, lastName, email);

      const link = Branchlink.getSMSInviteLink(locationId);

      if(shouldSendDownloadLink === true) {
        Mixpanel.track('Send Email Invite to new number', {'email':email, 'link': link});
        const fullName = firstName + ' ' + lastName;
        this.sendInvite( email, fullName, locationId, link );
      }

    }

    sendInvite(email, fullName, locationId, link){
      inviteCall(email, fullName, locationId, link).then(
        (response) => {
          if (response.status === 200) {
          }
        },
        (error) => { });
    }

    _handleCountryChange = (code) => {
      this.setState({
        dialCode: code
      });
    };

    render() {
        const {handleCloseAddTeamModal} = this.props;
        const {confirmButtonEnabled,firstName,lastName,email} = this.state;

        const addAnotherText = (<FormattedMessage id="AddUnregisteredUserComponent.addAnother" defaultMessage="Save and Add Another" />);
        const saveFinishText = (<FormattedMessage id="AddUnregisteredUserComponent.saveAndFinish" defaultMessage="Save and Finish" />);
        const firstNameText = (<FormattedMessage id="AddUnregisteredUserComponent.firstName" defaultMessage="First Name" />);
        const lastNameText = (<FormattedMessage id="AddUnregisteredUserComponent.lastName" defaultMessage="Last Name" />);
        const emailText = (<FormattedMessage id="AddUnregisteredUserComponent.email" defaultMessage="Email" />);

        const sendLinkText = (<FormattedMessage id="AddUnregisteredUserComponent.sendLink" defaultMessage="Send a download link to the Shyft mobile app" />);

        return (
            <div className="add-team-member-container modal-container">
              <div className='flex-row title-row'>
                <label className="title averta-regular fs20px">{strings.addTeamMember}</label>
                <button onClick={ () => {handleCloseAddTeamModal();} }> <img src="icons/corner-close.svg" /> </button>
              </div>

              <div className='flex-row'>
                <div className="averta-regular fs14px lightgray first-name">{firstNameText}</div>
                <div className="averta-regular fs14px lightgray last-name">{lastNameText}</div>
              </div>

              <div className='flex-row name-wrap'>
                  <input onChange={this.handleFirstNameChange} value={firstName} ref={this.firstNameInputRef}></input>
                  <span className='picker-divider'></span>
                  <input onChange={this.handleLastNameChange} value={lastName} ref={this.lastNameInputRef}></input>
              </div>

              <label className="averta-regular fs16px lightgray phone-label">{emailText}</label>
              <div className='flex-row country-wrap'> 
                <input onChange={this.handleEmailChange} value={email} ref={this.emailInputRef}></input> 
              </div>

              <div className='flex-row check-wrap'>
                <input type="checkbox" defaultChecked={true} onChange={this.handleCheckbox}></input>
                <label> {sendLinkText} </label>
              </div>

              <div className="confirm-button-wrap">
                <button className={confirmButtonEnabled ? "confirm-button-cancel averta-semibold fs14px" : "confirm-button-cancel averta-semibold fs14px line-button-disabled"} onClick={this.handleSaveAndFinish}>{saveFinishText}</button>
                {/* <button className=`confirm-button ` {confirmButtonEnabled ? "confirm-button averta-semibold fs14px button-enabled" : "confirm-button averta-semibold fs14px button-disabled"} style={{ outline: 0 }} onClick={this.handleSaveAddAnother}>{addAnotherText}</button> */}
                <button className={'confirm-button fs14px ff-semibold half-dimension ' + (confirmButtonEnabled == true ? "button-enabled" : "button-disabled") } style={{ outline: 0 }} onClick={this.handleSaveAddAnother}>{addAnotherText}</button>
              </div>
      
            </div>
          );
    }
}

export default injectIntl(AddUnregisteredUserComponent);