import { isValidObject } from "helpers/validation"
import service from "./service"
import constants from "../constants"
import { subtract } from "lodash";


/*
  DraftOpenShift: 'DraftOpenShift',
  DraftVTO: 'DraftVTO',
  DraftInventory: 'DraftInventory',
  DraftUnregisteredInventory: 'DraftUnregisteredInventory',
  ParentOpenShift: 'ParentOpenShift',
  ChildOpenShift: 'ChildOpenShift',
  ParentVTO: 'ParentVTO',
  ChildVTO: 'ChildVTO',
  Inventory: 'Inventory',
  TeamShift: 'TeamShift',
  Unknown: 'Unknown',
*/

const shouldShowSectionTab = (shiftObject) => {
    if ( isValidObject(shiftObject) == true && shiftObject?.name == 'open_shift' && shiftObject?.trade_status != 'unpublished_posted' ){
        return true;
    }else{
        return false;
    } 
}

const shouldShowApplicantsTabOnDetail = (subtype) => {
    return (subtype == constants.ShiftSubType.ParentOpenShift)
}

const shouldShowCommentsTabOnDetail = (subtype) => {
    return (subtype == constants.ShiftSubType.ChildOpenShift)
}

const shouldShowChildCardHeader = (presentedShift) => {
    const subtype = service.getShiftSubType(presentedShift) 
    if ( isValidObject(presentedShift) == true ) {
        return (subtype == constants.ShiftSubType.ChildOpenShift) 
    }
    return false 
}

const shouldShowPillTags = (presentedShift) => {
    const subtype = service.getShiftSubType(presentedShift) 
    return (subtype == constants.ShiftSubType.ParentOpenShift)
}

const postingOrViewingBulkShift = (assignToTarget, subtype) => {
    return service.isPostingBulkShifts(assignToTarget) && 
        (subtype == constants.ShiftSubType.ParentOpenShift || subtype == constants.ShiftSubType.DraftOpenShift || subtype == constants.ShiftSubType.Unknown)
}

const shouldShowQuantityField = (assignToTarget, subtype) => {
    return postingOrViewingBulkShift(assignToTarget, subtype)
}

const shouldShowPostToGroupField = (assignToTarget, subtype) => {
    return postingOrViewingBulkShift(assignToTarget, subtype)
}

const shouldShowDescriptionField = (assignToTarget, subtype) => {
    return postingOrViewingBulkShift(assignToTarget, subtype) || subtype == constants.ShiftSubType.ChildOpenShift
}

const shouldShowApplicantCommentsTab = (subtype) => {
    return subtype == constants.ShiftSubType.ChildOpenShift
}

const shouldShowSelectWeekdayField = (subtype) => {
    return subtype == constants.ShiftSubType.Unknown
}

const shouldShowSaveButton = (subtype) => {
    return ( subtype == constants.ShiftSubType.DraftOpenShift || 
           subtype == constants.ShiftSubType.ParentOpenShift ||
           subtype == constants.ShiftSubType.DraftInventory )
}

const disableAssignToField = (subtype) => {
    return (subtype !== constants.ShiftSubType.DraftOpenShift && subtype !== constants.ShiftSubType.Unknown)
}

const disablePositionField = (subtype) => {
    return (subtype == constants.ShiftSubType.ChildOpenShift || subtype == constants.ShiftSubType.TeamShift || subtype == constants.ShiftSubType.Inventory)
}

const disableDescriptionField = (subtype) => {
    return (subtype == constants.ShiftSubType.ChildOpenShift || subtype == constants.ShiftSubType.TeamShift || subtype == constants.ShiftSubType.Inventory)
}

const canEditTimeFields = () => {

}

const canEditPositionField = () => {

}

const disablePostToGroupField = (subtype) => {
    return (subtype == constants.ShiftSubType.ChildOpenShift || subtype == constants.ShiftSubType.ParentOpenShift)
}

const canEditDescriptionField = () => {

}

const canEditWeekdaySelector = () => {

}

export default {
    shouldShowApplicantsTabOnDetail,
    shouldShowCommentsTabOnDetail,
    shouldShowSectionTab,
    shouldShowChildCardHeader,
    shouldShowPillTags,
    shouldShowQuantityField,
    shouldShowPostToGroupField,
    shouldShowDescriptionField,
    shouldShowSelectWeekdayField,
    shouldShowApplicantCommentsTab,
    shouldShowSaveButton,
    disableAssignToField,
    disablePositionField,
    disableDescriptionField,
    canEditTimeFields,
    canEditPositionField,
    disablePostToGroupField,
    canEditDescriptionField,
    canEditWeekdaySelector,
}