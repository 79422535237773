import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import Modal from 'components/GeneralComponents/Modal';

import { getUserInfoNonAdmin, } from 'helpers/api-calls/user-calls';
import UserCell from './Cell/UserCell';
import StatusCell from './Cell/StatusCell';
import Confirm from './Confirm';
import ConfirmStep2 from './ConfirmStep2';
import constants from '../../constants';

import './UserTable.scss';
import styled from './styled';

import { Mixpanel } from '../../../../Mixpanel';
import {
  shiftDuration,
  isNextDay,
  startEndRange,
  formatTimeWithTimezone,
  formatDateWithDayAndTimezone,
} from '../../../../helpers/date-time';
import RequestCell from './Cell/RequestCell';
import { localTimeRangeFromTimeStamps } from 'helpers/calendarUtils'
import ActionCell from './Cell/ActionCell';

class UserRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      debug: false,
      userDepartments: null,
      userSkills: null,
      userGroups: [],
      shiftSelected: false,
      userInfo: null,
      locationPos: null,
      mouseOverLocation: false,
      groupDepartmentPos: null,
      mouseOverGroupDepartment: false,
      shiftSkillsPos: null,
      mouseOverShiftSkills: false,
      dropdownPos: null,
      openMenu: false,
      showConfirm: false,
      showConfirmStep2: false,
      selectedAction: null,
      isExpanded: false,
      isParentChecked: false,
      checkedChildren: props.shift.children ? props.shift.children.map(() => false) : [],
    };

    this._isMounted = false;
    this.apiCalled = false;
    this.calledUsers = [];
    this.locationRef = React.createRef();
    this.groupDepartmentRef = React.createRef();
    this.shiftSkillsRef = React.createRef();
    this.openMenuRef = React.createRef();
    this.menuWinRef = React.createRef();
    this.parentCheckboxRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { user, departments, skills, channels, locationId, channelData, selectedDepartment, selectedAllShifts, handleGetUserInformation, } = this.props;
    this._isMounted = true;
    window.addEventListener('scroll', this.handleScrolled, true);
    document.addEventListener("mousedown", this.handleClickOutside);

    const depts = this.getUserDepartments();
    this.setState({ userDepartments: depts });

    if (user && user.owner_id && locationId) {
      const results = this.getUserGroups1(user);
      this.setState({ userGroups: results });
      if ((user.user && user.user.skill_ids === null) || (user.user && user.user.skill_ids === undefined)) {
        if (user.status === 'active') {
          handleGetUserInformation(user, locationId);
          this.calledUsers.push(user.owner_id);
          this.apiCalled = true;
          //const msg = `componentDidMount: Location ${locationId}, User ${user.owner_id} api for skill called`;
          //console.log(msg);
        }
      } else {
        if (user.user) {
          const output = this.getUserSkills(user.user.skill_ids);
          if (this._isMounted) {
            this.setState({ userSkills: output });
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { shift, departments, skills, selectedDepartment, channelData, selectedAllShifts, filteredShifts, selectedType, selectedIndex,
      selectedShifts, lastAction, processedUsers, actionDoneOrCancelled, locationId, handleGetUserInformation,
      rowMenuOpened, processingUserId, channels, processingShiftId } = this.props;
    const { userInfo, userSkills, userDepartments, mouseOverPhone, mouseOverLocation, mouseOverEmployeeId, mouseOverEmail, openMenu,
      mouseOverDept, mouseOverGroup, mouseOverSkill, phonePos, locationPos, employeeIdPos, shiftSelected, userGroups, checkedChildren, isParentChecked } = this.state;
    const { userActionTypes, } = constants;

    const allChecked = checkedChildren.every((checked) => checked);
    const noneChecked = checkedChildren.every((checked) => !checked);

    if (prevProps.locationId !== locationId) {
      this.calledUsers = [];
      this.apiCalled = false;
    }

    if (this.parentCheckboxRef.current) {
      this.parentCheckboxRef.current.indeterminate =
        !allChecked && !noneChecked;
    }

    if (prevProps.channels !== channels) {
      const results = this.getUserGroups1(user);
      this.setState({ userGroups: results });
    }

    if (prevProps.selectedAllShifts !== selectedAllShifts) {
      // this.setState({ shiftSelected: selectedAllShifts });

      this.setState({
        isParentChecked: selectedAllShifts,
        checkedChildren: shift.children ? shift.children.map(() => selectedAllShifts) : [],
      });
    }

    if (prevProps.processedUsers !== processedUsers) {
      if (processedUsers && processedUsers.length > 0) {
        const found = processedUsers.filter((id) => id === user.owner_id);
        if (found && found.length > 0) {
          // this.setState({ shiftSelected: false });
          this.setState({
            isParentChecked: false,
            checkedChildren: shift.children ? shift.children.map(() => false) : [],
          });
        }
      }
    }

    if (prevProps.actionDoneOrCancelled !== actionDoneOrCancelled) {
      if (actionDoneOrCancelled) {
        // this.setState({ shiftSelected: false });
        this.setState({
          isParentChecked: false,
          checkedChildren: shift.children ? shift.children.map(() => false) : [],
        });
      }
    }

    if (prevProps.filteredShifts !== filteredShifts) {
      this.setState({ openMenu: false });
      if (filteredShifts.length === 0) {
        // this.setState({ shiftSelected: false });
        this.setState({
          isParentChecked: false,
          checkedChildren: shift.children ? shift.children.map(() => false) : [],
        });
      }
    }

    let winTop = '';
    let winLeft = '';

    if (prevState.openMenu !== openMenu) {
      if (openMenu) {
        const winW2 = '200px';//`50%`;
        const winH2 = `64px`; //94.9% viewport height
        const winRight = `40px`;
        if (this.openMenuRef && this.openMenuRef.current) {
          const domNode = ReactDOM.findDOMNode(this.openMenuRef.current);
          const output2 = domNode.getBoundingClientRect();
          if (output2) {
            //winTop = `${output2.y + output2.height}px`;
            winTop = `${output2.y - output2.height - 40}px`;
          }
        }
        const winData2 = {
          top: winTop,
          right: winRight,
          width: winW2,
          height: winH2,
        }
        this.setState({ dropdownPos: winData2 });
      }
    }

    if (prevProps.processingShiftId !== processingShiftId) {
      if (processingShiftId === shift.id) {
        // this.setState({ shiftSelected: false });
        //console.log(`Unchecked: ${user.id}`);
        this.setState({
          isParentChecked: false,
          checkedChildren: shift.children ? shift.children.map(() => false) : [],
        });
      }
    }
  }

  componentWillUnmount() {

    this._isMounted = false;
    window.removeEventListener('scroll', this.handleScrolled);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleScrolled = (event) => {

  }

  handleClickOutside = (event) => {
    const { openMenu, } = this.state;
    const { notifyMenuOpened, } = this.props;

    if (this.openMenuRef && this.openMenuRef.current && !this.openMenuRef.current.contains(event.target)) {
      if (this.menuWinRef && this.menuWinRef.current && !this.menuWinRef.current.contains(event.target)) {
        if (openMenu) {
          this.setState({ openMenu: false });
          notifyMenuOpened(false);
        }
      }
    }
  }

  getUserDepartments = () => {
    const { user, departments, } = this.props;
    let userDepartments = [];
    if (user && user.department_ids && user.department_ids.length > 0) {
      if (departments && departments.length > 0) {
        for (let i = 0; i < user.department_ids.length; i++) {
          const foundDept = departments.filter((dept) => dept.id === user.department_ids[i]);
          if (foundDept && foundDept.length > 0) {
            userDepartments.push(foundDept[0]);
          }
        }
      }
    } else {
      if (user && user.owner_id && departments && departments.length > 0) {
        for (let j = 0; j < departments.length; j++) {
          if (departments[j].user_ids && departments[j].user_ids.length > 0) {
            const foundUser = departments[j].user_ids.filter((id) => id === user.owner_id);
            if (foundUser && foundUser.length > 0) {
              userDepartments.push(departments[j]);
            }
          }
        }
      }
    }
    return userDepartments;
  };

  getUserSkills = (skill_ids) => {
    const { skills, } = this.props;
    let userSkills = [];
    if (skill_ids && skill_ids.length > 0 && skills && skills.length > 0) {
      for (let i = 0; i < skill_ids.length; i++) {
        const foundSkill = skills.filter((skill) => skill.id === skill_ids[i]);
        if (foundSkill && foundSkill.length > 0) {
          userSkills.push(foundSkill[0]);
        }
      }
    }
    return userSkills;
  }

  getUserGroups = (userId) => {
    const { channelData, } = this.props;
    let userGroups = [];
    for (let i = 0; i < channelData.length; i++) {
      for (let j = 0; j < channelData[i].members.length; j++) {
        if (channelData[i].members[j].id === userId) {
          userGroups.push(channelData[i]);
        }
      }
    }
    return userGroups;
  }

  getUserGroups1 = (user) => {
    const { channels, } = this.props;
    let userGroups = [];
    if (!channels || channels.length === 0) {
      return userGroups;
    }
    if (user.user && user.user.channel_ids) {
      for (let i = 0; i < user.user.channel_ids.length; i++) {
        const foundChannels = channels.filter((channel) => channel.id === user.user.channel_ids[i]);
        if (foundChannels && foundChannels.length > 0) {
          userGroups.push(foundChannels[0]);
        }
      }
    }
    return userGroups;
  }

  mouseEnterLocation = (event) => {
    const { shift } = this.props;
    // console.log('Mouse Enter Location outside');
    let winTop = '';
    let winLeft = '';
    const winW = '200px';
    const winH = `60px`;
    if (this.locationRef && this.locationRef.current) {
      const domNode = ReactDOM.findDOMNode(this.locationRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y - output.height - 50}px`;
        winLeft = `${output.x - (200 - output.width) / 2}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ locationPos: winData });
    // console.log(winData);
    if (shift && shift.location && shift.location.formatted_address && shift.location.formatted_address.length > 0) {
      // console.log('Mouse Enter Location inside');
      this.setState({ mouseOverLocation: true });
    }
  }

  mouseLeaveLocation = (event) => {
    const { shift } = this.props;
    // console.log('Mouse Leave Location outside');
    if (shift && shift.location && shift.location.formatted_address && shift.location.formatted_address.length > 0) {
      // console.log('Mouse Leave Location inside');
      this.setState({ mouseOverLocation: false });
    }
  }

  handlePhoneClicked = (event) => {
    const { user, } = this.props;
    if (user && user.phone_number && user.phone_number.length > 0) {
      const url = `tel:${user.phone_number}`;
      window.open(url);
    }
  }

  findSkills = (shift, skills) => {
    let skillNames = '';
    let temp = '';
    let matchedSkills = [];

    if (shift && shift.skill_ids && shift.skill_ids.length > 0 && skills && skills.length > 0) {
      for (let i = 0; i < shift.skill_ids.length; i++) {
        for (let j = 0; j < skills.length; j++) {
          if (shift.skill_ids[i] === skills[j].id) {
            matchedSkills.push(skills[j]);
          }
        }
      }
      const copyArray = [...matchedSkills];
      this.sortSkillContentWithNullValues(copyArray, true);
      for (let k = 0; k < copyArray.length; k++) {
        temp += `${copyArray[k].skill_content}, `;
      }
      skillNames = temp.substring(0, temp.length - 2); // remove last two chars
    }
    if (skillNames.length === 0) {
      skillNames = "No skills assigned";
    }
    return skillNames;
  };

  sortSkillContentWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof (ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function (a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }
    }
    return arr.sort(sorter);
  }

  mouseEnterGroupDepartment = (event) => {
    const { shift } = this.props;
    let winTop = '';
    let winLeft = '';
    const winW = '160px';//`50%`;
    const winH = `30px`; //94.9% viewport height
    if (this.groupDepartmentRef && this.groupDepartmentRef.current) {
      const domNode = ReactDOM.findDOMNode(this.groupDepartmentRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y - output.height - 15}px`;
        winLeft = `${output.x - 100}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ groupDepartmentPos: winData });
    // console.log(winData);
    if (shift && shift.channel_name) {
      this.setState({ mouseOverGroupDepartment: true });
    }
  }

  mouseLeaveGroupDepartment = (event) => {
    const { shift } = this.props;
    if (shift && shift.channel_name) {
      this.setState({ mouseOverGroupDepartment: false });
    }
  }

  mouseEnterShiftSkills = (event) => {
    const { shift, skills } = this.props;
    let winTop = '';
    let winLeft = '';
    const winW = '160px';//`50%`;
    const winH = `30px`; //94.9% viewport height
    if (this.shiftSkillsRef && this.shiftSkillsRef.current) {
      const domNode = ReactDOM.findDOMNode(this.shiftSkillsRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y - output.height - 15}px`;
        winLeft = `${output.x - 100}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ shiftSkillsPos: winData });
    this.setState({ mouseOverShiftSkills: true });
  }

  mouseLeaveShiftSkills = (event) => {
    const { shift, skills } = this.props;
    this.setState({ mouseOverShiftSkills: false });
  }

  handleOpenMenuClick = (event) => {
    // const { user, notifyMenuOpened, rowMenuOpened, } = this.props;
    // const { openMenu, } = this.state;
    // //If one row menu opened, other rows cannot open
    // if (rowMenuOpened) {
    //   if (openMenu) {
    //     this.setState({ openMenu: !openMenu });
    //     notifyMenuOpened(!openMenu);
    //   }
    //   return;
    // }
    // if (user && user.status === 'active' || user.status === 'unregistered') {
    //   this.setState({ openMenu: !openMenu });
    //   notifyMenuOpened(!openMenu);
    // }
  }

  handleActionChange = (action) => {
    const { notifyActionRequested, user, } = this.props;
    const { userActionTypes } = constants;
    if (user && user.status === 'active' || user.status === 'unregistered') {
      this.setState({ openMenu: false, showConfirm: true, selectedAction: action });
    }

  }

  notifyConfirm = (request) => {
    const { selectedAction, } = this.state;
    const { notifyActionRequested, user, notifyShiftSelected, notifyMenuOpened, } = this.props;
    const { userActionTypes } = constants;

    this.setState({ showConfirm: false });
    notifyMenuOpened(false);
    if (selectedAction.name === userActionTypes.REMOVE) {
      if (request) {
        this.setState({ showConfirmStep2: true });
      } else {
        notifyActionRequested(request, selectedAction.name, user);
      }
    } else {
      notifyActionRequested(request, selectedAction.name, user);
    }

  }

  notifyConfirmStep2 = (confirmed) => {
    const { selectedAction, } = this.state;
    const { notifyActionRequested, user, } = this.props;
    this.setState({ showConfirmStep2: false });
    if (confirmed) {
      notifyActionRequested(true, selectedAction.name, user);
    } else {
      notifyActionRequested(false, selectedAction.name, user);
    }
  }

  renderLocationMouseTip = (param) => {
    const { user, shift } = this.props;
    const { locationPos, groupDepartmentPos } = this.state;
    let displayText = param;

    if (locationPos) {
      const phoneContainerStyle = {
        top: locationPos.top,
        left: locationPos.left,
        width: locationPos.width,
        height: locationPos.height,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        zIndex: 3,
        //overflow: 'scroll',
        borderRadius: '5px',
        paddingTop: '5px',
        //border: '1px solid red',
      };
      return (

        <div style={phoneContainerStyle}>
          <div className="mouse-tip__top-wrap">{displayText}</div>
        </div>
      );
    }
  }

  renderGroupDepartmentMouseTip = (param) => {
    const { user, shift } = this.props;
    const { locationPos, groupDepartmentPos } = this.state;
    let displayText = param;

    if (groupDepartmentPos) {
      const groupDepartmentContainerStyle = {
        top: groupDepartmentPos.top,
        left: groupDepartmentPos.left,
        width: groupDepartmentPos.width,
        height: groupDepartmentPos.height,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        zIndex: 3,
        //overflow: 'scroll',
        borderRadius: '5px',
        paddingTop: '5px',
        //border: '1px solid red',
      };
      return (

        <div style={groupDepartmentContainerStyle}>
          <div className="mouse-tip__top-wrap">{displayText}</div>
        </div>
      );
    }
  }

  renderShiftSkillsMouseTip = (param) => {
    const { skills, shift } = this.props;
    const { shiftSkillsPos } = this.state;

    let displayText = this.findSkills(shift, skills);

    if (shiftSkillsPos) {
      const shiftSkillsContainerStyle = {
        top: shiftSkillsPos.top,
        left: shiftSkillsPos.left,
        width: shiftSkillsPos.width,
        height: shiftSkillsPos.height,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        zIndex: 3,
        //overflow: 'scroll',
        borderRadius: '5px',
        paddingTop: '5px',
        //border: '1px solid red',
      };
      return (
        <div style={shiftSkillsContainerStyle}>
          <div className="mouse-tip__top-wrap">{displayText}</div>
        </div>
      );
    }
  }

  renderUserActionMenu = () => {
    const { user, } = this.props;
    const { dropdownPos, } = this.state;
    const { userActionTypes } = constants;

    const promoteText = (<FormattedMessage
      id="UserRow.promote"
      defaultMessage="Grant Admin Privileges"
    />);

    const withdrawText = (<FormattedMessage
      id="UserRow.withdraw"
      defaultMessage="Withdraw Admin Privileges"
    />);

    const removeText = (<FormattedMessage
      id="UserRow.remove"
      defaultMessage="Remove User from Location"
    />);

    const promote = { name: userActionTypes.PROMOTE, body: promoteText };
    const withdraw = { name: userActionTypes.WITHDRAW, body: withdrawText };
    const remove = { name: userActionTypes.REMOVE, body: removeText };
    const deleteUnregistered = { name: userActionTypes.DELETE_UNREGISTERED, body: removeText };
    let items = [];
    if (user && user.is_admin) {
      items = [withdraw, remove];
    } else if (user && user.status === 'unregistered') {
      items = [deleteUnregistered];
    } else {
      items = [promote, remove];
    }

    const actionItems = items.map((item, index) => (
      <div
        key={index}
        className="employee-page-header__dropdown-item-wrap"
        value={item}
        onClick={(event) => { this.handleActionChange(item) }}
      >
        <label className="employee-page-header__action-dropdown-text">
          {item.body}
        </label>
      </div>
    ));

    if (dropdownPos) {
      return (
        <styled.WinContainerRight ref={this.menuWinRef} top={this.pixelOffsetHack(dropdownPos.top, 30)} right={dropdownPos.right} width={dropdownPos.width} height={dropdownPos.height}>
          {actionItems}
        </styled.WinContainerRight>
      );
    }
  }

  pixelOffsetHack(pxString, val) {
    let num = Number(pxString.replace("px", '')) + val;
    return num + 'px';
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  handleSelectShift = (event) => {
    const isChecked = event.target.checked;
    const { shift, notifyShiftSelected } = this.props;
    const isShift = shift.time_off_reason === undefined;
    // Update parent checkbox state
    this.setState({ isParentChecked: isChecked });

    // Update children based on parent's checked state
    const newCheckedChildren = this.state.checkedChildren.map(() => isChecked);
    this.setState({ checkedChildren: newCheckedChildren });

    if (!isShift) {
      //rto
      notifyShiftSelected(shift, isChecked);
    } else if ((shift.name === 'shift' && shift.cover_mode === 'multi_applicants_picked_by_manager')) {
      //ToDo handle 2.0 team shift
      //notifyShiftSelected(shift, isChecked);
    } else if ((shift.cover_mode === 'multi_applicants_picked_by_manager')) {
      notifyShiftSelected(shift.children, isChecked);
    } else if ((shift.cover_mode !== 'multi_applicants_picked_by_manager')) {
      notifyShiftSelected(shift, isChecked);
    }
  };

  handleChildCheckbox = (index) => (event) => {
    const isChecked = event.target.checked;
    const { shift, notifyShiftSelected } = this.props;
    // Update child checkbox state
    const newCheckedChildren = [...this.state.checkedChildren];
    newCheckedChildren[index] = isChecked;

    // Notify about child selection
    notifyShiftSelected(shift.children[index], isChecked);

    this.setState({ checkedChildren: newCheckedChildren }, () => {
      // Update parent checkbox's checked state based on children's state
      const allChecked = newCheckedChildren.every(checked => checked);
      const anyChecked = newCheckedChildren.some(checked => checked);

      this.setState({ isParentChecked: allChecked });

      // Update the parent checkbox's indeterminate state
      if (this.parentCheckboxRef.current) {
        this.parentCheckboxRef.current.indeterminate = !allChecked && anyChecked;
      }
    });
  };


  render() {
    const { shift, locationId, isRequests, notifyInlineActionRequested } = this.props;
    const { isExpanded, isParentChecked, checkedChildren, mouseOverPhone, } = this.state;

    const locationButtonClassName = this.getButtonClassName(shift, 'location');
    const groupDepartmentButtonClassName = this.getButtonClassName(shift, 'group-department');
    const skillsButtonClassName = this.getButtonClassName(shift, 'skills');
    const toggleButtonClassName = isExpanded ? 'user-row__arrow-button-collapse' : 'user-row__arrow-button-expand';

    return (
      <>
        {this.renderRow(shift, locationId, isParentChecked, toggleButtonClassName, 0, true, isRequests)}

        {isExpanded && shift.children && shift.children.length > 0 && shift.children.map((child, index) => (
          this.renderRow(child, locationId, checkedChildren[index], '', index, false, isRequests)
        ))}
      </>
    );
  }

  renderRow(shift, locationId, isChecked, toggleButtonClassName, index, isParent, isRequests) {
    const { mouseOverLocation, mouseOverGroupDepartment, mouseOverShiftSkills } = this.state;
    const { notifyInlineActionRequested } = this.props;
    const isShift = shift.time_off_reason === undefined;
    const startTime = moment(shift.start_at).format('hh:mm a');
    const endTime = moment(shift.end_at).format('hh:mm a');
    const backgroundColor = isParent ? '#ffffff' : '#f7f7fa';
    //current shift location time range
    // const timeRange = `${startTime} - ${endTime}`;

    //current geo location time range
    const timeRange = isShift ? localTimeRangeFromTimeStamps(shift.start_at, shift.end_at) : localTimeRangeFromTimeStamps(shift.start_time, shift.end_time);

    const formatDate = (date) => {
      const currentYear = moment().year();
      const yearOfDate = moment(date).year();
      return yearOfDate === currentYear
        ? moment(date).local().format('ddd, MMM DD')
        : moment(date).local().format('MMM DD YYYY');
    };

    const dateRange = isShift
      ? formatDate(shift.start_at)
      : (
        <>
          {formatDate(shift.start_date)} -
          <br />
          {formatDate(shift.end_date)}
        </>
      );

    const shouldRenderExpandButton = (shift) => {
      return (shift.parent_card && (
        shift.current_applicants_approved_user_ids.length +
        shift.current_applicants_denied_user_ids.length +
        shift.current_applicants_pending_user_ids.length
      ) > 0) || (
          shift.name === 'shift' &&
          shift.children &&
          shift.cover_mode === 'multi_applicants_picked_by_manager'
        );
    };

    return (
      <tr className="user-row__row" key={isParent ? shift.id : (shift.coverer && shift.coverer.applicantId ? shift.coverer.applicantId : shift.id)}>
        <td style={{ backgroundColor }} className="user-row__checkbox">
          {index === 0 && isShift && shouldRenderExpandButton(shift) && isRequests && (
            <button className={toggleButtonClassName} onClick={this.toggleExpand} />
          )}
        </td>

        {isRequests && (<td style={{ backgroundColor }} className="user-row__checkbox">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={isParent ? this.handleSelectShift : this.handleChildCheckbox(index)}
          />
        </td>)}

        <td style={{ backgroundColor }} className="user-row__owner-cell">
          <RequestCell shift={shift} isParent={isParent} />
        </td>
        <td style={{ backgroundColor }} className="user-row__owner-cell">
          <UserCell shift={shift} user={shift.coverer} locationId={locationId} />
        </td>
        <td style={{ backgroundColor }} className="user-row__status-cell">
          <StatusCell shift={shift} isRequests={isRequests} isParent={isParent} />
        </td>
        <td style={{ backgroundColor }} className="user-row__user-datetime">
          {dateRange}
        </td>
        <td style={{ backgroundColor }} className="user-row__user-datetime">
          {timeRange}
        </td>
        <td style={{ backgroundColor }} className="user-row__td-centered">
          <div className="user-row__button-wrap">
            {isShift ? this.renderActionButtons(shift, locationId) : '--'}
          </div>
        </td>

        {isRequests && (<td style={{ backgroundColor }} className="user-row__user-datetime">
          {(isParent && isShift) ? "" :
            <ActionCell notifyInlineActionRequested={notifyInlineActionRequested} shift={shift} isRequests={isRequests} isParent={isParent} />
          }
        </td>)}
        {/* <td style={{ backgroundColor }} >
          <button className="user-row__button-more" ref={this.openMenuRef} onClick={this.handleOpenMenuClick} />
        </td> */}
        {
          mouseOverLocation && <Modal>
            {this.renderLocationMouseTip(shift.location ? (shift.location.formatted_address) : '')}
          </Modal>
        }
        {
          mouseOverGroupDepartment && <Modal>
            {this.renderGroupDepartmentMouseTip(shift.channel_name ? (shift.channel_name) : '')}
          </Modal>
        }
        {
          mouseOverShiftSkills && <Modal>
            {this.renderShiftSkillsMouseTip()}
          </Modal>
        }
      </tr >
    );
  }

  renderActionButtons(shift, locationId) {
    const locationButtonClassName = this.getButtonClassName(shift, 'location');
    const groupDepartmentButtonClassName = this.getButtonClassName(shift, 'group-department');
    const skillsButtonClassName = this.getButtonClassName(shift, 'skills');

    return (
      <>
        <button
          ref={this.locationRef}
          className={locationButtonClassName}
          onMouseEnter={this.mouseEnterLocation}
          onMouseLeave={this.mouseLeaveLocation}
        />
        <button
          ref={this.groupDepartmentRef}
          className={groupDepartmentButtonClassName}
          onMouseEnter={this.mouseEnterGroupDepartment}
          onMouseLeave={this.mouseLeaveGroupDepartment}
        />
        <button
          ref={this.shiftSkillsRef}
          className={skillsButtonClassName}
          onMouseEnter={this.mouseEnterShiftSkills}
          onMouseLeave={this.mouseLeaveShiftSkills}
        />
      </>
    );
  }

  getButtonClassName(shift, type) {
    const isEnabled = !!shift;
    return `user-row__contact-button-${type}${isEnabled ? '' : '-disabled'}`;
  }
}

UserRow.propTypes = {
  notifyShiftSelected: PropTypes.func.isRequired,
};

export default injectIntl(UserRow);

