import { getUserName } from 'helpers/cookies';
import { isValidObject } from 'helpers/validation';
import React, { useState, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import exportUtil from './export';
import service from './service';
import constants from '../constants';
import styled from '../components/TableComponents/Cell/styled';

const confirmText = () => {return <FormattedMessage id="Confirm.addShift" defaultMessage="Add Shift" />; }
const addShiftText = (date) => {return <FormattedMessage id="AddShiftComponent.addAShift" defaultMessage="Add a Shift on {value}" values={{ value: date?.format('ddd, MMM DD') }} />; }
const viewShiftText = (date) => {return <FormattedMessage id="AddShiftComponent.viewShift" defaultMessage="Shift on {value}" values={{ value: date?.format('ddd, MMM DD') }} />; }
const jobTitleText = () => {return <FormattedMessage id="ShiftRow.jobTitle" defaultMessage="Position" />;}
const assignToText = () => {return <FormattedMessage id="ShiftRow.assignTo" defaultMessage="Assign To" />;}
const timeText = () => {return <FormattedMessage id="TimePicker.startTimeLabel" defaultMessage="Time" />; }
const applyToText = () => {return <FormattedMessage id="AddShiftComponent.applyTo" defaultMessage="Apply To:" />; } 
const overnightShiftText = () => {return <FormattedMessage id="ViewShifts.overnightExplain" defaultMessage="This is an overnight shift." />;}
const longTitleWarningText = () => { return <FormattedMessage id="AddShiftComponent.jobTitleTooLong" defaultMessage="Position is too long." />;} 

const deleteText = () => { return <FormattedMessage id="ShiftDetail.delete" defaultMessage='Delete Shift' />; }
const copyText = () =>  { return <FormattedMessage id="ShiftDetail.copy" defaultMessage='Copy Shift' />; }
const saveText = () =>  { return <FormattedMessage id="ShiftDetail.save" defaultMessage='Save' />; }
const cancelText = () => { return <FormattedMessage id="ShiftDetail.cancel" defaultMessage='Cancel' />; }
const addShiftString = () => { return <FormattedMessage id="ShiftDetail.addShift" defaultMessage='Add Shift' />; }
const groupPlaceholderText = () => { return <FormattedMessage id="ShiftDetail.groupPlaceholder" defaultMessage='Select the group that will see this shift' />; }
const createShiftOnText = (date) => { return <FormattedMessage id="ShiftDetail.createShiftOn" defaultMessage='Create Shift on {shiftDate}' values={{shiftDate: date}} />; }

const hasAppliedForOpenShift = () => { return <FormattedMessage id="ShiftDetail.appliedForOS" defaultMessage='has applied to cover an open shift' />; } 
const isApprovedForOpenShift = () => { return <FormattedMessage id="ShiftDetail.approvedForOS" defaultMessage='has been approved to cover this open shift' />; } 

const pendingX = (count) => { return <FormattedMessage id="ShiftDetail.pendingX" defaultMessage='Pending ({value})' values={{value: count}}/>; } 
const approvedX = (count) => { return <FormattedMessage id="ShiftDetail.approvedX" defaultMessage='Approved ({value})' values={{value: count}}/>; } 
const deniedX = (count) => { return <FormattedMessage id="ShiftDetail.deniedX" defaultMessage='Denied ({value})' values={{value: count}}/>; } 

const messageX = (name) => { return 'Message '+ name }


const textHours = (hours) => {return <FormattedMessage id="DayCell.hours" defaultMessage="{value} h" values={{ value: hours }} />};
const pasteShift = () => {return <FormattedMessage id="AddShiftComponent.pasteShift" defaultMessage="Paste Shift" />};

const cardClassNameForShift = (shift) => {
    if( shift.trade_status === 'inventory' ) {
        return 'card_inventory';
    }else if ( shift.trade_status === 'called_off') {
        return 'card_called_off';
    // Team Shift
    }else if ( shift.name === 'shift' ) {
      if( shift.trade_status === 'covered' || shift.trade_status === 'approved' ) {
        return 'card_approved';
      }else if ( shift.trade_status === 'posted'){
        return 'card_posted';
      }else if ( shift.trade_status === 'pending'){
        return 'card_pending';
      }else if ( shift.trade_status === 'rejected'){
        return 'card_inventory';
      }
    // Open Shift
    }else if ( shift.name == 'open_shift' ) {
      if( shift.trade_status == 'approved' || shift.trade_status == 'covered' )  {
        return 'card_approved';
      }else {
        return 'card_posted';
      }
    }else {
        return 'card_unpublished';
    }
}

const shouldShowStatusTag = (className) => {
    if ( className == 'card_called_off' ){
        return true;
    }else if ( className == 'card_approved' ){
        return true;
    }else if ( className == 'card_posted' ){
        return true;
    }else if ( className == 'card_pending' ){
        return true;
    }else {
        return false;
    }
}

const shouldShowSectionTab = (shiftObject) => {
    if ( isValidObject(shiftObject) == true && shiftObject?.name == 'open_shift' && shiftObject?.trade_status != 'unpublished_posted' ){
        return true;
    }else{
        return false;
    } 
}

const tagTextForShift = (shiftObject) => {
    var tagText = '';

    if ( shiftObject.name === 'shift' ) {
        if( shiftObject?.trade_status == 'covered') {
        tagText = ( <FormattedMessage id="AddShiftComponent.covered" defaultMessage="COVERED" />); 
        }else if ( shiftObject?.trade_status == 'approved' ) {
        tagText = ( <FormattedMessage id="AddShiftComponent.approved" defaultMessage="APPROVED" />); 
        }else if ( shiftObject?.trade_status == 'pending' ){
        tagText = ( <FormattedMessage id="AddShiftComponent.pending" defaultMessage="PENDING" />); 
        }else if ( shiftObject?.trade_status == 'posted' ){
        tagText = ( <FormattedMessage id="AddShiftComponent.posted" defaultMessage="POSTED" />); 
        }
    }else if (shiftObject?.name === 'shift_inventory' ){
        if (shiftObject?.trade_status === 'called_off'){
        tagText = ( <FormattedMessage id="AddShiftComponent.calledoff" defaultMessage="CALLED OFF" />); 
        }
    }else {
        const subtype = service.getShiftSubType(shiftObject)
        if( subtype == constants.ShiftSubType.ChildOpenShift ){
            if( shiftObject?.trade_status == 'approved' ){
                tagText = ( <FormattedMessage id="AddShiftComponent.approved" defaultMessage="APPROVED" />);  
            }
        }
    }
    return tagText;
}

const tagTextForOpenShift = (shiftObject) => {
    if ( shiftObject == null) {return '';}
    if( shiftObject.name == 'open_shift' ) {
        if ( shiftObject.trade_status !== 'unpublished_posted' && shiftObject.parent_card == true ){
            return "POSTED";
        }else if ( shiftObject.trade_status == 'approved' && shiftObject.child_card == true){
            return "APPROVED"; 
        }else{
            return '';
        }
    }else{
        return '';
    }
}

const shouldShowPosterAvatar = (shiftObject) => {
    if ( shiftObject?.name == 'shift' && ( shiftObject?.trade_status == 'posted' || shiftObject?.trade_status == 'pending' || shiftObject?.trade_status == 'approved' || shiftObject?.trade_status == 'covered' )){
        return true;
    }else{
        return false;
    }
}

const shouldShowTakerAvatar = (shiftObject) => {
    if ( shiftObject?.name == 'shift' && ( shiftObject?.trade_status == 'pending' || shiftObject?.trade_status == 'approved' || shiftObject?.trade_status == 'covered' ) ) {
        return true;
    }else{
        return false;
    }
}

const userAvatarPlaceholder = () => { return '/icons/default-profile-thumb.svg'; }

const openShiftSelectionTarget = () => { return {assignToOpenShift: true, profile_image_thumb_url: '/icons/open-plus.svg', first_name: 'Open', last_name: 'Shift', id: 0}; }



const posterAvatar = (shiftObject, allUsers) => {
    const poster = getPoster(shiftObject, allUsers);
    return getUserAvatar(poster);
}

const takerAvatar = (shiftObject, allUsers) => {
    const taker = getTaker(shiftObject, allUsers);
    return getUserAvatar(taker);
}

const getUserAvatar = (user) => {
    var url = user?.profile_image_thumb_url;
        if( isValidObject(url) == false ){
            url = userAvatarPlaceholder()
        }
    return url;
}

const getName = (user) => {
    if( typeof user != 'undefined' && user !== null ){
        const userName = (user.hasOwnProperty('fullName')) ? user.fullName : (`${user.first_name} ${user.last_name}`); 
        return userName;
    }else{
        return '';
    }
}

const getPosterName = (shiftObject, allUsers) => {
    const user = getPoster(shiftObject, allUsers);
    return getName(user);
}

const getTakerName = (shiftObject, allUsers) => {
    const taker = getTaker(shiftObject, allUsers);
    return getName(taker);
}

const getPoster = (shiftObject, allUsers) => {
    if ( isValidObject(shiftObject) == true ){
        return getUserFromId(shiftObject.owner_id, allUsers);   
    }else{
        return null
    }
}

const getTaker = (shiftObject, allUsers) => {
    return getUserFromId(shiftObject.coverer_id, allUsers);
}

const getUserFromId = (userId, allUsers) => {
    const user = allUsers.filter((user) => user.owner_id == userId);
    return user[0];
}

const xCoveringForYText = (user, currentShift, allUsers) => {
    const userName = getTakerName(currentShift, allUsers);
    const posterName = getPosterName(currentShift, allUsers);
    var result='';

    if( (currentShift.trade_status === 'covered' || currentShift.trade_status === 'approved') ) {
        result = (<FormattedMessage id="PopupDialogViewShifts.xCoveringShiftForY" defaultMessage="{userName} is covering this shift for {posterUserName}." values={{ userName: userName, posterUserName: posterName}} />);
    }else if ( currentShift.trade_status === 'pending' ){
        result = (<FormattedMessage id="PopupDialogViewShifts.xAppiedToCoverXShift" defaultMessage="{userName} has applied to cover {posterUserName}'s shift." values={{ userName: userName, posterUserName: posterName}} />);
    }
    return result;    
}

const getGroupName = (shiftObject, channels) => {
    // const channelId = shiftObject.channel_id;
    // const channel = channels.filter((channel) => channel.id == channelId);
    // if( channel?.length > 0){
    //     return channel[0].channel_name;
    // }else{
    //     return '';
    // }
    return getGroup(shiftObject, channels)?.channel_name || '';
}

const getGroupId = (shiftObject, channels) => {
    return getGroup(shiftObject, channels)?.id || '';
}

const getGroup = (shiftObject, channels) => {
    const channelId = shiftObject.channel_id;
    const channel = channels.filter((channel) => channel.id == channelId);
    if( channel?.length > 0){
        return channel[0];
    }else{
        return null;
    } 
}

const applicantAvatar = (applicant) => {
    if ( isValidObject(applicant) == false) { return '' }
    const user = applicant.user;
    const placeholder = userAvatarPlaceholder();
    var avatarUrl = (user != null ? user.thumb_url : placeholder );
    return avatarUrl;
}

const getApplicantName = (applicant) => {
    const user = applicant?.user;
    return user?.first_name + ' ' + user?.last_name ;
}

const getNameFromApplicantOrUserId = (applicant, userId, allUsers) => {
    if ( isValidObject(applicant) == true ){
        return getApplicantName(applicant)
    }else{
        const user = getUserFromId(userId, allUsers)
        return getName(user)
    }
}

const getApplicantPosition = (applicant) => {
    const user = applicant?.user;
    return user?.current_position || '';
}

const getApplicantNameAndPosition = (applicant) => {
    const name = getApplicantName(applicant);
    const pos = getApplicantPosition(applicant);
    if( isValidObject(pos) == true) {
        return name + ' • ' + pos;
    }else{
        return name;
    }
}

const getOpenShiftApplicantTagline = (applicant) => {
    if ( applicant?.status == 'applied' || applicant?.status == 'nominated' ){ 
        return hasAppliedForOpenShift();
    }else if (applicant?.status == 'picked' ){
        return isApprovedForOpenShift();
    }else{
        return getApplicantPosition(applicant);
    }
}

const visibilityClassName = (shouldShow) => {
    if( shouldShow == true ) {
        return 'shown';
    }else{ 
        return 'hidden';
    }
}

const userShiftIsAssignedTo = (shift, allUsers, unregisteredUsers) => {
    if( shift.trade_status == 'unregistered_user_shift_inventory' || shift.trade_status == 'unregistered_unpublished_inventory' ){
        return exportUtil.getUnregisteredUserInfo(shift, unregisteredUsers) 
    }else{
        return getUserFromId(shift.taker_id, allUsers)
    }
}

const applicantNumberText = (count) => {
    return <FormattedMessage id="ShiftDetail.applicantX" defaultMessage='Applicants ({value})' values={{value: count}}/>;  
}

const pasteShiftCell = (onPasteClick, onEmptyCellMouseEnter, onEmptyCellMouseLeave, isMouseOverCell) => {
    return (<div className="day-cell-top-wrap"
                onMouseUpCapture={onPasteClick}
                onMouseEnter={onEmptyCellMouseEnter}
                onMouseLeave={onEmptyCellMouseLeave} >
 
                <div className="day-cell" display='block'>
                <styled.PasteShiftWrap display={isMouseOverCell ? 'block' : 'none'}>{pasteShift()}</styled.PasteShiftWrap>
                </div>
            </div>);
}

const addNewShiftCell = (onCellClick, onEmptyCellMouseEnter, onEmptyCellMouseLeave, isMouseOverCell) => {
    return (
        <div className="add-shift-wrap"
          onMouseUpCapture={onCellClick}
          onMouseEnter={onEmptyCellMouseEnter}
          onMouseLeave={onEmptyCellMouseLeave} >

          <div className="day-cell" display='flex'>
            <styled.NotScheduledWrap display={isMouseOverCell ? 'block' : 'none'}> <img src='/icons/round_plus_button.svg'/> </styled.NotScheduledWrap>
          </div>
        </div>
      );
}

const notScheduledCell = (shifts, isCopyingShift, shiftToCopy, onPasteClick, onCellClick, onEmptyCellMouseEnter, onEmptyCellMouseLeave, isMouseOverCell) => {

  if ( isValidObject(shifts) == false || shifts.length === 0) {
    if (isCopyingShift && shiftToCopy !== null) {

      return (
        <div className="day-cell-top-wrap"
          onMouseUpCapture={onPasteClick}
          onMouseEnter={onEmptyCellMouseEnter}
          onMouseLeave={onEmptyCellMouseLeave} >
       
          <div className="day-cell" display='block'>
            <styled.PasteShiftWrap display={isMouseOverCell ? 'block' : 'none'}>{presenter.pasteShift()}</styled.PasteShiftWrap>
          </div>
        </div>
      );
    } else {
      return (
        <div className="add-shift-wrap"
          onMouseUpCapture={onCellClick}
          onMouseEnter={onEmptyCellMouseEnter}
          onMouseLeave={onEmptyCellMouseLeave} >

          <div className="day-cell" display='flex'>
            <styled.NotScheduledWrap display={isMouseOverCell ? 'block' : 'none'}> <img src='/icons/round_plus_button.svg'/> </styled.NotScheduledWrap>
          </div>
        </div>
      );
    }
  }
}

export default {
    deleteText,
    copyText,
    saveText,
    cancelText,
    addShiftString,
    groupPlaceholderText,
    userAvatarPlaceholder,
    createShiftOnText,
    applicantAvatar,
    getApplicantName,
    getApplicantPosition,
    getApplicantNameAndPosition,
   cardClassNameForShift,
   shouldShowStatusTag,
   tagTextForShift,
   shouldShowPosterAvatar,
   shouldShowTakerAvatar,
   confirmText,
   addShiftText, 
   viewShiftText,
   jobTitleText, 
   assignToText,
   timeText,
   applyToText,
   overnightShiftText,
   longTitleWarningText,
   posterAvatar,
   takerAvatar,
   xCoveringForYText,
   tagTextForOpenShift,
   getGroupName,
   shouldShowSectionTab,
   hasAppliedForOpenShift,
   isApprovedForOpenShift, 
   getOpenShiftApplicantTagline,
   pendingX,
   approvedX,
   deniedX,
   messageX,
   getUserFromId,
   getUserAvatar,
   openShiftSelectionTarget,
   visibilityClassName,
   getPoster,
   getName,
   getUserAvatar,
   pasteShift,
   textHours,
   getTaker,
   getNameFromApplicantOrUserId,
   userShiftIsAssignedTo,
   applicantNumberText,
   getGroupId,
   getGroup,
   notScheduledCell,
   pasteShiftCell,
   addNewShiftCell,
};