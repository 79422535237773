import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import elements from '../../elements';
import blocks from '../../blocks';
import styled from './styled';
import SimplePost from '../Announcements/SimplePost';


class PinsComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={closePinPane: false,

    }
    this.handleClose = this.handleClose.bind(this);

    this.closePinPane = false;
  }

  handleClose(event) {
    const {resetActiveSidePane} = this.props;

    this.setState({closePinPane: false});
    this.closePinPane = true;
    resetActiveSidePane();
    event.preventDefault();
  }

  render () {
    const {pins, activeSidePane, locationSettings, active, activeChannel, allUsers, onlineUsers, userId, } = this.props;

    if(this.closePinPane){
      this.closePinPane = false;
      return null;
    }

    const title = (<FormattedMessage id="PinsComponent.headerLabel" defaultMessage="Pinned Posts" />);

    const pinnedPosts = pins && pins.data.map((post, index) => {

      return (
        <SimplePost
          key={index}
          data={{
            ...post,
            final_allow_comment: locationSettings.cfg__group_chat__comments,
            final_allow_like: locationSettings.cfg__group_chat__likes,
            final_allow_delete: active.userIsAdmin || post.owner_id === userId, // Fixed bug WS-1279, WS-1262
          }}
          active={active.post.id === post.id || active.delete.id === post.id}
          allUsers={allUsers}
          onlineUsers={onlineUsers}
          index={index}
          activeChannel={activeChannel}
        >
        </SimplePost>
      );
    });

    return (
      <elements.ColumnWrap>
        <div className="group__wrap">
          <p className="group__title">{title}</p>
          <button className="group__close-button" onClick={this.handleClose}/>
        </div>
        <elements.ScrollWrap isLoading={pins.loading}>
          {pinnedPosts}
        {!pins.loading || <elements.Loading />}
        </elements.ScrollWrap>
      </elements.ColumnWrap>
    );

  }
}

PinsComponent.propTypes = {
  pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(PinsComponent);

