export const sizeMap = [
    {
      isoCode: 'ac',
      width: 20,
      height: 10,
      xPos: 0,
    },
    {
      isoCode: 'ad',
      width: 20,
      height: 14,
      xPos: -22,
    },
    {
      isoCode: 'ae',
      width: 20,
      height: 10,
      xPos: -44,
    },
    {
      isoCode: 'af',
      width: 20,
      height: 14,
      xPos: -66,
    },
    {
      isoCode: 'ag',
      width: 20,
      height: 14,
      xPos: -88,
    },
    {
      isoCode: 'ai',
      width: 20,
      height: 10,
      xPos: -110,
    },
    {
      isoCode: 'al',
      width: 20,
      height: 15,
      xPos: -132,
    },
    {
      isoCode: 'am',
      width: 20,
      height: 10,
      xPos: -154,
    },
    {
      isoCode: 'ao',
      width: 20,
      height: 14,
      xPos: -176,
    },
    {
      isoCode: 'aq',
      width: 20,
      height: 14,
      xPos: -198,
    },
    {
      isoCode: 'ar',
      width: 20,
      height: 13,
      xPos: -220,
    },
    {
      isoCode: 'as',
      width: 20,
      height: 10,
      xPos: -242,
    },
    {
      isoCode: 'at',
      width: 20,
      height: 14,
      xPos: -264,
    },
    {
      isoCode: 'au',
      width: 20,
      height: 10,
      xPos: -286,
    },
    {
      isoCode: 'aw',
      width: 20,
      height: 14,
      xPos: -308,
    },
    {
      isoCode: 'ax',
      width: 20,
      height: 13,
      xPos: -330,
    },
    {
      isoCode: 'az',
      width: 20,
      height: 10,
      xPos: -352,
    },
    {
      isoCode: 'ba',
      width: 20,
      height: 10,
      xPos: -374,
    },
    {
      isoCode: 'bb',
      width: 20,
      height: 14,
      xPos: -396,
    },
    {
      isoCode: 'bd',
      width: 20,
      height: 12,
      xPos: -418,
    },
    {
      isoCode: 'be',
      width: 18,
      height: 15,
      xPos: -440,
    },
    {
      isoCode: 'bf',
      width: 20,
      height: 14,
      xPos: -460,
    },
    {
      isoCode: 'bg',
      width: 20,
      height: 12,
      xPos: -482,
    },
    {
      isoCode: 'bh',
      width: 20,
      height: 12,
      xPos: -504,
    },
    {
      isoCode: 'bi',
      width: 20,
      height: 12,
      xPos: -526,
    },
    {
      isoCode: 'bj',
      width: 20,
      height: 14,
      xPos: -548,
    },
    {
      isoCode: 'bl',
      width: 20,
      height: 14,
      xPos: -570,
    },
    {
      isoCode: 'bm',
      width: 20,
      height: 10,
      xPos: -592,
    },
    {
      isoCode: 'bn',
      width: 20,
      height: 10,
      xPos: -614,
    },
    {
      isoCode: 'bo',
      width: 20,
      height: 14,
      xPos: -636,
    },
    {
      isoCode: 'bq',
      width: 20,
      height: 14,
      xPos: -658,
    },
    {
      isoCode: 'br',
      width: 20,
      height: 14,
      xPos: -680,
    },
    {
      isoCode: 'bs',
      width: 20,
      height: 10,
      xPos: -702,
    },
    {
      isoCode: 'bt',
      width: 20,
      height: 14,
      xPos: -724,
    },
    {
      isoCode: 'bv',
      width: 20,
      height: 15,
      xPos: -746,
    },
    {
      isoCode: 'bw',
      width: 20,
      height: 14,
      xPos: -768,
    },
    {
      isoCode: 'by',
      width: 20,
      height: 10,
      xPos: -790,
    },
    {
      isoCode: 'bz',
      width: 20,
      height: 14,
      xPos: -812,
    },
    {
      isoCode: 'ca',
      width: 20,
      height: 10,
      xPos: -834,
    },
    {
      isoCode: 'cc',
      width: 20,
      height: 10,
      xPos: -856,
    },
    {
      isoCode: 'cd',
      width: 20,
      height: 15,
      xPos: -878,
    },
    {
      isoCode: 'cf',
      width: 20,
      height: 14,
      xPos: -900,
    },
    {
      isoCode: 'cg',
      width: 20,
      height: 14,
      xPos: -922,
    },
    {
      isoCode: 'ch',
      width: 15,
      height: 15,
      xPos: -944,
    },
    {
      isoCode: 'ci',
      width: 20,
      height: 14,
      xPos: -961,
    },
    {
      isoCode: 'ck',
      width: 20,
      height: 10,
      xPos: -983,
    },
    {
      isoCode: 'cl',
      width: 20,
      height: 14,
      xPos: -1005,
    },
    {
      isoCode: 'cm',
      width: 20,
      height: 14,
      xPos: -1027,
    },
    {
      isoCode: 'cn',
      width: 20,
      height: 14,
      xPos: -1049,
    },
    {
      isoCode: 'co',
      width: 20,
      height: 14,
      xPos: -1071,
    },
    {
      isoCode: 'cp',
      width: 20,
      height: 14,
      xPos: -1093,
    },
    {
      isoCode: 'cr',
      width: 20,
      height: 12,
      xPos: -1115,
    },
    {
      isoCode: 'cu',
      width: 20,
      height: 10,
      xPos: -1137,
    },
    {
      isoCode: 'cv',
      width: 20,
      height: 12,
      xPos: -1159,
    },
    {
      isoCode: 'cw',
      width: 20,
      height: 14,
      xPos: -1181,
    },
    {
      isoCode: 'cx',
      width: 20,
      height: 10,
      xPos: -1203,
    },
    {
      isoCode: 'cy',
      width: 20,
      height: 14,
      xPos: -1225,
    },
    {
      isoCode: 'cz',
      width: 20,
      height: 14,
      xPos: -1247,
    },
    {
      isoCode: 'de',
      width: 20,
      height: 12,
      xPos: -1269,
    },
    {
      isoCode: 'dg',
      width: 20,
      height: 10,
      xPos: -1291,
    },
    {
      isoCode: 'dj',
      width: 20,
      height: 14,
      xPos: -1313,
    },
    {
      isoCode: 'dk',
      width: 20,
      height: 15,
      xPos: -1335,
    },
    {
      isoCode: 'dm',
      width: 20,
      height: 10,
      xPos: -1357,
    },
    {
      isoCode: 'do',
      width: 20,
      height: 13,
      xPos: -1379,
    },
    {
      isoCode: 'dz',
      width: 20,
      height: 14,
      xPos: -1401,
    },
    {
      isoCode: 'ea',
      width: 20,
      height: 14,
      xPos: -1423,
    },
    {
      isoCode: 'ec',
      width: 20,
      height: 14,
      xPos: -1445,
    },
    {
      isoCode: 'ee',
      width: 20,
      height: 13,
      xPos: -1467,
    },
    {
      isoCode: 'eg',
      width: 20,
      height: 14,
      xPos: -1489,
    },
    {
      isoCode: 'eh',
      width: 20,
      height: 10,
      xPos: -1511,
    },
    {
      isoCode: 'er',
      width: 20,
      height: 10,
      xPos: -1533,
    },
    {
      isoCode: 'es',
      width: 20,
      height: 14,
      xPos: -1555,
    },
    {
      isoCode: 'et',
      width: 20,
      height: 10,
      xPos: -1577,
    },
    {
      isoCode: 'eu',
      width: 20,
      height: 14,
      xPos: -1599,
    },
    {
      isoCode: 'fi',
      width: 20,
      height: 12,
      xPos: -1621,
    },
    {
      isoCode: 'fj',
      width: 20,
      height: 10,
      xPos: -1643,
    },
    {
      isoCode: 'fk',
      width: 20,
      height: 10,
      xPos: -1665,
    },
    {
      isoCode: 'fm',
      width: 20,
      height: 11,
      xPos: -1687,
    },
    {
      isoCode: 'fo',
      width: 20,
      height: 15,
      xPos: -1709,
    },
    {
      isoCode: 'fr',
      width: 20,
      height: 14,
      xPos: -1731,
    },
    {
      isoCode: 'ga',
      width: 20,
      height: 15,
      xPos: -1753,
    },
    {
      isoCode: 'gb',
      width: 20,
      height: 10,
      xPos: -1775,
    },
    {
      isoCode: 'gd',
      width: 20,
      height: 12,
      xPos: -1797,
    },
    {
      isoCode: 'ge',
      width: 20,
      height: 14,
      xPos: -1819,
    },
    {
      isoCode: 'gf',
      width: 20,
      height: 14,
      xPos: -1841,
    },
    {
      isoCode: 'gg',
      width: 20,
      height: 14,
      xPos: -1863,
    },
    {
      isoCode: 'gh',
      width: 20,
      height: 14,
      xPos: -1885,
    },
    {
      isoCode: 'gi',
      width: 20,
      height: 10,
      xPos: -1907,
    },
    {
      isoCode: 'gl',
      width: 20,
      height: 14,
      xPos: -1929,
    },
    {
      isoCode: 'gm',
      width: 20,
      height: 14,
      xPos: -1951,
    },
    {
      isoCode: 'gn',
      width: 20,
      height: 14,
      xPos: -1973,
    },
    {
      isoCode: 'gp',
      width: 20,
      height: 14,
      xPos: -1995,
    },
    {
      isoCode: 'gq',
      width: 20,
      height: 14,
      xPos: -2017,
    },
    {
      isoCode: 'gr',
      width: 20,
      height: 14,
      xPos: -2039,
    },
    {
      isoCode: 'gs',
      width: 20,
      height: 10,
      xPos: -2061,
    },
    {
      isoCode: 'gt',
      width: 20,
      height: 13,
      xPos: -2083,
    },
    {
      isoCode: 'gu',
      width: 20,
      height: 11,
      xPos: -2105,
    },
    {
      isoCode: 'gw',
      width: 20,
      height: 10,
      xPos: -2127,
    },
    {
      isoCode: 'gy',
      width: 20,
      height: 12,
      xPos: -2149,
    },
    {
      isoCode: 'hk',
      width: 20,
      height: 14,
      xPos: -2171,
    },
    {
      isoCode: 'hm',
      width: 20,
      height: 10,
      xPos: -2193,
    },
    {
      isoCode: 'hn',
      width: 20,
      height: 10,
      xPos: -2215,
    },
    {
      isoCode: 'hr',
      width: 20,
      height: 10,
      xPos: -2237,
    },
    {
      isoCode: 'ht',
      width: 20,
      height: 12,
      xPos: -2259,
    },
    {
      isoCode: 'hu',
      width: 20,
      height: 10,
      xPos: -2281,
    },
    {
      isoCode: 'ic',
      width: 20,
      height: 14,
      xPos: -2303,
    },
    {
      isoCode: 'id',
      width: 20,
      height: 14,
      xPos: -2325,
    },
    {
      isoCode: 'ie',
      width: 20,
      height: 10,
      xPos: -2347,
    },
    {
      isoCode: 'il',
      width: 20,
      height: 15,
      xPos: -2369,
    },
    {
      isoCode: 'im',
      width: 20,
      height: 10,
      xPos: -2391,
    },
    {
      isoCode: 'in',
      width: 20,
      height: 14,
      xPos: -2413,
    },
    {
      isoCode: 'io',
      width: 20,
      height: 10,
      xPos: -2435,
    },
    {
      isoCode: 'iq',
      width: 20,
      height: 14,
      xPos: -2457,
    },
    {
      isoCode: 'ir',
      width: 20,
      height: 12,
      xPos: -2479,
    },
    {
      isoCode: 'is',
      width: 20,
      height: 15,
      xPos: -2501,
    },
    {
      isoCode: 'it',
      width: 20,
      height: 14,
      xPos: -2523,
    },
    {
      isoCode: 'je',
      width: 20,
      height: 12,
      xPos: -2545,
    },
    {
      isoCode: 'jm',
      width: 20,
      height: 10,
      xPos: -2567,
    },
    {
      isoCode: 'jo',
      width: 20,
      height: 10,
      xPos: -2589,
    },
    {
      isoCode: 'jp',
      width: 20,
      height: 14,
      xPos: -2611,
    },
    {
      isoCode: 'ke',
      width: 20,
      height: 14,
      xPos: -2633,
    },
    {
      isoCode: 'kg',
      width: 20,
      height: 12,
      xPos: -2655,
    },
    {
      isoCode: 'kh',
      width: 20,
      height: 13,
      xPos: -2677,
    },
    {
      isoCode: 'ki',
      width: 20,
      height: 10,
      xPos: -2699,
    },
    {
      isoCode: 'km',
      width: 20,
      height: 12,
      xPos: -2721,
    },
    {
      isoCode: 'kn',
      width: 20,
      height: 14,
      xPos: -2743,
    },
    {
      isoCode: 'kp',
      width: 20,
      height: 10,
      xPos: -2765,
    },
    {
      isoCode: 'kr',
      width: 20,
      height: 14,
      xPos: -2787,
    },
    {
      isoCode: 'kw',
      width: 20,
      height: 10,
      xPos: -2809,
    },
    {
      isoCode: 'ky',
      width: 20,
      height: 10,
      xPos: -2831,
    },
    {
      isoCode: 'kz',
      width: 20,
      height: 10,
      xPos: -2853,
    },
    {
      isoCode: 'la',
      width: 20,
      height: 14,
      xPos: -2875,
    },
    {
      isoCode: 'lb',
      width: 20,
      height: 14,
      xPos: -2897,
    },
    {
      isoCode: 'lc',
      width: 20,
      height: 10,
      xPos: -2919,
    },
    {
      isoCode: 'li',
      width: 20,
      height: 12,
      xPos: -2941,
    },
    {
      isoCode: 'lk',
      width: 20,
      height: 10,
      xPos: -2963,
    },
    {
      isoCode: 'lr',
      width: 20,
      height: 11,
      xPos: -2985,
    },
    {
      isoCode: 'ls',
      width: 20,
      height: 14,
      xPos: -3007,
    },
    {
      isoCode: 'lt',
      width: 20,
      height: 12,
      xPos: -3029,
    },
    {
      isoCode: 'lu',
      width: 20,
      height: 12,
      xPos: -3051,
    },
    {
      isoCode: 'lv',
      width: 20,
      height: 10,
      xPos: -3073,
    },
    {
      isoCode: 'ly',
      width: 20,
      height: 10,
      xPos: -3095,
    },
    {
      isoCode: 'ma',
      width: 20,
      height: 14,
      xPos: -3117,
    },
    {
      isoCode: 'mc',
      width: 19,
      height: 15,
      xPos: -3139,
    },
    {
      isoCode: 'md',
      width: 20,
      height: 10,
      xPos: -3160,
    },
    {
      isoCode: 'me',
      width: 20,
      height: 10,
      xPos: -3182,
    },
    {
      isoCode: 'mf',
      width: 20,
      height: 14,
      xPos: -3204,
    },
    {
      isoCode: 'mg',
      width: 20,
      height: 14,
      xPos: -3226,
    },
    {
      isoCode: 'mh',
      width: 20,
      height: 11,
      xPos: -3248,
    },
    {
      isoCode: 'mk',
      width: 20,
      height: 10,
      xPos: -3270,
    },
    {
      isoCode: 'ml',
      width: 20,
      height: 14,
      xPos: -3292,
    },
    {
      isoCode: 'mm',
      width: 20,
      height: 14,
      xPos: -3314,
    },
    {
      isoCode: 'mn',
      width: 20,
      height: 10,
      xPos: -3336,
    },
    {
      isoCode: 'mo',
      width: 20,
      height: 14,
      xPos: -3358,
    },
    {
      isoCode: 'mp',
      width: 20,
      height: 10,
      xPos: -3380,
    },
    {
      isoCode: 'mq',
      width: 20,
      height: 14,
      xPos: -3402,
    },
    {
      isoCode: 'mr',
      width: 20,
      height: 14,
      xPos: -3424,
    },
    {
      isoCode: 'ms',
      width: 20,
      height: 10,
      xPos: -3446,
    },
    {
      isoCode: 'mt',
      width: 20,
      height: 14,
      xPos: -3468,
    },
    {
      isoCode: 'mu',
      width: 20,
      height: 14,
      xPos: -3490,
    },
    {
      isoCode: 'mv',
      width: 20,
      height: 14,
      xPos: -3512,
    },
    {
      isoCode: 'mw',
      width: 20,
      height: 14,
      xPos: -3534,
    },
    {
      isoCode: 'mx',
      width: 20,
      height: 12,
      xPos: -3556,
    },
    {
      isoCode: 'my',
      width: 20,
      height: 10,
      xPos: -3578,
    },
    {
      isoCode: 'mz',
      width: 20,
      height: 14,
      xPos: -3600,
    },
    {
      isoCode: 'na',
      width: 20,
      height: 14,
      xPos: -3622,
    },
    {
      isoCode: 'nc',
      width: 20,
      height: 10,
      xPos: -3644,
    },
    {
      isoCode: 'ne',
      width: 18,
      height: 15,
      xPos: -3666,
    },
    {
      isoCode: 'nf',
      width: 20,
      height: 10,
      xPos: -3686,
    },
    {
      isoCode: 'ng',
      width: 20,
      height: 10,
      xPos: -3708,
    },
    {
      isoCode: 'ni',
      width: 20,
      height: 12,
      xPos: -3730,
    },
    {
      isoCode: 'nl',
      width: 20,
      height: 14,
      xPos: -3752,
    },
    {
      isoCode: 'no',
      width: 20,
      height: 15,
      xPos: -3774,
    },
    {
      isoCode: 'np',
      width: 13,
      height: 15,
      xPos: -3796,
    },
    {
      isoCode: 'nr',
      width: 20,
      height: 10,
      xPos: -3811,
    },
    {
      isoCode: 'nu',
      width: 20,
      height: 10,
      xPos: -3833,
    },
    {
      isoCode: 'nz',
      width: 20,
      height: 10,
      xPos: -3855,
    },
    {
      isoCode: 'om',
      width: 20,
      height: 10,
      xPos: -3877,
    },
    {
      isoCode: 'pa',
      width: 20,
      height: 14,
      xPos: -3899,
    },
    {
      isoCode: 'pe',
      width: 20,
      height: 14,
      xPos: -3921,
    },
    {
      isoCode: 'pf',
      width: 20,
      height: 14,
      xPos: -3943,
    },
    {
      isoCode: 'pg',
      width: 20,
      height: 15,
      xPos: -3965,
    },
    {
      isoCode: 'ph',
      width: 20,
      height: 10,
      xPos: -3987,
    },
    {
      isoCode: 'pk',
      width: 20,
      height: 14,
      xPos: -4009,
    },
    {
      isoCode: 'pl',
      width: 20,
      height: 13,
      xPos: -4031,
    },
    {
      isoCode: 'pm',
      width: 20,
      height: 14,
      xPos: -4053,
    },
    {
      isoCode: 'pn',
      width: 20,
      height: 10,
      xPos: -4075,
    },
    {
      isoCode: 'pr',
      width: 20,
      height: 14,
      xPos: -4097,
    },
    {
      isoCode: 'ps',
      width: 20,
      height: 10,
      xPos: -4119,
    },
    {
      isoCode: 'pt',
      width: 20,
      height: 14,
      xPos: -4141,
    },
    {
      isoCode: 'pw',
      width: 20,
      height: 13,
      xPos: -4163,
    },
    {
      isoCode: 'py',
      width: 20,
      height: 11,
      xPos: -4185,
    },
    {
      isoCode: 'qa',
      width: 20,
      height: 8,
      xPos: -4207,
    },
    {
      isoCode: 're',
      width: 20,
      height: 14,
      xPos: -4229,
    },
    {
      isoCode: 'ro',
      width: 20,
      height: 14,
      xPos: -4251,
    },
    {
      isoCode: 'rs',
      width: 20,
      height: 14,
      xPos: -4273,
    },
    {
      isoCode: 'ru',
      width: 20,
      height: 14,
      xPos: -4295,
    },
    {
      isoCode: 'rw',
      width: 20,
      height: 14,
      xPos: -4317,
    },
    {
      isoCode: 'sa',
      width: 20,
      height: 14,
      xPos: -4339,
    },
    {
      isoCode: 'sb',
      width: 20,
      height: 10,
      xPos: -4361,
    },
    {
      isoCode: 'sc',
      width: 20,
      height: 10,
      xPos: -4383,
    },
    {
      isoCode: 'sd',
      width: 20,
      height: 10,
      xPos: -4405,
    },
    {
      isoCode: 'se',
      width: 20,
      height: 13,
      xPos: -4427,
    },
    {
      isoCode: 'sg',
      width: 20,
      height: 14,
      xPos: -4449,
    },
    {
      isoCode: 'sh',
      width: 20,
      height: 10,
      xPos: -4471,
    },
    {
      isoCode: 'si',
      width: 20,
      height: 10,
      xPos: -4493,
    },
    {
      isoCode: 'sj',
      width: 20,
      height: 15,
      xPos: -4515,
    },
    {
      isoCode: 'sk',
      width: 20,
      height: 14,
      xPos: -4537,
    },
    {
      isoCode: 'sl',
      width: 20,
      height: 14,
      xPos: -4559,
    },
    {
      isoCode: 'sm',
      width: 20,
      height: 15,
      xPos: -4581,
    },
    {
      isoCode: 'sn',
      width: 20,
      height: 14,
      xPos: -4603,
    },
    {
      isoCode: 'so',
      width: 20,
      height: 14,
      xPos: -4625,
    },
    {
      isoCode: 'sr',
      width: 20,
      height: 14,
      xPos: -4647,
    },
    {
      isoCode: 'ss',
      width: 20,
      height: 10,
      xPos: -4669,
    },
    {
      isoCode: 'st',
      width: 20,
      height: 10,
      xPos: -4691,
    },
    {
      isoCode: 'sv',
      width: 20,
      height: 12,
      xPos: -4713,
    },
    {
      isoCode: 'sx',
      width: 20,
      height: 14,
      xPos: -4735,
    },
    {
      isoCode: 'sy',
      width: 20,
      height: 14,
      xPos: -4757,
    },
    {
      isoCode: 'sz',
      width: 20,
      height: 14,
      xPos: -4779,
    },
    {
      isoCode: 'ta',
      width: 20,
      height: 10,
      xPos: -4801,
    },
    {
      isoCode: 'tc',
      width: 20,
      height: 10,
      xPos: -4823,
    },
    {
      isoCode: 'td',
      width: 20,
      height: 14,
      xPos: -4845,
    },
    {
      isoCode: 'tf',
      width: 20,
      height: 14,
      xPos: -4867,
    },
    {
      isoCode: 'tg',
      width: 20,
      height: 13,
      xPos: -4889,
    },
    {
      isoCode: 'th',
      width: 20,
      height: 14,
      xPos: -4911,
    },
    {
      isoCode: 'tj',
      width: 20,
      height: 10,
      xPos: -4933,
    },
    {
      isoCode: 'tk',
      width: 20,
      height: 10,
      xPos: -4955,
    },
    {
      isoCode: 'tl',
      width: 20,
      height: 10,
      xPos: -4977,
    },
    {
      isoCode: 'tm',
      width: 20,
      height: 14,
      xPos: -4999,
    },
    {
      isoCode: 'tn',
      width: 20,
      height: 14,
      xPos: -5021,
    },
    {
      isoCode: 'to',
      width: 20,
      height: 10,
      xPos: -5043,
    },
    {
      isoCode: 'tr',
      width: 20,
      height: 14,
      xPos: -5065,
    },
    {
      isoCode: 'tt',
      width: 20,
      height: 12,
      xPos: -5087,
    },
    {
      isoCode: 'tv',
      width: 20,
      height: 10,
      xPos: -5109,
    },
    {
      isoCode: 'tw',
      width: 20,
      height: 14,
      xPos: -5131,
    },
    {
      isoCode: 'tz',
      width: 20,
      height: 14,
      xPos: -5153,
    },
    {
      isoCode: 'ua',
      width: 20,
      height: 14,
      xPos: -5175,
    },
    {
      isoCode: 'ug',
      width: 20,
      height: 14,
      xPos: -5197,
    },
    {
      isoCode: 'um',
      width: 20,
      height: 11,
      xPos: -5219,
    },
    {
      isoCode: 'us',
      width: 20,
      height: 11,
      xPos: -5241,
    },
    {
      isoCode: 'uy',
      width: 20,
      height: 14,
      xPos: -5263,
    },
    {
      isoCode: 'uz',
      width: 20,
      height: 10,
      xPos: -5285,
    },
    {
      isoCode: 'va',
      width: 15,
      height: 15,
      xPos: -5307,
    },
    {
      isoCode: 'vc',
      width: 20,
      height: 14,
      xPos: -5324,
    },
    {
      isoCode: 've',
      width: 20,
      height: 14,
      xPos: -5346,
    },
    {
      isoCode: 'vg',
      width: 20,
      height: 10,
      xPos: -5368,
    },
    {
      isoCode: 'vi',
      width: 20,
      height: 14,
      xPos: -5390,
    },
    {
      isoCode: 'vn',
      width: 20,
      height: 14,
      xPos: -5412,
    },
    {
      isoCode: 'vu',
      width: 20,
      height: 12,
      xPos: -5434,
    },
    {
      isoCode: 'wf',
      width: 20,
      height: 14,
      xPos: -5456,
    },
    {
      isoCode: 'ws',
      width: 20,
      height: 10,
      xPos: -5478,
    },
    {
      isoCode: 'xk',
      width: 20,
      height: 15,
      xPos: -5500,
    },
    {
      isoCode: 'ye',
      width: 20,
      height: 14,
      xPos: -5522,
    },
    {
      isoCode: 'yt',
      width: 20,
      height: 14,
      xPos: -5544,
    },
    {
      isoCode: 'za',
      width: 20,
      height: 14,
      xPos: -5566,
    },
    {
      isoCode: 'zm',
      width: 20,
      height: 14,
      xPos: -5588,
    },
    {
      isoCode: 'zw',
      width: 20,
      height: 10,
      xPos: -5610,
    },
  ];