
import React  from "react";
import { FormattedMessage } from 'react-intl';

import './AvailabilityComponent.scss';
import '../../App/layout.scss';
import '../../App/fonts.scss';
import { Mixpanel } from '../../Mixpanel';

import TableHeaderComponent from "pages/ScheduleTab/TableHeaderComponent";
import AvailabilityTableRowComponent from "./TableRow/AvailabilityTableRowComponent";
import AvailabilityDetailComponent from "./detail/AvailabilityDetailComponent";
import Modal from "components/GeneralComponents/Modal";
import styledConfirm from '../Dashboard/components/styled';

const LoadingLayer = ({ loading }) => {
    return (
      <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
        <img src="/loader.gif" alt="Loading gif" />
      </div>
    );
  };

class AvailabilityTableComponent extends React.Component {
    state = {
      showAvailDetail: false,
    };
 
    dayCellClicked = (user, userAvailabilities) => {
      this.detailAvailabilities = userAvailabilities;
      this.detailUser = user;
      this.setState({ showAvailDetail: true});
      Mixpanel.track('Open Avail Detail');
    }

    closeAvailDetail = () => {
      this.setState({ showAvailDetail: false});
      Mixpanel.track('Close Avail Detail');
    }

    render() {
        const { loading, locationId, locationData, rangeStart, rangeEnd, allUsers, availabilities } = this.props;
        const { showAvailDetail } = this.state;

        const userRows = allUsers.map((user) => ( rangeStart 
          && <AvailabilityTableRowComponent 
                key = {user.id}
                user = {user} 
                rangeStart = {rangeStart} 
                rangeEnd = {rangeEnd} 
                availabilities = {availabilities}
                loadAvailDetail = {this.dayCellClicked}
                /> ));

        return (
            <section className="avail-table__wrap">
                <LoadingLayer loading={loading} />

                <table className={`avail-table ${loading ? 'loading' : ''}`}>
                  <TableHeaderComponent rangeStart = {rangeStart} rangeEnd = {rangeEnd} />
                  <tbody> {userRows} </tbody>
                </table>

                {showAvailDetail && <Modal>
                    <AvailabilityDetailComponent 
                      user = {this.detailUser}
                      userAvailabilities = {this.detailAvailabilities}
                      closeAvailDetail = {this.closeAvailDetail}
                      rangeStart = {rangeStart}
                      rangeEnd = {rangeEnd}
                    />
                  </Modal>}

                <styledConfirm.Overlay display={(showAvailDetail) ? 'block' : 'none'} />

            </section>
        );
    }

}

export default AvailabilityTableComponent;