import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { localeChanged } from 'actions/dashboard-actions';
import { getBrowserLanguage } from 'helpers/localization';
import PageWrap from 'components/GeneralComponents/PageWrap';
import 'helpers/findIndex-shim';

import '../../components/GeneralComponents/RegistrationDesign.scss'
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';

import {
  signInAttempt,
  SignInConfirm,
  signInWithEmail,
  AddAuthHeaders,
  updateUserAttributes,
} from 'helpers/api-calls';
import {
  setAuthenticationCookie,
  setUserCookie,
  checkForAuthentication,
  setLocaleCookie,
} from 'helpers/cookies';

import { LoginFirstStage } from './LoginFirstStage/LoginFirstStage';
import LoginSecondStage from './LoginSecondStage/LoginSecondStage';

import './LoginPage.scss';

import { Mixpanel } from '../../Mixpanel';

class LoginPagei18n extends React.Component {
  state = {
    stage: 1,
    email: {
      value: '',
      valid: false,
    },
    phone: {
      value: '',
      valid: false,
    },
    error: null,
    mobilePinError: '',
    emailPasswordError: '',
    path: null,
  };

  messages = defineMessages({
    mobilePinError1: {
      id: 'LoginPage.mobilePinError1',
      defaultMessage:
        "Oops, that PIN doesn't match our records.Please try again.",
    },
    mobilePinError2: {
      id: 'LoginPage.mobilePinError2',
      defaultMessage:
        'Something went wrong while processing the information. Please try again.',
    },
    emailPasswordError1: {
      id: 'LoginPage.emailPasswordError1',
      defaultMessage: 'Wrong password, please try again.',
    },
    emailPasswordError2: {
      id: 'LoginPage.emailPasswordError2',
      defaultMessage:
        'There is no email associated with this account. Please try again.',
    },
    emailPasswordError3: {
      id: 'LoginPage.emailPasswordError3',
      defaultMessage:
        'Something went wrong while processing the information. Please try again.',
    },
    phoneError1: {
      id: 'LoginPage.phoneError1',
      defaultMessage:
        "We're unable to find that phone number. Please try again.",
    },
    phoneError2: {
      id: 'LoginPage.phoneError2',
      defaultMessage:
        'Something went wrong while processing the information. Please try again.',
    },
  });

  _nextStep = ({ type, value }) => {
    this.setState({
      type,
      [type]: {
        value,
        valid: true,
      },
      stage: 2,
    });
  };

  back = (e) => {
    e.preventDefault();
    this.setState({
      stage: 1,
      mobilePinError: '',
      emailPasswordError: '',
    });
  };

  _checkUserForLocations = (location) => {
    if (location && location.location) {
      return location.location.id;
    }
    return false;
  };

  _setLocale = (user) => {
    const { onLocaleChange, locale } = this.props;

    if (user.locale && user.locale !== locale) {
      // User has previously saved locale on server
      setLocaleCookie(user.locale);
      onLocaleChange(user.locale);
    } else if (locale !== getBrowserLanguage()) {
      // User has no saved locale setting on server and
      // changed locale before logging in

      setLocaleCookie(locale);
      updateUserAttributes({
        user_id: user.id,
        locale,
      });
    }

    // Sync cookie with local from state
    setLocaleCookie(locale);
  };

  identifyUserToTracker = (user) => {
    Mixpanel.identify(user.id);
  }

  _phoneLogin = (pin) => {
    const { phone } = this.state;
    SignInConfirm(phone.value, pin)
      .then((response) => {
        const headers = {
          ...response.headers,
          'Auth-Category': 'phone_number',
          'Auth-Target': phone.value,
          location_id: this._checkUserForLocations(
            response.data.user.recent_user_privilege
          ),
        };
        setAuthenticationCookie(headers);
        setUserCookie(response.data.user);
        this.identifyUserToTracker(response.data.user);
        AddAuthHeaders();

        this._setLocale(response.data.user);

        if (
          this._checkUserForLocations(response.data.user.recent_user_privilege)
        ) {
          this.setState({
            path: {
              pathname: '/schedulecalendar',
            },
          });
        } else {
          this.setState({
            path: {
              pathname: '/add-location',
            },
          });
        }
      })
      .catch((error) => {
        const { intl } = this.props;
        const { response } = error;

        if (response) {
          if (
            (response && response.status === 401) ||
            (response && response.status === 403)
          ) {
            // Fixed bug WS-1243
            /*
            this.setState({
              mobilePinError: intl.formatMessage(this.messages.mobilePinError1),
            });
            */

            const msg = (<FormattedMessage id="LoginPage.mobilePinError3"
              defaultMessage="Oops, that PIN doesn't match our records.Please try again." />);
            this.setState({
              mobilePinError: msg,
            });

          }
        } else {
          this.setState({
            mobilePinError: intl.formatMessage(this.messages.mobilePinError2),
          });
        }
      });
  };

  _emailLogin = (email, password) => {
    signInWithEmail(email, password).then(
      (response) => {
        const headers = {
          ...response.headers,
          'Auth-Category': 'email',
          'Auth-Target': email.toLowerCase(),
          location_id: this._checkUserForLocations(
            response.data.user.recent_user_privilege
          ),
        };
        setAuthenticationCookie(headers);
        AddAuthHeaders();
        setUserCookie(response.data.user);

        this._setLocale(response.data.user);

        if (
          this._checkUserForLocations(response.data.user.recent_user_privilege)
        ) {
          this.setState({
            path: {
              pathname: '/schedulecalendar',
            },
          });
        } else {
          this.setState({
            path: {
              pathname: '/add-location',
            },
          });
        }
      },
      ({ response }) => {
        const { intl } = this.props;

        if (
          (response && response.status === 401) ||
          (response && response.status === 403)
        ) {

          this.setState({
            emailPasswordError: intl.formatMessage(
              this.messages.emailPasswordError1
            ),
          });


        } else if (response.status === 404) {
          this.setStates({
            emailPasswordError: intl.formatMessage(
              this.messages.emailPasswordError2
            ),
          });
        } else {
          this.setState({
            emailPasswordError: intl.formatMessage(
              this.messages.emailPasswordError3
            ),
          });
        }
      }
    );
  };

  _firstValueSubmit = ({ type, value }) => {
    if (type === 'email') {
      this._nextStep({ type, value });
    } else {
      this._phoneNumberSubmit({ type, value });
    }
  };

  _phoneNumberSubmit = ({ type, value }) => {
    signInAttempt(value).then(
      (response) => {
        const { intl } = this.props;

        if (response.status === 200) {
          this._nextStep({ type, value });
        } else {
          this.setState({
            error: intl.formatMessage(this.messages.phoneError1),
            type,
            [type]: {
              value,
              valid: true,
            },
          });
        }
      },
      (error) => {
        const { intl } = this.props;

        if (
          (error.response && error.response.status === 404) ||
          (error.response && error.response.status === 400)
        ) {
          this.setState({
            // Fixed bug WS-1243
            error: intl.formatMessage(this.messages.phoneError1),
            //error: <FormattedMessage  id="LoginPage.phoneError1"
            //defaultMessage="We're unable to find that phone number. Please try again."/>,
            type,
            [type]: {
              value,
              valid: true,
            },
          });
        } else if (error.response) {
          this.setState({
            error: intl.formatMessage(this.messages.phoneError2),
            type,
            [type]: {
              value,
              valid: true,
            },
          });
        }
      }
    );
  };

  render() {
    const {
      stage,
      path,
      error,
      type,
      mobilePinError,
      emailPasswordError,
      phone,
      email,
    } = this.state;


    if (checkForAuthentication()) {
      // User is logged in
      return <Redirect to="/" />;
    }

    const CurrentStep = () => {

      switch (stage) {
        case 1:
          return (
            <LoginFirstStage
              _formSubmit={this._firstValueSubmit}
              error={error}
            />
          );

        case 2:
          return (
            <LoginSecondStage
              back={this.back}
              type={type}
              _pinSubmit={this._phoneLogin}
              _passwordSubmit={this._emailLogin}
              _formSubmit={this._login}
              phoneResend={this._firstValueSubmit}
              mobilePinError={mobilePinError}
              emailPasswordError={emailPasswordError}
              phone={phone.value}
              email={email.value}
            />
          );

        default:
          return <LoginFirstStage formSubmit={this.nextStep} />;
      }
    };

    if (path) {
      return <Redirect push to={path} />;
    }
    return (

      <RegistrationLayout>
        <div className="form-content">
          <div className="welcome-text-wrapper">
            <div className="dot"></div>
            <div className="welcome-text">Welcome back</div>
          </div>
          <div className="main-heading">
            Log in to Shyft
          </div>

          <CurrentStep />

          {stage == 1 && <div className="login-message">
            Don't have an account?{' '}
            <Link className="login-message-link" to="/sign-up">
              Sign Up
            </Link>
          </div>
          }
        </div>
      </RegistrationLayout>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
LoginPagei18n.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = ({ localeReducer }) => ({
  locale: localeReducer.locale,
});

const mapDispatchToProps = (dispatch) => ({
  onLocaleChange: (locale) => {
    moment.locale(locale);
    dispatch(localeChanged(locale));
  },
});

const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LoginPagei18n));

export { LoginPage, LoginPagei18n };
