import styled from 'styled-components/macro';
import { css } from 'styled-components';

import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';
import { lighten } from 'polished';

const WRAP_MODIFIERS = {
  active: ({ theme }) => css`
    //background-color: ${theme.palette.border};
    background-color: rgba(242,242,247,255);
    cursor: default;

    &:hover {
      //background-color: ${theme.palette.border};
      background-color: rgba(242,242,247,255);
    }
  `,
};
const MessageWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  border-bottom: 1px solid rgba(227,227,230,255);

  &:hover {
    //background-color: ${({ theme }) => lighten(0.1, theme.palette.border)};
    background-color: rgba(242,242,247,255);
  }

  ${applyStyleModifiers(WRAP_MODIFIERS)}
`;
MessageWrap.proptypes = {
  modifiers: styleModifierPropTypes(WRAP_MODIFIERS),
};

const Info = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.secondary};
  min-width: 0;
  //border: 1px solid red;
`;

const ChannelMessageWrap = styled.div`
  position: relative;
  display: flex;
  padding: 1rem;
  background-color: white;
  border-bottom: 1px solid rgba(227,227,230,255);
  width: 373px;
  height: 72px;

  &:hover {
    //background-color: ${({ theme }) => lighten(0.1, theme.palette.border)};
    background-color: rgba(242,242,247,255);
  }

  ${applyStyleModifiers(WRAP_MODIFIERS)}
  //border: 1px solid red;
`;
ChannelMessageWrap.proptypes = {
  modifiers: styleModifierPropTypes(WRAP_MODIFIERS),
};

export default {
  MessageWrap,
  ChannelMessageWrap,
  Info,
};
