
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  changeDateRange,
  addTypeFilter,
  deleteTypeFilter,
  addDayFilter,
  deleteDayFilter,
  addStateFilter,
  deleteStateFilter,
} from '../../../actions/calendar-actions';
import FilterContainer from './FilterContainer';

import { loadLocationData } from '../../../actions/dashboard-actions';

const mapStateToProps = ({
  locationReducer,
  setupReducer,
  appReducer,
  calendarReducer,
}) => {
  const vtoEnabled = setupReducer.setup_actions.meta.current_location.cfg_vto;
  const postOpenShiftEnabled =
    appReducer.location.settings.cfg__shift__post_open_shift;
  const actionMenuEnabled = appReducer.location.settings.cfg__action_menu;

  return {
    vtoEnabled,
    postOpenShiftEnabled,
    actionMenuEnabled,
    location_id: locationReducer.location_code,
    location_permission: locationReducer.location_data.shift_require_approval,
    dayFilters: calendarReducer.dayFilters,
    typeFilters: calendarReducer.typeFilters,
    stateFilters: calendarReducer.stateFilters,
    currentDateRangeStart: calendarReducer.currentDateRangeStart,
    currentDateRangeEnd: calendarReducer.currentDateRangeEnd,
    locationData: locationReducer.location_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    loadLocationDataDispatch: () => {
      dispatch(loadLocationData());
    },

    changeWeek: (from, to) => {
      dispatch(changeDateRange(from, to));
    },

    stateFilterUpdate: ({ action, value }) => {
      if (action === 'add') {
        dispatch(addStateFilter(value));
      }

      if (action === 'delete') {
        dispatch(deleteStateFilter(value));
      }
    },

    typeFilterUpdate: ({ action, value }) => {
      if (action === 'add') {
        dispatch(addTypeFilter(value));
      }

      if (action === 'delete') {
        dispatch(deleteTypeFilter(value));
      }
    },

    dayFilterUpdate: ({ action, value }) => {
      if (action === 'add') {
        dispatch(addDayFilter(value));
      }

      if (action === 'delete') {
        dispatch(deleteDayFilter(value));
      }
    },
  };
};


const FilterDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterContainer);

export default FilterDataContainer;
