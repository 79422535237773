import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import styled from './styled';

class Confirm extends React.PureComponent {
	constructor(props) {
    super(props);

    this.state = {
      debug: false,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { title, content, notifyCancel, notifyConfirm, } = this.props;

    this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {
  	const { title, content, notifyCancel, notifyConfirm, } = this.props;

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
  	const { notifyCancel, notifyDelete, } = this.props;

  	let w = 400;
    let h = 180;
    let winTop = `${(window.innerHeight - h)/2}px`;
    let winLeft = `${(window.innerWidth - w)/2}px`;
    let winH = `${h}px`;
    let winW = `${w}px`;

    const title = (<FormattedMessage
            id="CommentsConfirm.title"
            defaultMessage="Delete Message"
          />);
    const content = (<FormattedMessage
            id="CommentsConfirm.content"
            defaultMessage="Are you sure you want to delete this message? This cannot be undone."
          />);
    const cancel = (<FormattedMessage
            id="CommentsConfirm.buttonCancel"
            defaultMessage="Cancel"
          />);
    const deleting = (<FormattedMessage
            id="CommentsConfirm.buttonDelete"
            defaultMessage="Delete"
          />);

  	return (
  		<styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
  			<label className="confirm__title">{title}</label>
  			<label className="confirm__text">{content}</label>
  			<div className="confirm__button-wrap">
          <button className="confirm__button-cancel" onClick={notifyCancel}>{cancel}</button>
          <button className="confirm__button-delete" onClick={notifyDelete}>{deleting}</button>
        </div>
  		</styled.WinContainerConfirm>

  	);
  }
}

Confirm.propTypes = {
  notifyCancel: PropTypes.func.isRequired,
  notifyDelete: PropTypes.func.isRequired
};

export default injectIntl(Confirm);