import React  from "react";
import { FormattedMessage } from 'react-intl';

import '../../../App/layout.scss';
import '../../../App/fonts.scss';
// import { Mixpanel } from '../../../Mixpanel';
import AvailabilityTableRowUserCell from "./AvailabilityTableRowUserCell";
import AvailabilityTableRowWeekdayCell from "./AvailabilityTableRowWeekdayCell";

class AvailabilityTableRowComponent extends React.Component {
    state = {
    };
  
    constructor(props) {
      super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    render() {
        const {rangeStart, rangeEnd, user, availabilities, loadAvailDetail} = this.props;
        const userAvailabilities = availabilities.filter( avail => avail.user_id == user.owner_id);

        const cloned = rangeStart.clone();
        const col1 = cloned;
        const col2 = cloned.clone().add(1, 'days');
        const col3 = cloned.clone().add(2, 'days');
        const col4 = cloned.clone().add(3, 'days');
        const col5 = cloned.clone().add(4, 'days');
        const col6 = cloned.clone().add(5, 'days');
        const col7 = rangeEnd.clone();

        return (
            <tr className="avail-row">
                <AvailabilityTableRowUserCell user = {user} />
                <AvailabilityTableRowWeekdayCell  user = {user} date = {col1} userAvailabilities = {userAvailabilities} loadAvailDetail = {loadAvailDetail} />
                <AvailabilityTableRowWeekdayCell  user = {user} date = {col2} userAvailabilities = {userAvailabilities} loadAvailDetail = {loadAvailDetail}/>
                <AvailabilityTableRowWeekdayCell  user = {user} date = {col3} userAvailabilities = {userAvailabilities} loadAvailDetail = {loadAvailDetail}/>
                <AvailabilityTableRowWeekdayCell  user = {user} date = {col4} userAvailabilities = {userAvailabilities} loadAvailDetail = {loadAvailDetail}/>
                <AvailabilityTableRowWeekdayCell  user = {user} date = {col5} userAvailabilities = {userAvailabilities} loadAvailDetail = {loadAvailDetail}/>
                <AvailabilityTableRowWeekdayCell  user = {user} date = {col6} userAvailabilities = {userAvailabilities} loadAvailDetail = {loadAvailDetail}/>
                <AvailabilityTableRowWeekdayCell  user = {user} date = {col7} userAvailabilities = {userAvailabilities} loadAvailDetail = {loadAvailDetail}/>
            </tr>
        );
    }

}

export default AvailabilityTableRowComponent;