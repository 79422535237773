import moment from 'moment';
import constants from '../constants';

const usersNotScheduledForCurrentWeekSelector = (shifts, startDate, endDate, users) => {

  if (shifts.length == 0) {
    return users;
  } else {
    console.log("Number of Users Before Removal: " + users.length);
    const resultUsers = users.slice();

    for (let i = 0; i < shifts.length; i++) {
      let shift = shifts[i];
      const shiftStart = moment(shift.start_at);

      if (shiftStart.isAfter(startDate) && shiftStart.isBefore(endDate)) {

        let lookUpUserId = null;

        if (shift.trade_status === 'inventory') {

          lookUpUserId = shift.owner_id;
        } else if (shift.name === 'shift') {

          if (shift.trade_status === 'covered' || shift.trade_status === 'approved') {
            lookUpUserId = shift.taker_id;
          } else if (shift.trade_status === 'posted' || shift.trade_status === 'rejected' || shift.trade_status === 'pending') {
            lookUpUserId = shift.owner_id;
          }

        } else if (shift.name === 'open_shift') {

          if (shift.trade_status === 'approved' || shift.trade_status === 'covered') {
            lookUpUserId = shift.taker_id;
          }
        }

        if (lookUpUserId != null) {
          const idx = resultUsers.findIndex((user) => user.owner_id === lookUpUserId);
          if (idx > -1) {
            resultUsers.splice(idx, 1);
          }
        }

      }
    }
    console.log("Number of Users After Removal: " + resultUsers.length);
    return resultUsers;
  }
};

const shouldShiftBeShownForUser = (shift, user) => {
  if (user == null) {
    return false;
  }
  // Inventory Shift
  if (shift.trade_status === 'inventory' || shift.trade_status === 'called_off') {
    if (shift.owner_id === user.owner_id) {
      return true;
    }
    // Team Shift
  } else if (shift.name === 'shift') {
    if (shift.taker_id === user.owner_id && (shift.trade_status === 'covered' || shift.trade_status === 'approved')) {
      return true;
    } else if (shift.owner_id === user.owner_id && (shift.trade_status === 'posted' || shift.trade_status === 'rejected' || shift.trade_status === 'pending')) {
      return true;
    }
    // Open Shift
  } else if (shift.name === 'open_shift') {
    if (shift.taker_id === user.owner_id && (shift.trade_status === 'approved' || shift.trade_status === 'covered')) {
      return true;
    }
  }
  return false;
}

const shouldShiftBeCountedTowardsTotalHours = (shift) => {
  // Inventory Shift
  if (shift.trade_status === 'inventory') {
    return true;
    // Team Shift
  } else if (shift.name === 'shift') {
    if (shift.trade_status === 'covered' || shift.trade_status === 'approved') {
      return true;
    } else if (shift.trade_status === 'posted' || shift.trade_status === 'rejected' || shift.trade_status === 'pending') {
      return true;
    }
    // Open Shift
  } else if (shift.name === 'open_shift') {
    if (shift.trade_status === 'approved' || shift.trade_status === 'covered') {
      return true;
    }
  } else if (shift.trade_status == 'unregistered_user_shift_inventory' && shift.unregistered_user_id !== null) {
    return true;
  }
  return false;
}

const currentOwnerId = (shift) => {
  // Inventory Shift
  if (shift.trade_status === 'inventory' || shift.trade_status === 'called_off') {
    return shift.owner_id;
    // Team Shift
  } else if (shift.name === 'shift') {
    if (shift.trade_status === 'covered' || shift.trade_status === 'approved') {
      return shift.taker_id;
    } else if (shift.trade_status === 'posted' || shift.trade_status === 'rejected' || shift.trade_status === 'pending') {
      return shift.owner_id;
    }
    // Open Shift
  } else if (shift.name === 'open_shift') {
    if (shift.trade_status === 'approved' || shift.trade_status === 'covered') {
      return shift.taker_id;
    }
  }
  return null;
}

const compareTimes = (startTimeA, startTimeB) => {
  if (startTimeA.isBefore(startTimeB)) {
    return -1;
  } else if (startTimeA.isAfter(startTimeB)) {
    return 1;
  } else {
    return 0;
  }
}

const sortShifts = (listItems, sortType, ascending) => {
  const { sortTypes, } = constants;

  let arrToSort = [...listItems];

  if (sortType === null || sortType === undefined) {
    sortStartDateWithNullValues(arrToSort, ascending);
  } else {
    switch (sortType) {
      case sortTypes.DATE:
        sortStartDateWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.POSITION:
        sortJobTitleWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.FIRST_NAME:
        sortFirstNameWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.LAST_NAME:
        sortLastNameWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.STATUS:
        sortStatusWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.USER_ROLE:
        //this.sortUserRoleWithNullValues(arrToSort, ascending);
        const admins = arrToSort.filter((user) => user.is_admin === true);
        const members = arrToSort.filter((user) => user.is_admin === false);
        const unregistered = arrToSort.filter((user) => user.status === 'unregistered');
        sortFirstNameWithNullValues(admins, true);
        sortFirstNameWithNullValues(members, true);
        sortFirstNameWithNullValues(unregistered, true);
        if (ascending) {
          arrToSort = admins.concat(members).concat(unregistered);
        } else {
          arrToSort = members.concat(admins);
        }
        break;
    }
  }
  return arrToSort;
}

const sortStartDateWithNullValues = (arr, ascending = true) => {
  const multiplier = ascending ? 1 : -1;

  return arr.sort((a, b) => {
    // Determine if items are shifts or rtos
    const isAaShift = a.time_off_reason === undefined;
    const isBaShift = b.time_off_reason === undefined;

    // Extract dates and times for comparison
    let dateA = isAaShift ? a.start_at.split('T')[0] : a.start_date;
    let timeA = isAaShift ? a.start_at : a.start_time;

    let dateB = isBaShift ? b.start_at.split('T')[0] : b.start_date;
    let timeB = isBaShift ? b.start_at : b.start_time;

    // Compare dates first
    if (dateA < dateB) return -1 * multiplier;  // If dateA is earlier and ascending, it should be first
    if (dateA > dateB) return 1 * multiplier;   // If dateA is later and ascending, it should be second

    // If dates are equal, compare times (if available)
    if (timeA && timeB) {
      if (timeA < timeB) return -1 * multiplier; // Compare times similarly
      if (timeA > timeB) return 1 * multiplier;
    }

    // If dates are the same but no time is available or times are equal, keep original order
    return 0;
  });
}


const sortedShiftsByDOW = (rangeStart, sortDOW, shifts) => {
  const dayToSortBy = rangeStart.clone().add(sortDOW - 1, 'days');

  const filteredShifts = shifts.filter((item) => {
    return (shouldShiftBeCountedTowardsTotalHours(item));
  });

  const sortedShifts = [...filteredShifts].sort((a, b) => {
    const startTimeA = moment(a.start_at);
    const startTimeB = moment(b.start_at);
    // console.log(startTimeA.format("MMM Do YY")+ ' : '+ startTimeB.format("MMM Do YY"));
    if (dayToSortBy.isSame(startTimeA, 'day')) {
      if (dayToSortBy.isSame(startTimeB, 'day')) {
        return compareTimes(startTimeA, startTimeB);
      } else {
        return -1;
      }
    } else if (dayToSortBy.isSame(startTimeB, 'day')) {
      return 1;
    } else {
      return compareTimes(startTimeA, startTimeB);
    }
  });
  return sortedShifts;
}

const sortUsersWithShiftOrder = (rangeStart, sortDOW, shifts, allUsers, unregisteredUsers) => {
  if (rangeStart !== null) {

    if (sortDOW == 0) {
      return [].concat(allUsers.concat(unregisteredUsers));
    }

    const sortedShifts = sortedShiftsByDOW(rangeStart, sortDOW, shifts);

    var sortedUsers = [];
    sortedShifts.forEach((shift) => {
      var user;
      if ((shift.hasOwnProperty('unregistered_user_id'))) {
        user = unregisteredUsers.find(userObj => { return userObj.id === shift.unregistered_user_id });
      } else if (shift.hasOwnProperty('unregistered_user')) {
        user = unregisteredUsers.find(userObj => { return userObj.id === shift.unregistered_user.id });
      } else {
        user = allUsers.find(userObj => { return userObj.owner_id === currentOwnerId(shift) });
      }
      if (sortedUsers.includes(user) === false && user !== null) {
        sortedUsers.push(user);
      }
    });

    allUsers.forEach((user) => {
      if (sortedUsers.includes(user) === false && user !== null) {
        sortedUsers.push(user);
      }
    });

    unregisteredUsers.forEach((unregUser) => {
      if (sortedUsers.includes(unregUser) === false && unregUser !== null) {
        sortedUsers.push(unregUser);
      }
    });

    console.log(sortedUsers);
    return sortedUsers;
  }

  return [];
}


const getDisplayableShiftsForUser = (shifts, user, showInventory) => {
  // console.log(JSON.stringify(user));

  let firstFiltered = [];
  for (let i = 0; i < shifts.length; i++) {
    if (shifts[i].hasOwnProperty('unregistered_user_id')) {
      if (shifts[i].trade_status === 'unregistered_user_shift_inventory' && shifts[i].unregistered_user_id === user.id) {
        firstFiltered.push(shifts[i]);
      }
    } else if (shifts[i].hasOwnProperty('unregistered_user')) {
      if (shifts[i].trade_status === 'unregistered_user_shift_inventory' &&
        shifts[i].unregistered_user.id === user.id) {
        firstFiltered.push(shifts[i]);
      }
    } else {
      if (shouldShiftBeShownForUser(shifts[i], user)) {
        firstFiltered.push(shifts[i]);
      }
    }
  }

  if (showInventory !== 1) {
    firstFiltered = [];
  }

  return firstFiltered;
}

const getRowOfShiftsToDisplay = (shifts, user, rangeStart, showInventory) => {

  var firstShifts = [];
  var secondShifts = [];
  var thirdShifts = [];
  var fourthShifts = [];
  var fifthShifts = [];
  var sixthShifts = [];
  var lastColumnShifts = [];
  var totalHoursForWeekForUser = 0;

  if (shifts.length > 0) {
    const myShifts = getDisplayableShiftsForUser(shifts, user, showInventory);
    if (myShifts && myShifts.length > 0) {
      for (let i = 0; i < myShifts.length; i++) {
        var momentVal = moment(myShifts[i].start_at);
        momentVal.locale("en");

        const startDate = new Date(myShifts[i].start_at);
        const endDate = new Date(myShifts[i].end_at);

        const hoursDifference = (endDate - startDate) / (1000 * 60 * 60);

        totalHoursForWeekForUser += hoursDifference;
        const dayDiff = momentVal.diff(rangeStart, 'days');
        switch (dayDiff) {
          case 0:
            firstShifts.push(myShifts[i]);
            break;
          case 1:
            secondShifts.push(myShifts[i]);
            break;
          case 2:
            thirdShifts.push(myShifts[i]);
            break;
          case 3:
            fourthShifts.push(myShifts[i]);
            break;
          case 4:
            fifthShifts.push(myShifts[i]);
            break;
          case 5:
            sixthShifts.push(myShifts[i]);
            break;
          case 6:
            lastColumnShifts.push(myShifts[i]);
            break;
        }
      }
    }
  }
  return [totalHoursForWeekForUser, firstShifts, secondShifts, thirdShifts, fourthShifts, fifthShifts, sixthShifts, lastColumnShifts];
}

const nextWeek = (momentDate) => {
  const cloned = momentDate.clone();
  const result = cloned.add(7, 'days');
  return result;
}

const previousWeek = (momentDate) => {
  const cloned = momentDate.clone();
  const result = cloned.subtract(7, 'days');
  return result;
}

export default {
  usersNotScheduledForCurrentWeekSelector,
  shouldShiftBeShownForUser,
  nextWeek,
  previousWeek,
  shouldShiftBeCountedTowardsTotalHours,
  sortStartDateWithNullValues,
  sortUsersWithShiftOrder,
  sortShifts,
  getDisplayableShiftsForUser,
  getRowOfShiftsToDisplay,
};