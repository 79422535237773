
import { formatForWeekPicker } from './date-time';
import moment from 'moment';

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function listCalendarWeeks(startTime = new Date(), fiscalDay = 'monday', periodLength = 6) {
  const weeks = [];
  const startDate = new Date(startTime);

  // Find the next fiscal day from the current day
  const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const startDay = startDate.getDay();
  const fiscalIndex = weekdays.indexOf(fiscalDay.toLowerCase());
  const daysUntilNextFiscalDay = (fiscalIndex + 7 - startDay) % 7;
  

  // Adjust start date to the next fiscal day
  startDate.setDate(startDate.getDate() + daysUntilNextFiscalDay);

  // Loop for the specified period length
  for (let i = 0; i < periodLength * 4; i++) {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Sunday of the week

    // Format dates as YYYY-MM-DD
    const startDateString = formatDate(startDate);
    const endDateString = formatDate(endDate);

    const weekDates = [startDateString, endDateString];

    // const weekString = `${formatDate2(startDate)} ~ ${formatDate2(endDate)}`;
    const weekString = formatForWeekPicker(startDate, endDate);

    weeks.push([startDateString,  weekString]);

    // Move to the next fiscal day
    startDate.setDate(startDate.getDate() + 7);
  }

  return weeks;
}

const localTimeRangeFromTimeStamps = (startAt, endAt) => {
  const startTime = moment(startAt).local().clone();
  const endTime = moment(endAt).local().clone();
  const timeRange = `${startTime.format('LT')} - ${endTime.format('LT')}`;
  return timeRange;
}

const  shortHandTimeRangeForTimeStamps = (startAt, endAt) => {
  const start = moment(startAt).local().clone();
  const end = moment(endAt).local().clone();
  const formatString = 'hh:mma';
  const startString = start.format(formatString);
  const endString = end.format(formatString);

  const displayStartString = startString.substring(0, startString.length-1);
  const displayEndString = endString.substring(0, startString.length-1);

  const timeRange = `${displayStartString} - ${displayEndString}`;
  return timeRange;
}

const effectiveOnTimeStamp = (effectiveOn) => {
  const result = moment(effectiveOn).local().format('MMM DD, YYYY')
  return result;
}

const submittedTimeStamp = (time) => {
  const result = moment(time).local().format('ddd, MMM DD, YYYY @ LT');
  return result;
}

export { listCalendarWeeks, 
  localTimeRangeFromTimeStamps, 
  effectiveOnTimeStamp, 
  submittedTimeStamp, 
  shortHandTimeRangeForTimeStamps};
