import React from 'react';
import PropTypes from 'prop-types';
import { Prompt, Redirect } from 'react-router';
import { injectIntl, defineMessages } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import ProfileContainer from './components/Profile/ProfileContainer';
import { default as Styled } from './styled';

const editString = defineMessages({
  edit: {
    id: 'TeamProfileHeaderComponent.edit',
    defaultMessage: 'Edit',
  },
});

class TeamProfileHeaderComponent extends React.Component {
   constructor(props) {
    super(props);

    this.state ={
      edit: false,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      position: this.props.position,
      dash3: true,
    };

    this._isMounted = false;
    this.localStorageKey = 'edited-user';
    this.userId = '';
  }

  componentDidMount () {
    const { userSkills, userDepartments, locationSettings, user, userPrivileges, } = this.props;

    this._isMounted = true;
  }

  componentDidUpdate (prevProps, prevState) {
    const { userSkills,  locationId, user, userPrivileges } = this.props;

    if(prevProps.userSkills !== userSkills) {
      if(userSkills && userSkills.length > 0) {

      }
    }
  }

  componentWillUnmount () {
    const { dash3, } = this.state;
    this._isMounted = false;
    //if location changes, back to team page.
    if(window.location.href.indexOf('/team') > -1) {
      if(window.location.href.indexOf('/team-feed') === -1) {
        const backToPath = dash3 ? "/usermanagement" : "/team";
        window.location.pathname = backToPath;
      }
    }
  }

  _applyEditMode = (e) => {
    const { user, } = this.props;
    this.setState({
      edit: true,
    });
    const userId = user ? user.id : '';
    localStorage.setItem(this.localStorageKey, userId);
    //const readout = localStorage.getItem(this.localStorageKey);
    //console.log(readout);
  };

  _cancelEditMode = () => {
    this.setState({
      edit: false,
    });
  };

  render() {
    const { edit, dash3, } = this.state;
    const { intl , userSkills, userDepartments, locationSettings, userPrivileges, user, } = this.props;
    //const backToPath = dash3 ? "/usermanagement" : "/team";
    const backToPath = dash3 ? `/usermanagement?filter=${user ? user.id: ''}` : "/team";
    const backToTeam = (<FormattedMessage
                        id="TeamProfileHeaderComponent.backToTeam"
                        defaultMessage="Back to Team"
                      />);
    const backToUserManagement = (<FormattedMessage
                        id="TeamProfileHeaderComponent.backToUserManagement"
                        defaultMessage="Back to User Management"
                      />);
    const backTo = dash3 ? backToUserManagement : backToTeam;
    return (
      <Styled.HeaderWrap>
        <Styled.HeaderRow>
          <Styled.BackSection>
            <Styled.BackLink to={backToPath} replace>
              <Styled.BackArrow />
              {backTo}
            </Styled.BackLink>
          </Styled.BackSection>
        </Styled.HeaderRow>

        <Styled.HeaderRow>
          <ProfileContainer
            edit={edit}
            _cancelEditMode={this._cancelEditMode}
            userSkills={userSkills}
            userDepartments={userDepartments}
            locationSettings={locationSettings}
            userPrivileges={userPrivileges}
          />
          {!edit ? (
            <Styled.EditButton
              modifiers={['primary', 'small']}
              onClick={(e) => this._applyEditMode(e)}
            >
              {intl.formatMessage(editString.edit)}
            </Styled.EditButton>
          ) : null}
        </Styled.HeaderRow>
      </Styled.HeaderWrap>
    );
  }
}

export default injectIntl(TeamProfileHeaderComponent);
