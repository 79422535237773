
import React, { useState, useCallback } from 'react'
import OpenShiftDraggableCell from './OpenShiftDraggableCell';
import moment from 'moment';
import { useDrop } from 'react-dnd';
import utils from '../../../duck/utils';
import { Mixpanel } from '../../../../../Mixpanel';

export function OpenShiftDroppableCellBox ({ user, date, shiftsForDay, selectedDateToAddShift, selectedShiftToViewDetail, copyOpenShiftToDateDispatch, isCopyingShift, shiftToCopy, moveDraftOpenShiftDispatch, allUsers }) {

  const [isHoverButtonVisible, setIsHoverButtonVisible] = useState(false);

  const dropItem = (item) => {
    moveDraftOpenShiftDispatch(item, date);
    Mixpanel.track('Moved shift');
  }

  const mouseEnterCell = (e) => {
    setIsHoverButtonVisible(true); 
  }

  const mouseExitCell = (e) => {
    setIsHoverButtonVisible(false); 
  }

  const [{canDrop, isOver}, drop] = useDrop(() => ({
    accept: 'OpenShiftCell',

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),

    canDrop: (item, monitor) => {
      if( item != null){
        if( false == date.isSame(moment(item.start_at)) || user.owner_id != utils.currentOwnerIdForShift(item) ) {
          return true;
        }
      }
      return false;
    },
  
    drop: (item,monitor) => {
      dropItem(item);
    },

  }), [date]
)

  const shiftsCellForDay = useCallback((user, date, shifts, selectedDateToAddShift, selectedShiftToViewDetail, allUsers ) => {

    if (shifts.length > 0) {
      // Sort the shifts by start_at time in descending order
      const sortedShifts = shifts.sort((a, b) => moment(b.start_at).isBefore(moment(a.start_at)));

      return sortedShifts.map(shift => (
        <OpenShiftDraggableCell
          key={shift.id}
          user={user}
          date={date}
          shifts={[shift]} // Passing single shift in an array
          handleOpenShiftDetail={selectedShiftToViewDetail}
          selectedDateToAddShift={selectedDateToAddShift}
          allUsers = {allUsers}
          allUserShiftsOnDay = {sortedShifts}
        />
      ));
    } else {
      return (
        <OpenShiftDraggableCell
          user={user}
          date={date}
          handleOpenShiftDetail={selectedShiftToViewDetail}
          selectedDateToAddShift={selectedDateToAddShift}
          isCopyingShift={isCopyingShift}
          shiftToCopy={shiftToCopy}
          copyOpenShiftToDateDispatch={copyOpenShiftToDateDispatch}
        />
      );
    }
  }, [selectedShiftToViewDetail, copyOpenShiftToDateDispatch, isCopyingShift, shiftToCopy ]);

  const addButtonClicked = (item) => {
      selectedDateToAddShift(user, date);
  } 

  const showDottedFrame = (isOver == true && canDrop == true);

  return (
    <td className={shiftsForDay.length> 0 ? "shift-row__day-cell top-align" : "shift-row__day-cell middle-align"} ref={drop}  onMouseOverCapture={mouseEnterCell} onMouseLeave={mouseExitCell}>
      {shiftsCellForDay(user, date, shiftsForDay, selectedDateToAddShift, selectedShiftToViewDetail, allUsers )}
      {isHoverButtonVisible && (shiftsForDay.length > 0) && <button className="hover-add" onMouseDownCapture={addButtonClicked}>Add</button>}
      { showDottedFrame && <div className='dotted-drop-frame'></div>}
    </td>
  );
};

export default OpenShiftDroppableCellBox;