import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { localeChanged } from 'actions/dashboard-actions';
import { getBrowserLanguage } from 'helpers/localization';
import PageWrap from 'components/GeneralComponents/PageWrap';
import 'helpers/findIndex-shim';

import {
  AddAuthHeaders,
  updateUserAttributes,
  SuperLogin,
} from 'helpers/api-calls';
import {
  setAuthenticationCookie,
  setUserCookie,
  setLocaleCookie,
} from 'helpers/cookies';

import { DemoLoginFirstStage } from './LoginFirstStage/DemoLoginFirstStage';

import './LoginPage.scss';

import { Mixpanel } from '../../Mixpanel';

class DemoLoginPagei18n extends React.Component {
  state = {
    stage: 1,
    email: {
      value: '',
      valid: false,
    },
    phone: {
      value: '',
      valid: false,
    },
    error: null,
    mobilePinError: '',
    emailPasswordError: '',
    path: null,
  };

  messages = defineMessages({
    mobilePinError1: {
      id: 'LoginPage.mobilePinError1',
      defaultMessage:
        "Oops, that PIN doesn't match our records.Please try again.",
    },
    mobilePinError2: {
      id: 'LoginPage.mobilePinError2',
      defaultMessage:
        'Something went wrong while processing the information. Please try again.',
    },
    emailPasswordError1: {
      id: 'LoginPage.emailPasswordError1',
      defaultMessage: 'Wrong password, please try again.',
    },
    emailPasswordError2: {
      id: 'LoginPage.emailPasswordError2',
      defaultMessage:
        'There is no email associated with this account. Please try again.',
    },
    emailPasswordError3: {
      id: 'LoginPage.emailPasswordError3',
      defaultMessage:
        'Something went wrong while processing the information. Please try again.',
    },
    phoneError1: {
      id: 'LoginPage.phoneError1',
      defaultMessage:
        "We're unable to find that phone number. Please try again.",
    },
    phoneError2: {
      id: 'LoginPage.phoneError2',
      defaultMessage:
        'Something went wrong while processing the information. Please try again.',
    },
  });

  back = (e) => {
    e.preventDefault();
    this.setState({
      stage: 1,
      mobilePinError: '',
      emailPasswordError: '',
    });
  };

  _checkUserForLocations = (location) => {
    if (location && location.location) {
      return location.location.id;
    }
    return false;
  };

  _setLocale = (user) => {
    const { onLocaleChange, locale } = this.props;

    if (user.locale && user.locale !== locale) {
      // User has previously saved locale on server
      setLocaleCookie(user.locale);
      onLocaleChange(user.locale);
    } else if (locale !== getBrowserLanguage()) {
      // User has no saved locale setting on server and
      // changed locale before logging in

      setLocaleCookie(locale);
      updateUserAttributes({
        user_id: user.id,
        locale,
      });
    }

    // Sync cookie with local from state
    setLocaleCookie(locale);
  };

  identifyUserToTracker = (user) => {
    Mixpanel.identify(user.id);
  }

  _superLogin= (phone) => {

    if( phone.value.length == 0){return;}

    SuperLogin(phone.value)
      .then((response) => {
        const headers = {
          ...response.headers,
          'Auth-Category': 'phone_number',
          'Auth-Target': phone.value,
          location_id: this._checkUserForLocations(
            response.data.user.recent_user_privilege
          ),
        };
        setAuthenticationCookie(headers);
        setUserCookie(response.data.user);
        this.identifyUserToTracker(response.data.user);
        AddAuthHeaders();

        this._setLocale(response.data.user);

        if (
          this._checkUserForLocations(response.data.user.recent_user_privilege)
        ) {
          this.setState({
            path: {
              pathname: '/',
            },
          });
        } else {
          this.setState({
            path: {
              pathname: '/add-location',
            },
          });
        }
      })
      .catch((error) => {
        const { intl } = this.props;
        const { response } = error;

        if (response) {
          if (
            (response && response.status === 401) ||
            (response && response.status === 403)
          ) {
                      
            const msg=(<FormattedMessage  id= "LoginPage.mobilePinError3"
                               defaultMessage="Oops, that PIN doesn't match our records.Please try again."/>);
            this.setState({
              mobilePinError: msg,
            });
            
          }
        } else {
          this.setState({
            mobilePinError: intl.formatMessage(this.messages.mobilePinError2),
          });
        }
      });
  };

  render() {

    let env_check = (process.env.NODE_ENV === 'production' || window.location.hostname.includes("web.myshyft.com") )

    const { path, error } = this.state;

    if (path || env_check) {
      return <Redirect push to={path} />;
    }
    return (
      <PageWrap full opaque>
        <div className="login__wrap">
          <h2 className="login__header">
            <FormattedMessage
              id="LoginPage.heading"
              defaultMessage="Demo Dashboard"
            />
          </h2>

          <DemoLoginFirstStage
          _formSubmit={this._superLogin}
          error={error}
          />

        </div>
      </PageWrap>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
DemoLoginPagei18n.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = ({ localeReducer }) => ({
  locale: localeReducer.locale,
});

const mapDispatchToProps = (dispatch) => ({
  onLocaleChange: (locale) => {
    moment.locale(locale);
    dispatch(localeChanged(locale));
  },
});

const DemoLoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DemoLoginPagei18n));

export { DemoLoginPage, DemoLoginPagei18n };
