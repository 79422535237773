import styled from 'styled-components/macro';
import blocks from '../../blocks';
import { rgba } from 'polished';
import submitIcon from '../../blocks/ActionButtons/assets/send.svg';

const ActivePost = styled(blocks.Post)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.border};
`;

const CommentInputForm = styled.form`
  //width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  //padding-top: 5px;
  //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border: 1px solid ${({ theme }) => theme.palette.border};
  //border-radius: 6px;
  border-left: none;
  position: relative;

  min-height: 80px;


   @media (max-width: 360px) {
    padding-bottom: 1.5rem;
  }
`;

const CommentInput = styled.textarea`
  max-height: 160px;
  min-height: 40px;
  height: 40px;
  //padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.palette.border};
  transition: all 0.25s;
  background-repeat: no-repeat;
  background-position: 8px 10px;
  margin-bottom: 0px;
  //overflow-y: hidden;
  overflow-y: scroll;
  border: none;
  resize: none;
  padding-top: 7px;
  padding-left: 12px;
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 600;

  &:focus {
    border-color: ${({ theme }) => theme.palette.primary[0]};
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
    transition: all 0.25s;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.label};
    padding-top: 0px; // To align cursor and text
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 600;
  }

  @media (max-width: 210px) {
    min-width: 100px;
  }
  //border: 1px solid red;
`;

const SubmitButton = styled.button`
   height: 35px;
   width: 35px;
   background-repeat: no-repeat;
   background-image: url("${submitIcon}");
   background-position: center;
   background-size: 65%; /* 100 To fill the dimensions of the button */
   margin-top: 6px;
   //margin-left: 360px;
   //position: relative;

   position: absolute;
   right: 0.1rem;
   bottom: 0.1rem;


  &:hover {
    //color: white;
    //background-color: ${({ theme }) => theme.palette.primary[0]};
    outline: none;
    box-shadow: 0 2px 10px 2px rgba(195,192,192,.5);
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }
`;

 const ButtonsDiv = styled.div`
  //width: 400px; /* element will not resize if specifying width */
  //max-height: 40px;
  //min-height: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-top: 0px;
  margin-top: 0px;
  //border: 1px solid ${({ theme }) => theme.palette.primary[1]};
  border: 1px solid rgb(238,238,238);
  background-color: rgb(248,248,248);

  @media (max-width: 420px) {
    min-width: 200px;
  }
`;

const CloseButton = styled.button`
 height: 20px;
 width: 70px;
 margin-left: 100px;
 margin-top: 0px;
 margin-bottom: 0px;
 //border 1px solid red;
`;
const Warning = styled.h1`
  height: 60px;
  min-height: 60px;
  justify-content: center;
  text-align: center;
  
  //padding-top: 5px;
  //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border: 1px solid ${({ theme }) => theme.palette.border};
  //border-radius: 6px;
  //border-left: none;
  //border-right: none;
  position relative;
  margin-bottom: 0px;
  background-color: rgb(219,225,234);
  font-size: 1.88rem;
  font-weight: 300;
`;

const WinContainerConfirm = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  //width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  width: fit-content;
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2; 
  //overflow: scroll;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  //border: 1px solid red;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;


export default {
  ActivePost,
  CommentInputForm,
  CommentInput,
  SubmitButton,
  ButtonsDiv,
  CloseButton,
  Warning,
  WinContainerConfirm,
};
