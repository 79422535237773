import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import {
  formatTermsLinkWithLocale,
  formatPrivacyLinkWithLocale,
} from 'helpers/formatting';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { LanguageSelector } from '@shyft/acorns';
import { APP_VERSION } from '../constants';
import { checkForAuthentication } from 'helpers/cookies';
import { updateUserAttributes } from 'helpers/api-calls';
import {
  setLocaleCookie,
  getUidCookie,
  getLocationCode,
} from 'helpers/cookies';

const LanguageSelectorWrap = styled.div`
  display: inline-block;
  border-right: 1px solid #ababab;
  margin-right: 10px;
  padding-right: 15px;

  button {
    font-size: 12px;
  }
`;

class Footer extends React.PureComponent {
  contactUsButtonClick = () => {
  };

  termsButtonClick = () => {
  };

  featureRequestButtonClick = () => {
  };

  privacyPolicyButtonClick = () => {
  };

  subscriptionButtonClick = () => {
  };

  _handleLanguageChange = (locale) => {
    const { onLocaleChange, loadSetupActions } = this.props;
    const locationId = getLocationCode();

    setLocaleCookie(locale);
    moment.locale(locale);
    onLocaleChange(locale);

    if (locationId) {
      // User is auth'd
      loadSetupActions(locationId);
      updateUserAttributes({
        user_id: getUidCookie(),
        locale,
      });
    }

    //if language changes, make sure filter=pending removed.
    if (window.location.href.indexOf('filter=pending') > -1) {
      const backToPath = "/usermanagement";
      window.history.replaceState(null, '', backToPath);
    }
  };

  render() {
    const { setupActions, supportedLocales, locale } = this.props;
    const termsLink = formatTermsLinkWithLocale(setupActions, locale);
    const featureRequestLink = "https://forms.gle/RQTG1soZ64TjQW9h7";
    const privacyLink = formatPrivacyLinkWithLocale(setupActions, locale);
    const authenticated = checkForAuthentication();
    if (authenticated) {
      return (
        <footer className="footer">
          <section className="footer-detail--container">
            <span className="footer-detail--link">
              {/* <LanguageSelectorWrap>
              <LanguageSelector
                _languageSelectHandler={this._handleLanguageChange}
                languagesArray={supportedLocales}
                currentLang={locale}
              />
            </LanguageSelectorWrap> */}

              <a
                className="footer-detail--link-ind"
                rel="noopener noreferrer"
                target="_blank"
                href={privacyLink}
                onClick={this.privacyPolicyButtonClick}
              >
                <FormattedMessage
                  id="Footer.privacyPolicy"
                  defaultMessage="Privacy Policy"
                />
              </a>
              <a
                className="footer-detail--link-ind"
                rel="noopener noreferrer"
                target="_blank"
                href={termsLink}
                onClick={this.termsButtonClick}
              >
                <FormattedMessage
                  id="Footer.terms"
                  defaultMessage="Terms of Service"
                />
              </a>
              <span className="footer-detail--link-ind">{`v${APP_VERSION}`}</span>
            </span>
          </section>
          <section className="footer-detail--feature-container">
            {authenticated && <span className="footer-detail--link-ind">
              <FormattedMessage
                id="Footer.feature"
                defaultMessage="If you have additional product suggestions, please"
              />
              &nbsp;
              <a
                className="footer-detail--link-ind"
                rel="noopener noreferrer"
                target="_blank"
                href={featureRequestLink}
                onClick={this.featureRequestButtonClick}
              >
                <FormattedMessage
                  id="Footer.requesttext"
                  defaultMessage="submit a feature request"
                />
              </a>
            </span>
            }
          </section>
        </footer>
      );
    } else {
      return null;
    }
  }
}

export default Footer;
