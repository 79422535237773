import constants from '../constants';
import uniqBy from 'lodash/uniqBy';
import utils from './utils';

const applyFiltersToUsers = (users, dept, group, jobTitle, email, employeeId, skill) => {
  const usersAfterApplyingDeptFilter = filterUsersWithDepartment(users, dept);
  const usersAfterApplyingGroupFilter = filterUsersWithGroup( usersAfterApplyingDeptFilter, group);
  const usersAfterApplyingJobTitleFilter = filterUsersWithJobTitle( usersAfterApplyingGroupFilter, jobTitle);
  const usersAfterApplyingEmailFilter = filterUsersWithEmail( usersAfterApplyingJobTitleFilter, email);
  const usersAfterApplyingEmployeeIdFilter = filterUsersWithEmployeeId( usersAfterApplyingEmailFilter, employeeId);
  const usersAfterApplyingSkillFilter = filterUsersWithSkill(usersAfterApplyingEmployeeIdFilter, skill);
  return usersAfterApplyingSkillFilter; 
}

const filterUsersWithDepartment = (users, dept) => {
    if( dept == null ){
      return users;
    }else{
      const filtered = users.filter( (user) => { return dept.user_ids.includes(user.owner_id); });
      return filtered;
    }
}

const filterUsersWithGroup = (users, group) => {
  if( group == null ){
    return users;
  }else{
    const filtered = users.filter( (userPrivilege) => { 
      if ( userPrivilege.status == 'unregistered') {
        return false;
      }else{
        return userPrivilege.user.channel_ids.includes(group.id); }
      }
    );
    return filtered;
  }
}

const filterUsersWithJobTitle = (users, jobTitle) => {
  if ( jobTitle == null ) { 
    return users; 
  } else {
    const filtered = users.filter( (uPriv) => { return uPriv.position && uPriv.position.toLowerCase().includes(jobTitle.toLowerCase()); } );
    return filtered;
  }
}

const filterUsersWithEmail = (users, email) => {
  if ( email == null ){
    return users;
  }else{
    const filtered = users.filter( (uPriv) => {return uPriv.email === email;} );
    return filtered;
  }
}

const filterUsersWithEmployeeId = (users, employeeId) => {
  if ( employeeId == null ){
    return users;
  }else{
    const filtered = users.filter( (uPriv) => {return uPriv.badge_id == employeeId;} );
    return filtered;
  }
}

const filterUsersWithSkill = (users, skill) => {
  if ( skill == null ){
    return users;
  }else{
    return users.filter( (uPriv) => {return skill.user_ids.includes(uPriv.owner_id)});
  }
}

const filterUsersByCategoryType = (type, item, index,  managers, associates, removedUsers, pendingUsers, departments, channels, skills, unregisteredUsers ) => {
    const { categoryTypes } = constants;
    const activeUsers = managers.concat(associates);

    let total = [];
    if (type === categoryTypes.LOCATION) {
      total = activeUsers.concat(pendingUsers, removedUsers).concat(unregisteredUsers);
    } else {
      total = activeUsers.concat(pendingUsers);
    }

    const noDuplicated = uniqBy(total, 'id'); // Remove duplicates
    total = noDuplicated;

    if (!type) {
      return total;
    }
    //WS-1953: removed "All"

    let filtered = [];
    switch (type) {
      case categoryTypes.LOCATION:
        if (index === 0) {
          filtered = total.filter((user) => user && (user.status === 'active' || user.status === 'unregistered'));
        } else if (index === 1) {
          filtered = total.filter((user) => user && user.status === 'active');
        } else if (index === 2) {
          filtered = total.filter((user) => user && user.status === 'unregistered');
        } else if (index === 3) {
          filtered = total.filter((user) => user && user.status === 'pending');
        } else if (index === 4) {
          filtered = removedUsers;
        }
        break;
      case categoryTypes.DEPARTMENTS:
        if (departments && departments.length > 0 && item) {
          const foundDepts = departments.filter((dept) => dept.id === item.id);
          if (foundDepts && foundDepts.length > 0) {
            let deptUsers = [];
            for (let i = 0; i < foundDepts[0].user_ids.length; i++) {
              const found = total.filter((user) => user.owner_id === foundDepts[0].user_ids[i]);
              if (found && found.length > 0) {
                deptUsers.push(found[0]);
              }
            }
            filtered = deptUsers;
          }
        } else {
          filtered = total;
        }
        break;
      case categoryTypes.GROUPS:
        if (channels && channels.length > 0 && item) {
          const foundChannels = channels.filter((channel) => channel.id === item.id)
          if (foundChannels && foundChannels.length > 0) {

            const usersHaveId = total.filter((user) => user.user && user.user.channel_ids && user.user.channel_ids.includes(item.id));
            if (usersHaveId && usersHaveId.length > 0) {
              filtered = usersHaveId;
            }
          }
        } else {
          filtered = total;
        }
        break;
      case categoryTypes.SKILLS:
        if (skills && skills.length > 0 && item) {
          const idx1 = skills.findIndex((skill) => skill.skill_content === item.name
            || skill.skill_content === item.skill_content)
          if (idx1 >= 0) {
            const skill = skills[idx1];
            let skillUsers = [];
            for (let i = 0; i < total.length; i++) {
              for (let j = 0; j < skill.user_ids.length; j++) {
                if (total[i].owner_id === skill.user_ids[j]) {
                  skillUsers.push(total[i]);
                }
              }
            }
            filtered = skillUsers;
          }
        } else {
          filtered = total;
        }
        break;
    }

    return filtered;
  };

// Legacy code, needs refactoring
const searchUsers = (term, users ) => {
    const { sortTypes, } = constants;

    let results = [];
    let isName = false;
    let isPosition = false;
    let isUserRole = false;
    let isStatus = false;
    if (term && term.length > 0) {
      const term1 = term.toLowerCase();
      if (term1.includes(' ')) {
        const strings = term1.split(' ');
        if (strings && strings.length === 2) {
          const fullName = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(strings[0]) &&
            user.last_name && user.last_name.toLowerCase().includes(strings[1]));
          if (fullName && fullName.length > 0) {
            results = fullName;
            isName = true;
          } else {
            const fullName1 = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(strings[1]) &&
              user.last_name && user.last_name.toLowerCase().includes(strings[0]));
            if (fullName1 && fullName1.length > 0) {
              results = fullName1;
              isName = true;
            } else {
              const foundInPosition = users.filter((user) => user.position &&
                (user.position.toLowerCase().includes(strings[0]) || user.position.toLowerCase().includes(strings[1])));
              if (foundInPosition && foundInPosition.length > 0) {
                results = foundInPosition;
                isPosition = true;
              }
              const foundInPosition1 = users.filter((user) => user.position &&
                (user.position.toLowerCase().includes(strings[1]) || user.position.toLowerCase().includes(strings[0])));
              if (foundInPosition && foundInPosition.length > 0) {
                results = results.concat(foundInPosition1);
                isPosition = true;
              }
              if (foundInPosition && foundInPosition.length === 0 && foundInPosition1 && foundInPosition1.length === 0) {
                if (strings[0] === 'team' && strings[1] === 'member') {
                  results = users.filter((user) => user.status && !user.is_admin);
                  isUserRole = true;
                } else if (strings[1] === 'team' && strings[0] === 'member') {
                  results = users.filter((user) => user.status && user.is_admin);
                  isUserRole = true;
                }
              }
            }
          }
        } else if (strings && strings.length > 2) {
          //Possiblly this is position
          let contains1Ward = [];
          let contains2Wards = [];
          let contains3Wards = [];
          for (let i = 0; i < strings.length; i++) {
            const found = users.filter((user) => user.position && user.position.toLowerCase().includes(strings[i]));
            contains1Ward = contains1Ward.concat(found);
          }
          if (contains1Ward.length > 0) {
            if (strings.length <= 3) {
              contains2Wards = contains1Ward.filter((user) => user.position && user.position.toLowerCase().includes(strings[1]));
              contains3Wards = contains2Wards.filter((user) => user.position && user.position.toLowerCase().includes(strings[2]));
            }
            if (contains3Wards && contains3Wards.length > 0) {
              results = contains3Wards;
            } else if (contains2Wards && contains2Wards.length > 0) {
              results = contains2Wards;
            } else {
              results = contains1Ward;
            }
            isPosition = true;
          }
        }
      } else {
        const firstNameContains = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(term1));
        if (firstNameContains && firstNameContains.length > 0) {
          results = firstNameContains;
          isName = true;
          const alsoInLastName = users.filter((user) => user.last_name && user.last_name.toLowerCase().includes(term1));
          if (alsoInLastName && alsoInLastName.length > 0) {
            results = results.concat(alsoInLastName);
          }
          const alsoInPosition = users.filter((user) => user.position && user.position.toLowerCase().includes(term1));
          if (alsoInPosition && alsoInPosition.length > 0) {
            results = results.concat(alsoInPosition);
            isPosition = true;
          }
          if (term1.toLowerCase() === 'admin') {
            results = users.filter((user) => user.status && user.is_admin);
            isName = false;
            isUserRole = true;
            isPosition = false;
          }
        } else {
          const lastNameContains = users.filter((user) => user.last_name && user.last_name.toLowerCase().includes(term1));
          if (lastNameContains && lastNameContains.length > 0) {
            results = lastNameContains;
            isName = true;
            const alsoInFirstName = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(term1));
            if (alsoInFirstName && alsoInFirstName.length > 0) {
              results = results.concat(alsoInFirstName);
            }
            if (term1.toLowerCase() === 'admin') {
              results = users.filter((user) => user.status && user.is_admin);
              isName = false;
              isUserRole = true;
            }
          } else {
            const positionContains = users.filter((user) => user.position && user.position.toLowerCase().includes(term1));
            if (positionContains && positionContains.length > 0) {
              results = positionContains
              isPosition = true;
            } else {
              const statusContains = users.filter((user) => user.status && user.status.toLowerCase().includes(term1));
              if (statusContains && statusContains.length > 0) {
                results = statusContains;
                isStatus = true;
              } else {
                if (term1.toLowerCase() === 'admin') {
                  results = users.filter((user) => user.status && user.is_admin);
                  isUserRole = true;
                } else if (term1.toLowerCase().includes('team') || term1.toLowerCase().includes('member')) {
                  results = users.filter((user) => user.status && !user.is_admin);
                  isUserRole = true;
                } else {
                  if (term1.toLowerCase() === 'terminated') {
                    const terminated = users.filter((user) => user.status && user.status !== 'active' && user.status !== 'pending');
                    if (terminated && terminated.length > 0) {
                      results = terminated;
                      isUserRole = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
      const found = uniqBy(results, 'id'); // Remove duplicated
      let type = '';
      if (isName) {
        type = sortTypes.FIRST_NAME;
      }
      if (isPosition) {
        type = sortTypes.POSITION;
      }
      if (isStatus) {
        type = sortTypes.STATUS;
      }
      if (isUserRole) {
        type = sortTypes.USER_ROLE;
      }
      if (isName && isPosition) {
        type = sortTypes.FIRST_NAME;
      }
      const sorted = utils.sortUsers(found, type, true);
      return sorted;
    } else {
        return users;
    }
  }

  export default {
    searchUsers,
    filterUsersByCategoryType,
    filterUsersWithDepartment,
    filterUsersWithGroup,
    applyFiltersToUsers,
  }