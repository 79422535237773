import { createSlice } from '@reduxjs/toolkit';

const unregisteredUsersSlice = createSlice({
    name: 'unregisteredUsers',
    initialState: {
        users: [],
        loading: false,
    },
    reducers: {
        requestUnregisteredUsers: (state, action) => {
            state.loading = true;
        },

        receiveUnregisteredUsers: (state, action) => {
            state.users = action.payload.users.map((item) => {
                return { ...item, status: 'unregistered' };
            });
            state.loading = false;
        },

        removeUnregisteredUser: (state, action) => {
            const userIndex = state.users.findIndex(
                (user) => user.id === action.payload.id
            );
            state.users.splice(userIndex, 1);
        },

        appendUnregisteredUser: (state, action) => {
            state.users = state.users.concat(action.payload.user);
        },

        updateUnregisteredUserNumber: (state, action) => {
            const userIndex = state.users.findIndex(
                (user) => user.id === action.payload.user.id
            );
            state.users[userIndex] = action.payload.user;
            console.log(action.payload.user);
        },

        updateUnregisteredUserBadgeID: (state, action) => {
            const userIndex = state.users.findIndex(
                (user) => user.id === action.payload.user.id
            );
            state.users[userIndex] = action.payload.user;
            console.log(action.payload.user);
        }
    }
});

export default unregisteredUsersSlice;