import React, { useEffect, useRef } from 'react';
import presenter from "pages/ScheduleCalendar/duck/presenter";
import ShiftApplicantCommentRow from "./ShiftApplicantCommentRow";
import applicantLogic from "pages/ScheduleCalendar/duck/applicantLogic";
import './ShiftApplicantCommentsComponent.scss';
import { isValidObject } from 'helpers/validation';
import { Mixpanel } from '../../../../../Mixpanel';

export function ShiftApplicantCommentsComponent({applicant, applicantCommentsDictionary, fecthApplicantPostsDispatch, addApplicantCommentDispatch, allUsers, handleBackButtonClick, applicantId, applicantUserId}) {

    const commentInputRef = useRef(null)

    const getRealApplicantID = () => {
        if( isValidObject(applicant) == true ){ return applicant.id }else { return applicantId }
    }

    const realApplicantId = getRealApplicantID() 

    useEffect(() => { fecthApplicantPostsDispatch(realApplicantId); }, []);

    const url = presenter.applicantAvatar(applicant);
    const nameAndPosition = presenter.getApplicantNameAndPosition(applicant);
    const tagline = presenter.getOpenShiftApplicantTagline(applicant);

    const name = presenter.getNameFromApplicantOrUserId(applicant, applicantUserId, allUsers);
    const messageX = presenter.messageX(name);
    const comments = applicantLogic.commentsForApplicant(realApplicantId, applicantCommentsDictionary);

    const commentRows = comments.map((comment) => {
        const userObject = presenter.getUserFromId(comment.owner_id, allUsers);
        return <ShiftApplicantCommentRow key={comment.id} comment={comment} user={userObject} />
    });

    const handleSendComment = () => {
        addApplicantCommentDispatch(realApplicantId, commentInputRef.current.value);
        Mixpanel.track('Send applicant comment', { applicantId: realApplicantId });
    }

  return (
    <div className="applicant-comments-container">
        {isValidObject(applicant) == true &&
            <div className="comments-header">
                { handleBackButtonClick !== null && <img className='back-button' src='/icons/back-chevron.svg' onClick={handleBackButtonClick}></img>} 
                <img className='avatar' src={url}/>
                    <div className='applicant-info'>
                        <label className='name-position'> {nameAndPosition}</label>
                        <label className='tagline'> {tagline} </label>
                    </div>
            </div>}

        <div className="comments-list">
            {commentRows}
        </div>
        <div className="comment-box">
            <input ref={commentInputRef} className="comment-input" type='text' placeholder={messageX}/>
            <img onClick={handleSendComment} className='send-button' src='icons/send.svg'></img>
        </div>
    </div>
  );
}

export default ShiftApplicantCommentsComponent;