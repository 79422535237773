import { createSlice } from '@reduxjs/toolkit';

const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    data: [],
    loading: true,

  },
  reducers: {
    hydratePlans: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    requestPlans: (state, action) => {
      state.loading = true;
    },
  },
});

export default plansSlice;
