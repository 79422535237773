import { createSlice } from '@reduxjs/toolkit';

const pendingShifts = createSlice({
  name: 'pendingShifts',
  initialState: {
    data: [],
    loading: true,
    shiftsWithApplicants: 0,
    fetchFailed: false,
  },
  reducers: {
    hydratePendingShifts: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.shiftsWithApplicants = 0;
      state.fetchFailed = false;
    },
    addMorePendingShifts: (state, action) => {
      //let newData = [...state.data];
      //newData.concat(action.payload);
      state.data = state.data.concat(action.payload);
    },
    createPendingShift: (state, action) => {
      state.data.unshift(action.payload);
    },
    deletePendingShift: (state, action) => {
      state.data = state.data.filter((shift) => shift.id !== action.payload);
    },
    requestPendingShifts: (state, action) => {
      state.loading = true;
    },
    updatePendingShiftSegments: (state, action) => {
      const shiftIndex = state.data.findIndex(
        (shift) => action.payload[0] && shift.id === action.payload[0].schedule_element_id
      );

      state.data[shiftIndex].segments = action.payload;
    },
    updateShiftApplicants: (state, action) => {
      const shiftIndex = state.data.findIndex(
        (shift) => action.payload[0] && shift.id === action.payload[0].schedule_element_id
      );
      if (shiftIndex >= 0) {
        const newData = [...state.data];
        newData[shiftIndex].applicants = action.payload;
        state.data = newData;
        //const msg = `${action.payload[0].schedule_element_id} updated state: applicants`;
        //console.log(msg);
        state.shiftsWithApplicants += 1;
      }
      else {
        if (action.payload.length === 0) {
          const msg = `${action.payload[0].schedule_element_id} : shift not find in state`;
          console.log(msg);
        }
      }
    },

    updateShiftApplicantStatus: (state, action) => {
      const shiftIndex = state.data.findIndex(
        (shift) => shift.id === action.payload.shiftId
      );
      if (shiftIndex >= 0) {
        const newData = [...state.data];
        //removing applicant Id from pending array
        newData[shiftIndex].current_applicants_pending_ids = state.data[shiftIndex].current_applicants_pending_ids.filter(id => id !== action.payload.applicantId);

        //adding applicant Id to correct array

        if (action.payload.status === 'picked') {
          newData[shiftIndex].current_applicants_approved_ids = [
            ...state.data[shiftIndex].current_applicants_approved_ids,
            action.payload.applicantId,
          ];
        } else {
          newData[shiftIndex].current_applicants_denied_ids = [
            ...state.data[shiftIndex].current_applicants_denied_ids,
            action.payload.applicantId,
          ];
        }

        state.data = newData;

        // if (state.data[shiftIndex].applicants) {
        //   const applicantIndex = state.data[shiftIndex].applicants.findIndex(
        //     (applicant) => applicant.id === action.payload.applicantId
        //   );
        //   if (applicantIndex >= 0) {
        //     const newData = [...state.data];
        //     newData[shiftIndex].applicants[applicantIndex].status = action.payload.status;
        //     if (action.payload.status === 'picked') {
        //       newData[shiftIndex].available_shifts_covered += 1;
        //     }
        //     state.data = newData;
        //   } else {
        //     const msg = `updateShiftApplicantStatus: shift = ${state.data[shiftIndex].id}, applicant not found.`;
        //     console.log(msg)
        //   }
        // }
      } else {
        const msg = `updateShiftApplicantStatus: shift ${state.data[shiftIndex].id} not found.`;
        console.log(msg)
      }
    },

    updateShiftStatus: (state, action) => {
      const shiftIndex = state.data.findIndex(
        (shift) => shift.id === action.payload.id
      );
      if (shiftIndex >= 0) {
        const newData = [...state.data];
        newData[shiftIndex].trade_status = action.payload.status;
        state.data = newData;
      }
    },

    updateShiftApproved: (state, action) => {
      const shiftIndex = state.data.findIndex(
        (shift) => shift.id === action.payload
      );
      if (shiftIndex >= 0) {
        const newData = [...state.data];
        newData[shiftIndex].available_shifts_covered += 1;
        state.data = newData;
      }
    },
    failedToGetApplicants: (state, action) => {
      state.fetchFailed = true;
    },
    resetFetchFailed: (state, action) => {
      state.fetchFailed = false;
    },
  },
});

export default pendingShifts;
