import React from 'react';
import { connect } from 'react-redux';
import { PubNubConsumer } from 'pubnub-react';

import {
  teamFeedOperations,
  teamFeedSelectors,
  teamFeedActions,
} from '../../duck';

import CommentsComponent from './CommentsComponent';
/*
const mapStateToProps = ({ teamFeedReducer, teamFeedReducer: { active }, appReducer, }) => ({
  comments: teamFeedSelectors.getOrderedComments(teamFeedReducer),
  active,
  activePost: teamFeedSelectors.getActivePost(teamFeedReducer),
  activeSidePane: teamFeedSelectors.getActiveSidePane(teamFeedReducer),
  activeChannel: teamFeedSelectors.getActiveChannel(teamFeedReducer),
  allowCreatePost: appReducer.location.settings.cfg__group_chat__post__create,
  allUsers: appReducer.allUsers.users,
  onlineUsers: appReducer.onlineUsers.users,
});
*/
const mapStateToProps = (state) => {
  const {
    teamFeedReducer,
    teamFeedReducer: { active },
    setupReducer,
    appReducer,
    locationReducer,
  } = state;

  return {
    comments: teamFeedSelectors.getOrderedComments(teamFeedReducer),
    active,
    activePost: teamFeedSelectors.getActivePost(teamFeedReducer),
    activeSidePane: teamFeedSelectors.getActiveSidePane(teamFeedReducer),
    activeChannel: teamFeedSelectors.getActiveChannel(state),
    allowCreatePost: appReducer.location.settings.cfg__group_chat__post__create,
    allUsers: appReducer.allUsers.users,
    onlineUsers: appReducer.onlineUsers.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  const fetchComments = (post_id) => {
    dispatch(teamFeedOperations.fetchComments(post_id));
  };

  const fetchMoreComments = (channel_id, page) => {
    dispatch(teamFeedOperations.fetchMoreComments(channel_id, page));
  };

  const setActiveComment = (id) => {
    dispatch(teamFeedActions.updateActiveComment(id));
  };

  const setActiveDelete = ({ id, type }) => {
    dispatch(teamFeedActions.updateActiveDelete({ id, type }));
  };

  const createComment = (content, source_id) => {
    dispatch(teamFeedOperations.createComment(content, source_id));
  };

  const resetActiveSidePane = () => {
    dispatch(teamFeedActions.updateActiveSidePane(''));
  };

  const fetchPostLikeList = (post_id) => {
    dispatch(teamFeedOperations.getPostLikeList(post_id));
  };

  const fetchPostReaderList = (post_id) => {
    dispatch(teamFeedOperations.getPostReaderList(post_id));
  };

  const setActivePost = (id, channel_name) => {
    dispatch(teamFeedActions.updateActivePost({id, channel_name}));
    dispatch(teamFeedActions.clearReadReceipts());
  };

  const setActiveSidePane = (pane) => {
    dispatch(teamFeedActions.updateActiveSidePane(pane));
  };

  const deleteComment = (commentId, post) => {
    dispatch(teamFeedOperations.deleteComment(commentId, post));
  };

  return {
    fetchComments,
    fetchMoreComments,
    setActiveDelete,
    setActiveComment,
    createComment,
    resetActiveSidePane,
    fetchPostLikeList,
    fetchPostReaderList,
    setActivePost,
    setActiveSidePane,
    deleteComment,
  };
};

/*
const CommentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsComponent);
*/


const CommentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  return (
    <PubNubConsumer>
      {(client) => {
        const { fetchComments,
                fetchMoreComments,
                setActiveDelete,
                setActiveComment,
                createComment,
                resetActiveSidePane,
                comments,
                active,
                activePost,
                activeSidePane,
                activeChannel,
                allowCreatePost,
                allUsers,
                onlineUsers,
                fetchPostLikeList,
                fetchPostReaderList,
                deleteComment,
                } = props;

        return (
          <CommentsComponent
            pubnub={client}
            fetchComments={fetchComments}
            fetchMoreComments={fetchMoreComments}
            setActiveDelete={setActiveDelete}
            setActiveComment={setActiveComment}
            createComment={createComment}
            resetActiveSidePane={resetActiveSidePane}
            comments={comments}
            active={active}
            activePost={activePost}
            activeSidePane={activeSidePane}
            activeChannel={activeChannel}
            allowCreatePost={allowCreatePost}
            allUsers={allUsers}
            onlineUsers={onlineUsers}
            fetchPostLikeList={fetchPostLikeList}
            fetchPostReaderList={fetchPostReaderList}
            deleteComment={deleteComment}
          />
        );
      }}
    </PubNubConsumer>
  );
});

export default CommentsContainer;
