import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { ProfileThumb } from '@shyft/acorns';

import { createFullName } from 'helpers/formatting';

import blocks from '../../blocks';
import elements from '../../elements';

import readCheckbox from './assets/read-checkbox.svg';
import unreadCheckbox from './assets/unread-checkbox.svg';
import './ReadReceiptsComponent.scss';
import styled from './styled';


const useStyles = makeStyles({
  root: {},
  userWrap: {
    position: 'relative',
    display: 'flex',
    padding: '1rem',
    backgroundColor: 'white',

    '&:hover': {
      backgroundColor: '',
    },
  },
  listSeparator: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#4d4d4d',
    padding: '1rem 1rem 0 1rem',
  },
  avatarWrap: {
    width: '44px',
    marginRight: '1rem',
    flex: '0 0 44px',
  },

  detailsWrap: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  detailsPosition: {
    color: '#979797',
    fontSize: '14px',
  },

  closeButton: {
    height: '20px',
    width: '70px',
    marginLeft: '120px',
    marginTop: '0px',
    marginBottom: '0px',
  },
});

const UserDetails = ({ user }) => {
  const classes = useStyles();

  return (
    <div className={classes.detailsWrap}>
      <div>{createFullName(user)}</div>
      <div className={classes.detailsPosition}>{user.position || ''}</div>
    </div>
  );
};

const Avatar = ({ profileImage }) => {
  const classes = useStyles();

  return (
    <div className={classes.avatarWrap}>
      <ProfileThumb thumbUrl={profileImage} width="44px" />
    </div>
  );
};

const StatusIndicator = ({ read }) => (
  <img src={read ? readCheckbox : unreadCheckbox} />
);

const UserLine = ({ user, readReceipt }) => {
  const classes = useStyles();

  return (
    <div className={classes.userWrap}>
      <Avatar profileImage={user.profile_image_thumb_url} />
      <UserDetails user={user} />
      <StatusIndicator read={readReceipt} />
    </div>
  );
};

/*
const ReadReceiptsComponent = ({ receipts, activeSidePane, resetActiveSidePane }) => {
  const classes = useStyles();
  var requestClose = false;

  if(requestClose){
    requestClose = false;
    return null;
  }

  return (
    <elements.ColumnWrap>
      <blocks.Header>
        <FormattedMessage
          id="ReadReceipts.headerLabel"
          defaultMessage="Team Members"
        />
          <button className={classes.closeButton}
                  onClick={() => {
                    requestClose = true;
                    resetActiveSidePane();
            }}>
             <FormattedMessage id="ReadReceipts.close" defaultMessage="X Close"/>
          </button>
      </blocks.Header>
      <elements.ScrollWrap isLoading={false}>
        {!receipts.loading || <elements.Loading />}
        <div className={classes.listSeparator}>
          <div className={classes.listSeparator}>
            <FormattedMessage id="ReadReceipts.readLabel" defaultMessage="Read" />{' '}
            ({receipts.read.length})
          </div>
          {receipts.read.map((receipt) => (
            <UserLine user={receipt} readReceipt={true} />
          ))}
          
          <FormattedMessage
            id="ReadReceipts.unreadLabel"
            defaultMessage="Unread"
          />{' '}
          ({receipts.unread.length})
        </div>
        {receipts.unread.map((receipt) => (
          <UserLine user={receipt} />
        ))}

      </elements.ScrollWrap>
    </elements.ColumnWrap>
  );
};

const handleClose = () => {
  
};

export default ReadReceiptsComponent;

*/

const sha1 = require('js-sha1');

class ReadReceiptsComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={
      closePinPane: false,
      readList: [],
      channelMembers: [],
      unReaders: [],
    };
    this.handleClose = this.handleClose.bind(this);

    this.closeReadPane = false;
    this._isMounted = false;
  }

  componentDidMount() {
    const {activePost, allUsers, onlineUsers, activeChannel, } = this.props;
    this._isMounted = true;

    if(activePost && activePost.readList) {
      if(activePost.readList.length > 0) {
        let readers = [];
        if(allUsers) {
          for(let j=0; j<activePost.readList.length; j++) {
            const reader = allUsers.filter((person) => person.owner_id === activePost.readList[j]);
            if(reader && reader.length > 0) {
              readers.push(reader[0]);
            }
          }
        }
        this.setState({readList: readers});
      }
    }

    if(activeChannel && allUsers) {
      const channelUsers = allUsers.filter((user) =>  
        user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
      if(channelUsers && channelUsers.length > 0) {
        this.setState({channelMembers: channelUsers});
        let filtered = [];
        for(let i=0; i< channelUsers.length; i++) {
          if(activePost && activePost.readList && !activePost.readList.includes(channelUsers[i].owner_id)) {
            filtered.push(channelUsers[i]);
          }
        }
        this.setState({unReaders: filtered});
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {activePost, allUsers, onlineUsers, activeChannel, } = this.props;

    if(prevProps.allUsers !== allUsers) {
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
          let filtered = [];
          for(let i=0; i< channelUsers.length; i++) {
            if(activePost && activePost.readList && !activePost.readList.includes(channelUsers[i].owner_id)) {
              filtered.push(channelUsers[i]);
            }
          }
          this.setState({unReaders: filtered});
        }
      }
    }

    if(prevProps.activeChannel !== activeChannel) {
      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers) {
          this.setState({channelMembers: channelUsers});
          let filtered = [];
          for(let i=0; i< channelUsers.length; i++) {
            if(activePost && activePost.readList && !activePost.readList.includes(channelUsers[i].owner_id)) {
              filtered.push(channelUsers[i]);
            }
          }
          this.setState({unReaders: filtered});
        }
      }
    }

    if(prevProps.activePost !== activePost) {
      if(activePost && activePost.readList) {
        if(activePost.readList.length > 0) {
          let readers = [];
          if(allUsers) {
            for(let j=0; j<activePost.readList.length; j++) {
              const reader = allUsers.filter((person) => person.owner_id === activePost.readList[j]);
              if(reader && reader.length > 0) {
                readers.push(reader[0]);
              }
            }
          }
          this.setState({readList: readers});
        }
      }

      if(activeChannel && allUsers) {
        const channelUsers = allUsers.filter((user) =>  
          user.user &&  user.status === 'active' && user.user.channel_ids && user.user.channel_ids.includes(activeChannel.id));
        if(channelUsers && channelUsers.length > 0) {
          this.setState({channelMembers: channelUsers});
          let filtered = [];
          for(let i=0; i< channelUsers.length; i++) {
            if(activePost && activePost.readList && !activePost.readList.includes(channelUsers[i].owner_id)) {
              filtered.push(channelUsers[i]);
            }
          }
          this.setState({unReaders: filtered});
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserUUID = (userId) => {
    return `user.${sha1(`user.${userId}`)}`;
  };

  handleClose(event) {
    const {resetActiveSidePane} = this.props;

    //this.setState({closePinPane: false});
    this.closeReadPane = true;
    resetActiveSidePane();
    event.preventDefault();
  }

  render () {
    //const {receipts, activeSidePane,} = this.props;
    const {receipts, activeSidePane, activePost, allUsers, onlineUsers, } = this.props;
    const { readList, channelMembers, unReaders, } = this.state;

    const title = (<FormattedMessage id="ReadReceipts.readBy" defaultMessage="Read By" />);
    const approvedBadge = '/icons/verified-badge-icon.svg';
    const notRead = (<FormattedMessage
            id="ReadReceipts.unreadLabel"
            defaultMessage="Unread"
          />);
    const read = (<FormattedMessage
            id="ReadReceipts.readLabel"
            defaultMessage="Read"
          />);

    const numReaders = `(${readList ? readList.length : 0})`;
    const numOthers = `(${unReaders ? unReaders.length : 0})`;
    const others = unReaders.map((user, index) => {
      let profileUrl = '/icons/default-profile-thumb.svg';
      if(user && user.profile_image_thumb_url) {
        profileUrl = user.profile_image_thumb_url;
      }
      const showAdminBadge = user && user.is_admin ? true : false;
      const position = user && user.position ? user.position : '';
      const userUUID = user ? this.getUserUUID(user.owner_id) : '';
      let showOnline = false;
      if(onlineUsers && onlineUsers.includes(userUUID)) {
        showOnline = true;
      }

      const placeholder = '';
      let  bottomLine = null;    
      if(user) {
        if(user.position && !user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.position}</styled.BottomLineTextOneItem>);
        }else if(!user.position && user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.badge_id}</styled.BottomLineTextOneItem>);
        }else if(!user.position && !user.badge_id){
          bottomLine = (<styled.BottomLineTextOneItem>{placeholder}</styled.BottomLineTextOneItem>)  
        }else {
           bottomLine = (    
            <styled.BottomLineWrap>
              <styled.BottomLineText>{user.position}</styled.BottomLineText>
              <styled.Dot/>
              <styled.BottomLineText>{user.badge_id}</styled.BottomLineText>
            </styled.BottomLineWrap>);
        }
      }

      return (
        <styled.MessageWrap key={user.id}>
        <styled.AvatarWrap>
          <styled.ProfileAvatar src={profileUrl}/>
          {showOnline && <styled.OnlineMark/>}
        </styled.AvatarWrap>
        <styled.InfoWrap>
          <styled.Name>
            {user !== undefined ? `${user.first_name} ${user.last_name}` : null}
            {showAdminBadge && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.Name>
          {bottomLine}
        </styled.InfoWrap>
        </styled.MessageWrap>
      );

    });

    const readers = readList.map((user, index) => {
      let profileUrl = '/icons/default-profile-thumb.svg';
      if(user && user.profile_image_thumb_url) {
        profileUrl = user.profile_image_thumb_url;
      }
      const showAdminBadge = user && user.is_admin ? true : false;
      const position = user && user.position ? user.position : '';
      const userUUID = user ? this.getUserUUID(user.owner_id) : '';
      let showOnline = false;
      if(onlineUsers && onlineUsers.includes(userUUID)) {
        showOnline = true;
      }

      const placeholder = '';
      let  bottomLine = null;    
      if(user) {
        if(user.position && !user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.position}</styled.BottomLineTextOneItem>);
        }else if(!user.position && user.badge_id) {
          bottomLine = (<styled.BottomLineTextOneItem>{user.badge_id}</styled.BottomLineTextOneItem>);
        }else if(!user.position && !user.badge_id){
          bottomLine = (<styled.BottomLineTextOneItem>{placeholder}</styled.BottomLineTextOneItem>)  
        }else {
           bottomLine = (    
            <styled.BottomLineWrap>
              <styled.BottomLineText>{user.position}</styled.BottomLineText>
              <styled.Dot/>
              <styled.BottomLineText>{user.badge_id}</styled.BottomLineText>
            </styled.BottomLineWrap>);
        }
      }

      return (
        <styled.MessageWrap key={user.id}>
        <styled.AvatarWrap>
          <styled.ProfileAvatar src={profileUrl}/>
          {showOnline && <styled.OnlineMark/>}
        </styled.AvatarWrap>
        <styled.InfoWrap>
          <styled.Name>
            {user !== undefined ? `${user.first_name} ${user.last_name}` : null}
            {showAdminBadge && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.Name>
          {bottomLine}
        </styled.InfoWrap>
        </styled.MessageWrap>
        );
    });


    return (
      <elements.ColumnWrap>
        <div className="reader__wrap">
          <p className="reader__title">{title}</p>
          <button className="reader__close-button" onClick={this.handleClose}/>
        </div>
        <elements.ScrollWrap isLoading={false}>
          {false && <elements.Loading />}
          <div className="listSeparator">
            <label>{read}</label>
            <label className="listSeparator__number">{numReaders}</label>
          </div>
          {readers}
          <div className="listSeparator">
            <label>{notRead}</label>
            <label className="listSeparator__number">{numOthers}</label>
          </div>
          {others}
        </elements.ScrollWrap>
      </elements.ColumnWrap>
    );
  }
}

ReadReceiptsComponent.propTypes = {
  //pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(ReadReceiptsComponent);

