import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import styled from './styled';
import './GroupComponent.scss';

class EditGroupComponent extends React.Component {
	constructor(props){
    super(props);
    this.state={
    	channelId: 0,
      name: '',
      groupDescription: '',
    };

    this._isMounted = false;

  }

  componentDidMount() {
  	const { active, } = this.props;
  	this._isMounted = true;

  	if(active) {
  		const id = active && active.subscriptions ? active.subscriptions.channel.id : 0;
  		const channelName = active && active.subscriptions ? active.subscriptions.channel.channel_name : '';
  		const desc = active && active.subscriptions ? active.subscriptions.channel.description : '';
  		this.setState({channelId: id, name: channelName, groupDescription: desc});
  	}

  }

  componentDidUpdate(prevProps, prevState) {
  	const { active, } = this.props;

  	if(prevProps.active !== active) {
  		if(active) {
  			const channleId = active && active.subscriptions ? active.subscriptions.channel.id : 0;
	  		const channelName = active && active.subscriptions ? active.subscriptions.channel.channel_name : '';
	  		const desc = active && active.subscriptions ? active.subscriptions.channel.description : '';
	  		this.setState({channelId: id, name: channelName, groupDescription: desc});
	  	}
  	}
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNameChanged = (event) => {

    this.setState({name: event.target.value});
  }

  handleDescriptionChanged = (event) => {

    this.setState({groupDescription: event.target.value});
  }

  handleCancel = (event) => {
  	const { notifyCancel, } = this.props;
  	notifyCancel();
  }

  handleConfirm = (event) => {
  	const { acitve, notifySaveChanges, } = this.props;
  	const {channelId, name, groupDescription, } = this.state;
  	notifySaveChanges(channelId, name, groupDescription);
  }

  render () {
  	const { activeChannel, } = this.props;
  	const { name, groupDescription, } = this.state;

  	let w = 400;
    let h = 350;
  	let winTop = `${(window.innerHeight - h)/2}px`;
    let winLeft = `${(window.innerWidth - w)/2}px`;
    let winH = `${h}px`;
    let winW = `${w}px`;

    const cancel = (<FormattedMessage
      id="EditGroupComponent.cancel"
      defaultMessage="Cancel"
    />);
    const confirm = (<FormattedMessage
      id="EditGroupComponent.confirm"
      defaultMessage="Confirm"
    />);
    const save = (<FormattedMessage
      id="EditGroupComponent.save"
      defaultMessage="Save Changes"
    />);
    const groupDetails = (<FormattedMessage
      id="EditGroupComponent.groupDetails"
      defaultMessage="Edit Group Details"
    />);
    const info = (<FormattedMessage
      id="EditGroupComponent.info"
      defaultMessage="Name your group and add a helpful description"
    />);
    const groupName = (<FormattedMessage
      id="EditGroupComponent.groupName"
      defaultMessage="Group Name"
    />);

    const description = (<FormattedMessage
      id="EditGroupComponent.description"
      defaultMessage="Enter Group Details"
    />);

  	return (
      <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
      	<div className="edit-group__top-line">
        <label className="edit-group__title">{groupDetails}</label>
        <label className="edit-group__info">{info}</label>
        </div>
        <div className="edit-group__group-name-wrap">
          <label className="edit-group__caption">{groupName}</label>
          <input className="edit-group__name"
            onChange={this.handleNameChanged}
            type="text"
            value={name}
          />
        </div>
        <div className="edit-group__group-description-wrap">
          <label className="edit-group__caption">{description}</label>
          <textarea className="edit-group__description"
            onChange={this.handleDescriptionChanged}
            type="text"
            value={groupDescription}
          />
        </div>
        <div className="edit-group__button-wrap-create">
          <button className="edit-group__button-cancel" onClick={this.handleCancel}>{cancel}</button>
          <button className="edit-group__button" onClick={this.handleConfirm}>{save}</button>
        </div>
      </styled.WinContainerConfirm>
    );
  }
}

EditGroupComponent.propTypes = {
  //pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(EditGroupComponent);