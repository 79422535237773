import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import styled from './styled';



class UpgradePrompt extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			debug: false,
		}

		this._isMounted = false;
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentDidUpdate(prevProps, prevState) {

	}

	componentWillUnmount() {
    this._isMounted = false;
    
  }

  handleViewPlansClicked = (event) => {
  	event.preventDefault();
    const backToPath = "/settings/subscription";
    window.location.pathname = backToPath;
  }

  render() {

   	let w = 400;
    let h = 205;
    let winTop = `${(window.innerHeight - h)/2}px`;
    let winLeft = `${(window.innerWidth - w)/2}px`;
    let winH = `${h}px`;
    let winW = `${w}px`;

    const title = ( <FormattedMessage
                  id="UpgradePrompt.title"
                  defaultMessage="Upgrade your plan"
                />);
    const content = ( <FormattedMessage
                  id="UpgradePrompt.content"
                  defaultMessage="This feature requires Shyft Subscription. In order to access it, please upgrade your account."
                />);
    const buttonText = ( <FormattedMessage
                  id="UpgradePrompt.buttonText"
                  defaultMessage="View Plans"
                />);
   	return (
   		<styled.WinContainer top={winTop} left={winLeft} width={winW} height={winH}>
   			<styled.Title>{title}</styled.Title>
   			<styled.Content>{content}</styled.Content>
   			<styled.ViewPlansButton onClick={this.handleViewPlansClicked}>{buttonText}</styled.ViewPlansButton>
   		</styled.WinContainer>
   	);
   }
}

UpgradePrompt.propTypes = {
  //selectedItem: PropTypes.object.isRequired,
  //selectedType: PropTypes.string.isRequired
};

export default injectIntl(UpgradePrompt);