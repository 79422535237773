import userCalls from '../../../helpers/api-calls/user-calls';
import actions from './actions';
import { appActions } from 'App/duck';
import constants from '../../../pages/UserManagement/constants';
import {Mixpanel} from '../../../Mixpanel';

const {userActionTypes} = constants;

const fetchUnregisteredUsersDispatch = (locationId) => (dispatch) => {
    if( typeof(locationId) == 'undefined' || locationId == null ){ 
        console.log('fetchUnregisteredUsersDispatch Error: Undefined or Null LocationId');
        return; 
    }

    return userCalls.getUnregisteredUsers(locationId).then( (response) => {
        const payload = {
            users: response.data.unregistered_users
        };
        dispatch(actions.receiveUnregisteredUsers(payload));
    }).catch( (error) => {
        console.log(`fetch UU error: ${error}`);
    });
};

const removeUnregisteredUserOperation = (unregisteredUserId) => (dispatch) => {
    return userCalls.removeUnregisteredUser(unregisteredUserId).then( (response) => {
        const payload = {
            id: unregisteredUserId
        };
        const totalIds = [unregisteredUserId];
        const actionPayload = {name: userActionTypes.DELETE_UNREGISTERED, ids: totalIds};
        dispatch(appActions.lastAction(actionPayload));
        dispatch(actions.removeUnregisteredUser(payload));
    }).catch( (error) => {
        console.log(`fetch UU error: ${error}`);
    });

}

//locationId, firstName, lastName, email, phone
const createUnregisteredUserOperation = (locationId, firstName, lastName, email) => (dispatch) => {
    return userCalls.createUnregisteredUser(locationId, firstName, lastName, email).then( (response) => {
        const payload = {
            user: response.data.unregistered_user
        };
        payload.user.status = 'unregistered';
        dispatch(actions.appendUnregisteredUser(payload));
        Mixpanel.track('Added unregistered user',{'location':locationId, 'email': email});
    }).catch( (error) => {
        console.log(`Create UU error: ${error}`);
    });
}

const updateUnregisteredUserOperation = (unregisteredUserId, phone) => (dispatch) => {
    return userCalls.updateUnregisteredUser(unregisteredUserId, phone).then( (response) => {
        const payload = {
            user: response.data.unregistered_user
        };
        payload.user.status = 'unregistered';
        dispatch(actions.updateUnregisteredUserNumber(payload));
    }).catch( (error) => {
        console.log(`Update UU error: ${error}`);
    })
}

const updateUnregisteredUserBadgeIDOperation = (unregisteredUserId, badgeId) => (dispatch) => {
    return userCalls.updateUnregisteredUserBadgeID(unregisteredUserId, badgeId).then( (response) => {
        const payload = {
            user: response.data.unregistered_user
        };
        payload.user.status = 'unregistered';
        dispatch(actions.updateUnregisteredUserBadgeID(payload));
    }).catch( (error) => {
        console.log(`Update UU badgeID error: ${error}`);
    })
}

export default {
    fetchUnregisteredUsersDispatch,
    createUnregisteredUserOperation,
    removeUnregisteredUserOperation,
    updateUnregisteredUserOperation,
    updateUnregisteredUserBadgeIDOperation
}