import React  from "react";
import { FormattedMessage, } from 'react-intl';
import AvailabilityContainer from "../Availability/AvailabilityContainer";
import ScheduleCalendarContainer from "../ScheduleCalendar/ScheduleCalendarContainer";

import './ScheduleTabComponent.scss';
import '../css/tab.scss';

import moment from 'moment';
import '../../App/layout.scss';
import '../../App/fonts.scss';
import { Mixpanel } from '../../Mixpanel';

class ScheduleTabComponent extends React.Component {
    state = {
        activeTab: 1,
    };
  
    constructor(props) {
      super(props);
    }

    componentDidMount() {
        const { match } = this.props;

        const initialTab = match.params.module;
        if( initialTab === 'availability'){
            this.setState({activeTab: 2});
            Mixpanel.track('Availability Page')
        }else{
            this.setState({activeTab: 1});
            Mixpanel.track('Schedule Page')
        }
    }

    componentDidUpdate() {
     
    }

    handleScheduleClick = () => {
        this.setState({activeTab: 1});
        Mixpanel.track('Schedule Page')
    }

    handleAvailabilityClick = () => {
        this.setState({activeTab: 2});
        Mixpanel.track('Availability Page')
    }

    render() {
        const {locationSettings} = this.props;
        const {activeTab } = this.state;

        var zone_name = moment.tz.guess();
        var timezone = moment.tz(zone_name).zoneAbbr();

        const timeZoneText = (<FormattedMessage id="ScheduleCalendarComponent.tz" defaultMessage="Your Time Zone: " />);
        const scheduleListItem = (<FormattedMessage id="ShiftHeaderComponent.schedule" defaultMessage="Schedule" />);
        const availabilityListItem = <FormattedMessage id="Availability.availability" defaultMessage="Availability"/>;

        const shouldShowAvailability = (locationSettings.cfg__availability === 1);

        return (
            <div className="top-tab_wrap">

                <div className="flex-row schedule-calendar_header">
                    <div className="top-tab_list">
                        <ul>
                            <li className={ (activeTab === 1 ? 'active-li' : '') } onClick={this.handleScheduleClick}>{scheduleListItem}</li>
                             { shouldShowAvailability && <li className={ (activeTab === 2 ? 'active-li' : '') } onClick={this.handleAvailabilityClick}>{availabilityListItem}</li>}
                        </ul>
                    </div>
                    <div className="schedule-calendar_tz fs12px averta-regular"> {timeZoneText} {timezone}</div>
                </div>

                <div className="top-tab_container">
                    { activeTab === 1 && <ScheduleCalendarContainer locationSettings = {locationSettings} /> }
                    { activeTab === 2 && <AvailabilityContainer/> }
                    
                </div>
            </div>
        );
    }

}

export default ScheduleTabComponent;