import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';


import Modal from 'components/GeneralComponents/Modal';
import constants from '../../constants';
import styled from './styled';
import './Header.scss';

class ConfirmOperation extends React.PureComponent {
	constructor(props) {
    super(props);

    this.state ={
      debug: false,
      name: '',
      groupDescription: '',
      openGroupMenu: false,
      selectedChannel: null,
      selectedDepartment: null,
      selectedSkill: null,
      usersAlreadyIn: [],
      associatesAlreadyInDept: [],
      dropdownPos: null,
      hideDropdownList: false,
      notChannelAdmin: true,
      locationFeed: false,

    };

    this.dropdownRef = React.createRef();

    this._isMounted = false;
  }

  componentDidMount() {
    const { requestedOperation, channels, channelData, selectedItem, departments, skills, selectedType, } = this.props;
    const { categoryTypes, operationTypes, } = constants;

    this._isMounted = true;

    if(selectedType !== categoryTypes.LOCATION && 
      (requestedOperation === operationTypes.REMOVE_USERS_FROM_DEPT || 
        requestedOperation === operationTypes.REMOVE_USERS_FROM_GROUP || 
        requestedOperation === operationTypes.REMOVE_USERS_FROM_SKILL)) {
      this.setState({hideDropdownList: true});
      if(selectedItem) {
        this.handleParentSelectedItem(selectedItem);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { requestedOperation, selectedItem, channels, departments, } = this.props;
    const { dropdownPos, openGroupMenu, } = this.state;

    if(prevState.openGroupMenu !== openGroupMenu) {
      if(openGroupMenu) {
        let winTop = ''; //`${(window.innerHeight - 612)/2}px`;
        let winLeft = ''; //`${(window.innerWidth - 1200)/2}px`;
        let winW = '200px';//`50%`;
        const winH = `200px`; //94.9% viewport height

        if(this.dropdownRef && this.dropdownRef.current) {
          const domNode = ReactDOM.findDOMNode(this.dropdownRef.current);
          const output =domNode.getBoundingClientRect();
          if(output) {
            winTop = `${output.y + output.height}px`;
            winLeft = `${output.x}px`;
            winW = `${output.width}px`
          }
        }
        const winData = {
          top: winTop,
          left: winLeft,
          width: winW,
          height: winH,
        }
        this.setState({dropdownPos: winData});
      }
    }

    if(prevProps.channels !== channels) {
      console.log('channel changed');
    }

    if(prevProps.departments !== departments) {
      console.log('departments changed');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleParentSelectedItem = (inputItem) => {
    const { requestedOperation, channels, channelData, departments, skills, selectedUsers, } = this.props;
    const { operationTypes, } = constants;

    switch(requestedOperation) {
      case operationTypes.REMOVE_USERS_FROM_DEPT:
        //const depts = departments.filter((dept) => dept.name === inputItem.name);
        const depts = departments.filter((dept) => dept.id === inputItem.id);
        if(depts && depts.length > 0) {
          this.setState({selectedDepartment: depts[0]});
          let usersInDept = [];
          for(let i=0; i<selectedUsers.length; i++) {
            const foundUsers = depts[0].user_ids.filter((id) => id === selectedUsers[i].owner_id)
            if(foundUsers && foundUsers.length > 0) {
              usersInDept.push(selectedUsers[i]);
            }
          }
          this.setState({usersAlreadyIn: usersInDept});
        }
        break;
      case operationTypes.REMOVE_USERS_FROM_GROUP:
        //const groups = channels.filter((channel) => channel.channel_name === inputItem.name);
        const groups = channels.filter((channel) => channel.id === inputItem.id);
        if(groups && groups.length > 0) {
          //if(groups[0].channel_type !== 'location_feed' && groups[0].userIsAdmin) {
          if(groups[0].channel_type !== 'location_feed') {
            this.setState({selectedChannel: groups[0]});
            const usersInChannel = this.getChannelMembers(groups[0].id);
            this.setState({usersAlreadyIn: usersInChannel});
          }else {
            if(groups[0].channel_type === 'location_feed') {
              this.setState({locationFeed: true});
              console.log(`Cannot remove users: Selected channel ${groups[0].channel_name} is ${groups[0].channel_type}`);
            }else if(!groups[0].userIsAdmin) {
              this.setState({notChannelAdmin: true});
              console.log(`Cannot remove users: Logged in user is not channel admin for channel ${groups[0].channel_name}`);
            }
          }
        }
        break;
      case operationTypes.REMOVE_USERS_FROM_SKILL:
        const theSkills = skills.filter((skill) => skill.skill_content === inputItem.name);
        if(theSkills && theSkills.length > 0) {
          this.setState({selectedSkill: theSkills[0]});
          let usersForSkill = [];
          for(let i=0; i<selectedUsers.length; i++) {
            const foundUsers = theSkills[0].user_ids.filter((id) => id === selectedUsers[i].owner_id)
            if(foundUsers && foundUsers.length > 0) {
              usersForSkill.push(selectedUsers[i]);
            }
          }
          this.setState({usersAlreadyIn: usersForSkill});
        }
        break;
    }
  }

  handleNameChanged = (event) => {

    this.setState({name: event.target.value});
  }

  handleDescriptionChanged = (event) => {

    this.setState({groupDescription: event.target.value});
  }

  handleCancel = (event) => {
    const { notifyConfirmOperation, requestedOperation } = this.props;
    const { name, groupDescription, selectedChannel, selectedDepartment, selectedSkill, usersAlreadyIn, } = this.state;
    const { userActionTypes, operationTypes, } = constants;
    switch(requestedOperation) {
      case operationTypes.CREATE_DEPARTMENT:
        const dept = {
          operation: requestedOperation,
          name: name,
          description: '',
        }
        notifyConfirmOperation(false, dept);
        break;
      case operationTypes.ASSIGN_USERS_TO_DEPT:
        const output = this.findValidUsers();
        const deptObj = {
          operation: requestedOperation,
          department: selectedDepartment,
          users: output, 
        }
        notifyConfirmOperation(false, deptObj);
        break;
      case operationTypes.REMOVE_USERS_FROM_DEPT:
        const deptObj1 = {
          operation: requestedOperation,
          department: selectedDepartment,
          users: usersAlreadyIn, 
        }
        notifyConfirmOperation(false, deptObj1);
        break;
      case operationTypes.CREATE_GROUP:
        const group = {
        operation: requestedOperation,
        name: name,
        description: groupDescription,
      }
      notifyConfirmOperation(false, group);
      break;
      case operationTypes.ASSIGN_USERS_TO_GROUP:
        const validUsers = this.findValidUsers();
        const channelObj = {
          operation: requestedOperation,
          channel: selectedChannel, 
          users: validUsers,
        }
        notifyConfirmOperation(false, channelObj);
        break;
      case operationTypes.REMOVE_USERS_FROM_GROUP:
        const channelObj1 = {
          operation: requestedOperation,
          channel: selectedChannel, 
          users: usersAlreadyIn,
        }
        notifyConfirmOperation(false, channelObj1);
        break;
      case operationTypes.CREATE_SKILL:
        const skill = {
          operation: requestedOperation,
          name: name,
          description: '',
        }
        notifyConfirmOperation(false, skill);
        break;
      case operationTypes.ASSIGN_USERS_TO_SKILL:
        const validUsersForSkill = this.findValidUsers();
        const skillObj = {
          operation: requestedOperation,
          skill: selectedSkill,
          users: validUsersForSkill,
        }
        notifyConfirmOperation(false, skillObj);
        break;
      case operationTypes.REMOVE_USERS_FROM_SKILL:
        const skillObj1 = {
          operation: requestedOperation,
          skill: selectedSkill, 
          users: usersAlreadyIn,
        }
        notifyConfirmOperation(false, skillObj1);
        break;

    }
  }
  handleConfirm = (event) => {
    const { notifyConfirmOperation, requestedOperation, selectedUsers, } = this.props;
    const { name, groupDescription, selectedChannel, usersAlreadyIn, selectedDepartment, selectedSkill, } = this.state;
    const { userActionTypes, operationTypes, } = constants;

    switch(requestedOperation) {
      case operationTypes.CREATE_DEPARTMENT:
        const dept = {
          operation: requestedOperation,
          name: name,
          description: '',
        }
        notifyConfirmOperation(true, dept);
        break;
      case operationTypes.ASSIGN_USERS_TO_DEPT:
        const output = this.findValidUsers();
        const deptObj = {
          operation: requestedOperation,
          department: selectedDepartment,
          users: output, 
        }
        notifyConfirmOperation(true, deptObj);
        break;
      case operationTypes.REMOVE_USERS_FROM_DEPT:
        const deptObj1 = {
          operation: requestedOperation,
          department: selectedDepartment,
          users: usersAlreadyIn, 
        }
        notifyConfirmOperation(true, deptObj1);
        break;
      case operationTypes.CREATE_GROUP:
        const group = {
          operation: requestedOperation,
          name: name,
          description: groupDescription,
        }
        notifyConfirmOperation(true, group);
        break;
      case operationTypes.ASSIGN_USERS_TO_GROUP:
        const validUsers = this.findValidUsers();
        const channelObj = {
          operation: requestedOperation,
          channel: selectedChannel, 
          users: validUsers,
        }
        notifyConfirmOperation(true, channelObj);
        break;
      case operationTypes.REMOVE_USERS_FROM_GROUP:
        const channelObj1 = {
          operation: requestedOperation,
          channel: selectedChannel, 
          users: usersAlreadyIn,
        }
        notifyConfirmOperation(true, channelObj1);
        break;
      case operationTypes.CREATE_SKILL:
        const skill = {
          operation: requestedOperation,
          name: name,
          description: '',
        }
        notifyConfirmOperation(true, skill);
        break;
      case operationTypes.ASSIGN_USERS_TO_SKILL:
        const validUsersForSkill = this.findValidUsers();
        const skillObj = {
          operation: requestedOperation,
          skill: selectedSkill,
          users: validUsersForSkill,
        }
        notifyConfirmOperation(true, skillObj);
        break;
      case operationTypes.REMOVE_USERS_FROM_SKILL:
        const skillObj1 = {
          operation: requestedOperation,
          skill: selectedSkill, 
          users: usersAlreadyIn,
        }
        notifyConfirmOperation(true, skillObj1);
        break;
    }
  }

  findValidUsers = () => {
    const { selectedUsers, } = this.props;
    const { usersAlreadyIn, } = this.state;
    const copy = [...selectedUsers];

    if(usersAlreadyIn && usersAlreadyIn.length > 0) {
      for(let i=0; i<usersAlreadyIn.length; i++) {
        const idx = copy.findIndex((user) => user.id === usersAlreadyIn[i].id);
        if(idx >= 0) {
          copy.splice(idx, 1);
        }
      }
      
    }
    return copy;
  }

  handleGroupDropDown = (event) => {
    const { requestedOperation, selectedType, } = this.props;
    const { openGroupMenu, hideDropdownList, } = this.state;
    const { operationTypes,categoryTypes, } = constants;

    if(hideDropdownList) {
      return;
    }

    this.setState({openGroupMenu: !openGroupMenu});
  }

  handleSelected = (selectedItem) => {
    const { channelData, selectedUsers, requestedOperation, departments, skills, } = this.props;
    const { operationTypes, } = constants;

    this.setState({openGroupMenu: false});
    switch(requestedOperation) {
      case operationTypes.ASSIGN_USERS_TO_GROUP:
      case operationTypes.REMOVE_USERS_FROM_GROUP:
        this.setState({selectedChannel: selectedItem});
        const usersInChannel = this.getChannelMembers(selectedItem.id);
        this.setState({usersAlreadyIn: usersInChannel});
        break;
      case operationTypes.ASSIGN_USERS_TO_DEPT:
      case operationTypes.REMOVE_USERS_FROM_DEPT:
        this.setState({selectedDepartment: selectedItem});
        let usersInDept = [];
        let associatesInDept = [];
        if(departments && departments.length > 0) {
          const foundDept = departments.filter((dept) => dept.id === selectedItem.id);
          if(foundDept[0] && selectedUsers && selectedUsers.length > 0) {
            for(let i=0; i<selectedUsers.length; i++) {
              const foundUsers = foundDept[0].user_ids.filter((id) => id === selectedUsers[i].owner_id)
              if(foundUsers && foundUsers.length > 0) {
                usersInDept.push(selectedUsers[i]);
              }
            }
          }
          if(requestedOperation === operationTypes.ASSIGN_USERS_TO_DEPT) {
            const associates = selectedUsers.filter((user) => user.is_admin === false);
            if(associates && associates.length > 0) {
              for(let j=0; j<associates.length; j++) {
                if(associates[j].department_ids.length > 0) {
                  //Associate can ONLY be in ONE department on each location
                  if(!usersInDept.includes(associates[j])) {
                    usersInDept.push(associates[j]);
                  }
                  associatesInDept.push(associates[j]);
                }
              }
            }
          }
        }
        this.setState({usersAlreadyIn: usersInDept, associatesAlreadyInDept: associatesInDept});
        break;
      case operationTypes.ASSIGN_USERS_TO_SKILL:
      case operationTypes.REMOVE_USERS_FROM_SKILL:
        this.setState({selectedSkill: selectedItem});
        let usersForSkill = [];
        if(skills && skills.length > 0) {
          const foundSkills = skills.filter((skill) => skill.id === selectedItem.id);
          if(foundSkills[0] && selectedUsers && selectedUsers.length > 0) {
            for(let i=0; i<selectedUsers.length; i++) {
              const foundUsers = foundSkills[0].user_ids.filter((id) => id === selectedUsers[i].owner_id)
              if(foundUsers && foundUsers.length > 0) {
                usersForSkill.push(selectedUsers[i]);
              }
            }
          }
        }
        this.setState({usersAlreadyIn: usersForSkill});
        break;
    }
  }

  sortDeptNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.name === b.name) // identical? return 0
        return 0;
      else if (a.name === null)  // a is null? last 
        return 1;
      else if (b.name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.name.localeCompare(b.name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortSkillWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortChannelNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.channel_name === b.channel_name) // identical? return 0
        return 0;
      else if (a.channel_name === null)  // a is null? last 
        return 1;
      else if (b.channel_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.channel_name.localeCompare(b.channel_name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  renderDropdownMenu = (operation) => {
    const { channels, departments, skills, } = this.props;
    const { dropdownPos, } = this.state;
    const { operationTypes, } = constants;
   
    let menuItems = [];
    switch(operation) {
      case operationTypes.ASSIGN_USERS_TO_DEPT:
        let sortedDepts = [...departments];
        this.sortDeptNameWithNullValues(sortedDepts);
        const deptItems = sortedDepts.map((dept, index) => (
          <div 
            key={index} 
            className="employee-page-header__dropdown-item-wrap" 
            value={dept}
            onClick={(event) => {this.handleSelected(dept)}}
          >
            <label className="confirm-operation__group-name">
              {dept.name}
            </label>
          </div>
        ));
        menuItems = deptItems;
        break;
      case operationTypes.ASSIGN_USERS_TO_GROUP:
        //Only channel admin can do add/remove users.
        const validChannels = channels.filter((channel) => 
                      channel.channel_type !== 'location_feed' && (channel.userIsAdmin && channel.userIsAdmin === true));
        let sortedChannels = [...validChannels];
        this.sortChannelNameWithNullValues(sortedChannels, true);
        const channelItems = sortedChannels.map((channel, index) => (
          <div 
            key={index} 
            className="employee-page-header__dropdown-item-wrap" 
            value={channel}
            onClick={(event) => {this.handleSelected(channel)}}
          >
            <label className="confirm-operation__group-name">
              {channel.channel_name}
            </label>
          </div>
        ));
        menuItems = channelItems;
        break;
      case operationTypes.ASSIGN_USERS_TO_SKILL:
        let sortedSkills = [...skills];
        this.sortSkillWithNullValues(sortedSkills, true);

        const skillItems = sortedSkills.map((skill, index) => (
          <div 
            key={index} 
            className="employee-page-header__dropdown-item-wrap" 
            value={skill}
            onClick={(event) => {this.handleSelected(skill)}}
          >
            <label className="confirm-operation__group-name">
              {skill.skill_content}
            </label>
          </div>
        ));
        menuItems = skillItems;
        break;
    }

    if(dropdownPos) {
      return (
        <styled.WinContainerGroupList top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {menuItems}
        </styled.WinContainerGroupList>
      );
    }
    return null;
  }

  getChannelMembers = (channelId) => {
    const { managers, associates, removedUsers, pendingUsers, channels, selectedUsers, } = this.props;
  
    const usersHaveId = selectedUsers.filter((user) =>  user.user && user.user.channel_ids && user.user.channel_ids.includes(channelId));
    if(usersHaveId && usersHaveId.length > 0) {
      const valid = usersHaveId.filter((item) => item.status === 'active');
      if(valid && valid.length > 0) {
        return valid;
      }
    }
    return [];
  }


  render () {
  	const { selectedAction, selectedUsers, requestedOperation, selectedItem, } = this.props;
    const { name, groupDescription, openGroupMenu, selectedChannel, usersAlreadyIn, selectedDepartment, 
    dropdownPos,  selectedSkill, associatesAlreadyInDept, hideDropdownList, notChannelAdmin, locationFeed, } = this.state;
    const { userActionTypes, operationTypes, categoryTypes, } = constants;

    let w = 400;
    let h = 250;

    if(requestedOperation === operationTypes.CREATE_DEPARTMENT) {
      h = 210;
    }
    if(requestedOperation === operationTypes.ASSIGN_USERS_TO_DEPT || requestedOperation === operationTypes.REMOVE_USERS_FROM_DEPT) {
      if(selectedDepartment) {
        h = 250;
      }else {
        h = 230;
      }
    }
    if(requestedOperation === operationTypes.CREATE_GROUP) {
      h = 340;
    }
    if(requestedOperation === operationTypes.ASSIGN_USERS_TO_GROUP || requestedOperation === operationTypes.REMOVE_USERS_FROM_GROUP) {
      if(selectedChannel) {
        h = 250;
      }else {
        h = 230;
      }
    }
    if(requestedOperation === operationTypes.CREATE_SKILL) {
      h = 210;
    }

    if(requestedOperation === operationTypes.ASSIGN_USERS_TO_SKILL || requestedOperation === operationTypes.REMOVE_USERS_FROM_SKILL) {
      if(selectedSkill) {
        h = 250;
      }else {
        h = 230;
      }
    }

    let winTop = `${(window.innerHeight - h)/2}px`;
    let winLeft = `${(window.innerWidth - w)/2}px`;
    let winH = `${h}px`;
    let winW = `${w}px`;
    const totalSelected = selectedUsers ? selectedUsers.length : 0;
    const alreadyIn = usersAlreadyIn ? usersAlreadyIn.length : 0;
    const toAdd = totalSelected - alreadyIn;
    const numUsersNotIn = totalSelected - alreadyIn;
    const cannotAddInDept = associatesAlreadyInDept ? associatesAlreadyInDept.length : 0;

    const cancel = (<FormattedMessage id="ConfirmOperation.cancel" defaultMessage="Cancel" />);
    const confirm = (<FormattedMessage id="ConfirmOperation.confirm" defaultMessage="Confirm" />);
    const create = (<FormattedMessage id="ConfirmOperation.create" defaultMessage="Create" />);
    const groupDetails = (<FormattedMessage id="ConfirmOperation.groupDetails" defaultMessage="Enter Group Details" />);
    const groupName = (<FormattedMessage id="ConfirmOperation.groupName" defaultMessage="Group Name" />);
    const description = (<FormattedMessage id="ConfirmOperation.description" defaultMessage="Enter Group Details" />);
    const addUsersToGroup = (<FormattedMessage id="ConfirmOperation.addUsersToGroup" defaultMessage="Add Users to Group" />);
    const removeFromGroup = (<FormattedMessage id="ConfirmOperation.removeFromGroup" defaultMessage="Remove Users from Group" />);
    const selectGroup = (<FormattedMessage id="ConfirmOperation.selectGroup" defaultMessage="Select group" />);
    const numUsersSelected = (<FormattedMessage
      id="ConfirmOperation.selectedUsers"
      defaultMessage="{number, plural, one {{number} user is selected} other {{number} users are selected}}" 
      values={{number: totalSelected}}/>); 
    const numUsersAlreadyInDept = (<FormattedMessage
      id="ConfirmOperation.alreadyInDept"
      defaultMessage="{number, plural, one {{number} user selected is already in the department} other {{number} users selected are already in the department}}" 
      values={{number: alreadyIn}}/>); 
    const numUsersCannotAddInDept = (<FormattedMessage
      id="ConfirmOperation.cannotAddInDept"
      defaultMessage="{number, plural, one {{number} user selected cannot be added to this department} other {{number} users selected cannot be added to this department}}" 
      values={{number: cannotAddInDept}}/>); 
    const numUsersAlreadyIn = (<FormattedMessage
      id="ConfirmOperation.alreadyIn"
      defaultMessage="{number, plural, one {{number} user selected is already in the group} other {{number} users selected are already in the group}}" 
      values={{number: alreadyIn}}/>); 
    const numUsersAlreadyInSkill = (<FormattedMessage
      id="ConfirmOperation.alreadyInSkill"
      defaultMessage="{number, plural, one {{number} user selected is already in the skill} other {{number} users selected are already in the skill}}" 
      values={{number: alreadyIn}}/>); 
    const numUsersToAdd = (<FormattedMessage
      id="ConfirmOperation.toAdd"
      defaultMessage="{number, plural, one {{number} user selected will be added} other {{number} users selected will be added}}" 
      values={{number: toAdd}}/>); 
    const numUsersToRemove = (<FormattedMessage
      id="ConfirmOperation.toRemove"
      defaultMessage="{number, plural, one {{number} user selected will be removed} other {{number} users selected will be removed}}" 
      values={{number: alreadyIn}}/>); 
    const numUsersNotInDept = (<FormattedMessage
      id="ConfirmOperation.notInDept"
      defaultMessage="{number, plural, one {{number} user selected is not in department} other {{number} users selected are not in department}}" 
      values={{number: numUsersNotIn}}/>); 
    const numUsersNotInGroup = (<FormattedMessage
      id="ConfirmOperation.notInGroup"
      defaultMessage="{number, plural, one {{number} user selected is not in the group} other {{number} users selected are not in the group}}" 
      values={{number: numUsersNotIn}}/>); 
    //This message content may be wrong, check design to confirm. possible: '3 user selected are not in the skill'
    const numUsersNotInSkill = (<FormattedMessage
      id="ConfirmOperation.notInSkill"
      defaultMessage="{number, plural, one {{number} user selected already has the skill} other {{number} users selected already have the skill}}" 
      values={{number: numUsersNotIn}}/>); 
    //This is the right message
    const numUsersNotHaveSkill = (<FormattedMessage
      id="ConfirmOperation.notHaveSkill"
      defaultMessage="{number, plural, one {{number} user selected does not have the skill} other {{number} users selected do not have the skill}}" 
      values={{number: numUsersNotIn}}/>); 

    const deptDetails = (<FormattedMessage id="ConfirmOperation.deptDetails" defaultMessage="Enter Department Details" />);
    const deptName = (<FormattedMessage id="ConfirmOperation.deptName" defaultMessage="Department Name" />);
    const selectDept = (<FormattedMessage id="ConfirmOperation.selectDept" defaultMessage="Select department" />);
    const skillDetails = (<FormattedMessage id="ConfirmOperation.skillDetails" defaultMessage="Enter Skill Details" />);
    const skillName = (<FormattedMessage id="ConfirmOperation.skillName" defaultMessage="Skill Name" />);
    const addUsersToDept = (<FormattedMessage id="ConfirmOperation.addUsersToDept" defaultMessage="Add Users to Department" />);
    const removeUsersFromDept = (<FormattedMessage id="ConfirmOperation.removeUsersFromDept" defaultMessage="Remove Users from Department" />);
    const selectSkill = (<FormattedMessage id="ConfirmOperation.selectSkill" defaultMessage="Select skill" />);
    const addUsersToSkill = (<FormattedMessage id="ConfirmOperation.addUsersToSkill" defaultMessage="Add Users to Skill" />);
    const removeFromSkill = (<FormattedMessage id="ConfirmOperation.removeFromSkill" defaultMessage="Remove Users from Skill" />);
    const cannotRemove = (<FormattedMessage id="ConfirmOperation.cannotRemove" defaultMessage="Only the Group Creator can remove users from this group." />);
    //Users cannot be removed from the Location Channel.
    const locationChannel = (<FormattedMessage
      id="ConfirmOperation.locationChannel"
      defaultMessage="Users cannot be removed from the Location Channel."
    />);
    let dropdownText = '';
    const arrowButtonClassName = openGroupMenu ? 'confirm-operation__arrow-button-up' : 'confirm-operation__arrow-button-down';
    //const dropdownText = selectedChannel ? selectedChannel.channel_name : selectGroup;
    const dropdownTextClassName = selectedChannel ? 'confirm-operation__group-name' : 'confirm-operation__drop-down-placeholder';

    let showDisabledButton = false;

    switch(requestedOperation) {
      case operationTypes.CREATE_DEPARTMENT:
        if(name.length === 0) {
          showDisabledButton = true;
        }
         return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm-operation__title">{deptDetails}</label>
            <div className="confirm-operation__group-name-wrap">
              <label className="confirm-operation__caption">{deptName}</label>
              <input className="confirm-operation__name"
                onChange={this.handleNameChanged}
                type="text"
                value={name}
              />
            </div>
            <div className="confirm__button-wrap-create">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              {/*<button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>*/}
              {showDisabledButton ? <button className="confirm__button-disabled"  disabled={true} onClick={this.handleConfirm}>{create}</button> :
              <button className="confirm__button" onClick={this.handleConfirm}>{create}</button>}
            </div>
          </styled.WinContainerConfirm>
        );
        break;
      case operationTypes.ASSIGN_USERS_TO_DEPT:
      case operationTypes.REMOVE_USERS_FROM_DEPT:
        if(!selectedDepartment) {
          showDisabledButton = true;
        }
        dropdownText = selectedDepartment ? selectedDepartment.name : selectDept;
        const title = requestedOperation === operationTypes.ASSIGN_USERS_TO_DEPT ? addUsersToDept : removeUsersFromDept;
        const usersToProcess = requestedOperation === operationTypes.ASSIGN_USERS_TO_DEPT ? numUsersToAdd : numUsersToRemove;
        //const usersNotProcess = requestedOperation === operationTypes.ASSIGN_USERS_TO_DEPT ? numUsersAlreadyInDept : numUsersNotInDept;
        //const usersNotProcess = requestedOperation === operationTypes.ASSIGN_USERS_TO_DEPT ? numUsersCannotAddInDept : numUsersNotInDept;
        let usersNotProcess = requestedOperation === operationTypes.ASSIGN_USERS_TO_DEPT ? numUsersAlreadyInDept : numUsersNotInDept;
        if(cannotAddInDept >= 1) {
          usersNotProcess = requestedOperation === operationTypes.ASSIGN_USERS_TO_DEPT ? numUsersCannotAddInDept : numUsersNotInDept;
        }

        return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm-operation__title">{title}</label>
            <div className="confirm-operation__group-drop-down" ref={this.dropdownRef} onClick={this.handleGroupDropDown}>
              <label className={dropdownTextClassName}>{dropdownText}</label>
              {!hideDropdownList && <button className={arrowButtonClassName} onClick={this.handleGroupDropDown}/>}
            </div>
            {selectedDepartment ? 
            <div className="confirm__label-wrap">
              <div className="confirm__text-wrap">
                <img className="confirm__img" src="/icons/checkmark-icon.svg"/>
                <label className="confirm__text">{usersToProcess}</label>
              </div>
              <div className="confirm__text-wrap">
                <img className="confirm__img" src="/icons/exclaimation-mark-icon.svg"/>
                <label className="confirm__text">{usersNotProcess}</label>
              </div>
            </div>
            :<div className="confirm-operation__dot-wrap">
              <span className="confirm-operation__dot"/>
              <label className="confirm-operation__text">{numUsersSelected}</label>
            </div>}
            <div className="confirm__button-wrap">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              {/*<button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>*/}
              {showDisabledButton ? <button className="confirm__button-disabled"  disabled={true} onClick={this.handleConfirm}>{confirm}</button> :
              <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>}
            </div>
            {openGroupMenu && <Modal>
              {this.renderDropdownMenu(operationTypes.ASSIGN_USERS_TO_DEPT)}
            </Modal>}
          </styled.WinContainerConfirm>
        );
        break;
      case operationTypes.CREATE_GROUP:
        if(name.length === 0) {
          showDisabledButton = true;
        }
        return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm-operation__title">{groupDetails}</label>
            <div className="confirm-operation__group-name-wrap">
              <label className="confirm-operation__caption">{groupName}</label>
              <input className="confirm-operation__name"
                onChange={this.handleNameChanged}
                type="text"
                value={name}
              />
            </div>
            <div className="confirm-operation__group-description-wrap">
              <label className="confirm-operation__caption">{description}</label>
              <textarea className="confirm-operation__description"
                onChange={this.handleDescriptionChanged}
                type="text"
                value={groupDescription}
              />
            </div>
            <div className="confirm__button-wrap-create">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              {/*<button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>*/}
              {showDisabledButton ? <button className="confirm__button-disabled"  disabled={true} onClick={this.handleConfirm}>{create}</button> :
              <button className="confirm__button" onClick={this.handleConfirm}>{create}</button>}
            </div>
          </styled.WinContainerConfirm>
        );
        break;
      case operationTypes.ASSIGN_USERS_TO_GROUP:
      case operationTypes.REMOVE_USERS_FROM_GROUP:
        if(!selectedChannel) {
          showDisabledButton = true;
          if(locationFeed) {
            return (
               <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={'140px'}>
                <label className="confirm-operation__title">{locationChannel}</label>
                <div className="confirm__button-wrap">
                  <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
                </div>
               </styled.WinContainerConfirm>
            );
          }
        }else {
          if(!selectedChannel.userIsAdmin) {
            return (
               <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={'140px'}>
                <label className="confirm-operation__title">{cannotRemove}</label>
                <div className="confirm__button-wrap">
                  <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
                </div>
               </styled.WinContainerConfirm>
            );
          }else if(locationFeed) {
            return (
               <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={'140px'}>
                <label className="confirm-operation__title">{locationChannel}</label>
                <div className="confirm__button-wrap">
                  <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
                </div>
               </styled.WinContainerConfirm>
            );
          }
        }
        dropdownText = selectedChannel ? selectedChannel.channel_name : selectGroup;
        const titleGroup = requestedOperation === operationTypes.ASSIGN_USERS_TO_GROUP ? addUsersToGroup : removeFromGroup;
        const usersToHandle = requestedOperation === operationTypes.ASSIGN_USERS_TO_GROUP ? numUsersToAdd : numUsersToRemove;
        const usersNotHandle = requestedOperation === operationTypes.ASSIGN_USERS_TO_GROUP ? numUsersAlreadyIn : numUsersNotInGroup;
        return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm-operation__title">{titleGroup}</label>
            <div className="confirm-operation__group-drop-down" ref={this.dropdownRef} onClick={this.handleGroupDropDown}>
              <label className={dropdownTextClassName}>{dropdownText}</label>
              {!hideDropdownList && <button className={arrowButtonClassName} onClick={this.handleGroupDropDown}/>}
            </div>
            {selectedChannel ? 
            <div className="confirm__label-wrap">
              <div className="confirm__text-wrap">
                <img className="confirm__img" src="/icons/checkmark-icon.svg"/>
                <label className="confirm__text">{usersToHandle}</label>
              </div>
              <div className="confirm__text-wrap">
                <img className="confirm__img" src="/icons/exclaimation-mark-icon.svg"/>
                <label className="confirm__text">{usersNotHandle}</label>
              </div>
            </div>
            :<div className="confirm-operation__dot-wrap">
              <span className="confirm-operation__dot"/>
              <label className="confirm-operation__text">{numUsersSelected}</label>
            </div>}
            <div className="confirm__button-wrap">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              {/*<button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>*/}
              {showDisabledButton ? <button className="confirm__button-disabled"  disabled={true} onClick={this.handleConfirm}>{confirm}</button> :
              <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>}
            </div>
            {openGroupMenu && <Modal>
              {this.renderDropdownMenu(operationTypes.ASSIGN_USERS_TO_GROUP)}
            </Modal>}
          </styled.WinContainerConfirm>
        );
        break;
      case operationTypes.CREATE_SKILL:
        if(name.length === 0) {
          showDisabledButton = true;
        }
        return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm-operation__title">{skillDetails}</label>
            <div className="confirm-operation__group-name-wrap">
              <label className="confirm-operation__caption">{skillName}</label>
              <input className="confirm-operation__name"
                onChange={this.handleNameChanged}
                type="text"
                value={name}
              />
            </div>
            <div className="confirm__button-wrap-create">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              {/*<button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>*/}
              {showDisabledButton ? <button className="confirm__button-disabled"  disabled={true} onClick={this.handleConfirm}>{create}</button> :
              <button className="confirm__button" onClick={this.handleConfirm}>{create}</button>}
            </div>
          </styled.WinContainerConfirm>
        );
        break;
      case operationTypes.ASSIGN_USERS_TO_SKILL:
      case operationTypes.REMOVE_USERS_FROM_SKILL:
        if(!selectedSkill) {
          showDisabledButton = true;
        }
        dropdownText = selectedSkill ? selectedSkill.skill_content : selectSkill;
        const titleSkill = requestedOperation === operationTypes.ASSIGN_USERS_TO_SKILL ? addUsersToSkill : removeFromSkill;
        const usersToHandleSkill = requestedOperation === operationTypes.ASSIGN_USERS_TO_SKILL ? numUsersToAdd : numUsersToRemove;
        const usersNotHandleSkill = requestedOperation === operationTypes.ASSIGN_USERS_TO_SKILL ? numUsersAlreadyInSkill : numUsersNotHaveSkill;
        return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm-operation__title">{titleSkill}</label>
            <div className="confirm-operation__group-drop-down" ref={this.dropdownRef} onClick={this.handleGroupDropDown}>
              <label className={dropdownTextClassName}>{dropdownText}</label>
              {!hideDropdownList && <button className={arrowButtonClassName} onClick={this.handleGroupDropDown}/>}
            </div>
            {selectedSkill ? 
            <div className="confirm__label-wrap">
              <div className="confirm__text-wrap">
                <img className="confirm__img" src="/icons/checkmark-icon.svg"/>
                <label className="confirm__text">{usersToHandleSkill}</label>
              </div>
              <div className="confirm__text-wrap">
                <img className="confirm__img" src="/icons/exclaimation-mark-icon.svg"/>
                <label className="confirm__text">{usersNotHandleSkill}</label>
              </div>
            </div>
            :<div className="confirm-operation__dot-wrap">
              <span className="confirm-operation__dot"/>
              <label className="confirm-operation__text">{numUsersSelected}</label>
            </div>}
            <div className="confirm__button-wrap">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              {showDisabledButton ? <button className="confirm__button-disabled"  disabled={true} onClick={this.handleConfirm}>{confirm}</button> :
              <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>}
            </div>
            {openGroupMenu && <Modal>
              {this.renderDropdownMenu(operationTypes.ASSIGN_USERS_TO_SKILL)}
            </Modal>}
          </styled.WinContainerConfirm>
        );
        break;
    }

  }
}

ConfirmOperation.propTypes = {
  //selectedItem: PropTypes.object.isRequired,
  //selectedType: PropTypes.string.isRequired
};

export default injectIntl(ConfirmOperation);