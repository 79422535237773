import { createSlice } from '@reduxjs/toolkit';

const shiftsSlice = createSlice({
  name: 'shifts',
  initialState: {
    data: [],
    page: 0,
    totalPages: 0,
    loading: false,
    adding: false,
    deleting: false,
    publishing: false,
    publishError: false,
    totalToPublish: 0,
    totalCompleted: 0,
  },
  reducers: {

    changeStatusToPublishing: (state, action) => {
      state.publishing = true;
      state.totalToPublish = action.payload.totalToPublish;
      console.log('Publishing ' + state.totalToPublish + ' shifts');
    },

    incrementPublishCount: (state, action) => {
      state.totalCompleted += 1;
      console.log('Incremented publish count: ' + state.totalCompleted);
      if( state.totalCompleted >= state.totalToPublish ){
        state.publishing = false;
        state.totalToPublish = 0;
        state.totalCompleted = 0; 
      }else{
        console.log('Publishing ' + (state.totalToPublish - state.totalCompleted) + ' shifts');
      }
    },

    changeStatusToAdding: (state, action) => {
      state.adding = true;
    },

    finishAdding: (state, action) => {
      state.adding = false;
    },

    changeStatusToDeleting: (state, action) => {
      state.deleting = true;
    },

    appendSingleShift: (state, action) => {
      state.data = state.data.concat(action.payload.shifts);
      state.adding = false;
    },

    updateSingleShift: (state, action) => {
      state.data = state.data.map(item => item.id === action.payload.shift.id ? action.payload.shift : item);
    },

    upsertSingleShift: (state, action) => {
      const shiftId = action.payload.shift.id;
      const existingShifts = state.data.filter( (shift) => shift.id == shiftId ); 
      if( existingShifts.length > 0 ){
        state.data = state.data.map(item => item.id === action.payload.shift.id ? action.payload.shift : item);
      }else{
        console.log('Inserted ' + action.payload.shift );
        state.data = state.data.concat(action.payload.shift);
      }
    },

    deleteSingleShift: (state, action) => {
      state.data = state.data.filter((shift) => shift.id !== action.payload.scheduleId);
      state.deleting = false;
    },

    requestShifts: (state, action) => {
      state.loading = true;
    },
    receiveFirstPageShifts: (state, action) => {
      state.data = action.payload.shifts;
      state.page = action.payload.page;
      state.loading = false;
    },
    appendShifts: (state, action) => {
      state.data = state.data.concat(action.payload.shifts);
      state.loading = false;
    },
    receiveFirstShiftsInDateRange: (state, action) => {
      shifts = state.data;
    },
    appendShiftsInDateRange: (state, action) => {
      state.data = state.data.concat(action.payload.shifts);
      state.loading = false;
    },

    appendSingleDraftShift: (state, action) => {
      state.data = state.data.concat(action.payload.shifts);
      state.adding = false;
    },
    deleteSingleDraftShift: (state, action) => {
      state.data = state.data.filter((shift) => shift.id !== action.payload.scheduleId);
      state.deleting = false;
    },
    resetShifts: (state, action) => {
      state.data = [];
      state.page = 0
      state.totalPages = 0;
      state.loading = false;
    },
    setPublishError: (state, action) => {
      state.publishError= true;
    },
    removePublishError: (state, action) => {
      state.publishError= false;
    },
  },
});

export default shiftsSlice;
