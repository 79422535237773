import moment from 'moment';

const  formattedShiftTimeRange = (shift) => {
    const start = moment(shift.start_at).local().clone();
    const end = moment(shift.end_at).local().clone();
    const formatString = 'hh:mma';
    const startString = start.format(formatString);
    const endString = end.format(formatString);

    const displayStartString = startString.substring(0, startString.length-1);
    const displayEndString = endString.substring(0, startString.length-1);

    const timeRange = `${displayStartString} - ${displayEndString}`;
    return timeRange;
}

const summaryDateRange = (rangeStart, rangeEnd) => {
  const start = rangeStart.local().clone();
  const end = rangeEnd.local().clone();
  const startString = start.format('MMM DD');
  const endString = end.format('MMM DD, YYYY');
  const result = startString + ' - ' + endString;
  return result;
}

const shiftTitleDate = (date) => {
  const formatString = 'ddd, MMM DD';
  const start = moment(date).local().clone();
  const result = start.format(formatString);
  return result;
}

const shiftDetailTitle = (shift) => {
  const date = shiftTitleDate(shift?.start_at)
  const range = formattedShiftTimeRange(shift)
  return `${date} • ${range} • ${shift.primary_job}`
}

const durationString = (start, end) => {
  console.log(start + ' ' + end);
  const startVal = moment(start).local().clone();
  const endVal = moment(end).local().clone();
  const diff = moment.duration(endVal.diff(startVal));
  const hours = parseFloat(diff.asHours()).toFixed(2);
  return hours;
}

const pickerHourTimeZoneFormat = (timestamp) => {
  return moment(timestamp).format(hourTimeZoneFormat())
}

const hourTimeZoneFormat = () => 'HH:mm:00Z';

export default {
    formattedShiftTimeRange,
    hourTimeZoneFormat,
    summaryDateRange,
    durationString,
    shiftDetailTitle,
    shiftTitleDate,
    pickerHourTimeZoneFormat,
};