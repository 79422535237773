import React  from "react";
import { FormattedMessage } from 'react-intl';
import moment from "moment";

import '../../../App/layout.scss';
import '../../../App/fonts.scss';
import { Mixpanel } from '../../../Mixpanel';
import AvailabilityDetailTableHeaderComponent from "./AvailabilityDetailTableHeaderComponent";
import AvailabilityDetailTableRowComponent from "./AvailabilityDetailTableRowComponent";

class AvailabilityDetailTableComponent extends React.Component {
    state = {
    };
  
    constructor(props) {
      super(props);
    }

    componentDidMount() {
  
    }

    componentDidUpdate() {

    }

    onClose() {
       console.log('Close');
    }

    render() {
        const {rangeStart, rangeEnd, userAvailabilities} = this.props;

        const sorter = function(a, b) {
            if ( moment(a.range_start_date).isBefore(moment(b.range_start_date)) )  {
              return -1;
            }else{
              return 1;
            }
        }

        const unsubmittedText = <FormattedMessage id="Availability.unsubmitted" defaultMessage='No Availability Submitted'/>;

        const availRows = userAvailabilities.sort(sorter).map((avail) => ( rangeStart 
            && <AvailabilityDetailTableRowComponent
                  rangeStart = {rangeStart} 
                  rangeEnd = {rangeEnd} 
                  availability = {avail}
                  /> ));
        const emptyPlaceholder = ( <tr><td className="avail-empty" colSpan={9}><span> {unsubmittedText} </span> </td></tr> )

        return (
            <div className="avail-detail-table">
                <AvailabilityDetailTableHeaderComponent rangeStart={rangeStart} rangeEnd={rangeEnd} />
                {availRows.length > 0 ? availRows : emptyPlaceholder}
            </div>
            );
        }

    }

    export default AvailabilityDetailTableComponent;