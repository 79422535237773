import React from 'react';
import styled from 'styled-components/macro';
import { ConfirmationSheet } from '@shyft/acorns';

import { getPlans } from '../../helpers/api-calls/plan-calls';
import PageWrap from 'components/GeneralComponents/PageWrap';
import confirmation from './confirmation';
import elements from './elements';
import components from './components';
import { getUserId } from '../../helpers/cookies';
import styledConfirm from '../Dashboard/components/styled';
import UpgradePrompt from '../../components/GeneralComponents/UpgradePrompt/UpgradePrompt';
import Modal from 'components/GeneralComponents/Modal';


const BigWrap = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: calc(100% - 40px);
  width:100%;
`;

const TeamFeedWrap = styled(PageWrap)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  padding-top:0px;
`;

class TeamFeed extends React.Component {

  constructor(props) {
    super(props);

    this.state ={
      debug: false,
      planName:[],
    };

    this._isMounted = false;
    this.planUrlSet = false;
  }

  componentDidMount() {
    const { active, clearActiveDelete, deletePost, deleteComment, udpateChannelContent, posts, locationData, plans, } = this.props;
    if(plans && plans.length > 0) {
      if(locationData && locationData.plan_id) {
        const cplan = plans.find(plan => plan.id === locationData.plan_id);
        this.setState({planName: cplan.name});
        // console.log("1");
        // console.log(cplan);
      }
    }
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { active, clearActiveDelete, deletePost, deleteComment, udpateChannelContent, posts, locationData, plans } = this.props;
    if(plans && plans.length > 0) {
      if(locationData && locationData.plan_id) {
        if(!this.planUrlSet) {
          this.planUrlSet = true;
          const cplan = plans.find(plan => plan.id === locationData.plan_id);
          this.setState({planName: cplan.name});
          // console.log("2");
          // console.log(cplan);
          // console.log(cplan.name);
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    const { active, clearActiveDelete, deletePost, deleteComment, udpateChannelContent, posts } = this.props;
    const { planName } = this.state
    const deleteType = active.error.code && active.error.code !== 404 ? 'error' : active.delete.type;
    const handleDelete = (id) => {
      const postIndex = posts.data.findIndex((post) => post.id === id);
      deletePost(id, posts.lastPost);
    };
    const deleteCallback = (deleteType === 'post' || deleteType === 'announcement') ? handleDelete : deleteComment;
    // console.log(planName);
    return (
      <BigWrap>
        <components.TopLine/>
        <TeamFeedWrap direction="row" full>
          <components.Channels />
          <elements.ColumnWrap>
            {active.channel && <components.Posts />}
          </elements.ColumnWrap>
          {active.sidepane === 'comments' && <components.Comments /> }
          {active.sidepane === 'read-receipts' && <components.ReadReceipts />}
          {active.sidepane === 'pins' && <components.Pins />}
          {active.sidepane === 'likers' && <components.Likers />}
          {active.sidepane === 'group-details' && <components.GroupDetails />}
          {active.sidepane === 'group-members' && <components.GroupMembers />}
          {active.sidepane === 'announcements' && <components.Announcements />}
        </TeamFeedWrap>
        {/*<ConfirmationSheet
          bodyText={confirmation.bodyText(deleteType)}
          buttons={confirmation.buttons(
            [clearActiveDelete, deleteCallback],
            active
          )}
          visible={active.delete.id}
        />*/}
        {planName === 'Basic' && <Modal>
          <UpgradePrompt/>
        </Modal>}
        <styledConfirm.Overlay display={ planName === "Basic" ? 'block' : 'none'} />
      </BigWrap>
    );
  }
}

TeamFeed.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default TeamFeed;
