
import React from 'react';
import '../../../../App/fonts.scss';
import '../../../../App/colors.scss';
import '../../../../App/layout.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

import {Mixpanel} from '../../../../Mixpanel';

import './ScheduleSearchFilterComponent.scss';
import SelectDepartmentPopover from './SelectDepartmentPopover'; 

class ScheduleSearchFilterComponent extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
        isShowingFilterDropDown: false,
        isShowingFilterLogicBubble: false,
        isShowingDepartmentSelectPopover: false,
        searchTerm: '',
        selectedDepartment: null,
    };
  }

  handleAddFilterClick = (e) => {
    const {isShowingFilterDropDown}  = this.state;
    if( isShowingFilterDropDown == true ){
        this.setState({isShowingFilterDropDown: false});
    }else{
        this.setState({isShowingFilterDropDown: true});
        Mixpanel.track('Add Dept Filter on Sched');
    }
  }

  handleDeleteFilterClick = (e) => {
    const {handleDepartmentSelect} = this.props;
    this.setState({selectedDepartment: null, isShowingFilterDropDown: false, isShowingFilterLogicBubble: false, isShowingDepartmentSelectPopover: false});
    handleDepartmentSelect(null);
    Mixpanel.track('Delete Dept Filter');
  }

  dropDownItemSelected = (e) => {
    if( e == 1) {
        this.setState({isShowingFilterDropDown: false, isShowingFilterLogicBubble: true, isShowingDepartmentSelectPopover: true});
    }
  }

  handleKeyPress = (event) => {
    const { notifySearchTermChanged, } = this.props;
    const { searchTerm, } = this.state;

    if(event.keyCode === 13) {
      notifySearchTermChanged(searchTerm);
      Mixpanel.track('Search on Sched', {'keyword':searchTerm});
    }
  }

  handleSearchChanged = (event) => {
    const { notifySearchTermChanged, } = this.props;
    event.preventDefault();
    this.setState({searchTerm: event.target.value});
    if(event.target.value === '') {
      notifySearchTermChanged('');
    }
  }

  handleDepartmentSelect = (e) => {
    const {handleDepartmentSelect} = this.props;
    this.setState({selectedDepartment: e, isShowingDepartmentSelectPopover: false});
    handleDepartmentSelect(e);
  }

  render() {
    const {searchTerm, locationSettings, departments, intl} = this.props;
    const {isShowingFilterDropDown, isShowingFilterLogicBubble, isShowingDepartmentSelectPopover, selectedDepartment} = this.state;
   
    const addFilterText = (<FormattedMessage id="SearchFilter.addFilter" defaultMessage="Add Filter" />);
    const departmentIsText = (<FormattedMessage id="SearchFilter.departmentIs" defaultMessage="Department is" />);
    const departmentText = (<FormattedMessage id="SearchFilter.department" defaultMessage="Department" />);

    const placeholderText = { id: 'SearchFilter.placeholder', defaultMessage: 'Search' };

    return (
      <div className="search-filter-container">
          <div className='zero-col-container'>
            <input className="search-box"
              onChange={this.handleSearchChanged}
              onKeyUp={this.handleKeyPress}
              type="text"
              placeholder={intl.formatMessage(placeholderText)}
              value={searchTerm} />
            </div>

            <div className='first-col-container'>
                {isShowingFilterLogicBubble && 
                    <div className="filter-logic">
                        <label className='filter-subject averta-semibold'>{departmentIsText}</label>
                        <label className='filter-object'> {selectedDepartment && selectedDepartment.name} </label>
                        <button className='close-btn' onClick={ () => {this.handleDeleteFilterClick();}}> <img src="icons/close-icon.svg"></img> </button>
                    </div>
                }

                {isShowingDepartmentSelectPopover && 
                    <SelectDepartmentPopover 
                      handleDepartmentSelect={this.handleDepartmentSelect}
                      departments={departments} /> }
            </div>

            <div className='second-col-container'>
                {locationSettings.cfg__departments == 1 && <button className="add-filter-button averta-semibold"  onClick={this.handleAddFilterClick}>{addFilterText}</button>}

                {isShowingFilterDropDown && <div className='filter-dropdown'> 
                    <ul className='filter-list'> <li onClick={ () => {this.dropDownItemSelected(1);}}> {departmentText} </li> </ul> </div>
                }
            </div>
      </div> 
    );
  }
}


export default injectIntl(ScheduleSearchFilterComponent);
