import React from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../components/GeneralComponents/RegistrationDesign.scss'
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import {
    checkForAuthentication,
} from 'helpers/cookies';

class StartSignUpPage extends React.PureComponent {

    handleCreateLocation = () => {
        this.props.history.push({
            pathname: '/sign-up/profile',
            state: { isCreateLocationSelected: true },
        });
    };

    handleJoinLocation = () => {
        this.props.history.push({
            pathname: '/sign-up/profile',
            state: { isCreateLocationSelected: false },
        });
    };


    render() {
        if (checkForAuthentication()) {
            // User is logged in
            return <Redirect to="/" />;
        }

        return (
            <RegistrationLayout>
                <div className="form-content">
                    <div className="welcome-text-wrapper">
                        <div className="dot"></div>
                        <div className="welcome-text">Excited to meet you</div>
                    </div>
                    <div className="main-heading">
                        Welcome to Shyft!
                    </div>
                    <div className="sub-heading" style={{ marginBottom: '16px', }}>
                        What would you like to do? Create a new location for your team, or join an existing location?
                    </div>

                    {/* <CurrentStep /> */}

                    <button className="next-btn" onClick={this.handleCreateLocation}>Create a new location</button>

                    <button className="clear-btn" onClick={this.handleJoinLocation}>Join an existing location</button>

                    <div className="login-message">
                        Already have an account?{' '}
                        <Link className="login-message-link" to="/login">
                            Login
                        </Link>

                    </div>
                </div>
            </RegistrationLayout>
        );
    }
}


export default withRouter(connect()(injectIntl(StartSignUpPage)));
