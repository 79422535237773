import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './UserRowShimmer.scss';

class UserRowShimmer extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    // Additional logic can be added here if needed in the future
  }

  componentDidUpdate(prevProps) {
    // Implement logic if props are updated (currently nothing is done)
  }

  componentWillUnmount() {
    this._isMounted = false;
    // Ensure all event listeners or subscriptions are cleaned up here if needed
  }

  renderShimmerCells = (count) => {
    return Array.from({ length: count }).map((_, idx) => (
      <td className="user-row__departments-wrap" key={idx}>
        <span className="general-cell" />
      </td>
    ));
  };

  render() {
    return (
      <tr className="user-row__row">
        <td className="user-row__checkbox">
          <span className="checkbox-cell"></span>
        </td>
        <td className="user-row__owner-cell">
          <span className="general-cell" />
        </td>
        <td className="user-row__user-role">
          <span className="general-cell" />
        </td>
        <td className="user-row__status-cell">
          <span className="general-cell" />
        </td>
        <td className="user-row__departments-wrap">
          <span className="general-cell" />
        </td>
        <td className="user-row__departments-wrap">
          <span className="general-cell" />
        </td>
        <td className="user-row__departments-wrap">
          <span className="general-cell" />
        </td>
        <td className="user-row__departments-wrap">
          <span className="general-cell" />
        </td>
      </tr>
    );
  }

}

UserRowShimmer.propTypes = {
  //notifyUserSelected: PropTypes.func.isRequired,
};

export default injectIntl(UserRowShimmer);

