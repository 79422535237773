

const sortTypes = {
  DATE: 'DATE',
  FILE_NAME: 'FILE_NAME',
  SHIFT_COUNT: 'SHIFT_COUNT'
};

const uploadStatus = {
  FAILED: 'failed',
  SUCCESS: 'success',
  CANCELLED: 'cancelled',
};


export default {
  sortTypes,
  uploadStatus
};
