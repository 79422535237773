import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './EditUserEmployeeIDComponent.scss';
import '../../../../../App/layout.scss';
import '../../../../../App/fonts.scss';
import { CountrySelector, } from '@shyft/acorns';
import { validatePhoneNumber } from '../../../../../helpers/validation';
import { SMSInvite } from 'helpers/api-calls';
import { Mixpanel } from '../../../../../Mixpanel';
import { Branchlink } from 'helpers/branchlink';
import userCellStyled from '../../../../ScheduleCalendar/components/TableComponents/styledPopup';
import StatusCell from '../Cell/StatusCell';

class EditUserEmployeeIDComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmButtonEnabled: false,
      employeeId: '',
    };

    this.employeeIdInputRef = React.createRef();
  }

  componentDidMount() {
  }

  componentDidUpdate() {

  }

  handleEmployeeIdChange = (e) => {
    const val = e.target.value;
    this.validateInputs(val);
    this.setState({ employeeId: val });
  }

  validateInputs(employeeId) {
    if (this.areInputsValid(employeeId) == true) {
      this.setState({ confirmButtonEnabled: true });
    } else {
      this.setState({ confirmButtonEnabled: false });
    }
  }

  areInputsValid(employeeId) {
    if (employeeId && employeeId.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  handleSaveAndFinish = (e) => {
    const { employeeId } = this.state;
    const { handleCloseEditEmployeeIDModal, updateUserBadgeIDDispatch, updateUnregisteredUserBadgeIDDispatch, user } = this.props;
    if (this.areInputsValid(employeeId) === true) {
      if (user.status !== 'unregistered') {
        updateUserBadgeIDDispatch(user.id, user.owner_id, employeeId);
      } else {
        updateUnregisteredUserBadgeIDDispatch(user.id, employeeId);
      }
      Mixpanel.track('Edit Badge ID save and finish');
      handleCloseEditEmployeeIDModal();
    }
  }

  render() {
    const { handleCloseEditEmployeeIDModal, user } = this.props;
    const { confirmButtonEnabled, employeeId } = this.state;

    const editEmployeeIDText = (<FormattedMessage id="EditUserEmployeeIDComponent.editEmployeeID" defaultMessage="Edit Employee ID" />);
    const saveText = (<FormattedMessage id="EditUserEmployeeIDComponent.save" defaultMessage="Save" />);
    const cancelText = (<FormattedMessage id="EditUserEmployeeIDComponent.cancel" defaultMessage="Cancel" />);
    const employeeIDText = (<FormattedMessage id="EditUserEmployeeIDComponent.employeeID" defaultMessage="Employee ID" />);
    let bottomLine = null;
    const placeholder = '';
    const userImageUrl = user && user.profile_image_thumb_url ?
      user.profile_image_thumb_url : '/icons/default-profile-thumb.svg';
    const userName = (user && user.hasOwnProperty('fullName')) ? user.fullName : (`${user.first_name} ${user.last_name}`);
    const position = user && user.position ? user.position : 'None';
    const badgeId = user && user.badge_id ? user.badge_id : '';
    if (user) {
      if (user.position) {
        bottomLine = (
          <userCellStyled.BottomLineTextOneItem> {position}</userCellStyled.BottomLineTextOneItem>);
      } else {
        bottomLine = (<userCellStyled.BottomLineTextOneItem>{placeholder}</userCellStyled.BottomLineTextOneItem>)
      }
    }

    return (
      <div className="add-team-member-container modal-container">
        <div className='flex-row title-row'>
          <label className="title averta-regular fs20px">{editEmployeeIDText}</label>
          <button onClick={() => { handleCloseEditEmployeeIDModal(); }}> <img src="icons/corner-close.svg" /> </button>
        </div>

        <div className="confirm__label-wrap">
          <userCellStyled.TopWrap style={{ width: '100%', paddingLeft: '0px' }}>
            <userCellStyled.InviteOwnerAvatar src={userImageUrl} />
            <userCellStyled.OwnerInfoWrap style={{ 'marginTop': '6px' }}>
              <userCellStyled.OwnerInfoTopLine>{userName}</userCellStyled.OwnerInfoTopLine>
              {bottomLine}
            </userCellStyled.OwnerInfoWrap>
            <StatusCell
              user={user}
            />
          </userCellStyled.TopWrap>
        </div>

        <div className='flex-row'>
          <div className="averta-regular fs14px lightgray employee-id">{employeeIDText}</div>
        </div>

        <div className='flex-row employee-id-wrap'>
          <input onChange={this.handleEmployeeIdChange} value={employeeId} ref={this.employeeIdInputRef}></input>
        </div>


        <div className="confirm-button-wrap">
          <button className="confirm-button-cancel averta-semibold fs14px line-button-disabled" onClick={handleCloseEditEmployeeIDModal}>{cancelText}</button>
          <button className={confirmButtonEnabled ? "confirm-button averta-semibold fs14px button-enabled" : "confirm-button averta-semibold fs14px button-disabled"} style={{ outline: 0 }} onClick={this.handleSaveAndFinish}>{saveText}</button>
        </div>

      </div>
    );
  }
}

export default injectIntl(EditUserEmployeeIDComponent);