import React  from "react";
import { FormattedMessage } from 'react-intl';

import './AvailabilityComponent.scss';
import '../../App/layout.scss';
import '../../App/fonts.scss';
import { Mixpanel } from '../../Mixpanel';

import AvailabilityHeaderComponent from "./AvailabilityHeaderComponent";
import AvailabilityTableComponent from "./AvailabilityTableComponent";

class AvailabilityComponent extends React.Component {
    state = {
    };
  
    constructor(props) {
      super(props);
    }

    componentDidMount() {
        const {loadAvailabilityDispatch, locationId} = this.props;
        loadAvailabilityDispatch(locationId);
    }

    componentDidUpdate() {

    }

    render() {
        const { notifyTeamDispatch, availabilities, loading, locationId, locationData, rangeStart, rangeEnd, startStr, changeDateRangeSlice, allUsers } = this.props;
        
        return (
            <div className="avail_wrap">
                <AvailabilityHeaderComponent
                    rangeStart={rangeStart}
                    rangeEnd={rangeEnd}
                    startStr={startStr}
                    changeDateRangeSlice={changeDateRangeSlice}
                    locationId={locationId}
                    locationData={locationData}
                    notifyTeamDispatch = {notifyTeamDispatch} />

                <AvailabilityTableComponent 
                    rangeStart={rangeStart}
                    rangeEnd={rangeEnd}
                    locationId={locationId}
                    locationData={locationData}
                    loading={loading}
                    allUsers = {allUsers}
                    availabilities = {availabilities}
                     />
            </div>
        );
    }

}

export default AvailabilityComponent;