import React from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../components/GeneralComponents/RegistrationDesign.scss'
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import GoogleMapView from './GoogleMapView';

class RegistrationLocationConfirmPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            location: null,
            isCreateLocationSelected: false,
        };
    }


    componentDidMount() {
        const { location } = this.props;

        if (location && location.state) {
            this.setState({
                isCreateLocationSelected: location.state.isCreateLocationSelected,
                userName: location.state.userName,
                location: location.state.location,
            });
        } else {
            // If API is not loaded, redirect to Registration1
            this.props.history.push('/');
            return null;
        }

    }

    handleNext = () => {
        const { userName, location, isCreateLocationSelected } = this.state;
        if (userName && location) {
            this.props.history.push({
                pathname: '/sign-up/account',
                state: { userName, location, isCreateLocationSelected },
            });
        }
    };



    render() {
        const { isCreateLocationSelected, userName, location } = this.state;
        console.log('location', location);
        return (
            <RegistrationLayout>
                <div className="form-content">
                    <div className="welcome-text-wrapper">
                        <div className="dot"></div>
                        <div className="welcome-text">Finding location</div>
                    </div>
                    <div className="main-heading">Join this location?</div>
                    <div className="name-prompt" style={{ marginTop: '8px' }}>You entered location code: {location && location.swift_code}</div>

                    <div className="confirm-location-container" >
                        <div className="confirm-location-avatar-container">
                            <img src='/img/channel-placeholder.svg' alt='Location Avatar' />
                        </div>

                        <div className="confirm-location-info-div">
                            <label className="confirm-location-title">
                                {location && location.location_name}
                            </label>
                            <label className="confirm-location-title">
                                {location && location.formatted_address}
                            </label>
                        </div>
                    </div>

                    {location && <GoogleMapView lat={location.latitude} lng={location.longitude} />}
                    <button className="next-btn" onClick={this.handleNext}>Confirm & Join</button>
                </div>
            </RegistrationLayout>
        );
    }
}

const RegistrationLocationConfirmPagei18n = injectIntl(RegistrationLocationConfirmPage);

export default withRouter(RegistrationLocationConfirmPagei18n);