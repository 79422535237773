import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import './TableComponent.scss';
import './RowComponent.scss';
import utils from 'pages/ScheduleCalendar/duck/utils';
import service from 'pages/ScheduleCalendar/duck/service';
import timeUtil from 'pages/ScheduleCalendar/duck/timeUtil';
import 'App/fonts.scss';

const AggregationType = {
  Scheduled: 'Scheduled',
  OpenShift: 'OpenShift',
};

class HoursRowComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalHoursType: AggregationType.Scheduled,
    };
  }

  selectAggregationType = (type) => {
    this.setState({totalHoursType: type})
  }

  hoursTotalSection = (topLabel, bottomLabel ) => {
    return <div className='left-group'>
              <label className='total-text ff-semibold fs12'>{topLabel}</label>
              <label className='user-count ff-regular fs12'>{bottomLabel}</label>
          </div>
  }

  scheduledHoursTotalSection = (results, userCount) => {
    return this.hoursTotalSection('Total Scheduled', `${results["WeekTotal"].toFixed(2)} hours • ${userCount} assigned`)
  }

  openShiftHoursTotalSection = (results) => {
    return this.hoursTotalSection('Total Open Shifts', `${results["WeekTotalHours"].toFixed(2)} hours • ${results["WeekTotalSlots"]} shifts`)
  }

  weekdayCell = (showIcon, itemCount, hours) => {
    return <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 8px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  {showIcon== true && <img alt="User icon" src="/icons/user-avatar.svg" style={{ marginRight: '8px', alignSelf: 'center' }} />}
                  <div className='labor-hours-num'>{itemCount}</div>
              </div>
              <div className='labor-hours-num'>{hours}</div>
          </div>
  }

  scheduledWeekdayCell = (results, day) => {
    return this.weekdayCell(true,  results[day].uniqueUsers.size,  results[day].totalHours.toFixed(2) + ' h')
  }

  openShiftWeekdayCell = (results, day) => {
    return this.weekdayCell(false,  results[day].totalSlots,  results[day].totalHours.toFixed(2) + ' shifts')
  }

  render() {
    const {scheduledUserCount, scheduledResult, openShiftResult, shouldShowOpenShiftUI, locationData } = this.props;
    const {totalHoursType} = this.state;
    const weekdays =  timeUtil.daysOfTheWeek(locationData) 

    const showScheduledHours = ( totalHoursType == AggregationType.Scheduled )
    const showOpenShiftHours = ( totalHoursType == AggregationType.OpenShift ) 

    return (
      <tr className="calendar-total-row">
        <td className="total-hour-cell dropdown">
            <button className='down-button' id="totalTypeDropdown" data-bs-toggle="dropdown" aria-expanded="false">  

              {showScheduledHours == true && this.scheduledHoursTotalSection(scheduledResult, scheduledUserCount) }
              {showOpenShiftHours == true && this.openShiftHoursTotalSection(openShiftResult) }

              {shouldShowOpenShiftUI && <div className='right-group'> <img src='/icons/down-arrow.svg'/> </div> }

               <ul className="dropdown-menu total-types" aria-labelledby="totalTypeDropdown">
                  <li onClick={() => this.selectAggregationType(AggregationType.Scheduled)}><a className="dropdown-item">Total Scheduled</a></li>
                  {shouldShowOpenShiftUI && <li onClick={() => this.selectAggregationType(AggregationType.OpenShift)}><a className="dropdown-item">Total Open Shifts</a></li>}
              </ul> 
            </button> 
        </td>

        {weekdays.map(day => (
          <td key={day} className='shift-row__day-cell middle-align'>
            {showScheduledHours == true && this.scheduledWeekdayCell(scheduledResult, day)}
            {showOpenShiftHours == true && this.openShiftWeekdayCell(openShiftResult, day)}
          </td>
        ))}
      </tr>
    );
  }
}

export default injectIntl(HoursRowComponent);