import React from "react"
import 'App/fonts.scss'
import presenter from "pages/ScheduleCalendar/duck/presenter"

export function DetailFieldDescriptionComponent ({defaultValue, handleDescriptionChange, disableField, author }) {

return (
        <div>
            <div className="flex-row desc-header">
                <div className="title-label ">Shift Description:</div>
                { author != null && <div>
                    <img className="mini-avatar ff-regular fs12" src={presenter.getUserAvatar(author)}/>
                    <label className="author-name ff-regular fs12">{presenter.getName(author)} </label>
                    <label className="author-tag ff-bold fs8">Author</label>
                </div>}
            </div>
            <textarea disabled={disableField} type="textarea" placeholder="" className="shift-description fs14 ff-regular" onChange={handleDescriptionChange} defaultValue={defaultValue}>
            </textarea>
        </div>
    )
}

export default DetailFieldDescriptionComponent

