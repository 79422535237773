import dateRangeSlice from './dateRange';
import shiftsSlice from './shifts';
import scheduleUsersSlice from './scheduleUsers';
import applicantsSlice from './applicants';
import applicantCommentsSlice from './applicantComments';

export default {
  dateRangeSlice,
  shiftsSlice,
  scheduleUsersSlice,
  applicantsSlice,
  applicantCommentsSlice,
};
