import React from 'react';
import 'App/layout.scss';
import 'App/fonts.scss';
import './DetailFieldPillTags.scss';
import presenter from 'pages/ScheduleCalendar/duck/presenter';
import applicantLogic from 'pages/ScheduleCalendar/duck/applicantLogic';

export function DetailFieldPillTagsComponent ({shift, applicants}) {

    const total = shift?.total_shifts_available || 0
    const applied = applicants.length || 0
    const pending =  applicantLogic.pendingApplicants(applicants).length || 0
    const approved = applicantLogic.approvedApplicants(applicants).length || 0
    const denied = applicantLogic.deniedApplicants(applicants).length || 0

    const pills = [ {label: 'Shifts', count: total},
                    {label: 'Applied', count: applied},
                    {label: 'Pending', count: pending},
                    {label: 'Approved', count: approved},
                    {label: 'Denied', count: denied} ]    

    const tags = pills.map( (item) => {
        return <div className="pill-item flex-row" key={item.label}>
                <label className='count ff-bold'>{item.count} </label>
                <label className='label ff-regular'>{item.label}</label>
            </div>
    })

    return (
        <div className="pill-tags fs12 flex-row">
            {tags}
        </div>
    )
}

export default DetailFieldPillTagsComponent;