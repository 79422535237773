import React from 'react';
import { connect } from 'react-redux';
import { scheduleCalendarOperations } from 'pages/ScheduleCalendar/duck';
import ShiftDetailComponent from './ShiftDetailComponent';

const mapStateToProps = ({
  scheduleCalendarReducer,
}) => {
  return {
    allUsers: scheduleCalendarReducer.allUsers.users,
    applicants: scheduleCalendarReducer.applicants.data,
  };
};

const mapDispatchToProps = (dispatch) => {

  const loadApplicantsForShiftDispatch = (shiftId) => {
    dispatch(scheduleCalendarOperations.fetchApplicantsForShiftOperation(shiftId));
  }
  
  return {
    loadApplicantsForShiftDispatch,
  };
};

const ShiftDetailComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {
    allUsers,
    applicants,
    loadApplicantsForShiftDispatch,
  } = props;

  return <ShiftDetailComponent
    allUsers = {allUsers}
    applicants = {applicants}
    loadApplicantsForShiftDispatch = {loadApplicantsForShiftDispatch}
    {...props}
  /> 
});

export default ShiftDetailComponentContainer;