import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { formatForWeekPicker } from '../../../helpers/date-time';
import { FormattedMessage, injectIntl } from 'react-intl';
import './WeekPickerComponent.scss';

const WeekPickerComponent = ({
  back,
  forward,
  currentDateRangeEnd,
  currentDateRangeStart,
  goToday, 
}) => {

const todayText = ( <FormattedMessage id="Header.today" defaultMessage="Today" />);
  
  return (
  currentDateRangeStart !== null && <div className="week-picker">

    <button onClick={goToday} className='today-button' >
      {todayText}
    </button>

    <span className="week-picker--current-date-range">
      {`${formatForWeekPicker(
        moment(currentDateRangeStart),
        moment(currentDateRangeEnd)
      )}`}
    </span>

    <span className="week-picker--left-arrow">
      <button
        type="button"
        className="week-picker--back-button week-picker--button"
        onClick={back}
      >
        <img src="/icons/week-picker-left.svg" />
      </button>
    </span>
    <span className="week-picker--right-arrow">
      <button
        type="button"
        className="week-picker--forward-button week-picker--button"
        onClick={forward}
      >
        <img src="/icons/week-picker-right.svg" />
      </button>
    </span>
  </div>
);
};

export default WeekPickerComponent;

WeekPickerComponent.propTypes = {
  back: PropTypes.func.isRequired,
  forward: PropTypes.func.isRequired,
};
