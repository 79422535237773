import moment from 'moment';

const shiftsInSchedule = (shifts) => {
    const result = shifts.filter( (shift) => shouldShiftBeCountedTowardsTotalHours(shift));
    return result;
}

const populateTotalScheduledHoursForWeek = (shifts, filteredUsers, filteredUnregisteredUsers, initialStatsByWeekday) => {

  let totalHoursForWeek = 0;

  shifts.forEach(shift => {
    if( shouldShiftBeCountedTowardsTotalHours(shift) === true && doesShiftBelongToUserSubsets(shift, filteredUsers, filteredUnregisteredUsers) === true ){

      const startDate = new Date(shift.start_at);
      const endDate = new Date(shift.end_at);
      const weekday = startDate.toLocaleDateString('en-US', { weekday: 'long' });

      // Check if unregistered_user_id is available, otherwise use owner_id
      const userId = shift.unregistered_user_id || shift.owner_id;

      const hoursDifference = (endDate - startDate) / (1000 * 60 * 60);

      if( initialStatsByWeekday != null ){
        initialStatsByWeekday[weekday].uniqueUsers.add(userId);
        initialStatsByWeekday[weekday].totalHours += hoursDifference;
      }

      totalHoursForWeek += hoursDifference;
    }
  });

  initialStatsByWeekday["WeekTotal"] = totalHoursForWeek;

  return initialStatsByWeekday;
}


//Need to add guardrails for start and end range
const numberOfUsersScheduledForWeek = (shifts, filteredUsers, filteredUnregisteredUsers) => {

  var totalScheduledUsers = 0;
  var registeredUserIds = new Set(); 
  var unregisteredUserIds = new Set();

  shifts.forEach(shift => {
    if( shouldShiftBeCountedTowardsTotalHours(shift) === true && doesShiftBelongToUserSubsets(shift, filteredUsers, filteredUnregisteredUsers) === true ){
      //unregistered user shift
      if( isUnregisteredUserShift(shift) == true ) {
        const unregUserId = currentOwnerIdForShift(shift);
        unregisteredUserIds.add(unregUserId);
      //registered user shift
      }else{
        const regUserId = currentOwnerIdForRegisteredShift(shift);
        registeredUserIds.add(regUserId);
      }
    }
  });

  totalScheduledUsers = registeredUserIds.size + unregisteredUserIds.size; 
  return totalScheduledUsers;
}

const usersNotScheduledForCurrentWeekSelector = (shifts, startDate, endDate, users) => {

    if(shifts.length == 0){
        return users;
    }else {
        console.log("Number of Users Before Removal: " + users.length);
        const resultUsers = users.slice();

        for(let i=0; i<shifts.length; i++) {
            let shift = shifts[i];
            const shiftStart = moment(shift.start_at);

            if( shiftStart.isAfter(startDate) && shiftStart.isBefore(endDate) ){

              let lookUpUserId = null;
              
              if( shift.trade_status === 'inventory' || shift.trade_status === 'called_off' ){
                lookUpUserId = shift.owner_id;
              }else if ( shift.name === 'shift' ){

                if( shift.trade_status === 'covered' || shift.trade_status === 'approved' ){
                  lookUpUserId = shift.taker_id;
                }else if ( shift.trade_status === 'posted' || shift.trade_status === 'rejected' || shift.trade_status === 'pending' ) {
                  lookUpUserId = shift.owner_id;
                }

              }else if ( shift.name === 'open_shift' ){

                if( shift.trade_status === 'approved' || shift.trade_status === 'covered' ) {
                  lookUpUserId = shift.taker_id;
                }
              }

              if( lookUpUserId != null){
                const idx = resultUsers.findIndex((user) => user.owner_id === lookUpUserId);
                if( idx > -1 ){
                   resultUsers.splice(idx, 1); 
                }
              }

            }
        }
        console.log("Number of Users After Removal: " + resultUsers.length);
        return resultUsers;
    }
};

const shouldShiftBeShownForRegisteredUser = (shift, user) => {
  if( user == null ){
    return false;
  }
  // Inventory Shift
  if( shift.trade_status === 'inventory' ) {
    if( shift.owner_id === user.owner_id ) {
      return true;
    }
  }else if ( shift.trade_status === 'unpublished_inventory' ){
    if( shift.owner_id === user.owner_id ){
      return true;
    }
  }else if (shift.trade_status === 'unregistered_unpublished_inventory' ){
    if( shift.unregistered_user_id === user.owner_id ){
      return true;
    }
  // Team Shift
  }else if ( shift.name === 'shift' ) {
    if( shift.taker_id === user.owner_id && ( shift.trade_status === 'covered' || shift.trade_status === 'approved' ) ) {
      return true;
    }else if ( shift.owner_id === user.owner_id && (shift.trade_status === 'posted' || shift.trade_status === 'rejected' || shift.trade_status === 'pending' ) ){
      return true;
    }
  // Open Shift
  }else if ( shift.name === 'open_shift' ) {
    if( shift.taker_id === user.owner_id && (shift.trade_status === 'approved' || shift.trade_status === 'covered' ) ) {
      return true;
    }
  }else if ( shift.trade_status === 'called_off' ){
    if( shift.owner_id === user.owner_id ) {
      return true;
    }
  }

  return false;
}

const shouldShiftBeCountedTowardsTotalHours = (shift) => {
   // Inventory Shift
   if( shift.trade_status === 'inventory' ) {
      return true;
  // Team Shift
  }else if ( shift.name === 'shift' ) {
    if( shift.trade_status === 'covered' || shift.trade_status === 'approved' )  {
      return true;
    }else if ( shift.trade_status === 'posted' || shift.trade_status === 'rejected' || shift.trade_status === 'pending' ){
      return true;
    }
  // Open Shift
  }else if ( shift.name === 'open_shift' ) {
    if( shift.trade_status === 'approved' || shift.trade_status === 'covered' )  {
      return true;
    }
  }else if ( shift.trade_status == 'unregistered_user_shift_inventory' && shift.unregistered_user_id !== null ){
      return true;
  }else if ( shift.trade_status === 'unpublished_inventory' ){
      return true;
  }else if ( shift.trade_status === 'unregistered_unpublished_inventory' ){
      return true;
  }
  return false; 
}

const currentOwnerIdForRegisteredShift = (shift) => {
  // Inventory Shift
  if( shift.trade_status === 'inventory' ) {
    return shift.owner_id;
  }else if ( shift.trade_status === 'called_off') {
    return shift.owner_id;
// Team Shift
  }else if ( shift.name === 'shift' ) {
    if( shift.trade_status === 'covered' || shift.trade_status === 'approved' )  {
      return shift.taker_id;
    }else if ( shift.trade_status === 'posted' || shift.trade_status === 'rejected' || shift.trade_status === 'pending' ){
      return shift.owner_id;
    }
  // Open Shift
  }else if ( shift.name === 'open_shift' ) {
    if( shift.trade_status === 'approved' || shift.trade_status === 'covered' )  {
      return shift.taker_id;
    }
  }else if ( shift.trade_status === 'unpublished_inventory' ){
    return shift.owner_id;
  }
  return null;
}

const isUnregisteredUserShift = (shift)  => {
  return shift.trade_status == 'unregistered_user_shift_inventory' || shift.trade_status == 'unregistered_unpublished_inventory';
}

const doesShiftBelongToUserSubsets = (shift, filteredUsers, filteredUnregisterdUsers) => {
  if ( isUnregisteredUserShift(shift) == true ) { 
    if ( shift.hasOwnProperty('unregistered_user_id') ){
      const numberOfMatchingUsers = filteredUnregisterdUsers.filter( (unregUser) => unregUser.id == shift.unregistered_user_id);
      return numberOfMatchingUsers.length>0;
    }else if ( shift.hasOwnProperty('unregistered_user')){
      const numberOfMatchingUsers = filteredUnregisterdUsers.filter( (unregUser) => unregUser.id == shift.unregistered_user.id);
      return numberOfMatchingUsers.length>0;
    }else{
      return false;
    }
  }else{
    const registeredOwnerId = currentOwnerIdForRegisteredShift(shift);
    const numberOfMatchingUsers = filteredUsers.filter( (user) => user.owner_id == registeredOwnerId);
    return numberOfMatchingUsers.length>0;
  }
}

const currentOwnerIdForShift = (shift) => {

  if ( isUnregisteredUserShift(shift) == true ) { 
    if ( shift.hasOwnProperty('unregistered_user_id') ){
      return shift.unregistered_user_id;
    }else if ( shift.hasOwnProperty('unregistered_user')){
      return shift.unregistered_user.id;
    }else{
      return null;
    }
  }else{
    return currentOwnerIdForRegisteredShift(shift);
  }
}

const compareTimes = (startTimeA, startTimeB) => {
  if( startTimeA.isBefore(startTimeB) ){
    return -1;
  }else if (startTimeA.isAfter(startTimeB)){
    return 1;
  }else{
    return 0;
  }
}

const sortedShiftsByDOW = (rangeStart, sortDOW, shifts) => {
  const dayToSortBy = rangeStart.clone().add(sortDOW - 1, 'days');

  const filteredShifts = shifts.filter( (item) => {
    return (shouldShiftBeCountedTowardsTotalHours(item));
  });

  const sortedShifts = [...filteredShifts].sort( (a, b) => {
    const startTimeA = moment(a.start_at);
    const startTimeB = moment(b.start_at);
    // console.log(startTimeA.format("MMM Do YY")+ ' : '+ startTimeB.format("MMM Do YY"));
      if( dayToSortBy.isSame(startTimeA, 'day') ) {
        if( dayToSortBy.isSame(startTimeB, 'day') ){
            return compareTimes(startTimeA, startTimeB);
        }else{
          return  -1;
        }
      }else if ( dayToSortBy.isSame(startTimeB, 'day') ){
        return 1;
      }else {
        return compareTimes(startTimeA, startTimeB);
      }
  });
  return sortedShifts;
}

const isSearchOrFilterApplied = (searchTerm, selectedDepartment) => {
  if( selectedDepartment == null && searchTerm.length == 0 ){
    return false;
  }else{
    return true;
  }
}

const filteredByDepartment = (allUsers, selectedDepartment) => {
  if( selectedDepartment == null ){
    return allUsers;
  }else{
    const filtered = allUsers.filter( (user) => {
      return selectedDepartment.user_ids.includes(user.owner_id);
    });
    return filtered;
  }
}

const filteredUsers = (allUsers, selectedDepartment, searchTerm) => {
  const lowercaseSearchTerm = searchTerm.toLowerCase();
  const usersFilteredByDepartment = filteredByDepartment(allUsers, selectedDepartment);
  const usersFilteredBySearch = usersFilteredByDepartment.filter( (user) => {
    const fullName = user.first_name + ' ' + user.last_name;
    const lowercaseFullName = fullName.toLowerCase();
    return (lowercaseFullName.includes(lowercaseSearchTerm) || 
    (user.position != null && user.position.toLowerCase().includes(lowercaseSearchTerm)) || 
     ( user.badge_id != null && user.badge_id.includes(lowercaseSearchTerm)) );
  });
  return usersFilteredBySearch;
}

const filteredUnregisteredUsers = (unregisteredUsers, selectedDepartment, searchTerm) => {
  const lowercaseSearchTerm = searchTerm.toLowerCase();
  const unregFilteredByDept =  unreigisterdUsersFilteredByDepartment(unregisteredUsers, selectedDepartment);
  const unregisteredUsersFilteredBySearch = unregFilteredByDept.filter( (user) => {
    const fullName = user.first_name + ' ' + user.last_name;
    const lowercaseFullName = fullName.toLowerCase();
    return (lowercaseFullName.includes(lowercaseSearchTerm) || 
    (user.internal_id != null && user.internal_id.includes(lowercaseSearchTerm)) );
  });
  return unregisteredUsersFilteredBySearch;
}

const unreigisterdUsersFilteredByDepartment = (unregisteredUsers, selectedDepartment) => {
  // console.log(JSON.stringify(unregisteredUsers));
  if( selectedDepartment == null ){
    return unregisteredUsers;
  }else{
    return [];
  }
}

const sortUsersWithShiftOrder = (rangeStart, sortDOW, shifts, allUsers, unregisteredUsers) => {
  if( rangeStart !== null ){

    if( sortDOW == 0 ){
      return [].concat(allUsers.concat(unregisteredUsers));
    }

    const sortedShifts = sortedShiftsByDOW(rangeStart, sortDOW, shifts);

    var sortedUsers = [];
    sortedShifts.forEach((shift) => {
      var user;
      if ( (shift.hasOwnProperty('unregistered_user_id') )) {
        user = unregisteredUsers.find( userObj => {return userObj.id === shift.unregistered_user_id });
      }else if ( shift.hasOwnProperty('unregistered_user') )  {
        user = unregisteredUsers.find( userObj => {return userObj.id === shift.unregistered_user.id});
      }else{
        user = allUsers.find( userObj => {return userObj.owner_id === currentOwnerIdForRegisteredShift(shift) });
      }
      if ( sortedUsers.includes(user) === false && user !== null ){
        sortedUsers.push(user);
      }
    });

    allUsers.forEach( (user) => {
      if( sortedUsers.includes(user) === false && user !== null){
        sortedUsers.push(user);
      }
    });

    unregisteredUsers.forEach( (unregUser) => {
      if( sortedUsers.includes(unregUser) === false && unregUser !== null){
        sortedUsers.push(unregUser);
      }
    });

    console.log(sortedUsers);
    return sortedUsers;
  }

  return [];
}

const getDisplayableShiftsForUser = (shifts, user, showInventory) => {

  let firstFiltered = [];
  for (let i = 0; i < shifts.length; i++) {
    const shiftObject = shifts[i];

    if (shiftObject.hasOwnProperty('unregistered_user_id')) {
      if ( isUnregisteredUserShift(shiftObject) == true  && shiftObject.unregistered_user_id === user.id) {
        firstFiltered.push(shiftObject);
      }
    } else if (shiftObject.hasOwnProperty('unregistered_user')) {
      if ( isUnregisteredUserShift(shiftObject) == true  &&
        shiftObject.unregistered_user.id === user.id) {
        firstFiltered.push(shiftObject);
      }
    } else {
      if (shouldShiftBeShownForRegisteredUser(shiftObject, user)) {
        firstFiltered.push(shiftObject);
      }
    }
  }

  if ( showInventory !== 1 ) {
    firstFiltered = [];
  }

  return firstFiltered;
}

const getRowOfShiftsToDisplay = (shifts, user, rangeStart, showInventory) => {

  var firstShifts = [];
  var secondShifts = [];
  var thirdShifts = [];
  var fourthShifts = [];
  var fifthShifts = [];
  var sixthShifts = [];
  var lastColumnShifts = [];
  var totalHoursForWeekForUser = 0;

  if (shifts.length > 0) {
    const myShifts = getDisplayableShiftsForUser(shifts, user, showInventory);
    if (myShifts && myShifts.length > 0) {

      for (let i = 0; i < myShifts.length; i++) {
        var momentVal = moment(myShifts[i].start_at);
        momentVal.locale("en");

        const startDate = new Date(myShifts[i].start_at);
        const endDate = new Date(myShifts[i].end_at);

        const hoursDifference = (endDate - startDate) / (1000 * 60 * 60);

        totalHoursForWeekForUser += hoursDifference;
        const dayDiff = momentVal.diff(rangeStart, 'days');
        switch (dayDiff) {
          case 0:
            firstShifts.push(myShifts[i]);
            break;
          case 1:
            secondShifts.push(myShifts[i]);
            break;
          case 2:
            thirdShifts.push(myShifts[i]);
            break;
          case 3:
            fourthShifts.push(myShifts[i]);
            break;
          case 4:
            fifthShifts.push(myShifts[i]);
            break;
          case 5:
            sixthShifts.push(myShifts[i]);
            break;
          case 6:
            lastColumnShifts.push(myShifts[i]);
            break;
        }
      }
    }
  } 
  return [totalHoursForWeekForUser, firstShifts, secondShifts, thirdShifts, fourthShifts, fifthShifts, sixthShifts, lastColumnShifts];
}

const getDisplayableOpenShifts = (shifts, allowPostOpenShift) => {

  let firstFiltered = [];
  for (let i = 0; i < shifts.length; i++) {
    const shiftObject = shifts[i];

    // unpublished_posted
    if (shiftObject.name == 'open_shift' && shiftObject.parent_card == true  ) {
        firstFiltered.push(shiftObject);
    }
  }

  if ( allowPostOpenShift !== 1 ){
    firstFiltered = [];
  }

  return firstFiltered;
}

const getRowOfOpenShiftsToDisplay = (shifts, rangeStart, allowPostOpenShift) => {

  var firstShifts = [];
  var secondShifts = [];
  var thirdShifts = [];
  var fourthShifts = [];
  var fifthShifts = [];
  var sixthShifts = [];
  var lastColumnShifts = [];
  var totalHoursForWeekForUser = 0;

  if (shifts.length > 0) {
    const myShifts = getDisplayableOpenShifts(shifts, allowPostOpenShift);

    if (myShifts && myShifts.length > 0) {
      for (let i = 0; i < myShifts.length; i++) {
        var momentVal = moment(myShifts[i].start_at);
        momentVal.locale("en");

        const startDate = new Date(myShifts[i].start_at);
        const endDate = new Date(myShifts[i].end_at);

        const hoursDifference = (endDate - startDate) / (1000 * 60 * 60);

        totalHoursForWeekForUser += hoursDifference;
        const dayDiff = momentVal.diff(rangeStart, 'days');
        switch (dayDiff) {
          case 0:
            firstShifts.push(myShifts[i]);
            break;
          case 1:
            secondShifts.push(myShifts[i]);
            break;
          case 2:
            thirdShifts.push(myShifts[i]);
            break;
          case 3:
            fourthShifts.push(myShifts[i]);
            break;
          case 4:
            fifthShifts.push(myShifts[i]);
            break;
          case 5:
            sixthShifts.push(myShifts[i]);
            break;
          case 6:
            lastColumnShifts.push(myShifts[i]);
            break;
        }
      }
    }
  } 
  return [totalHoursForWeekForUser, firstShifts, secondShifts, thirdShifts, fourthShifts, fifthShifts, sixthShifts, lastColumnShifts];
}

const nextWeek = (momentDate) => {
    const cloned= momentDate.clone();
    const result = cloned.add(7, 'days');
    return result;
}

const previousWeek = (momentDate) => {
    const cloned= momentDate.clone();
    const result = cloned.subtract(7, 'days');
    return result;
}

export default {
    usersNotScheduledForCurrentWeekSelector,
    shouldShiftBeShownForRegisteredUser,
    nextWeek,
    previousWeek,
    shouldShiftBeCountedTowardsTotalHours,
    sortUsersWithShiftOrder,
    getDisplayableShiftsForUser,
    getRowOfShiftsToDisplay,
    filteredUsers,
    filteredUnregisteredUsers,
    doesShiftBelongToUserSubsets,
    isSearchOrFilterApplied,
    currentOwnerIdForShift,
    isUnregisteredUserShift,
    populateTotalScheduledHoursForWeek,
    numberOfUsersScheduledForWeek,
    shiftsInSchedule,
    currentOwnerIdForRegisteredShift,
    getRowOfOpenShiftsToDisplay,
};