import { axiosInstance } from '../api-calls';
import { BASE_URL, API_SERVER_VERSION } from '../../constants';
import { em } from 'polished';

export const signUpWithEmail = ({
  first_name,
  last_name,
  email,
  password,
  finalPhone,
  locale,
  signup_from,
  isChecked,
  location,
}) =>
  axiosInstance.request({
    method: 'POST',
    url: `https://${BASE_URL}/api/users`,
    params: { invite: isChecked },
    headers: { locale },
    data: {
      api_user: {
        provider: 'email',
        first_name,
        last_name,
        unconfirmed_email: email,
        unconfirmed_phone_number: finalPhone,
        password,
        password_confirmation: password,
        email_host: window.location.host,
        signup_from: signup_from,
        register_location: {
          swift_code: location.swift_code,
        },
      },
    },
  });

export const signUpWithEmailPhone = ({
  first_name,
  last_name,
  email,
  finalPhone,
  password,
  placeDetails,
  isChecked,
  locale,
  signup_from,
  zipCode,
  address,
}) => {
  const registerLocation = !placeDetails
    ? { location_name: address, formatted_address: zipCode }
    : {
      location_name: placeDetails.googleBusinessName,
      latitude: placeDetails.latitude,
      longitude: placeDetails.longitude,
      google_map_id: placeDetails.googlePlaceID,
      formatted_address: placeDetails.googleFormattedAddress,
    };

  return axiosInstance.request({
    method: 'POST',
    url: `https://${BASE_URL}/api/users`,
    headers: { locale },
    params: { invite: isChecked },
    data: {
      api_user: {
        provider: 'email',
        first_name,
        last_name,
        unconfirmed_email: email,
        unconfirmed_phone_number: finalPhone,
        password,
        password_confirmation: password,
        email_host: window.location.host,
        signup_from: signup_from,
        register_location: registerLocation,
      },
    },
  });
};

export const signUpConfirmation = (confirmation_token) =>
  axiosInstance.request({
    url: `https://${BASE_URL}/api/users/confirmation`,
    params: {
      confirmation_token,
    },
  });

export const randomlyGeneratePassword = () => {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';

  for (let i = 0; i < 9; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const signInWithEmail = (email, password) =>
  axiosInstance.request({
    method: 'POST',
    url: `https://${BASE_URL}/api/users/sign_in`,
    data: {
      api_user: {
        email,
        password,
      },
    },
  });


export const signUpResendConfirmation = (email) =>
  axiosInstance.request({
    url: `https://${BASE_URL}/api/users/confirmation`,
    method: 'POST',
    data: {
      api_user: {
        email,
      },
    },
  });

export const signInAttempt = (phone_number) => {
  const charactersToStrip = /[-()\s+]/g;
  const strippedPhoneNumber = phone_number.replace(charactersToStrip, '');

  return axiosInstance.request({
    url: `https://${BASE_URL}/api/code_auth/${strippedPhoneNumber}`,
    method: 'GET',
  });
};

export const SignInConfirm = (phoneNumber, pin) => {
  const charactersToStrip = /[-()\s+]/g;
  const strippedPhoneNumber = phoneNumber.replace(charactersToStrip, '');
  const api_user = {
    phone_number: strippedPhoneNumber,
    sign_in_code: pin,
  };

  return axiosInstance.request({
    url: '/api/users/sign_in',
    method: 'POST',
    data: {
      api_user,
    },
  });
};

export const SuperLogin = (phoneNumber) => {
  return axiosInstance.request({
    url: `/api/users/super_login?login=${phoneNumber}`,
    headers: { 'God-Key': 'BCoQ7nuYrRHo6VTBuR9XvURxCpfQB92SomLQqbx61l4uYWVOHCPuKdQB2784Oxzn3j9fHV3DZRhXzv8PmDfrae1MoNAlaTdH' },
    method: 'GET',
  });
}

/**
 * Renew SSO session
 * Takes an SSO authentication code and sends it to the backend,
 * receiving a success or failure
 */
export const RenewSSOsession = (company, code) =>
  axiosInstance.request({
    url: `https://${BASE_URL}/api/customer/user_role/sso_sessions/renew?sso_provider_name=${company}&code=${code}`,
    method: 'POST',
  });

/**
 * Makes a request to reset the user's password.
 * If successful, this will send an email to the user
 *
 * 201 - success - response object will not include anything in it
 * 422 - unprocessable entity. Check response object for error messages
 * @param { String } email - the email of the user
 *
 * @return { Promise }
 */
export const StartPasswordReset = (email) =>
  axiosInstance.request({
    method: 'POST',
    url: `https://${BASE_URL}/api/users/password`,
    data: {
      api_user: {
        email,
        email_host: window.location.host,
      },
    },
  });

export const ConfirmPasswordReset = (
  password,
  password_confirmation,
  reset_password_token
) =>
  axiosInstance.request({
    method: 'PUT',
    url: `https://${BASE_URL}/api/users/password`,
    data: {
      api_user: {
        password,
        password_confirmation,
        reset_password_token,
      },
    },
  });
