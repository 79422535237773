import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import styled from './styled';
import ReactDOM from 'react-dom';
import Modal from 'components/GeneralComponents/Modal';
import constants from '../../../../UserManagement/constants'
import '../RowComponent.scss';

import {Mixpanel} from '../../../../../Mixpanel';

class UserCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: true,
      showConfirm: false,
      selectedAction: null,
      mouseOverUser: false,
      userInfoPos: null,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { schedule } = this.props;

    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {

  }

  componentWillUnmount() {

    this._isMounted = false;
  }

  mouseEnterUser = (event) => {
    const { user, } = this.props;
    const { mouseOverUser, } = this.state;

    let winTop = '';
    let winLeft = '';
    const winW = '180px';//`50%`;
    const winH = `60px`; //94.9% viewport height

    
    winTop = event.clientY;
    winLeft = event.clientX;
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ userInfoPos: winData });
    if (user.position != null) {
      if (user.badge_id != null) {
        if (user.position.length + user.badge_id.length > 20) {
          this.setState({ mouseOverUser: true });
        }
      } else {
        if (user.position.length > 20) {
          this.setState({ mouseOverUser: true });
        }
      }

    }
  }

  mouseLeaveUser2 = (event) => {
    const { mouseOverUser, } = this.state;
    this.setState({ mouseOverUser: false });
  }

  handleInviteClick = (user) => {
    const { userActionTypes, } = constants;
    const {handleShowSMSInviteModal} = this.props;
    const inviteText = (<FormattedMessage
      id="LinkUser.invite"
      defaultMessage="Invite User"
    />);
    // const action = { name: userActionTypes.INVITE_USER, body: inviteText };
    // this.setState({ showConfirm: true, selectedAction: action });
    handleShowSMSInviteModal(user);

    Mixpanel.track('Clicked invite on schedule user cell', {'id': user.id});
  }

  handleLinkScheduleClick = (user) => {
    const { selectedUnregisteredUserToLink } = this.props;
    selectedUnregisteredUserToLink(user);
    Mixpanel.track('Link unregistered user', {'user_id': user.id});
    event.preventDefault();
  }

  renderUserInfoMouseTip = () => {
    const { user, } = this.props;
    const { userInfoPos, } = this.state;
    const userInfo = (
      <div className="mouse-tip__wrap">
        <label className="mouse-tip__position">{user.position}</label>
      </div>
    );

    if (userInfoPos) {
      const containerStyle = {
        top: userInfoPos.top,
        left: userInfoPos.left,
        minWidth: userInfoPos.width,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        zIndex: 2,
        borderRadius: '5px',
        paddingTop: '5px',
        //border: '1px solid red',
      };

      return (
        <div style={containerStyle}>
          <div className="mouse-tip__top-wrap">{userInfo}</div>
        </div>
      );
    }
  }

  render() {
    const { user, totalHoursForWeekForUser } = this.props;
    const { mouseOverUser, showConfirm, selectedAction } = this.state;
    const userImageUrl = user && user.profile_image_thumb_url ?
      user.profile_image_thumb_url : '/icons/default-profile-thumb.svg';
    var userName = `${user.first_name} ${user.last_name}`;

    const position = user && user.position ? user.position : 'None';
    const badgeId = user && user.badge_id ? user.badge_id : '';
    const placeholder = '';
    let bottomLine = null;

    const inviteText = (<FormattedMessage id="LinkUser.invite" defaultMessage="Invite User" />);

    const linkText = (<FormattedMessage id="LinkUser.link" defaultMessage="Link User" />);
    let emptyBottomLine = true;
    if (user) {
      if (user.status == 'unregistered') {
        userName = user.first_name + ' ' + user.last_name;

        // console.log("Link Status:" + user.unregisteredUserStatus);
        emptyBottomLine = false;
        if (user.unregisteredUserStatus === 'linking') {
          bottomLine = (
            <styled.BottomLineWrap >
              <styled.BottomLineTextDisabledLinks onClick={() => { }}>{linkText}</styled.BottomLineTextDisabledLinks>
              <styled.Dot />
              <styled.BottomLineTextDisabledLinks onClick={() => { }}>{inviteText}</styled.BottomLineTextDisabledLinks>
            </styled.BottomLineWrap>);

        } else {
          bottomLine = (
            <styled.BottomLineWrap >
              <styled.BottomLineTextUserLinks onClick={() => { this.handleLinkScheduleClick(user) }}>{linkText}</styled.BottomLineTextUserLinks>
              <styled.Dot />
              <styled.BottomLineTextUserLinks onClick={() => { this.handleInviteClick(user) }}>{inviteText}</styled.BottomLineTextUserLinks>
            </styled.BottomLineWrap>);
        }
      }
    }
    return (
      <div>
        <styled.TopWrap>
          <styled.OwnerAvatar src={userImageUrl} />
          <styled.OwnerInfoWrap onMouseEnter={this.mouseEnterUser} onMouseLeave={this.mouseLeaveUser2} >

            <styled.OwnerInfoTopLine emptyBottomLine={emptyBottomLine}>{userName}</styled.OwnerInfoTopLine>
            {position != 'None' && <div className='position-line'>{position}</div>}
            <div className='labor-hours-num'>{totalHoursForWeekForUser.toFixed(2) + " h"}</div>
            {!emptyBottomLine && bottomLine}
            {mouseOverUser && <Modal> {this.renderUserInfoMouseTip()} </Modal>}

          </styled.OwnerInfoWrap>
        </styled.TopWrap>
      </div>
    );
  }
}


UserCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(UserCell);