import { connect } from 'react-redux';

import LikersComponent from './LikersComponent';

import {
  teamFeedActions,
  teamFeedOperations,
  teamFeedSelectors,
} from '../../duck';

const mapStateToProps = ({ teamFeedReducer, appReducer, locationReducer, }) => ({
  comments: teamFeedSelectors.getOrderedComments(teamFeedReducer),
  activePost: teamFeedSelectors.getActivePost(teamFeedReducer),
  active: teamFeedReducer.active,
  locationSettings: appReducer.location.settings,
  locationId: locationReducer.location_code,
  allUsers: appReducer.allUsers.users,
  onlineUsers: appReducer.onlineUsers.users,
});

const mapDispatchToProps = (dispatch) => {
  const fetchPostLikeList = (post_id) => {
    dispatch(teamFeedOperations.getPostLikeList(post_id));
  };

  const fetchPostReaderList = (post_id) => {
    dispatch(teamFeedOperations.getPostReaderList(post_id));
  };

  const resetActiveSidePane = () => {
    dispatch(teamFeedActions.updateActiveSidePane(''));
  };

  return {
    fetchPostLikeList,
    fetchPostReaderList,
    resetActiveSidePane,
  };
};

const LikersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LikersComponent);

//export default connect(mapStateToProps, mapDispatchToProps)(LikersComponent);

export default LikersContainer;
