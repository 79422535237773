import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Modal from 'components/GeneralComponents/Modal';
import { UploadSchedulerFile, } from '../../../../helpers/api-calls/schedule-calls';
import {UploadImage, UploadPDF, } from '../../../../helpers/api-calls/feed-calls';
import Confirm from './Confirm';
import ConfirmStep2 from './ConfirmStep2';
import ConfirmOperation from './ConfirmOperation';
import SearchDialogue from './SearchDialogue';
import constants from '../../constants';
import { MAX_USERS_IN_BATCH, } from '../../constants';
import './Header.scss';
import styled from './styled';

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      searchTerm: '',
      openLocationMenu: false,
      locInfo: '',
      openActionMenu: false,
      openOperationMenu: false,
      openSortMenu: false,
      openFilterMenu: false,
      showConfirm: false,
      showConfirmOperation: false,
      selectedAction: null,
      showConfirmStep2: false,
      addOperation: false,
      dropdownPos: null,
      actionMenuPos: null,
      selectedSort: null,
      ascending: true,
      showFilterDept: false,
      showFilterEmail: false,
      openSearchWin: false,
      selectedFilter: null,
      selectedDept: null,
      selectedEmail: null,
      selectedEmployeeId: null,
      selectedGroup: null,
      selectedJobTitle: null,
      selectedSkill: null,
      showFilterEmployeeId: false,
      showFilterGroup: false,
      showFilterJobTitle: false,
      showFilterSkill: false,
    };

    this.messages = defineMessages({
      placeholder: {
        id: 'Header.placeholder',
        defaultMessage: 'Search',
      },
    });

    this.topRowRef = React.createRef();
    this.openRef = React.createRef();
    this.actionRef = React.createRef();
    this.addRef = React.createRef();
    this.removeRef = React.createRef();
    this.operationRef = React.createRef();
    this.sortRef = React.createRef();
    this.sortWinRef = React.createRef();
    this.filterWinRef = React.createRef();
    this.filterRef = React.createRef();
    this.deptRef = React.createRef();
    this.deptWinRef = React.createRef();
    this.emailRef = React.createRef();
    this.employeeIdRef = React.createRef();

    this._isMounted = false;

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { locationData,  locations, requestedOperation, locationSettings, notifySearchTermChanged, notifySortTypeChanged, } = this.props;
    const { searchTerm} = this.props;
    const { sortTypes, } = constants;

    this._isMounted = true;
    notifySearchTermChanged(searchTerm);
    if(requestedOperation && requestedOperation.length > 0) {
      this.setState({showConfirmOperation: true});
    }
    /*
    const status = (<FormattedMessage
      id="Header.status"
      defaultMessage="Status"
    />);
    */
    const userRole = (<FormattedMessage
      id="Header.userRole"
      defaultMessage="User Role"
    />);

    //const itemStatus = {name: sortTypes.STATUS, body: status}; 
    const itemUserRole = {name: sortTypes.USER_ROLE, body: userRole};
    this.setState({selectedSort: itemUserRole});
    notifySortTypeChanged(itemUserRole);

    document.addEventListener("mousedown", this.handleClickOutside);

  }

  componentDidUpdate(prevProps, prevState) {
    const { requestedOperation, departments,  selectedItem, selectedUsers, notifySearchTermChanged, channels, } = this.props;
    const { openActionMenu, openOperationMenu, addOperation, openLocationMenu, openSortMenu, openFilterMenu, 
            showFilterDept, showFilterEmail, showFilterEmployeeId, showFilterGroup, showFilterJobTitle, showFilterSkill, } = this.state;
    const { operationTypes } = constants;

    if(prevProps.requestedOperation !== requestedOperation) {
      if(requestedOperation && requestedOperation.length > 0) {
        this.setState({showConfirmOperation: true});
       }
    }

    if(prevState.openActionMenu !== openActionMenu) {
      if(openActionMenu) {
        let winTop = ''; //`${(window.innerHeight - 612)/2}px`;
        let winLeft = ''; //`${(window.innerWidth - 1200)/2}px`;
        const winW = '200px';//`50%`;
        const winH = `100px`; //94.9% viewport height

        if(this.openRef && this.openRef.current) {
          const domNode = ReactDOM.findDOMNode(this.openRef.current);
          const output =domNode.getBoundingClientRect();
          if(output) {
            winTop = `${output.y + output.height}px`;
            winLeft = `${output.x}px`;
          }
        }
        const winData = {
          top: winTop,
          left: winLeft,
          width: winW,
          height: winH,
        }
        this.setState({actionMenuPos: winData});
      }
    }

    if(prevState.openOperationMenu !== openOperationMenu) {
      let top = `400px`; //`${(window.innerHeight - 612)/2}px`;
      let left = `20px`; //`${(window.innerWidth - 1200)/2}px`;
      let width = '100px';//`50%`;
      let height = `100px`; //94.9% viewport height

      if(addOperation) {
        if(this.addRef && this.addRef.current) {
          const domNodeAdd = ReactDOM.findDOMNode(this.addRef.current);
          const output =domNodeAdd.getBoundingClientRect();
          if(output) {
            top = `${output.y + output.height + 15}px`;
            left = `${output.x}px`;
          }
        }
      }else {
        if(this.removeRef && this.removeRef.current) {
          const domNodeRemove = ReactDOM.findDOMNode(this.removeRef.current);
          const output1 =domNodeRemove.getBoundingClientRect();
          if(output1) {
            top = `${output1.y + output1.height + 15}px`;
            left = `${output1.x}px`;
          }
        }
      }
      const pos = {
        top: top,
        left: left,
        width: width,
        height: height,
      }
      this.setState({dropdownPos: pos});
    }

    if(prevState.openLocationMenu !== openLocationMenu) {
      if(openLocationMenu) {
        let top1 = `400px`; //`${(window.innerHeight - 612)/2}px`;
        let left1 = `20px`; //`${(window.innerWidth - 1200)/2}px`;
        const width1 = '250px';//`50%`;
        const height1 = `200px`; //94.9% viewport height

        if(this.topRowRef && this.topRowRef.current) {
          const domNode = ReactDOM.findDOMNode(this.topRowRef.current);
          const output =domNode.getBoundingClientRect();
          if(output) {
            top1 = `${output.y + output.height}px`;
          }
        }
        const pos1 = {
        top: top1,
        left: left1,
        width: width1,
        height: height1,
        }
        this.setState({dropdownPos: pos1});
      }
    }

    if(prevState.openSortMenu !== openSortMenu) {
      if(openSortMenu) {
        let top2 = `400px`; //`${(window.innerHeight - 612)/2}px`;
        let left2 = `20px`; //`${(window.innerWidth - 1200)/2}px`;
        let width2 = '400px';//`50%`;
        const height2 = `128px`; //94.9% viewport height

        if(this.sortRef && this.sortRef.current) {
          const domNode = ReactDOM.findDOMNode(this.sortRef.current);
          const output2 =domNode.getBoundingClientRect();
          if(output2) {
            top2 = `${output2.y + output2.height}px`;
            left2 = `${output2.x}px`;
            width2 = `${output2.width}px`;
          }
        }
        const pos2 = {
        top: top2,
        left: left2,
        width: width2,
        height: height2,
        }
        this.setState({dropdownPos: pos2});
      }
    }

     if(prevState.openFilterMenu !== openFilterMenu) {
      if(openFilterMenu) {
        let top3 = `400px`; //`${(window.innerHeight - 612)/2}px`;
        let left3 = `20px`; //`${(window.innerWidth - 1200)/2}px`;
        let width3 = '200px';//`50%`;
        const height3 = `128px`; //94.9% viewport height

        if(this.filterRef && this.filterRef.current) {
          const domNode = ReactDOM.findDOMNode(this.filterRef.current);
          const output3 =domNode.getBoundingClientRect();
          if(output3) {
            top3 = `${output3.y + output3.height}px`;
            left3 = `${output3.x}px`;
            //width3 = `${output3.width}px`;
          }
        }
        const pos3 = {
        top: top3,
        left: left3,
        width: width3,
        height: height3,
        }
        this.setState({dropdownPos: pos3});
      }
    }

    if(prevState.showFilterDept !== showFilterDept) {
      if(showFilterDept) {
        let top4 = `400px`; //`${(window.innerHeight - 612)/2}px`;
        let left4 = `20px`; //`${(window.innerWidth - 1200)/2}px`;
        let width4 = '200px';//`50%`;
        const height4 = `128px`; //94.9% viewport height

        if(this.deptRef && this.deptRef.current) {
          const domNode = ReactDOM.findDOMNode(this.deptRef.current);
          const output4 =domNode.getBoundingClientRect();
          if(output4) {
            top4 = `${output4.y + output4.height + 5}px`;
            left4 = `${output4.x}px`;
            //width3 = `${output3.width}px`;
          }
        }
        const pos4 = {
        top: top4,
        left: left4,
        width: width4,
        height: height4,
        }
        this.setState({dropdownPos: pos4});
      }
    }

    if(prevProps.selectedItem !== selectedItem) {
      //this.setState({searchTerm: ''});
      //notifySearchTermChanged('');
    }

    if(prevProps.selectedUsers !== selectedUsers) {
      if(prevProps.selectedUsers.length > 0 && selectedUsers.length === 0) {
        this.setState({
          selectedFilter: null,
          selectedDept: null,
          selectedEmail: null,
          selectedEmployeeId: null,
          selectedGroup: null,
          selectedJobTitle: null,
          selectedSkill: null,
          showFilterDept: false, 
          showFilterEmail: false, 
          showFilterEmployeeId: false, 
          showFilterGroup:false, 
          showFilterJobTitle: false, 
          showFilterSkill: false
        });
      }
    }

    if(prevProps.channels !== channels) {
      //console.log('got');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { openActionMenu, openOperationMenu, openSortMenu, openFilterMenu, } = this.state;
    const { notifyDropDownOpened, } = this.props;

    if(this.openRef && this.openRef.current && !this.openRef.current.contains(event.target)) {
      if(this.actionRef && this.actionRef.current && !this.actionRef.current.contains(event.target)) {
        if(openActionMenu) {
          this.setState({openActionMenu: false});
          notifyDropDownOpened(false);
        }
      }
    }

    if(this.addRef && this.addRef.current && !this.addRef.current.contains(event.target)) {
      if(this.operationRef && this.operationRef.current && !this.operationRef.current.contains(event.target)) {
        if(openOperationMenu) {
          this.setState({openOperationMenu: false});
          notifyDropDownOpened(false);
        }
      }
    }

    if(this.removeRef && this.removeRef.current && !this.removeRef.current.contains(event.target)) {
      if(this.operationRef && this.operationRef.current && !this.operationRef.current.contains(event.target)) {
        if(openOperationMenu) {
          this.setState({openOperationMenu: false});
          notifyDropDownOpened(false);
        }
      }
    }

    if(this.sortRef && this.sortRef.current && !this.sortRef.current.contains(event.target)) {
      if(this.sortWinRef && this.sortWinRef.current && !this.sortWinRef.current.contains(event.target)) {
        if(openSortMenu) {
          this.setState({openSortMenu: false});
          notifyDropDownOpened(false);
        }
      }
    }

    if(this.filterRef && this.filterRef.current && !this.filterRef.current.contains(event.target)) {
      if(this.filterWinRef && this.filterWinRef.current && !this.filterWinRef.current.contains(event.target)) {
        if(openFilterMenu) {
          this.setState({openFilterMenu: false});
          notifyDropDownOpened(false);
        }
      }
    }
  }

  handleKeyPress = (event) => {
    const { notifySearchTermChanged, } = this.props;
    const { searchTerm, } = this.state;

    if(event.keyCode === 13) {
      notifySearchTermChanged(searchTerm);
    }
  }

  handleSearchChanged = (event) => {
    const { notifySearchTermChanged, } = this.props;
    event.preventDefault();
    this.setState({searchTerm: event.target.value});
    if(event.target.value === '') {
      notifySearchTermChanged('');
    }
  }

  handleCloseFilter = (filter) => {
    const { filterTypes, } = constants;
    const { notifyFiltersSelected, } = this.props;
    const { selectedDept, selectedEmail, selectedEmployeeId, selectedGroup, selectedJobTitle,  selectedSkill, openSearchWin, } = this.state;

    this.setState({openSearchWin: false});
    switch(filter) {
      case filterTypes.DEPARTMENT:
        this.setState({showFilterDept: false, selectedDept: null});
        notifyFiltersSelected(null, selectedEmail, selectedEmployeeId, selectedGroup, selectedJobTitle, selectedSkill);
        break;
      case filterTypes.EMAIL:
        this.setState({showFilterEmail: false, selectedEmail: null});
        notifyFiltersSelected(selectedDept, null, selectedEmployeeId, selectedGroup, selectedJobTitle, selectedSkill);
        break;
      case filterTypes.EMPLOYEE_ID:
        this.setState({showFilterEmployeeId: false, selectedEmployeeId: null});
        notifyFiltersSelected(selectedDept, selectedEmail, null, selectedGroup, selectedJobTitle, selectedSkill);
        break;
      case filterTypes.GROUP:
        this.setState({showFilterGroup: false, selectedGroup: null});
        notifyFiltersSelected(selectedDept, selectedEmail, selectedEmployeeId, null, selectedJobTitle, selectedSkill);
        break;
      case filterTypes.JOB_TITLE:
        this.setState({showFilterJobTitle: false, selectedJobTitle: null});
        notifyFiltersSelected(selectedDept, selectedEmail, selectedEmployeeId, selectedGroup, null, selectedSkill);
        break;
      case filterTypes.SKILL:
        this.setState({showFilterSkill: false, selectedSkill: null});
        notifyFiltersSelected(selectedDept, selectedEmail, selectedEmployeeId, selectedGroup, selectedJobTitle, null);
        break;
    }
    
  }

  handleAddFilterClick = (event) => {
    const { openFilterMenu, } = this.state;

    event.preventDefault();

    this.setState({openFilterMenu: !openFilterMenu});
  }

  handleHideFilterClick = (event) => {
    event.preventDefault();
    alert('clicked')
  }

  handleClearAllClick = (event) => {
    event.preventDefault();
    alert('clicked')
  }

  handleSortOrderChanged = (event) => {
    const { ascending, } = this.state;
    const { notifySortOrderChanged, } = this.props;
    this.setState({ascending: !ascending});
    notifySortOrderChanged(!ascending);
  }

  handleRefreshClick = (event) => {
    const { notifyRefreshRequested, notifyFiltersSelected, } = this.props;
    const { selectedFilter, selectedDept, selectedEmail, selectedEmployeeId, selectedGroup, selectedJobTitle, selectedSkill} = this.state;
    event.preventDefault();
    notifyRefreshRequested();
    this.setState({
      selectedFilter: null,
      selectedDept: null,
      selectedEmail: null,
      selectedEmployeeId: null,
      selectedGroup: null,
      selectedJobTitle: null,
      selectedSkill: null,
      showFilterDept: false,
      showFilterEmail: false,
      showFilterEmployeeId: false,
      showFilterGroup: false,
      showFilterJobTitle: false,
      showFilterSkill: false,
    });
  }

  handleArrowButtonClicked = (event) => {
    const { openLocationMenu, } = this.state;

    this.setState({openLocationMenu: !openLocationMenu});
  }

  handleLocationChange = (location) => {
    const { handleChangeLocation, } = this.props;
    const info = this.createLocationInfo(location);
    this.setState({locInfo: info, openLocationMenu: false});

    //Do all things required when  changing location
    handleChangeLocation(location.id);
  }

  handleActionChange = (action) => {
    const { notifyActionRequested, notifyDropDownOpened, } = this.props;
    const { userActionTypes } = constants;

    this.setState({openActionMenu: false, showConfirm: true, selectedAction: action});
    notifyDropDownOpened(false);
  }

  handleFilterChange = (filter) => {
    const { notifyDropDownOpened, } = this.props;
    const { showFilterDept, showFilterEmail, showFilterEmployeeId, showFilterGroup, showFilterJobTitle, showFilterSkill, selectedFilter, } = this.state;
    const { filterTypes } = constants;

    this.setState({openFilterMenu: false, openSearchWin: true, selectedFilter: filter});
    notifyDropDownOpened(false);
    switch(filter.name) {
      case filterTypes.DEPARTMENT:
        this.setState({showFilterDept: true});
        break;
      case filterTypes.EMAIL:
        this.setState({showFilterEmail: true});
        break;
      case filterTypes.EMPLOYEE_ID:
        this.setState({showFilterEmployeeId: true});
        break;
      case filterTypes.GROUP:
        this.setState({showFilterGroup: true});
        break;
      case filterTypes.JOB_TITLE:
        this.setState({showFilterJobTitle: true});
        break;
      case filterTypes.SKILL:
        this.setState({showFilterSkill: true});
        break;
    }
  }

  notifyConfirm = (request) => {
    const { selectedAction, } = this.state;
    const { notifyActionRequested, } = this.props;
    const { userActionTypes } = constants;

    this.setState({showConfirm: false});
    if(selectedAction.name === userActionTypes.REMOVE) {
      if(request) {
        this.setState({showConfirmStep2: true});
      }else {
        notifyActionRequested(request, selectedAction.name);
      }
    }else {
      notifyActionRequested(request, selectedAction.name);
    }
  }

  notifyConfirmStep2 = (confirmed) => {
    const { selectedAction, } = this.state;
    const { notifyActionRequested, } = this.props;
    this.setState({showConfirmStep2: false});
    if(confirmed) {
      notifyActionRequested(true, selectedAction.name);
    }else {
      notifyActionRequested(false, selectedAction.name);
    }
  }

  notifyConfirmOperation = (confirmed, groupName, groupDescription) => {

    const { selectedAction, } = this.state;
    const { notifyActionRequested, notifyConfirmOperation, } = this.props;
    const { userActionTypes } = constants;
    
    this.setState({showConfirmOperation: false});
    notifyConfirmOperation(confirmed, groupName, groupDescription);
  }

  notifySearchDone = (filter) => {
    const { selectedFilter, selectedDept, selectedEmail, selectedEmployeeId, selectedGroup, selectedJobTitle, selectedSkill, } = this.state;
    const { filterTypes, } = constants;
    const { notifyFiltersSelected, } = this.props;

    this.setState({openSearchWin: false});

    switch(selectedFilter.name) {
      case filterTypes.DEPARTMENT:
        this.setState({selectedDept: filter});
        notifyFiltersSelected(filter, selectedEmail, selectedEmployeeId, selectedGroup, selectedJobTitle, selectedSkill);
        break;
      case filterTypes.EMAIL:
        this.setState({selectedEmail: filter});
        notifyFiltersSelected(selectedDept, filter, selectedEmployeeId, selectedGroup, selectedJobTitle, selectedSkill);
        break;
      case filterTypes.EMPLOYEE_ID:
        this.setState({selectedEmployeeId: filter});
        notifyFiltersSelected(selectedDept, selectedEmail, filter, selectedGroup, selectedJobTitle, selectedSkill);
        break;
      case filterTypes.GROUP:
        this.setState({selectedGroup: filter});
        notifyFiltersSelected(selectedDept, selectedEmail, selectedEmployeeId, filter, selectedJobTitle, selectedSkill);
        break;
      case filterTypes.JOB_TITLE:
        this.setState({selectedJobTitle: filter});
        notifyFiltersSelected(selectedDept, selectedEmail, selectedEmployeeId, selectedGroup, filter, selectedSkill);
        break;
      case filterTypes.SKILL:
        this.setState({selectedSkill: filter});
        notifyFiltersSelected(selectedDept, selectedEmail, selectedEmployeeId, selectedGroup, selectedJobTitle, filter);
        break;

    }
  }

  renderLocationItems = () => {
    const { locations, } = this.props;
    const { dropdownPos, } = this.state;
    
    const locationItems = locations && locations.map((location, index) => (
      <div 
        key={index} 
        className="employee-page-header__dropdown-item-wrap" 
        value={location}
        onClick={(event) => {this.handleLocationChange(location)}}
      >
        <label className="employee-page-header__dropdown-text">
          {this.createLocationInfo(location)}
        </label>
      </div>
    ));
    if(dropdownPos) {
      return (
        <styled.WinContainer top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {locationItems}
        </styled.WinContainer>
      );
    }
  }

  renderUserActionItems = () => {
    const { locations, } = this.props;
    const { actionMenuPos, } = this.state;
    const { userActionTypes } = constants;

    const promoteText = (<FormattedMessage id="Header.promote" defaultMessage="Grant Admin Privileges" />);
    const withdrawText = (<FormattedMessage id="Header.withdraw" defaultMessage="Withdraw Admin Privileges" />);
    const removeText = (<FormattedMessage id="Header.remove" defaultMessage="Remove User from Location" />);

    const promote = { name: userActionTypes.PROMOTE, body: promoteText};
    const withdraw = { name: userActionTypes.WITHDRAW, body: withdrawText};
    const remove = { name: userActionTypes.REMOVE, body: removeText};
    const items = [promote, withdraw, remove];
    
    const actionItems = items.map((item, index) => (
      <div 
        key={index} 
        className="employee-page-header__dropdown-item-wrap" 
        value={item}
        onClick={(event) => {this.handleActionChange(item)}}
      >
        <label className="employee-page-header__action-dropdown-text">
          {item.body}
        </label>
      </div>
    ));
    if(actionMenuPos) {
      return (
        <styled.WinContainer  ref={this.actionRef} top={actionMenuPos.top} left={actionMenuPos.left} width={actionMenuPos.width} height={actionMenuPos.height}>
          {actionItems}
        </styled.WinContainer>
      );
    }
  }

  renderFilterMenu = () => {
    const { dropdownPos, showFilterDept, showFilterEmail, showFilterEmployeeId, showFilterGroup,
            showFilterJobTitle, showFilterSkill, } = this.state;
    const { locationSettings, } = this.props;
    const { filterTypes, } = constants;

    const departmentText = (<FormattedMessage id="Header.department" defaultMessage="Department" />);
    const emailText = (<FormattedMessage id="Header.email" defaultMessage="Email" />);
    const employeeIdText = (<FormattedMessage id="Header.employeeId" defaultMessage="Employee ID" />);
    const groupText = (<FormattedMessage id="Header.group" defaultMessage="Group" />);
    const jobTitleText = (<FormattedMessage id="Header.jobTitle" defaultMessage="Job Title" />);
     const skillText = (<FormattedMessage id="Header.skill" defaultMessage="Skill" />);

    const itemDept = { name: filterTypes.DEPARTMENT, body: departmentText};
    const itemEmail = { name: filterTypes.EMAIL, body: emailText};
    const itemEmployeeId = { name: filterTypes.EMPLOYEE_ID, body: employeeIdText};
    const itemGroup = { name: filterTypes.GROUP, body: groupText};
    const itemJobTitle = { name: filterTypes.JOB_TITLE, body: jobTitleText};
    const itemSkill = { name: filterTypes.SKILL, body: skillText};

    let items = [];
    
    if(locationSettings && locationSettings.cfg__departments === 1) {
      items.push(itemDept);
    }

    items.push(itemEmail);

    if(locationSettings && locationSettings.cfg__badge_id === 1) {
        items.push(itemEmployeeId);
    }

    items.push(itemGroup);
    items.push(itemJobTitle);

    if(locationSettings && locationSettings.cfg__skill === 1) {
        items.push(itemSkill);
    }
   
    const filterItems = items.map((item, index) => (
      <div 
        key={index} 
        className="employee-page-header__dropdown-item-wrap" 
        value={item}
        onClick={(event) => {this.handleFilterChange(item)}}
      >
        <label className="employee-page-header__action-dropdown-text">
          {item.body}
        </label>
      </div>
    ));
    if(dropdownPos) {
      return (
        <styled.WinContainer ref={this.filterWinRef} top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {filterItems}
        </styled.WinContainer>
      );
    }

  }

  createLocationInfo = (location) => {
    const { managers, associates, pendingUsers, removedUsers,  } = this.props;

    const all = managers.concat(associates, pendingUsers, removedUsers);
    //const info = `${location.location_name} #${all.length}`;
    const info = `${location.location_name}`;
    return info; 
  }

  handleApproveClick = (event) => {
    const { notifyActionRequested, } = this.props;
    const { userActionTypes } = constants;
    const approveText = (<FormattedMessage
      id="Header.approve"
      defaultMessage="Approve Users?"
    />);
    const action = { name: userActionTypes.APPROVE, body: approveText};
    this.setState({showConfirm: true, selectedAction: action});
  }

  handleDenyClick = (event) => {
    const { notifyActionRequested, } = this.props;
    const { userActionTypes } = constants;
    const denyText = (<FormattedMessage
      id="Header.deny"
      defaultMessage="Deny Users?"
    />);
    const action = { name: userActionTypes.DENY, body: denyText};
    this.setState({showConfirm: true, selectedAction: action});
  }

  handleAddClick = (event) => {
    const { openOperationMenu, openActionMenu, } = this.state;
    const { notifyDropDownOpened, } = this.props;
    if(openActionMenu) {
      return;
    }
    this.setState({openOperationMenu: !openOperationMenu, addOperation: true});
    notifyDropDownOpened(!openOperationMenu);
  }

  handleRemoveClick = (event) => {
    const { openOperationMenu, openActionMenu, } = this.state;
    const { notifyDropDownOpened, selectedType, notifyOperationRequested, } = this.props;
    const { categoryTypes, operationTypes, } = constants;
    if(openActionMenu) {
      return;
    }
    if(selectedType === categoryTypes.LOCATION) {
      this.setState({openOperationMenu: !openOperationMenu, addOperation: false});
      notifyDropDownOpened(!openOperationMenu);
    }else {
      const departmentText = (<FormattedMessage id="Header.department" defaultMessage="Department" />);
      const groupText = (<FormattedMessage id="Header.group" defaultMessage="Group" />);
      const skillsText = (<FormattedMessage id="Header.skills" defaultMessage="Skills" />);
      const removeUsersFromDept = { name: operationTypes.REMOVE_USERS_FROM_DEPT, body: departmentText};
      const removeUsersFromGroup = { name: operationTypes.REMOVE_USERS_FROM_GROUP, body: groupText};
      const removeUsersFromSkills = { name: operationTypes.REMOVE_USERS_FROM_SKILL, body: skillsText};
      let selectedOperation = null;
      if(selectedType === categoryTypes.DEPARTMENTS) {
        selectedOperation = removeUsersFromDept;
      }else if(selectedType === categoryTypes.GROUPS) {
        selectedOperation = removeUsersFromGroup;
      }else if(selectedType === categoryTypes.SKILLS) {
        selectedOperation = removeUsersFromSkills;
      }
      notifyOperationRequested(selectedOperation);
    }
  }

  handleOpenMenuClick = (event) => {
    const { openActionMenu, openOperationMenu, } = this.state;
    const { notifyDropDownOpened, } = this.props;
    if(openOperationMenu) {
      return;
    }
    this.setState({openActionMenu: !openActionMenu, addOperation: false});
    notifyDropDownOpened(!openActionMenu);
  }

  handleOperationChange = (operation) => {
    const { notifyOperationRequested, notifyDropDownOpened, } = this.props;
    const { operationTypes } = constants;
    //console.log(`Selected operation: ${operation.name}`);
    this.setState({openOperationMenu: false});
    notifyOperationRequested(operation);
    notifyDropDownOpened(false);
  }

  handleSortDropdownClick = (event) => {
    const { openSortMenu, } = this.state;
    const { notifyDropDownOpened, } = this.props;

    this.setState({openSortMenu: !openSortMenu});
    notifyDropDownOpened(!openSortMenu);
  }

  handleSortChange = (sort) => {
    const { notifySortTypeChanged, notifyDropDownOpened, } = this.props;
    const { openSortMenu, selectedSort, } = this.state;

    this.setState({openSortMenu: false, selectedSort: sort});
    notifySortTypeChanged(sort);
    notifyDropDownOpened(false);
  }


  renderOperationItems = () => {
    const { locations, locationSettings, } = this.props;
    const { addOperation,  dropdownPos, } = this.state;
    const { operationTypes, } = constants;
   
    const departmentText = (<FormattedMessage id="Header.department" defaultMessage="Department" />);
    const groupText = (<FormattedMessage id="Header.group" defaultMessage="Group" />);
    const skillsText = (<FormattedMessage id="Header.skills" defaultMessage="Skills" />);

    const assignUsersToDept = { name: operationTypes.ASSIGN_USERS_TO_DEPT, body: departmentText};
    const assignUsersToGroup = { name: operationTypes.ASSIGN_USERS_TO_GROUP, body: groupText};
    const assignUsersToSkill = { name: operationTypes.ASSIGN_USERS_TO_SKILL, body: skillsText};
    const removeUsersFromDept = { name: operationTypes.REMOVE_USERS_FROM_DEPT, body: departmentText};
    const removeUsersFromGroup = { name: operationTypes.REMOVE_USERS_FROM_GROUP, body: groupText};
    const removeUsersFromSkills = { name: operationTypes.REMOVE_USERS_FROM_SKILL, body: skillsText};

    let addItems = [assignUsersToDept, assignUsersToGroup, assignUsersToSkill];
    let removeItems = [removeUsersFromDept, removeUsersFromGroup, removeUsersFromSkills];
    if(locationSettings && locationSettings.cfg__departments !== 1) {
      addItems = [assignUsersToGroup, assignUsersToSkill];
      removeItems = [removeUsersFromGroup, removeUsersFromSkills];
    }
    if(locationSettings && locationSettings.cfg__skill !== 1) {
      addItems = [assignUsersToDept, assignUsersToGroup];
      removeItems = [removeUsersFromDept, removeUsersFromGroup];
    }
    if(locationSettings && locationSettings.cfg__departments !== 1 && locationSettings.cfg__skill !== 1) {
      addItems = [assignUsersToGroup];
      removeItems = [removeUsersFromGroup];
    }
    
    const items = addOperation ? addItems : removeItems;
    
    const operationItems = items.map((item, index) => (
      <div 
        key={index} 
        className="category__dropdown-item-wrap" 
        value={item}
        onClick={(event) => {this.handleOperationChange(item)}}
      >
        <label className="category__action-dropdown-text">
          {item.body}
        </label>
      </div>
    ));
    if(dropdownPos) {
      return (
        <styled.WinContainer ref={this.operationRef} top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {operationItems}
        </styled.WinContainer>
      );
    }
  }

  renderSortMenu = () => {
    const {dropdownPos, } = this.state;
    const { sortTypes, } = constants;

    const name = (<FormattedMessage id="Header.name" defaultMessage="Name" />);
    const jobTitle = (<FormattedMessage id="Header.jobTitle" defaultMessage="Job Title" />);
    const userRole = (<FormattedMessage id="Header.userRole" defaultMessage="User Role" />);
    const itemName = {name: sortTypes.FIRST_NAME, body: name};
    const itemJobTitle = { name: sortTypes.POSITION, body: jobTitle};
    const itemUserRole = {name: sortTypes.USER_ROLE, body: userRole};

    //const items = [itemJobTitle, itemName, itemStatus, itemUserRole];
    const items = [itemJobTitle, itemName, itemUserRole];
    const sortItems = items.map((item, index) => (
      <div 
        key={index} 
        className="employee-page-header__dropdown-item-wrap" 
        value={item}
        onClick={(event) => {this.handleSortChange(item)}}
      >
        <label className="employee-page-header__action-dropdown-text">
          {item.body}
        </label>
      </div>
    ));
    if(dropdownPos) {
      return (
        <styled.WinContainer ref={this.sortWinRef} top={dropdownPos.top} left={dropdownPos.left} width={dropdownPos.width} height={dropdownPos.height}>
          {sortItems}
        </styled.WinContainer>
      );
    }

  }

  render() {
    const { debug, searchTerm, openLocationMenu, locInfo, openActionMenu, showConfirm, showConfirmOperation,
    showConfirmStep2, selectedAction, openOperationMenu, addOperation, openSortMenu, selectedSort, ascending, 
    openFilterMenu, showFilterDept, showFilterEmail, showFilterEmployeeId, showFilterGroup, showFilterJobTitle, showFilterSkill,
    openSearchWin, dropdownPos, selectedFilter, selectedDept, selectedEmail, selectedEmployeeId, selectedGroup, selectedJobTitle, selectedSkill, } = this.state;
    const { selectedType, selectedItem, locationId, locationInfo, locations, filteredUsers, locationSettings,
            selectedUsers, requestedOperation, channels, channelData, departments, skills, intl, notifyFiltersSelected, } = this.props;
    const { filterTypes, } = constants;
    
    const submit = moment();
    const submittedTime = `${submit.format('MMM DD, YYYY')}`;
    const usersOnTable = filteredUsers ? filteredUsers.length : 0;
    const locationText = locInfo.length > 0 ? locInfo : locationInfo;
    const locationButtonClassName = openLocationMenu ? "employee-page-header__arrow-button-up" : "employee-page-header__arrow-button-down";
    const selectedPendingUsers = selectedUsers.length > 0 ? selectedUsers.filter((user) => user.status === 'pending') : [];
    const selectedActiveUsers = selectedUsers.length > 0 ?  selectedUsers.filter((user) => user.status === 'active') : [];
    const sortButtonClassName = openSortMenu ? "employee-page-header__sort-arrow-button-up" : "employee-page-header__sort-arrow-button-down";
    const sortOrderButtonClassName =  ascending ? 'employee-page-header__sort-order-descending' : 'employee-page-header__sort-order-ascending';
    let showFilterButton = false;
    if(locationSettings.cfg__departments !== 1) {
      if(locationSettings.cfg__skill === 1) {
        if(locationSettings.cfg__badge_id === 1) {
          showFilterButton = !showFilterEmail || !showFilterEmployeeId || !showFilterGroup || !showFilterJobTitle || !showFilterSkill;
        }else {
          showFilterButton = !showFilterEmail || !showFilterGroup || !showFilterJobTitle || !showFilterSkill;
        }
      }else {
        if(locationSettings.cfg__badge_id === 1) {
          showFilterButton = !showFilterEmail || !showFilterEmployeeId || !showFilterGroup || !showFilterJobTitle;
        }else {
          showFilterButton = !showFilterEmail || !showFilterGroup || !showFilterJobTitle;
        }
      }
    }else {
      if(locationSettings.cfg__skill === 1) {
        if(locationSettings.cfg__badge_id === 1) {
          showFilterButton = !showFilterDept || !showFilterEmail || !showFilterEmployeeId || !showFilterGroup || !showFilterJobTitle || !showFilterSkill;
        }else {
          showFilterButton = !showFilterDept || !showFilterEmail || !showFilterGroup || !showFilterJobTitle || !showFilterSkill;
        }
      }else {
        if(locationSettings.cfg__badge_id === 1) {
          showFilterButton = !showFilterDept || !showFilterEmail || !showFilterEmployeeId || !showFilterGroup || !showFilterJobTitle;
        }else {
          showFilterButton = !showFilterDept || !showFilterEmail || !showFilterGroup || !showFilterJobTitle;
        }
      }
    }

    const sortBy = (<FormattedMessage id="Header.sortBy" defaultMessage="Sort by" />);
    const userRoleText = (<FormattedMessage id="Header.userRole" defaultMessage="User Role" />);
    const results = (<FormattedMessage id="Header.results" defaultMessage="{number} results" values={{number: usersOnTable}}/>);
    const addFilters = (<FormattedMessage id="Header.addFilters" defaultMessage="Add Filters" />);
    const hideFilters = (<FormattedMessage id="Header.hideFilters" defaultMessage="Hide Filters" />);
    const clearAll = (<FormattedMessage id="Header.clearAll" defaultMessage="Clear All" />);
    const approveUsers = (<FormattedMessage id="Header.approveUsers" defaultMessage="Approve {number}" values={{number: selectedPendingUsers.length}}/>);
    const denyUsers = (<FormattedMessage id="Header.denyUsers" defaultMessage="Deny {number}" values={{number: selectedPendingUsers.length}}/>);
    const addUsers = (<FormattedMessage id="Header.addUsers" defaultMessage="Add" />);
    const removeUsers = (<FormattedMessage id="Header.removeUsers" defaultMessage="Remove" />);
    const  deptIs = (<FormattedMessage id="Header.departmentIs" defaultMessage="Department is" />);
    const  emailIs = (<FormattedMessage id="Header.emailIs" defaultMessage="Email is" />);
    const  employeeIdIs = (<FormattedMessage id="Header.employeeIdIs" defaultMessage="Employee ID is" />);
    const groupIs = (<FormattedMessage id="Header.groupIs" defaultMessage="Group is" />);
    const jobTitleIs = (<FormattedMessage id="Header.jobTitleIs" defaultMessage="Job Title is" />);
    const skillIs = (<FormattedMessage id="Header.skillIs" defaultMessage="Skill is" />);

    const sortBoxText = selectedSort ? selectedSort.body : userRoleText;

    //All selected users are pending users
    if(selectedPendingUsers.length > 0 && selectedUsers.length === selectedPendingUsers.length) {
      return (
        <div className="employee-page-header__top-wrap">
          <div className="employee-page-header__wrap">
            <div className="employee-page-header__row-1" ref={this.topRowRef}>
              <label className="employee-page-header__dropdown-text">{locationText}</label>
            </div>
            <div className="employee-page-header__row-2">
              <div className="employee-page-header__button-group-1-wrap">
                <button className="employee-page-header__approve-users" onClick={this.handleApproveClick}>{approveUsers}</button>
                <button className="employee-page-header__deny-users" onClick={this.handleDenyClick}>{denyUsers}</button>
              </div>
              <div className="employee-page-header__sort-drop-down" ref={this.sortRef} onClick={this.handleSortDropdownClick}>{sortBy}
                <label className="employee-page-header__sort-item-text">{sortBoxText}</label>
                <button className={sortButtonClassName}/>
              </div>
              <button className={sortOrderButtonClassName} onClick={this.handleSortOrderChanged}/>
              <button className="employee-page-header__refresh-button" onClick={this.handleRefreshClick} />
            </div>
          </div> 
          {openSortMenu && <Modal>
            {this.renderSortMenu()}
          </Modal>}
          {showConfirm && <Modal>
            <Confirm
              selectedAction={selectedAction}
              notifyConfirm={this.notifyConfirm}
              selectedUsers={selectedUsers}
            />
          </Modal>}
          <styled.Overlay display={showConfirm ? 'block' : 'none'} />
        </div>
      );
    }

    //All selected users are active users
    if((selectedActiveUsers.length > 0 && selectedUsers.length === selectedActiveUsers.length)
     || (requestedOperation && requestedOperation.length > 0)) {
      return (
        <div className="employee-page-header__top-wrap">
          <div className="employee-page-header__wrap">
            <div className="employee-page-header__row-1" ref={this.topRowRef}>
              <label className="employee-page-header__dropdown-text">{locationText}</label>
            </div>
            <div className="employee-page-header__row-2">
              <div className="employee-page-header__button-group-1-wrap">
                <button className="employee-page-header__add-users" ref={this.addRef} onClick={this.handleAddClick}>{addUsers}</button>
                <button className="employee-page-header__remove-users" ref={this.removeRef} onClick={this.handleRemoveClick}>{removeUsers}</button>
              </div>
              <button className="employee-page-header__open-more" onClick={this.handleOpenMenuClick} ref={this.openRef}/>
              <div className="employee-page-header__sort-drop-down"ref={this.sortRef} onClick={this.handleSortDropdownClick}>{sortBy}
                <label className="employee-page-header__sort-item-text">{sortBoxText}</label>
                <button className={sortButtonClassName}/>
              </div>
              <button className={sortOrderButtonClassName} onClick={this.handleSortOrderChanged}/>
              <button className="employee-page-header__refresh-button" onClick={this.handleRefreshClick} />
            </div>
          </div> 
          {openActionMenu && <Modal>
            {this.renderUserActionItems()}
          </Modal>}
          {openOperationMenu && <Modal>
            {this.renderOperationItems()}
          </Modal>}
          {openSortMenu && <Modal>
            {this.renderSortMenu()}
          </Modal>}
          {openFilterMenu && <Modal>
            {this.renderFilterMenu()}
          </Modal>}
          {showConfirm && <Modal>
            <Confirm
              selectedAction={selectedAction}
              notifyConfirm={this.notifyConfirm}
              selectedUsers={selectedUsers}
            />
          </Modal>}
          {showConfirmStep2 && <Modal>
            <ConfirmStep2
              selectedAction={selectedAction}
              notifyConfirmStep2={this.notifyConfirmStep2}
              selectedUsers={selectedUsers}
            />
          </Modal>}
          {showConfirmOperation && <Modal>
            <ConfirmOperation 
              requestedOperation={requestedOperation}
              channels={channels}
              channelData={channelData}
              selectedUsers={selectedUsers}
              departments={departments}
              skills={skills}
              notifyConfirmOperation={this.notifyConfirmOperation}
              selectedItem={selectedItem}
              selectedType={selectedType}
            />
          </Modal>}
          <styled.Overlay display={showConfirm || showConfirmOperation ? 'block' : 'none'} />
        </div>
      );
    }

    return (
      <div className="employee-page-header__top-wrap">
        <div className="employee-page-header__wrap">
          <div className="employee-page-header__row-1" ref={this.topRowRef}>
            <div className="employee-page-header__location-dropdown-wrap">
              <label className="employee-page-header__dropdown-text">{locationText}</label>
            </div>
            <label className="employee-page-header__results-text">{results}</label>
          </div>
          <div className="employee-page-header__row-2">
            <input className="employee-page-header__search-box"
              onChange={this.handleSearchChanged}
              onKeyUp={this.handleKeyPress}
              type="text"
              placeholder={intl.formatMessage(this.messages.placeholder)}
              value={searchTerm}
            />
            <div className="employee-page-header__filter-wrap">
              {showFilterDept && <div className="employee-page-header__filter" ref={this.deptRef} onClick={(event) => {this.handleCloseFilter(filterTypes.DEPARTMENT)}}>
                <p className="employee-page-header__filter-text">{deptIs}</p>
                <p className="employee-page-header__item-name">{selectedDept ? selectedDept.name : ''}</p>
              </div>}
              {showFilterEmail && <div className="employee-page-header__filter" ref={this.emailRef} onClick={(event) => {this.handleCloseFilter(filterTypes.EMAIL)}}>
                <p className="employee-page-header__filter-text">{emailIs}</p>
                <p className="employee-page-header__item-name">{selectedEmail ? selectedEmail : ''}</p>
              </div>}
              {showFilterEmployeeId && <div className="employee-page-header__filter" ref={this.employeeIdRef} onClick={(event) => {this.handleCloseFilter(filterTypes.EMPLOYEE_ID)}}>
                <p className="employee-page-header__filter-text">{employeeIdIs}</p>
                <p className="employee-page-header__item-name">{selectedEmployeeId ? selectedEmployeeId : ''}</p>
              </div>}
              {showFilterGroup && <div className="employee-page-header__filter" ref={this.groupRef} onClick={(event) => {this.handleCloseFilter(filterTypes.GROUP)}}>
                <p className="employee-page-header__filter-text">{groupIs}</p>
                <p className="employee-page-header__item-name">{selectedGroup ? selectedGroup.channel_name : ''}</p>
              </div>}
              {showFilterJobTitle && <div className="employee-page-header__filter" ref={this.jobTitleRef} onClick={(event) => {this.handleCloseFilter(filterTypes.JOB_TITLE)}}>
                <p className="employee-page-header__filter-text">{jobTitleIs}</p>
                <p className="employee-page-header__item-name">{selectedJobTitle ? selectedJobTitle : ''}</p>
              </div>}
              {showFilterSkill && <div className="employee-page-header__filter" ref={this.skillRef} onClick={(event) => {this.handleCloseFilter(filterTypes.SKILL)}}>
                <p className="employee-page-header__filter-text">{skillIs}</p>
                <p className="employee-page-header__item-name">{selectedSkill ? selectedSkill.skill_content : ''}</p>
              </div>}
            </div>
            {showFilterButton && <button className="employee-page-header__add-filters" ref={this.filterRef} onClick={this.handleAddFilterClick}>{addFilters}</button>}
            <div className="employee-page-header__sort-drop-down"ref={this.sortRef} onClick={this.handleSortDropdownClick}>{sortBy}
              <label className="employee-page-header__sort-item-text">{sortBoxText}</label>
              <button className={sortButtonClassName}/>
            </div>
            <button className={sortOrderButtonClassName} onClick={this.handleSortOrderChanged}/>
            <button className="employee-page-header__refresh-button" onClick={this.handleRefreshClick} />
          </div>
        </div> 
        {openLocationMenu && <Modal>
          {this.renderLocationItems()}
        </Modal>}
        {openSortMenu && <Modal>
          {this.renderSortMenu()}
        </Modal>}
        {openFilterMenu && <Modal>
          {this.renderFilterMenu()}
        </Modal>}
        {openSearchWin && <Modal>
          <SearchDialogue
            departments={departments}
            channels={channels}
            skills={skills}
            notifySearchDone={this.notifySearchDone}
            position={dropdownPos}
            selectedFilter={selectedFilter}
          />
        </Modal>}
      </div>
    );
  }
}

Header.propTypes = {
  selectedItem: PropTypes.object.isRequired,
  selectedType: PropTypes.string.isRequired
};

export default injectIntl(Header);

