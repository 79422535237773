import styled from 'styled-components/macro';
import blocks from '../../blocks';
import { rgba } from 'polished';
import { rem } from 'polished';

const ChannelAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 15px;
  margin-right: 1rem;
  //border: 1px solid red;
`;

const WinContainerConfirm = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  //width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  width: fit-content;
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2; 
  //overflow: scroll;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
  //border: 1px solid red;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const Overlay = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  //rgba(198,198,201,255)
`;

export default {
  ChannelAvatar,
  WinContainerConfirm,
  Overlay,
};
