import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    showToast: false,
  },
  
  reducers: {
    startShowingToast: (state, action) => {
      state.showToast= true;
    },

    stopShowingToast: (state, action) => {
      state.showToast= false;
    },
  },
});

export default settingsSlice;
