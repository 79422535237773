import React from "react";
import 'App/layout.scss';
import 'App/fonts.scss';
import './DetailChildCardHeader.scss';
import presenter from "pages/ScheduleCalendar/duck/presenter";
import allUsersSlice from "App/duck/slices/allUsers";

export function DetailChildCardHeaderComponent ({shift, allUsers}) {

    console.log(JSON.stringify(shift));
    const taker = presenter.getTaker(shift, allUsers)
    const tagText = presenter.tagTextForOpenShift(shift)

    return (
         <div className="child-card text-center">
            <img className="pic br-50" src={presenter.getUserAvatar(taker)}></img>
            <label className="stat-label ff-bold">{tagText}</label>
            <label className="ff-semibold fs14">{presenter.getName(taker)}</label>
            <label className="ff-regular fs14">has been approved to cover this open shift</label>
        </div>
    )
}

export default DetailChildCardHeaderComponent 
