import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import constants from '../../constants';
import './Summary.scss';
import './SummaryShimmer.scss';

class Summary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: true,
      selectedIndex: 0,
      selectedItem: null,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { parentSelectedIndex, summaryItems, selectedType, } = this.props;

    this._isMounted = true;
    if (parentSelectedIndex !== undefined) {
      if (selectedType === 'REQUESTS') {
        this.setState({ selectedIndex: parentSelectedIndex });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { summaryItemSelected, selectedType, summaryItems, selectedUsers, parentSelectedIndex, } = this.props;
    const { selectedItem, selectedIndex, } = this.state;
    const { categoryTypes } = constants;

    if (prevProps.selectedType !== selectedType) {
      const firstItem = summaryItems && summaryItems.length > 0 ? summaryItems[0] : null;
      this.setState({ selectedIndex: 0, selectedItem: firstItem });
    }

    if (prevState.selectedItem !== selectedItem) {
      summaryItemSelected(selectedIndex, selectedItem);
    }

    if (prevProps.parentSelectedIndex !== parentSelectedIndex) {
      this.setState({ selectedIndex: parentSelectedIndex });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleItemClicked = (e: any, index: number, item: any) => {
    this.setState({ selectedIndex: index, selectedItem: item });
  }

  render() {
    const { selectedIndex, } = this.state;
    const { summaryItems, selectedType, loading } = this.props;

    const paramWrapClassName = 'summary__param-wrap';

    const createSummaryElements = () => {
      if (summaryItems) {
        const elements = summaryItems.map((item, index) => {
          return (
            <div className={selectedIndex === index ? "summary__param-wrap-selected" : "summary__param-wrap"}
              key={index}
              id={index}
              onClick={(e) => this.handleItemClicked(e, index, item)}
            >
              <label className="summary__param-value">{item.value}</label>
              <label className="summary__param-name">{item.name}</label>
            </div>
          );
        });
        return elements;
      }
      return null;
    };

    const createShimmerSummaryElements = () => {
      if (summaryItems) {
        const elements = summaryItems.map((item, index) => {
          return (
            <div className={selectedIndex === index ? "summary__param-wrap-selected" : "summary__param-wrap"}
              key={index}
              id={index}
              onClick={(e) => this.handleItemClicked(e, index, item)}
            >
              <label className="param-value"></label>
              <label className="summary__param-name">{item.name}</label>
            </div>
          );
        });
        return elements;
      }
      return null;
    };

    if (loading) {
      return (
        <div className="summary__wrap">
          {createShimmerSummaryElements()}
        </div>
      );
    }

    return (
      <div className="summary__wrap">
        <div className="summary-left"> {createSummaryElements()} </div>
      </div>
    );
  }
}

Summary.propTypes = {
  summaryItems: PropTypes.array.isRequired,
  selectedType: PropTypes.string.isRequired,
  summaryItemSelected: PropTypes.func.isRequired,
};

export default injectIntl(Summary);

