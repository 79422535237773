import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';

import ScheduleIconPath from '../../../assets/schedule-icon.svg';



const TopWrap = styled.span`
  display: flex;
  flex-direction: row;
  width: 250px;
  //border: 1px solid blue;

  @media (max-width: 946px) {
    //border: 1px solid red;
  }

  @media (max-width: 640px) {
    //display: flex;
    //min-width: 280px;
  }
  //border: 1px solid red;

`;

const OwnerAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-top: 2px;
`;

const OwnerInfoWrap = styled.span`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  //border: 1px solid green;
`;

const OwnerInfoTopLine = styled.span`
  display: flex;
  flex-direction: row;
  //border: 1px solid red;
`;
const UserName = styled.label `
  margin-top: 0;
  margin-bottom: 0;
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 500;
  //border: 1px solid red;
`;

const ApprovedBadge = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 3px;
  //border: 1px solid red;
`;

const OwnerInfoBottomLine = styled.p `
  margin-top: 0;
  margin-bottom: 0;
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  //border: 1px solid red;
`;

const UnregisteredWrap = styled.span`
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 32px;
`;

const StatusPending = styled.span`
  display: flex;
  flex-direction: row;
  //width: 120px;
  width: fit-content;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(255,238,214,255);
  border: 1px solid rgba(255,142,0,255);
`;
const PendingSpan = styled.span `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 8px;
  background-color: rgba(255,142,0,255);
`;

const inviteLink = styled.button `
  @font-face {
    font-family: 'AvertaSemibold';
    src: url('/fonts/averta/Averta-Semibold.ttf');
  }
  font-family: 'AvertaSemibold'; 
  font-size: 12px;
  margin-left: 16px;
  text-decoration: underline;
  font-weight:400;
  height: 32px;
`;

const PendingText = styled.label `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 6px;
  padding-right: 7px;
`;

const StatusActive = styled.span`
  display: flex;
  flex-direction: row;
  //width: 85px;
  width:fit-content;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(243,252,246,255);
  border: 1px solid rgba(53,199,89,255);
`;
const ActiveSpan = styled.span `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 8px;
  background-color: rgba(53,199,89,255);
`;

const ActiveText = styled.label `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 6px;
  padding-right: 7px;
`;

const StatusFailed = styled.div `
  display: flex;
  flex-direction: row;
  //width: 120px;
  width: fit-content;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(255,227,229,255);
  border: 1px solid rgba(255,41,71,255);
`;
const FailedSpan = styled.span `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 8px;
  background-color: rgba(255,41,71,255);
`;

const FailedText = styled.label `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 6px;
  padding-right: 7px;
`;

const DetailsWrap = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  //border: 1px solid blue;
`;

const ScheduleIcon = styled.img.attrs({
  src: ScheduleIconPath,
})`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const ContentWrap = styled.div `
  display: flex;
  flex-direction: column;
  //border: 1px solid red;
`;

const TopLineWrap = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  //border: 1px solid blue;
`;

const BottomLineWrap = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  height: 15px;
  //border: 1px solid red;
`;

const TopLineText = styled.p `
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 12px;
  font-weight: 500;
  margin: auto;
`;

const Dot = styled.span `
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
`;

const BottomLineText = styled.label `
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  //margin: auto;
  margin-top: 8px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`;

const BottomLineTextOneItem = styled.label `
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  margin: auto;
  min-width: 140px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 15px;

`;

const ProfileLink = styled(Link)`
  margin-top: 0;
  margin-bottom: 0;
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  color: black;
`;


export default {
  TopWrap,
  OwnerAvatar,
  OwnerInfoWrap,
  OwnerInfoTopLine,
  OwnerInfoBottomLine,
  StatusPending,
  PendingSpan,
  PendingText,
  StatusActive,
  ActiveSpan,
  ActiveText,
  StatusFailed,
  FailedSpan,
  FailedText,
  DetailsWrap,
  ScheduleIcon,
  ContentWrap,
  TopLineWrap,
  BottomLineWrap,
  TopLineText,
  Dot,
  BottomLineText,
  BottomLineTextOneItem,
  UserName,
  ApprovedBadge,
  ProfileLink,
  UnregisteredWrap,
  inviteLink,
};
