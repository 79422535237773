import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../../../components/GeneralComponents/TableComponent/TableRow/messages';
import styled from './styled';

class StatusCell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      appStatus: '',
    };
  }

  componentDidMount() {
    this.updateStatus(this.props.shift);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shift !== this.props.shift) {
      this.updateStatus(this.props.shift);
    }
  }

  updateStatus(shift) {
    const isShift = shift.time_off_reason === undefined;
    const coverer = shift.coverer;

    if (!isShift || !shift || !coverer) {
      return this.setState({ appStatus: '' });
    }

    if (!coverer.first_name && coverer.last_name) {
      return this.setState({ appStatus: coverer.last_name });
    }

    const tradeStatus = shift.trade_status;
    const statusMap = {
      approved: '#a',
      pending: '#p',
      denied: '#d',
      rejected: '#d',
    };

    if (tradeStatus && statusMap[tradeStatus]) {
      this.setState({ appStatus: statusMap[tradeStatus] });
    }
  }

  getStatusComponent(statusType, message) {
    const statusComponents = {
      approved: (
        <styled.StatusApproved>
          <styled.ApprovedSpan />
          <styled.ApprovedText>{message}</styled.ApprovedText>
        </styled.StatusApproved>
      ),
      active: (
        <styled.StatusActive>
          <styled.ActiveSpan />
          <styled.ActiveText>{message}</styled.ActiveText>
        </styled.StatusActive>
      ),
      pending: (
        <styled.StatusPending>
          <styled.PendingSpan />
          <styled.PendingText>{message}</styled.PendingText>
        </styled.StatusPending>
      ),
      failed: (
        <styled.StatusFailed>
          <styled.FailedSpan />
          <styled.FailedText>{message}</styled.FailedText>
        </styled.StatusFailed>
      ),
    };

    return statusComponents[statusType] || null;
  }

  render() {
    const { shift, intl: { formatMessage }, isParent, isRequests } = this.props;
    const { appStatus } = this.state;
    const isShift = shift.time_off_reason === undefined;

    const statusMap = {
      '#a': { type: 'approved', message: formatMessage(messages.approved) },
      '#p': { type: 'pending', message: formatMessage(messages.pending) },
      '#d': { type: 'failed', message: formatMessage(messages.denied) },
    };

    if (appStatus && statusMap[appStatus]) {
      const { type, message } = statusMap[appStatus];
      return this.getStatusComponent(type, message);
    }

    const tradeStatusMap = {
      open: 'active',
      posted: 'active',
      pending: 'pending',
      requested: 'pending',
      denied: 'failed',
      rejected: 'failed',
      covered: 'approved',
      approved: 'approved',
    };

    if (shift && (shift.parent_card || shift.cover_mode === 'multi_applicants_picked_by_manager') && isRequests && isParent) {
      if (shift.current_applicants_pending_ids.length > 0) {
        return this.getStatusComponent(tradeStatusMap['pending'], formatMessage(messages.pending));
      } else if (shift.current_applicants_approved_ids.length > 0) {
        return this.getStatusComponent(tradeStatusMap['approved'], formatMessage(messages.approved));
      } else if (shift.current_applicants_denied_ids.length > 0) {
        return this.getStatusComponent(tradeStatusMap['denied'], formatMessage(messages.denied));
      }

      return this.getStatusComponent(tradeStatusMap['posted'], formatMessage(messages.posted));
    } else if (shift) {
      const statusType = isShift
        ? tradeStatusMap[shift.trade_status]
        : tradeStatusMap[shift.status];
      const statusMessage = formatMessage(
        messages[shift.trade_status || shift.status] || messages.pending
      );

      return this.getStatusComponent(statusType, statusMessage);
    }

    return null;
  }
}

StatusCell.propTypes = {
  shift: PropTypes.object.isRequired,
};

export default injectIntl(StatusCell);
