import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import PlacesAutocomplete from 'react-places-autocomplete';
import { SearchLocation, LocationJoin, CreateLocationJoin } from 'helpers/api-calls';
import { addLocation } from 'actions/dashboard-actions';
import { setLocationCookie } from 'helpers/cookies';
import backArrow from './assets/back-arrow.svg';
import LocationContainer from './LocationContainer/LocationContainer';
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';
import './AddLocationDialog.scss';
import messages from './messages';
import GoogleMapView from '../SignUp/GoogleMapView';

const CloseButton = () => (
    <img src="/icons/close-simple.svg" alt="Close Sidebar" />
);
class AddLocationDialog extends React.Component {
    state = {
        locationCode: '',
        isValid: false,
        step: 1,
        isCreateLocationSelected: false,
        location: {
            isLoading: false,
            hasBeenCalled: false,
            data: null,
        },
        showError: false,
        joinError: '',
        isCodeValid: false,
        isFormValid: false,
        isGoogleApiLoaded: false,
        showZipCodeInput: false,
        zipCode: '',
        placeDetails: null,
        address: '',
        showLoadingDialog: false,
    };


    componentDidMount() {
        // Check if Google Maps API is already loaded when the component mounts
        if (window.google && window.google.maps) {
            this.setState({
                isGoogleApiLoaded: true
            });
        } else {
            // If API is not loaded, redirect to Registration1

        }
    }


    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const { isCreateLocationSelected } = this.state;
            if (isCreateLocationSelected) {
                this.handleNext();
            } else {
                this._handleSubmitLocationCode();
            }
        }
    };

    handleNext = () => {
        const { address, placeDetails, isCreateLocationSelected, zipCode, showZipCodeInput, showLoadingDialog } = this.state;
        const { intl } = this.props;
        if (((placeDetails && placeDetails.place_id) || (address && showZipCodeInput && zipCode && zipCode.length > 3))) {
            this.setState({
                showLoadingDialog: true,
            });
            CreateLocationJoin(
                placeDetails
                    ? {
                        googlePlaceID: placeDetails.place_id,
                        googleFormattedAddress: placeDetails.formatted_address,
                        googleBusinessName: placeDetails.name,
                        latitude: placeDetails.geometry.location.lat(),
                        longitude: placeDetails.geometry.location.lng(),
                    }
                    : null,
                zipCode,
                address).then(
                    (response) => {
                        if (response.status === 201 || response.status === 200) {
                            setLocationCookie(response.data.location.id);
                            this.props.addedLocation(response.data.location.id);
                            this.props.onClose(); // Close the modal after success
                        }
                        this.setState({
                            showLoadingDialog: false,
                        });
                    },
                    ({ response }) => {
                        this.setState({
                            location: Object.assign(location, {
                                isLoading: false,
                            }),
                            showLoadingDialog: false,
                        });

                        if (response.status === 401 || response.status === 403) {
                            this.setState({
                                joinError: intl.formatMessage(messages.notAuthError),
                            });
                        }

                        if (response.status === 500 || response.status === 422) {
                            this.setState({
                                joinError: intl.formatMessage(messages.ourSideError),
                            });
                        }

                        if (response.status === 422) {
                            if (response.data.errors.user[0] === 'You are already a team member at this location') {
                                this.setState({
                                    joinError: intl.formatMessage(messages.alreadyMemberError),
                                });
                            }
                        }
                    }
                );
        }
    };

    _handleLocationCodeChange = (e) => {
        const { value } = e.target;

        this.setState({
            locationCode: value,
            isCodeValid: value.length >= 6 && value.length <= 10,
            showError: false,
        });
    };

    _handleSubmitLocationCode = (e) => {
        const { isCodeValid } = this.state;
        if (isCodeValid) {
            this._submitLocationCode();
        } else {
            this.setState({
                showError: true,
            });
        }
    };

    _handleCancel = () => {
        this.setState({
            locationCode: '',
            showError: false,
        });
    }

    _handleBack = () => {
        this.setState({
            locationCode: '',
            showError: false,
            step: 1
        });
    }

    _submitLocationCode = () => {
        const { intl } = this.props;
        const { location, locationCode } = this.state;
        this.setState({
            location: Object.assign(location, {
                isLoading: true,
            }),
            joinError: '',

        });
        // call api
        SearchLocation(locationCode).then(
            (response) => {
                if (response.status === 200) {
                    this.setState({
                        location: Object.assign(location, {
                            hasBeenCalled: true,
                            isLoading: false,
                            data: response.data.location,
                        }),
                        step: 3,
                    });
                }

                if (response.status === 404) {
                    this.setState({
                        location: Object.assign(location, {
                            hasBeenCalled: true,
                            isLoading: false,
                            data: null,
                        }),
                        joinError: intl.formatMessage(messages.locationNotFoundError),
                    });
                }
            },
            ({ response }) => {
                if (response && response.status === 404) {
                    this.setState({
                        location: Object.assign(location, {
                            hasBeenCalled: true,
                            isLoading: false,
                            data: null,
                        }),
                        joinError: intl.formatMessage(messages.locationNotFoundError),
                    });
                } else {
                    this.setState({
                        location: Object.assign(location, {
                            hasBeenCalled: true,
                            isLoading: false,
                            data: null,
                        }),
                        joinError: intl.formatMessage(messages.ourSideError),
                    });
                }
            }
        );
    };

    _confirmLocation = () => {
        const { intl } = this.props;
        const { location } = this.state;
        // call api to join location

        this.setState({
            location: Object.assign(location, {
                isLoading: true,
            }),
            joinError: '',
        });

        LocationJoin(location.data.id).then(
            (response) => {
                if (response.status === 201 || response.status === 200) {
                    setLocationCookie(location.data.id);
                    this.props.addedLocation(location.data.id);
                    this.props.onClose(); // Close the modal after success
                }
            },
            ({ response }) => {
                this.setState({
                    location: Object.assign(location, {
                        isLoading: false,
                    }),
                });

                if (response.status === 401 || response.status === 403) {
                    this.setState({
                        joinError: intl.formatMessage(messages.notAuthError),
                    });
                }

                if (response.status === 500 || response.status === 422) {
                    this.setState({
                        joinError: intl.formatMessage(messages.ourSideError),
                    });
                }

                if (response.status === 422) {
                    if (response.data.errors.user[0] === 'You are already a team member at this location') {
                        this.setState({
                            joinError: intl.formatMessage(messages.alreadyMemberError),
                        });
                    }
                }
            }
        );
    };

    _renderInputFormErrorDescription = () => {
        const { intl } = this.props;
        const { showError, joinError } = this.state;

        if (showError) {
            return intl.formatMessage(messages.codeLengthError);
        } else {
            if (joinError) {
                return joinError;
            }
        }
        return '';
    };

    switchLocationSelection = () => {
        this.setState({
            isCreateLocationSelected: !this.state.isCreateLocationSelected,
            showZipCodeInput: !this.state.isCreateLocationSelected,
        });
    }

    handleCreateLocation = () => {
        this.setState({
            step: 5,
            isCreateLocationSelected: true,
        });
    };

    handleJoinLocation = () => {
        this.setState({
            step: 2,
            isCreateLocationSelected: false,
        });
    };

    handleAddressChange = (address) => {
        this.setState({
            address,
            showZipCodeInput: address.length > 0
        }, this.validateForm);
    };

    handleZipCodeChange = (e) => {
        this.setState({
            zipCode: e.target.value
        }, this.validateForm);
    };

    handleAddressSelect = (address, placeId) => {
        // Use Google Maps API to fetch the place details
        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({ placeId }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                let zipCodeTemp = "";
                if (place.address_components) {
                    place.address_components.forEach(component => {
                        if (component.types.includes("postal_code")) {
                            zipCodeTemp = component.long_name; // Extract ZIP code
                        }
                    });
                }

                this.setState({
                    placeDetails: place, // Store the full place object
                    address: address, // Update address in state
                    zipCode: zipCodeTemp
                },
                    this.validateForm);
            }
        });
    };


    validateForm = () => {
        const { placeDetails, zipCode, showZipCodeInput, address } = this.state;

        // Check if all fields are valid
        this.setState({
            isFormValid: ((placeDetails && placeDetails.place_id) || (address && showZipCodeInput && zipCode && zipCode.length > 3)),
        });
    };

    render() {
        const { intl, onClose } = this.props;
        const { showError, location, joinError, step,
            isCreateLocationSelected, isFormValid,
            isCodeValid, isGoogleApiLoaded, showLoadingDialog,
            address, showZipCodeInput, zipCode, } = this.state;
        const inputFormErrorClassName = classNames({
            'location-page--error': showError || joinError,
        });

        const nextBtn = (<FormattedMessage
            id="AddLocationPageCode.next"
            defaultMessage="Next"
        />);

        const cancel = (<FormattedMessage
            id="Confirm.cancel"
            defaultMessage="Cancel"
        />);

        console.log("isCreateLocationSelected - ", isCreateLocationSelected);
        console.log("step - ", step);

        return (
            <div className="add-location-dialog">
                {step === 1 && (
                    <div className="location-page" style={{ minHeight: '600px', maxWidth: '540px', }}>
                        <button
                            className="close-btn"
                            onClick={onClose}
                        >
                            <CloseButton />
                        </button>
                        <div className="form-contents">
                            <div className="welcome-text-wrapper">
                                <div className="dot"></div>
                                <div className="welcome-text">We're expanding!</div>
                            </div>
                            <div className="main-heading">
                                Add a new location.
                            </div>
                            <div className="sub-heading" style={{ marginBottom: '16px', }}>
                                What would you like to do? Create a new location for your team, or join an existing location?
                            </div>

                            {/* <CurrentStep /> */}

                            <button className="next-btn" onClick={this.handleCreateLocation}>Create a new location</button>

                            <button className="clear-btn" onClick={this.handleJoinLocation}>Join an existing location</button>
                        </div>
                    </div>
                )}
                {(step === 2 || step === 5) && (
                    <div className="location-page" style={{ minHeight: '600px', maxWidth: '540px', }}>
                        <button
                            className="close-btn"
                            onClick={onClose}
                        >
                            <CloseButton />
                        </button>
                        <div className="form-contents">
                            <div className="welcome-text-wrapper">
                                <div className="dot"></div>
                                <div className="welcome-text">{isCreateLocationSelected ? "Finding location" : "Finding your team"}</div>
                            </div>

                            <div className="main-heading">
                                {isCreateLocationSelected ? "Create a new location." : "Your team has been waiting for you."}
                            </div>

                            {isCreateLocationSelected &&
                                <div className="sub-heading" style={{ marginBottom: '16px', }}>
                                    Search for the location you'd like to create on Shyft. You will be able to toggle between locations from your profile.
                                </div>
                            }

                            <div className="name-prompt">{isCreateLocationSelected ? "What's your company name?" : "Enter your unique 6-digit location code"}</div>

                            {!isCreateLocationSelected && (
                                <>
                                    <div className="icon-input-container">
                                        <input
                                            type="text"
                                            placeholder="SHY1234"
                                            className="icon-name-input"
                                            name="locationcode"
                                            onKeyDown={this.handleKeyDown}
                                            onChange={this._handleLocationCodeChange}
                                        />
                                        <img
                                            className="input-icon-left"
                                            src='/icons/pin-entry-icon.svg'
                                        />
                                    </div>

                                    <p className="error-message">
                                        {this._renderInputFormErrorDescription()}
                                    </p>
                                </>
                            )}

                            {isCreateLocationSelected && isGoogleApiLoaded && (
                                <PlacesAutocomplete
                                    value={address}
                                    onChange={this.handleAddressChange}
                                    onSelect={this.handleAddressSelect} // Optionally handle the selection of an address
                                    searchOptions={{
                                        types: ['establishment'], // Restrict results to US, or update as needed
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div className='name-input-container'>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Search for company',
                                                    className: 'name-input',
                                                })}
                                                onKeyDown={this.handleKeyDown}
                                            />
                                            <div className="country-dropdown">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion, index) => {
                                                    const regex = new RegExp(`(${address})`, 'gi');
                                                    const highlightedText = suggestion.description.replace(
                                                        regex,
                                                        `<strong>$1</strong>`
                                                    );

                                                    return (
                                                        <div
                                                            key={index}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className: 'suggestion-item',
                                                            })}
                                                            dangerouslySetInnerHTML={{ __html: highlightedText }}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            )}

                            {showZipCodeInput && (
                                <>
                                    <div className="name-prompt">What's your location zip code?</div>
                                    <div className="name-input-container">
                                        <input
                                            type="text"
                                            placeholder="Enter ZIP Code"
                                            className="name-input"
                                            value={zipCode}
                                            onChange={this.handleZipCodeChange}
                                            onKeyDown={this.handleKeyDown}
                                        />
                                    </div>
                                </>
                            )}

                            <button
                                className="next-btn"
                                onClick={isCreateLocationSelected ? this.handleNext : this._handleSubmitLocationCode}
                                disabled={isCreateLocationSelected ? !isFormValid : !isCodeValid}> {isCreateLocationSelected ? "Create location" : "Next"}</button>

                            <div className="login-message">
                                {isCreateLocationSelected ? "Know your location code?" : "Don't know your location code?"}{' '}
                                <a className="login-message-link" onClick={this.switchLocationSelection}>
                                    {isCreateLocationSelected ? "Search by code" : "Search for location"}
                                </a>
                            </div>

                        </div>
                    </div>
                )}
                {
                    step === 3 && (
                        <div className="location-page" style={{ minHeight: '600px', maxWidth: '540px', }}>
                            <button
                                className="close-btn"
                                onClick={onClose}
                            >
                                <CloseButton />
                            </button>
                            <div className="form-contents">
                                <div className="welcome-text-wrapper">
                                    <div className="dot"></div>
                                    <div className="welcome-text">Finding location</div>
                                </div>
                                <div className="main-heading">
                                    Join this location?
                                </div>
                                <div className="name-prompt" style={{ marginTop: '8px' }}>You entered location code: {location && location.data && location.data.swift_code}</div>

                                <div className="confirm-location-container" >
                                    <div className="confirm-location-avatar-container">
                                        <img src='/img/channel-placeholder.svg' alt='Location Avatar' />
                                    </div>

                                    <div className="confirm-location-info-div">
                                        <label className="confirm-location-title">
                                            {location && location.data && location.data.location_name}
                                        </label>
                                        <label className="confirm-location-title">
                                            {location && location.data && location.data.formatted_address}
                                        </label>
                                    </div>
                                </div>

                                {location && location.data && <GoogleMapView lat={location.data.latitude} lng={location.data.longitude} />}
                                <button className="next-btn" onClick={this._confirmLocation}>Confirm & Join</button>
                                <p className="error-message">
                                    {this._renderInputFormErrorDescription()}
                                </p>
                            </div>
                        </div>
                    )

                }

                {showLoadingDialog && (
                    <div className="loading-modal">
                        <div className="loading-dialog">
                            <div className="spinner"></div>
                            <div className="loading-text">Please wait...</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        addedLocation: (locationCode) => {
            dispatch(addLocation(locationCode));
        },
    };
};

const AddLocationDialogi18n = injectIntl(AddLocationDialog);

export default connect(null, mapDispatchToProps)(AddLocationDialogi18n);
