import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import 'helpers/findIndex-shim';
import { Redirect, Link } from 'react-router-dom';
import { validateEmail } from 'helpers/validation';
import EmailConfirmation from './EmailConfirmation/EmailConfirmation';
import PhoneConfirmation from './PhoneConfirmation/PhoneConfirmation';

import './LoginSecondStage.scss';

class LoginSecondStage extends React.Component {
  state = {
    validity: true,
    email: this.props.email,
    phone: this.props.phone,
    password: '',
    mobilePin: '',
  };

  _emailChange = (e) => {
    const [value] = e.target;

    this.setState({
      validity: validateEmail(value),
      value,
    });
  };

  _passwordChange = (e) => {
    const { value } = e.target;

    this.setState({
      password: value,
    });
  };

  _mobilePinChange = (val) => {
    this.setState({
      mobilePin: val,
    });
  };

  _phoneChange = (status, number, country) => {
    this.setState({
      value: `${country.dialCode}${number}`,
      validity: status,
    });
  };

  _submitForm = (e) => {
    e.preventDefault();
    if (this.props.type === 'email') {
      this.props._passwordSubmit(this.state.email, this.state.password);
    } else if (this.props.type === 'phone') {
      this.props._pinSubmit(this.state.mobilePin);
    }
  };

  _loginWithEmail = () => {
    // api call with email/password combination
    this.props._passwordSubmit(this.state.password);
  };

  _loginWithPhone = (pin) => {
    // api call with phone/pin combination
    this.props._pinSubmit(pin);
  };

  _renderConfirmationComponent = () => {
    if (this.props.type === 'email') {
      return (
        <EmailConfirmation
          value={this.state.email}
          validity={this.state.validity}
          inputChange={this._emailChange}
          passwordChange={this._passwordChange}
          submitForm={this._submitForm}
        />
      );
    }
    if (this.props.type === 'phone') {
      return (
        <PhoneConfirmation
          value={this.state.phone}
          validity={this.state.validity}
          inputChange={this.phoneChange}
          _pinChange={this._mobilePinChange}
          mobilePinError={this.props.mobilePinError}
          submitForm={this._submitForm}
        />
      );
    }
    return null;
  };

  _handleResendPin = (e) => {
    const { phoneResend, intl } = this.props;
    const { phone } = this.state;
    e.preventDefault();

    phoneResend({
      type: 'phone',
      value: phone,
    });

    return null;
  };


  render() {
    return (
      // <form className="login__form" onSubmit={this._submitForm}>
      <>

        {this._renderConfirmationComponent()}

        <section className="login__form-errors">
          <p className='error-message'>
            {this.props.mobilePinError}
          </p>

          <p className='error-message'>
            {this.props.emailPasswordError}
          </p>
        </section>

        <button className="next-btn" onClick={this._submitForm}>
          <FormattedMessage
            id="LoginSecondStage.logIn"
            defaultMessage="Log In"
          />
        </button>

        {this.props.type === 'email' && (
          <div className="login-message">
            Forgot Password?{' '}
            <Link className="login-message-link" to="/password-reset">
              Reset Password
            </Link>
          </div>
        )}

        {this.props.type === 'phone' && (
          <>
            <div className="login-message">
              Didn't receive verification code?{' '}
              <a className="login-message-link" onClick={this._handleResendPin}>
                Resend Code
              </a>
            </div>
            <div className="sub-heading">A new verification code has been sent to your phone number.</div>
          </>
        )}

        <section className="login__form-action">
          <div className="login-message">
            <a className="login-message-link" onClick={this.props.back} href="#">
              {this.props.type === 'email' ? 'Sign in with phone number' : 'Sign in with email'}
            </a>
          </div>

          <div className="login-message">
            Don't have an account?{' '}
            <Link className="login-message-link" to="/sign-up">
              Sign Up
            </Link>
          </div>
        </section>
      </>
    );
  }
}

//Fixed bug WS-1243. Changed "mobilePinError" type from string to object
LoginSecondStage.propTypes = {
  type: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  _pinSubmit: PropTypes.func.isRequired,
  mobilePinError: PropTypes.string,
  emailPasswordError: PropTypes.string,
  _backButtonText: PropTypes.string,
};

LoginSecondStage.defaultProps = {
  email: '',
  phone: '',
  mobilePinError: '',
  emailPasswordError: '',
  _backButtonText: '',
};

export default LoginSecondStage;
