import React from 'react';
import { connect } from 'react-redux';
import { scheduleCalendarOperations } from 'pages/ScheduleCalendar/duck';
import ShiftFieldsComponent from './ShiftFieldsComponent';
import timeUtil from 'pages/ScheduleCalendar/duck/timeUtil';

const mapStateToProps = ({
  scheduleCalendarReducer,
  locationReducer,
  appReducer,
  unregisteredUserReducer,
}) => {
  return {
    allUsers: scheduleCalendarReducer.allUsers.users,
    locationData: locationReducer.location_data,
    channels: appReducer.channels.data,
    unregisteredUsers: unregisteredUserReducer.unregisteredUsers.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  
  const addDraftShiftToUserDispatch = (user, startDateTime, endDateTime, jobTitle, locationId, selectedDays, date, fiscalStartDay) => {
    const shiftTimeList = timeUtil.getAdjustedShiftTimesForMultipleDays(startDateTime, endDateTime, selectedDays, date, fiscalStartDay);
    shiftTimeList.forEach((startEndTimePair) => {
        const adjustedStartDateTime = startEndTimePair.start;
        const adjustedEndDateTime = startEndTimePair.end;
        if (user.status !== 'unregistered') {
          dispatch(scheduleCalendarOperations.addDraftShiftToUserOnDateOperation(user.owner_id, adjustedStartDateTime, adjustedEndDateTime, jobTitle, locationId));
        } else {
          dispatch(scheduleCalendarOperations.addDraftShiftToUnregisteredUserOnDateOperation(user.id, adjustedStartDateTime, adjustedEndDateTime, jobTitle, locationId));
        }
    });
  };

  const addDraftOpenShiftToUserDispatch = (currentUserId, startDateTime, endDateTime, jobTitle, locationId, selectedDays, date, fiscalStartDay, channelId, quantity, shiftDescription) => {
    const shiftTimeList = timeUtil.getAdjustedShiftTimesForMultipleDays(startDateTime, endDateTime, selectedDays, date, fiscalStartDay);
    shiftTimeList.forEach((startEndTimePair) => {
        const adjustedStartDateTime = startEndTimePair.start;
        const adjustedEndDateTime = startEndTimePair.end;
        dispatch(scheduleCalendarOperations.addDraftOpenShiftToUserOnDateOperation(currentUserId, adjustedStartDateTime, adjustedEndDateTime, jobTitle, locationId, channelId, shiftDescription, quantity));
    });
  };


  const updateDraftShiftDispatch = (shift, startDateTime, endDateTime, jobTitle, date) => {
    // Adjust the start and end date based on the day
    const adjustedStartDateTime = timeUtil.dayLightSavingsAdjustedDateTime(date, date, startDateTime);
    var adjustedEndDateTime = timeUtil.dayLightSavingsAdjustedDateTime(date, date, endDateTime);
    
    // Handle overnight shift case (where end time is after midnight)
    if (timeUtil.isOvernightShift(startDateTime, endDateTime, date)) {
      const nextDay = date.clone().add(1, 'days');
      adjustedEndDateTime = timeUtil.dayLightSavingsAdjustedDateTime(nextDay, date, endDateTime);
    }

   dispatch(scheduleCalendarOperations.updateDraftShiftOperation(shift.id, adjustedStartDateTime, adjustedEndDateTime, jobTitle));
 }

 const updateDraftOpenShiftDispatch = (shiftId, currentUserId, startDateTime, endDateTime, jobTitle, locationId, date, channelId, quantity, shiftDescription) => {
    deleteDraftOpenShiftDispatch(shiftId, locationId)
    const adjustedStartDateTime = timeUtil.dayLightSavingsAdjustedDateTime(date, date, startDateTime);
    var adjustedEndDateTime = timeUtil.dayLightSavingsAdjustedDateTime(date, date, endDateTime);
    dispatch(scheduleCalendarOperations.addDraftOpenShiftToUserOnDateOperation(currentUserId, adjustedStartDateTime, adjustedEndDateTime,jobTitle, locationId, channelId, shiftDescription, quantity));
 }

 const hackMoveDraftOpenShiftDispatch = (shift, targetDate) => {
  updateDraftOpenShiftDispatch(shift.id, shift.owner_id, shift.start_at, shift.end_at, shift.job_title, shift.location_id, targetDate, shift.channel_id, shift.total_shifts_available, shift.content);
 }

 const deleteDraftOpenShiftDispatch = (shiftId, locationId) => {
  dispatch(scheduleCalendarOperations.deleteShiftOperation(shiftId, locationId));
};

 const updatePublishedOpenShiftDispatch = (openShift, totalAvailable, content, position ) => {
    dispatch(scheduleCalendarOperations.updatePublishedOpenShiftOperation(openShift.id, totalAvailable, content, position));
 }

  return {
    addDraftOpenShiftToUserDispatch,
    addDraftShiftToUserDispatch,
    updateDraftShiftDispatch,
    updatePublishedOpenShiftDispatch,
    updateDraftOpenShiftDispatch,
  };
};

const ShiftFieldsComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const {
    allUsers,
    locationData,
    channels,
    unregisteredUsers,
    applicants,

    addDraftShiftToUserDispatch,
    addDraftOpenShiftToUserDispatch,
    updateDraftShiftDispatch,
    updatePublishedOpenShiftDispatch,
    updateDraftOpenShiftDispatch,
    hackMoveDraftOpenShiftDispatch,

    presentedShift,
    cancelModal,
    prepareToDeleteShift,
    prepareToCopyShift,
    date,
    userToAddShiftTo,
    showOpenShiftOption,
    } = props;

  return <ShiftFieldsComponent
    allUsers={allUsers}
    locationData={locationData}
    channels={channels}
    unregisteredUsers = {unregisteredUsers}
    applicants = {applicants}

    addDraftOpenShiftToUserDispatch={addDraftOpenShiftToUserDispatch}
    addDraftShiftToUserDispatch={addDraftShiftToUserDispatch}
    updateDraftShiftDispatch={updateDraftShiftDispatch}
    updatePublishedOpenShiftDispatch={updatePublishedOpenShiftDispatch}
    updateDraftOpenShiftDispatch={updateDraftOpenShiftDispatch}
    hackMoveDraftOpenShiftDispatch = {hackMoveDraftOpenShiftDispatch}

    presentedShift={presentedShift}
    cancelModal={cancelModal}
    prepareToDeleteShift={prepareToDeleteShift}
    prepareToCopyShift={prepareToCopyShift}
    date={date}
    userToAddShiftTo = {userToAddShiftTo}
    showOpenShiftOption = {showOpenShiftOption}
   /> 
});

export default ShiftFieldsComponentContainer;