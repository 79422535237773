import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { appShapes } from 'App/duck';

import blocks from '../../blocks';
import elements from '../../elements';
import './ChannelsComponent.scss';

function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

class ChannelsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      sortedChannels: [],
    };
  }

  componentDidMount() {
    const { active, channels, setActiveChannel } = this.props;
    if(active){
      if(!channels.loading) {
        if(channels && channels.data && channels.data.length > 0) {
          const findChannel = channels.data.filter((channel) => channel.id === active); 
          if(findChannel && findChannel.length > 0) {
            setActiveChannel(findChannel[0].id);
          }else {
            //active may be for previous location
            setActiveChannel(channels.data[0].id);
          }
          let sorted = [...channels.data];
          this.sortChannelWithNullValues(sorted, false);
          this.setState({sortedChannels: sorted});
        }
      }
    }else{
      if(channels && channels.data && channels.data.length > 0) {
        setActiveChannel(channels.data[0].id);
      }
    }

  }

  componentDidUpdate(prevProps) {
    const { active, channels, setActiveChannel, locations, locationId, ResetNotifyNewPost, } = this.props;
    if(prevProps.channels !== channels) {
      if(active) {
        const findChannel = channels.data.filter((channel) => channel.id === active); 
        if(findChannel && findChannel.length > 0) {
          if(prevProps.channels.data) {
            if(prevProps.channels.data.length !== channels.data.length) {
              setActiveChannel(findChannel[0].id);
            }else {
              for(let i=0; i<channels.data.length; i++) {
                if(channels.data[i].id !== prevProps.channels.data[i].id) {
                  setActiveChannel(findChannel[0].id);
                  break;
                }
              }
            }
          }else {
            setActiveChannel(findChannel[0].id);
          }
        }else {
          //active may be for previous location
          setActiveChannel(channels.data[0].id);
        }
      }else {
        if(channels && channels.data && channels.data.length > 0) {
          setActiveChannel(channels.data[0].id);
        }
      }
  
      let sorted = [...channels.data];
      this.sortChannelWithNullValues(sorted, false);
      this.setState({sortedChannels: sorted});
    }

  }


  handleChannelClicked = (event) => {
    const { setActiveChannel, ResetNotifyNewPost, } = this.props;
    setActiveChannel(event);
    ResetNotifyNewPost(event);
  }

  sortChannelWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.updated_at === b.updated_at) // identical? return 0
        return 0;
      else if (a.updated_at === null)  // a is null? last 
        return 1;
      else if (b.updated_at === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.updated_at.localeCompare(b.updated_at) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  render() {
    const { channels, active, setActiveChannel, locations, locationId, } = this.props;
    const { location, sortedChannels, } = this.state;

    const title = (
      <FormattedMessage 
        id="Channels.header" 
        defaultMessage="Channels" />
      );
    return (
      <elements.ColumnWrap maxWidth="360px">
        {/*<blocks.Header>
          <FormattedMessage id="Channels.header" defaultMessage="Channels" />
        </blocks.Header>*/}
        <div className="channel__wrap">
          <p className="channel__title">{title}</p>
          {/*<button className="channel__compose-button" onClick={this.handleComposeClicked}/>*/}
        </div>
        <elements.ScrollWrap>
          {channels.loading ||
            //channels.data.map((channel) => (
            sortedChannels.map((channel) => (
              <blocks.Channel
                key={channel.id}
                active={channel.id === active}
                channel={channel}
                //handleClick={setActiveChannel}
                handleClick={this.handleChannelClicked}
                locations={locations}
                locationId={locationId}
              />
            ))}
        </elements.ScrollWrap>
      </elements.ColumnWrap>
    );
  }
}

ChannelsComponent.propTypes = {
  setActiveChannel: PropTypes.func.isRequired,
  channels: appShapes.channelsShape.isRequired,
  active: PropTypes.number.isRequired,
};

export default ChannelsComponent;
