import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  channels: slices.channelsSlice.reducer,
  users: slices.usersSlice.reducer,
  location: slices.locationSlice.reducer,
  skills: slices.skillsSlice.reducer,
  departments: slices.departmentsSlice.reducer,
  actionJobs: slices.actionJobsSlice.reducer,
  allUsers: slices.allUsersSlice.reducer,
  managers: slices.managersSlice.reducer,
  associates: slices.associatesSlice.reducer,
  pendingUsers: slices.pendingUsersSlice.reducer,
  removedUsers: slices.removedUsersSlice.reducer,
  onlineUsers: slices.onlineUsersSlice.reducer,
  plans: slices.plansSlice.reducer,
};

const reducer = combineReducers({
  channels: reducers.channels,
  users: reducers.users,
  location: reducers.location,
  skills: reducers.skills,
  departments: reducers.departments,
  actionJobs: reducers.actionJobs,
  allUsers: reducers.allUsers,
  managers: reducers.managers,
  associates: reducers.associates,
  pendingUsers: reducers.pendingUsers,
  removedUsers: reducers.removedUsers,
  onlineUsers: reducers.onlineUsers,
  plans: reducers.plans,
});

export default reducer;
