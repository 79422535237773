import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { Mixpanel } from '../../../../../Mixpanel';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import '../Cell/Cell.scss';
import presenter from 'pages/ScheduleCalendar/duck/presenter';
import styled from '../Cell/styled';
import draftLogic from 'pages/ScheduleCalendar/duck/draftLogic';
import formatter from 'pages/ScheduleCalendar/duck/formatter';
import { isValidObject } from 'helpers/validation';

export function OpenShiftDraggableCell ({user,
    shifts,
    selectedDateToAddShift,
    date,
    handleOpenShiftDetail,
    copyOpenShiftToDateDispatch,
    shiftToCopy,
    isCopyingShift,
    }) {

  const ref = useRef(null);

  const canDragOpenShift = () => {
    if( shifts != null ){
      const shiftToDrag = shifts[0];
      if( shiftToDrag != null){
        if ( shiftToDrag.trade_status === 'unpublished_posted') { 
          return true;
        }else{
          return false;
        }
      }
    }
    return false;
  }

  const [{isDragging, canDrag}, drag] = useDrag(() => ({
    type: 'OpenShiftCell',
    item: () => (shifts?.length ? shifts[0] : { id: 0 }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag:  canDragOpenShift(),
  }), [canDragOpenShift])

  drag(ref);

  const [isMouseOverCell, setIsMouseOverCell] = useState(false);

  const onCellClick = useCallback(() => {
    if (shifts && shifts.length > 0) {
      Mixpanel.track('View open shift detail', { 'date': date.format('YYYY-MM-DD') });
      handleOpenShiftDetail(shifts[0], date);
    } else {
      setIsMouseOverCell(false);
      selectedDateToAddShift(user, date);

      Mixpanel.track('Add Open Shift ', { 'date': date.format('YYYY-MM-DD') });
    }
  }, [shifts, user, date, handleOpenShiftDetail, selectedDateToAddShift]);

  const onPasteClick = useCallback(() => {
    setIsMouseOverCell(false);
    copyOpenShiftToDateDispatch(shiftToCopy, date, shiftToCopy.location_id);
    Mixpanel.track('Paste Open Shift');
  }, [copyOpenShiftToDateDispatch, shiftToCopy, user, date]);

  const onEmptyCellMouseEnter = () => { setIsMouseOverCell(true); };
  const onEmptyCellMouseLeave = useCallback(() => { setIsMouseOverCell(false); }, []);

 
  if ( isValidObject(shifts) == false || shifts.length === 0) {
    if (isCopyingShift && shiftToCopy !== null) {
      return presenter.pasteShiftCell(onPasteClick, onEmptyCellMouseEnter, onEmptyCellMouseLeave, isMouseOverCell);
    } else {
      return presenter.addNewShiftCell(onCellClick, onEmptyCellMouseEnter, onEmptyCellMouseLeave, isMouseOverCell);
    }
  }

  let position = '';
  let start = null;
  let end = null;
  let hours = 0;
  let timeRange = '';

  if (shifts && shifts.length > 0) {
    start = moment(shifts[0].start_at).local().clone();
    end = moment(shifts[0].end_at).local().clone();
    timeRange = formatter.formattedShiftTimeRange(shifts[0]);
    const diff = moment.duration(end.diff(start));
    hours = parseFloat(diff.asHours()).toFixed(2);
    position = shifts[0].primary_job;
  }

  const shiftObject = shifts[0];
  const tagText = presenter.tagTextForOpenShift(shiftObject)
  const isDraftShift = draftLogic.isDraftOpenShift(shiftObject);

  const cardName = presenter.cardClassNameForShift(shiftObject);

  const bottomLine = (
    <styled.DayCellBottomLineWrap>
      <styled.DayCellTextPosition>{position}</styled.DayCellTextPosition>
        {position.length > 0 && <styled.Dot />}
      <styled.BottomLineText>{presenter.textHours(hours)}</styled.BottomLineText>
    </styled.DayCellBottomLineWrap>
  );

  return (
    <div className= {"day-cell-top-wrap " + cardName } ref={ref} onMouseUpCapture={onCellClick} key= {shifts[0].id}>
        {tagText !== '' && <span className='status-tag'> {tagText} </span>}

        <div className= {(isDraftShift) ? 'day-cell dotted-border': 'day-cell solid-border'}>
          <div className='top-row'> <label className='quantity-bubble'> {shiftObject.total_shifts_available} </label> <label className='time-label'> {timeRange} </label> </div>
          {bottomLine}
        </div>

    </div>
  );

};

export default OpenShiftDraggableCell; 