import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import {
  openLocationCalendarFilters,
  closedLocationCalendarFilters,
  CalendarShiftTypes,
} from 'config';

import WeekPickerComponent from '../WeekPickerComponent/WeekPickerComponent';

import './FilterContainer.scss';

import { startOfLocationFiscalWeek, endOfLocationFiscalWeek } from '../../../helpers/date-time';

import { Mixpanel } from '../../../Mixpanel';
import { NavLink } from 'react-router-dom';

const weekFormat = 'ddd';

class FilterContainer extends React.Component {
  constructor(props) {
    super(props);
    const {
      location_code,
      location_permission,
      vtoEnabled,
      typeFilters,
      dayFilters,
      stateFilters,
    } = props;

    const initialStateFilters = location_permission
      ? closedLocationCalendarFilters
      : openLocationCalendarFilters;
    const mappedStateFilters = initialStateFilters.map((element) => {
      return {
        ...element,
        checked: stateFilters.indexOf(element.value) > -1,
      };
    });

    this.state = {
      location_id: location_code,
      locationPermission: location_permission,
      stateFilters: mappedStateFilters,
      displayedDateRangeStart: null,
      displayedDateRangeEnd: null,
      typeFilters: [
        {
          label: CalendarShiftTypes.teamShift,
          value: 'shift',
          checked: typeFilters.indexOf('shift') > -1,
        },
        {
          label: CalendarShiftTypes.openShift,
          value: 'open_shift',
          checked: typeFilters.indexOf('open_shift') > -1,
        },
        ...(vtoEnabled
          ? [
            {
              label: CalendarShiftTypes.vto,
              value: 'shift_vto',
              checked: typeFilters.indexOf('shift_vto') > -1,
            },
          ]
          : []),
      ],
      dayFilters: [
        {
          label: moment('2019-01-21').format(weekFormat),
          value: 1,
          checked: dayFilters.indexOf(1) > -1,
        },
        {
          label: moment('2019-01-22').format(weekFormat),
          value: 2,
          checked: dayFilters.indexOf(2) > -1,
        },
        {
          label: moment('2019-01-23').format(weekFormat),
          value: 3,
          checked: dayFilters.indexOf(3) > -1,
        },
        {
          label: moment('2019-01-24').format(weekFormat),
          value: 4,
          checked: dayFilters.indexOf(4) > -1,
        },
        {
          label: moment('2019-01-25').format(weekFormat),
          value: 5,
          checked: dayFilters.indexOf(5) > -1,
        },
        {
          label: moment('2019-01-26').format(weekFormat),
          value: 6,
          checked: dayFilters.indexOf(6) > -1,
        },
        {
          label: moment('2019-01-20').format(weekFormat),
          value: 0,
          checked: dayFilters.indexOf(0) > -1,
        },
      ],
    };
  }

  componentDidMount() {
    const { loadLocationDataDispatch } = this.props;
    loadLocationDataDispatch();
  }

  componentDidUpdate(prevState, state) {
    const { displayedDateRangeEnd, displayedDateRangeStart } = this.state;
    const { changeWeek, locationData } = this.props;

    if (prevState.locationData !== locationData) {
      console.log(JSON.stringify(locationData));
      const start = startOfLocationFiscalWeek(locationData.fiscal_day);
      const end = endOfLocationFiscalWeek(locationData.fiscal_day);
      changeWeek(start, end);
      this.setState({ displayedDateRangeStart: start });
      this.setState({ displayedDateRangeEnd: end });
    } else if (prevState.displayedDateRangeStart !== displayedDateRangeStart ||
      prevState.displayedDateRangeEnd !== displayedDateRangeEnd) {
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {
      location_id,
      locationPermission,
    } = this.state;
    if (props.location_id !== location_id) {
      this.setState({ location_id: props.location_id });
    }

    console.log('CALLED AT ALL');
    if (props.location_permission !== locationPermission) {
      this.setState({
        locationPermission: props.location_permission,
        stateFilters: props.location_permission
          ? closedLocationCalendarFilters
          : openLocationCalendarFilters,
      });
    }
  }

  updateDisplayDateRange = (start, end) => {
    this.setState({ displayedDateRangeStart: start });
    this.setState({ displayedDateRangeEnd: end });
  }

  moveCurrentWeekBackward = () => {
    const { changeWeek, dateRangeChanged, currentDateRangeStart, currentDateRangeEnd } = this.props;

    const newDateRangeStart = currentDateRangeStart.subtract(1, 'week');
    const newDateRangeEnd = currentDateRangeEnd.subtract(1, 'week');

    changeWeek(newDateRangeStart, newDateRangeEnd);
    dateRangeChanged(newDateRangeStart, newDateRangeEnd);
    this.updateDisplayDateRange(newDateRangeStart, newDateRangeEnd);
    Mixpanel.track('Previous requests week', { 'target_week': newDateRangeStart.format("MMM Do YYYY") + ' - ' + newDateRangeEnd.format("MMM Do YYYY") })
  };

  moveCurrentWeekForward = () => {
    const { changeWeek, dateRangeChanged, currentDateRangeStart, currentDateRangeEnd } = this.props;

    const newDateRangeStart = currentDateRangeStart.add(1, 'week');
    const newDateRangeEnd = currentDateRangeEnd.add(1, 'week');

    changeWeek(newDateRangeStart, newDateRangeEnd);
    dateRangeChanged(newDateRangeStart, newDateRangeEnd);
    this.updateDisplayDateRange(newDateRangeStart, newDateRangeEnd);
    Mixpanel.track('Next requests week', { 'target_week': newDateRangeStart.format("MMM Do YYYY") + ' - ' + newDateRangeEnd.format("MMM Do YYYY") });
  };

  /**
   * Description - this encapsulates all of the checkbox arrays (all three of them),
   * and maps through them, changing the correct one
   * @param  { String } checkedValue - the value that originates from the checkbox that is clicked
   * @param  { Array } array - the array of filters
   * @param  { Func } cb - the redux action with the correct action performed on the checkbox
   *
   * @return { Array } - newly generated array to update state with
   */
  _updateCheckboxArray = (checkedValue, array, cb) => {
    const filterObject = {};
    return array.map((element) => {
      if (element.value == checkedValue) {
        // create filter object
        filterObject.value = element.value;
        filterObject.action = element.checked ? 'delete' : 'add';
        // callback once element is found and action is determined
        cb(filterObject);
        return Object.assign({}, element, {
          checked: !element.checked,
        });
      }
      return element;
    });
  };

  stateCheckboxChanged = (e) => {
    e.stopPropagation();
    const { stateFilters } = this.state;
    const { stateFilterUpdate } = this.props;
    if (e.target.value) {
      const newStateFilters = this._updateCheckboxArray(
        e.target.value,
        stateFilters,
        stateFilterUpdate
      );
      this.setState({ stateFilters: newStateFilters });
    }
  };

  dayCheckboxChanged = (e) => {
    e.stopPropagation();
    const { dayFilters } = this.state;
    const { dayFilterUpdate } = this.props;
    if (e.target.value) {
      const dayStateFilters = this._updateCheckboxArray(
        e.target.value,
        dayFilters,
        dayFilterUpdate
      );
      this.setState({ dayFilters: dayStateFilters });
    }
  };

  typeCheckboxChanged = (e) => {
    e.stopPropagation();
    const { typeFilters } = this.state;
    const { typeFilterUpdate } = this.props;
    if (e.target.value) {
      const typeStateFilters = this._updateCheckboxArray(
        e.target.value,
        typeFilters,
        typeFilterUpdate
      );
      this.setState({ typeFilters: typeStateFilters });
    }
  };

  handleExportClick = (e) => {
    const { exportData, currentDateRangeStart, currentDateRangeEnd } = this.props;
    e.preventDefault();
    Mixpanel.track('Export shifts', { 'start': currentDateRangeStart, 'end': currentDateRangeEnd });
    exportData(currentDateRangeStart, currentDateRangeEnd);
  };

  _showPostShiftButton = () => {
    const { vtoEnabled, openShiftEnabled } = this.props;

    if (vtoEnabled || openShiftEnabled) return true;

    return false;
  };

  render() {
    const { postOpenShiftEnabled, vtoEnabled, actionMenuEnabled, currentDateRangeStart, currentDateRangeEnd } = this.props;
    const {
      dayFilters,
      typeFilters,
      stateFilters,
      displayedDateRangeStart, displayedDateRangeEnd
    } = this.state;

    const exportText = <FormattedMessage id="Scheduling.exportToCSV" defaultMessage='Export' />
    const postShiftText = <FormattedMessage id="FilterContainer.postShift" defaultMessage="Post Shift" />
    return (
      <div className='header-row-wrap'>
        <div className='flex-col'>
          <WeekPickerComponent
            onChange={this.changeWeek}
            back={this.moveCurrentWeekBackward}
            forward={this.moveCurrentWeekForward}
            currentDateRangeEnd={displayedDateRangeEnd}
            currentDateRangeStart={displayedDateRangeStart}
          />
        </div>
        <div className='header-right-group'>
          <div className="dropdown">

            <button className='header-button' onClick={this.handleExportClick}>
              {exportText}
            </button>

            {actionMenuEnabled === 1 && (vtoEnabled || postOpenShiftEnabled !== 0) ? (
              <NavLink className="header-button" to="/post_shift" style={{ padding: '10px 20px' }} onClick={(e) => {
                Mixpanel.track('Click post shift');
              }}>
                {postShiftText}
              </NavLink>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default FilterContainer;

FilterContainer.propTypes = {
  exportData: PropTypes.func.isRequired,
  postOpenShiftEnabled: PropTypes.number,
  vtoEnabled: PropTypes.bool,
};

FilterContainer.defaultProps = {
  postOpenShiftEnabled: 0,
  vtoEnabled: true,
};
