import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

let currentTimezone;

const DEFAULT_EXPORT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const TIME_FORMAT = 'h:mm A';

export const numberForFiscalDay = (fiscalDay) => {
  if( fiscalDay === 'sunday' ){
    return 7;
  }else if ( fiscalDay === 'monday' ){
    return 1;
  }else if ( fiscalDay === 'tuesday' ){
    return 2;
  }else if ( fiscalDay === 'wednesday' ){
    return 3;
  }else if ( fiscalDay === 'thursday' ){
    return 4;
  }else if ( fiscalDay === 'friday' ){
    return 5;
  }else if ( fiscalDay === 'saturday' ){
    return 6;
  }else{
    return 1;
  }
}

export const startOfLocationFiscalWeek = (fiscalStartDay) => {
  return startOfFiscalWeek(fiscalStartDay, moment());
};

export const endOfLocationFiscalWeek = (fiscalStartDay) => {
  return startOfLocationFiscalWeek(fiscalStartDay).add(7, 'days').subtract(1, 'seconds');
};

export const startOfFiscalWeek = (fiscalStartDay, anchorDate) => {
  const fiscalDayNumber = numberForFiscalDay(fiscalStartDay);
  const momentAnchorDate = moment(anchorDate);
  const anchorDateNumber = momentAnchorDate.clone().day();

  if( fiscalDayNumber > anchorDateNumber){
    const minusDays = 7 - fiscalDayNumber + anchorDateNumber;
    const result = momentAnchorDate.clone().startOf('day').clone().subtract(minusDays, 'days');
    return result;

    console.log('Last Week Num: ' + fiscalStartDay + ' ' + JSON.stringify(result));
  }else{
    const minusDays = fiscalDayNumber - anchorDateNumber;
    const result = momentAnchorDate.clone().startOf('day').clone().add(minusDays, 'days');
    // console.log('This Week Num:' + fiscalStartDay + ' ' + JSON.stringify(result));
    return result;
  }

}

export const createApiDateFormat = (date) => {
  return moment(date)
    .utc()
    .format(DEFAULT_DATE_FORMAT);
};

export const createCsvDateFormat = (date) => {
  return moment(date).format(DEFAULT_EXPORT_DATE_FORMAT);
};

export const setDefaultTimezone = (timezone) => {
  if (timezone) {
    momentTimezone.tz.setDefault(timezone);
    currentTimezone = timezone;
  } else {
    momentTimezone.tz.setDefault();
    currentTimezone = momentTimezone.tz.guess();
  }
};

export const getDefaultTimezone = () => {
  return currentTimezone;
};

export const convertToCurrentTimezone = (date) =>
  moment.utc(date).tz(currentTimezone);

export const defaultShiftDateRangeFormat = (dateFrom, dateTo) => {
  const date = moment(dateFrom).format('MMM D');
  const timeFrom = moment(dateFrom).format('h:mmA');
  const timeTo = moment(dateTo).format('h:mmA');

  return `${date} | ${timeFrom} - ${timeTo}`;
};

export const commentFormat = (timestamp) => {
  const today = moment();
  if (today.isBefore(moment(timestamp), 'hour')) {
    return moment(timestamp).format('LT');
  }
  if (today.subtract(7, 'days').isBefore(moment(timestamp), 'hour')) {
    return moment(timestamp).format('ddd LT');
  }
  return moment(timestamp).format('M/D/Y LT');
};

export const messageDateFormat = (timestamp) => {
  let returnStr = '';
  const now = moment();
  const past = moment(timestamp);
  const pastToNow = moment.duration(now.diff(past));
  const daysPastToNow = parseInt(pastToNow.asDays());
  if(daysPastToNow > 7) {
    returnStr = past.format('MMM D');
  }else if(daysPastToNow <= 7 && daysPastToNow >= 1) {
    //Get weekdays
    returnStr = past.format('dddd');
  }else {
    returnStr = past.format('h:mm A');
  }
  return returnStr;
}

export const lastSevenDaysDateRange = (date) => {
  const to = date.format(DEFAULT_DATE_FORMAT);
  const from = date.subtract(7, 'days').format(DEFAULT_DATE_FORMAT);

  return {
    from,
    to,
  };
};

export const formatDateWithDayAndTimezone = (date) => {
  return moment(date).format('ddd, MMM Do');
};

export const isNextDay = (start, end) =>
  moment(start).day() !== moment(end).day();

const shiftTime = (time) => moment(time).format(TIME_FORMAT);

export const formatShiftEndTime = (start_time, end_time) => {
  if (isNextDay(start_time, end_time)) {
    return `${moment(end_time).format(TIME_FORMAT)} (next day)`;
  }

  return shiftTime(end_time);
};

export const startEndRange = (start, end) =>
  `${shiftTime(start)} - ${formatShiftEndTime(start, end)}`;

export const formatDateRange = (dayFrom, dayTo) => {
  return `${dayFrom.format('MMM DD')} - ${dayTo.format('MMM DD')}`;
};

export const formatTimeWithTimezone = (date) => {
  return moment(date).format('LT');
};

export const formatWithTimezone = (date) => {
  return moment(date).format(DEFAULT_EXPORT_DATE_FORMAT);
};

export const formatDateWithTimezone = (date) => {
  const today = moment();
  const momentDate = moment(date);

  if (momentDate.isSame(today, 'day')) {
    return 'today';
  }
  return momentDate.format('MMM DD');
};

export const fullDate = (date) => {
  const today = moment();
  const momentDate = moment(date);

  if (momentDate.isSame(today, 'day')) {
    return 'today';
  }

  return momentDate.format('MMM DD YYYY');
};

export const formatDayWithTimezone = (date) => {
  return moment;
};

export const shiftDuration = (from, to) => {
  const timeFrom = moment(from);
  const timeTo = moment(to);

  return timeTo.diff(timeFrom, 'hours');
};

export const shiftDurationInMin = (from, to) => {
  const timeFrom = moment(from);
  const timeTo = moment(to);

  // Get total difference in minutes
  const totalMinutes = timeTo.diff(timeFrom, 'minutes');

  // Convert minutes to hours with decimals
  return totalMinutes / 60;
};

export const formatForWeekPicker = (from, to) => {
  const momentFrom = moment(from);
  const momentTo = moment(to);
  // if (momentFrom.format('MM') !== momentTo.format('MM')) {
  //   if (momentFrom.format('YYYY') !== momentTo.format('YYYY')) {
  //     return `${momentFrom.format('MMM DD, YYYY')} - ${momentTo.format(
  //       'MMM DD, YYYY'
  //     )}`;
  //   }
  //   return `${momentFrom.format('MMM DD')} - ${momentTo.format(
  //     'MMM DD, YYYY'
  //   )}`;
  // }
  return `${momentFrom.format('MMM DD, YYYY')} - ${momentTo.format('MMM DD, YYYY')}`;
};

const timeMessages = defineMessages({
  fewSecondsAgo: {
    id: 'timeMessages.fewSecondsAgo',
    defaultMessage: 'A few seconds ago',
  },
  secondsAgo: {
    id: 'timeMessages.secondsAgo',
    defaultMessage: '{time} seconds ago',
  },
  minutesAgo: {
    id: 'timeMessages.minutesAgo',
    defaultMessage:
      '{time, plural, one {{time} minute} other {{time} minutes}}',
  },
  hourAgo: {
    id: 'timeMessages.hourAgo',
    defaultMessage: '{time} hour ago',
  },
  todayAt: {
    id: 'timeMessages.todayAt',
    defaultMessage: 'Today at {time}',
  },
  yesterdayAt: {
    id: 'timeMessages.yesterdayAt',
    defaultMessage: 'Yesterday at {time}',
  },
  longTimeAgo: {
    id: 'timeMessages.longTimeAgo',
    defaultMessage: 'Yesterday at {time}',
  },
  weekTime: {
    id: 'timeMessages.weekTime',
    defaultMessage: '{week} at {time}',
  },
  at: {
    id: 'timeMessages.at',
    defaultMessage: 'at',
  },
});

export const formatManagementTimeForFeed = (date, formatMessage) => {
  const now = moment();
  const managedTime = moment(date);
  let text;
  /*
  const temp = now.diff(managedTime, 'days');
  const msg = `time diff = ${temp}`;
  console.log(msg);
  */

  if (now.diff(managedTime, 'seconds') < 30) {
    text = formatMessage(timeMessages.fewSecondsAgo);
  }

  if (
    now.diff(managedTime, 'seconds') > 29 &&
    now.diff(managedTime, 'seconds') < 60
  ) {
    text = formatMessage(timeMessages.secondsAgo, {
      time: now.diff(managedTime, 'seconds'),
    });
    // text = `${now.diff(managedTime, 'seconds')} ${formatMessage(timeMessages.secondsAgo)}`;
  }

  // if (
  //   now.diff(managedTime, 'minutes') >= 1 &&
  //   now.diff(managedTime, 'minutes') < 2
  // ) {
  //   text = `${now.diff(managedTime, 'minutes')} minute ago`;
  // }

  if (
    now.diff(managedTime, 'minutes') >= 1 &&
    now.diff(managedTime, 'minutes') < 60
  ) {
    text = formatMessage(timeMessages.minutesAgo, {
      time: now.diff(managedTime, 'minutes'),
    });
    // text = `${now.diff(managedTime, 'minutes')} minutes ago`;
  }

  if (
    now.diff(managedTime, 'hours') >= 1 &&
    now.diff(managedTime, 'hours') < 2
  ) {
    text = formatMessage(timeMessages.hourAgo, {
      time: now.diff(managedTime, 'hours'),
    });
    // text = `${now.diff(managedTime, 'hours')} hour ago`;
  }

  if (
    now.diff(managedTime, 'hours') >= 2 &&
    now.diff(managedTime, 'hours') <= 24
  ) {
    text = formatMessage(timeMessages.todayAt, {
      time: managedTime.format('LT'),
    });
    // text = `${managedTime.format('[Today at] LT')}`;
  }

  if(now.diff(managedTime, 'hours') > 24 && now.diff(managedTime, 'hours') < 36){
    text = formatMessage(timeMessages.yesterdayAt, {
      time: managedTime.format('LT'),
    });
  }

  if(now.diff(managedTime, 'hours') > 36 && now.diff(managedTime, 'hours') < 48){
    text = formatMessage(timeMessages.weekTime, {
      week: managedTime.format('dddd'),
      time: managedTime.format('LT'),
    });
  }
  /*
  if (now.diff(managedTime, 'days') >= 1 && now.diff(managedTime, 'days') < 2) {
    text = formatMessage(timeMessages.yesterdayAt, {
      time: managedTime.format('LT'),
    });
    // text = `${managedTime.format('[Yesterday at] LT')}`;
  }
  */
  if (now.diff(managedTime, 'days') >= 2 && now.diff(managedTime, 'days') < 7) {
    text = formatMessage(timeMessages.weekTime, {
      week: managedTime.format('dddd'),
      time: managedTime.format('LT'),
    });
    // text = `${managedTime.format('dddd [at] LT')}`;
  }

  if (now.diff(managedTime, 'days') >= 7) {
    text = `${managedTime.format('MM/DD/YYYY LT')}`;
  }

  return {
    text,
    needed: now.diff(managedTime, 'hours') < 3,
  };
};

export const formatManagementTimeForFeedNew = (date, formatMessage) => {

  const now = moment();
  const managedTime = moment(date);
  let text;
  
  const temp = now.diff(managedTime, 'hours');
  const msg = `time diff = ${temp}`;
  console.log(msg);

  if (now.diff(managedTime, 'hours') >= 0 && now.diff(managedTime, 'hours') <= 24) {
    const day = managedTime.format('DD');
    const currentDay = now.format('DD');
    if(day === currentDay) {
      const s = managedTime.format('h:mma');
      const timeText = s.slice(0, s.length-1);
      text = formatMessage(timeMessages.todayAt, {
        time: timeText,
      });
    }else {
      const weekText = managedTime.format('ddd');
      const t = managedTime.format('h:mma');
      const timeText = t.slice(0, t.length -1);
      text = formatMessage(timeMessages.weekTime, {
        week: weekText,
        time: timeText,
      });
    }
  }
  
  if (now.diff(managedTime, 'hours') > 24 && now.diff(managedTime, 'days') < 7) {
    const weekText = managedTime.format('ddd');
    const t = managedTime.format('h:mma');
    const timeText = t.slice(0, t.length -1);
    text = formatMessage(timeMessages.weekTime, {
      week: weekText,
      time: timeText,
    });
  }

  if (now.diff(managedTime, 'days') >= 7) {
    const month = managedTime.format('MMM');
    const s = managedTime.format('h:mma');
    const timeText = s.slice(0, s.length-1);
    text = `${month} ${formatMessage(timeMessages.at)} ${timeText}`;
  }

  if (now.diff(managedTime, 'months') > 12) {
    const date = managedTime.format('MM/DD/YY');
    const s = managedTime.format('h:mma');
    const timeText = s.slice(0, s.length-1);
    text = `${date} ${formatMessage(timeMessages.at)} ${timeText}`;
  }

  return {
    text,
    needed: now.diff(managedTime, 'hours') < 3,
  };
};

