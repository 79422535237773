import React, { useState, useEffect, useCallback } from 'react';
import presenter from 'pages/ScheduleCalendar/duck/presenter';
import formatter from 'pages/ScheduleCalendar/duck/formatter';
import { isValidObject } from 'helpers/validation';
import moment from 'moment';
import timeUtil from 'pages/ScheduleCalendar/duck/timeUtil';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

export function DetailFieldTimeComponent ({date,presentedShift, disableField, startTimeChanged, endTimeChanged, shouldDisplayOvernightShift, startTime, endTime}) {

  const timePickerSection = () => {

    var momentStart = null, momentEnd = null ;

    if (  isValidObject(presentedShift) == true ) {
        momentStart = moment(presentedShift.start_at).local();
        momentEnd = moment(presentedShift.end_at).local();
    }else {
        momentStart = timeUtil.defaultStart(date);
        momentEnd = timeUtil.defaultEnd(date);
    }
    return <div className="time-row flex-row">
              <TimePicker onChange={startTimeChanged} disabled={disableField} inputReadOnly={true} allowEmpty={false} defaultValue={momentStart} use12Hours={true} showSecond={false} minuteStep={15} hideDisabledOptions={true} />
              <span className="picker-divider"></span>
              <TimePicker onChange={endTimeChanged} disabled={disableField} inputReadOnly={true} allowEmpty={false} defaultValue={momentEnd} use12Hours={true} showSecond={false} minuteStep={15} hideDisabledOptions={true} />
            </div>;
  }

    return (
        <div>
          <div className='flex-row time-title'>
            <div className="time-label">Time:</div>
            <label className='fs12 ff-regular'>{timeUtil.calculateDuration(date,startTime, endTime)} hours</label>
          </div>
            {timePickerSection()}

            {shouldDisplayOvernightShift && 
            <div className=''> <img className='conflict-sign' src='/icons/conflict.svg'/> 
              <label className='overnight-shift-warning fs12px'>{presenter.overnightShiftText()}</label> 
            </div> }
          </div>
    )
}

export default DetailFieldTimeComponent;