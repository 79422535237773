import React from 'react';
import { FormattedMessage, injectIntl, } from 'react-intl';
import utils from 'pages/ScheduleCalendar/duck/utils';
import WeekPickerComponent from 'components/CalendarComponents/WeekPickerComponent/WeekPickerComponent';
import AvailabilityReminderComponent from './AvailabilityReminderComponent';
import styledConfirm from '../Dashboard/components/styled';
import Modal from 'components/GeneralComponents/Modal';
import { Mixpanel } from '../../Mixpanel';
import { startOfLocationFiscalWeek } from '../../helpers/date-time';
import moment from 'moment';


class AvailabilityHeaderComponent extends React.Component {
    state = {
      showNotifyTeamModal: false,
    };
  
    constructor(props) {
      super(props);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }


    handleNotifyTeamClick = () => {
        this.setState({ showNotifyTeamModal: true});
    }
  
    closeNotifyTeamModal = () => {
        this.setState({ showNotifyTeamModal: false});
    }

    moveCurrentWeekBackward = () => {
        const { changeDateRangeSlice, rangeStart, rangeEnd} = this.props;
    
        const newDateRangeStart = utils.previousWeek(rangeStart);
        const newDateRangeEnd= utils.previousWeek(rangeEnd);
    
        changeDateRangeSlice(newDateRangeStart, newDateRangeEnd);
        Mixpanel.track('Avail Previous week', {'target_week': newDateRangeStart.format("MMM Do YYYY") + ' - ' + newDateRangeEnd.format("MMM Do YYYY")});
    };
    
      moveCurrentWeekForward = () => {
        const { changeDateRangeSlice, rangeStart, rangeEnd } = this.props;
    
        const newDateRangeStart = utils.nextWeek(rangeStart);
        const newDateRangeEnd= utils.nextWeek(rangeEnd);
    
        changeDateRangeSlice(newDateRangeStart, newDateRangeEnd);
        Mixpanel.track('Avail Next week', {'target_week': newDateRangeStart.format("MMM Do YYYY") + ' - ' + newDateRangeEnd.format("MMM Do YYYY")});
    };

    moveToToday = () => {
        const {locationData} = this.props;

        var start = startOfLocationFiscalWeek(locationData.fiscal_day);
        this.moveCurrentWeekTo(start);

        Mixpanel.track('Avail go to today');
    }

    moveCurrentWeekTo = (sDate) => {
        const { changeDateRangeSlice } = this.props;

        const newDateRangeStart = moment(sDate);
        const newDateRangeEnd = newDateRangeStart.clone().add(7, 'day').subtract(1, 'second');

        changeDateRangeSlice(newDateRangeStart, newDateRangeEnd);
    }

    render() {
        const { rangeStart, rangeEnd, locationId, notifyTeamDispatch } = this.props;
        const { showNotifyTeamModal } = this.state;
        const remindTeamText = <FormattedMessage id="Availability.remindTeam" defaultMessage='Send Availability Update Reminder'/>;

        var message = null;
        if( rangeStart && rangeEnd && locationId ){
          message = 'If you have not updated your availability yet, please do it as soon as possible!';
        }

        return (
            <div className='header-row-wrap'>
                {rangeStart && <WeekPickerComponent
                    back={this.moveCurrentWeekBackward}
                    forward={this.moveCurrentWeekForward} 
                    currentDateRangeStart={rangeStart}
                    currentDateRangeEnd={rangeEnd}
                    goToday={this.moveToToday}
                /> }

                {rangeStart && <div className='header-right-group'>
                    <button className='header-button' onClick={this.handleNotifyTeamClick}> {remindTeamText} </button>
                </div>} 

                {showNotifyTeamModal === true && 
                <Modal>
                    <AvailabilityReminderComponent
                        cancelModal = {this.closeNotifyTeamModal} 
                        notifyTeamDispatch = {notifyTeamDispatch}
                        locationId = {locationId}
                        message = {message}
                    />
                </Modal>}
                <styledConfirm.Overlay display={ showNotifyTeamModal === true ? 'block' : 'none'} />
            </div>
        );
    }

}

export default AvailabilityHeaderComponent;