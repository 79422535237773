import React from 'react';
import './ShiftDetailComponent.scss';
import 'App/fonts.scss';
import 'App/layout.scss';


import { Mixpanel } from '../../../../../Mixpanel';
import formatter from '../../../duck/formatter';
import presenter from 'pages/ScheduleCalendar/duck/presenter';
import { injectIntl } from 'react-intl';
import draftLogic from 'pages/ScheduleCalendar/duck/draftLogic';
import timeUtil from 'pages/ScheduleCalendar/duck/timeUtil';
import { getUserId } from 'helpers/cookies';
import { isValidObject } from 'helpers/validation';

import DetailFieldAssignToComponent from './DetailFieldAssignToComponent';
import DetailFieldTimeComponent from './DetailFieldTimeComponent';
import DetailFieldPostToGroupComponent from './DetailFieldPostToGroupComponent';
import DetailFieldPositionComponent from './DetailFieldPositionComponent';
import DetailFieldQuantityComponent from './DetailFieldQuantityComponent';
import DetailFieldPillTagsComponent from './DetailFieldPillTagsComponent';
import DetailFieldSelectWeekdayComponent from './DetailFieldSelectWeekdayComponent';
import DetailFieldDescriptionComponent from './DetailFieldDescriptionComponent';

import displayLogic from 'pages/ScheduleCalendar/duck/displayLogic';
import service from 'pages/ScheduleCalendar/duck/service';
import constants from 'pages/ScheduleCalendar/constants';

class ShiftFieldsComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shiftStartTime: timeUtil.defaultStart(props.date).format(formatter.hourTimeZoneFormat()),
      shiftEndTime: timeUtil.defaultEnd(props.date).format(formatter.hourTimeZoneFormat()),
      jobTitle: '',
      shouldDisplayOvernightShift: false,
      shouldDisplayJobTitleLengthWarning: false,
      selectedDays: [this.getSelectedWeekday()],

      assignToTarget: presenter.openShiftSelectionTarget(),
      postToGroupTarget: null,
      bulkQuantity: 1,
      descriptionOfShift: '', 
    };
  }

  componentDidMount() {
    const { date, presentedShift, userToAddShiftTo } = this.props;

    if( isValidObject(presentedShift) == true ){
      this.populateFieldsFromShift()
    }else {
      // Add inventory after cliking on a user cell
      if( isValidObject(userToAddShiftTo) == true && isValidObject(date) == true ){
         this.setState({assignToTarget : userToAddShiftTo}) ;
      }
    }
  }

  populateFieldsFromShift = () => {
    const { shiftStartTime, shiftEndTime, jobTitle } = this.state;
    const { date, presentedShift, allUsers, unregisteredUsers, channels } = this.props;

    if( jobTitle == '' ){
      const shiftJobTitle = presentedShift?.primary_job;
      this.setState({ jobTitle: shiftJobTitle});
    }

    if( presentedShift?.total_shifts_available> 0 ){ this.setState({ bulkQuantity: presentedShift?.total_shifts_available }); }

    if (timeUtil.isOvernightShift(shiftStartTime, shiftEndTime, date)) { this.setState({ shouldDisplayOvernightShift: true }); }

    const subtype = service.getShiftSubType(presentedShift)
    if( subtype !== constants.ShiftSubType.ParentOpenShift && subtype !== constants.ShiftSubType.DraftOpenShift ){
      const target = presenter.userShiftIsAssignedTo(presentedShift, allUsers, unregisteredUsers)
      console.log(target)
      this.setState({ assignToTarget:target }) 
    }

    const groupToPostTo = presenter.getGroup(presentedShift, channels);

    this.setState({shiftStartTime: formatter.pickerHourTimeZoneFormat(presentedShift.start_at),
      shiftEndTime: formatter.pickerHourTimeZoneFormat(presentedShift.end_at),
      descriptionOfShift: presentedShift.content,
      postToGroupTarget: groupToPostTo
     })
  }

  componentDidUpdate(prevProps, prevState) {
    const { date, } = this.props;
    const { shiftStartTime, shiftEndTime } = this.state;

    // If the shift start or end time changes, check if it is an overnight shift
    if (prevState.shiftStartTime !== shiftStartTime || prevState.shiftEndTime !== shiftEndTime) {
      const result = timeUtil.isOvernightShift(shiftStartTime, shiftEndTime, date);
        this.setState({ shouldDisplayOvernightShift: result });
    }
  }

  getSelectedWeekday = () => {
    const { date } = this.props;
    const weekdayIndex = date.isoWeekday() - 1; // Monday is 0, Sunday is 6
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][weekdayIndex];
  };

  startTimeChanged = (time) => {
    if (time) {
      const val = time.format(formatter.hourTimeZoneFormat());
      this.setState({ shiftStartTime: val });
    }
  };

  endTimeChanged = (time) => {
    if (time) {
      const val = time.format(formatter.hourTimeZoneFormat());
      this.setState({ shiftEndTime: val });
    }
  };

  handleJobTitleChange = (event) => {
    const val = event.target.value;
    if (val && val.length > 35) {
      this.setState({
        shouldDisplayJobTitleLengthWarning: true,
      });
    } else {
      this.setState({
        jobTitle: val,
        shouldDisplayJobTitleLengthWarning: false,
      });
    }
    this.validateInputs();
  };

  handleSelectedDaysChange = (selectedDays) => { this.setState({ selectedDays }); };

  handleConfirm = () => {
    const { userToAddShiftTo, date, addDraftShiftToUserDispatch, locationData, cancelModal, addDraftOpenShiftToUserDispatch } = this.props;
    const { assignToTarget, shiftStartTime, shiftEndTime, jobTitle, selectedDays, postToGroupTarget, bulkQuantity, descriptionOfShift } = this.state;

    if ( this.validateInputs() == false ){ return;}

    if( assignToTarget?.assignToOpenShift == true ){
      console.log(bulkQuantity + ' , ' + postToGroupTarget.id + ' ' + descriptionOfShift);
      addDraftOpenShiftToUserDispatch(getUserId(), shiftStartTime, shiftEndTime, jobTitle, locationData.id, selectedDays, date, locationData.fiscal_day, postToGroupTarget.id, bulkQuantity, descriptionOfShift); 
    }else{
      addDraftShiftToUserDispatch(assignToTarget, shiftStartTime, shiftEndTime, jobTitle, locationData.id, selectedDays, date, locationData.fiscal_day);
      Mixpanel.track('Confirm add shift', { start_time: shiftStartTime, end_time: shiftEndTime, job_title: jobTitle });
    }

    cancelModal();
  };

  handleDeleteClick = () => {
    const { prepareToDeleteShift, presentedShift, cancelModal} = this.props;
    prepareToDeleteShift(presentedShift.id, presentedShift.location_id);
    cancelModal();
    Mixpanel.track('Delete shift from detail');
  };

  handleStartCopyClick = () => {
    const { prepareToCopyShift, cancelModal, presentedShift} = this.props;

    if( isValidObject(presentedShift) == true ){
      prepareToCopyShift(presentedShift);
      cancelModal();
      Mixpanel.track('Start Copy Shift');
   }
  }
  handleCancel = () => {
    const { cancelModal } = this.props;
    cancelModal();
    Mixpanel.track('Cancel shift detail');
  }

  handleUpdateClick = () => {
    const {updateDraftOpenShiftDispatch, updateDraftShiftDispatch, updatePublishedOpenShiftDispatch, date, presentedShift, cancelModal } = this.props;
    const { shiftStartTime, shiftEndTime, jobTitle, descriptionOfShift, bulkQuantity, postToGroupTarget } = this.state;
    const subtype = service.getShiftSubType(presentedShift); 
    if( subtype == constants.ShiftSubType.ParentOpenShift ){
      updatePublishedOpenShiftDispatch(presentedShift, bulkQuantity, descriptionOfShift, jobTitle);
    }else if ( subtype == constants.ShiftSubType.DraftOpenShift ){
      updateDraftOpenShiftDispatch(presentedShift.id, presentedShift.owner_id, shiftStartTime, shiftEndTime, jobTitle, presentedShift.location_id,date, postToGroupTarget.id, bulkQuantity, descriptionOfShift)
    }else{
      updateDraftShiftDispatch(presentedShift, shiftStartTime, shiftEndTime, jobTitle, date);
    }
    Mixpanel.track('Update Shift');
    cancelModal();
  }

  selectAssignTarget = (target)  => {
    this.setState( {assignToTarget: target} );
    this.validateInputs();
  }

  selectGroup = (target) => {
    this.setState( {postToGroupTarget: target} );
    this.validateInputs();
  }

  handleQuantityChange = (event) => { this.setState({ bulkQuantity: event.target.value});  this.validateInputs();}
  handleDescriptionChange = (event) => { this.setState({ descriptionOfShift: event.target.value}); this.validateInputs(); }

  validateInputs = () => {
    const {assignToTarget, postToGroupTarget, descriptionOfShift, jobTitle, bulkQuantity} = this.state;
    if( assignToTarget.assignToOpenShift == true ){
      if ( isValidObject(postToGroupTarget) == true &&
          isValidObject(jobTitle) == true && 
          bulkQuantity > 0) {
        return true;
      }else{
        return false;
      }
    }else{
      return true
    }
  };

  render() {
    const {allUsers, channels, date, locationData, presentedShift, applicants, showOpenShiftOption } = this.props;
    const { shiftStartTime, shiftEndTime, bulkQuantity, postToGroupTarget, shouldDisplayOvernightShift, shouldDisplayJobTitleLengthWarning, selectedDays, assignToTarget } = this.state;

    const isViewingShift = (isValidObject(presentedShift) == true);
    var allowShiftDelete = false;

    const selectedGroupName = postToGroupTarget?.channel_name;
    var groupDisplayText = (selectedGroupName !== null && selectedGroupName?.length > 0) ? selectedGroupName: presenter.groupPlaceholderText(); 

    if( isViewingShift ){
      allowShiftDelete = (presentedShift.allow_delete === true)
      groupDisplayText = presenter.getGroupName(presentedShift, channels);
    }else {
    }

    const isDraftShift = draftLogic.isDraftShift(presentedShift);
    const disableEditingForPublishedShift = (isViewingShift == true && isDraftShift == false);
    const areInputsValidated = this.validateInputs();
    const subtype = service.getShiftSubType(presentedShift); 

    const shouldShowSaveButton = displayLogic.shouldShowSaveButton(subtype)

    return (
        <div className='shift-fields-container'>
    
          {displayLogic.shouldShowPillTags(presentedShift) == true && 
            <DetailFieldPillTagsComponent shift={presentedShift} applicants={applicants}/> }
         
          <div className='flex-row top-row'>
            <DetailFieldAssignToComponent 
                disableField = {displayLogic.disableAssignToField(subtype)}
                allUsers={allUsers} 
                selectAssignTarget={this.selectAssignTarget} 
                assignToTarget={assignToTarget}
                showOpenShiftOption = {showOpenShiftOption} /> 

            {displayLogic.shouldShowQuantityField(assignToTarget, subtype) == true &&
              <DetailFieldQuantityComponent 
                presentedShift={presentedShift}
                bulkQuantity ={bulkQuantity}
                handleQuantityChange={this.handleQuantityChange}/> }
          </div>

          <DetailFieldTimeComponent 
              disableField={disableEditingForPublishedShift}  
              shouldDisplayOvernightShift={shouldDisplayOvernightShift} 
              date={date} presentedShift={presentedShift} 
              startTimeChanged={this.startTimeChanged} endTimeChanged={this.endTimeChanged}
              startTime={shiftStartTime} endTime={shiftEndTime} />

          <DetailFieldPositionComponent 
              defaultValue={presentedShift?.primary_job} 
              disableField={displayLogic.disablePositionField(subtype)} 
              handleJobTitleChange={this.handleJobTitleChange}
              shouldDisplayLengthWarning={shouldDisplayJobTitleLengthWarning} />

          {displayLogic.shouldShowPostToGroupField(assignToTarget, subtype) == true && 
            <DetailFieldPostToGroupComponent 
               disableField={displayLogic.disablePostToGroupField(subtype)} 
               channels={channels} 
               selectGroup={this.selectGroup} 
               groupDisplayText={groupDisplayText}/>}
        
          {displayLogic.shouldShowDescriptionField(assignToTarget, subtype) == true &&
            <DetailFieldDescriptionComponent 
              defaultValue = {presentedShift?.content}
              disableField={displayLogic.disableDescriptionField(subtype)}
              handleDescriptionChange={this.handleDescriptionChange}
              author= {presenter.getPoster(presentedShift, allUsers)} /> }

          {displayLogic.shouldShowSelectWeekdayField(subtype) == true &&
            <DetailFieldSelectWeekdayComponent 
              disableManualSelect={isViewingShift} 
              locationData={locationData} 
              selectedDay={this.getSelectedWeekday()} 
              selectedDays={selectedDays} 
              onChange={this.handleSelectedDaysChange} /> }

          <div className="button-row">
            <div className='left-group'>          
              {allowShiftDelete && <button className='delete-button' onClick={this.handleDeleteClick}>{presenter.deleteText()}</button>}
              {isViewingShift == true && <button className='copy-button' onClick={this.handleStartCopyClick}>{presenter.copyText()}</button>}
            </div>

            <div className='right-group'>
              { shouldShowSaveButton == true && <button className='cancel-button' onClick={this.handleCancel}> {presenter.cancelText()}</button> } 
              { shouldShowSaveButton == true && <button className='save-button' onClick={this.handleUpdateClick}> {presenter.saveText()}</button> }
              {isViewingShift == false && <button className={ 'narrow-height confirm-button ' + (areInputsValidated==true ? 'button-enabled' : 'button-disabled')} onClick={this.handleConfirm} > {presenter.addShiftString()} </button>}
            </div>
          </div>
        </div>
    );
  }
}

export default injectIntl(ShiftFieldsComponent);
