import moment from 'moment';
import axios from 'axios';


import { GetLocationSchedules, GetLocationActionJobs, DeleteActionJob, } from 'helpers/api-calls/schedule-calls';
import { createApiDateFormat } from 'helpers/date-time';

import actions from './actions';
import { appActions } from 'App/duck';

const fetchLocationSchedules = (location_id) => (dispatch) => {
  dispatch(actions.requestSchedules(true));
  return GetLocationSchedules(location_id).then(({ data }) => {
    dispatch(actions.hydrateSchedules(data.schedules));
  }).catch((error) => {
    const msg = `fetchLocationSchedules failed. error: ${error}`
    console.log(msg);
  });
};

const fetchLocationActionJobs = (location_id) => (dispatch) => {
  dispatch(appActions.requestActionJobs(true));
  return GetLocationActionJobs(location_id).then(({ data }) => {
    dispatch(appActions.hydrateActionJobs(data.action_jobs));
  }).catch((error) => {
    const msg = `fetchLocationActionJobs failed. error: ${error}`
    console.log(msg);
  });
};

const deleteActionJob = (action_job_uuid, schedule_id, location_id, handleScheduleDeleted) => (dispatch) => {
  return DeleteActionJob(action_job_uuid).then(({ data }) => {
    dispatch(fetchLocationSchedules(location_id));
    dispatch(fetchLocationActionJobs(location_id));
    handleScheduleDeleted(schedule_id );
  }).catch((error) => {
    const msg = `deleteActionJob failed. error: ${error}`
    console.log(msg);
  });
};


export default {
  fetchLocationSchedules,
  fetchLocationActionJobs,
  deleteActionJob,
};
