import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import styled from 'styled-components/macro';
import { injectIntl, defineMessages } from 'react-intl';
import PubNub from 'pubnub';
import moment from 'moment';
import { LoadingSpinner } from 'components/GeneralComponents/LoadingSpinner';

import {
  getLocationCode,
  checkForAuthentication,
} from 'helpers/cookies';
import { ROLES, FindRole } from 'helpers/user-roles';
import { getUserId } from 'helpers/cookies';
import SetupContainer from 'containers/SetupContainer';
import LocationDropdown from 'containers/LocationDropdown';
import { USERS_PER_PAGE, } from '../../pages/UserManagement/constants';
import { PUBNUB_SUBSCRIBE_KEY } from '../../constants';

const LocationDropdownWrap = styled.div`
  display: none;
  width: 100%;
  position: relative;

  .select-dropdown {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 680px) {
    display: none;
  }
`;

const LoadingWrap = styled.div`
  width: 8rem;
  margin: 8rem auto;
`;

const Loader = styled(LoadingSpinner)``;

const sha1 = require('js-sha1');

class RoleComponent extends React.Component {
  constructor(props) {
    super(props);

    this.allUserPageIndex = 1;

    this.idleTimerRef = React.createRef();

    this.messages = defineMessages({
      idleAlert: {
        id: 'RoleComponent.idleAlert',
        defaultMessage:
          "You've been away for awhile! It's time to log back in and see what your coworkers are up to.",
      },
    });
    this.sha1Presence = '';
    //this.userIdHash = `user.${sha1(`user.${getUserId()}`)}`;
    //this.pubNubClient = new PubNub({ subscribeKey: PUBNUB_SUBSCRIBE_KEY, userId: this.userIdHash, restore: true, keepAlive: true });
  }

  componentDidMount() {
    const { loadRequiredApis, 
      pendingUsersLoaded,
      fetchAllUsers,
      resetManagers,
      resetAssociates,
      resetRemovedUsers,
      resetPendingUsers,
      resetAllUsers,
      fetchPlans,
      } = this.props;

    const location_id = getLocationCode();

    if (location_id) {
      loadRequiredApis(location_id, pendingUsersLoaded);
      const id = parseInt(location_id);
      const idHash = sha1(`channel.${id}`);
      this.sha1Presence = `channel_presence.${idHash}`;
      //this.pubnubSubscribeToPresence();
      resetManagers();
      resetAssociates();
      resetRemovedUsers();
      resetPendingUsers();
      resetAllUsers();
      this.allUserPageIndex = 1;
      fetchAllUsers(location_id, this.allUserPageIndex, USERS_PER_PAGE);
      this.allUserPageIndex += 1;
      fetchPlans(location_id);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      setupActionsLoading: prevSetupActionsLoading,
      locationId: prevLocationId,
    } = prevProps;
    const {
      setupActionsLoading,
      fetchLocation,
      setupActions,
      locationId,
      loadRequiredApis,
      fetchChannels,
      fetchAllUsers,
      allUsers,
      resetManagers,
      resetAssociates,
      resetRemovedUsers,
      resetPendingUsers,
      resetAllUsers,
      fetchPlans,
    } = this.props;

    if (locationId && prevLocationId !== locationId) {
      loadRequiredApis(locationId);
      const id = parseInt(locationId);
      const idHash = sha1(`channel.${id}`);
      this.sha1Presence = `channel_presence.${idHash}`;
      //this.pubnubSubscribeToPresence();
    

      //this is for location changes. It has been done in compomentDidMount
      if(prevLocationId !== undefined) {
        resetManagers();
        resetAssociates();
        resetRemovedUsers();
        resetPendingUsers();
        resetAllUsers();
        this.allUserPageIndex = 1;
        fetchAllUsers(locationId, this.allUserPageIndex, USERS_PER_PAGE);
        this.allUserPageIndex += 1;
        fetchPlans(locationId);
        //if language changes, make sure filter=pending removed.
        if(window.location.href.indexOf('filter=pending') > -1) {
          const backToPath = "/usermanagement";
          window.history.replaceState(null, '', backToPath);
        }
      }
    }

    if (
      locationId && prevSetupActionsLoading !== setupActionsLoading &&
      !setupActionsLoading
    ) {
      if(setupActions && setupActions.meta && setupActions.meta.current_user_privilege) {
        fetchLocation(setupActions.meta.current_user_privilege.id);
      }
      fetchChannels(locationId);
      //console.log(`88-Location: ${locationId}, fetchChannels called`);
    }

    if(prevProps.allUsers !== allUsers) {
      if(prevProps.allUsers) {
        if(allUsers.length - prevProps.allUsers.length === USERS_PER_PAGE) {
          fetchAllUsers(locationId, this.allUserPageIndex, USERS_PER_PAGE);
          this.allUserPageIndex += 1;
        }
      }
    }
  }

  _onIdleHandler = () => {
    // Removed 15 minute timeout
    // -    alert(intl.formatMessage(this.messages.idleAlert));
    // -    purgeAuthenticationCookie();
    // -    window.location.pathname = '/login';
  };

  pubnubSubscribeToPresence = () => {
    const { handleShiftUpdate } = this.props;

    this.pubNubClient.addListener({
      presence: function(event) {
        var action = event.action;
        var channelName = event.channel;
        var occupancy = event.occupancy;
        var eventTimetoken = event.timetoken;
        var occupantUserId = event.uuid;
        var state = event.state;
        var subscribeManager = event.subscription;

        if(event) {
          console.log('got event');
        }
      }
    });
    
    this.pubNubClient.subscribe({
      channels: [this.sha1Presence],
      withPresence: true
    });

    if(this.sha1Presence.length > 0) {
      try {
        const start = moment();
        console.log('calling hereNow');
        this.pubNubClient.hereNow({
          channels: [this.sha1Presence],
          includeUUIDs: true,
          includeState: true,
        }, (status, response) => {
          const end = moment();
          const diff = moment.duration(end.diff(start))
          const ms = parseInt(diff.asMilliseconds());
          console.log(`response comes in ${ms} ms.`);
          if(response) {
            if(response.channels[this.sha1Presence].occupants.length > 0) {
              for(let i=0; i<response.channels[this.sha1Presence].occupants.length; i++) {
                console.log(`User ${i} : uuid: ${response.channels[this.sha1Presence].occupants[i].uuid}`);
                const receivedUuid = response.channels[this.sha1Presence].occupants[i].uuid;
                const myId = getUserId();
                const myUuid = `user.${sha1(`user.${myId}`)}`;
                if(myUuid === receivedUuid) {
                  console.log('right');
                }
              }
              getLocationOnlineUsers(response.channels[this.sha1Presence].occupants);
            }
          }
        });
      } catch(error) {
        console.log(error);
      };
    }
  };

  render() {
    const {
      setupActions,
      routeProps,
      setupActionsLoading,
      locationSettingsLoading,
    } = this.props;
    const SetupContainerWithIdle = (
      <IdleTimer
        ref={this.idleTimerRef}
        onIdle={this._onIdleHandler}
        timeout={1000 * 60 * 60 * 24}
      >
        <LocationDropdownWrap>
          <LocationDropdown />
        </LocationDropdownWrap>
        <SetupContainer routeProps={routeProps} />
      </IdleTimer>
    );

    if (!checkForAuthentication()) {
      // User is logged out
      return <Redirect to="/login" />;
    }

    if (!setupActionsLoading && !locationSettingsLoading) {
      // Data from setup actions api call available
      const role = FindRole(setupActions);

      switch (role) {
        case ROLES.LOGGED_OUT.key:
          return <Redirect to="/login" />;

        case ROLES.USER.key:
          return SetupContainerWithIdle;

        case ROLES.ADMIN.key:
          return SetupContainerWithIdle;

        default:
          return null;
      }
    }

    return (
      <LoadingWrap>
        <Loader color="blue" size="large" />
      </LoadingWrap>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });

RoleComponent.propTypes = {
  pendingUsersLoaded: PropTypes.bool.isRequired,
  locationSettingsLoading: PropTypes.bool.isRequired,
  loadRequiredApis: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchChannels: PropTypes.func.isRequired,
  //locationId: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  setupActions: PropTypes.shape({}).isRequired,
  setupActionsLoading: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(RoleComponent);
