import React from 'react';
import { connect } from 'react-redux';

import GroupMembersComponent from './GroupMembersComponent';

import {
  teamFeedActions,
  teamFeedOperations,
  teamFeedSelectors,
} from '../../duck';

/*
const mapStateToProps = ({ teamFeedReducer, appReducer, }) => ({
  	receipts: teamFeedReducer.readReceipts,
  	activeSidePane: teamFeedSelectors.getActiveSidePane(teamFeedReducer),
    activePost: teamFeedSelectors.getActivePost(teamFeedReducer),
    allUsers: appReducer.allUsers.users,
    onlineUsers: appReducer.onlineUsers.users,
    activeChannel: teamFeedSelectors.getActiveChannel(state),
});
*/
const mapStateToProps = (state) => {
  const {
    teamFeedReducer,
    setupReducer,
    appReducer,
    locationReducer,
  } = state;

  return {
    activeSidePane: teamFeedSelectors.getActiveSidePane(teamFeedReducer),
    activePost: teamFeedSelectors.getActivePost(teamFeedReducer),
    activeChannel: teamFeedSelectors.getActiveChannel(state),
    locationId: locationReducer.location_code,
    locations: locationReducer.locations,
    allUsers: appReducer.allUsers.users,
    onlineUsers: appReducer.onlineUsers.users,
    receipts: teamFeedReducer.readReceipts,
  };
};

const mapDispatchToProps = (dispatch) => {
	const resetActiveSidePane = () => {
  		dispatch(teamFeedActions.updateActiveSidePane(''));
  	};
  	
  	return {
  		resetActiveSidePane,
  	}
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupMembersComponent);
