import { protectedAxiosInstance, axiosInstance, zeroResponseAxiosInstance, zeroParamProtectedAxiosInstance} from 'helpers/api-calls';
import { BASE_URL, BASE_SUBDOMAIN_URL } from '../../constants';
import { createHeadersForApiCall, getUserId, getSessionUukey, createHeadersForPDFUploading, createHeadersForUploadImage } from '../cookies';
import { StatusCheck } from '../authentication';
import { getLocationCode, getLocaleCookie } from '../cookies';
import { getEffectiveTypeParameterDeclarations } from 'typescript';

/**
 * Upload scheduler file
 * @param { file } 
 *
 * @return { Object } uploading status
 *                   
 */
export function UploadSchedulerFile(file, location_id) {
  var formData = new FormData();
  formData.append('file', file);

  return fetch(`https://${BASE_URL}/api/integration/shifts/bulk_upsync?location_id=${location_id}&api_default_record_action=skip_or_create`, {
    headers: createHeadersForPDFUploading(),
    method: 'POST',
    body: formData,
  });

};

/**
 * Upload scheduler file
 * @param { file } 
 *
 * @return { Object } uploading status
 *                   
 */
export function UploadSchedulerFile1(data, location_id, file_name) {
  //var formData = new FormData();
  //formData.append('file', file);

  return fetch(`https://${BASE_URL}/api/integration/shifts/bulk_upsync?location_id=${location_id}&input_sheet_original_filename=${file_name}`, {
    headers: createHeadersForApiCall(),
    method: 'POST',
    body: data,
  });

};

/**
 * Gets schedules on current location.
 * 
 * 
 * @param  { String | Int } {location_id}
 */
export const GetLocationSchedules = (location_id) => {
  const locale = getLocaleCookie();
  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/schedules?location_id=${location_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      page: 1,
      per_page: 500,
      total: true,
    },
  });
};

/**
 * Gets schedules on current location.
 * 
 * 
 * @param  { String | Int } {location_id}
 */
export const GetLocationActionJobs = (location_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/action_jobs?location_id=${location_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      page: 1,
      per_page: 500,
      total: true,
    },
  });
};

/**
 * Gets schedules on current location.
 * 
 * 
 * @param  { String | Int } {location_id}
 */
export const GetActionJob = (location_id, uuid) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/action_jobs?uuid=${uuid}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      uuid,
      page: 1,
      per_page: 500,
      total: true,
    },
  });
};

/**
 * Gets shifts in a schedule.
 * 
 * 
 * @param  { String | Int } {location_id}
 */
export const GetShiftsInSchedule = (location_id, schedule_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/schedule_elements/can_admin?location_id=${location_id}&schedule_id=${schedule_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      schedule_id,
      page: 1,
      per_page: 1000,
      total: true,
    },
  });
};

/**
 * Delete an action job.
 * 
 * 
 * @param  { String | Int } {jobId}
 */
export const DeleteActionJob = (action_job_uuid) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    // url: `/api/customer/user_role/schedules/${schedule_id}`,
    url: `/api/customer/user_role/action_jobs/${action_job_uuid}?delete_schedule=true`,
    method: 'DELETE',
    headers: {
      Locale: locale,
    }
  });
};

export const LinkUnregisteredUserSchedule = (unregistered_user_id, target_user_id) => {

  const payload = {
    "unregistered_user": {
      "setup_id": target_user_id,
    }
  };

  return fetch(`https://${BASE_URL}/api/customer/user_role/unregistered_users/${unregistered_user_id}`, {
    method: 'PUT',
    headers: createHeadersForApiCall(),
    body: JSON.stringify(payload)
  });
};

export const AddShiftToUserCall = (userId, startDateTime, endDateTime, jobTitle, locationId) => {
  const payload = {
    "schedule_element": {
      "start_at": startDateTime,
      "end_at": endDateTime,
      "cached_primary_job": jobTitle,
      "name": "shift_inventory",
      "owner_id": userId,
    }
  };

  return protectedAxiosInstance.request({
    url: `api/customer/user_role/locations/${locationId}/schedule_elements`,
    method: 'POST',
    data: payload
  });
};


export const DeleteShiftCall = (scheduleId, locationId) => {
  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/locations/${locationId}/schedule_elements/${scheduleId}`,
    method: 'DELETE'
  });
};

export const AddShiftToUnregisteredUserCall = (unregUserId, startDateTime, endDateTime, jobTitle, locationId) => {
  const payload = {
    "schedule_element": {
      "start_at": startDateTime,
      "end_at": endDateTime,
      "cached_primary_job": jobTitle,
      "name": "shift_inventory",
      "trade_status": "unregistered_user_shift_inventory",
      "unregistered_user_id": unregUserId,
    }
  };

  return protectedAxiosInstance.request({
    url: `api/customer/user_role/locations/${locationId}/schedule_elements`,
    method: 'POST',
    data: payload
  });
};

export const BroadcastNotificationCall= (locationId, message, event, userIds) => {

  var payload = { "message": message, "p_action" : event, "n_event": event};

  if( typeof userIds != 'undefined' && userIds != null && userIds.length > 0 ){
    if (Array.isArray(userIds) && userIds.length > 0) {
      payload.user_ids = userIds;
    }
  }

  return protectedAxiosInstance.request({
    url: `api/customer/user_role/locations/${locationId}/broadcast_notification`,
    method: 'PUT',
    data: payload
  });
};

export const CopyToWeekCall = (locationId, scrWeekDay, destWeekDay, ownerIds = null) => {
  const payload = {
    "dest_week_day": destWeekDay,
    "src_week_day": scrWeekDay,
    "owner_ids": ownerIds
  }

  return protectedAxiosInstance.request({
    url: `api/customer/user_role/locations/${locationId}/copy_week_planned_shifts?to_draft=true`,
    method: 'PUT',
    data: payload
  });
};


export default {
};

