import React from 'react';
import PropTypes from 'prop-types';

import TableRow from './TableRow/TableRow';
import AbsentDataComponent from '../AbsentData/AbsentDataComponent';
import { FormattedMessage } from 'react-intl';
import { getUserInfoNonAdmin } from 'helpers/api-calls/user-calls';
import './TableComponent.scss';
import UserTable from 'pages/RequestsV3/components/UserTable/UserTable';

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

class TableComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: props.headers ? props.headers : [],
      rows: [],
      totalRows: [],
      locationId: 0,
    };
  }

  componentDidMount() {
    const { locationSettings, skills, rows, headers, stateFilters, updateRowData, deletedGroupId, } = this.props;
    const output = this.getTotalRows(rows);
    this.setState({ totalRows: output });
    this.setState({ rows: this.createRowData(output, headers) });
    updateRowData(output);
    if (rows && rows.length > 0) {
      this.setState({ locationId: rows[0].location_id });
      const msg = `TableComponent.componentDidMount: shiftId = ${rows[0].id}, location_id = ${rows[0].location_id}`;
      console.log(msg);
    }
  }

  componentDidUpdate(prevProps) {
    const { locationSettings, headers, rows, skills, stateFilters, updateRowData, deletedGroupId, } = this.props;
    const { totalRows, } = this.state;

    if (prevProps.headers !== headers) {
      this.setState({ headers: headers });
    }
    if (prevProps.rows !== rows) {
      if (rows && rows.length > 0) {
        this.setState({ locationId: rows[0].location_id });
      }
      if (headers) {
        //this.setState({rows: this.createRowData(rows, headers),})
        const output = this.getTotalRows(rows);
        this.setState({ totalRows: output });
        this.setState({ rows: this.createRowData(output, headers) });
        updateRowData(output);
      }
    }
    if (prevProps.stateFilters !== stateFilters) {
      const output = this.getTotalRows(rows);
      this.setState({ totalRows: output });
      this.setState({ rows: this.createRowData(output, headers) });
      updateRowData(output);
    }
    if (prevProps.deletedGroupId !== deletedGroupId) {
      let localCopy = [...rows];
      if (localCopy && localCopy.length > 0) {
        for (let i = 0; i < localCopy.length; i++) {
          if (localCopy[i].channel_id === deletedGroupId) {
            localCopy.splice(i, 1);
            i -= 1;
          }
        }
        const output1 = this.getTotalRows(localCopy);
        this.setState({ totalRows: output1 });
        this.setState({ rows: this.createRowData(output1, headers) });
        updateRowData(output1);
      }
    }
  }

  getTotalRows = (rows) => {
    const { stateFilters, } = this.props;
    let totalRows = [];
    let c0 = 0;
    let c1 = 0;
    let c2 = 0;
    let c3 = 0;
    if (rows && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].cover_mode === 'default') {
          totalRows.push(rows[i]);
          c0 += 1;
        } else {
          if (rows[i].cover_mode === 'multi_applicants_picked_by_manager') {
            //No applicant yet, show parent shift as 'Posted'
            if (rows[i].current_applicants_pending_user_ids.length === 0 &&
              rows[i].current_applicants_denied_user_ids.length === 0 &&
              rows[i].current_applicants_approved_user_ids.length === 0 &&
              rows[i].current_applicants_nominated_user_ids.length === 0) {
              if (stateFilters && stateFilters.length > 0) {
                if (stateFilters.includes('posted')) {
                  totalRows.push(rows[i]);
                  c2 += 1;
                }
              } else {
                totalRows.push(rows[i]);
                c2 += 1;
              }
              //totalRows.push(rows[i]);
            }
            //for team shift need to do this, not for open shift because a child card created for each approved applicant
            if (rows[i].current_applicants_approved_user_ids.length === 1) {
              if (rows[i].name === 'shift') {
                if (stateFilters && stateFilters.length > 0) {
                  if (stateFilters.includes('approved')) {
                    totalRows.push(rows[i]);
                    c2 += 1;
                  }
                } else {
                  totalRows.push(rows[i]);
                  c2 += 1;
                }
              }
            }
            //removed condition (stateFilters.includes('pending') || stateFilters.includes('posted')
            if (rows[i].current_applicants_pending_user_ids.length > 0) {
              for (let j = 0; j < rows[i].current_applicants_pending_user_ids.length; j++) {
                let copyRow = { ...rows[i] }
                if (copyRow.coverer === null) {
                  const userId = copyRow.current_applicants_pending_user_ids[j];
                  let coverer = { id: 0, first_name: '', last_name: '', profile_image: {} };
                  coverer.id = userId;
                  coverer.last_name = '#p'; //use this variable to pass applicant status
                  copyRow.coverer = coverer;
                } else {
                  if (rows[i].name === 'shift') {
                    const userId = copyRow.current_applicants_pending_user_ids[j];
                    let coverer = { id: 0, first_name: '', last_name: '', profile_image: {} };
                    coverer.id = userId;
                    coverer.last_name = '#p'; //use this variable to pass applicant status
                    copyRow.coverer = coverer;
                  }
                }
                if (stateFilters && stateFilters.length > 0) {
                  if (stateFilters.includes('pending')) {
                    totalRows.push(copyRow);
                    c2 += 1;
                  }
                } else {
                  totalRows.push(copyRow);
                  c2 += 1;
                }
              }
            }
            //removed condition  stateFilters.includes('rejected')
            if (rows[i].current_applicants_denied_user_ids.length > 0) {
              for (let j = 0; j < rows[i].current_applicants_denied_user_ids.length; j++) {
                let copyRow = { ...rows[i] }
                if (copyRow.coverer === null) {
                  const userId = copyRow.current_applicants_denied_user_ids[j];
                  let coverer = { id: 0, first_name: '', last_name: '', profile_image: {} };
                  coverer.id = userId;
                  coverer.last_name = '#d'; //use this variable to pass applicant status
                  copyRow.coverer = coverer;
                } else {
                  if (rows[i].name === 'shift') {
                    const userId = copyRow.current_applicants_denied_user_ids[j];
                    let coverer = { id: 0, first_name: '', last_name: '', profile_image: {} };
                    coverer.id = userId;
                    coverer.last_name = '#d'; //use this variable to pass applicant status
                    copyRow.coverer = coverer;
                  }
                }
                if (stateFilters && stateFilters.length > 0) {
                  if (stateFilters.includes('rejected')) {
                    totalRows.push(copyRow);
                    c2 += 1;
                  }
                } else {
                  totalRows.push(copyRow);
                  c2 += 1;
                }
              }
            }
          }
        }
      }
    }
    return totalRows;
  }

  createRowData = (shifts, headers) => {
    const { stateFilters, } = this.props;

    if (shifts && shifts.length > 0 && headers && headers.length > 0) {
      return shifts.map((shift, index) => {
        return headers.map((header) => {
          if (Array.isArray(header.key)) {
            return header.key.map((key) => {
              return shift[key];
            });
          } else {
            return shift[header.key];
          }
        });
      });
    } else {
      return [];
    }
  };

  createRows = (rowDatas, headers) => {
    const { skills, stateFilters, } = this.props;
    const { locationId, } = this.state;


    if (rowDatas.length > 0) {
      return rowDatas.map((element, index) => {
        return <TableRow
          key={index}
          rowData={element}
          headers={headers}
          skills={skills}
          locationId={locationId}
          stateFilters={stateFilters}
        />;
      });
    } else {
      return [];
    }
  };

  checkForAbsentData = () => {
    if (!this.props.loading && this.state.rows.length === 0) {
      return (
        //<AbsentDataComponent message="There is no data available for the conditions set." />
        <AbsentDataComponent message=<FormattedMessage
          id="CalendarTable.noData"
          defaultMessage="There is no data available for the condition set." />
        />
      );
    }
  };

  createHeaders = () => {
    if (!this.props.headers) {
      return;
    }
    const Headers = () => {
      const headers = this.props.headers.map((element, index) => {
        return (
          <th key={index} className="shyft-table--header-title">
            {element.title}
          </th>
        );
      });
      return headers;
    };

    return (
      <thead>
        <tr className="shyft-table--headers">
          <Headers />
        </tr>
      </thead>
    );
  };

  findInFilters = (status, filters) => {
    return filters.findIndex((element) => {
      return status == element;
    });
  };

  render() {
    return (
      <section className="shyft-table--wrapper">
        <LoadingLayer loading={this.props.loading} />
        <table className={`shyft-table ${this.props.loading ? 'loading' : ''}`}>
          {this.createHeaders()}
          <tbody>{this.createRows(this.state.rows, this.props.headers)}</tbody>
        </table>
        {this.checkForAbsentData()}
      </section>
    );
  }
}

export default TableComponent;

TableComponent.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  rows: PropTypes.array.isRequired,
};
