
import React from 'react';
import { connect,} from 'react-redux';
import { unregisteredUserOperations, } from './duck';
import AddUnregisteredUserComponent from './AddUnregisteredUserComponent';

const mapDispatchToProps = (dispatch) => {
  const createUnregisteredUserDispatch = (locationId, firstName, lastName, email) => {
    dispatch(unregisteredUserOperations.createUnregisteredUserOperation(locationId, firstName, lastName, email));
  }
  return {
    createUnregisteredUserDispatch,
  };
};
const AddUnregisteredUserContainer = connect(
    null,
  mapDispatchToProps
)((props) => {
    const {locationId, handleCloseAddTeamModal, createUnregisteredUserDispatch, locationData} = props;

  return (
    <AddUnregisteredUserComponent 
    handleCloseAddTeamModal = {handleCloseAddTeamModal}
    locationId = {locationId}
    createUnregisteredUserDispatch = {createUnregisteredUserDispatch}
    locationData = {locationData}
    />
  );
});

export default AddUnregisteredUserContainer;
