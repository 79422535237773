import { defineMessages } from 'react-intl';

const messages = defineMessages({
  notAuthError: {
    id: 'AddLocationPageCode.notAuthError',
    defaultMessage: "*Oops! You're not authorized to join this location.",
  },
  ourSideError: {
    id: 'AddLocationPageCode.ourSideError',
    defaultMessage:
      '*Something went wrong while processing the information. Please try again.',
  },
  alreadyMemberError: {
    id: 'AddLocationPageCode.alreadyMemberError',
    defaultMessage:
      "*It looks like you're already a member of this location! If you're having trouble accessing the location, please contact us at support@myshyft.com. Otherwise, happy Shyfting!",
  },
  codeLengthError: {
    id: 'AddLocationPageCode.codeLengthError',
    defaultMessage: '*Location Codes must be 6-10 characters long!',
  },
  locationCodePlaceholder: {
    id: 'AddLocationPageCode.locationCodePlaceholder',
    defaultMessage: 'Location code',
  },
  locationNotFoundError: {
    id: 'LocationContainer.locationNotFound',
    defaultMessage: "*Location not found! Try again."

  },
  emailTakenError: {
    id: 'SignupPage.emailTakenError',
    defaultMessage:
      'That email has already been taken. Please try another or try logging in instead.',
  },
  phoneTakenError: {
    id: 'SignupPage.phoneTakenError',
    defaultMessage:
      'That phone number has already been taken. Please try another or try logging in instead.',
  },
  wrongPinError: {
    id: 'SignupPage.wrongPinError',
    defaultMessage:
      "Oops, that PIN doesn't match our records. Please try again.",
  },
  wrongPinError2: {
    id: 'SignupPage.wrongPinError2',
    defaultMessage:
      'Something went wrong while processing the information. Please try again.',
  },
});

export default messages;
