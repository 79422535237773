import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  dateRange: slices.dateRangeSlice.reducer,
  shifts: slices.shiftsSlice.reducer,
  allUsers: slices.scheduleUsersSlice.reducer,
  applicants: slices.applicantsSlice.reducer,
  applicantComments: slices.applicantCommentsSlice.reducer,
};

const reducer = combineReducers({
  dateRange: reducers.dateRange,
  shifts: reducers.shifts,
  allUsers: reducers.allUsers,
  applicants: reducers.applicants,
  applicantComments: reducers.applicantComments,
});

export default reducer;
