import React from "react";

export function DetailFieldQuantityComponent ({presentedShift, bulkQuantity, handleQuantityChange}) {

    return (
        <div className={ 'quantity-wrap '}>
            <div className="title-label">Quantity</div>
            <input className='quantity-input' onChange={handleQuantityChange} defaultValue={ presentedShift?.name == 'open_shift' ? presentedShift?.total_shifts_available : bulkQuantity}/>
        </div>
    )
}

export default DetailFieldQuantityComponent; 