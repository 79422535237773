import { formatOnlyProps } from 'helpers/formatting';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const COUNTERPART_URL = process.env.REACT_APP_COUNTERPART_URL;
export const PUBNUB_SUBSCRIBE_KEY = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
export const COOKIE_DOMAIN =
  process.env.NODE_ENV === 'production' ? 'myshyft.com' : undefined;

export const ModalTypes = {
  MY_SHIFT: 'MY_SHIFT',
  MY_COVERING_SHIFT: 'MY_COVERING_SHIFT',
  AVAILABLE_SHIFT: 'AVAILABLE_SHIFT',
  COVER_SHIFT: 'COVER_SHIFT',
  DELETE_SHIFT: 'DELETE_SHIFT',
  POST_SHIFT: 'POST_SHIFT',
};

export const userCookieKeys = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  LOCATION_ID: 'location_id',
  PROFILE_IMAGE: 'profile_image',
  TIME_ZONE: 'time_zone',
  USER_ID: 'user_id',
};

export const API_SECTIONS = ['index', 'setup_actions', 'config'];

export const shiftCallKeys = formatOnlyProps(`
    id,
    post_id,
    content,
    created_at,
    start_at,
    end_at,
    name,
    channel_name,
    coverer_time,
    manager_time,
    trade_status,
    segment_count,
    primary_job,
    cover_mode,
    total_shifts_available,
    available_shifts_pending,
    available_shifts_covered,
    available_shifts_left,
    parent_card,
    parent_id,
    child_card,
    current_applicants_pending_user_ids,
    current_applicants_approved_user_ids,
    current_applicants_denied_user_ids,
    current_applicants_nominated_user_ids,
    current_applicants_pending_ids, 
    current_applicants_approved_ids, 
    current_applicants_denied_ids, 
    current_applicants_nominated_ids,
    shift_1_5,
    channel_id,
    skill_ids,
    location_id,
    taker_id,
    coverer[
      id,
      first_name,
      last_name,
      profile_image
    ],
    owner[
      id,
      first_name,
      last_name,
      profile_image
    ],
    approver[
      id,
      first_name,
      last_name,
      profile_image,
    ],
    location[
      location_name,
      formatted_address,
    ],
  `);



export const perfShiftCallKeys = formatOnlyProps(`
  id,
  content,
  created_at,
  start_at,
  end_at,
  name,
  primary_job,
  channel_name,
  coverer_time,
  manager_time,
  trade_status,
  cover_mode,
  total_shifts_available,
  available_shifts_pending,
  available_shifts_covered,
  available_shifts_left,
  parent_card,
  parent_id,
  child_card,
  channel_id,
  skill_ids,
  current_applicants_pending_user_ids,
  current_applicants_approved_user_ids,
  current_applicants_denied_user_ids,
  current_applicants_nominated_user_ids,
  current_applicants_pending_ids, 
  current_applicants_approved_ids, 
  current_applicants_denied_ids, 
  current_applicants_nominated_ids,
  coverer[
    id,
    first_name,
    last_name,
    profile_image
  ],
  owner[
    id,
    first_name,
    last_name,
    profile_image
  ],
  approver[
    id,
    first_name,
    last_name,
  ],
   location[
      location_name,
      formatted_address,
    ],
`);
export const PENDING_SHIFTS_PER_PAGE = 60;
export const CALENDAR_SHIFTS_PER_PAGE = 1000;
export const MAX_LOADING_TIMES = 20; // in case something goes wrong, stop loading before reaching this

