import axios from 'axios';
import { axiosInstance, protectedAxiosInstance } from 'helpers/api-calls';
import { formatOnlyProps } from 'helpers/formatting';
import { BASE_URL } from '../../constants';
import { getLocationCode, getLocaleCookie } from '../cookies';

const GetLocationAnalytics = (id, start, end) =>
  protectedAxiosInstance
    .request({
      url: `/api/organization/locations/${id}/analytics`,
      method: 'GET',
      params: {
        kpi_start_time: start,
        kpi_end_time: end,
      },
    })
    .then((response) => {
      return response.data;
    });

export const GetAccessibleLocations = () => {
  return protectedAxiosInstance
    .request({
      url: `/api/customer/user_role/locations/can_access`,
      params: {
        per_page: 100,
        'response[only]': formatOnlyProps(`
          formatted_address,
          fence_radius,
          formatted_address,
          id,
          lat,
          latitude,
          lng,
          location_name,
          longitude,
          phone_number,
          shift_require_approval,
          swift_code,
          time_zone,
          avatar_blob,
          member_count,
          shyft_pro_active,
          created_at,
          users_count,
          feed_channel_id,
          pending_users_count
        `),
      },
    })
    .then((response) => {
      return response.data;
    });
};

export const GetCurrentLocation = () => {
  const locationId = getLocationCode();
  return protectedAxiosInstance.request({
    url: `api/organization/locations/${locationId}`,
    method: 'GET',
    params: {
      'response[only]': formatOnlyProps(`
        id,
        formatted_address,  
        location_name,
        time_zone,
        shift_require_approval,
        plan_id,
        plan_cycle,
        plan_owner_id,
        plan_refresh_date,
        fiscal_day,
        swift_code
      `),
    },
  });
};

export const GetDashboardAnalytics = (
  locationId,
  thisSevenDays,
  previousSevenDays
) =>
  axios
    .all([
      GetLocationAnalytics(locationId, thisSevenDays.from, thisSevenDays.to),
      GetLocationAnalytics(
        locationId,
        previousSevenDays.from,
        previousSevenDays.to
      ),
    ])
    .then(
      axios.spread(function (firstSeven, prevSeven) {
        return {
          firstSeven: firstSeven.location.kpis,
          prevSeven: prevSeven.location.kpis,
        };
      })
    );

/**
 * Gets setup actions for the user's current location.
 * This will be used more and more often for checking whether
 * a user needs certain features in the app.
 * @param  { String | Int } {location_id}
 */
export const GetLocationSetupActions = (location_id) => {
  const locale = getLocaleCookie();
  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/setup_actions`,
    method: 'GET',
    headers: {
      locale,
    },
    params: {
      location_id,
      'response[meta][nested]': 2,
    },
  });
};

/**
 * Claims administrative privilege for manager's of locations. They have to confirm after this.
 * @param  { String } ref_id - location id of the user privilege that the admin wants to claim
 * @param  { String } email - email of admin claiming privilege
 */
export const ClaimAdministrativePrivileges = (ref_id, email) => {
  const email_host = `${window.location.protocol}//${window.location.hostname}`;
  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/admin_claims`,
    method: 'POST',
    data: {
      admin_claim: {
        ref_id,
        email,
        email_host,
      },
    },
  });
};

/**
 * Confirms administrative privilege for manager's of locations.
 * @param  { String } code - confirmation code received from the code
 */
export const ConfirmAdministrativePrivileges = (activation_code) => {
  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/admin_claims/claim`,
    method: 'GET',
    params: {
      activation_code,
    },
  });
};

export const LocationJoin = (location_id) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/user_privileges`,
    method: 'POST',
    data: {
      user_privilege: {
        location_id,
      },
    },
  });

export const CreateLocationJoin = (placeDetails, zipCode, address,) => {
  const registerLocation = !placeDetails
    ? { location_name: address, formatted_address: zipCode }
    : {
      location_name: placeDetails.googleBusinessName,
      latitude: placeDetails.latitude,
      longitude: placeDetails.longitude,
      google_map_id: placeDetails.googlePlaceID,
      formatted_address: placeDetails.googleFormattedAddress,
    };

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/locations/find_or_create?join=true`,
    method: 'POST',
    data: {
      location: registerLocation,
    },
  });
};

export const SearchLocation = (swiftCode) =>
  axiosInstance.request({
    url: `/api/organization/locations/${swiftCode.toUpperCase()}`,
  });

export const getLocationSettings = (user_privilege_id) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/user_privileges/${user_privilege_id}/settings`,
  });

export const getLocationCostCenterData = (location_id) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/locations/${location_id}/tag_groups`,
  });

/**
* Gets cost center data for the user's current location.
* This will be used more and more often for checking whether
* a user needs certain features in the app.
* @param  { String | Int } {location_id}
*/
export const GetLocationCostCenterData = (location_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/locations/${location_id}/tag_groups`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
    },
  });
};



export const updateWorkweekStartDayCall = (locationId, weekday) => {
  const payload = {
    "location": {
      "fiscal_day": weekday,
    }
  };

  return protectedAxiosInstance.request({
    url: `api/customer/user_role/locations/${locationId}`,
    method: 'PUT',
    data: payload
  });
};