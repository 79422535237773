import React, { useState, useCallback, useRef, useEffect } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import styled from './styled';
import { useDrag } from 'react-dnd';
import formatter from 'pages/ScheduleCalendar/duck/formatter';
import { Mixpanel } from '../../../../../Mixpanel';
import './Cell.scss';

import presenter from 'pages/ScheduleCalendar/duck/presenter';
import service from 'pages/ScheduleCalendar/duck/service';
import { isValidObject } from 'helpers/validation';

export function DraggableShiftCell ({
  user,
  shifts,
  selectedDateToAddShift,
  date,
  handleOpenShiftDetail,
  copyShiftToUserAndDateDispatch,
  shiftToCopy,
  isCopyingShift,
  allUsers,
  allUserShiftsOnDay,
  handleTooltipHide,
  handleTooltipShow,
})  { 

  const ref = useRef(null);

  const canDragShift = () => {
    if( shifts != null ){
      const shiftToDrag = shifts[0];
      if( shiftToDrag != null){
        if ( shiftToDrag.trade_status === 'unpublished_inventory' || shiftToDrag.trade_status === 'unregistered_unpublished_inventory' ) {
          return true;
        }else{
          return false;
        }
      }
    }
    return false;
  }

  const [{isDragging, canDrag}, drag] = useDrag(() => ({
    type: 'ShiftCell',
    item: () => (shifts?.length ? shifts[0] : { id: 0 }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag:  canDragShift(),
  }), [canDragShift])

  drag(ref);

  const [isMouseOverCell, setIsMouseOverCell] = useState(false);

  const onCellClick = useCallback(() => {
    if (shifts && shifts.length > 0) {
      Mixpanel.track('Open shift detail', { 'target user': user.owner_id, 'date': date.format('YYYY-MM-DD') });
      handleOpenShiftDetail(shifts[0], date);
    } else {
      setIsMouseOverCell(false);
      selectedDateToAddShift(user, date);

      // Add Shifts
      if (user.status !== 'unregistered') {
        Mixpanel.track('Add shift from cell', { 'target user': user.owner_id, 'date': date.format('YYYY-MM-DD') });
      } else {
        Mixpanel.track('Clicked unregistered user cell', { 'target user': user.id, 'date': date.format('YYYY-MM-DD') });
      }
    }
  }, [shifts, user, date, handleOpenShiftDetail, selectedDateToAddShift]);

  const onPasteClick = useCallback(() => {
    setIsMouseOverCell(false);
    copyShiftToUserAndDateDispatch(shiftToCopy, user, date, shiftToCopy.location_id);
    Mixpanel.track('Paste Shift');
  }, [copyShiftToUserAndDateDispatch, shiftToCopy, user, date]);

  const onEmptyCellMouseEnter = () => { setIsMouseOverCell(true); };

  const onEmptyCellMouseLeave = useCallback(() => { setIsMouseOverCell(false); }, []);

  const onAvatarMouseOver = (e) => { 
    if ( presenter.shouldShowTakerAvatar(shifts[0]) == true ){
      const content = presenter.xCoveringForYText(user, shifts[0], allUsers);
      handleTooltipShow(e, content);
    }
  };

  const onConflictMouseOver = (e) => {
    const name = user.first_name + ' ' + user.last_name;
    handleTooltipShow(e, 'Conflict: '+ name + ' has another shift on this day.');;
  };

  const onConflictMouseOut = () => {
    handleTooltipHide();
  };

  let position = '';
  let start = null;
  let end = null;
  let hours = 0;
  let timeRange = '';

  if (shifts && shifts.length > 0) {
    start = moment(shifts[0].start_at).local().clone();
    end = moment(shifts[0].end_at).local().clone();
    timeRange = formatter.formattedShiftTimeRange(shifts[0]);
    const diff = moment.duration(end.diff(start));
    hours = parseFloat(diff.asHours()).toFixed(2);
    position = shifts[0].primary_job;
  }

  const textHours = ( <FormattedMessage id="DayCell.hours" defaultMessage="{value} h" values={{ value: hours }} />);

  if ( isValidObject(shifts) == false || shifts.length === 0) {
    if (isCopyingShift && shiftToCopy !== null) {
      return presenter.pasteShiftCell(onPasteClick, onEmptyCellMouseEnter, onEmptyCellMouseLeave, isMouseOverCell);
    } else {
      return presenter.addNewShiftCell(onCellClick, onEmptyCellMouseEnter, onEmptyCellMouseLeave, isMouseOverCell);
    }
  }

  const shiftObject = shifts[0];

  const posterAvatar = presenter.posterAvatar(shiftObject, allUsers);
  const takerAvatar = presenter.takerAvatar(shiftObject, allUsers);

  const showAvatar = (presenter.shouldShowPosterAvatar(shiftObject) || presenter.shouldShowTakerAvatar(shiftObject));

  const bottomLine = (
    <styled.DayCellBottomLineWrap>
      {presenter.shouldShowPosterAvatar(shiftObject) == true && <styled.DayCellAvatar1 src={posterAvatar} onMouseOver={onAvatarMouseOver} onMouseOut={onConflictMouseOut}></styled.DayCellAvatar1>}
      {presenter.shouldShowTakerAvatar(shiftObject) == true && <styled.DayCellAvatar2 src={takerAvatar} onMouseOver={onAvatarMouseOver} onMouseOut={onConflictMouseOut}></styled.DayCellAvatar2>}

      <label className={`position-hours ${showAvatar ? 'left-margin-4' : ''}`}>{position}</label>
        {position.length > 0 && <styled.Dot />}
      <styled.BottomLineText>{textHours}</styled.BottomLineText>
    </styled.DayCellBottomLineWrap>
  );

  var isDraftShift = false;
  if( shifts[0]?.trade_status === 'unpublished_inventory' ||
      shifts[0]?.trade_status === 'unregistered_unpublished_inventory') { 
        isDraftShift = true;
  }

  const cardClassName = presenter.cardClassNameForShift(shiftObject);
  const tagText = presenter.tagTextForShift(shiftObject);
  const shouldShowConflict = service.userHasShiftConflict(shifts[0], allUserShiftsOnDay);

  return (
    <div className= {"day-cell-top-wrap" + " " + cardClassName } ref={ref} onMouseUpCapture={onCellClick} key= {shifts[0].id}>
        <span className='status-tag'> {tagText} </span>

        <div className= {(isDraftShift) ? 'day-cell dotted-border': 'day-cell solid-border'}>
          <div className='top-row'>
            <label className='time-label'> {timeRange} </label>
            {shouldShowConflict && <img className='conflict-sign' src='/icons/conflict.svg' onMouseOver={onConflictMouseOver} onMouseOut={onConflictMouseOut}></img>}
          </div>
          {bottomLine}
        </div>

    </div>
  );

};

export default DraggableShiftCell;
