import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import './ShiftApplicantsComponent.scss';
import applicantLogic from 'pages/ScheduleCalendar/duck/applicantLogic';
import ShiftApplicantRow from './ShiftApplicantRow';
import presenter from 'pages/ScheduleCalendar/duck/presenter';
import ShiftApplicantCommentsContainer from './ShiftApplicantCommentsContainer';
import service from 'pages/ScheduleCalendar/duck/service';
import { Mixpanel } from '../../../../../Mixpanel';

export function ShiftApplicantsComponent ({
    applicants,
    approveApplicantDispatch,
    denyApplicantDispatch,
    presentedShift,
    shiftMatrix,
    weekRangeStart,
    handleTooltipShow,
    handleTooltipHide,
  }) {

    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const [showingComments, setShowingComments] = useState(false);
    const [applicantToShowCommentsFor, setApplicantToShowCommentsFor] = useState(null);

    const approveSelectedApplicant = () => {
      const applicant = selectedApplicant;
      if( applicant != null ) { 
        approveApplicantDispatch(applicant.schedule_element_id, applicant.id); 
        Mixpanel.track('Approve applicant', { applicantId: applicant.id });
      }
    }

    const denySelectedApplicant = () => {
      const applicant = selectedApplicant;
      if( applicant != null ){
        denyApplicantDispatch(applicant.schedule_element_id, applicant.id); 
        Mixpanel.track('Deny applicant', { applicantId: applicant.id });
      }
    }    

    const selectApplicant = (applicant) => {
      setSelectedApplicant(applicant);
    }

    const showCommentsForApplicant = (applicant) => {
      setApplicantToShowCommentsFor(applicant);
      setShowingComments(true);
    }

    const showApplicantList = () => {
      setApplicantToShowCommentsFor(null);
      setShowingComments(false);
    }

    const applicantRow = (applicant) => {
      const selected = (selectedApplicant?.id == applicant.id);
      const hasConflict = service.applicantHasOtherShifts(applicant, presentedShift, weekRangeStart, shiftMatrix)

      return <ShiftApplicantRow 
          key={applicant.id} 
          applicant={applicant} 
          selectApplicant={selectApplicant} 
          isHighlighted={selected}
          showCommentsForApplicant={showCommentsForApplicant}
          hasConflict = {hasConflict}
          handleTooltipShow = {handleTooltipShow}
          handleTooltipHide = {handleTooltipHide} />
    }

    const pendingRows  = applicantLogic.pendingApplicants(applicants).map((applicant) => { return applicantRow(applicant); });
    const approvedRows = applicantLogic.approvedApplicants(applicants).map((applicant) => { return applicantRow(applicant); });
    const deniedRows =  applicantLogic.deniedApplicants(applicants).map((applicant) => { return applicantRow(applicant); });

    const pendingX = presenter.pendingX(pendingRows.length);
    const approvedX = presenter.approvedX(approvedRows.length);
    const deniedX = presenter.deniedX(deniedRows.length);

    return (<div className='shift-applicants-container'>
              { showingComments == false && <div>
                <div className='applicants-list'>
                  <label className='applicant-row-header'>{pendingX}</label>
                  {pendingRows}
                
                  <label className='applicant-row-header'>{approvedX}</label>
                  {approvedRows}

                  <label className='applicant-row-header'>{deniedX}</label>
                  {deniedRows} 
                </div>
                <div className='action-buttons'>
                  <button className='deny-button' onClick={denySelectedApplicant}> Deny </button>
                  <button className='approve-button' onClick={approveSelectedApplicant}> Approve </button>
                </div>
              </div> }

              { showingComments == true && 
                <ShiftApplicantCommentsContainer 
                applicant={applicantToShowCommentsFor} 
                handleBackButtonClick={showApplicantList}
                /> }
            </div>
            );
}

export default injectIntl(ShiftApplicantsComponent);
