import React from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../components/GeneralComponents/RegistrationDesign.scss'
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import {
    signUpResendConfirmation,
} from 'helpers/api-calls';


class RegistrationAccountVerificationPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            email: '',
            phone: '',
            isCreateLocationSelected: false,
        };
    }

    componentDidMount() {
        const { location } = this.props;

        if (location && location.state) {
            this.setState({
                isCreateLocationSelected: location.state.isCreateLocationSelected,
                userName: location.state.userName,
                email: location.state.email,
                phone: location.state.phone,
            });
        } else {
            // If API is not loaded, redirect to Registration1
            this.props.history.push('/');
            return null;
        }
    }

    resendEmail = async () => {
        const { email, phone, userName, isCreateLocationSelected, } = this.state;
        const { intl } = this.props;
        signUpResendConfirmation(email).then(
            (response) => {
                // console.log("Method - success - ", response);
            },
            (error) => {
                // console.log("Method - error - ", error);
            }
        );
    };

    render() {
        const { isCreateLocationSelected, userName, email, phone } = this.state;
        return (
            <RegistrationLayout>
                <div className="form-content">
                    <div className="welcome-text-wrapper">
                        <div className="dot"></div>
                        <div className="welcome-text">Verifying Email</div>
                    </div>
                    <div className="main-heading">Almost there!</div>
                    <div className="trial-prompt">
                        An email verification link was sent to {email}.
                        Click on the link to verify your account and get started.
                        Make sure to check your spam folder.
                    </div>

                    <div className="login-message" style={{ marginTop: '24px' }}>
                        Didn't get the email?{' '}
                        <a className="login-message-link" onClick={this.resendEmail}>
                            Resend email
                        </a>

                    </div>

                    <section className="login__form-action" style={{ marginTop: '120px' }}>
                        <div className="login-message">
                            Already verified?{' '}
                            <Link className="login-message-link" to="/login">
                                Log in
                            </Link>
                        </div>
                    </section>
                </div>
            </RegistrationLayout>
        );
    }
}

const RegistrationAccountVerificationPagei18n = injectIntl(RegistrationAccountVerificationPage);

export default withRouter(RegistrationAccountVerificationPagei18n);