import { getUserId, getLocationCode } from 'helpers/cookies';
//import moment from 'moment';
import moment from 'moment-timezone';
import {
  createApiDateFormat,
  convertToCurrentTimezone,
  getDefaultTimezone,
} from 'helpers/date-time';

export function isNextDay(times) {
  return (
    times[1].isBefore(times[0]) ||
    times[0].isSame(times[1]) ||
    times[1].isAfter(times[0], 'date')
  );
}

/**
 * @param  { Array } shifts - the shifts to be posted to the back-end
 * @param  { String } type - the type of shift to be posted
 *
 * @return { Array } - array of shifts that are ready to be posted to the API
 */
export function shiftsForApi(shifts, type, costCenters) {
  const owner_id = getUserId();
  const location_id = getLocationCode();
  const timeZone = getDefaultTimezone();


  //console.log('outPut = ' + moment.tz("2014-06-01 12:00", "America/New_York").format());


  if(type ==='open_shift' && costCenters) {
    return shifts.map((shift) => {
      const endAt = isNextDay([shift.start_at, shift.end_at])
        ? shift.end_at.add(1, 'days')
        : shift.end_at;
      const start = moment.tz(shift.start_at, timeZone).format();
      const end = moment.tz(endAt, timeZone).format();
      return {
        schedule_element: {
          name: type,
          owner_id: Number(owner_id),
          location_id: Number(location_id),
          channel_id: Number(shift.channel),
          total_shifts_available: shift.number_of_shifts,
          shift_content: shift.reason,
          //start_at: createApiDateFormat(shift.start_at),
          //end_at: createApiDateFormat(endAt),
          start_at: start,
          end_at: end,
          applicants_max_num: shift.applicants_max_num,
          cached_primary_job: shift.jobTitle,
          cost_work_center: shift.cost_work_center,
          skill_ids: shift.skill_ids,
        },
      };
    });
  }else {
    
    return shifts.map((shift) => {
      const endAt = isNextDay([shift.start_at, shift.end_at])
        ? shift.end_at.add(1, 'days')
        : shift.end_at;
      const start = moment.tz(shift.start_at, timeZone).format();
      const end = moment.tz(endAt, timeZone).format();
      console.log('start_at:' + start);
      console.log('endAt:' + end);
      return {
        schedule_element: {
          name: type,
          owner_id: Number(owner_id),
          location_id: Number(location_id),
          channel_id: Number(shift.channel),
          total_shifts_available: shift.number_of_shifts,
          shift_content: shift.reason,
          start_at: start,
          end_at: end,
          applicants_max_num: shift.applicants_max_num,
          cached_primary_job: shift.jobTitle,
          skill_ids: shift.skill_ids,
        },
      };
    });
  }
}

/**
 * this.reason = reason;
  this.start_at = start_at;
  this.end_at = end_at;
  this.number_of_shifts = number_of_shifts;
  this.channel = null;
  this.posting = {
    id: null,
    posting: false,
    success: null,
    error: null,
  };
 */
export function shiftsForState(shifts) {
  return shifts.map((shift) => {
    const formattedShift = {
      start_at: convertToCurrentTimezone(shift.start_at),
      end_at: convertToCurrentTimezone(shift.end_at),
      channel: shift.channel_id,
      reason: shift.shift_content,
      number_of_shifts: shift.total_shifts_available,
      posting: {
        id: shift.id,
        posting: false,
        success: true,
        error: null,
      },
    };

    return formattedShift;
  });
}
