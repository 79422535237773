import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import OwnerCell from './Cell/OwnerCell';
import StatusCell from './Cell/StatusCell';
import Modal from 'components/GeneralComponents/Modal';

import './TableComponent.scss';
import styled from './styled';
import styledConfirm from '../../../UserManagement/components/Header/styled';
import constants from '../../../UserManagement/constants';
import Confirm from '../../../UserManagement/components/Header/Confirm';
import currentUser from 'pages/TeamProfile/duck/slices';

class ScheduleRowComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      debug: true,
      openMenu: false,
      dropdownPos: null,
      showConfirm: false,
      selectedAction: null,
      scheduleDeleted: false,
      owner: null,
    };

    this._isMounted = false;
    this.openMenuRef = React.createRef();
    this.menuWinRef = React.createRef();

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { schedule, actionJob, allUsers, notifyScheduleOwner, } = this.props;

    this._isMounted = true;
    document.addEventListener("mousedown", this.handleClickOutside);
    if (schedule && schedule.user_id) {
      if (schedule && schedule.user_id && schedule.location_id) {
        if (allUsers && allUsers.length > 0) {
          const users = allUsers.filter((item) => item.owner_id === schedule.user_id);
          if (users && users.length > 0) {
            this.setState({ owner: users[0] });
            notifyScheduleOwner(schedule.schedule_id, schedule.user_id, users[0]);
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { schedule, actionJob, allUsers, notifyScheduleOwner, } = this.props;
    const { openMenu, } = this.state;

    if (prevProps.schedule !== schedule) {
      if (schedule && schedule.user_id) {
        if (schedule && schedule.user_id && schedule.location_id) {
          if (allUsers && allUsers.length > 0) {
            const users = allUsers.filter((item) => item.owner_id === schedule.user_id);
            if (users && users.length > 0) {
              this.setState({ owner: users[0] });
              notifyScheduleOwner(schedule.schedule_id, schedule.user_id, users[0]);
            }
          }
        }
      }
    }

    if (prevProps.allUsers !== allUsers) {
      if (schedule && schedule.user_id) {
        if (schedule && schedule.user_id && schedule.location_id) {
          if (allUsers && allUsers.length > 0) {
            const users = allUsers.filter((item) => item.owner_id === schedule.user_id);
            if (users && users.length > 0) {
              this.setState({ owner: users[0] });
              notifyScheduleOwner(schedule.schedule_id, schedule.user_id, users[0]);
            }
          }
        }
      }
    }

    let winTop = '';
    let winLeft = '';

    if (prevState.openMenu !== openMenu) {
      if (openMenu) {
        const winW2 = '200px';//`50%`;
        const winH2 = `64px`; //94.9% viewport height
        const winRight = `30px`;
        if (this.openMenuRef && this.openMenuRef.current) {
          const domNode = ReactDOM.findDOMNode(this.openMenuRef.current);
          const output = domNode.getBoundingClientRect();
          if (output) {
            //winTop = `${output2.y + output2.height}px`;
            winTop = `${output.y - output.height - 20}px`;
          }
        }
        const winData = {
          top: winTop,
          right: winRight,
          width: winW2,
          height: winH2,
        }
        this.setState({ dropdownPos: winData });
      }
    }

  }

  componentWillUnmount() {
    const { schedule, allUsers, } = this.props;

    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleOpenMenuClick = (event) => {
    const { notifyMenuOpened, rowMenuOpened, } = this.props;
    const { openMenu, } = this.state;
    //If one row menu opened, other rows cannot open
    if (rowMenuOpened) {
      if (openMenu) {
        this.setState({ openMenu: !openMenu });
        notifyMenuOpened(!openMenu);
      }
      return;
    }

    this.setState({ openMenu: !openMenu });
    notifyMenuOpened(!openMenu);
  }

  handleDeleteClick = (event) => {

    const { userActionTypes } = constants;
    const approveText = (<FormattedMessage
      id="Header.deleteschedule"
      defaultMessage="Delete Schedule?"
    />);
    const action = { name: userActionTypes.DELETESCHEDULE, body: approveText };
    this.setState({ showConfirm: true, selectedAction: action });
  }

  notifyConfirm = (request) => {
    const { selectedAction, } = this.state;
    const { userActionTypes } = constants;
    const { notifyActionRequested, notifyMenuOpened, deleteActionJob, schedule, handleScheduleDeleted, notifyScheduleAboutToDelete, } = this.props;
    this.setState({ showConfirm: false, openMenu: false });
    notifyMenuOpened(false);
    if (request) {
      if (selectedAction.name === userActionTypes.DELETESCHEDULE) {
        deleteActionJob(schedule.uuid, schedule.schedule_id, schedule.location_id, handleScheduleDeleted);
        notifyScheduleAboutToDelete();
      }
    }
  }

  handleClickOutside = (event) => {
    const { openMenu, } = this.state;
    const { notifyMenuOpened, } = this.props;

    if (this.openMenuRef && this.openMenuRef.current && !this.openMenuRef.current.contains(event.target)) {
      if (this.menuWinRef && this.menuWinRef.current && !this.menuWinRef.current.contains(event.target)) {
        if (openMenu) {
          this.setState({ openMenu: false });
          notifyMenuOpened(false);
        }
      }
    }
  }

  renderUserActionMenu = () => {
    const { user, } = this.props;
    const { dropdownPos, } = this.state;

    const deleteText = (<FormattedMessage
      id="ScheduleRowComponent.delete"
      defaultMessage="Delete"
    />);

    const itemDelete = { name: 'delete', body: deleteText };

    let items = [itemDelete];

    const actionItems = items.map((item, index) => (
      <div
        key={index}
        className="schedule-row__dropdown-item-wrap"
        value={item}
        onClick={(event) => { this.handleDeleteClick(item) }}
      >
        <label className="schedule-row__action-dropdown-text">
          {item.body}
        </label>
      </div>
    ));

    if (dropdownPos) {
      return (
        <styled.WinContainerRight ref={this.menuWinRef} top={dropdownPos.top} right={dropdownPos.right} width={dropdownPos.width} height={dropdownPos.height}>
          {actionItems}
        </styled.WinContainerRight>
      );
    }
  }

  render() {
    const { schedule, notifyScheduleOwner, allUsers, } = this.props;
    const { openMenu, owner, showConfirm, selectedAction, } = this.state;
    const scheduleName = schedule && schedule.record_meta ? schedule.record_meta.input_sheet_original_filename : '';
    const scheduleId = schedule && schedule.schedule_id ? schedule.schedule_id : '';
    const total = schedule && schedule.total_count !== null ? schedule.total_count : '-';
    const success = schedule && schedule.success_count !== null ? schedule.success_count : '-';
    const failed = schedule && schedule.failed_count !== null ? schedule.failed_count : '-';
    const { categoryTypes } = constants;
    const scheduleIdText = (<FormattedMessage
      id="ScheduleRowComponent.id"
      defaultMessage="Schedule ID: {id}"
      values={{ id: scheduleId }} />);

    const scheduleDeletedMessage = (<FormattedMessage
      id="ScheduleRowComponent.deleted"
      defaultMessage="Schedule ID: {id} Successfully Deleted"
      values={{ id: scheduleId }} />);

    return (
      <tr className="schedule-row__row">
        <td className="schedule-row__file-name-cell">
          <styled.FileNameWrap>
            <styled.FileName>{scheduleName}</styled.FileName>
            <styled.ScheduleId>{scheduleIdText}</styled.ScheduleId>
          </styled.FileNameWrap>
        </td>
        <td className="schedule-row__owner-cell">
          <OwnerCell
            schedule={schedule}
            notifyScheduleOwner={notifyScheduleOwner}
            owner={owner}
          />
        </td>
        <td className="schedule-row__status-cell">
          <StatusCell
            schedule={schedule}
          />
        </td>
        <td className="schedule-row__total-cell">{total}</td>
        <td className="schedule-row__succeeded-cell">{success}</td>
        <td className="schedule-row__failed-cell">{failed}</td>
        <td><button className="schedule-row__button-more" ref={this.openMenuRef} onClick={this.handleOpenMenuClick} /></td>
        {openMenu && <Modal>
          {this.renderUserActionMenu()}
        </Modal>}
        {showConfirm && <Modal>
          <Confirm
            selectedAction={selectedAction}
            selectedItem={currentUser}
            selectedUsers={[currentUser]}
            selectedType={categoryTypes.DELETESCHEDULE}
            notifyConfirm={this.notifyConfirm}
          />
        </Modal>}
        <styledConfirm.Overlay display={showConfirm ? 'block' : 'none'} />


      </tr>
    );
  }

}

ScheduleRowComponent.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(ScheduleRowComponent);