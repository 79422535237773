import React, { useState, useEffect, useCallback } from 'react'
import presenter from 'pages/ScheduleCalendar/duck/presenter';
import { injectIntl } from 'react-intl';
import 'App/fonts.scss'
import 'App/layout.scss'

export function DetailFieldAssignToComponent ({allUsers, selectAssignTarget, assignToTarget, disableField, showOpenShiftOption})  {

  const assignableUserList = () => {
    var dropdownItems;
    if( showOpenShiftOption == true ){
      const target = presenter.openShiftSelectionTarget();
      dropdownItems = [target].concat(allUsers)
    }else{
      dropdownItems = allUsers; 
    }

    return dropdownItems.map( (item) => {
        var name = `${item?.first_name} ${item?.last_name}`;
        const position = item?.position

        return <li className='assign-item flex-row' key={item?.id} onClick={() => selectAssignTarget(item) } > 
                <img  className='assign-icon' src={item?.profile_image_thumb_url} /> 
                <div className='flex-col assign-right'>
                  <label className="user_name ff-semibold fs12">{name} </label> 
                  {position && <label className="user_position ff-regular fs10">{position} </label> }
                </div>
              </li> 
    });
  }

    return (
        <div className='assign-to-wrap'>
          <div className="assign-to">Assign to</div>
          <div>
            { <button disabled= {disableField} id="dropdownMenuButton2" className="group-placeholder" type="button" data-bs-toggle="dropdown" aria-expanded="false"> <img className='assign-icon' src={presenter.getUserAvatar(assignToTarget)}/> {assignToTarget?.first_name} {assignToTarget?.last_name}</button> } 
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                {assignableUserList()}
              </ul> 
          </div>
        </div>
    );
}

export default injectIntl(DetailFieldAssignToComponent);