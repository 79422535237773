import React from 'react';
import './ConfirmDelete.scss';
import '../../../../App/fonts.scss';
import '../../../../App/colors.scss';
import '../../../../App/layout.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

import {Mixpanel} from '../../../../Mixpanel';

class ConfirmDeleteAllComponent extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      confirmButtonEnabled: true,
    };
  }

  handleCancel = (event) => {
    const { cancelModal } = this.props;
    cancelModal();
    Mixpanel.track('Cancel delete entire schedule');
  }

  handleConfirm = (event) => {
    const { cancelModal, locationId, deleteAllShiftsDispatch, message, shifts, rangeStart, rangeEnd } = this.props;
    const {confirmButtonEnabled} = this.state;

    if( confirmButtonEnabled == false ){
      return;
    }

    if(message !== null){
      deleteAllShiftsDispatch(shifts, locationId, rangeStart, rangeEnd)
      Mixpanel.track('Delete Entire Schedule', {'location_id':locationId});
      cancelModal();
    }
  }

  render() {
    const {confirmButtonEnabled } = this.state;

    const cancel = (<FormattedMessage id="Confirm.cancel" defaultMessage="Cancel" />);
    const confirm = (<FormattedMessage id="Confirm.confirm" defaultMessage="Delete Schedule" />);

    const notifyTitleText = (<FormattedMessage id="Scheduling.deleteEntireSchedule" defaultMessage="Delete Entire Schedule" />);
    const notifyBodyText1 = (<FormattedMessage id="Scheduling.deleteBodyText" defaultMessage="This will delete all shifts in the schedule for the weekly period. This action cannot be undone." />);

    return (
      <div className="delete-confirm-container">
        <label className="title averta-regular fs20px">{notifyTitleText}</label>
        <div className='body-text1'>{notifyBodyText1}</div>

        <div className="last-row-wrap">
          <div className='right-group'>
            <button className="confirm-button-cancel averta-semibold fs14px" onClick={this.handleCancel}>{cancel}</button>
            <button className={confirmButtonEnabled ? "confirm-button averta-semibold fs14px button-enabled" : "confirm-button averta-semibold fs14px button-disabled"} style={{ outline: 0 }} onClick={this.handleConfirm}>{confirm}</button>
          </div>
        </div>

      </div>
    );
  }
}

export default injectIntl(ConfirmDeleteAllComponent);