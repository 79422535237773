import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl'
import { shortHandTimeRangeForTimeStamps } from 'helpers/calendarUtils'

const availabilityElementsForDate = (date, userAvailabilities) => {
    var latest;

    const sortedAvailabilities = userAvailabilities.sort( (a, b) => {
        const dateA = moment(a.range_start_date);
        const dateB = moment(b.range_start_date);
        if( dateA.isBefore(dateB)){
            return -1;
        }else if ( dateA.isAfter(dateB) ){
            return 1;
        }else {
            return 0;
        }
    });

    console.log(sortedAvailabilities);

    for (const elem of sortedAvailabilities) {
        const cellDate = moment(date).format('YYYY-MM-DD');
        // console.log( elem.range_start_date + ':' + cellDate + ' - ' + moment(date).day() );
        if ( moment(elem.range_start_date).isSameOrBefore(cellDate) ){
            if ( latest == null ) {
                latest = elem;
            }else if ( moment(elem.range_start_date).isAfter(latest) ){
                latest = elem;
            }else{
                console.log('No go: ' + elem.range_start_date)
            }
        }
    }

    if( latest == null ){
        return [];
    }else{
        const dow = moment(date).day();

        const result = latest.availability_elements.filter( (e) => (e.day == dow && moment(date).isSameOrAfter(moment(latest.range_start_date))));
        console.log(result);
        return result;
    }
}


const availabilityElementsForDateWithoutRestrictions = (date, availability) => {
    if( availability == null ){
        return [];
    }else{
        const dow = moment(date).day();

        const result = availability.availability_elements.filter( (e) => (e.day == dow ) );
        console.log(result);
        return result;
    }
}

const timeRangeForAvailabilityElement = (element) => {
    const allDayText = <FormattedMessage id="Availability.allDay" defaultMessage='All Day'/>;
    if( element.start_time_local == '00:00' && element.end_time_local == '23:59' ){
        return allDayText;
    }else{
        return shortHandTimeRangeForTimeStamps(element.start_at, element.end_at);
    }
}


export default {
    availabilityElementsForDate,
    timeRangeForAvailabilityElement,
    availabilityElementsForDateWithoutRestrictions,
};