import React from 'react';
import { checkForAuthentication } from 'helpers/cookies';
import { includes } from 'helpers/validation';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import ReactDOM from 'react-dom';

import PageHeader from 'components/PageHeader/PageHeader';
import './NavPanel.scss';
import { purgeAuthenticationCookie } from 'helpers/cookies';
import Modal from './Modal';

import styled from '../../pages/UserManagement/components/Header/styled';
import constants from '../../pages/UserManagement/constants';
import Confirm from '../../pages/UserManagement/components/Header/Confirm';
import { Redirect } from 'react-router-dom';
import currentUser from 'pages/TeamProfile/duck/slices';
import './HorizontalNavbar.scss';
import { Mixpanel } from '../../Mixpanel';
import AddLocationDialog from 'pages/AddLocation/AddLocationDialog';
import { getUserId } from '../../helpers/cookies';

let basePricingPageUrl = "";
let baseShopPageUrl = "";

if (window.location.host === 'web.myshyft.com' || window.location.host === 'manager.myshyft.com') {
  basePricingPageUrl = "https://www.myshyft.com/pricing-monthly/"
  baseShopPageUrl = "https://billing.stripe.com/p/login/5kAeWA49Fbv6cNi6oo"
} else {
  basePricingPageUrl = "https://www.myshyft.com/pricing-test-3/"
  baseShopPageUrl = "https://billing.stripe.com/p/login/test_eVa9BB6633V8eIw7ss"
}

class HorizontalNavPanelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height,
      logoutActive: false,
      locationActive: false,
      profileActive: false,
      openLocationMenu: false,
      locationInfo: [],
      currentElement: this.props.options[0] ? this.props.options[0] : undefined,
      currentLocation: null,
      allEmployees: [],
      locationEmployees: [],
      lastLocationIndex: 0,
      showConfirm: false,
      selectedAction: null,
      selectedLocation: false,
      selectedProfile: false,
      selectedAddLocation: false,
      dashPos: null,
      calendarPos: null,
      requestPos: null,
      feedPos: null,
      teamPos: null,
      schedulePos: null,
      scheduleCalendarPos: null,
      settingsPos: null,
      locationPos: null,
      profilePos: null,
      dropdownPos: null,
      subscriptionPos: null,
      currentPlan: null,
    };

    this._isMounted = false;
    this.locationArrayIdx = 0;
    this.addLoc = "/add-location-authenticated";
    this.locationRef = React.createRef();
    this.profileRef = React.createRef();
    this.addLocRef = React.createRef();
    this.locationDropdownRef = React.createRef();
    this.profileDropdownRef = React.createRef();
    this.mouseTipW = 120;
    this.mouseTipH = 44;
    this.Mixpanel = require('mixpanel');
  }

  componentDidMount() {
    const { locationId, locations, locationData, plans } = this.props;
    this._isMounted = true;
    document.addEventListener('mousedown', this.handleOutsideClick);

    if (plans && plans.length > 0) {
      if (locationData && locationData.plan_id) {
        const cplan = plans.find(plan => plan.id === locationData.plan_id);
        this.setState({ currentPlan: cplan });
      }
    }
  }

  componentDidUpdate(prevState, prevProps) {
    const { locationId, locations, locationData, plans } = this.props;


    if (this.state.currentLocation === null || (this.state.currentLocation !== null && this.state.currentLocation.id != locationId)) {
      if (locations && locations.length > 0) {
        const current = locations.filter((item) => item.id.toString() == locationId);
        if (current && current.length > 0) {
          this.setState({ currentLocation: current[0] });
        }
      }
    }

    if (prevProps.locationData !== locationData) {

      if (plans && plans.length > 0) {
        if (locationData && locationData.plan_id) {
          const cplan = plans.find(plan => plan.id === locationData.plan_id);
          if (cplan && cplan !== this.state.currentPlan) {
            this.setState({ currentPlan: cplan });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = (event) => {
    if (
      (this.locationDropdownRef.current &&
        !this.locationDropdownRef.current.contains(event.target))
      ||
      this.profileDropdownRef.current &&
      !this.profileDropdownRef.current.contains(event.target)
    ) {
      // If the click is outside the dropdown, hide it
      this.setState({ selectedLocation: false, selectedProfile: false, selectedAddLocation: false });
    }
  };

  navigateToAddLocationPage = (event) => {
    this.setState({ selectedAddLocation: true, selectedLocation: false });
    Mixpanel.track('Add Location Page');
  }

  handleLogOut = (event) => {

    const { userActionTypes } = constants;
    const logOutText = (<FormattedMessage
      id="Header.logout"
      defaultMessage="Log Out?"
    />);
    const action = { name: userActionTypes.LOGOUT, body: logOutText };
    this.setState({ selectedProfile: false, showConfirm: true, selectedAction: action });
    event.preventDefault();
  }

  notifyConfirm = (request) => {
    const { selectedAction, } = this.state;
    const { userActionTypes } = constants;
    this.setState({ showConfirm: false });
    if (request) {
      if (selectedAction.name === userActionTypes.LOGOUT) {
        console.log('notifyConfirm 1 -> purgeAuthenticationCookie()')
        purgeAuthenticationCookie();
        window.location.pathname = '/';
        Mixpanel.reset();
      }
    }
  }

  locationMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;

    let top1 = `100px`; //`${(window.innerHeight - 612)/2}px`;
    let left1 = `200px`; //`${(window.innerWidth - 1200)/2}px`;
    const width1 = '200px';//`50%`;
    const height1 = `300px`; //94.9% viewport height

    if (this.locationRef && this.locationRef.current) {
      const domNode = ReactDOM.findDOMNode(this.locationRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.width}px`;
        top1 = `${output.y - 258}px`;
        left1 = winLeft;
      }
    }
    const winData = {
      top: '60px',
      right: '100px',
      width: '260px',
      height: winH,
    }
    const pos1 = {
      top: top1,
      right: '100px',
      width: width1,
      height: height1,
    }
    this.setState({ locationActive: true, dropdownPos: pos1 });
  }

  locationMouseLeave = (event) => {
    this.setState({ locationActive: false });
  }

  profileMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;

    if (this.profileRef && this.profileRef.current) {
      const domNode = ReactDOM.findDOMNode(this.profileRef.current);
      const output = domNode.getBoundingClientRect();
      if (output) {
        winTop = `${output.y + (output.height - this.mouseTipH) / 2}px`;
        winLeft = `${output.width}px`;
      }
    }
    const winData = {
      top: '60px',
      right: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({ profilePos: winData, profileActive: true });
  }

  profileMouseLeave = (event) => {
    this.setState({ profileActive: false });
  }

  handleLocationClicked = (event) => {
    this.setState({ selectedLocation: (!this.state.selectedLocation), selectedProfile: false, selectedAddLocation: false });
    event.preventDefault();
  }

  handleProfileClicked = (event) => {
    this.setState({ selectedLocation: false, selectedProfile: (!this.state.selectedProfile), selectedAddLocation: false });
    event.preventDefault();
  }

  handleLocationChange = (location) => {
    const { handleChange, } = this.props;
    this.setState({ selectedLocation: false, selectedProfile: false, selectedAddLocation: false });
    //Do all things required when  changing location
    handleChange(location.id);
    Mixpanel.track('Change to location', { 'location_id': location.id });
  }

  handleLocationSelected = (location) => {
    console.log('location selected');
  }

  handleLocationEmployees = (index, code, employees) => {
    const { allEmployees, updating } = this.state;

    let tempEmployees = allEmployees;
    for (let i = 0; i < employees.length; i++) {
      const idx = allEmployees.findIndex((item) => (item.id === employees[i].id));
      if (idx < 0) {
        tempEmployees.push(employees[i]);
      }
    }

    let obj = { index: 0, code: 0, numEmployees: 0 };
    obj.index = index;
    obj.code = code;
    obj.numEmployees = employees.length;
    let tempInfo = this.state.locationEmployees;
    tempInfo.push(obj);

    this.setState({
      allEmployees: tempEmployees,
      locationEmployees: tempInfo,
      lastLocationIndex: index,
    });
  }

  renderProfileItems = () => {
    const { locations, locationId } = this.props;
    const { profilePos, } = this.state;
    const logoutText = (<FormattedMessage
      id="NavPanelComponent.profile"
      defaultMessage="Log Out"
    />);
    if (profilePos) {
      const containerStyle = {
        top: '60px',
        right: '1px',
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        minWidth: '200px',
        justifyContent: 'left',
        alignItems: 'center',
        borderRadius: '4px',
        boxShadow: 'rgba(195, 192, 192, 0.5) 0px 5px 10px 2px'
      };

      const locationWrapStyle = {
        borderRadius: '4px',
        color: '#2c2c2e',
        width: '100%',
        backgroundColor: 'white',
        overflow: 'auto',
        maxHeight: '520px',
      }

      return (
        <div style={containerStyle} ref={this.profileDropdownRef}>
          <div style={locationWrapStyle} id="locations-wrap">
            <div
              className="add-location-item"
              onClick={this.handleLogOut}>
              <div className="location-avatar-container">
                <img className="log-out-avatar" src='/img/log-out-icon.svg' />
              </div>
              <span>
                {logoutText}
              </span>
            </div>
          </div>
        </div>
      );
    }
  }


  renderLocationItems = () => {
    const { locations, locationId } = this.props;
    const { dropdownPos, } = this.state;

    const sortedLocations = locations && [...locations]
      .sort((a, b) => {
        // Ensure null or undefined values are treated as empty strings
        const nameA = a.location_name || "";
        const nameB = b.location_name || "";

        if (a.id == this.props.locationId) return -1; // Move the matched item to the start
        if (b.id == this.props.locationId) return 1;  // Move other items after the matched item

        return nameA.localeCompare(nameB); // Sort remaining items alphabetically
      });

    const locationItems = sortedLocations && sortedLocations.map((location, index) => (
      <React.Fragment>
        <div
          key={index}
          className="employee-page-header__location-item-wrap"
          value={location}
          onClick={(event) => { this.handleLocationChange(location) }}
        >
          {this.locationAvatarImage(location)}

          <div className="location-info-div">
            <label className="employee-page-header__location-name-text">
              {this.locationNameLabel(location)}
            </label>
            <label className="employee-page-header__member-count">
              {this.locationMemberCountLabel(location)}
            </label>
          </div>
          {/* {this.locationSelectedImage(location)} */}
        </div>
        <div className="divider-line" />
      </React.Fragment>
    ));

    if (dropdownPos) {
      const containerStyle = {
        top: '60px',
        right: '100px',
        width: '320px',
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        boxShadow: 'rgba(195, 192, 192, 0.5) 0px 5px 10px 2px'
      };

      const locationWrapStyle = {
        borderRadius: '4px',
        color: '#2c2c2e',
        width: '100%',
        backgroundColor: 'white',
        overflow: 'auto',
        maxHeight: '520px',
      }

      return (
        <div style={containerStyle} ref={this.locationDropdownRef}>
          <div style={locationWrapStyle} id="locations-wrap">
            {locationItems}
            <div
              className="add-location-item"
              onClick={this.navigateToAddLocationPage}>
              <div className="location-avatar-container">
                <img className="add-location-avatar" src='/img/add-location-icon.svg' />
              </div>
              <span>
                <FormattedMessage
                  id="UserDropdown.addLocationLink"
                  defaultMessage="Add Location"
                  description="Link to Add Location page"
                />
              </span>
            </div>
          </div>
        </div>
      );
    }
  }

  locationAvatarImage = (location) => {

    const avatarUrl = location && location.avatar_blob?.blob_url || '/img/channel-placeholder.svg';
    const altText = location && location.avatar_blob ? 'Location Avatar' : 'Placeholder Avatar';

    return (
      <div className="location-avatar-container">
        <img src={avatarUrl} alt={altText} />
      </div>
    );
  }

  locationSelectedImage = (location) => {

    if (location.id == this.props.locationId) {
      return (<div className="location-selected-container">
        <img src="/icons/location-selected-icon.svg" />
      </div>)
    } else {
      return
    }
  }

  locationNameLabel = (location) => {
    const info = `${location.location_name}`;
    return info;
  }

  locationMemberCountLabel = (location) => {
    //NeedsLoc
    const info = `${location.swift_code}` + ` • +` + `${location.users_count}` + ` members`;
    return info;
  }

  closeDialog = () => {
    this.setState({ selectedAddLocation: false, showConfirm: false });
  };

  encrypt(uid, lid) {
    return (uid * uid + lid * lid + uid + 2 * uid * lid + 3 * lid) / 2;
  }

  render() {
    const { page } = this.state;
    if (page) {
      return <Redirect to={page} />;
    }
    const path = window.location.pathname;
    const { userInfo, user, locationData, locationId } = this.props;
    const {
      showConfirm,
      selectedAction,
      locationActive,
      profileActive,
      currentLocation,
      selectedLocation,
      selectedProfile,
      selectedAddLocation,
      locationPos,
      profilePos,
      currentPlan,
    } = this.state;

    const { categoryTypes } = constants;
    const userName = `${userInfo ? userInfo.first_name : ''} ${userInfo ? userInfo.last_name : ''}`;


    let displayTrialText = false;
    let displayTrialDaysText = '';
    let displayViewPlansUrl = '';
    if (currentPlan && currentPlan.name && currentPlan.name === 'Trial') {
      if (locationData && locationData.plan_refresh_date) {
        displayTrialText = true;
        const target = new Date(locationData.plan_refresh_date);
        const today = new Date();
        today.setHours(target.getHours(), target.getMinutes(), target.getSeconds(), target.getMilliseconds());
        const timeDifference = target - today;
        let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        if (days < 0) {
          days = 0;
        }
        displayTrialDaysText = `${days} days left on your free trial`;

        let userId = parseInt(getUserId());
        let ref_id = this.encrypt(userId, locationId);
        displayViewPlansUrl = `${basePricingPageUrl}?ref_id=${ref_id}`;
      }
    }


    let locationAvatarUrl = '';
    if (currentLocation && currentLocation.avatar_blob) {
      locationAvatarUrl = currentLocation.avatar_blob.blob_url;
    }

    let locationName = currentLocation?.location_name || '';
    let locationInfo = currentLocation
      ? `${currentLocation.swift_code} • +${currentLocation.users_count} members`
      : '';

    // console.log("Deepak locationData", currentLocation);
    let userAvatarUrl = '';
    if (user && user.profile_image) {
      userAvatarUrl = user.profile_image.thumb_url;
    }

    const locationText = (<FormattedMessage
      id="NavPanelComponent.location"
      defaultMessage="Location"
    />);



    if (
      checkForAuthentication() &&
      !includes(path, 'login') &&
      !includes(path, 'sign-up') &&
      !includes(path, 'password')
    ) {
      return (
        <header className="horizontal-header">

          <div className="header-container">

            <a href="/">
              <img className="header-logo" src="/img/shyft-logo-only.svg" alt="Shyft Logo" />
            </a>

            {displayTrialText && <div className="center-text">
              <span>{displayTrialDaysText}</span>
              <a
                href={displayViewPlansUrl}
                className="view-button"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                View Plans
              </a>
            </div>
            }

            <div className="right-content">
              <div
                className="left-section"
                ref={this.locationRef}
                onClick={this.handleLocationClicked}
                onMouseEnter={this.locationMouseEnter}
                onMouseLeave={this.locationMouseLeave}
              >
                {locationName &&
                  <img
                    className="logo"
                    src={locationAvatarUrl ? locationAvatarUrl : '/icons/channel-placeholder-avatar.svg'}
                    alt="Location Icon"
                  />
                }

                <div className="text-section">
                  <div className="title">{locationName || "+ Add Location"}</div>
                  <div className="subtitle">{locationInfo}</div>
                </div>

                {locationName && <div className="chevron">▼</div>}
              </div>
              <div
                className="right-section"
                ref={this.profileRef}
                onClick={this.handleProfileClicked}
                // onClick={this.handleLogOut}
                onMouseEnter={this.profileMouseEnter}
                onMouseLeave={this.profileMouseLeave}
              >
                <img
                  className="avatar"
                  src={userAvatarUrl ? userAvatarUrl : '/icons/default-profile-thumb.svg'}
                  alt="Profile Icon"
                />
              </div>
            </div>
            {
              selectedLocation && <Modal>
                {this.renderLocationItems()}
              </Modal>
            }

            {
              selectedProfile && <Modal>
                {this.renderProfileItems()}
              </Modal>
            }

            {
              selectedAddLocation && <Modal>
                <AddLocationDialog
                  onClose={this.closeDialog} />
              </Modal>
            }

            {
              showConfirm && <Modal>
                <Confirm
                  selectedAction={selectedAction}
                  selectedItem={currentUser}
                  selectedUsers={[currentUser]}
                  selectedType={categoryTypes.LOGOUT}
                  notifyConfirm={this.notifyConfirm}
                />
              </Modal>
            }
          </div >
        </header>
      )
    }
    return null;
  }
}

HorizontalNavPanelComponent.propTypes = {

}

export default injectIntl(HorizontalNavPanelComponent);
