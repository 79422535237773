import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl'
import ScheduleHeaderComponent from './components/Header/ScheduleHeaderComponent';
import TableComponent from './components/TableComponents/TableComponent';
import { SHIFTS_PER_PAGE, } from './constants';
import styledConfirm from '../Dashboard/components/styled';
import UpgradePrompt from '../../components/GeneralComponents/UpgradePrompt/UpgradePrompt';
import Modal from 'components/GeneralComponents/Modal';

import './ScheduleCalendarComponent.scss';
import '../css/toast.scss';
import HoursRowComponent from './components/TableComponents/HoursRowComponent';

import './components/TableComponents/TableComponent.scss';

import { startOfLocationFiscalWeek, endOfLocationFiscalWeek } from '../../helpers/date-time';

import '../../App/fonts.scss';
import '../../App/colors.scss';
import '../../App/layout.scss';
import InviteWithEmailContainer from 'pages/UnregisteredUser/InviteWithEmailContainer';
import utils from './duck/utils';
import dataExport from './duck/export';

import './components/TableComponents/ConfirmDeleteAllComponent';
import './components/TableComponents/ConfirmDeleteDraftsComponent';
import ConfirmDeleteAllComponent from './components/TableComponents/ConfirmDeleteAllComponent';
import ConfirmDeleteDraftsComponent from './components/TableComponents/ConfirmDeleteDraftsComponent';

import FinishPastingCopmonent from "./components/Header/FinishPastingComponent";
import ScheduleCalendarPubnubHandler from './ScheduleCalendarPubnubHandler';
import timeUtil from './duck/timeUtil';
import service from './duck/service';

class ScheduleCalendarComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      planName: '',
      showSendEmailModal: false,
      showAddUnregUserModal: false,
      showDeleteEntireScheduleModal: false,
      showDeleteUnpublishedModal: false,
      searchTerm: '',
      selectedDepartment: null,
      isShowingPastingSnackBar: false,
      shiftBeingPasted: null,
      tooltip: {
        visible: false,
        content: '',
        position: { top: 0, left: 0 }
      },
    };
    this._isMounted = false;
    this.apiCalled = false;
    this.pageIndex = 1;

    this.pubnub = props.pubnub;
  }

  componentDidMount() {
    console.log('ScheduleCalendar Mounted');

    const {loadSingleShiftDispatch, loadSingleApplicantDispatch,loadSingleFreePostDispatch, loadDepartments, locationId, getScheduleUsers, locationData, plans, loadLocationDataDispatch, loadUnregisteredUsersDispatch } = this.props;
   

    loadLocationDataDispatch();
    if( typeof(locationId) !== 'undefined' && locationId !== null){
      getScheduleUsers(locationId, 1, 10000);
      loadUnregisteredUsersDispatch(locationId);
      loadDepartments(locationId);

      if( this._isMounted == false ){
        this.listenerParams = ScheduleCalendarPubnubHandler.subscribe(this.pubnub, loadSingleShiftDispatch, loadSingleApplicantDispatch, loadSingleFreePostDispatch);
      }
    }

    if (plans && plans.length > 0) {
      if (locationData && locationData.plan_id) {
        const cplan = plans.find(plan => plan.id === locationData.plan_id);
        this.setState({ planName: cplan.name });
      }
    }

    this._isMounted = true;
  }

  componentWillUnmount() {
    this.pubnub.removeListener(this.listenerParams);
    this.pubnub.unsubscribe({
      channels: [eventChannelName()],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { loadChannelsToPostDispatch, locationId, errorWhilePublishing, removePublishErrorDispatch, clearDateRangeResetFlagDispatch, shifts, rangeStart, rangeEnd, allUsers, locationData, plans, changeDateRangeSlice, unregisteredUsers, needReset } = this.props;

    if (prevProps.locationData !== locationData) {

      if (plans && plans.length > 0) {
        if (locationData && locationData.plan_id) {
          const cplan = plans.find(plan => plan.id === locationData.plan_id);
          this.setState({ planName: cplan.name });
        }
      }

      if (!this.apiCalled && locationData !== null) {
        var start = startOfLocationFiscalWeek(locationData.fiscal_day);
        var end = endOfLocationFiscalWeek(locationData.fiscal_day);
        
        if( rangeStart !== null && rangeEnd !== null){
          //Read cached values if fiscal day not updated

          if( needReset == false ){
            start = rangeStart.clone();
            end = rangeEnd.clone();
          }
        }

      console.log('DidMount - New Date Range: ' + start.format("MMM Do YY") + '-' + end.format("MMM Do YY"));

      this.pageIndex = 1;
      this.makeLoadShiftsCall(start, end, this.pageIndex, 'Initial Load');
      changeDateRangeSlice(start, end);
      loadChannelsToPostDispatch(locationId);
    }

  } else if (prevProps.rangeStart !== rangeStart) {
    if (this.apiCalled) {
      this.pageIndex = 1;
      this.makeLoadShiftsCall(rangeStart, rangeEnd, this.pageIndex, 'Week Range Change');
      }
    } else if (prevProps.shifts !== shifts) {
      // console.log('Shifts: ' + shifts.length);
      this.apiCalled = true;
      clearDateRangeResetFlagDispatch();

      // cachedFiscalDay = locationData.fiscal_day;

      if (prevProps.shifts && shifts) {
        if (shifts.length - prevProps.shifts.length === SHIFTS_PER_PAGE) {
          this.makeLoadShiftsCall(rangeStart, rangeEnd, this.pageIndex, 'Got More Shifts');
        }
      }
    } else if (prevProps.allUsers !== allUsers) {
      // console.log(`Prev Users: ${prevProps.allUsers.length}, New Users: ${allUsers.length}`);
    } else if (prevProps.unregisteredUsers !== unregisteredUsers) {
      // console.log(`Unreg Users Loaded: ${unregisteredUsers.length}`);
    }

    if ( errorWhilePublishing === true) {
        setTimeout(() => {
          removePublishErrorDispatch();
        }, 2000);
    }
  }

  makeLoadShiftsCall(rangeStart, rangeEnd, eventPrefix) {
    const { getShiftsInDateRange } = this.props;
    console.log(eventPrefix + '- Load Page:' + this.pageIndex + ' @ ' + rangeStart.format("MMM Do YYYY") + '-' + rangeEnd.format("MMM Do YYYY"));
    getShiftsInDateRange(rangeStart, rangeEnd, this.pageIndex, SHIFTS_PER_PAGE, true);
    this.pageIndex += 1;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAddTeamMemberClick = (e) => {
    this.setState({ showAddUnregUserModal: true });
  }

  handleCloseAddTeamModal = (e) => {
    this.setState({ showAddUnregUserModal: false });
  }

  handleCloseInviteModal = (e) => {
    this.setState({ showSendEmailModal: false });
  }

  handleShowSMSInviteModal = (user) => {
    this.setState({ showSendEmailModal: true });
    this.unregisteredUserToInvite = user;
  }

  handleDepartmentSelect = (e) => {
    console.log('Dep Selected: ' + e );
    this.setState({selectedDepartment: e, shiftBeingPasted: null, isShowingPastingSnackBar: false});
  }

  notifySearchTermChanged = (e) => {
    console.log('Search: ' + e);
    this.setState({searchTerm: e, shiftBeingPasted: null, isShowingPastingSnackBar: false});
  }

  notifyHeaderOfShiftPasting = (isPasting, shift) => {
    this.setState({isShowingPastingSnackBar: isPasting, shiftBeingPasted: shift});
  }

  // Handle mouse enter event for each cell
  handleTooltipShow = (e, content) => {
    const {tooltip} = this.state;

    const rect = e.target.getBoundingClientRect();

    if( tooltip.visible == false ){
      this.setState({
        tooltip: {
          visible: true,
          content: content,
          position: {
            top: rect.top + window.scrollY + 10,  // Position above the cell
            left: rect.left + window.scrollX + rect.width/2 - 100, // Centered horizontally
          }
        }
      });
    }
  };

  // Handle mouse leave event from each cell
  handleTooltipHide = () => {
    const {tooltip} = this.state;

    if( tooltip.visible == true ){
      this.setState({
        tooltip: {
          ...this.state.tooltip,
          visible: false
        }
      });
    }

  };

  handleDeleteEntireScheduleModal = () => {
    this.setState({ showDeleteEntireScheduleModal: true });
  }

  handleDeleteDraftsModal = () => {
    this.setState({ showDeleteUnpublishedModal: true });
  }

  cancelDeleteAllModal = () => {
    this.setState({ showDeleteEntireScheduleModal: false});
  }

  cancelDeleteDraftModel = () => {
    this.setState({ showDeleteUnpublishedModal: false});
  }

  handleFinishPasting = () => {
    this.setState({isShowingPastingSnackBar: false, shiftBeingPasted: null});
  }

  render() {
    const {channels, errorWhilePublishing, deleteAllDraftShiftsDispatch, deleteAllShiftsDispatch, publishDraftShiftsDispatch, shifts, allUsers, locationId, locationData, rangeStart, rangeEnd, startStr, notifyTeamOfPublishDispatch,
      locationSettings, loading, linkUnregisteredUser, deleteShiftDispatch, changeDateRangeSlice, unregisteredUsers, departments } = this.props;
    const {showDeleteUnpublishedModal, showDeleteEntireScheduleModal, isShowingPastingSnackBar, planName, showSendEmailModal, selectedDepartment, searchTerm, shiftBeingPasted, tooltip} = this.state;

    const filteredUsers = utils.filteredUsers(allUsers, selectedDepartment, searchTerm);
    const filteredUnregisteredUsers = utils.filteredUnregisteredUsers(unregisteredUsers, selectedDepartment, searchTerm);
    const isSearchOrFilterApplied = utils.isSearchOrFilterApplied(searchTerm, selectedDepartment);

    const publishErrorText = (<FormattedMessage id="Schedule.publishError" defaultMessage="Error publishing some shifts, please refresh the page and try again" />);

    const weekdays = timeUtil.daysOfTheWeek(locationData)
    const scheduledHoursDictionary = utils.populateTotalScheduledHoursForWeek(shifts, filteredUsers, filteredUnregisteredUsers, service.initializeZeroStatsByWeekday(weekdays))
    const openShiftHoursDictionary = service.populateOpenShiftHoursForWeek(shifts, service.initializeZeroStatsByWeekday(weekdays)) 

    const scheduledUserCount = utils.numberOfUsersScheduledForWeek(shifts, filteredUsers, filteredUnregisteredUsers);

    const shouldShowOpenShiftUI = (locationSettings.cfg__shift__post_open_shift == true)

    return (
      <div className="schedule-calendar__wrap">

        <ScheduleHeaderComponent
          shifts={shifts}
          allUsers={allUsers}
          allUnregisteredUsers={unregisteredUsers}
          rangeStart={rangeStart}
          rangeEnd={rangeEnd}
          startStr={startStr}
          changeDateRangeSlice={changeDateRangeSlice}
          notifyTeamOfPublishDispatch={notifyTeamOfPublishDispatch}
          publishDraftShiftsDispatch = {publishDraftShiftsDispatch}
          locationId={locationId}
          locationData={locationData}
          locationSettings={locationSettings}
          exportableData={dataExport.exportableData(shifts, filteredUsers, filteredUnregisteredUsers, rangeStart, rangeEnd)}
          tableFormatData= {dataExport.tableFormatData(shifts, filteredUsers, filteredUnregisteredUsers, rangeStart, rangeEnd)}
          departments = {departments}
          handleDepartmentSelect = {this.handleDepartmentSelect}
          notifySearchTermChanged = {this.notifySearchTermChanged}
          isShowingPastingSnackBar = {isShowingPastingSnackBar}
          shiftBeingPasted = {shiftBeingPasted}
          handleDeleteDraftsModal = {this.handleDeleteDraftsModal}
          handleDeleteEntireScheduleModal = {this.handleDeleteEntireScheduleModal}
          notifyHeaderOfShiftPasting = {this.handleFinishPasting}
          scheduledResult = {scheduledHoursDictionary}
          openShiftResult = {openShiftHoursDictionary}
          scheduledUserCount = {scheduledUserCount} 
          shouldShowOpenShiftUI = {shouldShowOpenShiftUI}
          isPublishing = {this.props.isPublishing}
        />

        <TableComponent
          shifts={shifts}
          allUsers={filteredUsers}
          rangeStart={rangeStart}
          rangeEnd={rangeEnd}
          locationId={locationId}
          locationSettings={locationSettings}
          locationData={locationData}
          loading={loading}
          linkUnregisteredUser={linkUnregisteredUser}
          deleteShiftDispatch={deleteShiftDispatch}
          unregisteredUsers={filteredUnregisteredUsers}
          handleShowSMSInviteModal={this.handleShowSMSInviteModal}
          shouldShowAddMemberButton={(isSearchOrFilterApplied==false)}
          notifyHeaderOfShiftPasting = {this.notifyHeaderOfShiftPasting}
          shiftToCopy = {shiftBeingPasted}
          isCopyingShift = {isShowingPastingSnackBar}
          handleTooltipShow = {this.handleTooltipShow}
          handleTooltipHide = {this.handleTooltipHide}
          channels = {channels}
          openShiftHoursResult = {openShiftHoursDictionary}
        />

        {showSendEmailModal === true && <Modal> <InviteWithEmailContainer
          handleCloseInviteModal={this.handleCloseInviteModal}
          userToInvite={this.unregisteredUserToInvite}
          locationId={locationId}
          locationData={locationData}
        /> </Modal>}

        { showDeleteEntireScheduleModal === true && <Modal>
            <ConfirmDeleteAllComponent 
              deleteAllShiftsDispatch = {deleteAllShiftsDispatch} 
              cancelModal = {this.cancelDeleteAllModal}
              locationId = {locationId}
              shifts = {shifts}
              rangeStart = {rangeStart}
              rangeEnd = {rangeEnd}
              />
          </Modal> }

        { showDeleteUnpublishedModal === true && <Modal>
            <ConfirmDeleteDraftsComponent 
              deleteAllDraftShiftsDispatch = {deleteAllDraftShiftsDispatch} 
              cancelModal = {this.cancelDeleteDraftModel}
              locationId = {locationId}
              shifts = {shifts}
              rangeStart = {rangeStart}
              rangeEnd = {rangeEnd}
              />
          </Modal> }

        {planName === 'Basic' && <Modal> <UpgradePrompt /> </Modal>}

        <styledConfirm.Overlay display={planName === 'Basic' || showSendEmailModal ? 'block' : 'none'} />

        <table className='calendar-table total-hours-table'>
          <tbody className="total-hours">
            <HoursRowComponent 
              locationId={locationId} 
              shifts={shifts} 
              locationData={locationData} 
              filteredUsers={filteredUsers} 
              scheduledResult = {scheduledHoursDictionary}
              openShiftResult = {openShiftHoursDictionary}
              scheduledUserCount = {scheduledUserCount} 
              shouldShowOpenShiftUI = {shouldShowOpenShiftUI} />
          </tbody>
        </table>

        {tooltip.visible && (
          <div
              className="tooltip"
              style={{
                top: tooltip.position.top,
                left: tooltip.position.left,
                visibility: tooltip.visible ? 'visible' : 'hidden',
                opacity: tooltip.visible ? 1 : 0
              }} >

            <img className='tooltip-caret'  src='/icons/caret.svg'></img>
            <div className='tooltip-main'> {tooltip.content} </div>
          </div>
        )}

        {isShowingPastingSnackBar == true && <FinishPastingCopmonent shiftBeingPasted={shiftBeingPasted} handleFinishPasting = {this.handleFinishPasting} /> }
        { errorWhilePublishing == true && <div className="toast-message"> {publishErrorText} </div>}
      </div>
    );
  }
}

export default injectIntl(ScheduleCalendarComponent);
