import { isValidArray } from "helpers/validation";

const pendingApplicants = (applicants) => {
    if( isValidArray(applicants) == false ){ return []; }
    const result = applicants.filter( (applicant) => (applicant.status === 'applied' || applicant.status === 'nominated'));
    return result;
}

const approvedApplicants = (applicants) => {
    if( isValidArray(applicants) == false ){ return []; }
    const result = applicants.filter( (applicant) => (applicant.status === 'picked'));
    return result;
}

const deniedApplicants = (applicants) => {
    if( isValidArray(applicants) == false ){ return []; }
    const result = applicants.filter( (applicant) => (applicant.status === 'denied'));
    return result;
}

const commentsForApplicant = (applicantId, dict) => {
    const comments = dict[applicantId];
    if( comments == null ){ 
        return []; 
    }else{
        return comments;
    }
}

export default {
    pendingApplicants,
    approvedApplicants,
    deniedApplicants,
    commentsForApplicant,
}