import { connect } from 'react-redux';
import { appActions } from 'App/duck';
import TeamFeed from './TeamFeedComponent';

import { teamFeedActions, teamFeedOperations } from './duck';

const mapStateToProps = ({
  teamFeedReducer: { active, posts, readReceipts },
  locationReducer,
  appReducer,
}) => ({
  active,
  posts,
  readReceipts,
  locationId: locationReducer.location_code,
  locationData: locationReducer.location_data,
  plans: appReducer.plans.data,
});

// const mapStateToProps = ({
//   teamFeedReducer,
//   locationReducer,
// }) => {
//   return {
//     active: teamFeedReducer.active,
//     posts: teamFeedReducer.posts,
//     readReceipts: teamFeedReducer.readReceipts,
//     locationId: locationReducer.location_code,
//     locationData: locationReducer.locationData,
//   };
// };

const mapDispatchToProps = (dispatch) => {
  const clearActiveDelete = () => {
    dispatch(teamFeedActions.clearActiveDelete());
    dispatch(teamFeedActions.updateActiveComment(0));
    dispatch(teamFeedActions.updateActiveError(false));
  };
  const deletePost = (postId, lastPost) => {
    dispatch(teamFeedOperations.deletePost(postId, lastPost));
  };
  const deleteComment = (commentId, post) => {
    dispatch(teamFeedOperations.deleteComment(commentId, post));
  };
  const udpateChannelContent = (id, content) => {
    dispatch(appActions.updateLatestContent({ id, content }));
  };
  const  getPlans = (locationId) => {
    dispatch(subscriptionOperations.fetchPlans(locationId));
  };

  return {
    clearActiveDelete,
    deletePost,
    deleteComment,
    udpateChannelContent,
    getPlans,
  };
};

const TeamFeedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamFeed);

export default TeamFeedContainer;
