import React from 'react';
import PropTypes from 'prop-types';
import Pinput from './Pinput/Pinput';
import { parsePhoneNumber } from 'libphonenumber-js';
import '../../../../components/GeneralComponents/RegistrationDesign.scss'
import { TextInput } from '@shyft/acorns';

const PhoneConfirmation = (props) => {
  const { value, submitForm } = props;
  const phoneNumber = parsePhoneNumber(`+${value}`);

  return (
    <>
      <div className="name-prompt">Phone Number</div>
      <div className="icon-input-container">
        <input
          className="icon-name-input"
          value={phoneNumber.formatNational()}
          onChange={() => { }}
          disabled
        />
        <img
          className="input-icon-left"
          src='/icons/phone-icon.svg'
        />
      </div>

      <Pinput inputChange={props._pinChange}
        submitForm={props.submitForm} />
    </>
  );
};

export default PhoneConfirmation;

PhoneConfirmation.propTypes = {
  value: PropTypes.string.isRequired,
  validity: PropTypes.bool.isRequired,
  _pinChange: PropTypes.func.isRequired,
};
