import styled from 'styled-components/macro';

const Icon = styled.img`
  /* flex: 0; */
  width: 13px;
  height: auto;
  margin-right: 0.25rem;
  margin-top: 2px;
`;

export default Icon;
