import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Modal from 'components/GeneralComponents/Modal';
import { UploadSchedulerFile, } from '../../helpers/api-calls/schedule-calls';
import { UploadImage, UploadPDF, } from '../../helpers/api-calls/feed-calls';
import UploadComponent from './components/Upload/UploadComponent';
import HeaderComponent from './components/Header/HeaderComponent';
import TableContainer from './components/TableComponents/TableContainer';
import constants from './constants';
import './SchedulerComponent.scss';
import styled from '../UserManagement/styled';
import styledConfirm from '../Dashboard/components/styled';
import UpgradePrompt from '../../components/GeneralComponents/UpgradePrompt/UpgradePrompt';

import {Mixpanel} from '../../Mixpanel';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

class SchedulerComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: true,
      filesToUpload: [],
      upload: false,
      userActionDone: false,
      timer: null,
      userActionDoneMessage: '',
      actionJobs: [],
      searchTerm: '',
      selectedSort: null,
      ascending: true,
      planName: '',
    };

    //this.handleSubmit = this.handleSubmit.bind(this);
    //this.handleButtonClick = this.handleButtonClick.bind(this);

    this.fileInput = React.createRef(null);

    this._isMounted = false;
  }

  componentDidMount() {
    const { locationId, getLocationEmployeesNew, actionJobs, getLocationSchedules, getLocationActionJobs,
      allUsers, managers, associates, pendingUsers, removedUsers, plans, locationData, } = this.props;
    this._isMounted = true;
    if (locationId) {
      getLocationSchedules(locationId);
      getLocationActionJobs(locationId);
      //getLocationEmployeesNew(locationId);
    }

    if(plans && plans.length > 0) {
      if(locationData && locationData.plan_id) {
        const cplan = plans.find(plan => plan.id === locationData.plan_id);
        this.setState({planName: cplan.name});
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { locationId, actionJobs, plans, locationData, } = this.props;

    if(prevProps.locationData !== locationData) {
      if(plans && plans.length > 0) {
        if(locationData && locationData.plan_id) {
          const cplan = plans.find(plan => plan.id === locationData.plan_id);
          this.setState({planName: cplan.name});
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.timer);
  }

  ReceiveUploadClick = () => {
    this.setState({ upload: true });
    Mixpanel.track('Clicked import');
  }

  handleFileUploaded = (status, info) => {
    this.startTimer(15000);
    if (!info) {
      this.setState({ upload: false });
    } else if (status) {
      this.setState({ upload: false, userActionDone: true, userActionDoneMessage: info });
    } else {
      console.log(info);
      this.setState({ upload: false, userActionDone: true, userActionDoneMessage: info });
    }
    Mixpanel.track('file uploaded', {'status':status, 'info': info});
  }

  handleScheduleDeleted = (schedule_id) => {
    const messageText = (<FormattedMessage
      id="SchedulerComponent.deletedMessage"
      defaultMessage="Schedule ID: {id} Successfully Deleted"
      values={{
        id: schedule_id
      }}
    />);
    this.startTimer(10000);
    this.setState({ userActionDone: true, userActionDoneMessage: messageText });
    Mixpanel.track('Schedule deleted', {'schedule_id':schedule_id});
  }

  notifyActionJobChanged = (jobs) => {
    const { actionJobs, } = this.state;
    this.setState({ actionJobs: jobs });
  }

  notifySearchTermChanged = (term) => {
    const { searchTerm, } = this.state;
    this.setState({ searchTerm: term });
  }

  notifySortTypeChanged = (sort) => {
    const { selectedSort, } = this.props;
    this.setState({ selectedSort: sort });
  }

  notifySortOrderChanged = (value) => {
    const { ascending, } = this.state;
    this.setState({ ascending: value });
  }

  nofityScheduleUploaded = (fileName) => {
    const { locationId, getLocationSchedules, getLocationActionJobs, } = this.props;
    if (locationId) {
      getLocationSchedules(locationId);
      getLocationActionJobs(locationId);
      Mixpanel.track('schedule uploaded', {'filename':fileName});
    }
  }

  notifyRefreshRequested = () => {
    const { locationId, getLocationActionJobs, getLocationSchedules } = this.props;

    if (locationId) {
      getLocationSchedules(locationId);
      getLocationActionJobs(locationId);
    }
  }

  startTimer = (timeOut) => {
    const myTimer = setInterval(this.tick, timeOut); //unit: ms
    if (this._isMounted) {
      this.setState({ timer: myTimer });
    }
  }

  tick = () => {
    if (this._isMounted) {
      this.setState({ userActionDone: false });
    }
  }

  render() {
    const { upload, userActionDone, userActionDoneMessage, actionJobs, searchTerm, selectedSort, ascending, planName, } = this.state;
    const { loading, locationId, getLocationEmployeesNew, getLocationSchedules, getLocationActionJobs, allUsers, } = this.props;

    const submit = moment();
    const submittedTime = `${submit.format('MMM DD, YYYY')}`;

    let winTop = '500px';
    let winLeft = '300px';
    winTop = `${(window.innerHeight - 100)}px`;
    winLeft = `${(window.innerWidth - 100) / 2}px`;

    const titleText = (<FormattedMessage
      id="SchedulerComponent.imports"
      defaultMessage="Imports"
    />);

    return (
      <div className="scheduler__wrap">
        <p className="scheduler__title">{titleText}</p>
        {!upload && <HeaderComponent
          ReceiveUploadClick={this.ReceiveUploadClick}
          actionJobs={actionJobs}
          notifySearchTermChanged={this.notifySearchTermChanged}
          notifySortTypeChanged={this.notifySortTypeChanged}
          notifySortOrderChanged={this.notifySortOrderChanged}
          notifyRefreshRequested={this.notifyRefreshRequested}
          getLocationActionJobs={getLocationActionJobs}
          locationId={locationId}
        />}
        <LoadingLayer loading={loading} />
        {!upload && <TableContainer
          notifyActionJobChanged={this.notifyActionJobChanged}
          handleScheduleDeleted={this.handleScheduleDeleted}
          searchTerm={searchTerm}
          selectedSort={selectedSort}
          ascending={ascending}
        />}
        <div className="scheduler__main-body">
          {upload && <UploadComponent
            locationId={locationId}
            getLocationEmployeesNew={getLocationEmployeesNew}
            handleFileUploaded={this.handleFileUploaded}
            nofityScheduleUploaded={this.nofityScheduleUploaded}
          />}
        </div>

        {userActionDone && <Modal>
          <styled.WinContainer top={winTop} left={winLeft}>
            <div className="status__wrap">
              <label className="status__text">{userActionDoneMessage}</label>
            </div>
          </styled.WinContainer>
        </Modal>}
        {planName === 'Basic' && <Modal>
        <UpgradePrompt/>
        </Modal>}
        <styledConfirm.Overlay display={planName === 'Basic' ? 'block' : 'none'} />
      </div>
    );
  }
}

SchedulerComponent.propTypes = {
  //locationId: PropTypes.string.isRequired,
  //searchTerm: PropTypes.string.isRequired,
  //searching: PropTypes.bool.isRequired,
  //activeFilter: PropTypes.string.isRequired,
  //determineFetch: PropTypes.func.isRequired,
  //fetchTeamMetadata: PropTypes.func.isRequired,
  //selectedAll: PropTypes.bool.isRequired,
};

export default SchedulerComponent;
