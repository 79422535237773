import { updateWorkweekStartDayCall } from 'helpers/api-calls/location-calls';
import {Mixpanel} from '../../../Mixpanel';
import {locationDataLoaded} from '../../../actions/dashboard-actions';
import actions from './actions';
import calendarActions from '../../ScheduleCalendar/duck/actions';

const updateWorkweekStartDayOperation = (locationId, weekday) => (dispatch) => {
    return updateWorkweekStartDayCall(locationId, weekday).then(({ data }) => {
      dispatch(locationDataLoaded(data.location));
      dispatch(actions.startShowingToast());
      dispatch(calendarActions.flagForReset());
    }).catch((error) => {
      Mixpanel.error('Update work week', {'error':error});
    });
  };

const stopShowingToastOperation = () => (dispatch) => {
  return dispatch(actions.stopShowingToast());
}

export default {
  updateWorkweekStartDayOperation,
  stopShowingToastOperation
};
