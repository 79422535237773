import React from 'react';
import './LinkUserComponent.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { scheduleCalendarSelectors } from '../../duck';
import utils from '../../duck/utils';
import UserRowShimmer from 'pages/UserManagement/components/UserTable/UserRowShimmer';

import { Mixpanel } from '../../../../Mixpanel';

class LinkUserComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: false,
      searchTerm: '',
      rawUsers: [],
      filteredUsers: [],
      selectedUnscheduledUser: null,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const { allUsers, shifts, rangeStart, rangeEnd } = this.props;
    const users = utils.usersNotScheduledForCurrentWeekSelector(shifts, rangeStart, rangeEnd, allUsers);
    this.setState({ rawUsers: users });
    this.setState({ filteredUsers: users });
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchTerm, rawUsers, selectedUnscheduledUser } = this.state;
    const resultArray = [];

    if (searchTerm) {
      if (searchTerm !== prevState.searchTerm) {
        const lowercaseSearchTerm = searchTerm.toLowerCase();
        rawUsers.forEach((user) => {
          const fullname = user.first_name + ' ' + user.last_name;
          if (user && ((user.first_name && user.first_name.toLowerCase().includes(lowercaseSearchTerm)) ||
            (user.last_name && user.last_name.toLowerCase().includes(lowercaseSearchTerm)) ||
            fullname.toLowerCase().includes(lowercaseSearchTerm))) {
            resultArray.push(user);
          }
        });
        this.setState({ filteredUsers: resultArray });
        if (resultArray.includes(selectedUnscheduledUser) === false) {
          this.setState({ selectedUnscheduledUser: null });
        }
      }
    } else {
      this.setState({ filteredUsers: rawUsers });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCancel = (event) => {
    const { cancelModal } = this.props;
    cancelModal();
    Mixpanel.track('Cancel link unregistered user');
  }

  handleConfirm = (event) => {
    const { selectedUnscheduledUser, filteredUsers } = this.state;
    const { linkUnregisteredUser, unregisteredUserToLink, cancelModal, rangeStart, rangeEnd } = this.props;

    if (selectedUnscheduledUser && filteredUsers.length > 0) {
      // console.log('Existing unscheduled user to link to: ' + unregisteredUserToLink.unregisteredUserId + ' | ' + selectedUnscheduledUser.owner_id);
      linkUnregisteredUser(unregisteredUserToLink.id, selectedUnscheduledUser.owner_id, rangeStart, rangeEnd);
      cancelModal();
    }
  }

  shouldEnableConfirmButton() {
    const { selectedUnscheduledUser, filteredUsers } = this.state;
    return (selectedUnscheduledUser && filteredUsers.length > 0);
  }

  handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      this.setState({ searchTerm: event.target.value })
    }
  }

  handleSearchChanged = (event) => {
    this.setState({ searchTerm: event.target.value });
  }

  selectUnScheduledUser(user) {
    const { selectedUnscheduledUser } = this.state;
    if (user === selectedUnscheduledUser) {
      this.setState({ selectedUnscheduledUser: null });
    } else {
      this.setState({ selectedUnscheduledUser: user });
    }
  }

  renderLinkCandidateList = () => {
    const { filteredUsers, selectedUnscheduledUser } = this.state;

    const errorText = (<FormattedMessage
      id="LinkUser.noUserAvailable"
      defaultMessage="No users available to link" />);

    const userRows = filteredUsers.map((user) => (
      <tr className="linkuser_header" key={user.id}>
        <td>
          <img src={user && user.profile_image_thumb_url ? user.profile_image_thumb_url : '/icons/default-profile-thumb.svg'} />
        </td>

        <td className="linkuser_unscheduled-user-wrap">
          <label className="linkuser_unscheduled-name">{user.first_name} {user.last_name}</label>
          <label className="linkuser_unscheduled-job">{user.position}</label>
        </td>
        <td className="linkuser_unscheduled-checkbox">
          <button onClick={() => { this.selectUnScheduledUser(user); }} >
            {selectedUnscheduledUser === user ? <img src='/icons/blue-checked.svg' /> : <img src='/icons/blue-unchecked.svg' />}
          </button>
        </td>
      </tr>
    ));

    if (userRows && userRows.length > 0) {
      return userRows;
    } else {
      const noLinkedUsersText = (
        <tr>
          <td className="linkuser_unscheduled-user-wrap">
            <label className="linkuser_no-user-available">{errorText}</label>
          </td>
        </tr>
      );
      return noLinkedUsersText;
    }
  }

  render() {
    const { unregisteredUserToLink, searchTerm } = this.props;

    const linkUserText = (<FormattedMessage
      id="LinkUser.toSchedule"
      defaultMessage="Link User to Schedule" />);

    const selectedUserText = (<FormattedMessage
      id="LinkUser.selectedUser"
      defaultMessage="(Selected User)"
    />);


    const cancel = (<FormattedMessage
      id="Confirm.cancel"
      defaultMessage="Cancel"
    />);

    const confirm = (<FormattedMessage
      id="Confirm.confirm"
      defaultMessage="Confirm"
    />);


    const userImageUrl = unregisteredUserToLink && unregisteredUserToLink.profile_image_thumb_url ?
      unregisteredUserToLink.profile_image_thumb_url : '/icons/default-profile-thumb.svg';

    return (
      <div className="linkuser_container">
        <label className="linkuser_title">{linkUserText}</label>
        <div className="linkuser_header">
          <img src={userImageUrl} />
          <div className="linkuser_unregistered-user-wrap">
            <div className="linkuser_unregistered-name-wrap">
              <label className="linkuser_unregistered-name">{unregisteredUserToLink.first_name + ' ' + unregisteredUserToLink.last_name}</label>
              <label className="linkuser_unregistered-selected-user-text">{selectedUserText}</label>
            </div>
            <label className="linkuser_unregistered-job">{unregisteredUserToLink.position}</label>
          </div>
        </div>
        <input className="linkuser_search-box" type="text"
          onChange={this.handleSearchChanged}
          onKeyUp={this.handleKeyPress}
          value={searchTerm} />

        <div className="linkuser_table-wrap">
          <table className="linkuser_unscheduled-table">
            <tbody>
              {this.renderLinkCandidateList()}
            </tbody>
          </table>
        </div>

        <div className="linkuser_confirm-button-wrap">
          <button className="linkuser_confirm-button-cancel" onClick={this.handleCancel}>{cancel}</button>
          <button className={this.shouldEnableConfirmButton() ? "linkuser_confirm-button linkuser_button-enabled" : "linkuser_confirm-button linkuser_button-disabled"} style={{ outline: 0 }} onClick={this.handleConfirm}>{confirm}</button>
        </div>

      </div>
    );
  }
}


export default injectIntl(LinkUserComponent);