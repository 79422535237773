import React from "react";
import { LoadScript } from "@react-google-maps/api";

const GoogleMapsLoader = ({ children }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={["places"]}>
      {children}
    </LoadScript>
  );
};

export default GoogleMapsLoader;
