import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Redirect } from 'react-router-dom';
import { validatePasswordStrength } from 'helpers/validation';
import { getQueryStringParameter } from 'helpers/browser-interactions';
import { ConfirmPasswordReset } from 'helpers/api-calls';
import messages from './messages';

import './ChangePasswordPage.scss';
import '../../components/GeneralComponents/RegistrationDesign.scss'
import RegistrationLayout from 'components/GeneralComponents/RegistrationLayout';
class ChangePasswordPageI18n extends React.Component {
  state = {
    showPassword: false,
    showConfirmPassword: false,
    token: '',
    firstPassword: {
      value: '',
      validity: false,
      errorMessage: '',
    },
    secondPassword: {
      value: '',
      validity: false,
      errorMessage: '',
    },
    successful: false,
    error: false,
    errorMessage: '',
    path: null,
  };

  componentDidMount() {
    if (getQueryStringParameter('reset_password_token')) {
      this.setState({
        token: getQueryStringParameter('reset_password_token'),
      });
    } else {
      this.setState({
        path: {
          pathname: '/login',
        },
      });
    }
  }

  login = () => {
    this.setState({
      path: {
        pathname: '/login',
      },
    });
  };

  _handleFirstPasswordChange = (e) => {
    const { value } = e.target;
    this.setState({
      firstPassword: {
        value,
        validity: validatePasswordStrength(value),
        errorMessage: '',
      },
    });
  };

  _handleSecondPasswordChange = (e) => {
    const { value } = e.target;
    this.setState({
      secondPassword: {
        value,
        validity: this.state.firstPassword.value === value,
        errorMessage: '',
      },
    });
  };

  _validateForm = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    let validity = true;
    if (!this.state.firstPassword.validity) {
      validity = false;
      this.setState({
        firstPassword: Object.assign(this.state.firstPassword, {
          errorMessage: formatMessage(messages.error1),
        }),
      });
    }

    if (!this.state.secondPassword.validity) {
      validity = false;
      this.setState({
        secondPassword: Object.assign(this.state.secondPassword, {
          errorMessage: formatMessage(messages.error2),
        }),
      });
    }

    return validity;
  };

  handleNext = () => {
    const {
      intl: { formatMessage },
    } = this.props;

    this.setState({
      error: false,
      errorMessage: '',
    });

    if (this._validateForm()) {
      ConfirmPasswordReset(
        this.state.firstPassword.value,
        this.state.secondPassword.value,
        this.state.token
      ).then(
        (response) => {
          console.log(response);
          if (response.status === 204) {
            this.setState({
              successful: true,
            });
          } else {
            this.setState({
              error: true,
            });
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.setState({
              error: true,
              errorMessage: formatMessage(messages.error3),
            });
          } else {
            this.setState({
              error: true,
              errorMessage: formatMessage(messages.error4),
            });
          }
        }
      );
    }
  };

  handlePasswordToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleConfirmPasswordToggle = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const { showPassword, showConfirmPassword } = this.state;
    const FirstPasswordErrorMessage = () => {
      if (this.state.firstPassword.errorMessage.length > 0) {
        return (
          <p className="login__form-errors--primary-red">
            {this.state.firstPassword.errorMessage}
          </p>
        );
      }
      return null;
    };

    const SecondPasswordErrorMessage = () => {
      if (this.state.secondPassword.errorMessage.length > 0) {
        return (
          <p className="login__form-errors--primary-red">
            {this.state.secondPassword.errorMessage}
          </p>
        );
      }
      return null;
    };

    const ApiErrorMessage = () => {
      if (this.state.error && this.state.errorMessage.length > 0) {
        return (
          <p className="login__form-errors--primary-red">
            {this.state.errorMessage}
          </p>
        );
      }
      return null;
    };

    if (this.state.path) {
      return <Redirect to={this.state.path} />;
    }
    //
    if (this.state.successful) {
      return (
        <RegistrationLayout>
          <div className="form-content">
            <div className="welcome-text-wrapper">
              <div className="dot"></div>
              <div className="welcome-text">Nicely done!</div>
            </div>
            <div className="main-heading">
              Success.
            </div>
            <div className="sub-heading">Your password has been updated! You can now log in.</div>

            <button className="next-btn" style={{ marginTop: '16px' }} onClick={this.login}>Back to Login</button>
          </div>
        </RegistrationLayout>
      );
    }
    return (
      <RegistrationLayout>
        <div className="form-content">
          <div className="welcome-text-wrapper">
            <div className="dot"></div>
            <div className="welcome-text">Password reset</div>
          </div>
          <div className="main-heading">
            Update Password.
          </div>
          <div className="sub-heading">Should include one uppercase, one lowercase, one special character and have at least 8 characters.</div>
          <div className="icon-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter New Password"
              className="icon-name-input"
              name="password"
              onChange={this._handleFirstPasswordChange}
            />
            <img
              className="input-icon-right"
              src={showPassword ? '/icons/password-eye-close-icon.svg' : '/icons/password-eye-icon.svg'}
              alt="Toggle Password Visibility"
              onClick={this.handlePasswordToggle}
            />

            <img
              className="input-icon-left"
              src='/icons/password-lock-icon.svg'
            />
          </div>

          <div className="icon-input-container" style={{ marginTop: '16px' }}>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Re-enter Password"
              className="icon-name-input"
              name="password_confirmation"
              onChange={this._handleSecondPasswordChange}
            />
            <img
              className="input-icon-right"
              src={showConfirmPassword ? '/icons/password-eye-close-icon.svg' : '/icons/password-eye-icon.svg'}
              alt="Toggle Password Visibility"
              onClick={this.handleConfirmPasswordToggle}
            />

            <img
              className="input-icon-left"
              src='/icons/password-lock-icon.svg'
            />
          </div>

          <section className="login__form-errors">
            <FirstPasswordErrorMessage />
            <SecondPasswordErrorMessage />
            <ApiErrorMessage />
          </section>

          <button className="next-btn" onClick={this.handleNext}>Update</button>
        </div>
      </RegistrationLayout>
    );
  }
}

const ChangePasswordPage = injectIntl(ChangePasswordPageI18n);

export { ChangePasswordPage };
