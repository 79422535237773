import React  from "react";
import { FormattedMessage } from 'react-intl';

import '../AvailabilityComponent.scss';
import '../../../App/layout.scss';
import '../../../App/fonts.scss';
import { Mixpanel } from '../../../Mixpanel';
import UserHeaderComponent from "components/GeneralComponents/UserHeader/UserHeaderComponent";

import AvailabilityDetailTableComponent from "./AvailabilityDetailTableComponent";

class AvailabilityDetailComponent extends React.Component {

    render() {

        const { user, closeAvailDetail, rangeStart, rangeEnd, userAvailabilities } = this.props;
        const teamMemberAvailText = <FormattedMessage id="Availability.teamMemberAvail" defaultMessage='Team Member Availability'/>
        return (
            <div className="avail-detail-container">
                 <div className="title-view">
                    <label className="title averta-regular fs20px">{teamMemberAvailText}</label>
                    <button type="button" className="close-button" onClick={ () => closeAvailDetail() }> <img src="icons/close-simple.svg" /></button>
                </div>

                <UserHeaderComponent user = {user} />
                <AvailabilityDetailTableComponent rangeStart={rangeStart} rangeEnd={rangeEnd} userAvailabilities={userAvailabilities} />

            </div>
            );
        }

    }

    export default AvailabilityDetailComponent;