import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import Modal from 'components/GeneralComponents/Modal';
import constants from '../../../constants';
import styled from './styled';

class UserCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      debug: false,
      mouseOverBottomLine: false,
      bottomLinePos: null,
    };

    this.bottomLineRef = React.createRef();
    this.oneItemRef = React.createRef();

    this._isMounted = false;
  }

  componentDidMount() {
    const { user } = this.props;

    this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {
    const { bottomLinePos, } = this.state;
  }

  componentWillUnmount() {

    this._isMounted = false;
  }

  mouseEnterBL = (event) => {
    this.setState({ mouseOverBottomLine: true });
  }

  mouseLeaveBL = (event) => {
    this.setState({ mouseOverBottomLine: false });
  }

  mouseEnterOneItem = (event) => {
    this.setState({ mouseOverBottomLine: true });
  }

  mouseLeaveOneItem = (event) => {
    this.setState({ mouseOverBottomLine: false });
  }

  renderBottomLineMouseTip = (content) => {
    const { bottomLinePos, } = this.state;

    if (bottomLinePos) {
      const bottomLineContainerStyle = {
        top: bottomLinePos.top,
        left: bottomLinePos.left,
        width: bottomLinePos.width,
        height: bottomLinePos.height,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        zIndex: 2,
        borderRadius: '5px',
        paddingTop: '5px',
        //border: '1px solid red',
      };
      const test = `top: ${bottomLinePos.top}, left: ${bottomLinePos.left}, w: ${bottomLinePos.width}, h: ${bottomLinePos.height}`;
      return (
        <div style={bottomLineContainerStyle}>
          <div className="mouse-tip__top-wrap">{test}</div>
        </div>
      );
    }
  }

  render() {
    const { user } = this.props;
    const { debug, mouseOverBottomLine, } = this.state;
    const { statusTypes } = constants;

    const userImageUrl = user && user.profile_image_thumb_url ?
      user.profile_image_thumb_url : '/icons/default-profile-thumb.svg';
    const userName = user ? `${user.first_name} ${user.last_name}` : '';
    const approvedBadge = '/icons/verified-badge-icon.svg';
    const placeholder = '';

    const allowProfile =
      user.status !== statusTypes.REMOVED &&
      user.status !== statusTypes.REJECTED &&
      user.status !== statusTypes.WITHDRAWN &&
      user.status !== statusTypes.BLOCKED;

    let bottomLine = null;
    if (user) {
      if (user.position && !user.badge_id) {
        bottomLine = (<styled.BottomLineTextOneItem
          ref={this.oneItemRef}
          onMouseEnter={this.mouseEnterOneItem}
          onMouseLeave={this.mouseLeaveOneItem}
        >{user.position}</styled.BottomLineTextOneItem>);
      } else if (!user.position && user.badge_id) {
        // bottomLine = (<styled.BottomLineTextOneItem
        //                 ref={this.oneItemRef}
        //                 onMouseEnter={this.mouseEnterOneItem}
        //                 onMouseLeave={this.mouseLeaveOneItem}
        //               >{user.badge_id}</styled.BottomLineTextOneItem>);

        bottomLine = (<styled.BottomLineTextOneItem
          ref={this.oneItemRef}
          onMouseEnter={this.mouseEnterOneItem}
          onMouseLeave={this.mouseLeaveOneItem}
        >{placeholder}</styled.BottomLineTextOneItem>)
      } else if (!user.position && !user.badge_id) {
        bottomLine = (<styled.BottomLineTextOneItem
          ref={this.oneItemRef}
          onMouseEnter={this.mouseEnterOneItem}
          onMouseLeave={this.mouseLeaveOneItem}
        >{placeholder}</styled.BottomLineTextOneItem>)
      } else {
        bottomLine = (
          <styled.BottomLineWrap
            ref={this.bottomLineRef}
            onMouseEnter={this.mouseEnterBL}
            onMouseLeave={this.mouseLeaveBL}
          >
            <styled.BottomLineText>{user.position}</styled.BottomLineText>
            {/* <styled.Dot />
            <styled.BottomLineText>{user.badge_id}</styled.BottomLineText> */}
          </styled.BottomLineWrap>);
      }
    }

    return (
      <styled.TopWrap>
        <styled.OwnerAvatar src={userImageUrl} />
        <styled.OwnerInfoWrap>
          <styled.OwnerInfoTopLine>
            {allowProfile ? <styled.ProfileLink to={`/team/${user.id}`}>{userName}</styled.ProfileLink> : <styled.UserName>{userName}</styled.UserName>}
            {user && user.is_admin && <styled.ApprovedBadge src={approvedBadge} />}
          </styled.OwnerInfoTopLine>
          {debug ? user.owner_id : bottomLine}
        </styled.OwnerInfoWrap>
        {/*mouseOverBottomLine && <Modal>
          {this.renderBottomLineMouseTip(bottomLine)}
        </Modal>*/}
      </styled.TopWrap>
    );
  }
}


UserCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(UserCell);