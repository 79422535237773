import { formatOnlyProps } from 'helpers/formatting';

const params = {
  getChannels: (location_id) => ({
    location_id,
    per_page: 100,
    type_is: ['custom_feed', 'location_feed', 'region_feed'],
    'response[only]': formatOnlyProps(`
      id,
      channel_name,
      updated_at,
      channel_type,
      channel_latest_content,
      member_count,
      shyft_channel,
      final_allow_manage,
    `),
    'response[nested]': 1,
  }),
};

const shiftNames = {
  SHIFT: 'shift',
  SHIFT_VTO: 'shift_vto',
  OPEN_SHIFT: 'open_shift',
  SHIFT_INVENTORY: 'shift_inventory',
  SHIFT_PICKUP: 'shift_pickup',
};

export default {
  params,
  shiftNames,
};
